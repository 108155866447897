import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

export enum ConfirmDialogType {
  CLOSEYEAR_GENERATE,
  CLOSEYEAR_REVERSION,
  WORKCALENDARS_GENERATE,
  WORKCALENDARS_REVERSION,
}

@Component({
  selector: 'app-close-year-histroy-confirm-dialog',
  templateUrl: './close-year-histroy-confirm-dialog.component.html',
  styleUrls: ['./close-year-histroy-confirm-dialog.component.css']
})
export class CloseYearHistroyConfirmDialogComponent {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  confirmDialogType: ConfirmDialogType;
  text: string = "";

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
  ) { }

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.confirmDialogType = this.data.confirmDialogType;
    this.getText();
  }

  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  getText() {
    let transalteString = "";

    switch (this.confirmDialogType) {
      case ConfirmDialogType.CLOSEYEAR_GENERATE:
        transalteString = "CLOSEYEAR_HISTORY.FORM_CONFIRMATION.CLOSEYEAR_GENERATE";
        break;
      case ConfirmDialogType.CLOSEYEAR_REVERSION:
        transalteString = "CLOSEYEAR_HISTORY.FORM_CONFIRMATION.CLOSEYEAR_REVERSION";
        break;
      case ConfirmDialogType.WORKCALENDARS_GENERATE:
        transalteString = "CLOSEYEAR_HISTORY.FORM_CONFIRMATION.WORKCALENDARS_GENERATE";
        break;
      case ConfirmDialogType.WORKCALENDARS_REVERSION:
        transalteString = "CLOSEYEAR_HISTORY.FORM_CONFIRMATION.WORKCALENDARS_REVERSION";
        break;
    }

    this.translate.get(transalteString).subscribe((str) => {
      this.text = str;
    });
  }

}
