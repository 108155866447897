import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeSelectionService } from 'src/app/modules/employee/services/employee-selection.service';

@Component({
  selector: 'app-absence-management-select-employee',
  templateUrl: './absence-management-select-employee.component.html',
  styleUrls: ['./absence-management-select-employee.component.css']
})
export class AbsenceManagementSelectEmployeeComponent implements OnInit {
  @Input() selectedEmployees: Employee[] = [];
  @Input() currentEmployee: Employee;

  @Input() employeeView: boolean = false;

  EmployeesFormGroup = new FormGroup({
    employee: new FormControl('', []),
  });

  constructor(
    protected employeeSelectionService: EmployeeSelectionService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  selectCurrentEmployee(employee: any) {
    this.currentEmployee = employee;
    this.employeeSelectionService.onChangeCurrentEmployee(this.currentEmployee);
  }

  displayCurrentEmployee(employee: Employee): string | undefined {
    return (employee) ? `${employee.name} ${employee.firstSurname} ${employee.secondSurname}` : undefined;
  }
}
