import { Company } from './company.model';
import { EmployeeType } from './employee-type.model';

export class PRCContract {
  prcContractId   : string;
  startDate       : string;
  value           : number;

  //Relations
  companyId       : string;
  company         : Company;
  employeeTypeId  : string;
  employeeType    : EmployeeType;

  constructor(obj?: Partial<PRCContract>) {
    this.prcContractId  = (obj && obj.prcContractId) || undefined;
    this.startDate      = (obj && obj.startDate) || undefined;
    this.value          = (obj && obj.value) || undefined;

    //Relations
    this.companyId      = (obj && obj.companyId) || undefined;
    this.company        = (obj && obj.company) || undefined;
    this.employeeTypeId = (obj && obj.employeeTypeId) || undefined;
    this.employeeType   = (obj && obj.employeeType) || undefined;
  }
}
