import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, BaseListComponent, Hotkeys, Role, SecurityStateManagementService } from 'inzo-portalempleado';
import { TrainingChangeRequestGroupItems } from 'src/app/interfaces/tcr-group-api.interface';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { TCRState, TCRStateCode } from 'src/app/models/tcr-state.model';
import { TrainingChangeRequest } from 'src/app/models/training-change-request.model';

@Component({
  selector: 'app-tcr-list',
  templateUrl: './tcr-list.component.html',
  styleUrls: [
    './tcr-list.component.css',
    './../../action.components.css',
    '../../action-list.component.css',
  ],
})
export class TCRListComponent extends BaseListComponent<TrainingChangeRequest> implements OnInit, OnDestroy  {
  @Input() trainingChangeRequests: TrainingChangeRequestGroupItems[] = [];
  @Input() employeeView = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  @Input() tcrStates: TCRState[] = [];
  @Input() canApprove = false;
  panelOpenState = false;
  canAdd = false;
  canDelete = false;

  roles: Role[];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public SSMService: SecurityStateManagementService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
  ) {
    super(accountService, SSMService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];

    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.Empleado)) {
        this.canApprove = false;
    }
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      if (this.router.url === "/managements/trainingChange") {
        this.canApprove = true;
      } else {
        this.canApprove = false;
      }
    }
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  getCanApprove(training){
    let result = (training.state.stateCode == TCRStateCode.PENDING_APROVAL || training.state.stateCode == TCRStateCode.PENDING_ANNULLED) && this.canApprove;
    return result;
  }

  getCanSend(training){
    return training.state.stateCode == TCRStateCode.REGISTERED;
  }
}

