import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, BaseFormComponent, Hotkeys, Role } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { TCRState, TCRStateCode } from 'src/app/models/tcr-state.model';
import { TrainingChangeRequest } from 'src/app/models/training-change-request.model';
import { TrainingChangeRequestService } from 'src/app/services/training-change-request.service';

@Component({
  selector: 'app-tcr-form',
  templateUrl: './tcr-form.component.html',
  styleUrls: [
    './tcr-form.component.css',
    './../../action.components.css',
    '../../action-list.component.css',
  ],
})
export class TCRFormComponent extends BaseFormComponent<TrainingChangeRequest> implements OnInit, OnDestroy {
  @Input() employeeView = false;
  selectedDesc: string = undefined;
  @Input() currentTrainingChangeRequests: TrainingChangeRequest;
  @Input() tcrStates: TCRState[] = [];

  roles: Role[];

  declineState: boolean = false;

  enabled = true;
  detail = false;
  permissionsLoaded = false;

  @Input() canApprove = false;
  @Input() canSend = false;

  componentTypeTranslated: string;
  subscription: Subscription;

  formGroupControl! : FormGroup;

  filterApi: FiltersApi = new FiltersApi();

  constructor(
    private toastrService: ToastrService,
    private trainingChangeRequestService: TrainingChangeRequestService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    protected spinner: NgxSpinnerService,
    private translate: TranslateService,
  ) {
    super(accountService, router, hotkeys);

    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('TRAINING_CHANGE_REQUEST_ACTION.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });

    this.filterApi.add(
      {
        field: "related",
        value: "true"
      }
    );
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();

    if (this.employeeView) {
      this.filterApi.add(
        {
          field: "employeeId",
          value: this.currentTrainingChangeRequests.employeeId
        }
      );
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  private init() {
    this.iniFormGroup();
  }

  iniFormGroup() {
    this.formGroupControl = new FormGroup(
      {
        reason: new FormControl({value: "", disabled: false}),
        state: new FormControl({value: "", disabled: false}),
      }
    );
  }

  getApproveLabel() {
    let result = "";
    let label = "TRAINING_CHANGE_REQUEST_ACTION.FORM.LABEL.APPROVE";

    if (this.currentTrainingChangeRequests.state.stateCode == TCRStateCode.PENDING_ANNULLED) {
      label = "TRAINING_CHANGE_REQUEST_ACTION.FORM.LABEL.CANCEL";
    }

    this.translate.get(label).subscribe((a: string) => {
      result = a;
    });

    return result;
  }

  // ======================== CRUD ========================

  cleanForm() {
    this.formGroupControl.controls["reason"].setValue("");
  }

  cancel() {
    this.declineState = false;
    this.cleanForm();
  }

  cancelRequest() {
    let status = this.tcrStates.find(element => element.stateCode == TCRStateCode.CANCELED).tcrStateId;

    this.update(status);
  }

  sendRequest() {
    let status = this.tcrStates.find(element => element.stateCode == TCRStateCode.PENDING_APROVAL).tcrStateId;

    this.update(status);
  }

  declineRequest() {
    this.declineState = true;
  }

  approveRequest() {
    this.declineState = false;
    let status = this.tcrStates.find(element => element.stateCode == TCRStateCode.APPROVED).tcrStateId;

    if (this.currentTrainingChangeRequests.state.stateCode == TCRStateCode.PENDING_ANNULLED) {
      status = this.tcrStates.find(element => element.stateCode == TCRStateCode.ANNULLED).tcrStateId;
    }

    this.update(status);
  }

  updateDecline() {
    this.declineState = true;

    let status = this.tcrStates.find(element => element.stateCode == TCRStateCode.REJECTED).tcrStateId;
    let reason = this.formGroupControl.controls["reason"].value;

    this.update(status, reason);
  }

  update(status : string, reason? : string) {
    this.spinner.show();
    this.trainingChangeRequestService.changeStateTrainingChangeRequest(
      this.currentTrainingChangeRequests.trainingChangeRequestId,
      status,
      reason
    ).subscribe(
      response => {
        this.trainingChangeRequestService.getAllTrainingChangeRequestGroupBy(this.filterApi.getStrinFilterApi()).subscribe(currentTrainingChangeRequests => {
          this.trainingChangeRequestService.updateTrainingChangeRequests(currentTrainingChangeRequests.items);
        });

        this.spinner.hide();
        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );
  }

  back() {
  }
}
