import { Category } from './category.model';
import { Company } from './company.model';
import { EmployeeContract } from './employee-contract.model';
import { EmployeeType } from './employee-type.model';
import { Employee } from './employee.model';

export class SBA {
  sbaId               : string;
  startDate           : string;
  sba                 : number;
  coefficient         : number;
  observations        : string;
  costHourManual      : number | null;
  costHour            : number;

  //Relations
  categoryId          : string;
  category            : Category;
  companyId           : string;
  company             : Company;
  employeeId          : string;
  employee            : Employee;
  employeeContractId  : string | null;
  employeeContract    : EmployeeContract;
  employeeTypeId      : string;
  employeeType        : EmployeeType;

  constructor(obj?: Partial<SBA>) {
    Object.assign(this, obj);
  }
}
