import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HolidayRequestService } from 'src/app/services/holiday-request.service';

@Component({
  selector: 'app-holiday-request',
  templateUrl: './holiday-request.component.html',
  styleUrls: ['./holiday-request.component.css', './../action.components.css']
})
export class HolidayRequestComponent implements AfterViewInit, OnInit {
  constructor() { }

  ngAfterViewInit() {
  }
  ngOnInit() {
  }
}
