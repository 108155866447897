import { EmployeeSituation } from './employee-situation.model';
import { EmployeeType } from './employee-type.model';
import { Employee } from './employee.model';

export class EmployeeHistory {
  employeeHistoryId       : string;
  reason                  : string;
  log                     : string;
  createdDate             : string;
  startDate               : string;
  finishDate              : string;

  //Relations
  newEmployeeSituationId  : string;
  newEmployeeSituation    : EmployeeSituation;

  employeeId              : string;
  employee                : Employee;

  createdByUserId         : string;
  createdByUser           : Employee;

  constructor(obj?: Partial<EmployeeHistory>) {
    Object.assign(this, obj);
  }
}
