import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { Degree } from '../models/degree.model';


@Injectable({ providedIn: 'root' })
export class DegreeService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private degreeSubject: ReplaySubject<Degree[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public degreeObservable: Observable<Degree[]> = this.degreeSubject.asObservable();

    private degreeRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public degreeRouteObservable: Observable<string> = this.degreeRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/degrees';
    }

    constructor(private http: HttpClient) {
    }

    getAllDegrees(filter?: string) {
        return this.http.get<any>(`${this.serviceUrl}${(filter!=undefined && filter !=null) ? filter : ""}`);
    }

    getAllDegreesByEmployee(employeeId?: string) {
        let employeeIdFilter="";
        if(employeeId!=undefined && employeeId != null){
            employeeIdFilter += "?employeeId="+employeeId;
        }
        return this.http.get<any>(`${this.serviceUrl}${employeeIdFilter}`);
    }

    getAllDegreesBy(degreeId: any){
        const endpointUrl = `${this.serviceUrl}/Group`;
        return this.http.post<any>(endpointUrl, degreeId);
    }

    getDegree(degreeId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<Degree>(`${this.serviceUrl}/${degreeId}${relatedFilter}`);
    }

    associateDegree(degreeId: string, employeeId: string, trainingCenterId: string, finishDate: string) {
      const endpointUrl = `${this.serviceUrl}/${degreeId}/Employee/${employeeId}/Center/${trainingCenterId}/FinishDate/${finishDate}`;
      return this.http.post(endpointUrl, null);
    }

    disassociateDegree(degreeId: string, employeeId: string) {
        const endpointUrl = `${this.serviceUrl}/${degreeId}/Employee/${employeeId}`;
        return this.http.delete(endpointUrl); //TODO verificar
    }

    updateDegrees(degrees: Degree[]) {
        this.degreeSubject.next(degrees);
    }

    updateDegreeRoutes(id: string) {
        this.degreeRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}
