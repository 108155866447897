import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseFormComponent, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { EmployeeType } from 'src/app/models/employee-type.model';
import { EmployeeTypeService } from 'src/app/services/employee-type.service';
import * as jsonpatch from 'fast-json-patch';

@Component({
  selector: 'app-employee-type-form',
  templateUrl: 'employee-type-form.component.html',
  styleUrls: ['./employee-type-form.component.css','./../../maintenance.components.css'],
})
export class EmployeeTypeFormComponent extends BaseFormComponent<EmployeeType> implements OnInit, OnDestroy {
  selectedDesc: string = undefined;
  employeeType: EmployeeType;

  roles: Role[];

  enabled = true;
  detail = false;
  permissionsLoaded = false;

  formGroupControl = new FormGroup({
    type: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
  });

  canAdd = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;
  subscription: Subscription;

  constructor(
    private toastrService: ToastrService,
    private employeeTypeService: EmployeeTypeService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ) {
      this.canAdd = true;
      this.canDelete = true;
    }

    this.PK = 'employeeTypeId';

    this.baseRoute = '/maintenances/employeeType';


    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('EMPLOYEETYPES_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  private init() {
    this.formGroupControl.get('type').disable();
    this.formGroupControl.get('description').disable();
    this.employeeType = new EmployeeType();
    this.subscription = this.route.paramMap.subscribe(params => {
      const transId = params.get('id');
      this.employeeTypeService.updateEmployeeTypeRoutes(transId);
      if (transId) {
        if (transId === 'new') {
          this.detail = false;
          this.enabled = true;
          this.employeeType = new EmployeeType();
          this.selectedDesc = undefined;
          this.formGroupControl.setValue({ type: '', description: '' });
          this.formGroupControl.get('type').enable();
          this.formGroupControl.get('description').enable();
        } else {
          this.employeeTypeService.getEmployeeType(transId).subscribe(response => {
            this.detail = true;
            this.enabled = false;
            this.employeeType = response;
            this.formGroupControl.setValue({ type: this.employeeType.type, description: this.employeeType.description });
            this.formGroupControl.get('type').disable();
            this.formGroupControl.get('description').disable();
            this.selectedDesc = response.type;
          });
        }
      } else {
        this.selectedDesc = undefined;
        this.detail = false;
        this.enabled = false;
        this.employeeType = new EmployeeType();
      }
    });
  }

  edit() {
    this.enabled = true;
    // this.formGroupControl.get('type').enable();
    this.formGroupControl.get('description').enable();
  }

  cleanForm() {
    this.formGroupControl.setValue({ type: '', description: '' });
  }

  // ======================== CRUD ========================

  create() {
    this.employeeType = new EmployeeType({
        type: this.formGroupControl.get('type').value,
      description: this.formGroupControl.get('description').value
    });
    this.employeeTypeService.createEmployeeType(this.employeeType).subscribe(() => {
        this.employeeTypeService.getAllEmployeeTypes().subscribe(employeeTypes => {
          this.employeeTypeService.updateEmployeeTypes(employeeTypes.items);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.CREATE_LONG', {
            type: this.componentTypeTranslated,
            code: this.employeeType.type,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.cancel();
      },
      error => {
        if (error.status === 500 || error.status === 0) {
        } else if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
        }
      },
    );
  }

  update() {
    const observer = jsonpatch.observe(this.employeeType);
    this.employeeType.type = this.formGroupControl.get('type').value;
    this.employeeType.description = this.formGroupControl.get('description').value;
    const patch = jsonpatch.generate(observer);
    this.employeeTypeService.modifyEmployeeType(this.employeeType.employeeTypeId,patch).subscribe(() => {
        this.employeeTypeService.getAllEmployeeTypes().subscribe(employeeTypes => {
          this.employeeTypeService.updateEmployeeTypes(employeeTypes.items);
        });
        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );
  }

  delete() {
    this.employeeTypeService.deleteEmployeeType(this.employeeType.employeeTypeId).subscribe(() => {
      this.employeeTypeService.getAllEmployeeTypes().subscribe(employeeTypes => {
        this.employeeTypeService.updateEmployeeTypes(employeeTypes.items);
      });
      let toastTitle = '';
      let toastBody = '';
      this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {
        toastTitle = a;
      });
      this.translate
        .get('MESSAGES.SUCCESS.DELETE_LONG', {
          type: this.componentTypeTranslated,
          code: this.employeeType.type,
        })
        .subscribe((a: string) => {
          toastBody = a;
        });
      this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
      this.cancel();
    });
  }

  cancel() {
    this.cleanForm();
    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }

  back() {
    this.cancel();
  }
}
