import { EmployeeCourse } from "./employee-course.model";
import { EmployeeDegree } from "./employee-degree.model";
import { EmployeeDocument } from "./employee-document.model";
import { EmployeeLanguage } from "./employee-language.model";

export class Certification extends EmployeeDocument {
  //Relations
  employeeDegreeId    : string;
  employeeDegree      : EmployeeDegree;

  employeeCourseId    : string;
  employeeCourse      : EmployeeCourse;

  employeeLanguageId  : string;
  employeeLanguage    : EmployeeLanguage;

  constructor(obj?: Partial<Certification>) {
    super();

    //Relations
    this.employeeDegreeId   = (obj && obj.employeeDegreeId) || undefined;
    this.employeeDegree     = (obj && obj.employeeDegree) || undefined;

    this.employeeCourseId   = (obj && obj.employeeCourseId) || undefined;
    this.employeeCourse     = (obj && obj.employeeCourse) || undefined;

    this.employeeLanguageId = (obj && obj.employeeLanguageId) || undefined;
    this.employeeLanguage   = (obj && obj.employeeLanguage) || undefined;
  }
}
