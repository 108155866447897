import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { EmployeeHistory } from '../models/employee-history.model';
import { Employee } from '../models/employee.model';


@Injectable({ providedIn: 'root' })
export class EmployeeService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private employeeSubject: ReplaySubject<Employee[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeObservable: Observable<Employee[]> = this.employeeSubject.asObservable();

    private employeeRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeRouteObservable: Observable<string> = this.employeeRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/employee';
    }

    constructor(private http: HttpClient) {
    }

    getPersonalData(employeeId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<Employee>(`${this.serviceUrl}/${employeeId}${relatedFilter}`);
    }

    getMyEmployeeInfo(filter?: string) {
        if (filter == undefined || filter == null) {
            filter = "";
        }

        let url = `${this.serviceUrl}/me${filter}`;
        return this.http.get<any>(url);
    }

    modifyPersonalData(employeeId: string, employeeData: any, homeAddressData: any) {
        const endpointUrl = `${this.serviceUrl}/${employeeId}`;
        let dataSend = { employeeData };

        if (homeAddressData != null) {
          dataSend["homeAddressData"] = homeAddressData;
        }

        return this.http.patch<Employee>(endpointUrl, dataSend);
    }

    modifyEmployeeSituation(employeeId: string, employeeHistory: EmployeeHistory, newEmployeeData: any = null) {
        const endpointUrl = `${this.serviceUrl}/EmployeeSituation/${employeeId}`;
        const dataSend = (newEmployeeData != null)
          ? { employeeHistory, newEmployeeData }
          : { employeeHistory };

        return this.http.patch<EmployeeHistory>(endpointUrl, dataSend);
    }

    associateUser(login: string, userId: string) {
      const endpointUrl = `${this.serviceUrl}/${login}/${userId}`;
      return this.http.put<Employee>(endpointUrl,null);
    }

    updateEmployees(employees: Employee[]) {
        this.employeeSubject.next(employees);
    }

    updateEmployeeRoutes(id: string) {
        this.employeeRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}
