//#region IMPORTS
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material';

import { MaterialModule } from 'src/app/modules/material/material.module';
import { CustomCommonImportsModule } from 'src/app/modules/custom-common-imports/custom-common-imports.module';

import { InzoMatPaginatorComponent } from './components/inzo-mat-paginator/inzo-mat-paginator.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../close-year/close-year.module';
import { HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
//#endregion

//#region COMPONENTES PROPIOS
//#endregion

@NgModule({
  // ------------------------------------------------------------------------------------------------
  // -- DECLARATIONS
  // ------------------------------------------------------------------------------------------------
  declarations: [
    // Componentes propios
    InzoMatPaginatorComponent,
  ],

  // ------------------------------------------------------------------------------------------------
  // -- IMPORTS
  // ------------------------------------------------------------------------------------------------
  imports: [
    CommonModule,

    // Custom modules
    MaterialModule,
    MatSlideToggleModule,
    CustomCommonImportsModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      timeOut: 5000,
      enableHtml: true
    }),
  ],

  // ------------------------------------------------------------------------------------------------
  // -- EXPORTS
  // ------------------------------------------------------------------------------------------------
  exports: [
    InzoMatPaginatorComponent,
  ],

  // ------------------------------------------------------------------------------------------------
  // -- PROVIDERS
  // ------------------------------------------------------------------------------------------------
  providers: [
  ],

  // ------------------------------------------------------------------------------------------------
  // -- SCHEMAS
  // ------------------------------------------------------------------------------------------------
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class PECommonModule { }
