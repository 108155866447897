import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseFormComponent, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { Language } from 'src/app/models/language.model';
import { LanguageService } from 'src/app/services/language.service';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { EmployeeSelectDialogComponent } from '../../employee-select-dialog/employee-select-dialog.component';
import { MatDialog } from '@angular/material';
import { EmployeeLanguage } from 'src/app/models/employee-language.model';
import { getDateString } from 'src/app/helpers/date.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmployeeSelectAssociateConfirmDialogComponent } from '../../employee-select-associate-confirm-dialog/employee-select-associate-confirm-dialog.component';

@Component({
  selector: 'app-language-form',
  templateUrl: 'language-form.component.html',
  styleUrls: ['./language-form.component.css','./../../maintenance.components.css'],
})
export class LanguageFormComponent extends BaseFormComponent<Language> implements OnInit, OnDestroy {
  selectedDesc: string = undefined;
  language: Language;
  allEmployees: Employee[]

  roles: Role[];

  enabled = true;
  detail = false;
  permissionsLoaded = false;

  formGroupControl = new FormGroup({
    description: new FormControl('', Validators.required),
    categoryDescription: new FormControl('', Validators.required),
  });

  canAdd = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;
  subscription: Subscription;

  constructor(
    private toastrService: ToastrService,
    private languageService: LanguageService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private employeeManagementService: EmployeeManagementService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ) {
      this.canAdd = true;
      this.canDelete = true;
    }
    this.PK = 'languageId';

    this.baseRoute = '/formations/language';


    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('LANGUAGES_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  private init() {
    this.formGroupControl.get('description').disable();
    this.formGroupControl.get('categoryDescription').disable();
    this.language = new Language();
    this.subscription = this.route.paramMap.subscribe(params => {
      this.employeeManagementService.getAllEmployees().subscribe(employeeManagementResponse =>{
        this.allEmployees = employeeManagementResponse.items;
        const transId = params.get('id');
        this.languageService.updateLanguageRoutes(transId);
        if (transId) {
          if (transId === 'new') {
            this.detail = false;
            this.enabled = true;
            this.language = new Language();
            this.selectedDesc = undefined;
            this.formGroupControl.setValue({ description: '', categoryDescription: '' });
            this.formGroupControl.get('description').enable();
            this.formGroupControl.get('categoryDescription').enable();
          } else {
            this.languageService.getLanguage(transId, true).subscribe(response => {
              this.detail = true;
              this.enabled = false;
              this.language = response;
              this.formGroupControl.setValue({ description: this.language.description, categoryDescription: this.language.categoryDescription});
              this.formGroupControl.get('description').disable();
              this.formGroupControl.get('categoryDescription').disable();
              this.selectedDesc = response.description;
            });
          }
        } else {
          this.selectedDesc = undefined;
          this.detail = false;
          this.enabled = false;
          this.language = new Language();
        }
      });
    });
  }

  edit() {
    this.enabled = true;
    this.formGroupControl.get('description').enable();
    this.formGroupControl.get('categoryDescription').enable();
  }

  cleanForm() {
    this.formGroupControl.setValue({ description: '', categoryDescription: '' });
  }

  assingEmployee(e) {
    this.formGroupControl.get('employee').setValue(e.option.value);
  }

  displayEmployee(employee: Employee): string | undefined {
    return (employee) ? employee.name : undefined;
  }

  // ======================== CRUD ========================

  cancel() {
    this.cleanForm();
    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }

  back() {
    this.cancel();
  }

  associateEmployee() {
    const employeeSelectionDialog = this.dialog.open(EmployeeSelectDialogComponent, {
      maxWidth: '80%',
      maxHeight: '65%',
      data: {
        employees: this.language.employeeLanguages
      }
    });

    employeeSelectionDialog.afterClosed().subscribe(async (selection: Employee) => {
      if (selection) {
        let text: string;
        this.translate.get('ASSOCIATE_EMPLOYEES.DIALOG_CONFIRMATION.TEXT_ADD', {
          employee: `${selection.firstSurname} ${selection.secondSurname}, ${selection.name}`
        }).subscribe((a: string) => {
          text = a;
        });
        const confirmationDialog = this.dialog.open(EmployeeSelectAssociateConfirmDialogComponent, {
          width: '550px',
          data: {
            asassociate: true,
            text
          }
        });

        confirmationDialog.afterClosed().subscribe(async (confirm) => {
          if (confirm != false) {
            this.spinner.show();

            this.languageService.associateLanguage(
              this.language.languageId,
              selection.employeeId,
              confirm.trainingCenterId,
              confirm.finishDate.toISOString()
            ).subscribe(
              response => {
                this.languageService.getLanguage(this.language.languageId, true).subscribe(language => {
                  this.language = language;
                  let title: string;
                  let msg: string;

                  this.translate.get('MESSAGES.SUCCESS.UPDATE_LONG_SIMPLE', {type: this.language.description}).subscribe((a: string) => {
                    title = a;
                  });

                  this.spinner.hide();
                  this.toastrService.success(msg, title, { timeOut: 3000 });
                });
              },
              error => {
                let title: string;

                this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
                  title = a;
                });

                this.spinner.hide();
                this.toastrService.error(error.error, title, { timeOut: 3000 });
              },
            );
          }
        });
      }
    });
  }

  disassociateEmployee(employeeLanguage: EmployeeLanguage) {
    let text: string;
    this.translate.get('ASSOCIATE_EMPLOYEES.DIALOG_CONFIRMATION.TEXT_REMOVE', {
      employee: `${employeeLanguage.employee.firstSurname} ${employeeLanguage.employee.secondSurname}, ${employeeLanguage.employee.name}`
    }).subscribe((a: string) => {
      text = a;
    });

    const confirmationDialog = this.dialog.open(EmployeeSelectAssociateConfirmDialogComponent, {
      width: '550px',
      data: {
        asassociate: false,
        text
      }
    });

    confirmationDialog.afterClosed().subscribe(async (confirm) => {
      if (confirm == true) {
        this.spinner.show();

        this.languageService.disassociateLanguage(
          this.language.languageId,
          employeeLanguage.employeeId
        ).subscribe(
          response => {
            this.languageService.getLanguage(this.language.languageId, true).subscribe(language => {
              this.language = language;
              let title: string;
              let msg: string;

              this.translate.get('MESSAGES.SUCCESS.DELETE_LONG_SIMPLE', {type: this.language.description}).subscribe((a: string) => {
                title = a;
              });

              this.spinner.hide();
              this.toastrService.success(msg, title, { timeOut: 3000 });
            });
          },
          error => {
            let title: string;

            this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
              title = a;
            });

            this.spinner.hide();
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          },
        );
      }
    });
  }

  getFinishDate(employeeLanguage: EmployeeLanguage) {
    return employeeLanguage && employeeLanguage.finishDate
      ? getDateString(employeeLanguage.finishDate, "DD/MM/YYYY")
      : "";
  }
}
