import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CourseComponent } from 'src/app/pages/formations/course/course.component';

@Component({
  selector: 'app-course-confirmation-delete-dialog',
  templateUrl: './course-confirmation-delete-dialog.component.html',
  styleUrls: ['./course-confirmation-delete-dialog.component.css'],
})
export class CourseConfirmationDeleteDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CourseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

}
