import { Component, Inject, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  DateAdapter,
  MatDialog,
} from "@angular/material";
import { ITLeaveComponent } from "../it-leave.component";
import { NgxSpinnerService } from "ngx-spinner";
import * as jsonpatch from "fast-json-patch";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Absence } from "src/app/models/absence.model";
import { AbsenceService } from "src/app/services/absence.service";
import { AbsenceType } from "src/app/models/absence-type.model";
import { AbsenceTypeService } from "src/app/services/absence-type.service";
import { Employee } from "src/app/models/employee.model";
import {
  AccountService,
  ResponseNotificationService,
} from "inzo-portalempleado";
import { EmployeeManagementService } from "src/app/services/employee-management.service";
import {
  disableDay,
  disableWeekEnd,
  getArrayDateString,
  getDateTimeString,
  transformDate,
} from "src/app/helpers/date.helper";

import * as moment from "moment";
import { NGX_MAT_DATE_FORMATS } from "@angular-material-components/datetime-picker";
import { CommonResources } from "src/app/models/common-resources";
import { WorkCalendar } from "src/app/models/work-calendar.model";
import { AbsenceChangesService } from "src/app/services/absence-changes.service";
import { AbsenceStateCode } from "src/app/models/absence-state-code-enum.model";
import { AbsenceState } from "src/app/models/absence-state.model";
import { WorkCalendarService } from "src/app/services/work-calendar.service";
import { ListDates } from "src/app/models/list-dates.model";
import { InputLabelsStrings } from "../../date-range-input/date-range-input.component";
import { Subscription } from "rxjs";
import { FiltersApi } from "src/app/models/filters-api.model";
import { AbsenceDocumentDialogComponent } from "../../absence-document-dialog/absence-document-dialog.component";
import { ConfirmationDialogComponent } from "../../dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-it-leave-create-range-dialog",
  templateUrl: "./it-leave-create-range-dialog.component.html",
  styleUrls: ["./it-leave-create-range-dialog.component.css"],
  providers: [
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: CommonResources.CUSTOM_DATETIME_FORMATS,
    },
  ],
})
export class ITLeaveCreateRangeDialogComponent {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  formGroupControl: FormGroup;
  itLeaveStates: AbsenceState[] = [];

  showReason: boolean = false;

  allAbsenceTypes = [];
  absenceId: string;
  absenceName: string;
  absence: Absence = null;

  currentEmployee: Employee = null;
  disabledDays: any[] = [];
  edit = false;

  defaultStartDate = [new Date().getHours(), 0, 0];
  defaultFinishDate = [new Date().getHours(), 0, 0];

  selectedDates: ListDates = new ListDates();

  datePickerInputLabels: InputLabelsStrings = null;
  inputExpectedFinishDate: boolean = true;
  filtersApiAbsemceType: FiltersApi = new FiltersApi();

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    public dialogRef: MatDialogRef<ITLeaveComponent>,
    public absenceTypeService: AbsenceTypeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected spinner: NgxSpinnerService,
    protected translate: TranslateService,
    protected toastrService: ToastrService,
    protected accountService: AccountService,
    protected employeeManagementService: EmployeeManagementService,
    protected absenceService: AbsenceService,
    protected absenceChangesService: AbsenceChangesService,
    protected workCalendarService: WorkCalendarService,
    protected RNService: ResponseNotificationService,
    protected dialog: MatDialog,
  ) {
    dialogRef.disableClose = true;
  }

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.formGroupControl = new FormGroup({
      description: new FormControl("", Validators.required),
      startDate: new FormControl("", Validators.required),
      finishDate: new FormControl("", Validators.required),
      absenceType: new FormControl("", Validators.required),
      state: new FormControl(""),
      reason: new FormControl(""),
    });

    this.filtersApiAbsemceType.add({
      field: "isMedicalLeave",
      value: "true",
    });

    // Ordenación
    this.filtersApiAbsemceType.add({
      field: "code",
      value: "asc",
    });

    this.absenceTypeService
      .getAllAbsenceTypes(this.filtersApiAbsemceType.getStrinFilterApi())
      .subscribe((absenceTypesResponse) => {
        this.allAbsenceTypes = absenceTypesResponse.items;
      });

    const userId = this.accountService.currentUser.id;

    if (this.data.currentEmployee) {
      this.currentEmployee = this.data.currentEmployee;

      if (this.data.itLeaveStates) {
        this.itLeaveStates = this.data.itLeaveStates;
      }
    } else {
      this.employeeManagementService
        .getEmployeeByUserId(userId, true)
        .subscribe((employee) => {
          this.currentEmployee = employee;
        });
    }

    this.absenceId = this.data.absenceId;
    this.absenceName = this.data.absenceName;
    this.absence = this.data.absence;

    if (this.currentEmployee && this.currentEmployee.workCalendar) {
      this.loadDisabledDays(this.currentEmployee.workCalendar);
    } else if (this.currentEmployee) {
      this.workCalendarService
        .getWorkCalendar(this.currentEmployee.workCalendarId)
        .subscribe((response) => {
          this.currentEmployee.workCalendar = response;
          this.loadDisabledDays(this.currentEmployee.workCalendar);
        });
    }

    if (this.absence) {
      this.edit = true;
      this.formGroupControl.setValue({
        description: this.absence.description,
        startDate: this.absence.startDate,
        finishDate: this.absence.finishDate,
        absenceType: this.absence.absenceType,
        state: this.absence.absenceStateId,
        reason: this.absence.reason,
      });
    }

    // this.createNoticeMessage();
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngOnDestroy(): void {
    this.toastrService.clear();
  }

  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  loadDisabledDays(workCalendar: WorkCalendar): void {
    const disabledDays = [];

    workCalendar.wcEvents.forEach((event) => {
      let startDate = moment(event.startDate);
      let endDate = moment(event.endDate);
      while (startDate.isSameOrBefore(endDate)) {
        disabledDays.push({ date: startDate.toDate() });
        startDate.add(1, "days");
      }
    });

    this.disabledDays = disabledDays;
  }

  selectedDatesChange(event) {
    this.selectedDates = event;

    if (this.selectedDates.empty()) {
      this.formGroupControl.get("startDate").reset();
      this.formGroupControl.get("finishDate").reset();
    } else {
      this.formGroupControl.patchValue({
        startDate: this.selectedDates.first(),
        finishDate: this.selectedDates.last(),
      });
    }
  }

  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  /* ****************************************************************************************************************** */
  /* ** CREATE
  /* ****************************************************************************************************************** */
  create(send: boolean = false) {
    this.spinner.show();

    this.absence = new Absence({
      description: this.formGroupControl.get("description").value,
      startDate: getDateTimeString(this.formGroupControl.get("startDate").value),
      absenceTypeId: this.formGroupControl.get("absenceType").value.absenceTypeId,
      employeeId: this.currentEmployee.employeeId,
      requestedDates: this.selectedDates.toDatesStrings(),
    });

    // Se asigna el valor de la fecha de final de la baja o la fecha de la próxima revisión de la misma (expectedFinishDate)
    if (this.inputExpectedFinishDate) {
      this.absence.expectedFinishDate = getDateTimeString(
        this.formGroupControl.get("finishDate").value
      );
    } else {
      this.absence.finishDate = getDateTimeString(
        this.formGroupControl.get("finishDate").value
      );
    }

    this.absenceService.createAbsence(this.absence, send).subscribe(
      (response) => {
        this.absence = response as Absence;
        this.absence.absenceType =
          this.formGroupControl.get("absenceType").value;

        let text = "";

        this.translate
          .get("ABSENCE_DOCUMENTS.MESSAGE.IT_ADD_DOCUMENT")
          .subscribe((transitionStr: string) => {
            text = transitionStr;
          });

        const confirmationDialog = this.dialog.open(
          ConfirmationDialogComponent,
          {
            width: "550px",
            data: {
              text,
              showConfirmationQuestion : false
            },
          }
        );

        confirmationDialog.afterClosed().subscribe((confirm) => {
          if (confirm) {
            this.openDocumentsDialog(this.absence);
          }
        });

        this.dialogRef.close(this.absence);

        // Para evitar que la notificación se cierre inmediantamente después de que lo haga el diálogo.
        setTimeout(() => {
          this.spinner.hide();
          this.createSuccessMessage(this.absence);
        }, 500);
      },
      (error) => {
        this.spinner.hide();
        this.createErrorMessage(error);
      }
    );
  }

  /* ****************************************************************************************************************** */
  /* ** UPDATE
  /* ****************************************************************************************************************** */
  update() {
    const observer = jsonpatch.observe(this.absence);
    this.absence.description = this.formGroupControl.get("description").value;
    this.absence.startDate = getDateTimeString(
      this.formGroupControl.get("startDate").value
    );
    this.absence.absenceTypeId =
      this.formGroupControl.get("absenceType").value.absenceTypeId;
    this.absence.requestedDates = this.selectedDates.toDatesStrings();

    // Se asigna el valor de la fecha de final de la baja o la fecha de la próxima revisión de la misma (expectedFinishDate)
    if (this.inputExpectedFinishDate) {
      this.absence.expectedFinishDate = getDateTimeString(
        this.formGroupControl.get("finishDate").value
      );
    } else {
      this.absence.finishDate = getDateTimeString(
        this.formGroupControl.get("finishDate").value
      );
    }

    const patch = jsonpatch.generate(observer);

    this.absenceService.modifyAbsence(this.absence.absenceId, patch).subscribe(
      (response) => {
        this.absence = response as Absence;
        this.absence.absenceType =
          this.formGroupControl.get("absenceType").value;
      },
      (error) => {
        this.createErrorMessage(error);
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.dialogRef.close(this.absence);
      }
    );
  }

  /**
   * Abre una ventana de diálogo para opererar con los documentos asociados a una ausencia.
   *
   * @param absence ausencia para gestionar
   */
  openDocumentsDialog(absence: Absence) {
    let filtersApi: FiltersApi = new FiltersApi();

    filtersApi.add({
      field: "related",
      value: "true",
    });
    filtersApi.add({
      field: "employeeId",
      value: absence.employeeId,
    });

    const absenceDocumentDialog = this.dialog.open(
      AbsenceDocumentDialogComponent,
      {
        width: "850px",
        data: {
          absenceService: this.absenceService,
          translate: this.translate,
          toastrService: this.toastrService,
          RNService: this.RNService,

          absence: absence,
          filtersApi: filtersApi,
        },
      }
    );

    absenceDocumentDialog.afterClosed().subscribe((response) => {
      this.absenceChangesService.refreshData(true);
    });
  }

  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */

  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  assingAbsenceType(e) {
    this.formGroupControl.get("absenceType").setValue(e.option.value);
  }

  displayAbsenceType(absenceType: AbsenceType): string | undefined {
    return absenceType ? absenceType.description : undefined;
  }

  setDate($event, startDate = true) {
    let value = $event.value
      ? $event.value
      : moment(
          this.formGroupControl.get(startDate ? "startDate" : "finishDate")
            .value
        );
    let temp: moment.Moment = value.set("seconds", 0);

    $event.value = temp;

    if (startDate) {
      this.defaultFinishDate = [temp.toDate().getHours(), 0, 0];
    } else {
      this.defaultStartDate = [temp.toDate().getHours(), 0, 0];
    }

    return $event;
  }

  /**
   * Comprueba los días que se deben deshabilitar del calendario
   *
   * @param d fecha en formato objeto momentjs
   * @returns
   */
  getDisabledDays(d: any | null): boolean {
    let result = true;
    d = moment(d);

    if (d.day() == 0 || d.day() == 6) {
      result = disableWeekEnd(d, this.currentEmployee.workCalendar);
    } else {
      result = disableDay(d, this.disabledDays);
    }

    return result;
  }

  getAbsenceState(state: string) {
    let currentState = null;

    for (let state of this.itLeaveStates) {
      if (state.absenceStateId == this.formGroupControl.get("state").value) {
        currentState = state;
        break;
      }
    }

    this.showReason = currentState.stateCode == AbsenceStateCode[state];
  }

  /**
   * Cambia el valor del flag que se utiliza para determinar el valor del parámetro de fecha final
   *
   * @param event evento provocado por un checkbox
   */
  changeExpectedFinishDate(event) {
    this.inputExpectedFinishDate = event.checked;
  }

  /**
   * Devuelve la etiqueta que se tiene que mostrar en el input de fecha final
   *
   * @returns string
   */
  getDatePickerInputLabels() {
    if (this.inputExpectedFinishDate) {
      this.translate
        .get("ABSENCE_MAINTENANCE.FORM.LABEL.EXPECTED_END_DATE")
        .subscribe((translation: string) => {
          this.datePickerInputLabels = { endDate: translation };
        });
    } else {
      this.datePickerInputLabels = null;
    }

    return this.datePickerInputLabels;
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  createNoticeMessage() {
    let toastTitle = "";
    let toastBody = "";

    this.translate
      .get("ABSENCE_MAINTENANCE.POPUPS.MEDICAL_LEAVES_TITLE")
      .subscribe((translation: string) => {
        toastTitle = translation;
      });
    this.translate
      .get("ABSENCE_MAINTENANCE.POPUPS.MEDICAL_LEAVES_MSG1")
      .subscribe((translation: string) => {
        toastBody = translation;
      });
    this.translate
      .get("ABSENCE_MAINTENANCE.POPUPS.MEDICAL_LEAVES_MSG2")
      .subscribe((translation: string) => {
        toastBody += `<br><br> ${translation}`;
      });

    this.toastrService.warning(toastBody, toastTitle, {
      positionClass: "toast-center-center",
      closeButton: true,
      tapToDismiss: false,
      disableTimeOut: true,
    });
  }

  createSuccessMessage(absence: Absence, timeMSG = 3000) {
    this.translate
      .get("ABSENCE_MAINTENANCE.NAME")
      .subscribe((componentTranslated: string) => {
        let toastTitle = "";
        let toastBody = "";
        this.translate.get("MESSAGES.SUCCESS.UPDATE").subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get("MESSAGES.SUCCESS.UPDATE_LONG", {
            type: componentTranslated,
            code: absence.description,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: timeMSG });
      });
  }

  createErrorMessage(error, timeMSG = 3000) {
    let errorOverlappingDates = false;
    let dateFormat = "DD-MM-YYYY";

    let title = "MESSAGES.ERROR.CREATE_CONFLICT";
    let message = "MESSAGES.ERROR.CREATE_CONFLITCT_BODY";

    if (error.error.type == "OVERLAPPING_DATES") {
      errorOverlappingDates = true;

      title = `MESSAGES.ERROR.${error.error.type}`;
      message = `MESSAGES.ERROR.${error.error.type}_BODY`;
    } else if (error.error.type == "error") {
      title = "MESSAGES.ERROR.UNEXPECTED";
      message = "MESSAGES.ERROR.UNEXPECTED_BODY";
    }

    this.translate.get(title).subscribe((transitionStr: string) => {
      title = transitionStr;
    });

    if (errorOverlappingDates) {
      this.translate
        .get(message, {
          absenceDates: getArrayDateString(
            error.error.absencesOverlappingDates,
            dateFormat
          ),
          holidaysDates: getArrayDateString(
            error.error.holidaysRequestOverlappingDates,
            dateFormat
          ),
        })
        .subscribe((transitionStr: string) => {
          message = transitionStr;
        });
    } else {
      this.translate.get(message).subscribe((transitionStr: string) => {
        message = transitionStr;
      });
    }

    this.toastrService.error(message, title, { timeOut: timeMSG });
  }
}
