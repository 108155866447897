import { AttrPatchChangesJson } from "../interfaces/attr-patch-changes-json.interface";
import { AttributeChange } from "./attribute-chage.model";
import { DCRState } from "./dcr-state.model";
import { Employee } from "./employee.model";

export class DataChangeRequest {
  dataChangeRequestId   : string;
  reason                : string;
  attrPatchChangesJson  : string;
  attributeChanges      : AttributeChange[];
  createdDate           : string;

  //Relations
  employeeId            : string;
  employee              : Employee;

  dcrStateId            : string;
  state                 : DCRState;

  constructor(obj?: Partial<DataChangeRequest>) {
    this.dataChangeRequestId  = (obj && obj.dataChangeRequestId) || undefined;
    this.reason               = (obj && obj.reason) || undefined;
    this.attrPatchChangesJson = (obj && obj.attrPatchChangesJson) || undefined;
    this.createdDate          = (obj && obj.createdDate) || undefined;

    //Relations
    this.employeeId           = (obj && obj.employeeId) || undefined;
    this.employee             = (obj && obj.employee) || undefined;

    this.dcrStateId           = (obj && obj.dcrStateId) || undefined;
    this.state                = (obj && obj.state) || undefined;
  }
}
