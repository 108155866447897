import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { EmployeeContract } from '../models/employee-contract.model';

@Injectable({ providedIn: 'root' })
export class EmployeeContractService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private employeeContractSubject: ReplaySubject<EmployeeContract[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public employeeContractObservable: Observable<EmployeeContract[]> = this.employeeContractSubject.asObservable();

  private employeeContractRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public employeeContractRouteObservable: Observable<string> = this.employeeContractRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/employeeContract';
  }

  constructor(private http: HttpClient) {
  }

  getAllEmployeeContracts(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getEmployeeContract(employeeContractId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/${employeeContractId}${filter}`;

    return this.http.get<EmployeeContract>(url);
  }

  getEmployeeContractsForEmployee(employeeId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/employee/${employeeId}${filter}`;

    return this.http.get<any>(url);
  }

  createEmployeeContract(employeeContract: EmployeeContract) {
    const url = `${this.serviceUrl}`;

    return this.http.post<EmployeeContract>(url, employeeContract);
  }

  deleteEmployeeContract(employeeContractId: string) {
    const url = `${this.serviceUrl}/${employeeContractId}`;

    return this.http.delete(url);
  }

  modifyEmployeeContract(employeeContractId: string, employeeContract: any) {
    const url = `${this.serviceUrl}/${employeeContractId}`;

    return this.http.patch(url, employeeContract);
  }

  updateEmployeeContracts(employeeContracts: any) {
    this.employeeContractSubject.next(employeeContracts);
  }

  updateEmployeeContractRoutes(id: string) {
    this.employeeContractRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
