import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { TCRState } from '../models/tcr-state.model';


@Injectable({ providedIn: 'root' })
export class TCRStateService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private tcrStateRequestSubject: ReplaySubject<TCRState[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public tcrStateRequestObservable: Observable<TCRState[]> = this.tcrStateRequestSubject.asObservable();

    private tcrStateRequestRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public tcrStateRequestRouteObservable: Observable<string> = this.tcrStateRequestRouteSubject.asObservable();

    get serviceUrl() {
        return `${Config.apiBaseUrl}/TCRStates`;
    }

    constructor(private http: HttpClient) {
    }

    getAllTCRStateRequest() {
        let url = `${this.serviceUrl}`;

        return this.http.get<any>(url);
    }

    getTCRStateRequest(tcrStateRequestId: string, related?: boolean) {
        let url = `${this.serviceUrl}}/${tcrStateRequestId}`;

        if(related != undefined && related != null){
          url += `?related=${related}`;
        }

        return this.http.get<TCRState>(url);
    }

    updateTCRStateRequests(tcrStateRequests: TCRState[]) {
        this.tcrStateRequestSubject.next(tcrStateRequests);
    }

    updateTCRStateRequestRoutes(id: string) {
        this.tcrStateRequestRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}