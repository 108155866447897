import { Component, ViewChild, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatAutocompleteSelectedEvent, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { transformDate } from 'src/app/helpers/date.helper';
import { Employee } from 'src/app/models/employee.model';
import { Subscription } from 'rxjs';
import { getDateTimeString } from 'src/app/helpers/date.helper';
import { EmployeeManagerHistory } from 'src/app/models/employee-manager-history.model';
import { DataHistoryService } from '../../../services/data-history.service';
import { EmployeeManagerHistoryService } from 'src/app/services/employee-manager-history.service';
import { EmployeeManagementService} from 'src/app/services/employee-management.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as Rxjs from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';

import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';

import { DataChangesService } from '../../../services/data-changes.service';
@Component({
  selector: 'app-manager-history-list',
  templateUrl: './employee-manager-history-list.component.html',
  styleUrls: [
    './employee-manager-history-list.component.css',
    '../../../../../../assets/styles/action.components.css',
    '../../../../../../assets/styles/action-list.component.css',
  ]
})
export class EmployeeManagerHistoryListComponent implements OnInit, OnDestroy {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  @Output() onAreaReloadNeeded: EventEmitter<string> = new EventEmitter();

  showAddPanel = false;
  employeeView = false;
  showAddDeletefuncition=false;

  employee: Employee = null;
  employees: Employee[] = [];
  allManagers: Employee[] = [];
  allManagersSearch: Employee[] = [];

  managers: EmployeeManagerHistory[] = [];

  displayedColumns: string[] = [
    'actions',
    'startDate',
    'manager',
  ];

  managerHistoryFormGroup: FormGroup;

  startDateFilter: string;
  finishDateFilter: string;

  //ELEMENT_DATA: EmployeeAreaHistory[] = [];
  dataSource: MatTableDataSource<EmployeeManagerHistory>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  subscriptions: Subscription[] = [];

  roles: Role[];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  };

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private translate: TranslateService,
    private dataHistoryService: DataHistoryService,
    private employeeManagerHistoryService: EmployeeManagerHistoryService,
    private employeeManagementService:EmployeeManagementService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private RNService: ResponseNotificationService,
    private dataChangesService:DataChangesService,
    protected permissionsService: PermissionsService,
  ) {
    this.managerHistoryFormGroup = new FormGroup({
      employeeId: new FormControl('', Validators.required),
      managerId: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
    });
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    //EmployeeView
    this.subscriptions.push(
      this.dataHistoryService.employeeView.subscribe(data => {
        this.employeeView = data;
      })
    );

    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;
        // acciones después de cargar permisos
        if(this.userRolesPermissions.isRRHH){
          this.showAddDeletefuncition=true;
        }
      })
    );

    // roles
    this.subscriptions.push(
      this.permissionsService.roles.subscribe(data => {
        this.roles = data;
      })
    );

    // Employee
    this.subscriptions.push(
      this.dataHistoryService.employee.subscribe(data => {
        this.employee = data;

        this.managerHistoryFormGroup.get('employeeId').setValue(this.employee.employeeId);

        this.loadDataOnTable();
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  /* ################################################################################################################## */
  /* ## DATA METHODS
  /* ################################################################################################################## */

  onGetAllManagers() {
    this.showAddPanel = true;
    this.employeeManagerHistoryService.getAllEmployeesManagers().subscribe(query => {
      this.allManagers = query.items;
      this.allManagersSearch=this.allManagers;
    }, error => {
      this.RNService.showError(error);
    });

  }

  searchManagers(event) {
    var listaux: Employee[] = []
    var valor: string = event.target.value;

    if (valor != "" && valor != undefined) {
      for (let val of this.allManagers) {
        if(val.name!=undefined && val.firstSurname!=undefined && val.secondSurname!=undefined){
          if (val.name.toLowerCase().includes(valor.toLowerCase()) || val.firstSurname.toLowerCase().includes(valor.toLowerCase()) || val.secondSurname.toLowerCase().includes(valor.toLowerCase())) {
            listaux.push(val);
          }
        }

      }
      this.allManagersSearch = listaux;
    }
    else {
      this.allManagersSearch = this.allManagers;
    }
  }
  onCreate() {
    if (this.managerHistoryFormGroup && this.managerHistoryFormGroup.valid) {

      this.spinner.show();
      const employeeHistory: EmployeeManagerHistory = new EmployeeManagerHistory();

      //this.onStartDateSelect(this.managerHistoryFormGroup.get('startDate').value);
      let dateaux = new Date(this.managerHistoryFormGroup.get('startDate').value);
      dateaux.setUTCDate(dateaux.getUTCDate() + 1);
      dateaux.setUTCHours(0, 0, 0, 0,);

      employeeHistory.startDate = dateaux;
      employeeHistory.managerId = this.managerHistoryFormGroup.get('managerId').value;
      employeeHistory.employeeId = this.managerHistoryFormGroup.get('employeeId').value;

      if(employeeHistory.startDate!=null && employeeHistory.startDate!=undefined && employeeHistory.managerId!=null &&  employeeHistory.managerId!=undefined){

        this.employeeManagerHistoryService.createEmployeeManagerHistory(employeeHistory).subscribe(
          response => {
            this.spinner.hide();
            if (response != null) {

              this.translate.get('MANAGERHISTORY_MAINTENANCE.FORM.CREATION_SUCCESS').subscribe((text: string) => {
                this.toastrService.success(text, '', { timeOut: 3000 });
              });

              this.showAddPanel = false;
              this.managerHistoryFormGroup.reset();

              this.getAndLoadDataOnTable();
            }
          },
          error => {
            console.log('error --> ', error);
            this.spinner.hide();
            if (error.error === 'MISSING_PARAMS') {
              this.translate.get('MANAGERHISTORY_MAINTENANCE.FORM.ERROR.MISSING_PARAMS').subscribe((text: string) => {
                this.toastrService.error(text, '', { timeOut: 3000 });
              });
            } else {
              this.translate.get('MANAGERHISTORY_MAINTENANCE.FORM.ERROR.UNEXPECTED').subscribe((text: string) => {
                this.toastrService.error(text, '', { timeOut: 3000 });
              });
            }
          }
        );
      }
      else
      {
        this.spinner.hide();
        this.translate.get('MISSING_PARAMS').subscribe((text: string) => {
          this.toastrService.error(text, '', { timeOut: 3000 });
        });
      }
    }
  }

  onRemove(employeeManagerHistory: EmployeeManagerHistory) {
    let text;
    this.translate.get('MANAGERHISTORY_MAINTENANCE.MESSAGE.DELETE_CONFIRMATION.TEXT').subscribe((transitionStr: string) => {
      text = transitionStr;
    });

    //se comprueba que al menos haya un responsable asignado ya que es obligatorio que un empleado tenga al menos un responsable
    if(this.managers.length >1)
    {
      const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        width: '550px',
        data: {
          text
        }
      });

      confirmationDialog.afterClosed().subscribe((confirm) => {

        if (confirm) {
          this.spinner.show();
          this.employeeManagerHistoryService.deleteEmployeeManagerHistory(employeeManagerHistory.employeeManagerHistoryId).subscribe(
            response => {
              this.spinner.hide();
              this.translate.get('MANAGERHISTORY_MAINTENANCE.FORM.DELETION_SUCCESS').subscribe((text: string) => {
                this.toastrService.success(text, '', { timeOut: 3000 });
              });

              this.getAndLoadDataOnTable();
            },
            error => {
              console.log('error --> ', error);
              this.spinner.hide();
              if (error.error === 'MISSING_PARAMS') {
                this.translate.get('MANAGERHISTORY_MAINTENANCE.FORM.ERROR.MISSING_PARAMS').subscribe((text: string) => {
                  this.toastrService.error(text, '', { timeOut: 3000 });
                });
              } else {
                this.translate.get('MANAGERHISTORY_MAINTENANCE.FORM.ERROR.UNEXPECTED').subscribe((text: string) => {
                  this.toastrService.error(text, '', { timeOut: 3000 });
                });
              }
            }
          );
        }
      });
    }
    else {
      this.translate.get('MANAGERHISTORY_MAINTENANCE.FORM.ERROR.MANDATORY_MANAGER').subscribe((text: string) => {
        this.toastrService.error(text, '', { timeOut: 3000 });
      });
    }
  }

  loadDataOnTable() {
    if (this.employee != undefined && this.employee != null) {
      // Se busca el valor actual
      this.assigCurrentManager();

      this.managers = this.employee.managerHistory;
      this.managers = _.orderBy(this.managers, a => moment(a.startDate).unix(), 'desc');
      this.dataSource = new MatTableDataSource<EmployeeManagerHistory>(this.managers);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  getAndLoadDataOnTable() {
    let filters: string = "";
    this.spinner.show();
    this.employeeManagerHistoryService.getAllEmployeeManagerHistorybyEmployeeId(this.employee.employeeId).subscribe(query => {
      this.employee.managerHistory = (query && query.items) || [];

      this.loadDataOnTable();

      // Se propaga el cambio a los observadores
      this.dataHistoryService.setEmployeeChange(this.employee);

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.RNService.showError(error);
    });
  }

  loadDataEmployee(employeeId)
  {
    this.employeeManagementService.getManagementInfo(employeeId).subscribe(query => {
      this.dataChangesService.setEmployeeManagementInfoChange(query);
    }, error => {
      this.spinner.hide();
      this.RNService.showError(error);
    });
  }

  /* ################################################################################################################## */
  /* ## AUTOCOMPLETE METHODS
  /* ################################################################################################################## */

  managerDisplayFn = (employeeId?: string) => {
    if (this.allManagers) {
      let item = this.allManagers.find(x => x.employeeId === employeeId);
      return item.name + " " + item.firstSurname + " " + item.secondSurname;
    }
  }

  managerSelectedOptionChanged(e: MatAutocompleteSelectedEvent) {
    const selectedmanagerId: string = e.option.value;

    const selectedmanager = this.allManagers.find(x => x.employeeId === selectedmanagerId);
    // if(!_.isNil(selectedmanager)) {
    //   this.areaHistoryFormGroup.get('employeeId').setValue(selectedmanager.employeeId);
    // } else {
    //   this.areaHistoryFormGroup.get('employeeId').setValue('');
    // }
  }

  onStartDateSelect(date: any): void {
    this.managerHistoryFormGroup.get('startDate').setValue(moment(date).format('DD/MM/YYYY'));
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  assigCurrentManager() {
    // Se busca el valor actual
    const currentManager = _(this.employee.managerHistory).filter(x => moment(x.startDate) <= moment()).maxBy(x => moment(x.startDate).unix());

    if (currentManager) {
      this.employee.manager = currentManager.manager;
      this.employee.managerId = currentManager.managerId;
    }
  }

  isCurrentValue(item): boolean {
    const currentManager = _(this.employee.managerHistory).filter(x => moment(x.startDate) <= moment()).maxBy(x => moment(x.startDate).unix());
    return (currentManager)
      ? currentManager.managerId == item.managerId
      : false;
  }

  getCreatedBy(employeeId) {
    let employee = this.employees.find(element => element.employeeId == employeeId);
    let result = (employee != null)
      ? `${employee.secondSurname} ${employee.firstSurname}, ${employee.name}`
      : "";

    return result;
  }

  getDate(date: any, format: string): string {
    let result = getDateTimeString(date, format);

    return result != "Invalid date"
      ? result
      : "";
  }

  canAdd(): boolean {
    return (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH || this.userRolesPermissions.isRA);
  }

  canDelete(): boolean {
    let result = (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH || this.userRolesPermissions.isRA);

    result = result && this.employee.managerHistory.length > 1;

    return result;
  }
}
