import { ContractType } from "./contract-type.model";
import { Employee } from "./employee.model";
import { EmployeeType } from "./employee-type.model";
import { LaborAgreement } from "./labor-agreement.model";
import { Category } from "./category.model";
import { Company } from "./company.model";

export class EmployeeContract {
  employeeContractId  : string;
  startDate           : string;
  finishDate          : string | null;

  //Relations
  companyId           : string;
  company             : Company;

  contractTypeId      : string;
  contractType        : ContractType;

  employeeId          : string;
  employee            : Employee;

  employeeTypeId      : string;
  employeeType        : EmployeeType;

  laborAgreementId    : string;
  laborAgreement      : LaborAgreement;

  constructor(obj?: Partial<EmployeeContract>) {
    Object.assign(this, obj);
  }
}
