import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { Headquarters } from '../models/headquarters.model';


@Injectable({ providedIn: 'root' })
export class HeadquartersService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private headquartersSubject: ReplaySubject<Headquarters[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public headquartersObservable: Observable<Headquarters[]> = this.headquartersSubject.asObservable();

    private headquartersRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public headquartersRouteObservable: Observable<string> = this.headquartersRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/headquarters';
    }

    constructor(private http: HttpClient) {
    }

    getAllHeadquarters() {
        return this.http.get<any>(`${this.serviceUrl}`);
    }

    getHeadquarter(headquartersId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<Headquarters>(`${this.serviceUrl}/${headquartersId}${relatedFilter}`);
    }

    createHeadquarter(headquarter: Headquarters) {
        const endpointUrl = `${this.serviceUrl}`;
        return this.http.post(endpointUrl, headquarter);
    }
    modifyHeadquarter(headquarterId: string, headquarter: any) {
        const endpointUrl = `${this.serviceUrl}/${headquarterId}`;
        console.log(headquarter);
        return this.http.patch(endpointUrl, headquarter);
    }
    deleteHeadquarter(headquarterId: string) {
        const endpointUrl = `${this.serviceUrl}/${headquarterId}`;
        return this.http.delete(endpointUrl);
    }

    updateHeadquarters(headquarters: Headquarters[]) {
        this.headquartersSubject.next(headquarters);
    }

    updateHeadquartersRoutes(id: string) {
        this.headquartersRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}
