import { Injectable } from '@angular/core';
import moment = require('moment');
import { BehaviorSubject } from 'rxjs';
import { getDateString } from '../helpers/date.helper';

export interface DatesChanges {
  dataStartDate: string,
  dataFinishDate: string
}

@Injectable({
  providedIn: 'root'
})
export class HRADateFilterService {

  firstDate = getDateString(moment().startOf('year').toDate());
  lastDate = getDateString(moment().endOf('year').toDate());

  private checkDates = new BehaviorSubject<DatesChanges>({
    dataStartDate: this.firstDate,
    dataFinishDate: this.lastDate
  });

  public dates = this.checkDates.asObservable();

  constructor() { }

  onChangeDates(dataStartDate: string, dataFinishDate: string) {
    this.checkDates.next({dataStartDate,dataFinishDate});
  }
}
