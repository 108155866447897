import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, Hotkeys, SecurityStateManagementService } from 'inzo-portalempleado';
import { TCRListComponent } from '../tcr-list/tcr-list.component';

@Component({
  selector: 'app-tcr-list-employee',
  templateUrl: './tcr-list-employee.component.html',
  styleUrls: [
    './tcr-list-employee.component.css',
    '../tcr-list/tcr-list.component.css',
    '../../action.components.css',
    '../../action-list.component.css'
  ],
})
export class TCRListEmployeeComponent extends TCRListComponent {

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public SSMService: SecurityStateManagementService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
  ) {
    super(router, route, SSMService, accountService, hotkeys);
  }
}
