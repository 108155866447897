export class Category {
  categoryId                : string;
  careerPlan                : string;
  categoryShort             : string;
  categoryCode              : string;
  quotationGroupEquivalence : string;

  //Relations

  constructor(obj?: Partial<Category>) {
    Object.assign(this, obj);
  }
}
