import { EmployeeDocument } from "./employee-document.model";
import { Employee } from "./employee.model";

export class Payroll extends EmployeeDocument {
  payrollDate : string;
  employeeId  : string;

  //Relations
  employee    : Employee;

  constructor(obj?: Partial<Payroll>) {
    super();

    this.payrollDate  = (obj && obj.payrollDate) || undefined;
    this.employeeId   = (obj && obj.employeeId) || undefined;

    //Relations
    this.employee     = (obj && obj.employee) || undefined;
  }
}
