import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { WCEvent } from '../models/wc-event.model';


@Injectable({ providedIn: 'root' })
export class WCEventService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private wcEventSubject: ReplaySubject<WCEvent[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public wcEventObservable: Observable<WCEvent[]> = this.wcEventSubject.asObservable();

    private wcEventRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public wcEventRouteObservable: Observable<string> = this.wcEventRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/WCEvent';
    }

    constructor(private http: HttpClient) {
    }

    getAllWCEvent() {
        return this.http.get<any>(`${this.serviceUrl}`);
    }

    getWCEvent(wcEventId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<WCEvent>(`${this.serviceUrl}/${wcEventId}${relatedFilter}`);
    }

    createWCEvent(wcEvent: WCEvent) {
        const endpointUrl = `${this.serviceUrl}`;
        return this.http.post(endpointUrl, wcEvent);
    }
    modifyWCEvent(wcEventId: string, wcEvent: any) {
        const endpointUrl = `${this.serviceUrl}/${wcEventId}`;
        return this.http.patch(endpointUrl, wcEvent);
    }
    deleteWCEvent(wcEventId: string) {
        const endpointUrl = `${this.serviceUrl}/${wcEventId}`;
        return this.http.delete(endpointUrl);
    }

    updateWCEvents(wcEvents: WCEvent[]) {
        this.wcEventSubject.next(wcEvents);
    }

    updateWCEventRoutes(id: string) {
        this.wcEventRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}