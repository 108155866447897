import { ContractDurationType } from "./contract-duration-type.model";
import { ContractWorkingHoursType } from "./contract-working-hours-type.model";

export class ContractType {
  contractTypeId              : string;
  governmentContractCode      : string;
  description                 : string;
  contractCode                : string;

  //Relations
  contractDurationTypeId      : string;
  contractDurationType        : ContractDurationType;

  contractWorkingHoursTypeId  : string;
  contractWorkingHoursType    : ContractWorkingHoursType;

  constructor(obj?: Partial<ContractType>) {
    this.contractTypeId             = (obj && obj.contractTypeId) || undefined;
    this.governmentContractCode     = (obj && obj.governmentContractCode) || undefined;
    this.description                = (obj && obj.description) || undefined;
    this.contractCode               = (obj && obj.contractCode) || undefined;

    //Relations
    this.contractDurationTypeId     = (obj && obj.contractDurationTypeId) || undefined;
    this.contractDurationType       = (obj && obj.contractDurationType) || undefined;

    this.contractWorkingHoursTypeId = (obj && obj.contractWorkingHoursTypeId) || undefined;
    this.contractWorkingHoursType   = (obj && obj.contractWorkingHoursType) || undefined;
  }
}
