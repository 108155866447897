import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { DCRHistory } from '../models/dcr-history.model';

@Injectable({ providedIn: 'root' })
export class DCRHistoryService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private dcrHistoryRequestSubject: ReplaySubject<DCRHistory[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public dcrHistoryRequestObservable: Observable<DCRHistory[]> = this.dcrHistoryRequestSubject.asObservable();

  private dcrHistoryRequestRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public dcrHistoryRequestRouteObservable: Observable<string> = this.dcrHistoryRequestRouteSubject.asObservable();

  get serviceUrl() {
    return `${Config.apiBaseUrl}/DCRHistory`;
  }

  constructor(private http: HttpClient) {
  }

  getAllDCRHistory(filters?: string) {
    let filter = filters ? "&" + filters : "";

    let url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getAllDCRHistoryByEmployee(employeeId: string, filters?: string) {
    let url = `${this.serviceUrl}?employeeid=${employeeId}`;

    if (filters != undefined && filters != null) {
      url = `${this.serviceUrl}?employeeid=${employeeId}&${filters}`;
    }

    return this.http.get<any>(url);
  }
}
