import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DataChangeRequestService } from 'src/app/services/data-change-request.service';

@Component({
  selector: 'app-atcion-dcr',
  templateUrl: './dcr.component.html',
  styleUrls: ['./../action.components.css'],
})
export class DcrComponent implements AfterViewInit, OnInit {
  showForm = false;
  employeeView = false;

  constructor(protected dataChangeRequestService: DataChangeRequestService) {
  }
  ngAfterViewInit() {
    this.dataChangeRequestService.dataChangeRequestRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
  ngOnInit() {
  }
}

