import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  Link,
  AccountService,
  Role,
} from 'inzo-portalempleado';
import {
  AppPermissions
} from '../models/app-permission.model';
import {
  Subscription
} from 'rxjs';
import {
  NotificationMiddlewareService
} from '../services/notification-middleware.service';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, OnDestroy {
  roles: Role[];
  static className = 'LayoutComponent';
  links: Link[] = [{
    header: {
      title: 'PortalEmpleado',
      imageLink: 'assets/img/kiomIco.png',
    },
  }];

  dashboard: Link = {
    title: 'SIDENAV.LINK_10.GROUP_TEXT',
    icon: 'dashboard',
    link: '/dashboard',
  }

  personalData: Link = {
    title: 'SIDENAV.LINK_9.GROUP_TEXT',
    icon: 'person',
    link: '/personal_data',
  }

  maintenances: Link = {
    title: 'SIDENAV.LINK_4.GROUP_TEXT',
    icon: 'settings',
    sublinks: [],
  };

  managements: Link = {
    title: 'SIDENAV.LINK_5.GROUP_TEXT',
    icon: 'widgets',
    sublinks: [],
  };

  formations: Link = {
    title: 'SIDENAV.LINK_6.GROUP_TEXT',
    icon: 'book',
    sublinks: [],
  };

  actions: Link = {
    title: 'SIDENAV.LINK_7.GROUP_TEXT',
    icon: 'account_circle',
    sublinks: [],
  };

  documents: Link = {
    title: 'SIDENAV.LINK_8.GROUP_TEXT',
    icon: 'dashboard',
    sublinks: [],
  };

  userPermissionsLoadedSubscription: Subscription;
  userRolesPermissions: IUserRolesPermissions;

  constructor(
    private accountService: AccountService,
    private notificationMiddleware: NotificationMiddlewareService,
    protected permissionsService: PermissionsService,
  ) {

  }

  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;
          this.userRolesPermissions = {
            isAdmin             : this.accountService.currentUser.isAdmin,
            isRRHH              : this.roles.some((x) => x.name === AppPermissions.ROLE_DATA.RRHH),
            isRA                : this.roles.some((x) => x.name === AppPermissions.ROLE_DATA.RA),
            isRP                : this.roles.some((x) => x.name === AppPermissions.ROLE_DATA.RP),
            isEmployee          : this.roles.some((x) => x.name === AppPermissions.ROLE_DATA.Empleado),
            isHolidaysValidator : this.accountService.userHasPermission(AppPermissions.PERMISSIONS_DATA.validateHolidaysRequests.value),
            isAbsenceValidator  : this.accountService.userHasPermission(AppPermissions.PERMISSIONS_DATA.validateAbsences.value),
          };

          // Servicio para gestionar los permisos de las vistas
          this.permissionsService.setRoles(this.accountService.roles);
          this.permissionsService.setPermissions(this.userRolesPermissions);

          if (
            this.accountService.currentUser.isAdmin ||
            this.userRolesPermissions.isRRHH ||
            this.userRolesPermissions.isRA ||
            this.userRolesPermissions.isRP ||
            this.userRolesPermissions.isEmployee
          ) {
            if (
              this.accountService.currentUser.isAdmin ||
              this.userRolesPermissions.isRRHH ||
              this.userRolesPermissions.isRA ||
              this.userRolesPermissions.isRP
            ) {
            }

            // this.updateDashboardLinks();
            this.updatePersonalDataLinks();
            this.updateActionLinks();

            this.updateDocumentsLinks();

            if (
              this.accountService.currentUser.isAdmin ||
              this.userRolesPermissions.isRRHH ||
              this.userRolesPermissions.isRA ||
              this.userRolesPermissions.isRP ||
              this.userRolesPermissions.isHolidaysValidator ||
              this.userRolesPermissions.isAbsenceValidator
            ) {
              this.updateManagementsLinks();
            }

            if (
              this.accountService.currentUser.isAdmin ||
              this.userRolesPermissions.isRRHH
            ) {
              // this.updateFormationsLinks();
              this.updateMaintenanceLinks();
            }
          }
        });
      });
    }
  }

  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }
  ngAfterViewChecked() {

  }
  ngAfterViewInit() {
    this.notificationMiddleware.init();
  }
  ngOnDestroy() {
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }

  // DASHBOARD
  private updateDashboardLinks() {
    if (
      this.accountService.currentUser.isAdmin ||
      this.userRolesPermissions.isRRHH ||
      this.userRolesPermissions.isRA ||
      this.userRolesPermissions.isRP
    ) {
      let index = this.links.indexOf(this.dashboard);
      if (index > -1) {
        this.links.splice(index, 1);
      }
      this.links.push(this.dashboard);
    }
  }

  // PERSONAL DATA
  private updatePersonalDataLinks() {
    let index = this.links.indexOf(this.personalData);
    if (index > -1) {
      this.links.splice(index, 1);
    }
    this.links.push(this.personalData);
  }

  // MAINTENANCES
  private updateMaintenanceLinks() {
    this.maintenances.sublinks = [];

    if (
      this.accountService.currentUser.isAdmin ||
      this.userRolesPermissions.isRRHH
    ) {
      this.maintenances.sublinks.push({
        title: 'SIDENAV.LINK_2.SUBLINK_10_TEXT',
        icon: 'event',
        link: '/maintenances/work_calendar',
      });
      this.maintenances.sublinks.push({
        title: 'SIDENAV.LINK_2.SUBLINK_2_TEXT',
        icon: 'computer',
        link: '/core/security/users',
      });
      if (this.accountService.currentUser.isAdmin) {
        this.maintenances.sublinks.push({
          title: 'SIDENAV.LINK_2.SUBLINK_1_TEXT',
          icon: 'assignment_turned_in',
          link: '/core/security/roles',
        });
      }
      this.maintenances.sublinks.push({
        title: 'SIDENAV.LINK_2.SUBLINK_5_TEXT',
        icon: 'supervised_user_circle',
        link: '/core/security/tenants',
      });
      this.maintenances.sublinks.push({
        title: 'SIDENAV.LINK_2.SUBLINK_6_TEXT',
        icon: 'dashboard',
        link: '/maintenances/area',
      });
      this.maintenances.sublinks.push({
        title: 'SIDENAV.LINK_2.SUBLINK_7_TEXT',
        icon: 'business',
        link: '/maintenances/headquarters',
      });
      this.maintenances.sublinks.push({
        title: 'SIDENAV.LINK_2.SUBLINK_9_TEXT',
        icon: 'business_center',
        link: '/maintenances/centre',
      });
      // this.maintenances.sublinks.push({
      //   title: 'SIDENAV.LINK_2.SUBLINK_11_TEXT',
      //   icon: 'event',
      //   link: '/maintenances/close_year',
      // });
      this.maintenances.sublinks.push({
        title: 'SIDENAV.LINK_2.SUBLINK_14_TEXT',
        icon: 'school',
        link: '/maintenances/training_center',
      });
      this.maintenances.sublinks.push({
        title: 'SIDENAV.LINK_2.SUBLINK_12_TEXT',
        icon: 'local_hospital',
        link: '/maintenances/absence_type',
      });
      this.maintenances.sublinks.push({
        title: 'SIDENAV.LINK_2.SUBLINK_13_TEXT',
        icon: 'school',
        link: '/maintenances/course_type',
      });
      this.maintenances.sublinks.push({
        title: 'SIDENAV.LINK_2.SUBLINK_3_TEXT',
        icon: 'folder_shared',
        link: '/maintenances/employeeType',
      });

      if (this.accountService.currentUser.isAdmin) {
        this.maintenances.sublinks.push({
          title: 'SIDENAV.LINK_2.SUBLINK_15_TEXT',
          icon: '',
          link: '/maintenances/category',
        });
        this.maintenances.sublinks.push({
          title: 'SIDENAV.LINK_2.SUBLINK_16_TEXT',
          icon: '',
          link: '/maintenances/annual_workhours',
        });
        this.maintenances.sublinks.push({
          title: 'SIDENAV.LINK_2.SUBLINK_21_TEXT',
          icon: '',
          link: '/maintenances/prc_contracts',
        });
        this.maintenances.sublinks.push({
          title: 'SIDENAV.LINK_2.SUBLINK_17_TEXT',
          icon: '',
          link: '/maintenances/labor_agreements',
        });
        this.maintenances.sublinks.push({
          title: 'SIDENAV.LINK_2.SUBLINK_18_TEXT',
          icon: '',
          link: '/maintenances/contract_types',
        });
        this.maintenances.sublinks.push({
          title: 'SIDENAV.LINK_2.SUBLINK_19_TEXT',
          icon: '',
          link: '/maintenances/contract_duration_types',
        });
        this.maintenances.sublinks.push({
          title: 'SIDENAV.LINK_2.SUBLINK_20_TEXT',
          icon: '',
          link: '/maintenances/contract_workinghours_types',
        });
      }
    }

    let index = this.links.indexOf(this.maintenances);
    if (index > -1) {
      this.links.splice(index, 1);
    }

    this.links.push(this.maintenances);
  }

  // FORMATIONS
  private updateFormationsLinks() {
    this.formations.sublinks = [];

    if (
      this.accountService.currentUser.isAdmin ||
      this.userRolesPermissions.isRRHH ||
      this.userRolesPermissions.isRA ||
      this.userRolesPermissions.isRP
    ) {
      this.formations.sublinks.push({
        title: 'SIDENAV.LINK_6.SUBLINK_1_TEXT',
        icon: 'school',
        link: '/formations/degree',
      });

      this.formations.sublinks.push({
        title: 'SIDENAV.LINK_6.SUBLINK_2_TEXT',
        icon: 'language',
        link: '/formations/language',
      });

      this.formations.sublinks.push({
        title: 'SIDENAV.LINK_6.SUBLINK_3_TEXT',
        icon: 'sync',
        link: '/formations/course',
      });

      this.formations.sublinks.push({
        title: 'SIDENAV.LINK_6.SUBLINK_4_TEXT',
        icon: 'sync',
        link: '/formations/certificationCourse',
      });

      /*this.formations.sublinks.push({
        title: 'SIDENAV.LINK_6.SUBLINK_5_TEXT',
        icon: 'description',
        link: '/formations/cv',
      });*/
    }

    let index = this.links.indexOf(this.formations);
    if (index > -1) {
      this.links.splice(index, 1);
    }
    this.links.push(this.formations);
  }

  // MANAGEMENTS
  private updateManagementsLinks() {
    this.managements.sublinks = [];

    if(this.accountService.currentUser.isAdmin ||
      this.userRolesPermissions.isRRHH ||
      this.userRolesPermissions.isRA ||
      this.userRolesPermissions.isRP
      ) {
        this.managements.sublinks.push({
          title: 'SIDENAV.LINK_5.SUBLINK_7_TEXT',
          icon: 'group',
          link: '/managements/employeeManagement',
        });
    }

    if(this.accountService.currentUser.isAdmin ||
      this.userRolesPermissions.isRRHH ||
      this.userRolesPermissions.isRA ||
      this.userRolesPermissions.isRP ||
      this.userRolesPermissions.isHolidaysValidator
      ) {
        this.managements.sublinks.push({
          title: 'SIDENAV.LINK_5.SUBLINK_1_TEXT',
          icon: 'map',
          link: '/managements/holiday_request_management',
        });
    }

    //se ten permiso de validacion de ausencias
    if(this.accountService.currentUser.isAdmin ||
      this.userRolesPermissions.isRRHH ||
      this.userRolesPermissions.isRA ||
      this.userRolesPermissions.isRP ||
      this.userRolesPermissions.isAbsenceValidator
    ) {
      this.managements.sublinks.push({
        title: 'SIDENAV.LINK_5.SUBLINK_2_TEXT',
        icon: 'update',
        link: '/managements/absence_management',
      });
    }

    if (
      this.userRolesPermissions.isRRHH
    ) {
      this.managements.sublinks.push({
        title: 'SIDENAV.LINK_5.SUBLINK_6_TEXT',
        icon: 'edit',
        link: '/managements/dataChange',
      });
    }

    if (
      this.accountService.currentUser.isAdmin ||
      this.userRolesPermissions.isRRHH ||
      this.userRolesPermissions.isRA ||
      this.userRolesPermissions.isRP
    ) {
      let title = 'SIDENAV.LINK_5.SUBLINK_3_TEXT';
      if (this.userRolesPermissions.isRRHH) {
        title += '_RRHH';
      }

      this.managements.sublinks.push({
        title: title,
        icon: 'update',
        link: '/managements/it_leave_management',
      });
    }

    let index = this.links.indexOf(this.managements);
    if (index > -1) {
      this.links.splice(index, 1);
    }
    this.links.push(this.managements);
  }

  // ACTIONS
  private updateActionLinks() {
    this.actions.sublinks = [];

    if (
      this.accountService.currentUser.isAdmin ||
      this.userRolesPermissions.isRRHH ||
      this.userRolesPermissions.isRA ||
      this.userRolesPermissions.isRP ||
      this.userRolesPermissions.isEmployee
    ) {
      this.actions.sublinks.push({
        title: 'SIDENAV.LINK_7.SUBLINK_2_TEXT',
        icon: 'map',
        link: '/actions/holiday_request',
      });

      this.actions.sublinks.push({
        title: 'SIDENAV.LINK_7.SUBLINK_3_TEXT',
        icon: 'update',
        link: '/actions/absence',
      });

      // this.actions.sublinks.push({
      //   title: 'SIDENAV.LINK_7.SUBLINK_4_TEXT',
      //   icon: 'local_hospital',
      //   link: '/actions/it_leave',
      // });

      this.actions.sublinks.push({
        title: 'SIDENAV.LINK_7.SUBLINK_5_TEXT',
        icon: 'edit',
        link: '/actions/dataChange',
      });
    }

    let index = this.links.indexOf(this.actions);
    if (index > -1) {
      this.links.splice(index, 1);
    }
    this.links.push(this.actions);
  }

  // DOCUMENTS
  private updateDocumentsLinks() {
    this.documents.sublinks = [];
    if (
      this.accountService.currentUser.isAdmin ||
      this.userRolesPermissions.isRRHH ||
      this.userRolesPermissions.isRA ||
      this.userRolesPermissions.isRP ||
      this.userRolesPermissions.isEmployee
    ) {
      this.documents.sublinks.push({
        title: 'SIDENAV.LINK_8.SUBLINK_4_TEXT',
        icon: 'payment',
        link: '/documents/personalPayrolls',
      });


      this.documents.sublinks.push({
        title: 'SIDENAV.LINK_8.SUBLINK_5_TEXT',
        icon: 'account_balance',
        link: '/documents/personalIRPF',
      });
    }

    let index = this.links.indexOf(this.documents);
    if (index > -1) {
      this.links.splice(index, 1);
    }
    this.links.push(this.documents);
  }
}
