import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

import { AccountService, BaseListComponent, Hotkeys, ResponseNotificationService, Role, SecurityStateManagementService } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';

import { ContractType } from 'src/app/models/contract-type.model';
import { ContractTypeService } from 'src/app/services/contract-type.service';

@Component({
  selector: 'app-contract-type-list',
  templateUrl: './contract-type-list.component.html',
  styleUrls: [
    './contract-type-list.component.css',
    './../../maintenance.components.css',
    '../../maintenance-list.component.css'
  ],
})
export class ContractTypeListComponent extends BaseListComponent<ContractType> implements OnInit, OnDestroy {
  //#region ATRRIBUTES
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  contractTypes: ContractType[] = [];
  subscription: Subscription;
  mediaSize: string;
  watcher: Subscription;

  roles: Role[];

  canAdd = false;

  /* ****************************************************************************************************************** */
  /* ** INHERITED
  /* ****************************************************************************************************************** */
  searching = true;
  filter = '';
  canDelete = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private contractTypeService: ContractTypeService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
  ) {
    super(accountService, SSMService, router, hotkeys);

    this.baseRoute = '/maintenances/contract_types';

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
      this.canView = true;
    }

    this.onBuild = () => {
    };

    this.loadData = this.customLoadData;

    this.onInit = this.init;
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
    this.init();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          if (
            this.accountService.currentUser.isAdmin ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RA) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RP)
          ) {
          }
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  //#region DATA MANIPULATION
  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  protected init() {
      this.loadData();

      this.contractTypeService.contractTypeObservable.subscribe(contractTypes => {
        this.contractTypes = contractTypes;
      });
  }

  protected customLoadData() {
    this.spinner.show();
    this.contractTypeService.getAllContractTypes().subscribe(contractTypes => {
      this.contractTypes = [];

      for(let contractType of contractTypes.items){
        if(contractType.description !== "DEFAULT"){
          this.contractTypes.push(contractType);
        }
      }

      this.contractTypeService.updateContractTypes(this.contractTypes);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.RNService.showError(error);
    });
  }
  //#endregion
}
