import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { CommonResources } from '../models/common-resources';

export interface ValidationResult {
  isCorrect: boolean;
  errorMessage: string;
}

@Injectable({ providedIn: 'root' })
export class IdentificationDocumentValidator {
  static DNIValidator(dni: string) {
    const possibleLetters = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const result: ValidationResult = { isCorrect: true, errorMessage: '' };

    if (new RegExp(CommonResources.NIF_PATTERN).test(dni) === true && dni.length === 9) {
      let dniNumbers = dni.substring(0, dni.length - 1);
      const letter = possibleLetters.charAt(parseInt(dniNumbers, 10) % 23);
      if (letter === dni.charAt(8)) result.isCorrect = true;
      else {
        result.isCorrect = false;
        result.errorMessage = 'La letra no se corresponde';
      }
    } else {
      result.isCorrect = false;
      result.errorMessage = 'Formato no válido.';
    }

    return result;
  }

  static NIEValidator(nie: string) {
    let test = new RegExp(CommonResources.NIE_PATTERN).test(nie);
    if (test === true && nie.length === 9) {
      let nie_prefix: string | number = nie.charAt(0);

      switch (nie_prefix) {
        case 'X': nie_prefix = 0; break;
        case 'Y': nie_prefix = 1; break;
        case 'Z': nie_prefix = 2; break;
      }

      return this.DNIValidator(nie_prefix + nie.substr(1));
    } else {
      return { isCorrect: false, errorMessage: 'Formato no válido.' };
    }
  }

  static CIFValidator(cif: string) {
    let par = 0;
    let non = 0;
    const letras = 'ABCDEFGHKLMNPQS';
    const letra = cif.charAt(0);

    if (cif.length !== 9) {
      // alert('El Cif debe tener 9 dígitos');
      return false;
    }

    if (letras.indexOf(letra.toUpperCase()) === -1) {
      // alert("El comienzo del Cif no es válido");
      return false;
    }

    for (let zz = 2; zz < 8; zz += 2) {
      par = par + parseInt(cif.charAt(zz), 10);
    }

    for (let zz = 1; zz < 9; zz += 2) {
      let nn = 2 * parseInt(cif.charAt(zz), 10);
      if (nn > 9) nn = 1 + (nn - 10);
      non = non + nn;
    }

    const parcial = par + non;
    let control = (10 - (parcial % 10));
    if (control === 10) control = 0;

    if (control.toString() !== cif.charAt(8)) {
      // alert("El Cif no es válido");
      return false;
    }

    // alert("El Cif es válido");
    return true;
  }
}

export function IdentificationDocumentFormValidator(): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const documentNumber = form.controls["documentNumber"].value;
    const documentType = form.controls["documentType"].value;

    let isValid = false;

    if (documentNumber && documentNumber.length > 0) {
      switch (documentType) { //Tipos descritos en CommonResources.IDENTIFICATION_DOCUMENT_TYPES
        case 'NIF':
          isValid = IdentificationDocumentValidator.DNIValidator(documentNumber.toUpperCase()).isCorrect;
          break;
        case 'NIE':
          isValid = IdentificationDocumentValidator.NIEValidator(documentNumber.toUpperCase()).isCorrect;
          break;
        case 'Pasaporte':
          isValid = true; //todo pending to regex
          break;
        case '':
          isValid = false;
        default:
          isValid = true; //Implementar regex para cada tipo adicional
      }
    }

    (isValid) ? form.controls["documentNumber"].setErrors(null) : form.controls["documentNumber"].setErrors({ invalidDocument: true });

    return null;
  }
}

export function IdentificationDocumentFormValidatorTest(documentType: string, documentNumber: string) {
  return (control: AbstractControl): ValidationErrors | null => {
    let isValid = true;

    if (documentNumber && documentNumber.length > 0) {
      switch (documentType) { //Tipos descritos en CommonResources.IDENTIFICATION_DOCUMENT_TYPES
        case 'DNI':
          isValid = IdentificationDocumentValidator.DNIValidator(documentNumber.toUpperCase()).isCorrect;
          break;
        case 'NIE':
          isValid = IdentificationDocumentValidator.NIEValidator(documentNumber.toUpperCase()).isCorrect;
          break;
        case 'CIF':
          isValid = new RegExp(CommonResources.CIF_PATTERN).test(documentNumber) && documentNumber.length === 9;// TODO use CIFValidator
          break;
        case 'Pasaporte':
          isValid = true; //todo pending to regex
          break;
        default:
          isValid = true; //Implementar regex para cada tipo adicional
      }
    }

    return (isValid) ? null : { invalidDocument: true };
  }
}

export function TestValidator(val: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let v: number = +control.value;

    if (isNaN(v)) {
      return { 'gte': true, 'requiredValue': val }
    }

    if (v <= +val) {
      return { 'gte': true, 'requiredValue': val }
    }

    return null;
  }
}
