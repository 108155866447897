import { Injectable, SecurityContext } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { StorageFolder } from "../models/storage-folder.model";
import { StorageFile } from "../models/storage-file.model";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { DomSanitizer } from "@angular/platform-browser";
import { QueryResult } from "../models/query-result.model";
import { Config } from "inzo-portalempleado";

@Injectable({
  providedIn: "root",
})
export class FileService {
  private list: StorageFolder[] = [];

  list$: BehaviorSubject<StorageFolder[]> = new BehaviorSubject(this.list);
  allowedExtensions: string;
  fileExtensions = ".pdf;.doc;.docx;.odt;.txt;.xlsx";
  imagesExtensions = ".jpg;.png;.jpeg";

  private changeSubject: Subject<any> = new Subject();
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private fileSubject: ReplaySubject<any[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public fileObservable: Observable<any[]> = this.fileSubject.asObservable();

  private fileRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public fileRouteObservable: Observable<string> =
    this.fileRouteSubject.asObservable();

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
  ) { }

  get serviceUrl() {
    return `${Config.apiBaseUrl}/StorageFile`;
  }

  get serviceMgntUrl() {
    return `${this.serviceUrl}/Management`;
  }

  getPayrolls(login: string, extended?: boolean): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}/Directories?path=${login.toUpperCase()}/Payrolls${
        extended ? "&extended=true" : ""
      }`
    );
  }

  getCVs(login: string, extended?: boolean): Observable<any> {
    return this.http.get<any>(
      `${
        this.serviceUrl
      }/Directories?path=${login.toUpperCase()}/Certifications/CVs${
        extended ? "&extended=true" : ""
      }`
    );
  }

  getIRPFs(login: string, extended?: boolean): Observable<any> {
    return this.http.get<any>(
      `${this.serviceUrl}/Directories?path=${login.toUpperCase()}/IRPFs${
        extended ? "&extended=true" : ""
      }`
    );
  }

  getDegrees(login: string, extended?: boolean): Observable<any> {
    return this.http.get<any>(
      `${
        this.serviceUrl
      }/Directories?path=${login.toUpperCase()}/Certifications/Degrees${
        extended ? "&extended=true" : ""
      }`
    );
  }

  getLanguages(login: string, extended?: boolean): Observable<any> {
    return this.http.get<any>(
      `${
        this.serviceUrl
      }/Directories?path=${login.toUpperCase()}/Certifications/Languages${
        extended ? "&extended=true" : ""
      }`
    );
  }

  getCourses(login: string, extended?: boolean): Observable<any> {
    return this.http.get<any>(
      `${
        this.serviceUrl
      }/Directories?path=${login.toUpperCase()}/Certifications/Courses${
        extended ? "&extended=true" : ""
      }`
    );
  }

  getFile(
    fileId: string,
    management: boolean = false,
    height?: number,
    width?: number
  ): Observable<StorageFile> {
    let request = "";
    const url = (management)
      ? this.serviceMgntUrl
      : this.serviceUrl;

    const urlAPI = `${url}/GetFileContent/${fileId}`;

    if (height)
      request += `${request.length > 0 ? "&" : "?"}thumbnailHeight=${height}`;
    if (width)
      request += `${
        request.length > 0 ? "&" : "?"
      }thumbnailWidth=${width}&extended=true`;

    return this.http.get<StorageFile>(urlAPI);
  }

  getFileBlob(ficheroId: string, management: boolean = false): Observable<Blob> {
    const url = (management)
      ? this.serviceMgntUrl
      : this.serviceUrl;

    const urlAPI = `${url}/DownloadFile/${ficheroId}`;

    return this.http.get(urlAPI, { responseType: "blob" }); // Necesita el responseType o dará error de parseo en la respuesta
  }

  updateFileContent(ficheroId: string, file: File, management: boolean = false) {
    const urlAPI = `${this.serviceUrl}/${ficheroId}/Content`;

    return this.http.put<StorageFile>(urlAPI, file);
  }

  openFile(file: StorageFile) {
    const extension = file.extension.toLowerCase().replace(".", "");
    const allowedExtensions = ["pdf", "png", "jpg", "jpeg", "txt"];

    if (!allowedExtensions.includes(extension)) {
      this.toastr.warning('El fichero que intenta abrir no es apto para el navegador.');

      return;
    }

    switch(extension) {
      case "pdf":
        this.openPDF(this.getBlob(file.content, file.contentType));
        break;
      default:
        this.openBase64File(file);
        break;
    }
  }

  openPDF(originalBlob: Blob) {
    let blob = originalBlob;

    if (blob.type != MimeTypes.types["pdf"]) {
      blob = new Blob([originalBlob], { type: MimeTypes.types["pdf"] });
    }

    const objectBlobURL = window.URL.createObjectURL(blob);
    window.open(objectBlobURL);
  }

  openBase64File(file: StorageFile) {
    // https://stackoverflow.com/a/8908315
    const fileEncoded = encodeURI(
      `data:${file.contentType};base64,${file.content as string}`
    );
    const validURL = this.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.sanitizer.bypassSecurityTrustResourceUrl(fileEncoded)
    );
    const image = new Image();
    image.src = validURL;
    const imageContainer = document.createElement("div");
    imageContainer.style.margin = "auto";
    imageContainer.style.width = "-moz-fit-content";
    imageContainer.style.width = "fit-content";
    imageContainer.style.height = "-moz-fit-content";
    imageContainer.style.height = "fit-content";

    imageContainer.appendChild(image) ;

    const newWindow = window.open("");
    newWindow.document.body.appendChild(imageContainer);
  }

  downloadFile(originalBlob: Blob, fileName: string) {
    const blob = new Blob([originalBlob], { type: "application/octet-stream" });
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute("download", fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  downloadBase64File(file: StorageFile) {
    const fileEncoded = encodeURI(
      `data:${file.contentType};base64,${file.content as string}`
    );
    const validURL = this.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.sanitizer.bypassSecurityTrustResourceUrl(fileEncoded)
    );
    const accessLink = document.createElement("a");

    accessLink.download = file.name;
    accessLink.target = "_blank";
    accessLink.setAttribute("title", file.name);
    accessLink.href = validURL;
    document.body.appendChild(accessLink);
    accessLink.click();
  }

  addFile(formData: FormData) {
    const ApiUrl = `${this.serviceUrl}`;
    return this.http.post<StorageFile>(ApiUrl, formData);
  }

  updateFiles(files: any[]) {
    this.fileSubject.next(files);
  }

  deleteFile(deletedFile: StorageFile) {
    this.list.forEach((element) => {
      if (element.files !== undefined) {
        element.files = element.files.filter(
          (x) => x.fileId !== deletedFile.fileId
        );
      }
    });
    this.list$.next(this.list);
  }

  deleteFileFromStorage(fileId: string) {
    const urlAPI = `${this.serviceUrl}/` + fileId;
    return this.http.delete<StorageFile>(urlAPI);
  }

  getBlob(data: string | ArrayBuffer, contentType: string): Blob {
    // https://stackoverflow.com/a/45872086
    // base64 string
    var base64str = data as string;

    // decode base64 string, remove space for IE compatibility
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }

    // create the blob object with contentType
    var blob = new Blob( [view], { type: contentType });

    return blob;
  }
}

export class MimeTypes {
  public static types: any = {
    aac: "audio/aac",
    abw: "application/x-abiword",
    arc: "application/octet-stream",
    avi: "video/x-msvideo",
    azw: "application/vnd.amazon.ebook",
    bin: "application/octet-stream",
    bz: "application/x-bzip",
    bz2: "application/x-bzip2",
    csh: "application/x-csh",
    css: "text/css",
    csv: "text/csv",
    doc: "application/msword",
    epub: "application/epub+zip",
    gif: "image/gif",
    htm: "text/html",
    html: "text/html",
    ico: "image/x-icon",
    ics: "text/calendar",
    jar: "application/java-archive",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    js: "application/javascript",
    json: "application/json",
    mid: "audio/midi",
    midi: "audio/midi",
    mpeg: "video/mpeg",
    mpkg: "application/vnd.apple.installer+xml",
    odp: "application/vnd.oasis.opendocument.presentation",
    ods: "application/vnd.oasis.opendocument.spreadsheet",
    odt: "application/vnd.oasis.opendocument.text",
    oga: "audio/ogg",
    ogv: "video/ogg",
    ogx: "application/ogg",
    pdf: "application/pdf",
    png: "image/png",
    ppt: "application/vnd.ms-powerpoint",
    rar: "application/x-rar-compressed",
    rtf: "application/rtf",
    sh: "application/x-sh",
    svg: "image/svg+xml",
    swf: "application/x-shockwave-flash",
    tar: "application/x-tar",
    tif: "image/tiff",
    tiff: "image/tiff",
    ttf: "font/ttf",
    txt: "text/plain",
    vsd: "application/vnd.visio",
    wav: "audio/x-wav",
    weba: "audio/webm",
    webm: "video/webm",
    webp: "image/webp",
    woff: "font/woff",
    woff2: "font/woff2",
    xhtml: "application/xhtml+xml",
    xls: "application/vnd.ms-excel",
    xml: "application/xml",
    xul: "application/vnd.mozilla.xul+xml",
    zip: "application/zip",
    "3gp": {
      audio: "audio/3gpp",
      video: "video/3gpp",
    },
    "3g2": {
      audio: "audio/3gpp2",
      video: "video/3gpp2",
    },
    "7z": "application/x-7z-compressed",
  };

  public static extensions: object = {
    "application/pdf": "pdf",
    "image/gif": "gif",
    "image/jpeg": "jpg",
    "image/png": "png",
  };
}
