import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

import { AccountService, BaseListComponent, Hotkeys, ResponseNotificationService, Role, SecurityStateManagementService } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';

import { LaborAgreement } from 'src/app/models/labor-agreement.model';
import { LaborAgreementService } from 'src/app/services/labor-agreement.service';

@Component({
  selector: 'app-labor-agreement-list',
  templateUrl: './labor-agreement-list.component.html',
  styleUrls: [
    './labor-agreement-list.component.css',
    './../../maintenance.components.css',
    '../../maintenance-list.component.css'
  ],
})
export class LaborAgreementListComponent extends BaseListComponent<LaborAgreement> implements OnInit, OnDestroy {
  //#region ATRRIBUTES
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  laborAgreements: LaborAgreement[] = [];
  subscription: Subscription;
  mediaSize: string;
  watcher: Subscription;

  roles: Role[];

  canAdd = false;

  /* ****************************************************************************************************************** */
  /* ** INHERITED
  /* ****************************************************************************************************************** */
  searching = true;
  filter = '';
  canDelete = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private laborAgreementService: LaborAgreementService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
  ) {
    super(accountService, SSMService, router, hotkeys);

    this.baseRoute = '/maintenances/labor_agreements';

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
      this.canView = true;
    }

    this.onBuild = () => {
    };

    this.loadData = this.customLoadData;

    this.onInit = this.init;
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  //#region DATA MANIPULATION
  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  protected init() {
      this.loadData();

      this.laborAgreementService.laborAgreementObservable.subscribe(laborAgreements => {
        this.laborAgreements = laborAgreements;
      });
  }

  protected customLoadData() {
    this.spinner.show();
    this.laborAgreementService.getAllLaborAgreements().subscribe(laborAgreements => {
      this.laborAgreements = [];

      for(let laborAgreement of laborAgreements.items){
        if(laborAgreement.name !== "DEFAULT"){
          this.laborAgreements.push(laborAgreement);
        }
      }

      this.laborAgreementService.updateLaborAgreements(this.laborAgreements);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.RNService.showError(error);
    });
  }
  //#endregion
}
