import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Employee } from 'src/app/models/employee.model';
import { HolidayRequestChangesService } from 'src/app/services/holiday-request-changes.service';

@Component({
  selector: 'app-holiday-request-summary-days',
  templateUrl: './holiday-request-summary-days.component.html',
  styleUrls: ['./holiday-request-summary-days.component.css']
})
export class HolidayRequestSummaryDaysComponent implements OnInit {
  @Input() selectedEmployees: Employee[] = [];
  @Input() currentEmployee: Employee;

  @Input() employeeView: boolean = false;
  subscriptions: Subscription[] = [];

  EmployeesFormGroup = new FormGroup({
    employee: new FormControl('', []),
  });

  constructor(
    protected holidayRequestChangesService: HolidayRequestChangesService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  /**
   * Devuelve el número de días de vacaciones sin solicitar.
   */
  getAvailableDays(previusYear: Boolean) {
    let result = 0;

    if (
      this.currentEmployee &&
      this.currentEmployee.totalAvailableHolidays != null &&
      this.currentEmployee.totalPendingHolidaysLastYear != null
    ) {
      let totalDays = previusYear ? this.currentEmployee.totalPendingHolidaysLastYear : this.currentEmployee.totalAvailableHolidays;
      let requestedDays = previusYear ? this.currentEmployee.totalPendingHolidaysLastYearEnjoyed : this.currentEmployee.totalHolidaysEnjoyed;

      result = totalDays - requestedDays;
    }

    return result;
  }

  /**
   * Devuelve el número de puentes sin solicitar.
   */
  getAvailableHolidayWeekend() {
    let result = 0;

    if (
      this.currentEmployee &&
      this.currentEmployee.totalHolidayWeekend != null &&
      this.currentEmployee.holidayWeekendEnjoyed != null
    ) {
      result = this.currentEmployee.totalHolidayWeekend - this.currentEmployee.holidayWeekendEnjoyed;
    }

    return result;
  }

  selectCurrentEmployee(employee: any) {
    this.currentEmployee = employee;
    this.holidayRequestChangesService.onChangeCurrentEmployee(this.currentEmployee);
  }

  displayCurrentEmployee(employee: Employee): string | undefined {
    return (employee) ? `${employee.name} ${employee.firstSurname} ${employee.secondSurname}` : undefined;
  }
}
