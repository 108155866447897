import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Language } from 'src/app/models/language.model';
import { LanguageService } from 'src/app/services/language.service';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';


@Component({
  selector: 'app-personal-language-list',
  templateUrl: 'personal-language-list.component.html',
  styleUrls: ['./../../maintenance.components.css','../../maintenance-list.component.css'],
})
export class PersonalLanguageListComponent extends BaseListComponent<Language> implements OnInit, OnDestroy {
  languages: Language[] = [];
  subscription: Subscription;
  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;

  roles: Role[];

  canAdd = false;
  canDelete = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  constructor(
    private languageService: LanguageService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
    private employeeManagementService: EmployeeManagementService
  ) {
    super(accountService, SSMService, router, hotkeys);
    this.baseRoute = '/documents/personalLanguages';
    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado,
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];

    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ) {
    }

    this.onBuild = () => {
    };

    this.loadData = () => {
      this.employeeManagementService.getEmployeeByUserId(this.accountService.currentUser.id).subscribe(employeeResponse => {

        this.spinner.show();
        this.languageService.getAllLanguagesByEmployee(employeeResponse.employeeId).subscribe(acActividad => {
          this.languageService.updateLanguages(acActividad.items);
          this.languages = acActividad.items;
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          this.RNService.showError(error);
        });
      });
    };

    this.onInit = () => {
      this.loadData();
      this.languageService.languageObservable.subscribe(arrayacActividad => {
        this.languages = arrayacActividad;
      });
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion
}

