import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

import { AccountService, BaseListComponent, Hotkeys, ResponseNotificationService, Role, SecurityStateManagementService } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';

import { PRCContract } from 'src/app/models/prc-contract.model';
import { PRCContractService } from 'src/app/services/prc-contract.service';
import { PRCContractsManagementInfo } from 'src/app/models/prc-contracts-management-info.model';
import { getDateString } from '../../../helpers/date.helper';

@Component({
  selector: 'app-prc-contract-list',
  templateUrl: './prc-contract-list.component.html',
  styleUrls: [
    './prc-contract-list.component.css',
    './../../maintenance.components.css',
    '../../maintenance-list.component.css'
  ],
})
export class PrcContractListComponent extends BaseListComponent<PRCContract> implements OnInit, OnDestroy {
  //#region ATRRIBUTES
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  prcContracts: PRCContract[] = [];
  prcContractsManagementInfo: PRCContractsManagementInfo;
  subscription: Subscription;
  mediaSize: string;
  watcher: Subscription;

  roles: Role[];

  canAdd = false;

  /* ****************************************************************************************************************** */
  /* ** INHERITED
  /* ****************************************************************************************************************** */
  searching = true;
  filter = '';
  canDelete = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private prcContractService: PRCContractService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
  ) {
    super(accountService, SSMService, router, hotkeys);

    this.baseRoute = '/maintenances/prc_contracts';

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
      this.canView = true;
    }

    this.onBuild = () => {
    };

    this.loadData = this.customLoadData;

    this.onInit = this.init;
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion


  //#region DATA MANIPULATION
  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  protected init() {
      this.loadData();

      this.prcContractService.prcContractObservable.subscribe(prcContracts => {
        this.prcContracts = prcContracts;
        this.assignPRCContract();
      });
  }

  protected customLoadData() {
    this.spinner.show();
    this.prcContractService.getManagementContractTypes().subscribe(response => {
      this.prcContractsManagementInfo = response;
      this.prcContracts = response.allPRCContracts;

      this.assignPRCContract();

      this.prcContractService.updatePRCContracts(this.prcContracts);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.RNService.showError(error);
    });
  }
  //#endregion

  //#region ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  assignPRCContract() {
    this.prcContracts.forEach(item => {
      item.company = this.prcContractsManagementInfo.allCompanies.find(company => company.companyId == item.companyId);
      item.employeeType = this.prcContractsManagementInfo.allEmployeeTypes.find(employeeType => employeeType.employeeTypeId == item.employeeTypeId);
    });
  }

  getStartDate(prcContract) {
    let result = (prcContract)
      ? getDateString(prcContract.startDate)
      : '';

    return result;
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  //#endregion
}
