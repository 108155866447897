import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { Language } from '../models/language.model';


@Injectable({ providedIn: 'root' })
export class LanguageService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private languageSubject: ReplaySubject<Language[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public languageObservable: Observable<Language[]> = this.languageSubject.asObservable();

    private languageRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public languageRouteObservable: Observable<string> = this.languageRouteSubject.asObservable();



    get serviceUrl() {
        return Config.apiBaseUrl + '/language';
    }

    constructor(private http: HttpClient) {
    }

    getAllLanguages() {
        return this.http.get<any>(`${this.serviceUrl}`);
    }

    getAllLanguagesByEmployee(employeeId?: string) {
        let employeeIdFilter="";
        if(employeeId!=undefined && employeeId != null){
            employeeIdFilter += "?employeeId="+employeeId;
        }
        return this.http.get<any>(`${this.serviceUrl}${employeeIdFilter}`);
    }

    GetAllLanguagesBy(languageId: any){
        const endpointUrl = `${this.serviceUrl}/Group`;
        return this.http.post<any>(endpointUrl, languageId);
    }

    getLanguage(languageId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<Language>(`${this.serviceUrl}/${languageId}${relatedFilter}`);
    }

    createLanguage(language: Language) {
        const endpointUrl = `${this.serviceUrl}`;
        return this.http.post(endpointUrl, language);
    }
    modifyLanguage(languageId: string, newLanguage: JSON) {
        const endpointUrl = `${this.serviceUrl}/${languageId}`;
        return this.http.patch(endpointUrl, newLanguage);
    }
    deleteLanguage(languageId: string) {
        const endpointUrl = `${this.serviceUrl}/${languageId}`;
        return this.http.delete(endpointUrl);
    }
    associateLanguage(languageId: string, employeeId: string, trainingCenterId: string, finishDate: string) {
      const endpointUrl = `${this.serviceUrl}/${languageId}/Employee/${employeeId}/Center/${trainingCenterId}/FinishDate/${finishDate}`;
      return this.http.post(endpointUrl, null);
    }

    disassociateLanguage(languageId: string, employeeId: string) {
        const endpointUrl = `${this.serviceUrl}/${languageId}/Employee/${employeeId}`;
        return this.http.delete(endpointUrl); //TODO verificar
    }

    updateLanguages(languages: Language[]) {
        this.languageSubject.next(languages);
    }

    updateLanguageRoutes(id: string) {
        this.languageRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}
