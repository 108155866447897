import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, BaseListComponent, Hotkeys, ResponseNotificationService, Role, SecurityStateManagementService } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { AbsenceTypeService } from 'src/app/services/absence-type.service';
import { AbsenceType } from '../../../models/absence-type.model';

@Component({
  selector: 'app-absence-type-maintenance-list',
  templateUrl: './absence-type-maintenance-list.component.html',
  styleUrls: [
    './absence-type-maintenance-list.component.css',
    './../../maintenance.components.css',
    '../../maintenance-list.component.css',
  ],
})
export class AbsenceTypeMaintenanceListComponent  extends BaseListComponent<AbsenceType> implements OnInit, OnDestroy {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  absenceTypes: AbsenceType[] = [];
  subscriptions: Subscription[] = [];
  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;

  roles: Role[];

  canView = false;
  canAdd = false;
  canDelete = false;
  filtersApi: FiltersApi = new FiltersApi();

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private absenceTypeService: AbsenceTypeService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
  ) {
    super(accountService, SSMService, router, hotkeys);

    this.baseRoute = '/maintenances/absence_type';

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];

    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
    }

    this.filtersApi.add(
      {
        field: "isMedicalLeave",
        value: "true"
      }
    );

    // Ordenación
    this.filtersApi.add(
      {
        field: "code",
        value: "asc"
      }
    );

    this.onBuild = () => {
    };

    this.loadData = () => {
      this.spinner.show();
      this.subscriptions.push(
        this.absenceTypeService.getAllAbsenceTypes(this.filtersApi.getStrinFilterApi()).subscribe(absenceTypes => {
          this.absenceTypeService.updateAbsenceTypes(absenceTypes.items);
          this.absenceTypes = absenceTypes.items;
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          this.RNService.showError(error);
        })
      );
    };

    this.onInit = () => {
      if(this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH)) {
        this.canView = true;
        this.canAdd = true;
        this.canDelete = true;

        this.loadData();

        this.subscriptions.push(
          this.absenceTypeService.absenceTypeObservable.subscribe(absenceTypes => {
            this.absenceTypes = absenceTypes;
        }));
      }
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;
          this.onInit();
        });
      });
    } else {
      this.roles = this.accountService.roles;
      this.onInit();
    }
  }
  //#endregion
}
