import { Component, OnInit } from '@angular/core';

import { ContractWorkingHoursTypeService } from 'src/app/services/contract-working-hours-type.service';

@Component({
  selector: 'app-contract-working-hours-type',
  templateUrl: './contract-working-hours-type.component.html',
  styleUrls: [
    './contract-working-hours-type.component.css',
    './../../../components/maintenance.components.css',
  ],
})
export class ContractWorkingHoursTypeComponent implements OnInit {
  showForm = false;

  constructor(private contractWorkingHoursTypeService: ContractWorkingHoursTypeService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.contractWorkingHoursTypeService.contractWorkingHoursTypeRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
}
