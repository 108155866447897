import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { EmployeeAreaHistory } from '../models/employee-area-history.model';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class EmployeeAreaHistoryService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private employeeAreaHistorySubject: ReplaySubject<EmployeeAreaHistory[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public employeeAreaHistoryObservable: Observable<EmployeeAreaHistory[]> = this.employeeAreaHistorySubject.asObservable();

  private employeeAreaHistoryRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public employeeAreaHistoryRouteObservable: Observable<string> = this.employeeAreaHistoryRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/employeeAreaHistory';
  }

  constructor(private http: HttpClient) {
  }

  getAllEmployeeAreaHistorys(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getEmployeeAreaHistory(employeeAreaHistoryId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/${employeeAreaHistoryId}${filter}`;

    return this.http.get<EmployeeAreaHistory>(url);
  }

  getAreaHistoryForEmployee(employeeId: string, filter?: string, includes?: string) {
    let finalRequestParam = '';

    if (!_.isNil(filter)) {
      finalRequestParam = finalRequestParam + `filter=${filter}`;
    }

    if (!_.isNil(includes)) {
      finalRequestParam = finalRequestParam +`related=true&include=${includes}`;
    }

    let url;
    if (!_.isNil(finalRequestParam) && !_.isEmpty(finalRequestParam)) {
      url = `${this.serviceUrl}/employee/${employeeId}?${finalRequestParam}`;
    } else {
      url = `${this.serviceUrl}/employee/${employeeId}`;
    }

    return this.http.get<PaginatedResult<EmployeeAreaHistory>>(url);
  }

  createAreaHistoryForEmployee(employeeAreaHistory: EmployeeAreaHistory) {
    const url = `${this.serviceUrl}`;

    return this.http.post<EmployeeAreaHistory>(url, employeeAreaHistory);
  }

  removeAreaHistoryForEmployee(employeeAreaHistoryId: string) {
    const url = `${this.serviceUrl}/${employeeAreaHistoryId}`;

    return this.http.delete<EmployeeAreaHistory>(url);
  }

  updateEmployeeAreaHistorys(employeeAreaHistorys: any) {
    this.employeeAreaHistorySubject.next(employeeAreaHistorys);
  }

  updateEmployeeAreaHistoryRoutes(id: string) {
    this.employeeAreaHistoryRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
