import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../close-year/close-year.module';
import { HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';

import { MaterialModule } from '../material/material.module';
import { CustomCommonImportsModule } from '../custom-common-imports/custom-common-imports.module';

import { MgntFormProfileComponent } from './components/forms/mgnt-form-profile/mgnt-form-profile.component';
import { MgntFormCompanyComponent } from './components/forms/mgnt-form-company/mgnt-form-company.component';
import { MgntFormFormationsComponent } from './components/forms/mgnt-form-formations/mgnt-form-formations.component';
import { MgntFormContractualComponent } from './components/forms/mgnt-form-contractual/mgnt-form-contractual.component';
import { MgntFormHistoryComponent } from './components/forms/mgnt-form-history/mgnt-form-history.component';
import { AbsencesHistoryListComponent } from './components/employee-history/absences-history-list/absences-history-list.component';
import { DCRHistoryListComponent } from './components/employee-history/dcr-history-list/dcr-history-list.component';
import { EmployeeAreaHistoryListComponent } from './components/employee-history/employee-area-history-list/employee-area-history-list.component';
import { EmployeeCategoryHistoryListComponent } from './components/employee-history/employee-category-history-list/employee-category-history-list.component';
import { EmployeeContractHistoryListComponent } from './components/employee-history/employee-contract-history-list/employee-contract-history-list.component';
import { EmployeeHistoryDialogComponent } from './components/employee-history/employee-history-dialog/employee-history-dialog.component';
import { EmployeeHistoryFilterRangueDialogComponent } from './components/employee-history/employee-history-filter-rangue-dialog/employee-history-filter-rangue-dialog.component';
import { EmployeeSBAHistoryListComponent } from './components/employee-history/employee-sba-history-list/employee-sba-history-list.component';
import { HolidaysHistoryListComponent } from './components/employee-history/holidays-history-list/holidays-history-list.component';
import { StatesHistoryListComponent } from './components/employee-history/situations-history-list/situations-history-list.component';
import { EmployeeTableComponent } from './components/tables/employee-table/employee-table.component';
import { PECommonModule } from '../pe-common/pe-common.module';
import { EmployeeManagerHistoryListComponent } from './components/employee-history/employee-manager-history-list/employee-manager-history-list.component';
import { EmployeeValidatorHistoryListComponent } from './components/employee-history/employee-validator-history-list/employee-validator-history-list.component';

@NgModule({
  declarations: [
    MgntFormProfileComponent,
    MgntFormCompanyComponent,
    MgntFormFormationsComponent,
    MgntFormContractualComponent,
    MgntFormHistoryComponent,
    AbsencesHistoryListComponent,
    DCRHistoryListComponent,
    EmployeeAreaHistoryListComponent,
    EmployeeManagerHistoryListComponent,
    EmployeeValidatorHistoryListComponent,
    EmployeeCategoryHistoryListComponent,
    EmployeeContractHistoryListComponent,
    EmployeeSBAHistoryListComponent,
    HolidaysHistoryListComponent,
    StatesHistoryListComponent,

    EmployeeHistoryDialogComponent,
    EmployeeHistoryFilterRangueDialogComponent,
    EmployeeTableComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    // Custom modules
    MaterialModule,
    CustomCommonImportsModule,
    PECommonModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      timeOut: 5000,
      enableHtml: true
    }),
  ],
  exports: [
    MgntFormProfileComponent,
    MgntFormCompanyComponent,
    MgntFormFormationsComponent,
    MgntFormContractualComponent,
    MgntFormHistoryComponent,
    EmployeeTableComponent,

  ],
  entryComponents: [
    //EmployeeHistory
    EmployeeHistoryDialogComponent,
    EmployeeHistoryFilterRangueDialogComponent,
    EmployeeAreaHistoryListComponent,
    EmployeeManagerHistoryListComponent,
    EmployeeValidatorHistoryListComponent,
    EmployeeCategoryHistoryListComponent,
    EmployeeContractHistoryListComponent,
    EmployeeSBAHistoryListComponent,
    HolidaysHistoryListComponent,
  ],
})
export class EmployeeModule { }
