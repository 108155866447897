import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { getDateString } from 'src/app/helpers/date.helper';
import { HRADateFilterService } from 'src/app/services/hra-date-filter.service';

@Component({
  selector: 'app-employee-history-filter-rangue-dialog',
  templateUrl: './employee-history-filter-rangue-dialog.component.html',
  styleUrls: [
    './employee-history-filter-rangue-dialog.component.css',
    '../../../../../../assets/styles/action.components.css',
    '../../../../../../assets/styles/action-list.component.css',
  ],
})
export class EmployeeHistoryFilterRangueDialogComponent {

  startDateFilter: string;
  finishDateFilter: string;

  formGroupControl = new FormGroup({
    startDate: new FormControl(''),
    finishDate: new FormControl('')
  });

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hraDateFilterService: HRADateFilterService
  ) { }

  ngOnInit(): void {

    this.hraDateFilterService.dates.subscribe(dates => {
      this.startDateFilter = dates.dataStartDate;
      this.finishDateFilter = dates.dataFinishDate;
    });

    this.formGroupControl.setValue({
      startDate: this.startDateFilter,
      finishDate: this.finishDateFilter
    });
  }

  filter(){
    let startDate =  getDateString(this.formGroupControl.get('startDate').value);
    let finishDate = getDateString(this.formGroupControl.get('finishDate').value);

    this.hraDateFilterService.onChangeDates(startDate, finishDate);
    this.dialogRef.close(true);
  }
}
