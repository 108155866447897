import { DataChangeRequest } from "./data-chage-request.model";

export enum DCRStateCode {
  REGISTERED      = 0,
  PENDING_APROVAL = 1,
  APPROVED        = 3,
  REJECTED        = 4,
  CANCELED        = 5
}

export class DCRState {
  dcrStateId  : string;
  stateCode   : DCRStateCode;
  description : string;

  //Relations
  DCRs        : DataChangeRequest[];

  constructor(obj?: Partial<DCRState>) {
    this.dcrStateId   = (obj && obj.dcrStateId) || undefined;
    this.stateCode    = (obj && obj.stateCode) || undefined;
    this.description  = (obj && obj.description) || undefined;

    //Relations
    this.DCRs         = (obj && obj.DCRs) || [];
  }
}
