import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DcrComponent } from '../dcr.component';
import { DataChangeRequest } from '../../../models/data-chage-request.model';

@Component({
  selector: 'app-dcr-dialog',
  templateUrl: './dcr-dialog.component.html',
  styleUrls: ['./dcr-dialog.component.css']
})
export class DcrDialogComponent implements OnInit {
  request! : DataChangeRequest;

  constructor(
    public dialogRef: MatDialogRef<DcrComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataChangeRequest
  ) { }


  ngOnInit() {
    this.request = this.data;
  }
}
