import { Employee } from "./employee.model";

export class Headquarters {
  headquartersId  : string;
  name            : string;
  description     : string;

  //Relations
  employees       : Employee[];

  constructor(obj?: Partial<Headquarters>) {
    this.headquartersId = (obj && obj.headquartersId) || undefined;
    this.name           = (obj && obj.name) || undefined;
    this.description    = (obj && obj.description) || undefined;

    //Relations
    this.employees      = (obj && obj.employees) || [];
  }
}
