import { Component, OnInit } from '@angular/core';

import { ContractDurationTypeService } from 'src/app/services/contract-duration-type.service';

@Component({
  selector: 'app-contract-duration-type',
  templateUrl: './contract-duration-type.component.html',
  styleUrls: [
    './contract-duration-type.component.css',
    './../../../components/maintenance.components.css',
  ],
})
export class ContractDurationTypeComponent implements OnInit {
  showForm = false;

  constructor(private contractDurationTypeService: ContractDurationTypeService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.contractDurationTypeService.contractDurationTypeRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
}
