/* #################################################################################################################### */
/* ## IMPORTS
/* #################################################################################################################### */

/* #################################################################################################################### */
/* ## INTERFACES
/* #################################################################################################################### */
export interface StyleRule {
  key   : string;
  value : string;
}

export class DynamicCssClass {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  id          : string;
  name        : string;
  styleRules  : StyleRule[] = [];

  styleSheet: HTMLStyleElement;

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */

  constructor(obj?: Partial<DynamicCssClass>) {
    Object.assign(this, obj);

    this.styleSheet = document.createElement('style');
  }

  /* ################################################################################################################## */
  /* ## CRUD METHODS
  /* ################################################################################################################## */
  /**
   * Añade un regla de estilo nueva.
   *
   * @param styleRule nueva regla de estilo
   * @param replace indica que si la regla de estilo que se pretende añadir ya existe se debe actualizar
   * @returns boolean con el resultado de la operación
   */
  addRule(styleRule: StyleRule, replace = false) {
    let result = false;

    if (replace) {
      result = this.replaceRule(styleRule, replace);
    } else {
      let item = this.searchRule(styleRule.key);

      if (!item) {
        this.styleRules.push(styleRule);
        result = true;
      }
    }

    return result;
  }

  /**
   * Elimina la regla de estilo con el nombre especificado.
   *
   * @param keyName nombre de la regla de estilo a eliminar
   * @returns boolean con el resultado de la operación
   */
  deleteRule(keyName: string) {
    let result = false;

    let item = this.searchRule(keyName);

    if (item) {
      const index = this.styleRules.indexOf(item);

      if (index > -1) {
        this.styleRules.splice(index, 1);
        result = true;
      }
    }

    return result;
  }

  /**
   * Reemplaza una regla de estilo con la información de la regla de estilo actualizada.
   *
   * @param styleRule regla de estilo actualizada
   * @param addNew indica que si la regla de estilo que se pretende sustituir no existe se debe añadir o no
   * @returns boolean con el resultado de la operación
   */
  replaceRule(styleRule: StyleRule, addNew = false) {
    let result = false;

    let item = this.searchRule(styleRule.key);

    if (item) {
      const index = this.styleRules.indexOf(item);

      if (index > -1) {
        this.styleRules[index]= styleRule;
        result = true;
      }
    } else if (addNew) {
      this.styleRules.push(styleRule);
      result = true;
    }

    return result;
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  /**
   * Busca la regla de estilo pasada en el array.
   *
   * @param keyName key de la regla de estilo a buscar
   * @returns regla de estilo
   */
  searchRule(keyName: string): StyleRule {
    let result: StyleRule = null;

    for (let item of this.styleRules) {
      if (item.key == keyName) {
        result = item;
        break;
      }
    }

    return result;
  }

  /**
   * Crea la clase de CSS con las reglas del array.
   */
  createHTMLStyle() {
    let stylesText = "";

    for(let styleRule of this.styleRules) {
      stylesText += `${styleRule.key}: ${styleRule.value};`;
    }

    this.styleSheet.innerHTML = `.${this.name} { ${stylesText} }`;
  }

  /**
   * Añade la clase creada a los estilos del documento.
   *
   * @param createClass indica si se debe crear la clase antes de intentar añadirla
   *                  a los estilos del documento
   */
  appendToDocument(createClass: boolean = false) {
    if (createClass) {
      this.createHTMLStyle();
    }

    if (this.styleSheet != undefined && this.styleSheet != null) {
      document.getElementsByTagName('head')[0].appendChild(this.styleSheet);
    }
  }
}
