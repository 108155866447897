import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseFormComponent, AccountService, Hotkeys, Role } from 'inzo-portalempleado';

import { DataChangeRequest } from 'src/app/models/data-chage-request.model';
import { DataChangeRequestService } from 'src/app/services/data-change-request.service';
import { AttributeChange } from 'src/app/models/attribute-chage.model';
import { AttrPatchChangesJson } from '../../../interfaces/attr-patch-changes-json.interface';
import { DCRState, DCRStateCode } from 'src/app/models/dcr-state.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppPermissions } from 'src/app/models/app-permission.model';




@Component({
  selector: 'app-dcr-form',
  templateUrl: './dcr-form.component.html',
  styleUrls: ['./dcr-form.component.css', './../../action.components.css'],
})
export class DcrFormComponent extends BaseFormComponent<DataChangeRequest> implements OnInit, OnDestroy {
  @Input() employeeView = false;
  selectedDesc: string = undefined;
  @Input() currentDataChangeRequests!: DataChangeRequest;
  @Input() dcrStates: DCRState[] = [];

  declineState: boolean = false;

  roles: Role[];

  enabled = true;
  detail = false;
  permissionsLoaded = false;

  @Input() canApprove=false;
  @Input() canSend = false;

  componentTypeTranslated: string;
  subscription: Subscription;

  formGroupControl !: FormGroup;

  filterApi: FiltersApi = new FiltersApi();

  constructor(
    private toastrService: ToastrService,
    private dataChangeRequestService: DataChangeRequestService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
  ) {
    super(accountService, router, hotkeys);

    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('DATA_CHANGE_REQUEST_ACTION.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });

    this.filterApi.add(
      {
        field: "related",
        value: "true"
      }
    );
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();

    if(this.router.url.includes("managements"))
      this.canApprove=true;
    else
      this.canApprove=false;

    if (this.employeeView) {
      this.filterApi.add(
        {
          field: "employeeId",
          value: this.currentDataChangeRequests.employeeId
        }
      );
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  private init() {
    this.getAttributesData();
    this.iniFormGroup();
  }

  iniFormGroup() {
    let form = {};

    for (let attribute of this.currentDataChangeRequests.attributeChanges) {
      form[`${attribute.attribute}Old`] = new FormControl({ value: "", disabled: true });
      form[`${attribute.attribute}New`] = new FormControl({ value: "", disabled: true });
    }
    form[`reason`] = new FormControl({ value: "", disabled: false });

    this.formGroupControl = new FormGroup(form);
  }

  getAttributesData() {
    if (this.currentDataChangeRequests.attributeChanges == undefined || this.currentDataChangeRequests.attributeChanges == null) {
      this.currentDataChangeRequests.attributeChanges = [];
    }

    if (this.currentDataChangeRequests.attributeChanges.length <= 0 &&
      this.currentDataChangeRequests.attrPatchChangesJson != undefined &&
      this.currentDataChangeRequests.attrPatchChangesJson != null
    ) {
      let attributesJSON: AttrPatchChangesJson[] = JSON.parse(this.currentDataChangeRequests.attrPatchChangesJson);

      for (let item of attributesJSON) {
        let attributeChange: AttributeChange = new AttributeChange();
        let attributeNameArray = item.path.split("/");
        attributeNameArray = attributeNameArray.filter((item) => item != ""); // Limpia el array de elementos vacíos

        let attributeName = attributeNameArray.reduce((result, item, index) => result += index > 0 ? `_${item}` : item);

        attributeChange.attribute = attributeName;
        attributeChange.path = item.path;
        attributeChange.newValue = item.value;

        // https://stackoverflow.com/questions/11922383/how-can-i-access-and-process-nested-objects-arrays-or-json
        attributeChange.oldValue = attributeNameArray.reduce(
          (a, v) => a[v], this.currentDataChangeRequests.employee
        );

        this.currentDataChangeRequests.attributeChanges.push(attributeChange);
      }
    }
  }

  /**
   * Evalúa el atributo pasado como parámetro y devuelve el nombre de la etiqueta que le corresponde
   *
   * @param attribute nombre del atributo a traducir
   * @param oldValue flag para indicar si es la etiqueta del valor anterior
   * @returns el nombre de la etiqueta del atributo
   */
  getFieldName(attribute: string, oldValue = false) {
    let label = "";
    let labelValue = "";
    let attributeArray = attribute.split("_");

    this.translate.get(`EMPLOYEEMANAGEMENT_MAINTENANCE.FORM.LABEL.${attributeArray[0].toUpperCase()}`).subscribe((a: string) => {
      label = a;
    });

    if (attributeArray.length > 1) {
      this.translate.get(`EMPLOYEEMANAGEMENT_MAINTENANCE.FORM.LABEL.${attributeArray[1].toUpperCase()}`).subscribe((a: string) => {
        label += ` - ${a}`;
      });
    }

    this.translate.get(`EMPLOYEEMANAGEMENT_MAINTENANCE.FORM.LABEL.${oldValue ? "OLD" : "NEW"}_VALUE`).subscribe((a: string) => {
      labelValue = a;
    });

    return `${label} (${labelValue.toLowerCase()})`;
  }

  // ======================== CRUD ========================

  cleanForm() {
    this.formGroupControl.controls["reason"].setValue("");
  }

  cancel() {
    this.declineState = false;
    this.cleanForm();
  }

  cancelRequest() {
      let status = this.dcrStates.find(element => element.stateCode == DCRStateCode.CANCELED).dcrStateId;
      this.update(status);
  }

  sendRequest() {
    let status = this.dcrStates.find(element => element.stateCode == DCRStateCode.PENDING_APROVAL).dcrStateId;
    this.update(status);
  }

  declineRequest() {
    this.declineState = true;
    this.iniFormGroup();
  }

  approveRequest() {
    this.declineState = false;
    let status = this.dcrStates.find(element => element.stateCode == DCRStateCode.APPROVED).dcrStateId;
    this.update(status);

  }


  updateDecline() {
    this.declineState = true;
    let status = this.dcrStates.find(element => element.stateCode == DCRStateCode.REJECTED).dcrStateId;
    let reason = this.formGroupControl.controls["reason"].value;

    this.update(status, reason);
  }

  update(status: string, reason?: string) {
    this.spinner.show();

    this.dataChangeRequestService.changeStateDataChangeRequest(
      this.currentDataChangeRequests.dataChangeRequestId,
      status,
      reason
    ).subscribe(
      response => {
        this.dataChangeRequestService.getAllDataChangeRequestGroupBy(this.filterApi.getStrinFilterApi()).subscribe(currentDataChangeRequests => {
          this.dataChangeRequestService.updateDataChangeRequests(currentDataChangeRequests.items);
          this.spinner.hide();
        });

        this.createSuccessMessage();
        this.cancel();
        this.spinner.hide();
      },
      error => {
        this.createErrorMessage(error);
        this.spinner.hide();
      },
    );
  }

  back() {
  }

  createSuccessMessage(timeMSG = 3000) {
    this.translate.get('DATA_CHANGE_REQUEST_ACTION.NAME').subscribe((componentTranslated: string) => {
      let toastTitle = '';
      let toastBody = '';
      this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => { toastTitle = a });
      this.translate.get('MESSAGES.SUCCESS.CREATE_LONG_SIMPLE', { type: componentTranslated }).subscribe((a: string) => { toastBody = a });
      this.toastrService.success(toastBody, toastTitle, { timeOut: timeMSG });
    });
  }

  createErrorMessage(error, timeMSG = 3000) {
    let title = "MESSAGES.ERROR.UNEXPECTED";
    let message = "MESSAGES.ERROR.UNEXPECTED_BODY";

    this.translate.get(title).subscribe((lang: string) => {
      title = lang;
    });
    this.translate.get(message).subscribe((lang: string) => {
      message = lang;
    });

    this.toastrService.error(message, title, { timeOut: timeMSG });
  }
}
