import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DegreeService } from 'src/app/services/degree.service';

@Component({
  selector: 'app-maintenance-degree',
  templateUrl: './degree.component.html',
  styleUrls: ['./../maintenance.components.css'],
})
export class DegreeComponent implements AfterViewInit, OnInit {
  showForm = false;
  constructor( private degreeService: DegreeService) {
  }
  ngAfterViewInit() {
    this.degreeService.degreeRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
  ngOnInit() {
  }
}
