export class ContractDurationType {
  contractDurationTypeId  : string;
  code                    : string;
  description             : string;

  //Relations

  constructor(obj?: Partial<ContractDurationType>) {
    this.contractDurationTypeId = (obj && obj.contractDurationTypeId) || undefined;
    this.code                   = (obj && obj.code) || undefined;
    this.description            = (obj && obj.description) || undefined;

    //Relations
  }
}
