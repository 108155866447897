import { Employee } from "./employee.model";
import { AHRChanges } from "./ahr-changes.model";
import { HolidayRequestState } from "./holiday-request-state.model";

export class HolidayRequest {
  holidaysRequestId       : string;
  startDate               : any;
  finishDate              : any;
  requestedDates          : string[];
  reason                  : string;
  createdDate             : string;
  updatedDate             : string;

  //Relations
  employeeId              : string;
  employee                : Employee;

  holidaysRequestStateId  : string;
  holidaysRequestState    : HolidayRequestState;
  holidaysRequestChanges  : AHRChanges[];

  visibleOnYearCalendar   : boolean;

  constructor(obj?: Partial<HolidayRequest>) {
    this.holidaysRequestId      = (obj && obj.holidaysRequestId) || undefined;
    this.startDate              = (obj && obj.startDate) || undefined;
    this.finishDate             = (obj && obj.finishDate) || undefined;
    this.requestedDates         = (obj && obj.requestedDates) || undefined;
    this.reason                 = (obj && obj.reason) || undefined;
    this.createdDate            = (obj && obj.createdDate) || undefined;
    this.updatedDate            = (obj && obj.updatedDate) || undefined;

    //Relations
    this.employeeId             = (obj && obj.employeeId) || undefined;
    this.employee               = (obj && obj.employee) || undefined;

    this.holidaysRequestStateId = (obj && obj.holidaysRequestStateId) || undefined;
    this.holidaysRequestState   = (obj && obj.holidaysRequestState) || undefined;
    this.holidaysRequestChanges = (obj && obj.holidaysRequestChanges) || [];

    this.visibleOnYearCalendar   = (obj && obj.visibleOnYearCalendar) || undefined;
  }
}
