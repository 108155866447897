import { Course } from "./course.model";
import { CV } from "./cv.model";
import { Degree } from "./degree.model";
import { Employee } from "./employee.model";
import { Language } from "./language.model";
import { TCRState } from "./tcr-state.model";
import { TrainingCenter } from './training-center.model';

export class TrainingChangeRequest {
  trainingChangeRequestId : string;
  reason                  : string;
  createdDate             : string;
  finishDate              : string;

  //Relations
  employeeId              : string;
  employee                : Employee;

  courseId                : string;
  course                  : Course;

  degreeId                : string;
  degree                  : Degree;

  employeeCVId            : string;
  employeeCV              : CV;

  languageId              : string;
  language                : Language;

  tcrStateId              : string;
  state                   : TCRState;

  trainingCenterId        : string;
  trainingCenter          : TrainingCenter;

  constructor(obj?: Partial<TrainingChangeRequest>) {
    this.trainingChangeRequestId  = (obj && obj.trainingChangeRequestId) || undefined;
    this.reason                   = (obj && obj.reason) || undefined;
    this.createdDate              = (obj && obj.createdDate) || undefined;
    this.finishDate               = (obj && obj.finishDate) || undefined;

    //Relations
    this.employeeId               = (obj && obj.employeeId) || undefined;
    this.employee                 = (obj && obj.employee) || undefined;

    this.courseId                 = (obj && obj.courseId) || undefined;
    this.course                   = (obj && obj.course) || undefined;

    this.degreeId                 = (obj && obj.degreeId) || undefined;
    this.degree                   = (obj && obj.degree) || undefined;

    this.employeeCVId             = (obj && obj.employeeCVId) || undefined;
    this.employeeCV               = (obj && obj.employeeCV) || undefined;

    this.languageId               = (obj && obj.languageId) || undefined;
    this.language                 = (obj && obj.language) || undefined;

    this.tcrStateId               = (obj && obj.tcrStateId) || undefined;
    this.state                    = (obj && obj.state) || undefined;

    this.trainingCenterId         = (obj && obj.trainingCenterId) || undefined;
    this.trainingCenter           = (obj && obj.trainingCenter) || undefined;
  }
}
