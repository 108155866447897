import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { IInputData } from 'inzo-calendar-lib/lib/Interface/IInputData';
import { LocaleSettings } from 'inzo-calendar-lib/lib/Interface/LocaleSettings';
import { AccountService, Hotkeys, ResponseNotificationService, SecurityStateManagementService } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { getLocaleNames } from 'src/app/helpers/date.helper';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { WorkCalendarService } from 'src/app/services/work-calendar.service';
import { CloseYearHistoryService } from '../../../services/close-year-history.service';
import { CloseYearHistroyConfirmDialogComponent, ConfirmDialogType } from '../../close-year-histroy-confirm-dialog/close-year-histroy-confirm-dialog.component';
import { CloseYearWorkCalendarHistoryCreateDialogComponent } from '../close-year-wc-history-create-dialog/close-year-wc-history-create-dialog.component';
import { CloseYearWorkCalendarHistoryEditDialogComponent } from '../close-year-wc-history-edit-dialog/close-year-wc-history-edit-dialog.component';

@Component({
  selector: 'app-close-year-wc-history-create',
  templateUrl: './close-year-wc-history-create.component.html',
  styleUrls: [
    './close-year-wc-history-create.component.css',
    './../../../../../components/maintenance-list.component.css',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CloseYearWorkCalendarHistoryCreateComponent implements OnInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  workCalendars: WorkCalendar[] = [];

  selectedWorkCalendar: WorkCalendar;
  selectedWorkCalendars: WorkCalendar[] = [];

  searching = true;
  filter = '';

  value:IInputData = {
    year: new Date().getFullYear(),
    dates: [],
  };

  locale: LocaleSettings = {
    dayNamesMin: [],
    monthNames: []
  };

  filtersApi: FiltersApi = new FiltersApi();

  @Output() selectWorkCalendarEvent: EventEmitter<WorkCalendar[]> = new EventEmitter();

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */

  constructor(
    private workCalendarService: WorkCalendarService,
    private closeYearHistoryService: CloseYearHistoryService,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {}

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = getLocaleNames(this.translate);
    });
    this.translate.get('CALENDAR.DAYS_OF_WEEK').subscribe((daysOfWeekLang) => {
      this.locale.dayNamesMin = daysOfWeekLang;
    });
    this.translate.get('CALENDAR.MONTHS').subscribe((monthsLang) => {
      this.locale.monthNames = monthsLang;
    });

    this.loadData();
  }

  onNgModelChange($event) {
    this.selectedWorkCalendar = $event;
  }

  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  loadData() {
    this.spinner.show();
    this.workCalendarService.getAllWorkCalendar(this.filtersApi.getStrinFilterApi()).subscribe(workCalendars => {
      this.workCalendarService.updateWorkCalendars(workCalendars.items);
      this.workCalendars = workCalendars.items;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.RNService.showError(error);
    });
  };

  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  createWorkCalendars() {
    const creationWCDialog = this.dialog.open(CloseYearWorkCalendarHistoryCreateDialogComponent, {
      disableClose: true,
      width: '550px',
      data: {
        selectedWorkCalendars: this.selectedWorkCalendars.length > 0 ? this.selectedWorkCalendars : this.workCalendars
      }
    });

    creationWCDialog.afterClosed().subscribe(async (responseCreationWCDialog) => {
      if (responseCreationWCDialog != false) {
        const confirmWCGenerate =  this.dialog.open(CloseYearHistroyConfirmDialogComponent, {
          disableClose: true,
          width: '550px',
          data: {
            confirmDialogType: ConfirmDialogType.WORKCALENDARS_GENERATE
          }
        });

        confirmWCGenerate.afterClosed().subscribe(async (confirm) => {
          if (confirm === true) {
            this.spinner.show();
            this.closeYearHistoryService.createNewWorkCalendars(responseCreationWCDialog.validYear, responseCreationWCDialog.selectedWorkCalendarIds).subscribe(closeYearHistory => {
              this.loadData();
              this.spinner.hide();

              const editWorkCalendarsDialog = this.dialog.open(CloseYearWorkCalendarHistoryEditDialogComponent, {
                // width: '1000px',
                disableClose: true,
                data: {
                  closeYearHistory: closeYearHistory
                }
              });
              editWorkCalendarsDialog.afterClosed().subscribe(async (responseEditWorkCalendarsDialog) => {
                if (responseEditWorkCalendarsDialog == 'revertWC') {
                  const confirmWCRevert =  this.dialog.open(CloseYearHistroyConfirmDialogComponent, {
                    disableClose: true,
                    width: '550px',
                    data: {
                      confirmDialogType: ConfirmDialogType.WORKCALENDARS_REVERSION
                    }
                  });

                  confirmWCRevert.afterClosed().subscribe(async (confirm) => {
                    if (confirm == true) {
                      this.spinner.show();
                      this.closeYearHistoryService.revertWorkCalendars(closeYearHistory.closeYearHistoryId).subscribe(closeYearHistory => {
                        this.loadData();
                        this.spinner.hide();
                      });
                    }
                  });
                }
              });
            }, error => {
              this.spinner.hide();
              this.RNService.showError(error);
            });
          } else {
            this.loadData();
            this.spinner.hide();
          }

        });

      }
    });
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  /**
  * Devuelve el objeto WorkCalendar selecionado.
  * @param workCalendar objeto WorkCalendar selecionado
  */
  returnWorkCalendar(): void {
    this.selectWorkCalendarEvent.emit(this.selectedWorkCalendars);
  }
}
