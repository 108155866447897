import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HolidayRequestEvent } from 'src/app/interfaces/events.interface';
import { HolidayRequestStateCode } from 'src/app/models/holiday-request-state-code-enum.model';
import { HolidayRequestState } from 'src/app/models/holiday-request-state.model';
import { HolidayRequest } from 'src/app/models/holiday-request.model';

@Component({
  selector: 'app-holiday-request-management-list-item',
  templateUrl: './holiday-request-management-list-item.component.html',
  styleUrls: ['./holiday-request-management-list-item.component.css']
})
export class HolidayRequestManagementListItemComponent implements OnInit {
  @Input() holidayRequest: HolidayRequest;
  @Input() holidayRequestStates: HolidayRequestState[] = [];

  @Output() actionHoliday: EventEmitter<HolidayRequestEvent> = new EventEmitter();

  showReason: boolean = false;
  formGroupControl: FormGroup;

  constructor() { }

  ngOnInit() {
    this.formGroupControl = new FormGroup({
      state: new FormControl(''),
      reason: new FormControl(''),
    });

    this.initValuesForm();
  }

  /**
   * Selecciona los valores iniciales del formulario
   */
  initValuesForm() {
    this.formGroupControl.setValue({
      state: this.holidayRequest.holidaysRequestStateId,
      reason: this.holidayRequest.reason,
    });
  }

  /**
   * Pasa la solicitud de vacaciones recibida como param al estado marcado en el selector
   *
   * @param holidayRequest - La solicitud de vacaciones que será cambiada de estado
   */
  setState(): void {
    let currentState = this.getState();
    let stateName = HolidayRequestStateCode[currentState.stateCode].toString();

    this.holidayRequest.holidaysRequestStateId = currentState.holidaysRequestStateId;
    this.holidayRequest.holidaysRequestState = currentState;
    this.holidayRequest.reason = this.getReason();

    this.actionHoliday.emit(
      {
        holidayRequest  : this.holidayRequest,
        eventType       : stateName,
      }
    );
  }

  /**
   * Cancela el cambio de estado de la solicitud
   */
  resetState(): void {
    this.initValuesForm();

    this.showReason = false;
    // Quita las marcas "dirty" de los inputs
    this.formGroupControl.markAsPristine();
  }

  /**
   * Comprueba que el estado de la solicitud sea igual al que se le pasa como parámetro.
   *
   * @param state estado a comprobar
   * @returns un boolean con el resultado de la comparación
   */
  checkHolidayRequestState(state: string): boolean {
    let currentState = this.getState();

    this.showReason = currentState.stateCode == HolidayRequestStateCode[state];

    return this.showReason;
  }

  /**
   * Comprueba si se deben mostrar los botones o no.
   *
   * @returns un boolean con el resultado de la comprobación
   */
  showButtons(): boolean {
    let result = false;

    //https://stackoverflow.com/questions/40914413/how-can-i-get-all-dirty-modified-fields-using-angular2-forms
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      if (currentControl.dirty) {
        result = true;
        return;
      }
    });

    return result;
  }

  /**
   * Pasa la solicitud de vacaciones recibida como param al estado marcado en el selector
   *
   * @returns si el nuevo estado es 'REJECTED' recupera el motivo del rechazo, si no es así
   * devuelve una cadena vacía
   */
  getReason(): string {
    let newReason = "";

    if (this.checkHolidayRequestState('REJECTED')) {
      newReason = this.formGroupControl.get('reason').value;
    }

    return newReason;
  }

  /**
   * Recupera el estado selecionado.
   *
   * @returns un objeto con el nuevo estado para la solicitud
   */
  getState(): HolidayRequestState {
    let newState: HolidayRequestState = null;

    for(let state of this.holidayRequestStates) {
      if (state.holidaysRequestStateId == this.formGroupControl.get("state").value) {
        newState = state;
        break;
      }
    }

    return newState;
  }
}
