import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Headquarters } from 'src/app/models/headquarters.model';
import { HeadquartersService } from 'src/app/services/headquarters.service';


@Component({
  selector: 'app-headquarters-list',
  templateUrl: 'headquarters-list.component.html',
  styleUrls: ['./../../maintenance.components.css','../../maintenance-list.component.css'],
})
export class HeadquartersListComponent extends BaseListComponent<Headquarters> implements OnInit, OnDestroy {
  roles: Role[];
  headquarters: Headquarters[] = [];
  subscription: Subscription;
  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;

  canView = false;
  canAdd = false;
  canDelete = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  constructor(
    private headquartersService: HeadquartersService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
  ) {
    super(accountService, SSMService, router, hotkeys);
    this.baseRoute = '/maintenances/headquarters';

    this.onBuild = () => {
    };

    this.loadData = () => {
      this.spinner.show();
      this.headquartersService.getAllHeadquarters().subscribe(headquarters => {
        this.headquarters = [];

        for(let headquarter of headquarters.items){
          if(headquarter.name !== "DEFAULT"){
            this.headquarters.push(headquarter);
          }
        }
        this.headquartersService.updateHeadquarters(this.headquarters);
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.RNService.showError(error);
      });
    };

    this.onInit = () => {
      if(this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH)) {
        this.canView = true;
        this.canAdd = true;
        this.canDelete = true;
      
        this.loadData();

        this.headquartersService.headquartersObservable.subscribe(headquarters => {
          const indexOfDefaultHeadquarter = headquarters.findIndex(headquarter => headquarter.name == "DEFAULT");
          if (indexOfDefaultHeadquarter >= 0) {
            headquarters.splice(indexOfDefaultHeadquarter, 1);
          }
          this.headquarters = Array.from(this.headquarters);
        });
      }
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.roles = this.accountService.roles;
      this.onInit();
    }
  }
  //#endregion
}

