import { Component, ViewChild, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { transformDate } from 'src/app/helpers/date.helper';
import { HRADateFilterService } from 'src/app/services/hra-date-filter.service';
import { Employee } from 'src/app/models/employee.model';
import { Subscription } from 'rxjs';
import { getDateTimeString } from 'src/app/helpers/date.helper';
import { DataHistoryService } from '../../../services/data-history.service';
import * as Rxjs from 'rxjs';
import * as _ from 'lodash';
import moment = require('moment');
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeSituation } from 'src/app/models/employee-situation.model';
import { EmployeeHistory } from 'src/app/models/employee-history.model';
import { EmployeeHistoryService } from 'src/app/services/employee-history.service';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { EmployeeSituationCode } from 'src/app/models/employee-situation-enum.model';

@Component({
  selector: 'app-situations-history-list',
  templateUrl: './situations-history-list.component.html',
  styleUrls: [
    './situations-history-list.component.css',
    '../../../../../../assets/styles/action.components.css',
    '../../../../../../assets/styles/action-list.component.css',
  ],
})
export class StatesHistoryListComponent implements OnInit, OnDestroy {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  @Output() onEmployeeSituationReloadNeeded: EventEmitter<string> = new EventEmitter();

  showAddPanel = false;
  employeeView = false;
  employee: Employee = null;
  employees: Employee[] = [];
  employeeSituations: EmployeeSituation[] = [];
  displayedColumns: string[] = [
    'actions',
    'startDate',
    'reason',
    'newEmployeeSituationId',
    'log',
    'createdByUserId',
  ];

  startDateFilter: string;
  finishDateFilter: string;

  ELEMENT_DATA: EmployeeHistory[] = [];
  dataSource: MatTableDataSource<EmployeeHistory>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  subscriptions: Subscription[] = [];

  currentEmployeeHistory : EmployeeHistory = new EmployeeHistory();

  employeeSituationHistoryFormGroup: FormGroup = new FormGroup({
    situation   : new FormControl("", Validators.required),
    reason      : new FormControl(""),
    startDate   : new FormControl("", Validators.required),
    finishDate  : new FormControl(""),
  });

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private dataHistoryService: DataHistoryService,
    private hraDateFilterService: HRADateFilterService,
    private employeeHistoryService: EmployeeHistoryService,
    protected permissionsService: PermissionsService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
  ) {
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;
      })
    );

    // EmployeeView
    this.subscriptions.push(
      this.dataHistoryService.employeeView.subscribe(data => {
        this.employeeView = data;
      })
    );

    // Employees
    this.subscriptions.push(
      this.dataHistoryService.employees.subscribe(data => {
        this.employees = data;
      })
    );

    // EmployeeSituations
    this.subscriptions.push(
      this.dataHistoryService.employeeSituations.subscribe(data => {
        this.employeeSituations = data;
      })
    );

    // Employee
    this.subscriptions.push(
      this.dataHistoryService.employee.subscribe(data => {
        this.employee = data; 
        this.loadDataOnTable();
      })
    );
    
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  //#endregion

  /* ################################################################################################################## */
  /* ## DATA METHODS
  /* ################################################################################################################## */
  loadDataOnTable() {
    if (this.employee != undefined && this.employee != null) {
      this.ELEMENT_DATA = this.employee.employeeHistory || [];

      // Se busca el valor actual
      this.assigCurrentSituation();

      this.ELEMENT_DATA = _.orderBy(this.ELEMENT_DATA, a => moment(a.startDate).unix(), 'desc');
      this.dataSource = new MatTableDataSource<EmployeeHistory>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  getAndLoadDataOnTable() {
    this.employeeHistoryService.getHistoryForEmployee(this.employee.employeeId).subscribe(EmployeeHisoryResponse => {
      this.employee.employeeHistory = (EmployeeHisoryResponse && EmployeeHisoryResponse.items) || [];

      const activeSituation = _.find(this.employeeSituations, item => item.stateCode == EmployeeSituationCode.ACTIVE);

      // Se busca el último alta
      const lastActiveSituationHistory = _(this.employee.employeeHistory).filter(x => moment(x.startDate) <= moment() && x.newEmployeeSituationId == activeSituation.employeeSituationId).maxBy(x => moment(x.startDate).unix());

      if (lastActiveSituationHistory) {
        this.employee.registrationDate = lastActiveSituationHistory.startDate;
      }

      this.loadDataOnTable();

      // Se propaga el cambio a los observadores
      this.dataHistoryService.setEmployeeChange(this.employee);
      // this.dataChangesService.setEmployeeChange(this.employee);

      this.spinner.hide();
    },
    error => {
      this.spinner.hide();
    });
  }

  onCreate() {
    if (this.employeeSituationHistoryFormGroup && this.employeeSituationHistoryFormGroup.valid) {
      this.spinner.show();

      this.getEmployeeHistoryToSend();

      this.employeeHistoryService.createEmployeeHistory(this.currentEmployeeHistory).subscribe(response => {
        this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.CATEGORY.CREATION_SUCCESS').subscribe((text: string) => {
          this.toastrService.success(text, '', { timeOut: 3000 });
        });

        this.resetForm();

        this.getAndLoadDataOnTable();

        this.showAddPanel = false;
      },
      error => {
        console.error('error --> ', error);
        this.spinner.hide();
        if(error.error === 'MISSING_PARAMS') {
          this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.CATEGORY.ERROR.MISSING_PARAMS').subscribe((text: string) => {
            this.toastrService.error(text, '', { timeOut: 3000 });
          });
        } else {
          this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.CATEGORY.ERROR.UNEXPECTED').subscribe((text: string) => {
            this.toastrService.error(text, '', { timeOut: 3000 });
          });
        }
      });
    }
  }

  onRemove(employeeHistory: EmployeeHistory) {
    let text;
    this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.MESSAGE.CATEGORY.DELETE_CONFIRMATION.TEXT').subscribe((transitionStr: string) => {
      text = transitionStr;
    });

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      data: {
        text
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.spinner.show();
        this.employeeHistoryService.deleteEmployeeHistory(employeeHistory.employeeHistoryId).subscribe(response => {
            this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.CATEGORY.DELETION_SUCCESS').subscribe((text: string) => {
              this.toastrService.success(text, '', { timeOut: 3000 });
            });

            //We reload the EmployeeSituation history
            this.employeeHistoryService.getHistoryForEmployee(this.employee.employeeId).subscribe(EmployeeSituationHisoryResponse => {
              this.spinner.hide();
              this.employee.employeeHistory = (EmployeeSituationHisoryResponse && EmployeeSituationHisoryResponse.items) || [];

              this.getAndLoadDataOnTable();
            },
            error => {
                this.spinner.hide();
            });
        },
        error => {
          this.spinner.hide();
          this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.CATEGORY.ERROR.UNEXPECTED').subscribe((text: string) => {
            this.toastrService.error(text, '', { timeOut: 3000 });
          });
        });
      }
    });
  }

  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  assigCurrentSituation() {
    if (this.employeeSituations && this.employeeSituations.length > 0 && this.employee && this.employee.employeeHistory) {
      const leaveSituation = _.find(this.employeeSituations, item => item.stateCode == EmployeeSituationCode.LEAVING);

      // Se busca el valor actual
      const currentEmployeeSituationHistory = _(this.employee.employeeHistory).filter(x => moment(x.startDate) <= moment()).maxBy(x => moment(x.startDate).unix());

      if (currentEmployeeSituationHistory) {
        this.employee.employeeSituation = _.find(this.employeeSituations, item => item.employeeSituationId == currentEmployeeSituationHistory.newEmployeeSituationId);
        this.employee.employeeSituationId = currentEmployeeSituationHistory.newEmployeeSituationId;

        if (this.employee.employeeSituationId != leaveSituation.employeeSituationId) {
          this.employee.leaveDate = undefined;
        } else {
          this.employee.leaveDate = currentEmployeeSituationHistory.startDate;
        }
      }
    }
  }

  resetForm() {
    this.showAddPanel = false;
    Object.keys(this.employeeSituationHistoryFormGroup.controls).forEach((name) => {
      const currentControl = this.employeeSituationHistoryFormGroup.controls[name];

      currentControl.setValue('');
    });

    this.employeeSituationHistoryFormGroup.markAsPristine();
    this.employeeSituationHistoryFormGroup.clearValidators();
  }

  /* ****************************************************************************************************************** */
  /* ** EmployeeSituation
  /* ****************************************************************************************************************** */
  getEmployeeHistoryToSend() {
    this.currentEmployeeHistory.employeeId = this.employee.employeeId;
    this.currentEmployeeHistory.startDate = moment(this.employeeSituationHistoryFormGroup.get('startDate').value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.currentEmployeeHistory.finishDate = moment(this.employeeSituationHistoryFormGroup.get('finishDate').value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.currentEmployeeHistory.reason = this.employeeSituationHistoryFormGroup.get('reason').value

    if (!_.isDate(this.currentEmployeeHistory.finishDate)) {
      this.currentEmployeeHistory.finishDate = undefined;
    };
  }

  /* ################################################################################################################## */
  /* ## AUTOCOMPLETE METHODS
  /* ################################################################################################################## */
  // EmployeeSituation
  employeeSituationSelectedOptionChanged(e: any) {
    const item: EmployeeSituation = e.option.value;
    this.currentEmployeeHistory.newEmployeeSituationId = item.employeeSituationId;
    this.currentEmployeeHistory.newEmployeeSituation = item;
  }

  // EmployeeSituation
  employeeSituationDisplay = (item?: EmployeeSituation) => {
    let result = (item)
      ? item.description
      : undefined;

    return result;
  }

  onStartDateSelect(event: any): void {
    const date = event.target.value;
    this.employeeSituationHistoryFormGroup.get('startDate').setValue(moment(date).format('DD/MM/YYYY'));
  }

  onFinishDateSelect(event: any): void {
    const date = event.target.value;
    this.employeeSituationHistoryFormGroup.get('finishDate').setValue(moment(date).format('DD/MM/YYYY'));
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  getEmployeeSituation(employeeSituationId) {
    let employeeSituation = this.employeeSituations.find(element => element.employeeSituationId == employeeSituationId);
    let result = employeeSituation != null ? employeeSituation.description : "";

    return result;
  }

  getCreatedBy(employeeId) {
    let employee = this.employees.find(element => element.employeeId == employeeId);
    let result = (employee != null)
      ? `${employee.secondSurname} ${employee.firstSurname}, ${employee.name}`
      : "";

    return result;
  }

  getDate(date: any, format: string): string {
    let result = getDateTimeString(date, format);
    return result != "Invalid date" ? result : "";
  }

  isCurrentValue(item): boolean {
    const currentEmployeeSituationHistory = _(this.employee.employeeHistory).filter(x => moment(x.startDate) <= moment()).maxBy(x => moment(x.startDate).unix());
    return (currentEmployeeSituationHistory)
      ? currentEmployeeSituationHistory.employeeHistoryId == item.employeeHistoryId
      : false;
  }

  canAdd(): boolean {
    return (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH);
  }

  canDelete(): boolean {
    let result = (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH);

    result = result && this.employee.employeeHistory.length > 1;

    return result;
  }
}

