import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config, AuthService } from 'inzo-portalempleado';
import { PushNotification } from '../models/push-notification.model';

@Injectable({
    providedIn: 'root'
})
export class NotificationMiddlewareService {

    public pushNotificationStatus = {
        isSubscribed: false,
        isSupported: false,
        isInProgress: false
    };
    private swRegistration: ServiceWorkerRegistration = null;
    public notifications = [];

    get baseUrl() {
        return Config.notificationsBaseUrl + '/api';
    }
    constructor(private http: HttpClient,
        private authService: AuthService) { }

    init() {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker.register('/assets/sw.js')
                .then(swReg => {
                    this.swRegistration = swReg;
                    // this.subscribe()
                    this.checkSubscription().then(subs => {
                        this.pushNotificationStatus.isSubscribed = subs;
                        if (!subs) {
                            this.subscribe().then(() => { }).catch(e => { console.error(e); });
                        }
                    });
                })
                .catch(error => {
                    console.error('Service Worker Error', error);
                });
            this.pushNotificationStatus.isSupported = true;
        } else {
            this.pushNotificationStatus.isSupported = false;
        }
    }

    checkSubscription(): Promise<boolean> {
        return this.swRegistration.pushManager.getSubscription()
            .then(subscription => {
                return this.pushNotificationStatus.isSubscribed = !(subscription === null);
            });
    }

    toggleSubscription() {
        if (this.pushNotificationStatus.isSubscribed) {
            this.unsubscribe();
        } else {
            this.subscribe();
        }
    }

    subscribe() {
        this.pushNotificationStatus.isInProgress = true;

        return this.swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.urlB64ToUint8Array(Config.pushServerPublicKey)
        })
            .then(subscription => {
                this.pushNotificationStatus.isSubscribed = true;
                const rawKey = subscription.getKey ? subscription.getKey('p256dh') : '';
                const key = rawKey ? btoa(String.fromCharCode.apply(null, new Uint8Array(rawKey))) : '';
                const rawAuthSecret = subscription.getKey ? subscription.getKey('auth') : '';
                const authSecret = rawAuthSecret ?
                    btoa(String.fromCharCode.apply(null, new Uint8Array(rawAuthSecret))) : '';
                const endpoint = subscription.endpoint;

                const pushNotificationSubscription =
                    new PushNotification(this.authService.currentUser.id, endpoint, key, authSecret);

                const headerDict = {
                    'Authorization': 'Bearer ' + this.authService.accessToken
                };

                const requestOptions = {
                    headers: new HttpHeaders(headerDict)
                };

                this.http.post(`${this.baseUrl}/NotificationPush`,
                    pushNotificationSubscription,
                    requestOptions
                ).subscribe(
                    response => {
                        this.pushNotificationStatus.isInProgress = false;
                    },
                    error => {
                        // TODO : Error message
                        console.log('Ooops something went wrong:', error);
                    });
            }).catch(e => {
                console.log('Error', e);
            });
    }

    unsubscribe() {
        this.pushNotificationStatus.isInProgress = true;
        this.swRegistration.pushManager.getSubscription()
            .then(function (subscription) {
                if (subscription) {
                    return subscription.unsubscribe();
                }
            })
            .catch(function (error) {
                console.log('Error unsubscribing', error);
            })
            .then(() => {
                this.pushNotificationStatus.isSubscribed = false;
                this.pushNotificationStatus.isInProgress = false;
            });
    }

    urlB64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
}
