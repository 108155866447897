import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { WorkCalendarHistory } from 'src/app/models/work-calendar-history.model';

@Injectable({
  providedIn: 'root'
})
export class WorkCalendarHistoryService {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private workCalendarHistorySubject: ReplaySubject<WorkCalendarHistory[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public workCalendarHistoryObservable: Observable<WorkCalendarHistory[]> = this.workCalendarHistorySubject.asObservable();

  private workCalendarHistoryRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public workCalendarHistoryRouteObservable: Observable<string> = this.workCalendarHistoryRouteSubject.asObservable();

  /* ################################################################################################################## */
  get serviceUrl() {
      return Config.apiBaseUrl + '/WorkCalendarHistory';
  }

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(private http: HttpClient) {
  }

  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  /* ------------------------------------------------------------------------------------------------------------------ */
  /* -- READ
  /* ------------------------------------------------------------------------------------------------------------------ */
  getAllWorkCalendarHistory(filter? : string) {
    if (filter == undefined || filter  == null) {
      filter = "";
    }
    let url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getWorkCalendarHistory(filter? : string) {
    if (filter == undefined || filter  == null) {
      filter = "";
    }
    let url = `${this.serviceUrl}${filter}`;

    return this.http.get<WorkCalendarHistory>(url);
  }

  /* ################################################################################################################## */
  /* ## OBSERVERS
  /* ################################################################################################################## */
  updateWorkCalendarHistory(workCalendarHistory: WorkCalendarHistory[]) {
    this.workCalendarHistorySubject.next(workCalendarHistory);
  }

  updateWorkCalendarHistoryRoutes(id: string) {
    this.workCalendarHistoryRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
