import { Employee } from './employee.model';

export class EmployeeManagerHistory {
  employeeManagerHistoryId: string;
  startDate: Date;

  //Relations
  managerId: string;
  employeeId: string;
  employee: Employee;
  manager : Employee;

  constructor(obj?: Partial<EmployeeManagerHistory>) {
    this.employeeManagerHistoryId = (obj && obj.employeeManagerHistoryId) || undefined;
    this.startDate = (obj && obj.startDate) || undefined;

    //Relations
    this.managerId = (obj && obj.managerId) || undefined;
    this.employeeId = (obj && obj.employeeId) || undefined;
    this.employee = (obj && obj.employee) || undefined;
    this.manager = (obj && obj.employee) || undefined;
  }
}
