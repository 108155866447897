import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import { FilterApi } from 'src/app/interfaces/filter-api.interface';
import { Employee } from 'src/app/models/employee.model';
import { FiltersApi } from 'src/app/models/filters-api.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeSelectionService {
  // Refresh
  private refreshEvent = new BehaviorSubject<boolean>(null);
  public refresh = this.refreshEvent.asObservable();

  // FilterApi
  private filterEvent = new BehaviorSubject<FilterApi>(null);
  public filterApi = this.filterEvent.asObservable();

  // FilterListApi
  private filterListApiEvent = new BehaviorSubject<FiltersApi>(null);
  public filterListApi = this.filterListApiEvent.asObservable();

  // Current Employee
  private currentEmployeeEvent: ReplaySubject<Employee> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public currentEmployee: Observable<Employee> = this.currentEmployeeEvent.asObservable();

  // Selection Employees
  private selectionEmployeesEvent: ReplaySubject<Employee[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public selectionEmployees: Observable<Employee[]> = this.selectionEmployeesEvent.asObservable();

  constructor() { }

  // Refresh
  refreshData(data: boolean) {
    this.refreshEvent.next(data);
  }

  // FilterApi
  onChangeFilterApi(data: FilterApi) {
    this.filterEvent.next(data);
  }

  // FilterListApi
  onChangeFilterListApi(data: FiltersApi) {
    this.filterListApiEvent.next(data);
  }

  // Current Employee
  onChangeCurrentEmployee(data: Employee) {
    this.currentEmployeeEvent.next(data);
  }

  // Selection Employees
  onChangeSelectionEmployees(data: Employee[]) {
    this.selectionEmployeesEvent.next(data);
  }

  // Reset Observables
  resetObservables() {
    this.refreshData(false);
    this.onChangeFilterApi(null);
    this.onChangeFilterListApi(null);
    this.onChangeCurrentEmployee(null);
    this.onChangeSelectionEmployees(null);
  }
}
