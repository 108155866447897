import { Component, OnInit, ElementRef, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource, PageEvent } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ResponseNotificationService } from 'inzo-portalempleado';
import { ToastrService } from 'ngx-toastr';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { AbsenceEvent } from 'src/app/interfaces/events.interface';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { AbsenceStateCode, AbsenceStateColor } from 'src/app/models/absence-state-code-enum.model';
import { AbsenceState } from 'src/app/models/absence-state.model';
import { Absence } from 'src/app/models/absence.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { AbsenceChangesService } from 'src/app/services/absence-changes.service';
import { AbsenceService } from 'src/app/services/absence.service';
import { AbsenceDocumentDialogComponent } from '../../absence-document-dialog/absence-document-dialog.component';
import { AbsenceManagementCalendarComponent } from '../absence-management-calendar/absence-management-calendar.component';
import { FiltersApiIds } from 'src/app/models/filters-api-ids.model';
import { AbsenceStateLabel } from 'src/app/interfaces/absence.interface';
import { AbsenceType } from 'src/app/models/absence-type.model';
import { RequestGroupsItems, RequestStateFilters } from 'src/app/interfaces/request.interface';
import { Subscription } from 'rxjs';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeSelectionService } from 'src/app/modules/employee/services/employee-selection.service';
import { AbsenceStateService } from 'src/app/services/absence-state.service';
import { AbsenceTypeService } from 'src/app/services/absence-type.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmployeeSituationCode } from 'src/app/models/employee-situation-enum.model';

@Component({
  selector: 'app-absence-management-list',
  templateUrl: 'absence-management-list.component.html',
  styleUrls: [
    './../../maintenance.components.css',
    '../../maintenance-list.component.css',
    './absence-management-list.component.css',
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AbsenceManagementListComponent implements OnInit {
  @Input() filtersRequestsEmployeeIds: FiltersApiIds = new FiltersApiIds();

  @Input() absenceStates: AbsenceState[] = [];
  @Input() allAbsenceTypes: AbsenceType[] = [];
  @Input() requestStateFilters: RequestStateFilters;

  @Input() visibleStatesCalendar: AbsenceStateCode[] = [];
  filtersApi: FiltersApi = new FiltersApi();

  subscriptions: Subscription[] = [];

  currentEmployee: Employee;

  searching = true;
  filter = '';

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  @Input() calendar: AbsenceManagementCalendarComponent;
  @Output() actionAbsence: EventEmitter<AbsenceEvent> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  absenceStateLabel: AbsenceStateLabel;

  // Permissions
  canDirectAssignment: boolean = false;
  directAssignment: boolean = false;

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  // ******************************************************************************************
  // Table
  // ******************************************************************************************
  columnsToDisplay = ['owner', 'counter', 'icon'];
  expandedElement: any;

  dataSource: MatTableDataSource<RequestGroupsItems> = new MatTableDataSource([]);
  dataPageSize: number = 10;
  pageSizeOptions = [10, 20, 30, 40];
  pageIndex = 0;
  pageSize  = 0;
  pageLength  = 0;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // ******************************************************************************************

  constructor(
    protected employeeSelectionService: EmployeeSelectionService,
    protected absenceChangesService: AbsenceChangesService,
    protected absenceService: AbsenceService,
    public absenceStateService: AbsenceStateService,
    public absenceTypeService: AbsenceTypeService,
    protected spinner: NgxSpinnerService,
    protected RNService: ResponseNotificationService,
    protected dialog: MatDialog,
    protected translate: TranslateService,
    protected toastrService: ToastrService,
  ) { }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.setDefaultFilters();

    this.subscriptions.push(
      this.absenceChangesService.refresh.subscribe(refresh => {
        if (refresh == true) {
          this.spinner.show();
          this.loadData();
        }
      })
    );

    this.subscriptions.push(
      this.absenceChangesService.canDirectAssignment.subscribe(checked => {
        this.canDirectAssignment = checked;
      })
    );

    this.subscriptions.push(
      this.absenceChangesService.check.subscribe(checked => {
        this.directAssignment = checked;
      })
    );

    this.subscriptions.push(
      this.absenceChangesService.selectedStateRequest.subscribe(data => {
        this.absenceStateLabel = data;

        if (data) {
          this.setCurrentFilters();
          this.loadData();
        }
      })
    );

    this.subscriptions.push(
      this.employeeSelectionService.refresh.subscribe((response) => {
        if (response == true) {
          this.spinner.show();
          this.loadData();
        }
      })
    );

    this.subscriptions.push(
      this.employeeSelectionService.filterListApi.subscribe((filtersApi) => {
        if (filtersApi !== null && filtersApi.filters.length > 0) {
          for (let filter of filtersApi.filters) {
            this.filtersApi.add(filter, true);
          }

          this.loadData();
        }
      })
    );

    this.subscriptions.push(
      this.employeeSelectionService.currentEmployee.subscribe((response) => {
        this.currentEmployee = response;
      })
    );

    this.subscriptions.push(
      this.employeeSelectionService.selectionEmployees.subscribe((response) => {
        let employeeIds: string[] = [];

        this.filtersRequestsEmployeeIds.reset();

        for (let index in response) {
          let item = response[index] as Employee;
          employeeIds.push(item.employeeId);
        }

        this.filtersRequestsEmployeeIds.addMultiple(employeeIds);
        this.loadData();
      })
    );

    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    this.employeeSelectionService.resetObservables();
  }
  //#endregion

  refresh() {
    this.refreshData.emit(true);
  }

  loadData() {
    this.spinner.show();

    this.subscriptions.push(
      this.absenceService.getAllAbsenceHierarchyGroupBy(this.filtersApi.getStrinFilterApi(), this.filtersRequestsEmployeeIds.filters).subscribe(absencesGroupGroups => {
        this.spinner.show();

        const {
          items,
          currentPage,
          pageCount,
          pageSize,
          totalCount,
          ...resto
        } = absencesGroupGroups;

        this.pageIndex = (currentPage > 0)
          ? currentPage - 1
          : currentPage; // El contador de páginas de Inzo empieza en 1 y el de MatPaginator en 0
        this.pageSize = pageSize;
        this.pageLength = totalCount;

        this.dataSource.data = items;

        if (this.currentEmployee) {
          for(let item of items) {
            const tempAbsence = item[0];
            if (tempAbsence.employeeId == this.currentEmployee.employeeId) {
              this.absenceChangesService.onChangeAbsences(item);
              break;
            }
          }
        } else {
          this.absenceChangesService.onChangeAbsences(null);
        }

        this.dataSource.filterPredicate = (data: RequestGroupsItems, filter: string): boolean => {
          const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
            return (currentTerm + (data as { [key: string]: any })[key] + '◬');
          }, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

          const transformedFilter = filter.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

          return dataStr.indexOf(transformedFilter) !== -1;
        };

        this.spinner.hide();
      })
    );
  }

  setCurrentFilters() {
    this.setApiPaginator(1);

    if (this.absenceStateLabel && this.absenceStateLabel.stateName != "ALL") {
      this.filtersApi.add(
        {
          field: "partialStateRequest",
          value: this.absenceStateLabel.stateName
        },
        true
      );
    } else {
      this.filtersApi.delete("partialStateRequest");
    }
  }

  setDefaultFilters() {
    this.setApiPaginator(1);

    if (this.absenceStateLabel && this.absenceStateLabel.stateName != "ALL") {
      this.filtersApi.add(
        {
          field: "partialStateRequest",
          value: this.absenceStateLabel.stateName
        },
        true
      );
    } else {
      this.filtersApi.delete("partialStateRequest");
    }

    this.filtersApi.add(
      {
        field: "validator",
        value: "true"
      },
      true
    );

    this.filtersApi.add(
      {
        field: "hierarchy",
        value: "false"
      },
      true
    );

    this.filtersApi.add(
      {
        field: "related",
        value: "true"
      },
      true
    );

    this.filtersApi.add(
      {
        field: "management",
        value: "true"
      },
      true
    );

    this.filtersApi.add(
      {
        field: "getAll",
        value: "false"
      },
      true
    );

    // Se incluye isMedicalLeave a false para no obtener las bajas IT.
    this.filtersApi.add(
      {
        field: "isMedicalLeave",
        value: "false"
      },
      true
    );
  }

  getOwnerName(element) {
    return `(${element[0].employee.code}) ${element[0].employee.firstSurname} ${element[0].employee.secondSurname}, ${element[0].employee.name}`;
  }

  getRequestCounter(element) {
    return element.length;
  }

  assignAbsencesStates(items) {
    for (let item of items) {
      for (let absence of item) {
        // Se recupera el estado
        absence.absenceState = this.absenceStates.find(element => element.absenceStateId == absence.absenceStateId);
        absence.absenceType = this.allAbsenceTypes.find(element => element.absenceTypeId == absence.absenceTypeId);
      }
    }

    return items;
  }

  /**
   * Recoge un evento del componente de listado de solicitudes y ejecuta la acción correspondiente a ese evento.
   *
   * @param absenceEvent evento recogido
   */
  actionAbsenceEvent(absenceEvent: AbsenceEvent) {
    this.actionAbsence.emit(absenceEvent);
  }

  /**
   * Pasa la solicitud de ausencias recibida como param a estado 'CANCELED'
   * @param absence - La solicitud de ausencias que será pasada al estado 'CANCELED'
   */
  abortAbsence(absence: Absence): void {
    this.actionAbsence.emit(
      {
        absence,
        eventType: "CANCELED"
      }
    );
  }

  /**
   * Pasa la solicitud de ausencias recibida como param a estado 'APPROVED' o 'ANNULLED'
   *
   * @param absence - La solicitud de ausencias que será pasada al estado 'APPROVED' o 'ANNULLED'
   */
  approveAbsence(absence: Absence): void {
    let eventType = "APPROVED";

    if (absence.absenceState.stateCode == AbsenceStateCode.PENDING_ANNULLED) {
      eventType = "ANNULLED";
    }

    this.actionAbsence.emit(
      {
        absence,
        eventType
      }
    );
  }

  /**
   * Pasa la solicitud de ausencias recibida como param a estado 'REJECTED', para ello previamente se abrirá un
   * dialog en el que se deberá escribir la razón por la cual se rechaza la solicitud.
   *
   * @param absence - La solicitud de ausencias a cancelar
   */
  rejectAbsence(absence: Absence): void {
    let eventType = "REJECTED";

    if (absence.absenceState.stateCode == AbsenceStateCode.PENDING_ANNULLED) {
      // Si se niega la anulación pasa a estado aprobado (se supo que sólo se puede solicitar la anulación de una solicitud aprobada)
      eventType = "APPROVED";
    }

    this.actionAbsence.emit(
      {
        absence,
        eventType
      }
    );
  }

  //#region PAGINATOR
  /* ################################################################################################################## */
  /* ## PAGINATOR
  /* ################################################################################################################## */
  setApiPaginator(currentPage: number, pageSize: number = this.dataPageSize) {
    if (pageSize != 0) {
      this.filtersApi.add(
        {
          field: "currentPage",
          value: currentPage.toString()
        },
        true
      );
      this.filtersApi.add(
        {
          field: "pageSize",
          value: pageSize.toString()
        },
        true
      );
    } else {
      this.resetPaginator();
    }
  }

  resetPaginator() {
    this.paginator.firstPage();
    this.paginator.length = 0;
    this.paginator.pageSize = this.dataPageSize;

    this.filtersApi.add(
      {
        field: "currentPage",
        value: "0"
      },
      true
    );
    this.filtersApi.add(
      {
        field: "pageSize",
        value: this.dataPageSize.toString()
      },
      true
    );
  }

  getDataPage(event: PageEvent): PageEvent {
    let goPage = -1;

    if(event.pageIndex > this.pageIndex) {
      goPage = 1;
    }

    goPage = (goPage < 0)
      ? 0
      : goPage;

    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.pageLength = event.length;

    this.setApiPaginator(this.paginator.pageIndex + goPage, this.paginator.pageSize);

    this.loadData();
    return event;
  }
  //#endregion


  /**
   * Función que determina si se deben mostrar los botones de aprobar o rechazar la solicitud
   *
   * @param absence - solicitud a valorar por la condición que dictará si mostrar el botón o no.
   * @returns
   */
   showApprovalButton(absence: Absence): boolean {
    let result = false;

    if(
      absence.absenceState.stateCode == AbsenceStateCode.PENDING_ANNULLED ||
      absence.absenceState.stateCode == AbsenceStateCode.PENDING_APROVAL
    ) {
      result = true;
    }

    return result;
  }

  /**
   * Función que muestra el botón de cancelar solicitud de vacaciones en cuanto a una condición
   *
   * @param absence - solicitud a valorar por la condición que dictará si mostrar el botón o no.
   * @returns
   */
   showCancelButton(absence: Absence): boolean {
    //const showButton = (moment(absence.startDate).isAfter(moment())) ? true : false;
    //se comenta la validación para que responsables y rrhh puedan anular en cualquier momento.
    let result = false;

    if(
      absence.absenceState.stateCode == AbsenceStateCode.PENDING_ANNULLED ||
      absence.absenceState.stateCode == AbsenceStateCode.PENDING_APROVAL
    ) {
      result = true;
    }

    return result;
  }

  // CALENDAR
  /**
   * Determina si se puede mostrar o no la solicitud en el calendario
   */
  toggleAbsenceLoadOnCalendar(absence: Absence) {
    if (this.visibleStatesCalendar.includes(absence.absenceState.stateCode)) {
      this.calendar.toggleAbsenceLoadOnCalendar(absence);
    }
  }

  /**
   * Selecciona las clases que debe tener la solicitud para su visualización correcta
   */
  getClassesItem(absence: Absence) {
    let assignedClasses = "mat-line my-auto";

    if (this.visibleStatesCalendar.includes(absence.absenceState.stateCode)) {
      assignedClasses += " pointer";

      assignedClasses += (absence.visibleOnYearCalendar) ? '' : ' unselect';
    }

    return assignedClasses;
  }

  /**
   * Selecciona el color del estado de la solicitud
   */
  getColorItem(absence: Absence) {
    let color = "";

    if (this.visibleStatesCalendar.includes(absence.absenceState.stateCode) && absence.visibleOnYearCalendar) {
      let stateName = AbsenceStateCode[absence.absenceState.stateCode];

      color = AbsenceStateColor[stateName];
    }

    return color;
  }

  /**
   * Abre una ventana de diálogo para opererar con los documentos asociados a una ausencia.
   *
   * @param absence ausencia para gestionar
   */
  openDocumentsDialog(absence: Absence) {
    let filtersApi: FiltersApi = new FiltersApi();

    filtersApi.add(
      {
        field: "related",
        value: "true"
      }
    );
    filtersApi.add(
      {
        field: "employeeId",
        value: absence.employeeId
      }
    );

    const absenceDocumentDialog = this.dialog.open(AbsenceDocumentDialogComponent, {
      width: '850px',
      data: {
        absenceService        : this.absenceService,
        translate             : this.translate,
        toastrService         : this.toastrService,
        RNService             : this.RNService,

        absence               : absence,
        filtersApi            : filtersApi,
      }
    });

    absenceDocumentDialog.afterClosed().subscribe((response) => {
      this.refresh();
    });
  }
}
