import { Component, OnInit, ElementRef, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ResponseNotificationService } from 'inzo-portalempleado';
import { ToastrService } from 'ngx-toastr';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { AbsenceEvent } from 'src/app/interfaces/events.interface';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { AbsenceStateCode, AbsenceStateColor } from 'src/app/models/absence-state-code-enum.model';
import { AbsenceState } from 'src/app/models/absence-state.model';
import { Absence } from 'src/app/models/absence.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { AbsenceChangesService } from 'src/app/services/absence-changes.service';
import { AbsenceService } from 'src/app/services/absence.service';
import { AbsenceDocumentDialogComponent } from '../../absence-document-dialog/absence-document-dialog.component';
import { AbsenceManagementCalendarComponent } from '../../absence-management/absence-management-calendar/absence-management-calendar.component';

@Component({
  selector: 'app-absence-list',
  templateUrl: 'absence-list.component.html',
  styleUrls: ['./../../maintenance.components.css', '../../maintenance-list.component.css', './absence-list.component.css'],
})
export class AbsenceListComponent {
  @Input() employeeView = false;
  @Input() absences: Absence[] = [];
  @Input() absenceStates: AbsenceState[] = [];

  @Input() visibleStatesCalendar: AbsenceStateCode[] = [];
  @Input() filtersApi: FiltersApi = new FiltersApi();

  searching = true;
  filter = '';

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  @Input() calendar: AbsenceManagementCalendarComponent;
  @Output() actionAbsence: EventEmitter<AbsenceEvent> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();

  // Permissions
  canDirectAssignment: boolean = false;
  directAssignment: boolean = false;

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  constructor(
    protected dialog: MatDialog,
    protected absenceService: AbsenceService,
    protected RNService: ResponseNotificationService,
    protected translate: TranslateService,
    protected toastrService: ToastrService,
    protected absenceChangesService: AbsenceChangesService,
  ) { }

  ngOnInit() {
    this.absenceChangesService.canDirectAssignment.subscribe(checked => {
      this.canDirectAssignment = checked;
    });
    this.absenceChangesService.check.subscribe(checked => {
      this.directAssignment = checked;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  refresh() {
    this.refreshData.emit(true);
  }

  /**
   * Recoge un evento del componente de listado de solicitudes y ejecuta la acción correspondiente a ese evento.
   *
   * @param absenceEvent evento recogido
   */
  actionAbsenceEvent(absenceEvent: AbsenceEvent) {
    this.actionAbsence.emit(absenceEvent);
  }

  /**
   * Pasa la solicitud de ausencias recibida como param a estado 'CANCELED'
   * @param absence - La solicitud de ausencias que será pasada al estado 'CANCELED'
   */
  abortAbsence(absence: Absence): void {
    this.actionAbsence.emit(
      {
        absence,
        eventType: "CANCELED"
      }
    );
  }

  // CALENDAR
  /**
   * Determina si se puede mostrar o no la solicitud en el calendario
   */
  toggleAbsenceLoadOnCalendar(absence: Absence) {
    if (this.visibleStatesCalendar.includes(absence.absenceState.stateCode)) {
      this.calendar.toggleAbsenceLoadOnCalendar(absence);
    }
  }

  /**
   * Selecciona las clases que debe tener la solicitud para su visualización correcta
   */
  getClassesItem(absence: Absence) {
    let assignedClasses = "mat-line my-auto";

    if (this.visibleStatesCalendar.includes(absence.absenceState.stateCode)) {
      assignedClasses += " pointer";

      assignedClasses += (absence.visibleOnYearCalendar) ? '' : ' unselect';
    }

    return assignedClasses;
  }

  /**
   * Selecciona el color del estado de la solicitud
   */
  getColorItem(absence: Absence) {
    let color = "";

    if (this.visibleStatesCalendar.includes(absence.absenceState.stateCode) && absence.visibleOnYearCalendar) {
      let stateName = AbsenceStateCode[absence.absenceState.stateCode];

      color = AbsenceStateColor[stateName];
    }

    return color;
  }

  /**
   * Abre una ventana de diálogo para opererar con los documentos asociados a una ausencia.
   *
   * @param absence ausencia para gestionar
   */
  openDocumentsDialog(absence: Absence) {
    let filtersApi: FiltersApi = new FiltersApi();

    filtersApi.add(
      {
        field: "related",
        value: "true"
      }
    );
    filtersApi.add(
      {
        field: "employeeId",
        value: absence.employeeId
      }
    );

    const absenceDocumentDialog = this.dialog.open(AbsenceDocumentDialogComponent, {
      width: '850px',
      data: {
        absenceService: this.absenceService,
        translate: this.translate,
        toastrService: this.toastrService,
        RNService: this.RNService,

        absence: absence,
        filtersApi: filtersApi,
      }
    });

    absenceDocumentDialog.afterClosed().subscribe((response) => {
      this.refresh();
    });
  }

  /**
   * Función que determina si se deben mostrar el botón de enviar la solicitud
   *
   * @param absence - solicitud a valorar por la condición que dictará si mostrar el botón o no.
   * @returns
   */
  showSendButton(absence: Absence): boolean {
    return absence.absenceState.stateCode == AbsenceStateCode.REGISTERED;
  }
  /**
   * Función que determina si se deben mostrar el botón de cancelar la solicitud
   *
   * @param absence - solicitud a valorar por la condición que dictará si mostrar el botón o no.
   * @returns
   */
  showCancelButton(absence: Absence): boolean {
    let result = false;
    if (
      !absence.isMedicalLeave &&
      (
        absence.absenceState.stateCode == AbsenceStateCode.APPROVED ||
        absence.absenceState.stateCode == AbsenceStateCode.PENDING_APROVAL ||
        absence.absenceState.stateCode == AbsenceStateCode.REGISTERED
      )
    ) {
      result = true;
    }

    return result;
  }


  /**
   * Pasa la solicitud de vacaciones recibida como param a estado 'REJECTED', para ello previamente se abrirá un
   * dialog en el que se deberá escribir la razón por la cual se rechaza la solicitud.
   *
   * @param absence - La solicitud de vacaciones a cancelar
   */
  cancelAbsence(absence: Absence): void {
    let eventType = "CANCELED";

    if (
      absence.absenceState.stateCode == AbsenceStateCode.APPROVED
    ) {
      eventType = "PENDING_ANNULLED";
    }

    this.actionAbsence.emit(
      {
        absence,
        eventType
      }
    );
  }

  /**
   * Pasa la solicitud de vacaciones recibida como param a estado 'REJECTED', para ello previamente se abrirá un
   * dialog en el que se deberá escribir la razón por la cual se rechaza la solicitud.
   *
   * @param absence - La solicitud de vacaciones a cancelar
   */
  sendAbsence(absence: Absence): void {
    this.actionAbsence.emit(
      {
        absence,
        eventType: "PENDING_APROVAL"
      }
    );
  }
}
