//#region GENERIC IMPORTS
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as jsonpatch from 'fast-json-patch';
//#endregion

//#region CUSTOM IMPORTS
import { AppPermissions } from 'src/app/models/app-permission.model';
import { BaseFormComponent, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { Course } from 'src/app/models/course.model';
import { CourseConfirmationDeleteDialogComponent } from '../course-confirmation-delete-dialog/course-confirmation-delete-dialog.component';
import { CourseManagementInfo } from 'src/app/models/course-info.model';
import { CourseService } from 'src/app/services/course.service';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeCourse } from 'src/app/models/employee-course.model';
import { EmployeeSelectAssociateConfirmDialogComponent } from '../../employee-select-associate-confirm-dialog/employee-select-associate-confirm-dialog.component';
import { EmployeeSelectDialogComponent } from '../../employee-select-dialog/employee-select-dialog.component';
import { getDateString } from 'src/app/helpers/date.helper';
//#endregion

@Component({
  selector: 'app-course-form',
  templateUrl: 'course-form.component.html',
  styleUrls: [
    './course-form.component.css',
    './../../maintenance.components.css',
  ],
})
export class CourseFormComponent extends BaseFormComponent<Course> implements OnInit, OnDestroy {
  //#region ATRRIBUTES
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  //#region CUSTOM
  @Input() isCertificationCourse = false;
  selectedDesc: string = undefined;
  course: Course;
  courseManagementInfo: CourseManagementInfo;

  roles: Role[];

  permissionsLoaded = false;
  subscription: Subscription;

  canAdd = false;
  //#endregion

  //#region INHERITED
  /* ****************************************************************************************************************** */
  /* ** INHERITED
  /* ****************************************************************************************************************** */
  enabled = true;
  detail = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;
  //#endregion

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  formGroupControl = new FormGroup({
    code: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
  });
  //#endregion
  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    protected toastrService: ToastrService,
    protected courseService: CourseService,
    public accountService: AccountService,
    protected route: ActivatedRoute,
    public router: Router,
    public hotkeys: Hotkeys,
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected spinner: NgxSpinnerService,
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
    }
    this.PK = 'courseId';

    this.baseRoute = '/formations/course';

    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('COURSES_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
    this.init();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          if (
            this.accountService.currentUser.isAdmin ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RA) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RP)
          ) {
          }
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  //#region DATA MANIPULATION
  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  protected init() {
    this.formGroupControl.get('code').disable();
    this.formGroupControl.get('description').disable();
    this.course = new Course();
    this.subscription = this.route.paramMap.subscribe(params => {
      const transId = params.get('id');
      this.courseService.updateCourseRoutes(transId);

      if (transId) {
        this.courseService.getInfo(this.isCertificationCourse, (transId != 'new') ? transId : null).subscribe(response => {
          this.courseManagementInfo = response;

          if (transId === 'new') {
            this.detail = false;
            this.enabled = true;
            this.course = new Course();
            this.selectedDesc = undefined;
            this.formGroupControl.setValue({ code: '', description: '' });
            this.formGroupControl.get('code').enable();
            this.formGroupControl.get('description').enable();
          } else {
            this.detail = true;
            this.enabled = false;
            this.course = response.course;
            this.selectedDesc = this.course.description;
            this.formGroupControl.setValue({ code: this.course.code, description: this.course.description });
            this.formGroupControl.get('code').disable();
            this.formGroupControl.get('description').disable();
          }
        });
      } else {
        this.selectedDesc = undefined;
        this.detail = false;
        this.enabled = false;
        this.course = new Course();
      }
    });
  }
  //#endregion

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  edit() {
    this.enabled = true;
    // this.formGroupControl.get('code').enable();
    this.formGroupControl.get('description').enable();
  }

  cleanForm() {
    this.formGroupControl.setValue({ code: '', description: '' });
  }

  cancel() {
    this.cleanForm();
    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }

  back() {
    this.cancel();
  }
  //#endregion
  //#endregion

  //#region CRUD
  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  //#region BASIC
  /* ****************************************************************************************************************** */
  /* ** CREATE
  /* ****************************************************************************************************************** */
  create() {
    let courseType = (this.isCertificationCourse)
      ? 'Certificación'
      : 'Sin asignar';

    this.course = new Course({
      code: this.formGroupControl.get('code').value,
      description: this.formGroupControl.get('description').value,
      courseTypeId: this.courseManagementInfo.allCourseTypes.find(element => element.name == courseType).courseTypeId,
    });

    this.courseService.createCourse(this.course).subscribe(
      response => {
        if (this.isCertificationCourse) {
          this.courseService.getAllCertificationCourses().subscribe(courses => {
            this.courseService.updateCourses(courses.items);
          });
        } else {
          this.courseService.getAllCourses().subscribe(courses => {
            this.courseService.updateCourses(courses.items);
          });
        }
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.CREATE_LONG', {
            type: this.componentTypeTranslated,
            code: this.course.description,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.cancel();
      },
      error => {
        if (error.status === 500 || error.status === 0) {
        } else if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
        }
      },
    );
  }

  /* ****************************************************************************************************************** */
  /* ** UPDATE
  /* ****************************************************************************************************************** */
  update() {
    const observer = jsonpatch.observe(this.course);
    this.course.code = this.formGroupControl.get('code').value;
    this.course.description = this.formGroupControl.get('description').value;
    const patch = jsonpatch.generate(observer);

    this.courseService.modifyCourse(this.course.courseId, patch).subscribe(
      response => {
        if (this.isCertificationCourse) {
          this.courseService.getAllCertificationCourses().subscribe(courses => {
            this.courseService.updateCourses(courses.items);
          });
        } else {
          this.courseService.getAllCourses().subscribe(courses => {
            this.courseService.updateCourses(courses.items);
          });
        }
        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );
  }

  /* ****************************************************************************************************************** */
  /* ** DELETE
  /* ****************************************************************************************************************** */
  delete() {
    const confirmationDialog = this.dialog.open(CourseConfirmationDeleteDialogComponent, {
      width: '550px',
      data: {
        description: this.course.description
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.courseService.deleteCourse(this.course.courseId).subscribe(response => {
          if (this.isCertificationCourse) {
            this.courseService.getAllCertificationCourses().subscribe(courses => {
              this.courseService.updateCourses(courses.items);
            });
          } else {
            this.courseService.getAllCourses().subscribe(courses => {
              this.courseService.updateCourses(courses.items);
            });
          }

          let toastTitle = '';
          let toastBody = '';
          this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {
            toastTitle = a;
          });
          this.translate.get('MESSAGES.SUCCESS.DELETE_LONG', { type: this.componentTypeTranslated, code: this.course.description }).subscribe((a: string) => {
            toastBody = a;
          });
          this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
          this.cancel();
        });
      }
    });

  }
  //#endregion

  //#region OTHERS
  /* ****************************************************************************************************************** */
  /* ** OTHERS
  /* ****************************************************************************************************************** */
  associateEmployee() {
    const employeeSelectionDialog = this.dialog.open(EmployeeSelectDialogComponent, {
      maxWidth: '80%',
      maxHeight: '65%',
      data: {
        employees: this.course.employeeCourses
      }
    });

    employeeSelectionDialog.afterClosed().subscribe(async (selection: Employee) => {
      if (selection) {
        let text: string;
        this.translate.get('ASSOCIATE_EMPLOYEES.DIALOG_CONFIRMATION.TEXT_ADD', {
          employee: `${selection.firstSurname} ${selection.secondSurname}, ${selection.name}`
        }).subscribe((a: string) => {
          text = a;
        });
        const confirmationDialog = this.dialog.open(EmployeeSelectAssociateConfirmDialogComponent, {
          width: '550px',
          data: {
            asassociate: true,
            text
          }
        });

        confirmationDialog.afterClosed().subscribe(async (confirm) => {
          if (confirm != false) {
            this.spinner.show();

            this.courseService.associateCourse(
              this.course.courseId,
              selection.employeeId,
              confirm.trainingCenterId,
              confirm.finishDate.toISOString()
            ).subscribe(
              response => {
                if (this.isCertificationCourse) {
                  this.courseService.getCertificationCourse(this.course.courseId, true).subscribe(course => {
                    this.course = course;
                    let title: string;
                    let msg: string;

                    this.translate.get('MESSAGES.SUCCESS.UPDATE_LONG_SIMPLE', { type: this.course.description }).subscribe((a: string) => {
                      title = a;
                    });

                    this.spinner.hide();
                    this.toastrService.success(msg, title, { timeOut: 3000 });
                  });
                } else {
                  this.courseService.getCourse(this.course.courseId, true).subscribe(course => {
                    this.course = course;
                    let title: string;
                    let msg: string;

                    this.translate.get('MESSAGES.SUCCESS.UPDATE_LONG_SIMPLE', { type: this.course.description }).subscribe((a: string) => {
                      title = a;
                    });

                    this.spinner.hide();
                    this.toastrService.success(msg, title, { timeOut: 3000 });
                  });
                }
              },
              error => {
                let title: string;

                this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
                  title = a;
                });

                this.spinner.hide();
                this.toastrService.error(error.error, title, { timeOut: 3000 });
              },
            );
          }
        });
      }
    });
  }

  disassociateEmployee(employeeCourse: EmployeeCourse) {
    let text: string;
    this.translate.get('ASSOCIATE_EMPLOYEES.DIALOG_CONFIRMATION.TEXT_REMOVE', {
      employee: `${employeeCourse.employee.firstSurname} ${employeeCourse.employee.secondSurname}, ${employeeCourse.employee.name}`
    }).subscribe((a: string) => {
      text = a;
    });

    const confirmationDialog = this.dialog.open(EmployeeSelectAssociateConfirmDialogComponent, {
      width: '550px',
      data: {
        asassociate: false,
        text
      }
    });

    confirmationDialog.afterClosed().subscribe(async (confirm) => {
      if (confirm == true) {
        this.spinner.show();

        this.courseService.disassociateCourse(
          this.course.courseId,
          employeeCourse.employeeId
        ).subscribe(
          response => {
            if (this.isCertificationCourse) {
              this.courseService.getCertificationCourse(this.course.courseId, true).subscribe(course => {
                this.course = course;
                let title: string;
                let msg: string;

                this.translate.get('MESSAGES.SUCCESS.DELETE_LONG_SIMPLE', { type: this.course.description }).subscribe((a: string) => {
                  title = a;
                });

                this.spinner.hide();
                this.toastrService.success(msg, title, { timeOut: 3000 });
              });
            } else {
              this.courseService.getCourse(this.course.courseId, true).subscribe(course => {
                this.course = course;
                let title: string;
                let msg: string;

                this.translate.get('MESSAGES.SUCCESS.DELETE_LONG_SIMPLE', { type: this.course.description }).subscribe((a: string) => {
                  title = a;
                });

                this.spinner.hide();
                this.toastrService.success(msg, title, { timeOut: 3000 });
              });
            }
          },
          error => {
            let title: string;

            this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
              title = a;
            });

            this.spinner.hide();
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          },
        );
      }
    });
  }
  //#endregion
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  getFinishDate(employeeCourse: EmployeeCourse) {
    return employeeCourse && employeeCourse.finishDate
      ? getDateString(employeeCourse.finishDate, "DD/MM/YYYY")
      : "";
  }

  getTitle(): string {
    let title = this.selectedDesc
      ? 'COURSES_MAINTENANCE.FORM.HEADER'
      : 'COURSES_MAINTENANCE.FORM.HEADER_NEW';

    if (this.isCertificationCourse) {
      title += '_CERTIFICATION';
    }

    this.translate.get(`${title}`, { value: this.selectedDesc }).subscribe((a: string) => {
      title = a;
    });

    return title;
  }

  getTranslation(label): string {
    let msg = `COURSES_MAINTENANCE.${label}`;

    if (this.isCertificationCourse) {
      msg += '_CERTIFICATION';
    }

    this.translate.get(`${msg}`, { value: this.selectedDesc }).subscribe((a: string) => {
      msg = a;
    });

    return msg;
  }
  //#endregion
}
