import { Component, OnInit,AfterViewInit } from '@angular/core';
import { CourseTypeService } from 'src/app/services/course-type.service';

@Component({
  selector: 'app-course-type',
  templateUrl: './course-type.component.html',
  styleUrls: ['./course-type.component.css']
})
export class CourseTypeComponent implements  OnInit ,AfterViewInit{

  showForm = false;
  constructor(private courseTypeService: CourseTypeService) { }

  ngAfterViewInit() {
    this.courseTypeService.courseTypeRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
  ngOnInit() {
  }

}
