import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { WorkCalendar } from '../models/work-calendar.model';


@Injectable({ providedIn: 'root' })
export class WorkCalendarService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private workCalendarSubject: ReplaySubject<WorkCalendar[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public workCalendarObservable: Observable<WorkCalendar[]> = this.workCalendarSubject.asObservable();

    private workCalendarRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public workCalendarRouteObservable: Observable<string> = this.workCalendarRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/workCalendar';
    }

    constructor(private http: HttpClient) {
    }

    getAllWorkCalendar(filter?: string) {
      if (filter == undefined || filter == null) {
        filter = "";
      }

      let url = `${this.serviceUrl}${filter}`;

      return this.http.get<any>(url);
    }

    getWorkCalendar(workCalendarId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<WorkCalendar>(`${this.serviceUrl}/${workCalendarId}${relatedFilter}`);
    }

    createWorkCalendar(workCalendar: WorkCalendar) {
        const endpointUrl = `${this.serviceUrl}`;
        return this.http.post(endpointUrl, workCalendar);
    }

    duplicateWorkCalendar(workCalendarId: string, workCalendar: any = {}) {
      const endpointUrl = `${this.serviceUrl}/Duplicate/${workCalendarId}`;

      return this.http.post<WorkCalendar>(endpointUrl, workCalendar);
    }

    importEventsFromWorkCalendars(destinationWorkCalendarId: string, sourceWorkCalendarIds: string[], workCalendar: any = {}) {
      const endpointUrl = `${this.serviceUrl}/ImportEvents/${destinationWorkCalendarId}/sources/${JSON.stringify(sourceWorkCalendarIds)}`;
      return this.http.post<WorkCalendar>(endpointUrl, workCalendar);
    }

    modifyWorkCalendar(workCalendarId: string, workCalendar: any) {
        const endpointUrl = `${this.serviceUrl}/${workCalendarId}`;
        return this.http.patch<WorkCalendar>(endpointUrl, workCalendar);
    }
    deleteWorkCalendar(workCalendarId: string) {
        const endpointUrl = `${this.serviceUrl}/${workCalendarId}`;
        return this.http.delete(endpointUrl);
    }

    updateWorkCalendars(workCalendars: WorkCalendar[]) {
        this.workCalendarSubject.next(workCalendars);
    }

    updateWorkCalendarRoutes(id: string) {
        this.workCalendarRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}
