import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, Hotkeys, ResponseNotificationService, SecurityStateManagementService } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AbsenceStateCode } from 'src/app/models/absence-state-code-enum.model';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { AbsenceStateService } from 'src/app/services/absence-state.service';
import { AbsenceTypeService } from 'src/app/services/absence-type.service';
import { AbsenceService } from 'src/app/services/absence.service';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { AbsenceManagementGroupsComponent } from '../../absence-management/absence-management-groups/absence-management-groups.component';
import { EmployeeSelectionService } from 'src/app/modules/employee/services/employee-selection.service';
import { AbsenceChangesService } from 'src/app/services/absence-changes.service';

@Component({
  selector: 'app-absence-groups',
  templateUrl: './absence-groups.component.html',
  styleUrls: ['./absence-groups.component.css']
})
export class AbsenceGroupsComponent extends AbsenceManagementGroupsComponent {
  constructor(
    protected absenceService: AbsenceService,
    public absenceStateService: AbsenceStateService,
    public absenceTypeService: AbsenceTypeService,
    public absenceChangesService: AbsenceChangesService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    protected RNService: ResponseNotificationService,
    public accountService: AccountService,
    public employeeManagementService: EmployeeManagementService,
    public hotkeys: Hotkeys,
    protected spinner: NgxSpinnerService,
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected toastrService: ToastrService,
    protected employeeSelectionService: EmployeeSelectionService,
    protected permissionsService: PermissionsService,
  ) {
    super(
      absenceService,
      absenceStateService,
      absenceTypeService,
      absenceChangesService,
      route,
      router,
      SSMService,
      RNService,
      accountService,
      employeeManagementService,
      hotkeys,
      spinner,
      translate,
      dialog,
      toastrService,
      employeeSelectionService,
      permissionsService,
    );

    this.baseRoute = '/actions/absence';

    this.employeeView = true;

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.Empleado,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.Empleado,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.Empleado,
      AppPermissions.ROLE_DATA.Empleado
    ];

    // Modifica las solicitudes a mostrar
    this.tabStates.unshift("REGISTERED");
    this.excludeStates.shift();
    this.visibleStatesCalendar.unshift(AbsenceStateCode.REGISTERED);

    this.loadData = () => {
      this.spinner.show();
      const userId = this.accountService.currentUser.id;

      this.subscriptions.push(
        this.route.paramMap.subscribe(params => {
          this.employeeManagementService.getEmployeeByUserId(userId, false).subscribe(employeeAssociatedToUserLogged => {
            this.employeeAssociatedToUserLoggedId = employeeAssociatedToUserLogged.employeeId;

            this.currentEmployee = employeeAssociatedToUserLogged;
            this.employees = [ this.currentEmployee ];

            if (!this.loadDataAlreadyLaunched) {
              // Se añade el validatorId a los filtros
              // Filtros para búsqueda de solicitudes
              // this.filtersApiAbsences.add(
              //   {
              //     field: "employeeId",
              //     value: this.employeeAssociatedToUserLoggedId
              //   },
              // );

              this.loadDataAlreadyLaunched = true;
              this.absenceService.getAllAbsenceGroupBy(this.filtersApiAbsences.getStrinFilterApi()).subscribe(absencesGroups => {
                const filteredItems = this.assignAbsencesStates(absencesGroups.items);

                this.absenceService.updateAbsencesGroup(filteredItems);
                this.loadAbsences(filteredItems);

              });
            }

            this.spinner.hide();
          })
        })
      );
    };
  }

  /**
   * Selecciona los estados que se deben manejar y los que se deben mostrar.
   */
  setRequestStates() {
    this.tabStates = [
      "REGISTERED",
      "PENDING",
      "APPROVED",
      "REJECTED",
      "ANNULLED",
      // "CANCELED",
      "ALL",
    ];

    this.excludeStates = [
      AbsenceStateCode.CANCELED,
    ];

    this.visibleStatesCalendar = [
      AbsenceStateCode.REGISTERED,
      AbsenceStateCode.APPROVED,
      AbsenceStateCode.PENDING_APROVAL,
      AbsenceStateCode.PENDING_ANNULLED,
    ];

    this.setFilterAbsencesStates();
  }
}
