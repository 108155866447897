import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, Hotkeys, SecurityStateManagementService } from 'inzo-portalempleado';
import { DcrListComponent } from '../dcr-list/dcr-list.component';

@Component({
  selector: 'app-dcr-list-employee',
  templateUrl: './dcr-list-employee.component.html',
  styleUrls: [
    './dcr-list-employee.component.css',
    '../dcr-list/dcr-list.component.css',
    '../../action.components.css',
    '../../action-list.component.css'
  ],
})
export class DcrListEmployeeComponent extends DcrListComponent {

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public SSMService: SecurityStateManagementService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
  ) {
    super(router, route, SSMService, accountService, hotkeys);
  }
}
