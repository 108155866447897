import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { FilterApi } from '../interfaces/filter-api.interface';
import { Employee } from '../models/employee.model';
import { FiltersApi } from '../models/filters-api.model';
import { FiltersApiIds } from '../models/filters-api-ids.model';
import { Absence } from '../models/absence.model';
import { AbsenceStateLabel } from '../interfaces/absence.interface';

@Injectable({
  providedIn: 'root'
})
export class AbsenceChangesService {
  private refreshEvent = new BehaviorSubject<boolean>(false);
  public refresh = this.refreshEvent.asObservable();

  private checkEvent = new BehaviorSubject<boolean>(false);
  public check = this.checkEvent.asObservable();

  private canDirectAssignmentEvent = new BehaviorSubject<boolean>(false);
  public canDirectAssignment = this.canDirectAssignmentEvent.asObservable();

  private absencesEvent = new ReplaySubject<Absence[]>(null);
  public absences: Observable<Absence[]> = this.absencesEvent.asObservable();

  private currentEmployeeEvent = new BehaviorSubject<Employee>(null);
  public currentEmployee = this.currentEmployeeEvent.asObservable();

  private absenceValidatorFilterEvent = new BehaviorSubject<FilterApi>(null);
  public absenceValidatorFilter = this.absenceValidatorFilterEvent.asObservable();

  private filtersAbsencesEmployeeIdsEvent = new BehaviorSubject<FiltersApiIds>(null);
  public filtersAbsencesEmployeeIds = this.filtersAbsencesEmployeeIdsEvent.asObservable();

  private selectedStateRequestEvent = new BehaviorSubject<AbsenceStateLabel>(null);
  public selectedStateRequest = this.selectedStateRequestEvent.asObservable();

  constructor() { }

  refreshData(data: boolean) {
    this.refreshEvent.next(data);
  }

  onChangeCheck(data: boolean) {
    this.checkEvent.next(data);
  }

  onChangecanDirectAssignment(data: boolean) {
    this.canDirectAssignmentEvent.next(data);
  }

  onChangeAbsences(data: Absence[]) {
    this.absencesEvent.next(data);
  }

  onChangeCurrentEmployee(data: Employee) {
    this.currentEmployeeEvent.next(data);
  }

  onChangeAbsenceValidatorFilter(data: FilterApi) {
    this.absenceValidatorFilterEvent.next(data);
  }

  onChangeFiltersAbsencesEmployeeIds(data: FiltersApiIds) {
    this.filtersAbsencesEmployeeIdsEvent.next(data);
  }

  onChangeSelectedStateRequest(data: AbsenceStateLabel) {
    this.selectedStateRequestEvent.next(data);
  }

  // Reset Observables
  resetObservables() {
    this.refreshData(false);
    this.onChangeCheck(false);
    this.onChangecanDirectAssignment(null);
    this.onChangeAbsences(null);
    this.onChangeCurrentEmployee(null);
    this.onChangeAbsenceValidatorFilter(null);
    this.onChangeFiltersAbsencesEmployeeIds(null);
    this.onChangeSelectedStateRequest(null);
  }
}
