import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { CourseType } from '../models/course-type.model';


@Injectable({ providedIn: 'root' })
export class CourseTypeService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private courseTypeSubject: ReplaySubject<CourseType[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public courseTypeObservable: Observable<CourseType[]> = this.courseTypeSubject.asObservable();

    private courseTypeRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public courseTypeRouteObservable: Observable<string> = this.courseTypeRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/CourseType';
    }

    constructor(private http: HttpClient) {
    }

    getAllCoursesType(filter? : string) {
      if (filter == undefined || filter  == null) {
        filter = "";
      }
      let url = `${this.serviceUrl}${filter}`;

      return this.http.get<any>(url);
    }

    getCourseType(courseTypeId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<CourseType>(`${this.serviceUrl}/${courseTypeId}${relatedFilter}`);
    }

    createCourseType(courseType: CourseType) {
        const endpointUrl = `${this.serviceUrl}`;
        return this.http.post(endpointUrl, courseType);
    }
    modifyCourseType(courseTypeId: string, newCourseType: any) {
        const endpointUrl = `${this.serviceUrl}/${courseTypeId}`;
        return this.http.patch(endpointUrl, newCourseType);
    }
    deleteCourseType(courseTypeId: string) {
        const endpointUrl = `${this.serviceUrl}/${courseTypeId}`;
        return this.http.delete(endpointUrl);
    }


    updateCoursesType(coursesType: CourseType[]) {
        this.courseTypeSubject.next(coursesType);
    }

    updateCourseTypeRoutes(id: string) {
        this.courseTypeRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}
