import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Payroll } from 'src/app/models/payroll.model';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { PayrollService } from 'src/app/services/payroll.service';
import { BaseFormComponent, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import * as jsonpatch from 'fast-json-patch';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { map, startWith } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileService } from 'src/app/services/file.service';
import { StorageFile } from 'src/app/models/storage-file.model';

@Component({
  selector: 'app-payroll-form',
  templateUrl: 'payroll-form.component.html',
  styleUrls: ['./payroll-form.component.css','./../../maintenance.components.css'],
})
export class PayrollFormComponent extends BaseFormComponent<Payroll> implements OnInit, OnDestroy {
  selectedDesc: string = undefined;
  payroll: Payroll;
  allEmployees: Employee[];

  roles: Role[];

  enabled = true;
  detail = false;
  permissionsLoaded = false;
  filePayroll = false;
  fileName = '';

  formGroupControl = new FormGroup({
    payrollDate: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    employee: new FormControl('', Validators.required),
    document: new FormControl(''),
  });

  canAdd = false;
  canUpdate = false;
  canDelete = false;

  filtersApi: FiltersApi = new FiltersApi();

  componentTypeTranslated: string;
  subscription: Subscription;

  fileToUpload: File = null;

  filteredEmployees: Observable<Employee[]>;
  loadEmployeeData;

  constructor(
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    private toastrService: ToastrService,
    private payrollService: PayrollService,
    public accountService: AccountService,
    private employeeManagementService: EmployeeManagementService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    super(accountService, router, hotkeys);

    this.loadEmployeeData = forkJoin({
      employeesVal: employeeManagementService.getAllEmployees()
    });
    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ) {
      this.canAdd = true;
      this.canDelete = true;
    }

    this.PK = 'employeeDocumentId';

    this.baseRoute = '/managements/payroll';

    this.filtersApi.add(
      {
        field: "related",
        value: "true"
      }
    );

    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('Payroll_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  private init() {
    this.formGroupControl.get('payrollDate').disable();
    this.formGroupControl.get('description').disable();
    this.formGroupControl.get('employee').disable();
    this.formGroupControl.get('document').disable();
    this.payroll = new Payroll();
    this.subscription = this.route.paramMap.subscribe(params => {
      this.employeeManagementService.getAllEmployees().subscribe(employeeManagementResponse => {
        this.allEmployees = employeeManagementResponse.items;
        const transId = params.get('id');
        this.payrollService.updatePayrollRoutes(transId);
        if (transId) {
          if (transId === 'new') {
            this.detail = false;
            this.enabled = true;
            this.payroll = new Payroll();
            this.selectedDesc = undefined;
            this.formGroupControl.setValue({ payrollDate: '', description: '', employee: '', document: '' });
            this.formGroupControl.get('payrollDate').enable();
            this.formGroupControl.get('description').enable();
            this.formGroupControl.get('employee').enable();
            this.formGroupControl.get('document').enable();
            this.filePayroll=false;
            this.fileName='';
            this.loadEmployeeData.subscribe(data => {
              this.allEmployees = data.employeesVal.items;
              this.addFilters();
            });

          } else {
            this.payrollService.getPayroll(transId).subscribe(response => {
              this.detail = true;
              this.enabled = false;
              this.payroll = response;
              const employeeSelected = this.allEmployees.find(employee => employee.employeeId === this.payroll.employeeId);
              //response
              this.fileService.getFile(this.payroll.fileId).subscribe(file => {
                if(file){
                  this.filePayroll=true;
                  this.fileName=file.name;
                }else{
                  this.filePayroll=false;
                  this.fileName='';
                }
                  this.formGroupControl.setValue({ payrollDate: this.payroll.payrollDate, description: this.payroll.description, employee: employeeSelected, document: file.name });
                  this.formGroupControl.get('payrollDate').disable();
                  this.formGroupControl.get('description').disable();
                  this.formGroupControl.get('employee').disable();
                  this.formGroupControl.get('document').disable();
                  this.selectedDesc = response.description;

                  this.loadEmployeeData.subscribe(data => {
                    this.allEmployees = data.employeesVal.items;
                    this.addFilters();
                  });
                }, error => {
                  this.toastrService.error('No se ha podido añadir el fichero.', error);
                  this.spinner.hide();
                });


            });
          }
        } else {
          this.selectedDesc = undefined;
          this.detail = false;
          this.enabled = false;
          this.payroll = new Payroll();
        }
      });
    });
  }

  edit() {
    this.enabled = true;
    this.formGroupControl.get('payrollDate').enable();
    this.formGroupControl.get('description').enable();
    this.formGroupControl.get('employee').enable();
    this.formGroupControl.get('document').enable();
    this.filePayroll=false;
  }

  cleanForm() {
    this.formGroupControl.setValue({ payrollDate: '', description: '' , employee: '', document: ''});
  }

  assingEmployee(e) {
    this.formGroupControl.get('employee').setValue(e.option.value);
  }

  displayEmployee(employee: Employee): string | undefined {
    return (employee) ? employee.name : undefined;
  }

  // ======================== CRUD ========================

  create() {
    const fileInput = this.fileToUpload;
    this.payroll = new Payroll({
      file: this.fileToUpload,
      payrollDate: this.formGroupControl.get('payrollDate').value,
      employeeId : this.formGroupControl.get('employee').value.employeeId
    });
    this.payroll.description = this.formGroupControl.get('description').value;
    this.fileService.getPayrolls(this.formGroupControl.get('employee').value.login,false).subscribe(degrees => {
      const formData = new FormData();
      formData.append('file', fileInput, fileInput.name);
      formData.append('directoryId', degrees.items[0].directoryId);
      formData.append('name', fileInput.name);
      formData.append('path', this.formGroupControl.get('employee').value.login+'/Certifications/Payrolls');
      this.fileService.addFile(formData).subscribe(response => {
        if(response){
          this.toastrService.success('Documento ' + fileInput.name + ' subido con éxito');
          this.payroll.fileId =  response.fileId;
          this.payrollService.createPayroll(this.payroll.employeeId,this.payroll).subscribe(
            response => {
              this.payrollService.GetAllPayrollsByEmployee(this.filtersApi.getStrinFilterApi()).subscribe(payrolls => {
                this.payrollService.updatepayrolls(payrolls.items);
              });
              let toastTitle = '';
              let toastBody = '';
              this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {
                toastTitle = a;
              });
              this.translate
                .get('MESSAGES.SUCCESS.CREATE_LONG', {
                  type: this.componentTypeTranslated,
                  code: this.payroll.description,
                })
                .subscribe((a: string) => {
                  toastBody = a;
                });
              this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
              this.cancel();
            },
            error => {
              if (error.status === 500 || error.status === 0) {
              } else if (error.status === 409) {
                let title: string;
                this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
                  title = a;
                  this.toastrService.error(error.error, title, { timeOut: 3000 });
                });
              } else {
              }
            },
          );
        }
      }, error => {
        this.toastrService.error('No se ha podido añadir el documento.', error);
        this.spinner.hide();
      });
    }, error => {
      this.toastrService.error('No se ha podido recuperar datos del directorio en el servidor de documentos.', error);
      this.spinner.hide();
    });

  }

  update() {
    const fileInput = this.fileToUpload;
    const observer = jsonpatch.observe(this.payroll);
    this.payroll.payrollDate = this.formGroupControl.get('payrollDate').value;
    this.payroll.description = this.formGroupControl.get('description').value;
    // TODO: Queda comentado la asignación del fichero ya que da un error en el back
    // this.cv.file = this.fileToUpload;
    const patch = jsonpatch.generate(observer);
    this.payrollService.modifyPayroll(this.payroll.employeeDocumentId, patch).subscribe(
      response => {
        this.payrollService.reassociatePayroll(this.payroll.employeeDocumentId,this.payroll.employeeId,this.formGroupControl.get('employee').value.employeeId).subscribe(
          responseReasociate => {
            this.fileService.updateFileContent(this.payroll.fileId,fileInput).subscribe(file => {
              if(file){
                this.toastrService.success('Documento actualizado con éxito');
                this.fileName=file.name;
              }else{
                this.toastrService.success('No se ha podido actualizar el documento');
              }
            });
            this.payrollService.GetAllPayrollsByEmployee(this.filtersApi.getStrinFilterApi()).subscribe(payrolls => {
              this.payrollService.updatepayrolls(payrolls.items);
            });
            this.cancel();
          },
          error => {
            let title: string;
            this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
              title = a;
              this.toastrService.error(error.error, title, { timeOut: 3000 });
            });
          },
        );
        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );

  }

  delete() {
    this.payrollService.deletePayroll(this.payroll.employeeDocumentId).subscribe(response => {
      this.payrollService.GetAllPayrollsByEmployee(this.filtersApi.getStrinFilterApi()).subscribe(payrolls => {
        this.payrollService.updatepayrolls(payrolls.items);
          this.fileService.deleteFileFromStorage(this.payroll.fileId).subscribe(response => {
            this.spinner.hide();
            if(response){
              this.toastrService.success('Documento eliminado con éxito');
            }
        }, error => {
          this.toastrService.error('No se ha podido eliminar el documento.', error);
          this.spinner.hide();
        });
      });
      let toastTitle = '';
      let toastBody = '';
      this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {
        toastTitle = a;
      });
      this.translate
        .get('MESSAGES.SUCCESS.DELETE_LONG', {
          type: this.componentTypeTranslated,
          code: this.payroll.description,
        })
        .subscribe((a: string) => {
          toastBody = a;
        });
      this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
      this.cancel();
    });
  }

  cancel() {
    this.cleanForm();
    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }

  back() {
    this.cancel();
  }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  addFilters() {
    //TODO cuando se limpia del todo el area, que aparezcan de nuevo todos los depts.
    this.filteredEmployees = this.formGroupControl.controls['employee'].valueChanges.pipe(startWith(""), map(val => this.allEmployees.filter(employees => this.customContains(employees.name + " " + employees.firstSurname, val))));

  }

  customStartsWith(attribute: String, value: String): boolean {
    if ((typeof value === "string" || value instanceof String)) {
      if (value == " ") {
        return true;
      }
      let normalizedAttribute = attribute.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      let normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return normalizedAttribute.toLowerCase().indexOf(normalizedValue.toLowerCase()) == 0;
    } else {
      return true;
    }
  }

  customContains(attribute: String, value: any): boolean {
    if ((typeof value === "string" || value instanceof String)) {
      if (value == " ") {
        return true;
      }
      let normalizedAttribute = attribute.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      let normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return normalizedAttribute.toLowerCase().indexOf(normalizedValue.toLowerCase()) != -1;
    } else {
      return true;
    }
  }

  downloadFile(payroll: Payroll){
    this.fileService.getFile(payroll.fileId).subscribe(fichero => {
      this.fileService.getFileBlob(fichero.fileId).subscribe(file => {
          this.fileService.downloadFile(file, fichero.name);
      });
    });
  }

  viewFile(payroll: Payroll){
      this.fileService.getFile(payroll.fileId).subscribe(fichero => {
        this.fileService.getFileBlob(fichero.fileId).subscribe(file => {
          this.fileService.openPDF(file);
        });
      });
  }
}
