import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WCEvent } from 'src/app/models/wc-event.model';
import { WCEventTypeService } from 'src/app/services/wc-event-type.service';
import { WCEventService } from 'src/app/services/wc-event.service';
import { WorkCalendarComponent } from '../work-calendar.component';
import * as jsonpatch from 'fast-json-patch';
import { WCEventType } from 'src/app/models/wc-event-type.model';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { WorkCalendarImportEventsConfirmDialogComponent } from '../work-calendar-import-events-confirm-dialog/work-calendar-import-events-confirm-dialog.component';
import { element } from 'protractor';

@Component({
  selector: 'app-work-calendar-import-events-dialog',
  templateUrl: './work-calendar-import-events-dialog.component.html',
  styleUrls: ['./work-calendar-import-events-dialog.component.css']
})
export class WorkCalendarImportEventsDialogComponent implements OnInit {
  workCalendars: WorkCalendar[] = [];
  workCalendarSelected: WorkCalendar = null;

  formGroupControl: FormGroup;

  sourceWorkCalendarIds = [];

  constructor(
    public dialogRef: MatDialogRef<WorkCalendarComponent>,
    public eventTypeService: WCEventTypeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private wcEventService: WCEventService,
    private dialog: MatDialog,

  ) { }

  ngOnInit() {
    this.workCalendarSelected = this.data.workCalendarSelected;
    this.workCalendars = this.data.workCalendars.filter((item: WorkCalendar) => item.workCalendarId != this.workCalendarSelected.workCalendarId );

    this.setFormValues();
  }

  setFormValues() {
    if (this.workCalendars) {
      let groupFrom = {};

      for (let workCalendar of this.workCalendars) {
        groupFrom[workCalendar.name] = new FormControl(false);
      }

      this.formGroupControl = new FormGroup(groupFrom);
    }
  }

  cleanForm() {
    // Quita las marcas "dirty" de los inputs
    this.formGroupControl.markAsPristine();

    this.setFormValues();
  }

  select() {
    this.sourceWorkCalendarIds = [];

    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      if (currentControl.dirty && currentControl.value) {
        let wcTemp = this.workCalendars.find((i) => i.name == name);

        this.sourceWorkCalendarIds.push(
          {
            name: wcTemp.name,
            wcId: wcTemp.workCalendarId,
          }
        );
      }
    });

    if (this.sourceWorkCalendarIds.length > 0) {
      let text: string[] = [];
      this.sourceWorkCalendarIds.forEach((element) => {
        text.push(element.name);
      })

      const confirmationDialog = this.dialog.open(WorkCalendarImportEventsConfirmDialogComponent, {
        width: '550px',
        data: { text }
      });

      confirmationDialog.afterClosed().subscribe(async (confirm) => {
        if (confirm) {
          this.dialogRef.close(
            {
              sourceWorkCalendarIds: this.sourceWorkCalendarIds.map((element) => element.wcId)
            }
          );
        }
      });
    }

  }
}
