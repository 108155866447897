import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { CloseYearHistory } from 'src/app/models/close-year-history.model';

@Injectable({
  providedIn: 'root'
})
export class CloseYearHistoryService {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<CloseYearHistory> = this.changeSubject.asObservable();
  private closeYearHistorySubject: ReplaySubject<CloseYearHistory[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public closeYearHistoryObservable: Observable<CloseYearHistory[]> = this.closeYearHistorySubject.asObservable();

  private closeYearHistoryRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public closeYearHistoryRouteObservable: Observable<string> = this.closeYearHistoryRouteSubject.asObservable();

  /* ################################################################################################################## */
  get serviceUrl() {
      return Config.apiBaseUrl + '/CloseYearHistory';
  }

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(private http: HttpClient) {
  }

  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  /* ------------------------------------------------------------------------------------------------------------------ */
  /* -- READ
  /* ------------------------------------------------------------------------------------------------------------------ */
  getAllCloseYearHistory(filter? : string) {
    if (filter == undefined || filter  == null) {
      filter = "";
    }
    let url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getCloseYearHistory(closeYearId: string, filter? : string) {
    if (filter == undefined || filter  == null) {
      filter = "";
    }
    let url = `${this.serviceUrl}/${closeYearId}${filter}`;

    return this.http.get<CloseYearHistory>(url);
  }

  /* -- WORKCALENDARS -- */
  getWorkCalendarsToReplace(closeYearHistoryId: string, filter? : string) {
    if (filter == undefined || filter  == null) {
      filter = "";
    }
    let url = `${this.serviceUrl}/WorkCalendar/${closeYearHistoryId}${filter}`;

    return this.http.get<any>(url);
  }

  /* ------------------------------------------------------------------------------------------------------------------ */
  /* -- CREATE
  /* ------------------------------------------------------------------------------------------------------------------ */
  createCloseYearHistory(closeYearHistory: CloseYearHistory, newYear: string = "") {
    const endpointUrl = `${this.serviceUrl}/NewYear/${newYear}`;

    return this.http.post<CloseYearHistory>(endpointUrl, closeYearHistory);
  }

  /* -- WORKCALENDARS -- */
  createNewWorkCalendars(newYear: string = "", workCalendarId: string[] = [], closeYearHistory: CloseYearHistory = new CloseYearHistory(), disableOld: boolean = false) {
    let sourceWorkCalendarIds = workCalendarId.length > 0 ? "&workCalendarId=" + JSON.stringify(workCalendarId) : "";

    const endpointUrl = `${this.serviceUrl}/WorkCalendar/NewYear/${newYear}?disableOld=${disableOld}${sourceWorkCalendarIds}`;

    return this.http.post<CloseYearHistory>(endpointUrl, closeYearHistory);
  }

  /* ------------------------------------------------------------------------------------------------------------------ */
  /* -- DELETE
  /* ------------------------------------------------------------------------------------------------------------------ */
  revertCloseYearHistory(closeYearHistoryId:string,) {
    const endpointUrl = `${this.serviceUrl}/${closeYearHistoryId}`;
    return this.http.delete(endpointUrl);
  }

  /* -- WORKCALENDARS -- */
  revertWorkCalendars(closeYearHistoryId:string,) {
    const endpointUrl = `${this.serviceUrl}/WorkCalendar/${closeYearHistoryId}`;
    return this.http.delete(endpointUrl);
  }

  /* ################################################################################################################## */
  /* ## OBSERVERS
  /* ################################################################################################################## */

  updateCloseYearHistory(closeYearHistory: CloseYearHistory[]) {
    this.closeYearHistorySubject.next(closeYearHistory);
  }

  updateCloseYearHistoryRoutes(id: string) {
    this.closeYearHistoryRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
