import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AbsenceTypeMaintenanceComponent } from '../absence-type-maintenance.component';

@Component({
  selector: 'app-absence-type-confirmation-delete-dialog',
  templateUrl: './absence-type-confirmation-delete-dialog.component.html',
  styleUrls: ['./absence-type-confirmation-delete-dialog.component.css']
})
export class AbsenceTypeConfirmationDeleteDialogComponent implements OnInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  absenceTypeDescription : string = "";

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    public dialogRef: MatDialogRef<AbsenceTypeMaintenanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
  ) { }

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    if (this.data.description) {
      this.absenceTypeDescription = this.data.description;
    }
  }

  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  getBodyText() {
    let result = "";

    this.translate.get('ABSENCE_TYPE_MAINTENANCE.DIALOG.DELETE.BODY', { value: this.absenceTypeDescription }).subscribe((a: string) => {
      result = a;
    });

    return result;
  }
}
