import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, BaseListComponent, Hotkeys, ResponseNotificationService, SecurityStateManagementService } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CloseYearHistoryEvent } from 'src/app/interfaces/events.interface';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { CloseYearHistory } from 'src/app/models/close-year-history.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { WorkCalendarHistory } from '../../../../models/work-calendar-history.model';
import { CloseYearHistoryService } from '../../services/close-year-history.service';
import { WorkCalendarHistoryService } from '../../services/work-calendar-history.service';
import { WorkCalendarHistoryEvent } from '../../../../interfaces/events.interface';
import { WorkCalendar } from 'src/app/models/work-calendar.model';

@Component({
  selector: 'app-close-year-layout',
  templateUrl: './close-year-layout.component.html',
  styleUrls: ['./close-year-layout.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CloseYearLayoutComponent extends BaseListComponent<Object> implements OnInit, OnDestroy {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  workCalendarHistoryArray: WorkCalendarHistory[] = [];
  closeYearHistoryArray: CloseYearHistory[] = [];

  subscriptions: Subscription[] = [];
  filterApiWorkCalendar: FiltersApi = new FiltersApi();
  filterApiCloseYear: FiltersApi = new FiltersApi();
  filterApiWorkCalendarHistory: FiltersApi = new FiltersApi();

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    protected closeYearHistoryService: CloseYearHistoryService,
    protected workCalendarHistoryService: WorkCalendarHistoryService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    protected RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    protected spinner: NgxSpinnerService,
  ) {
    super(accountService, SSMService, router, hotkeys);

    this.baseRoute = '/maintenances/close_year';

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
    ];

    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ) {
    }

    this.onBuild = () => {
    };

    // DATA MANIPULATION
    this.loadData = () => {
      this.spinner.show();
      this.filterApiWorkCalendar.add(
        {
          field: "related",
          value: "true"
        }
      );
      this.filterApiWorkCalendar.add(
        {
          field: "CloseYearHistoryNull",
          value: "false"
        }
      );
      this.filterApiCloseYear.add(
        {
          field: "related",
          value: "true"
        }
      );
      this.filterApiCloseYear.add(
        {
          field: "CreatedByUser",
          value: "include"
        }
      );

      this.subscriptions.push(
        this.route.paramMap.subscribe(params => {
          this.subscriptions.push(
            this.workCalendarHistoryService.getAllWorkCalendarHistory(this.filterApiWorkCalendar.getStrinFilterApi()).subscribe(response => {
              this.workCalendarHistoryArray = response.items;
              this.workCalendarHistoryService.updateWorkCalendarHistory(this.workCalendarHistoryArray);

              this.spinner.hide();
            }, error => {
              this.spinner.hide();
              this.RNService.showError(error);
            })
          ),

          this.subscriptions.push(
            this.closeYearHistoryService.getAllCloseYearHistory(this.filterApiCloseYear.getStrinFilterApi()).subscribe(response => {
              this.closeYearHistoryArray = response.items;
              this.closeYearHistoryService.updateCloseYearHistory(this.closeYearHistoryArray);

              this.spinner.hide();
            }, error => {
              this.spinner.hide();
              this.RNService.showError(error);
            })
          )
        })
      );
    };
  }

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /* ################################################################################################################## */
  /* ## CUSTOM EVENT HANDLERS
  /* ################################################################################################################## */
  selectWorkCalendarHistory(event: WorkCalendarHistoryEvent) {
    switch(event.eventType) {
      case "CREATE":
        this.createWorkCalendarHistory(event.workCalendarHistory);
        break;
      case "REVERSION":
        this.reversionWorkCalendarHistory(event.workCalendarHistory);
        break;
    }
  }

  selectCloseYearHistory(event: CloseYearHistoryEvent) {
    switch(event.eventType) {
      case "CREATE":
        this.createCloseYear(event.closeYearHistory);
        break;
      case "REVERSION":
        this.reversionCloseYear(event.closeYearHistory);
        break;
    }
  }

  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  createWorkCalendarHistory(workCalendarHistory : WorkCalendarHistory) {
    this.spinner.show();

    this.filterApiWorkCalendarHistory.add(
      {
        field: "related",
        value: "true"
      }
    );
    this.filterApiWorkCalendarHistory.addInclude("OldWorkCalendar");
    this.filterApiWorkCalendarHistory.addInclude("NewWorkCalendar");
    this.filterApiWorkCalendarHistory.add(
      {
        field: "CloseYearHistoryId",
        value: workCalendarHistory.closeYearHistory.closeYearHistoryId
      },
      true
    );

    this.subscriptions.push(
      this.workCalendarHistoryService.getAllWorkCalendarHistory(this.filterApiWorkCalendarHistory.getStrinFilterApi()).subscribe(response => {
        let newWorkCalendars: WorkCalendar[] = [];
        response.items.forEach((item) => {
          newWorkCalendars.push(item.newWorkCalendar);
        });

        workCalendarHistory.closeYearHistory.newWorkCalendars = newWorkCalendars;

        this.subscriptions.push(
          this.closeYearHistoryService.createCloseYearHistory(workCalendarHistory.closeYearHistory, workCalendarHistory.closeYearHistory.year).subscribe(response => {
            this.loadData();

            this.spinner.hide();
          }, error => {
            this.spinner.hide();
            this.RNService.showError(error);
          })
          )

          this.loadData();
      }, error => {
        this.spinner.hide();
        this.RNService.showError(error);
      })
    );
  }

  reversionWorkCalendarHistory(workCalendarHistory : WorkCalendarHistory) {
    this.spinner.show();

    this.subscriptions.push(
      this.closeYearHistoryService.revertWorkCalendars(workCalendarHistory.closeYearHistory.closeYearHistoryId).subscribe(response => {
        this.loadData();

        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.RNService.showError(error);
      })
    );
  }

  createCloseYear(closeYearHistory : CloseYearHistory) {
    this.spinner.show();

    this.subscriptions.push(
      this.closeYearHistoryService.createCloseYearHistory(closeYearHistory, closeYearHistory.year).subscribe(response => {
        this.loadData();

        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.RNService.showError(error);
      })
    );
  }

  reversionCloseYear(closeYearHistory : CloseYearHistory) {
    this.spinner.show();

    this.subscriptions.push(
      this.closeYearHistoryService.revertCloseYearHistory(closeYearHistory.closeYearHistoryId).subscribe(response => {
        this.loadData();

        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.RNService.showError(error);
      })
    );
  }
}
