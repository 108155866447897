// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';

import { User } from 'inzo-portalempleado';
import { EmployeeManagementInfo } from 'src/app/models/employee-management-info.model';
import { Employee } from 'src/app/models/employee.model';
import { Address } from '../../../models/address.model';
import { SBA } from '../../../models/sba.model';
import { EmployeeContract } from 'src/app/models/employee-contract.model';
import { ClonerService } from 'src/app/helpers/clone-object.helper';

// ################################################################################################################
// ## CLASS DataChangesService
// ################################################################################################################
@Injectable({
  providedIn: 'root'
})
export class DataChangesService {
  // Employee Management Info
  private employeeManagementInfoEvent: ReplaySubject<EmployeeManagementInfo> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public employeeManagementInfo: Observable<EmployeeManagementInfo> = this.employeeManagementInfoEvent.asObservable();

  // Identity users
  private allUsersEvent: ReplaySubject<User[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public allUsers: Observable<User[]> = this.allUsersEvent.asObservable();

  // Create Mode
  private createModeEvent: ReplaySubject<boolean> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public createMode: Observable<boolean> = this.createModeEvent.asObservable();

  // Edit Mode
  private editModeEvent: ReplaySubject<boolean> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public editMode: Observable<boolean> = this.editModeEvent.asObservable();

  // Dirty Form Marks
  private resetFormDirtyEvent: ReplaySubject<boolean> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public resetFormDirty: Observable<boolean> = this.resetFormDirtyEvent.asObservable();

  // Get Form Data flag
  private getFormDataEvent: ReplaySubject<boolean> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public formData: Observable<boolean> = this.getFormDataEvent.asObservable();

  /* ****************************************************************************************************************** */
  /* ** Employee Data
  /* ****************************************************************************************************************** */
  private employeeEvent: ReplaySubject<Employee> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public employee: Observable<Employee> = this.employeeEvent.asObservable();

  private homeAddressEvent: ReplaySubject<Address> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public homeAddress: Observable<Address> = this.homeAddressEvent.asObservable();

  private employeeContractEvent: ReplaySubject<EmployeeContract> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public employeeContract: Observable<EmployeeContract> = this.employeeContractEvent.asObservable();

  private sbaEvent: ReplaySubject<SBA> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public sba: Observable<SBA> = this.sbaEvent.asObservable();

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor() { }

  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  // Employee Management Info
  setEmployeeManagementInfoChange(data: EmployeeManagementInfo) {
    this.employeeManagementInfoEvent.next(data);
  }

  // Identity users
  setAllUsersChange(data: User[]) {
    this.allUsersEvent.next(data);
  }

  // Create Mode
  setCreateModeChange(data: boolean) {
    this.createModeEvent.next(data);
  }

  // Edit Mode
  setEditModeChange(data: boolean) {
    this.editModeEvent.next(data);
  }

  // Dirty Form Marks
  setResetFormDirty(data: boolean) {
    this.resetFormDirtyEvent.next(data);
  }
  // Get Form Data flag
  getFormData(data: boolean) {
    this.getFormDataEvent.next(data);
  }

  /* ****************************************************************************************************************** */
  /* ** Employee Data
  /* ****************************************************************************************************************** */
  setEmployeeChange(data: Employee) {
    this.employeeEvent.next(data);
  }

  setHomeAddressChange(data: Address) {
    this.homeAddressEvent.next(data);
  }

  setEmployeeContractChange(data: EmployeeContract) {
    this.employeeContractEvent.next(data);
  }

  setSbaChange(data: SBA) {
    this.sbaEvent.next(data);
  }

  /* ****************************************************************************************************************** */
  /* ** Reset Observables
  /* ****************************************************************************************************************** */
  resetEmployeeData(employeeManagementInfo) {
    let employeeManagementInfoCopy: EmployeeManagementInfo = ClonerService.deepClone(employeeManagementInfo);

    if (employeeManagementInfoCopy) {
      employeeManagementInfoCopy.employee = null;
    }

    this.setEmployeeManagementInfoChange(null);
  }

  resetObservables() {
    this.setAllUsersChange(null);
    this.setResetFormDirty(true);
    this.setEmployeeChange(null);
    this.setHomeAddressChange(null);
    this.setEmployeeContractChange(null);
    this.setSbaChange(null);
  }

  resetAllObservables() {
    this.resetObservables();

    this.setEmployeeManagementInfoChange(null);
    this.setEditModeChange(false);
  }
}
