import { TrainingChangeRequest } from "./training-change-request.model";

export enum TCRStateCode {
  REGISTERED        = 0,
  PENDING_APROVAL   = 1,
  APPROVED          = 3,
  REJECTED          = 4,
  CANCELED          = 5,
  ANNULLED          = 6,
  PENDING_ANNULLED  = 7,
}

export class TCRState {
  tcrStateId  : string;
  stateCode   : TCRStateCode;
  description : string;

  //Relations
  TCRs        : TrainingChangeRequest[];

  constructor(obj?: Partial<TCRState>) {
    this.tcrStateId   = (obj && obj.tcrStateId) || undefined;
    this.stateCode    = (obj && obj.stateCode) || undefined;
    this.description  = (obj && obj.description) || undefined;

    //Relations
    this.TCRs         = (obj && obj.TCRs) || [];
  }
}
