import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Area } from 'src/app/models/area.model';
import { AreaService } from 'src/app/services/area.service';


@Component({
  selector: 'app-area-list',
  templateUrl: 'area-list.component.html',
  styleUrls: ['./../../maintenance.components.css','../../maintenance-list.component.css'],
})
export class AreaListComponent extends BaseListComponent<Area> implements OnInit, OnDestroy {
  areas: Area[] = [];
  subscription: Subscription;
  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;

  roles: Role[];

  canView = false;
  canAdd = false;
  canDelete = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  constructor(
    private areaService: AreaService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
  ) {
    super(accountService, SSMService, router, hotkeys);
    this.baseRoute = '/maintenances/area';

    this.onBuild = () => {
    };

    this.loadData = () => {
      this.spinner.show();
      this.areaService.getAllArea().subscribe(areas => {
        this.areaService.updateAreas(areas.items);
        this.areas = areas.items;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.RNService.showError(error);
      });
    };

    this.onInit = () => {
      if(this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH)) {
        this.canView = true;
        this.canAdd = true;
        this.canDelete = true;

        this.loadData();

        this.areaService.areaObservable.subscribe(areas => {
          this.areas = areas;
        });
      }
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;
          this.onInit();
        });
      });
    } else {
      this.roles = this.accountService.roles;
      this.onInit();
    }
  }
  //#endregion
}

