import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import moment = require('moment');
import { TCRState } from 'src/app/models/tcr-state.model';
import { TrainingChangeRequest } from 'src/app/models/training-change-request.model';

export interface IInfoTCRDialog {
  title           : string,
  description     : string,
  asassociate     : boolean,
  pendingAnnulled : boolean,
};

@Component({
  selector: 'app-tcr-dialog',
  templateUrl: './tcr-dialog.component.html',
  styleUrls: ['./tcr-dialog.component.css']
})
export class TCRDialogComponent implements OnInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  request: TrainingChangeRequest;
  maxDate = moment();

  infoTCRDialog : IInfoTCRDialog;

  formGroupControl = new FormGroup({
    finishDate: new FormControl('', Validators.required),
  });

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    public dialogRef: MatDialogRef<TCRState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.request = this.data.request;
    this.infoTCRDialog = this.data.infoTCRDialog;
  }

  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  returnDate(action: string) {
    if (this.infoTCRDialog.asassociate) {
      this.dialogRef.close(
        {
          action,
          finishDate: this.formGroupControl.get('finishDate').value
        }
      );
    } else {
      this.dialogRef.close(true);
    }
  }
}
