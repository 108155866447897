import { Permissions } from 'inzo-portalempleado';
export abstract class AppPermissions extends Permissions { //TODO: revisar si son necesarios todos los permisos

  public static readonly ROLE_DATA = {
    RA: 'RA',
    RP: 'RP',
    RRHH: 'RRHH',
    Empleado: 'Empleado'
  };

  public static readonly PERMISSIONS_DATA = {
    ...Permissions.PERMISSIONS_DATA,
    // AbsenceDocuments
    viewAbsenceDocuments: {
      name: 'Ver documentos de ausencias',
      value: 'absenceDocuments.view',
    },
    createAbsenceDocuments: {
      name: 'Crear documentos de ausencias',
      value: 'absenceDocuments.create',
    },
    updateAbsenceDocuments: {
      name: 'Modificar documentos de ausencias',
      value: 'absenceDocuments.update',
    },
    deleteAbsenceDocuments: {
      name: 'Eliminar documentos de ausencias',
      value: 'absenceDocuments.delete',
    },

    // AbsenceStates
    viewAbsenceStates: {
      name: 'Ver estados de ausencia',
      value: 'absenceStates.view',
    },
    createAbsenceStates: {
      name: 'Crear estados de ausencia',
      value: 'absenceStates.create',
    },
    updateAbsenceStates: {
      name: 'Modificar estados de ausencia',
      value: 'absenceStates.update',
    },
    deleteAbsenceStates: {
      name: 'Eliminar estados de ausencia',
      value: 'absenceStates.delete',
    },

    // AbsenceTypes
    viewAbsenceTypes: {
      name: 'Ver tipos de ausencia',
      value: 'absenceTypes.view',
    },
    createAbsenceTypes: {
      name: 'Crear tipos de ausencia',
      value: 'absenceTypes.create',
    },
    updateAbsenceTypes: {
      name: 'Modificar tipos de ausencia',
      value: 'absenceTypes.update',
    },
    deleteAbsenceTypes: {
      name: 'Eliminar tipos de ausencia',
      value: 'absenceTypes.delete',
    },

    // Absences
    viewAbsences: {
      name: 'Ver ausencias',
      value: 'absences.view',
    },
    createAbsences: {
      name: 'Crear ausencias',
      value: 'absences.create',
    },
    updateAbsences: {
      name: 'Modificar ausencias',
      value: 'absences.update',
    },
    deleteAbsences: {
      name: 'Eliminar ausencias',
      value: 'absences.delete',
    },
    validateAbsences: {
      name: 'Eliminar validation',
      value: 'absences.validate',
    },

    // Addresses
    viewAddresses: {
      name: 'Ver direcciones',
      value: 'addresses.view',
    },
    createAddresses: {
      name: 'Crear direcciones',
      value: 'addresses.create',
    },
    updateAddresses: {
      name: 'Modificar direcciones',
      value: 'addresses.update',
    },
    deleteAddresses: {
      name: 'Eliminar direcciones',
      value: 'addresses.delete',
    },

    // Areas
    viewAreas: {
      name: 'Ver areas',
      value: 'areas.view',
    },
    createAreas: {
      name: 'Crear areas',
      value: 'areas.create',
    },
    updateAreas: {
      name: 'Modificar areas',
      value: 'areas.update',
    },
    deleteAreas: {
      name: 'Eliminar areas',
      value: 'areas.delete',
    },

    // AttributeChanges
    viewAttributeChanges: {
      name: 'Ver cambios de atributos',
      value: 'attributeChanges.view',
    },
    createAttributeChanges: {
      name: 'Crear cambios de atributos',
      value: 'attributeChanges.create',
    },
    updateAttributeChanges: {
      name: 'Modificar cambios de atributos',
      value: 'attributeChanges.update',
    },
    deleteAttributeChanges: {
      name: 'Eliminar cambios de atributos',
      value: 'attributeChanges.delete',
    },

    // Centres
    viewCentres: {
      name: 'Ver Centros',
      value: 'centres.view',
    },
    createCentres: {
      name: 'Crear Centros',
      value: 'centres.create',
    },
    updateCentres: {
      name: 'Modificar Centros',
      value: 'centres.update',
    },
    deleteCentres: {
      name: 'Eliminar Centros',
      value: 'centres.delete',
    },

    // Certifications
    viewCertifications: {
      name: 'Ver certificaciones',
      value: 'certifications.view',
    },
    createCertifications: {
      name: 'Crear certificaciones',
      value: 'certifications.create',
    },
    updateCertifications: {
      name: 'Modificar certificaciones',
      value: 'certifications.update',
    },
    deleteCertifications: {
      name: 'Eliminar certificaciones',
      value: 'certifications.delete',
    },

    // Company
    viewCompany: {
      name: 'Ver empresa',
      value: 'company.view',
    },
    createCompany: {
      name: 'Crear empresa',
      value: 'company.create',
    },
    updateCompany: {
      name: 'Modificar empresa',
      value: 'company.update',
    },
    deleteCompany: {
      name: 'Eliminar empresa',
      value: 'company.delete',
    },

    // Courses
    viewCourses: {
      name: 'Ver cursos',
      value: 'courses.view',
    },
    createCourses: {
      name: 'Crear cursos',
      value: 'courses.create',
    },
    updateCourses: {
      name: 'Modificar cursos',
      value: 'courses.update',
    },
    deleteCourses: {
      name: 'Eliminar cursos',
      value: 'courses.delete',
    },

    // CVs
    viewCVs: {
      name: 'Ver CVs',
      value: 'CVs.view',
    },
    createCVs: {
      name: 'Crear CVs',
      value: 'CVs.create',
    },
    updateCVs: {
      name: 'Modificar CVs',
      value: 'CVs.update',
    },
    deleteCVs: {
      name: 'Eliminar CVs',
      value: 'CVs.delete',
    },

    // DataChangeRequests
    viewDataChangeRequests: {
      name: 'Ver datos de petición de cambios',
      value: 'dataChangeRequests.view',
    },
    createDataChangeRequests: {
      name: 'Crear datos de petición de cambios',
      value: 'dataChangeRequests.create',
    },
    updateDataChangeRequests: {
      name: 'Modificar datos de petición de cambios',
      value: 'dataChangeRequests.update',
    },
    deleteDataChangeRequests: {
      name: 'Eliminar datos de petición de cambios',
      value: 'dataChangeRequests.delete',
    },
    approveDataChangeRequests: {
      name: 'Aprobar o rechazar datos de petición de cambios',
      value: 'dataChangeRequests.update',
    },

    // DCRStates
    viewDCRStates: {
      name: 'Ver estados de datos de petición de cambios',
      value: 'DCRStates.view',
    },
    createDCRStates: {
      name: 'Crear estados de datos de petición de cambios',
      value: 'DCRStates.create',
    },
    updateDCRStates: {
      name: 'Modificar estados de datos de petición de cambios',
      value: 'DCRStates.update',
    },
    deleteDCRStates: {
      name: 'Eliminar estados de datos de petición de cambios',
      value: 'DCRStates.delete',
    },

    // Degrees
    viewDegrees: {
      name: 'Ver titulaciones',
      value: 'degrees.view',
    },
    createDegrees: {
      name: 'Crear titulaciones',
      value: 'degrees.create',
    },
    updateDegrees: {
      name: 'Modificar titulaciones',
      value: 'degrees.update',
    },
    deleteDegrees: {
      name: 'Eliminar titulaciones',
      value: 'degrees.delete',
    },

    // Departments
    viewDepartments: {
      name: 'Ver Departamentos',
      value: 'departments.view',
    },
    createDepartments: {
      name: 'Crear Departamentos',
      value: 'departments.create',
    },
    updateDepartments: {
      name: 'Modificar Departamentos',
      value: 'departments.update',
    },
    deleteDepartments: {
      name: 'Eliminar Departamentos',
      value: 'departments.delete',
    },

    // EmployeeCourses
    viewEmployeeCourses: {
      name: 'Ver cursos de empleados',
      value: 'employeeCourses.view',
    },
    createEmployeeCourses: {
      name: 'Crear cursos de empleados',
      value: 'employeeCourses.create',
    },
    updateEmployeeCourses: {
      name: 'Modificar cursos de empleados',
      value: 'employeeCourses.update',
    },
    deleteEmployeeCourses: {
      name: 'Eliminar cursos de empleados',
      value: 'employeeCourses.delete',
    },

    // EmployeeDegrees
    viewEmployeeDegrees: {
      name: 'Ver titulaciones de empleados',
      value: 'employeeDegrees.view',
    },
    createEmployeeDegrees: {
      name: 'Crear titulaciones de empleados',
      value: 'employeeDegrees.create',
    },
    updateEmployeeDegrees: {
      name: 'Modificar titulaciones de empleados',
      value: 'employeeDegrees.update',
    },
    deleteEmployeeDegrees: {
      name: 'Eliminar titulaciones de empleados',
      value: 'employeeDegrees.delete',
    },

    // EmployeeDocuments
    viewEmployeeDocuments: {
      name: 'Ver documentos de empleados',
      value: 'employeeDocuments.view',
    },
    createEmployeeDocuments: {
      name: 'Crear documentos de empleados',
      value: 'employeeDocuments.create',
    },
    updateEmployeeDocuments: {
      name: 'Modificar documentos de empleados',
      value: 'employeeDocuments.update',
    },
    deleteEmployeeDocuments: {
      name: 'Eliminar documentos de empleados',
      value: 'employeeDocuments.delete',
    },

    // EmployeeLanguages
    viewEmployeeLanguages: {
      name: 'Ver Idiomas de Empleados',
      value: 'employeeLanguages.view',
    },
    createEmployeeLanguages: {
      name: 'Crear Idiomas de Empleados',
      value: 'employeeLanguages.create',
    },
    updateEmployeeLanguages: {
      name: 'Modificar Idiomas de Empleados',
      value: 'employeeLanguages.update',
    },
    deleteEmployeeLanguages: {
      name: 'Eliminar Idiomas de Empleados',
      value: 'employeeLanguages.delete',
    },

    // EmployeeTypes
    viewEmployeeTypes: {
      name: 'Ver Tipos de Empleados',
      value: 'employeeTypes.view',
    },
    createEmployeeTypes: {
      name: 'Crear Tipos de Empleados',
      value: 'employeeTypes.create',
    },
    updateEmployeeTypes: {
      name: 'Modificar Tipos de Empleados',
      value: 'employeeTypes.update',
    },
    deleteEmployeeTypes: {
      name: 'Eliminar Tipos de Empleados',
      value: 'employeeTypes.delete',
    },

    // Employees
    viewEmployees: {
      name: 'Ver empleados',
      value: 'employees.view',
    },
    createEmployees: {
      name: 'Crear empleados',
      value: 'employees.create',
    },
    updateEmployees: {
      name: 'Modificar empleados',
      value: 'employees.update',
    },
    deleteEmployees: {
      name: 'Eliminar empleados',
      value: 'employees.delete',
    },

    // Headquarters
    viewHeadquarters: {
      name: 'Ver sedes',
      value: 'headquarters.view',
    },
    createHeadquarters: {
      name: 'Crear sedes',
      value: 'headquarters.create',
    },
    updateHeadquarters: {
      name: 'Modificar sedes',
      value: 'headquarters.update',
    },
    deleteHeadquarters: {
      name: 'Eliminar sedes',
      value: 'headquarters.delete',
    },

    // HolidaysRequestStates
    viewHolidaysRequestStates: {
      name: 'Ver estados de petición de vacaciones',
      value: 'holidaysRequestStates.view',
    },
    createHolidaysRequestStates: {
      name: 'Crear estados de petición de vacaciones',
      value: 'holidaysRequestStates.create',
    },
    updateHolidaysRequestStates: {
      name: 'Modificar estados de petición de vacaciones',
      value: 'holidaysRequestStates.update',
    },
    deleteHolidaysRequestStates: {
      name: 'Eliminar estados de petición de vacaciones',
      value: 'holidaysRequestStates.delete',
    },

    // HolidaysRequests
    viewHolidaysRequests: {
      name: 'Ver peticiones de vacaciones',
      value: 'holidaysRequests.view',
    },
    createHolidaysRequests: {
      name: 'Crear peticiones de vacaciones',
      value: 'holidaysRequests.create',
    },
    updateHolidaysRequests: {
      name: 'Modificar peticiones de vacaciones',
      value: 'holidaysRequests.update',
    },
    deleteHolidaysRequests: {
      name: 'Eliminar peticiones de vacaciones',
      value: 'holidaysRequests.delete',
    },
    validateHolidaysRequests: {
      name: 'Validar peticiones de vacaciones',
      value: 'holidaysRequests.validate',
    },

    // IRPFs
    viewIRPFs: {
      name: 'Ver IRPFs',
      value: 'IRPFs.view',
    },
    createIRPFs: {
      name: 'Crear IRPFs',
      value: 'IRPFs.create',
    },
    updateIRPFs: {
      name: 'Modificar IRPFs',
      value: 'IRPFs.update',
    },
    deleteIRPFs: {
      name: 'Eliminar IRPFs',
      value: 'IRPFs.delete',
    },

    // Languages
    viewLanguages: {
      name: 'Ver Idiomas',
      value: 'languages.view',
    },
    createLanguages: {
      name: 'Crear Idiomas',
      value: 'languages.create',
    },
    updateLanguages: {
      name: 'Modificar Idiomas',
      value: 'languages.update',
    },
    deleteLanguages: {
      name: 'Eliminar Idiomas',
      value: 'languages.delete',
    },

    // Payrolls
    viewPayrolls: {
      name: 'Ver nóminas',
      value: 'payrolls.view',
    },
    createPayrolls: {
      name: 'Crear nóminas',
      value: 'payrolls.create',
    },
    updatePayrolls: {
      name: 'Modificar nóminas',
      value: 'payrolls.update',
    },
    deletePayrolls: {
      name: 'Eliminar nóminas',
      value: 'payrolls.delete',
    },

    // WCEventTypes
    viewWCEventTypes: {
      name: 'Ver tipos de eventos de calendario laboral',
      value: 'WCEventTypes.view',
    },
    createWCEventTypes: {
      name: 'Crear tipos de eventos de calendario laboral',
      value: 'WCEventTypes.create',
    },
    updateWCEventTypes: {
      name: 'Modificar tipos de eventos de calendario laboral',
      value: 'WCEventTypes.update',
    },
    deleteWCEventTypes: {
      name: 'Eliminar tipos de eventos de calendario laboral',
      value: 'WCEventTypes.delete',
    },

    // WCEvents
    viewWCEvents: {
      name: 'Ver eventos de calendario laboral',
      value: 'WCEvents.view',
    },
    createWCEvents: {
      name: 'Crear eventos de calendario laboral',
      value: 'WCEvents.create',
    },
    updateWCEvents: {
      name: 'Modificar eventos de calendario laboral',
      value: 'WCEvents.update',
    },
    deleteWCEvents: {
      name: 'Eliminar eventos de calendario laboral',
      value: 'WCEvents.delete',
    },

    // WorkCalendars
    viewWorkCalendars: {
      name: 'Ver calendarios laborables',
      value: 'workCalendars.view',
    },
    createWorkCalendars: {
      name: 'Crear calendarios laborables',
      value: 'workCalendars.create',
    },
    updateWorkCalendars: {
      name: 'Modificar calendarios laborables',
      value: 'workCalendars.update',
    },
    deleteWorkCalendars: {
      name: 'Eliminar calendarios laborables',
      value: 'workCalendars.delete',
    },


    //Los siguientes permisos se deberían eliminar
    // Employee
    Employee: {
      name: 'Employee',
      value: 'Employee',
    },
    // Manager
    Manager: {
      name: 'Manager',
      value: 'Manager',
    },
    // RRHH
    RRHH: {
      name: 'RRHH',
      value: 'RRHH',
    },
  };

  // ------------------------------------------------------------------------------------------------------------
  public static readonly PERMISSIONS_GROUP_DATA = {
    ...Permissions.PERMISSIONS_GROUP_DATA,
    // AbsenceDocuments
    absenceDocuments: {
      name: 'Documentos de ausencias',
      value: 'absenceDocuments',
    },

    // AbsenceStates
    absenceStates: {
      name: 'Estados de ausencia',
      value: 'absenceStates',
    },

    // AbsenceTypes
    absenceTypes: {
      name: 'Tipos de ausencia',
      value: 'absenceTypes',
    },

    // Absences
    absences: {
      name: 'Ausencias',
      value: 'absences',
    },

    // Direcciones
    addresses: {
      name: 'Direcciones',
      value: 'addresses',
    },

    // Areas
    areas: {
      name: 'Areas',
      value: 'areas',
    },

    // AttributeChanges
    attributeChanges: {
      name: 'Cambios de atributos',
      value: 'attributeChanges',
    },

    // Centres
    centres:{
      name:'Centros',
      value:'centres'
    },

    // Certifications
    certifications: {
      name: 'Certificaciones',
      value: 'certifications',
    },

    // Company
    company: {
      name: 'Empresa',
      value: 'company',
    },

    // Courses
    courses: {
      name: 'Cursos',
      value: 'courses',
    },

    // CVs
    CVs: {
      name: 'CVs',
      value: 'CVs',
    },

    // DataChangeRequests
    dataChangeRequests: {
      name: 'Datos de petición de cambios',
      value: 'dataChangeRequests',
    },

    // DCRStates
    DCRStates: {
      name: 'Estados de datos de petición de cambios',
      value: 'DCRStates',
    },

    // Degrees
    degrees: {
      name: 'Titulaciones',
      value: 'degrees',
    },

    // Departments
    departments: {
      name: 'Departamentos',
      value: 'departments',
    },

    // EmployeeCourses
    employeeCourses: {
      name: 'Cursos de empleados',
      value: 'employeeCourses',
    },

    // EmployeeDegrees
    employeeDegrees: {
      name: 'Titulaciones de empleados',
      value: 'employeeDegrees',
    },

    // EmployeeDocuments
    employeeDocuments: {
      name: 'Documentos de empleados',
      value: 'employeeDocuments',
    },

    // EmployeeLanguages
    employeeLanguages:{
      name: 'Idiomas de los empleado',
      value: 'employeeLanguages'
    },

    // EmployeeTypes
    employeeTypes:{
      name: 'Tipos de empleado',
      value: 'employeeTypes'
    },

    // Employees
    employees: {
      name: 'Empleados',
      value: 'employees',
    },

    // Headquarters
    headquarters: {
      name: 'Sedes',
      value: 'headquarters',
    },

    // HolidaysRequestStates
    holidaysRequestStates: {
      name: 'Estados de petición de vacaciones',
      value: 'holidaysRequestStates',
    },

    // HolidaysRequests
    holidaysRequests: {
      name: 'Peticiones de vacaciones',
      value: 'holidaysRequests',
    },

    // IRPFs
    IRPFs: {
      name: 'IRPFs',
      value: 'IRPFs',
    },

    // Languages
    languages:{
      name: 'Idiomas',
      value: 'languages'
    },

    // Payrolls
    payrolls: {
      name: 'Nóminas',
      value: 'payrolls',
    },

    // WCEventTypes
    WCEventTypes: {
      name: 'Tipos de eventos de calendario laboral',
      value: 'WCEventTypes',
    },

    // WCEvents
    WCEvents: {
      name: 'Eventos de calendario laboral',
      value: 'WCEvents',
    },

    // WorkCalendars
    workCalendars: {
      name: 'Calendarios laborables',
      value: 'workCalendars',
    },
  };
}
