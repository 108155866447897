import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { Company } from '../models/company.model';


@Injectable({ providedIn: 'root' })
export class CompanyService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private companySubject: ReplaySubject<Company[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public companyObservable: Observable<Company[]> = this.companySubject.asObservable();

    private companyRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public companyRouteObservable: Observable<string> = this.companyRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/company';
    }

    constructor(private http: HttpClient) {
    }

    getAllCompany() {
        return this.http.get<any>(`${this.serviceUrl}`);
    }

    getCompany(companyId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<Company>(`${this.serviceUrl}/${companyId}${relatedFilter}`);
    }
}
