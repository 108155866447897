import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, Hotkeys, ResponseNotificationService, SecurityStateManagementService } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TCRStateCode } from 'src/app/models/tcr-state.model';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { TCRStateService } from 'src/app/services/tcr-state.service';
import { TrainingChangeRequestService } from 'src/app/services/training-change-request.service';
import { TCRGroupsComponent } from '../tcr-groups/tcr-groups.component';

@Component({
  selector: 'app-tcr-groups-employee',
  templateUrl: './tcr-groups-employee.component.html',
  styleUrls: ['./tcr-groups-employee.component.css']
})
export class TCRGroupsEmployeeComponent  extends TCRGroupsComponent {

  constructor(
    protected trainingChangeRequestService: TrainingChangeRequestService,
    protected tcrStateService: TCRStateService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    protected RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    protected spinner: NgxSpinnerService,
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected toastrService: ToastrService,
    private employeeManagementService: EmployeeManagementService,
  ) {
    super(
      trainingChangeRequestService,
      tcrStateService,
      route,
      router,
      SSMService,
      RNService,
      accountService,
      hotkeys,
      spinner,
      translate,
      dialog,
      toastrService,
    );
    this.baseRoute = '/actions/trainingChange/';
    this.employeeView = true;

    this.filterApi.add(
      {
        field: "related",
        value: "true"
      }
    );

    this.filterApi.addInclude("Course");
    this.filterApi.addInclude("Degree");
    this.filterApi.addInclude("Employee");
    this.filterApi.addInclude("EmployeeCV");
    this.filterApi.addInclude("Language");
    this.filterApi.addInclude("Employee");

    this.loadData = () => {
      this.spinner.show();

      this.employeeManagementService.getEmployeeByUserId(this.accountService.currentUser.id).subscribe(employeeResponse => {
        this.employeeManagementService.updateEmployeeManagementRoutes(employeeResponse.employeeId);
        if (employeeResponse.employeeId) {
              this.filterApi.add(
                {
                  field: "employeeId",
                  value: employeeResponse.employeeId
                },
                true
              );

              this.trainingChangeRequestService.getAllTrainingChangeRequestGroupBy(this.filterApi.getStrinFilterApi()).subscribe(trainingChangeRequest => {
                const filteredItems = this.assignRequestStates(trainingChangeRequest.items);
                this.trainingChangeRequestService.updateTrainingChangeRequests(filteredItems);

                this.loadTrainingChangeRequests(filteredItems);
                this.spinner.hide();
              }, error => {
                this.spinner.hide();
                this.RNService.showError(error);
              });
        } else {
          this.loadTrainingChangeRequests([]);
        }
      });
    };
  }

  /**
   * Selecciona los estados que se deben manejar y los que se deben mostrar.
   */
  setRequestStates() {
    this.tabStates = [
      "REGISTERED",
      "PENDING",
      "APPROVED",
      "ANNULLED",
      "REJECTED",
      "ALL",
    ];

    this.excludeTCRStates = [
      TCRStateCode.CANCELED,
    ];

    this.setFilterRequestsStates();
  }
}

