import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
    './inzo-calendar.scss',
  ],
})
export class AppComponent {
  static className = 'AppComponent';
}
