import { Injectable, Inject, Injector } from '@angular/core';
import {
    Config,
    AuthService
} from 'inzo-portalempleado';
import { AppPermissions } from './models/app-permission.model';
import { environment } from 'src/environments/environment';
import { IApplicationConfig } from './models/iapplication-config';

@Injectable({ providedIn: 'root' })
export class AppService {
    constructor(
        @Inject('BASE_URL') private baseUrl: string,
        private injector: Injector,
    ) { }

    initializeAuth(): Promise<any> {
        return fetch(`${this.baseUrl}Home/Configuration`)
            .then(x => x.json())
            .then((data: IApplicationConfig) => {
                Config.scopes = 'portalEmpleado-api notification-api storage-api spa-api';
                Config.identityBaseUrl = `${data.identityApiUrl}`;
                Config.apiBaseUrl = `${data.portalEmpleadoApiUrl}`;
                Config.notificationsBaseUrl = `${data.notificationApiUrl}`;
                Config.baseHref = environment.production ? '/portalEmpleado' : '';
                Config.permissionsClass = AppPermissions;
                Config.pushServerPublicKey = data.pushServerPublicKey;
                const auth = this.injector.get(AuthService);
                auth.setupUserManager();
            });
    }

}
