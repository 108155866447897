import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TrainingChangeRequestService } from 'src/app/services/training-change-request.service';

@Component({
  selector: 'app-tcr',
  templateUrl: './tcr.component.html',
  styleUrls: [
    './tcr.component.css',
    './../action.components.css',
  ],
})
export class TCRComponent implements AfterViewInit, OnInit {
  showForm = false;
  constructor( private trainingChangeRequestService: TrainingChangeRequestService) {
  }
  ngAfterViewInit() {
    this.trainingChangeRequestService.trainingChangeRequestObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
  ngOnInit() {
  }
}
