import { encode } from 'url-encode-decode';

export class IncludesApi {
  _includes: string[] = [];

  constructor() { }

  get includes() {
    return this._includes;
  }

  /**
   * Añade un include nuevo.
   *
   * @param includeApi nuevo include
   * @returns boolean con el resultado de la operación
   */
  add(includeApi: string) {
    let result = false;

    if (!this._includes.includes(includeApi)) {
      this._includes.push(includeApi);
      result = true;
    }

    return result;
  }

  /**
   * Elimina el include con el nombre especificado.
   *
   * @param includeName nombre del include a eliminar
   * @returns boolean con el resultado de la operación
   */
  delete(includeName: string) {
    let result = false;
    const index = this._includes.indexOf(includeName);

    if (index > -1) {
      this._includes.splice(index, 1);
      result = true;
    }

    return result;
  }

  /**
   * Transforma el array de includes en un string para encadenar en una url.
   *
   * @returns un string válido para incluír en una petición get
   */
  getStrinIncludeApi(firstChar = true) {
    let result = ``;

    for (let i = 0; i < this.includes.length; i++) {
      let item = this.includes[i];

        if (i == 0) {
          if (firstChar) {
            result += "?";
          }
        } else {
          result += "&";
        }

        result += `includes=${encode(item)}`;
    }

    return result;
  }
}
