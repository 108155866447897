export class AttributeChange {
  attribute     : string;
  path          : string;
  oldValue      : string;
  newValue      : string;

  constructor(obj?: Partial<AttributeChange>) {
    this.attribute  = (obj && obj.attribute) || undefined;
    this.path       = (obj && obj.path) || undefined;
    this.oldValue   = (obj && obj.oldValue) || undefined;
    this.newValue   = (obj && obj.newValue) || undefined;
  }
}
