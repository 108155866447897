import { Absence } from "./absence.model";
import { EmployeeDocument } from "./employee-document.model";

export class AbsenceDocument extends EmployeeDocument {
  absenceId : string;
  absence   : Absence;

  constructor(obj?: Partial<AbsenceDocument>) {
    super();

    this.absenceId  = (obj && obj.absenceId) || undefined;
    this.absence    = (obj && obj.absence) || undefined;
  }
}
