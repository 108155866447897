import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Language } from 'src/app/models/language.model';
import { LanguageService } from 'src/app/services/language.service';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeLanguage } from 'src/app/models/employee-language.model';
import { EmployeeManagementChangesService } from 'src/app/services/employee-management-changes.service';
import { getDateString } from 'src/app/helpers/date.helper';

@Component({
  selector: 'app-language-list',
  templateUrl: 'language-list.component.html',
  styleUrls: ['./../../maintenance.components.css','../../maintenance-list.component.css'],
})
export class LanguageListComponent extends BaseListComponent<Language> implements OnInit, OnDestroy {
  @Input() languages: Language[] = [];
  @Input() employeeView: boolean = false;
  @Input() currentEmployee: Employee;
  @Input() editable: boolean;
  employeeLanguage: EmployeeLanguage;

  @Output() currentLanguage: EventEmitter<Language> = new EventEmitter();

  subscriptions: Subscription[] = [];

  roles: Role[];

  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;

  languageId: string;

  canAdd = false;
  canDelete = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  constructor(
    private languageService: LanguageService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
    private employeeManagementChanges: EmployeeManagementChangesService,
  ) {
    super(accountService, SSMService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ) {
      this.canAdd = true;
      this.canDelete = true;
    }

    this.baseRoute = '/formations/language';

    this.onBuild = () => {
    };

    this.loadData = () => {
      if (!this.employeeView) {
        this.spinner.show();

        this.subscriptions.push(
          this.route.paramMap.subscribe(params => {
            this.subscriptions.push(
              this.languageService.getAllLanguages().subscribe(acActividad => {
                this.languageService.updateLanguages(acActividad.items);
                this.languages = acActividad.items;
                this.spinner.hide();
              }, error => {
                this.spinner.hide();
                this.RNService.showError(error);
              })
            );
          })
        );
      }
    };

    this.onInit = () => {
      if (this.employeeView) {
        this.baseRoute = '';
      }

      this.subscriptions.push(
        this.employeeManagementChanges.canEdit.subscribe( canEdit => {
          this.editable = canEdit;
        })
      );

      if(!this.employeeView) {
        this.loadData();

        this.languageService.languageObservable.subscribe(arrayacActividad => {
          this.languages = arrayacActividad;
        });
      }
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  disassociateLanguage(language: Language){
    this.currentLanguage.emit(language);
  }

  getEmployeeLanguage(language: Language): EmployeeLanguage{
    let employeeLanguages = this.currentEmployee.employeeLanguages;
    let result: EmployeeLanguage = null;

    for(let id of employeeLanguages ){
      if(id.languageId == language.languageId){
        result = id;
        break;
      }
    }

    return result;
  }

  getTrainingCenter(language: Language) {
    let employeeLanguage = this.getEmployeeLanguage(language);

    return (employeeLanguage && employeeLanguage.trainingCenter)
    ? employeeLanguage.trainingCenter.name
      : "";
  }

  getFinishDate(language: Language) {
    let employeeLanguage = this.getEmployeeLanguage(language);

    return employeeLanguage && employeeLanguage.finishDate
      ? getDateString(employeeLanguage.finishDate, "DD/MM/YYYY")
      : "";
  }
}

