import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';

import { Category } from '../models/category.model';

@Injectable({ providedIn: 'root' })
export class CategoryService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private categorySubject: ReplaySubject<Category[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public categoryObservable: Observable<Category[]> = this.categorySubject.asObservable();

  private categoryRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public categoryRouteObservable: Observable<string> = this.categoryRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/category';
  }

  constructor(private http: HttpClient) {
  }

  getAllCategories(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getCategory(categoryId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/${categoryId}${filter}`;

    return this.http.get<Category>(url);
  }

  createCategory(category: Category) {
    const url = `${this.serviceUrl}`;

    return this.http.post(url, category);
  }

  deleteCategory(categoryId: string) {
    const url = `${this.serviceUrl}/${categoryId}`;

    return this.http.delete(url);
  }

  modifyCategory(categoryId: string, category: any) {
    const url = `${this.serviceUrl}/${categoryId}`;

    return this.http.patch(url, category);
  }

  updateCategories(categories: any) {
    this.categorySubject.next(categories);
  }

  updateCategoryRoutes(id: string) {
    this.categoryRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
