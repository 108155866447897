import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ITLeaveManagementComponent } from '../it-leave-management.component';

@Component({
  selector: 'app-it-leave-management-reject-dialog',
  templateUrl: './it-leave-management-reject-dialog.component.html',
  styleUrls: ['./it-leave-management-reject-dialog.component.css']
})
export class ITLeaveManagementRejectDialogComponent{
  formGroupControl: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ITLeaveManagementComponent>,
  ) { }

  ngOnInit() {
    this.formGroupControl = new FormGroup({
      reason: new FormControl('', Validators.required),
    });
  }

  update() {
    this.dialogRef.close(this.formGroupControl.get('reason').value);
  }

}
