import { Country } from "./country.model";
import { LocationApp } from './location-app.model';

export class Province {
  provinceId  : string;
  code        : string;
  name        : string;

  //Relations
  countryId   : string;
  country     : Country;
  locations   : LocationApp[];

  constructor(obj?: Partial<Province>) {
    Object.assign(this, obj);
  }
}
