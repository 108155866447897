
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-absence-document-create-dialog',
  templateUrl: './absence-document-create-dialog.component.html',
  styles: []
})
export class AbsenceDocumentCreateDialogComponent {

  constructor(
      public dialogRef: MatDialogRef<any>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

}