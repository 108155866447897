import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, BaseListComponent, Hotkeys, ResponseNotificationService, SecurityStateManagementService, Role } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TrainingChangeRequestGroupItems } from 'src/app/interfaces/tcr-group-api.interface';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { TCRState, TCRStateCode } from 'src/app/models/tcr-state.model';
import { TCRStateService } from 'src/app/services/tcr-state.service';
import { TrainingChangeRequestService } from 'src/app/services/training-change-request.service';

@Component({
  selector: 'app-tcr-groups',
  templateUrl: './tcr-groups.component.html',
  styleUrls: [
    './tcr-groups.component.css',
    './../../action.components.css',
    '../../action-list.component.css',
  ],
})
export class TCRGroupsComponent extends BaseListComponent<TrainingChangeRequestGroupItems> {
  employeeView = false;

  roles: Role[];

  trainingChangeRequests: TrainingChangeRequestGroupItems[] = [];
  tcrStates: TCRState[] = [];
  subscriptions: Subscription[] = [];
  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;

  filterApi: FiltersApi = new FiltersApi();

  tabStates: string[] = [];
  excludeTCRStates: TCRStateCode[] = [];

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  constructor(
    protected trainingChangeRequestService: TrainingChangeRequestService,
    protected tcrStateService: TCRStateService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    protected RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    protected spinner: NgxSpinnerService,
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected toastrService: ToastrService,
  ) {
    super(accountService, SSMService, router, hotkeys);

    this.baseRoute = '/managements/dataChange';

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];

    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ) {
    }

    this.onBuild = () => {
    };

    this.loadData = () => {
      this.spinner.show();

      this.filterApi.add(
        {
          field: "related",
          value: "true"
        }
      );

      this.filterApi.addInclude("Course");
      this.filterApi.addInclude("Degree");
      this.filterApi.addInclude("Employee");
      this.filterApi.addInclude("EmployeeCV");
      this.filterApi.addInclude("Language");

      this.subscriptions.push(
        this.route.paramMap.subscribe(params => {
          this.subscriptions.push(
            this.trainingChangeRequestService.getAllTrainingChangeRequestGroupBy(this.filterApi.getStrinFilterApi()).subscribe(trainingChangeRequest => {
              const filteredItems = this.assignRequestStates(trainingChangeRequest.items);

              this.trainingChangeRequestService.updateTrainingChangeRequests(filteredItems);
              this.loadTrainingChangeRequests(filteredItems);

              this.spinner.hide();
            }, error => {
              this.spinner.hide();
              this.RNService.showError(error);
            })
          )
        })
      );
    };

    this.onInit = () => {
      this.getTCRStates();

      this.loadData();

      this.subscriptions.push(
        this.trainingChangeRequestService.trainingChangeRequestObservable.subscribe(response => {
          this.loadTrainingChangeRequests(response);
        })
      );
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  refresh() {
    this.loadData();
  }

  getTCRStates() {
    this.tcrStateService.getAllTCRStateRequest().subscribe(tcrStateRequests => {
      this.tcrStates = tcrStateRequests.items;

      this.setRequestStates();
    }, error => {
      this.RNService.showError(error);
    });
  }

  assignRequestStates(items) {
    for (let item of items) {
      for (let tcr of item) {
        // Se recupera el estado
        tcr.state = this.tcrStates.find(element => element.tcrStateId == tcr.tcrStateId);
      }
    }

    return items;
  }

  // TODO: refacotrizar para simplificar
  loadTrainingChangeRequests(items) {
    // Se vacía el array
    this.trainingChangeRequests = new Array();

    // Se prepara el array nominativo
    for (let stateName of this.tabStates) {
      this.trainingChangeRequests[stateName] = [];
    }

    // Se rellena el array con los distintos items
    for (let item of items) {
      let temp = {
        ALL: new Array()
      };

      // Se recorren las HolidayRequest de cada item y se clasifican
      for (let trainingChangeRequest of item) {
        if (!this.excludeTCRStates.includes(trainingChangeRequest.state.stateCode)) {
          let indexName = TCRStateCode[trainingChangeRequest.state.stateCode];
          if (indexName.includes("PENDING")) {
            indexName = "PENDING";
          }

          if (temp.hasOwnProperty(indexName)) {
            temp[indexName].push(trainingChangeRequest);
          } else {
            temp[indexName] = [trainingChangeRequest];
          }

          temp["ALL"].push(trainingChangeRequest);
        }
      }

      // Se añaden las HolidayRequest ya clasificadas a la lista agrupadas por Employee
      for (let element in this.trainingChangeRequests) {
        if (Object.keys(temp).includes(element)) {
          this.trainingChangeRequests[element].push(temp[element]);
        }
      }
    }
  }

  /**
   * Devuelve el de solicitudes que se debe mostrar en las pestañas
   *
   * @param stateCode código de estado
   */
  getTabCount(dataChangeRequestsName: any): string {
    let result = ``;

    let trainingChangeRequestsGroup: any = this.trainingChangeRequests[dataChangeRequestsName];

    if (trainingChangeRequestsGroup) {
      let countGroups = trainingChangeRequestsGroup.length;
      let countRequest = 0;

      for (let i = 0; i < trainingChangeRequestsGroup.length; i++) {
        countRequest += trainingChangeRequestsGroup[i].length;
      }

      if (this.employeeView) {
        result += `${countRequest}`;
      } else {
        // result += `${countGroups} - ${countRequest}`;
        result += `${countGroups}`;
      }
    }

    return result;
  }
  /**
   * Crea un mensaje de cambio de estado correcto de la solicitud
   *
   * @param state nuevo estado de la solicitud
   * @param reasonReject razón del rechazo
   */
  successToastRequest(state: string, reasonReject: string = "") {
    let msg = "UPDATE";

    switch (state) {
      case "ANNULLED":
        msg = "ANNULLED";
        break;
      case "APPROVED":
        msg = "APPROVED";
        break;
      case "CANCELED":
        msg = "CANCEL";
        break;
      case "PENDING_ANNULLED":
      case "PENDING_APROVAL":
        msg = "SEND";
        break;
      case "REJECTED":
        msg = "REJECTED";
        break;
    }

    // TODO: Cambiar mensajes

    this.translate.get('HOLIDAY_REQUEST_MAINTENANCE.NAME').subscribe((componentTranslated: string) => {
      let toastTitle = '';
      let toastBody = '';
      this.translate.get(`MESSAGES.SUCCESS.${msg}`).subscribe((a: string) => { toastTitle = a });
      this.translate.get(`MESSAGES.SUCCESS.${msg}_LONG`, { type: componentTranslated, code: reasonReject }).subscribe((a: string) => { toastBody = a });
      this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
    });
  }

  /**
   * Selecciona los estados que se deben manejar y los que se deben mostrar.
   */
  setRequestStates() {
    this.tabStates = [
      "PENDING",
      "APPROVED",
      "REJECTED",
      "ANNULLED",
      "ALL",
    ];

    this.excludeTCRStates = [
      TCRStateCode.REGISTERED,
      TCRStateCode.CANCELED,
    ];

    this.setFilterRequestsStates();
  }

  /**
   * Asigna el filtro para obtener sólo las solicitudes con los estados que se van a manejar.
   */
  setFilterRequestsStates() {
    this.filterApi.addMultipleValue(
      "tcrStateId",
      this.getRequestStatesId(this.excludeTCRStates, true),
      true
    );
  }

  /**
   * Devuelve los Ids de la base de datos de la lista de códigos de estado pasada como parámetro.
   *
   * @param requestStateCodeList lista de códigos a procesar.
   * @param reverse si es true en lugar de procesar los estados de la lista pasada se procesan los
   * los estados que no coincidan con la lista.
   * @returns una lista de Ids de estados
   */
  getRequestStatesId(requestStateCodeList: TCRStateCode[], reverse: boolean = false) {
    let result: string[] = [];

    let requestStateTemp: TCRState[] = [];

    if (reverse) {
      requestStateTemp = this.tcrStates.filter(element => !requestStateCodeList.includes(element.stateCode));
    } else {
      requestStateTemp = this.tcrStates.filter(element => requestStateCodeList.includes(element.stateCode));
    }

    for (let item of requestStateTemp) {
      result.push(item.tcrStateId);
    }

    return result;
  }
}

