import { EmployeeDocument } from "./employee-document.model";
import { Employee } from "./employee.model";

export class CV extends EmployeeDocument {
  //Relations
  employeeId  : string;
  employee    : Employee;

  constructor(obj?: Partial<CV>) {
    super();

    //Relations
    this.employeeId = (obj && obj.employeeId) || undefined;
    this.employee   = (obj && obj.employee) || undefined;
  }
}
