import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, BaseFormComponent, Hotkeys, Role } from 'inzo-portalempleado';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AbsenceType } from 'src/app/models/absence-type.model';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { AbsenceTypeService } from 'src/app/services/absence-type.service';
import * as jsonpatch from 'fast-json-patch';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { AbsenceTypeConfirmationDeleteDialogComponent } from '../absence-type-confirmation-delete-dialog/absence-type-confirmation-delete-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-absence-type-maintenance-form',
  templateUrl: './absence-type-maintenance-form.component.html',
  styleUrls: [
    './absence-type-maintenance-form.component.css',
    './../../maintenance.components.css',
    '../../maintenance-list.component.css',
  ],
})
export class AbsenceTypeMaintenanceFormComponent extends BaseFormComponent<AbsenceType> implements OnInit, OnDestroy {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  selectedDesc: string = undefined;
  absenceType: AbsenceType;

  enabled = true;
  detail = false;

  roles: Role[];

  formGroupControl = new FormGroup({
    code: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
  });

  canAdd = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;
  subscription: Subscription;

  filtersApi: FiltersApi = new FiltersApi();

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private toastrService: ToastrService,
    private absenceTypeService: AbsenceTypeService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];

    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
    }

    this.filtersApi.add(
      {
        field: "isMedicalLeave",
        value: "true"
      }
    );

    this.PK = 'absenceTypeId';

    this.baseRoute = '/maintenances/absence_type';


    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('AREA_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          if (
            this.accountService.currentUser.isAdmin ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RA) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RP)
          ) {
          }
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  private init() {
    this.formGroupControl.get('code').disable();
    this.formGroupControl.get('description').disable();
    this.absenceType = new AbsenceType();

    this.subscription = this.route.paramMap.subscribe(params => {
        const transId = params.get('id');
        this.absenceTypeService.updateAbsenceTypeRoutes(transId);
        if (transId) {
          if (transId === 'new') {
            this.detail = false;
            this.enabled = true;
            this.absenceType = new AbsenceType();
            this.selectedDesc = undefined;
            this.formGroupControl.setValue({ code: '', description: '' });
            this.formGroupControl.get('code').enable();
            this.formGroupControl.get('description').enable();
          } else {
            this.absenceTypeService.getAbsenceType(transId).subscribe(response => {
              this.detail = true;
              this.enabled = false;
              this.absenceType = response;
              this.formGroupControl.setValue({ code: this.absenceType.description, description: this.absenceType.description });
              this.formGroupControl.get('code').disable();
              this.formGroupControl.get('description').disable();
              this.selectedDesc = response.description;
            });
          }
        } else {
          this.selectedDesc = undefined;
          this.detail = false;
          this.enabled = false;
          this.absenceType = new AbsenceType();
        }
    });
  }

  edit() {
    this.enabled = true;
    // this.formGroupControl.get('code').enable();
    this.formGroupControl.get('description').enable();
  }

  cleanForm() {
    this.formGroupControl.setValue({ code: '', description: '' });
  }

  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  create() {
    this.absenceType = new AbsenceType({
      code: this.formGroupControl.get('code').value,
      description: this.formGroupControl.get('description').value,
      isMedicalLeave: true
    });
    this.absenceTypeService.createAbsenceType(this.absenceType).subscribe(
      response => {
        this.absenceTypeService.getAllAbsenceTypes(this.filtersApi.getStrinFilterApi()).subscribe(absenceTypes => {
          this.absenceTypeService.updateAbsenceTypes(absenceTypes.items);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.CREATE_LONG', {
            type: this.componentTypeTranslated,
            code: this.absenceType.description,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.cancel();
      },
      error => {
        if (error.status === 500 || error.status === 0) {
        } else if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
        }
      },
    );
  }

  update() {
    const observer = jsonpatch.observe(this.absenceType);
    this.absenceType.code = this.formGroupControl.get('code').value;
    this.absenceType.description = this.formGroupControl.get('description').value;
    this.absenceType.isMedicalLeave = true;
    const patch = jsonpatch.generate(observer);
    this.absenceTypeService.modifyAbsenceType(this.absenceType.absenceTypeId, patch).subscribe(
      response => {
        this.absenceTypeService.getAllAbsenceTypes(this.filtersApi.getStrinFilterApi()).subscribe(departments => {
          this.absenceTypeService.updateAbsenceTypes(departments.items);
        });
        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );

  }

  delete() {
    const confirmationDialog = this.dialog.open(AbsenceTypeConfirmationDeleteDialogComponent, {
      width: '550px',
      data: {
        description: this.absenceType.description
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      if (confirm === true) {
        this.absenceTypeService.deleteAbsenceType(this.absenceType.absenceTypeId).subscribe(response => {
          this.absenceTypeService.getAllAbsenceTypes(this.filtersApi.getStrinFilterApi()).subscribe(absenceTypes => {
            this.absenceTypeService.updateAbsenceTypes(absenceTypes.items);
          });
          let toastTitle = '';
          let toastBody = '';
          this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {
            toastTitle = a;
          });
          this.translate
            .get('MESSAGES.SUCCESS.DELETE_LONG', {
              type: this.componentTypeTranslated,
              code: this.absenceType.description,
            })
            .subscribe((a: string) => {
              toastBody = a;
            });
          this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
          this.cancel();
        });
      }
    });
  }

  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  cancel() {
    this.cleanForm();
    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }

  back() {
    this.cancel();
  }
}
