import { EmployeeDocument } from "./employee-document.model";
import { Employee } from "./employee.model";

export class IRPF extends EmployeeDocument {
  year        : string;
  employeeId  : string;

  //Relations
  employee    : Employee;

  constructor(obj?: Partial<IRPF>) {
    super();

    this.year       = (obj && obj.year) || undefined;
    this.employeeId = (obj && obj.employeeId) || undefined;

    //Relations
    this.employee   = (obj && obj.employee) || undefined;
  }
}
