import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { EmployeeHistory } from '../models/employee-history.model';
import { Employee } from '../models/employee.model';


@Injectable({ providedIn: 'root' })
export class EmployeeHistoryService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private employeeSubject: ReplaySubject<Employee[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeObservable: Observable<Employee[]> = this.employeeSubject.asObservable();

    private employeeRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeRouteObservable: Observable<string> = this.employeeRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/EmployeeHistory';
    }

    constructor(private http: HttpClient) {
    }

    getAllEmployeeHistory(filter?: string) {
      if (filter == undefined || filter == null) {
        filter = "";
      }

      let url = `${this.serviceUrl}${filter}`;

      return this.http.get<any>(url);
    }

    getHistoryForEmployee(employeeId: string, filter?: string) {
      if (filter == undefined || filter == null) {
        filter = "";
      }
  
      const url = `${this.serviceUrl}/employee/${employeeId}${filter}`;
  
      return this.http.get<any>(url);
    }

    createEmployeeHistory(employeeHistory: EmployeeHistory) {
      const endpointUrl = `${this.serviceUrl}`;
      return this.http.post<EmployeeHistory>(endpointUrl, employeeHistory);
    }

    deleteEmployeeHistory(employeeTypeId: string) {
      const endpointUrl = `${this.serviceUrl}/${employeeTypeId}`;
      return this.http.delete<EmployeeHistory>(endpointUrl);
    }

    modifyPersonalData(employeeId: string, newEmployeeData: any) {
        const endpointUrl = `${this.serviceUrl}/${employeeId}`;
        return this.http.patch(endpointUrl, newEmployeeData);
    }

    associateUser(login: string, userId: string) {
      const endpointUrl = `${this.serviceUrl}/${login}/${userId}`;
      return this.http.put<Employee>(endpointUrl,null);
    }

    updateEmployees(employees: Employee[]) {
        this.employeeSubject.next(employees);
    }

    updateEmployeeRoutes(id: string) {
        this.employeeRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}
