import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { EmployeeManagementInfo } from '../models/employee-management-info.model';
import { Employee } from '../models/employee.model';


@Injectable({ providedIn: 'root' })
export class EmployeeManagementService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private employeeManagementSubject: ReplaySubject<Employee[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeManagementObservable: Observable<Employee[]> = this.employeeManagementSubject.asObservable();

    private employeeManagementRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeManagementRouteObservable: Observable<string> = this.employeeManagementRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/EmployeeManagement';
    }

    constructor(private http: HttpClient) {
    }

    getAllEmployees(filter? : string) {
      let url=`${this.serviceUrl}`;
      if(filter!=undefined){
        url += `${filter}`;
      }
      return this.http.get<any>(url);
    }

    GetAllEmployeesHierarchy(filter? : string) {
      let url=`${this.serviceUrl}/GetAllEmployeesHierarchy`;

      if(filter!=undefined){
        url += `${filter}`;
      }
      return this.http.get<any>(url);
    }

    getEmployee(employeeId: string, related?: boolean) {
      let url=`${this.serviceUrl}/${employeeId}`;

      if(related!=undefined && related != null){
          url += `?related=${related}`;
      }

      return this.http.get<Employee>(url);
    }

    getEmployeeByUserId(userId: string, related?: boolean, personalData?: boolean) {
      let url=`${this.serviceUrl}/UserId/${userId}`;

      if (related!=undefined && related != null) {
        url += `?related=${related}`;

        if (personalData != undefined && personalData != null) {
          url += `&personalData=${personalData}`;
        }
      }

      return this.http.get<Employee>(url);
    }

    getEmployeeMgntInfo(employeeId: string, filter? : string) {
      let url=`${this.serviceUrl}/Info/${employeeId}`;

      if(filter!=undefined){
        url += `${filter}`;
      }

      return this.http.get<EmployeeManagementInfo>(url);
    }

    getManagementInfo(employeeId?: string, userId: boolean = false) {
      if (employeeId == undefined || employeeId  == null) {
        employeeId = "";
      } else if (userId) {
        employeeId = `?userId=${employeeId}`
      }

      let url=`${this.serviceUrl}/InfoManagement/${employeeId}`;

      return this.http.get<EmployeeManagementInfo>(url);
    }

    createEmployee(newEmployeeData: Employee) {
      const endpointUrl = `${this.serviceUrl}`;
      return this.http.post<Employee>(endpointUrl, newEmployeeData);
    }

    modifyEmployee(employeeId: string, employee: any) {
      const endpointUrl = `${this.serviceUrl}/${employeeId}`;
      return this.http.patch(endpointUrl, employee);
    }

    deleteEmployee(employeeId: string) {
      const endpointUrl = `${this.serviceUrl}/${employeeId}`;
      return this.http.delete(endpointUrl);
    }

    getEmployeeByUserLogin(login: string) {
      const endpointUrl = `${this.serviceUrl}/user/${login}`;
      return this.http.get<Employee>(endpointUrl);
    }

    updateEmployeeManagements(employeeManagements: Employee[]) {
      this.employeeManagementSubject.next(employeeManagements);
    }

    updateEmployeeManagementRoutes(id: string) {
      this.employeeManagementRouteSubject.next(id);
    }

    notifyChanges() {
      this.changeSubject.next();
    }
}
