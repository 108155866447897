import { Component, ViewChild, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatAutocompleteSelectedEvent, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { transformDate } from 'src/app/helpers/date.helper';
import { Employee } from 'src/app/models/employee.model';
import { Subscription } from 'rxjs';
import { getDateTimeString } from 'src/app/helpers/date.helper';
import { Area } from 'src/app/models/area.model';
import { EmployeeAreaHistory } from 'src/app/models/employee-area-history.model';
import { DataHistoryService } from '../../../services/data-history.service';
import { EmployeeAreaHistoryService } from 'src/app/services/employee-area-history.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as Rxjs from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AreaService } from 'src/app/services/area.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';

@Component({
  selector: 'app-area-history-list',
  templateUrl: './employee-area-history-list.component.html',
  styleUrls: [
    './employee-area-history-list.component.css',
    '../../../../../../assets/styles/action.components.css',
    '../../../../../../assets/styles/action-list.component.css',
  ]
})
export class EmployeeAreaHistoryListComponent implements OnInit, OnDestroy {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  showAddPanel = false;
  employeeView = false;
  employee: Employee = null;
  employees: Employee[] = [];
  allAreas: Area[] = [];
  displayedColumns: string[] = [
    'actions',
    'startDate',
    'createdByUserId',
    'areaId',
  ];

  areaHistoryFormGroup: FormGroup = new FormGroup({
    areaId: new FormControl('', Validators.required),
    startDate: new FormControl('', Validators.required),
  });;

  startDateFilter: string;
  finishDateFilter: string;

  ELEMENT_DATA: EmployeeAreaHistory[] = [];
  dataSource: MatTableDataSource<EmployeeAreaHistory>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  subscriptions: Subscription[] = [];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private translate: TranslateService,
    private dataHistoryService: DataHistoryService,
    private employeeAreaHistoryService: EmployeeAreaHistoryService,
    protected permissionsService: PermissionsService,
    private areaService: AreaService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
  ) {
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;
      })
    );

    // EmployeeView
    this.subscriptions.push(
      this.dataHistoryService.employeeView.subscribe(data => {
        this.employeeView = data;
      })
    );
    
    // Areas
    this.subscriptions.push(
      this.dataHistoryService.areas.subscribe(data => {
        this.allAreas = data;
      })
      );

    // Employee
    this.subscriptions.push(
      this.dataHistoryService.employee.subscribe(data => {
        this.employee = data;
  
        this.loadDataOnTable();
      })
    );

  }
    
  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  /* ################################################################################################################## */
  /* ## DATA METHODS
  /* ################################################################################################################## */
  onCreate() {
    if(this.areaHistoryFormGroup && this.areaHistoryFormGroup.valid) {
      this.spinner.show();

      const areaHistoric: EmployeeAreaHistory = this.areaHistoryFormGroup.value;
      areaHistoric.employeeId = this.employee.employeeId;
      areaHistoric.startDate = moment(areaHistoric.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

      this.employeeAreaHistoryService.createAreaHistoryForEmployee(areaHistoric).subscribe(
        response => {

          this.areaHistoryFormGroup.get('areaId').setValue('');
          this.areaHistoryFormGroup.get('startDate').setValue('');

          this.translate.get('AREAHISTORY_MAINTENANCE.FORM.CREATION_SUCCESS').subscribe((text: string) => {
            this.toastrService.success(text, '', { timeOut: 3000 });
          });

          this.getAndLoadDataOnTable();

          this.showAddPanel = false;
        },
        error => {
          console.error('error --> ', error);
          this.spinner.hide();
          if(error.error === 'MISSING_PARAMS') {
            this.translate.get('AREAHISTORY_MAINTENANCE.FORM.ERROR.MISSING_PARAMS').subscribe((text: string) => {
              this.toastrService.error(text, '', { timeOut: 3000 });
            });
          } else {
            this.translate.get('AREAHISTORY_MAINTENANCE.FORM.ERROR.UNEXPECTED').subscribe((text: string) => {
              this.toastrService.error(text, '', { timeOut: 3000 });
            });
          }

      });
    }
  }


  onRemove(employeeAreaHistory: EmployeeAreaHistory) {
    let text;
    this.translate.get('AREAHISTORY_MAINTENANCE.MESSAGE.DELETE_CONFIRMATION.TEXT').subscribe((transitionStr: string) => {
      text = transitionStr;
    });

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      data: {
        text
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.spinner.show();
        this.employeeAreaHistoryService.removeAreaHistoryForEmployee(employeeAreaHistory.employeeAreaHistoryId).subscribe(
          response => {
              this.translate.get('AREAHISTORY_MAINTENANCE.FORM.DELETION_SUCCESS').subscribe((text: string) => {
                this.toastrService.success(text, '', { timeOut: 3000 });
              });

              this.getAndLoadDataOnTable();
          },
          error => {
            this.spinner.hide();
            this.translate.get('AREAHISTORY_MAINTENANCE.FORM.ERROR.UNEXPECTED').subscribe((text: string) => {
              this.toastrService.error(text, '', { timeOut: 3000 });
            });
          });
      }
    });
  }

  loadDataOnTable() {
    let filters: string = "";

    if (this.employee != undefined && this.employee != null) {
      this.spinner.show();

      this.ELEMENT_DATA = this.employee.employeeAreasHistory;

      // Se busca el valor actual
      this.assigCurrentArea();

      this.ELEMENT_DATA = _.orderBy(this.ELEMENT_DATA, a => moment(a.startDate).unix(), 'desc');
      this.dataSource = new MatTableDataSource<EmployeeAreaHistory>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.spinner.hide();
    }
  }

  getAndLoadDataOnTable() {
    this.employeeAreaHistoryService.getAreaHistoryForEmployee(this.employee.employeeId,null,'CreatedByUser').subscribe(areaHisoryResponse => {
      this.spinner.hide();
      this.employee.employeeAreasHistory = (areaHisoryResponse && areaHisoryResponse.items) || [];

      this.loadDataOnTable();

      // Se propaga el cambio a los observadores
      this.dataHistoryService.setEmployeeChange(this.employee);
    },
    error => {
      this.spinner.hide();
    });
  }

  assigCurrentArea() {
    // Se busca el valor actual
    const currentAreaHistory = _(this.employee.employeeAreasHistory).filter(x => moment(x.startDate) <= moment()).maxBy(x => moment(x.startDate).unix());
    this.employee.area = (currentAreaHistory)? currentAreaHistory.area : null;
    this.employee.areaId =  (currentAreaHistory)? currentAreaHistory.areaId : null;
  }

  /* ################################################################################################################## */
  /* ## AUTOCOMPLETE METHODS
  /* ################################################################################################################## */
  areaSelectedOptionChanged(e: MatAutocompleteSelectedEvent) {
    const selectedAreaId: string = e.option.value;

    const selectedArea = this.allAreas.find(x => x.areaId === selectedAreaId);
    if(!_.isNil(selectedArea)) {
      this.areaHistoryFormGroup.get('areaId').setValue(selectedArea.areaId);
    } else {
      this.areaHistoryFormGroup.get('areaId').setValue('');
    }
  }

  areaDisplayFn = (areaId?: string) => {
    if(this.allAreas) {
      return areaId ? this.allAreas.find(x => x.areaId===areaId).description : undefined;
    }
  }

  onStartDateSelect(date: any): void {
    this.areaHistoryFormGroup.get('startDate').setValue(moment(date).format('DD/MM/YYYY'));
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  getArea(areaId) {
    let area = this.allAreas.find(element => element.areaId == areaId);
    let result = (area != null)
      ? area.description
      : "";

    return result;
  }

  getCreatedBy(employeeId) {
    let employee = this.employees.find(element => element.employeeId == employeeId);
    let result = (employee != null)
      ? `${employee.secondSurname} ${employee.firstSurname}, ${employee.name}`
      : "";

    return result;
  }

  getDate(date: any, format: string): string {
    let result = getDateTimeString(date, format);

    return result != "Invalid date"
      ? result
      : "";
  }

  isCurrentValue(item): boolean {
    const currentAreaHistory = _(this.employee.employeeAreasHistory).filter(x => moment(x.startDate) <= moment()).maxBy(x => moment(x.startDate).unix());
    return (currentAreaHistory)
      ? currentAreaHistory.employeeAreaHistoryId == item.employeeAreaHistoryId
      : false;
  }

  canAdd(): boolean {
    return (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH);
  }

  canDelete(item): boolean {
    let result = (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH);

    return result;
  }
}
