import { Employee } from "./employee.model";
import { Certification } from "./certification.model";
import { Language } from "./language.model";
import { TrainingCenter } from './training-center.model';

export class EmployeeLanguage {
    employeeLanguageId  : string;
    finishDate          : string;

    //Relations
    employeeId          : string;
    employee            : Employee;
    languageId          : string;
    language            : Language;

    employeeDocumentId  : string;
    certification       : Certification;

    trainingCenterId    : string;
    trainingCenter      : TrainingCenter;

    constructor(obj?: Partial<EmployeeLanguage>) {
      this.employeeLanguageId = (obj && obj.employeeLanguageId) || undefined;
      this.finishDate         = (obj && obj.finishDate) || undefined;

      //Relations
      this.employeeId         = (obj && obj.employeeId) || undefined;
      this.employee           = (obj && obj.employee) || undefined;

      this.languageId         = (obj && obj.languageId) || undefined;
      this.language           = (obj && obj.language) || undefined;

      this.employeeDocumentId = (obj && obj.employeeDocumentId) || undefined;
      this.certification      = (obj && obj.certification) || undefined;

      this.trainingCenterId   = (obj && obj.trainingCenterId) || undefined;
      this.trainingCenter     = (obj && obj.trainingCenter) || undefined;
    }
  }
