import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-certification-course',
  templateUrl: './certification-course.component.html',
  styleUrls: [
    './certification-course.component.css',
    './../../../components/maintenance.components.css',
  ]
})
export class CertificationCourseComponent implements OnInit {
  showForm = false;
  isCertificationCourse = true;

  constructor( private courseService: CourseService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.courseService.courseRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
}
