import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, Hotkeys, ResponseNotificationService, SecurityStateManagementService } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { CourseService } from 'src/app/services/course.service';
import { EmployeeManagementChangesService } from 'src/app/services/employee-management-changes.service';
import { CourseListComponent } from './course-list.component';


@Component({
  selector: 'app-certification-course-list',
  templateUrl: 'course-list.component.html',
  styleUrls: ['./../../maintenance.components.css','../../maintenance-list.component.css'],
})
export class CertificationCourseListComponent extends CourseListComponent {

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    protected courseService: CourseService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    protected RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    protected translate: TranslateService,
    protected spinner: NgxSpinnerService,
    protected employeeManagementChanges: EmployeeManagementChangesService,
  ) {
    super(courseService, route, router, SSMService, RNService, accountService, hotkeys, translate, spinner, employeeManagementChanges);

    this.isCertificationCourse = true;
    this.baseRoute = '/formations/certificationCourse';
  }
}
