import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { WorkCalendarService } from 'src/app/services/work-calendar.service';
import { IInputData } from 'inzo-calendar-lib/lib/Interface/IInputData';
import { LocaleSettings } from 'inzo-calendar-lib/lib/Interface/LocaleSettings';
import * as moment from 'moment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { WorkCalendarCreateRangeDialogComponent } from '../work-calendar-create-range-dialog/work-calendar-create-range-dialog.component';
import { WCEvent } from 'src/app/models/wc-event.model';
import { getLocaleNames, getDateString } from 'src/app/helpers/date.helper';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { ToastrService } from 'ngx-toastr';
import * as jsonpatch from 'fast-json-patch';
import { WorkCalendarImportEventsDialogComponent } from '../work-calendar-import-events-dialog/work-calendar-import-events-dialog.component';

const EVENT_DAY_COLOR = "fuchsia";

@Component({
  selector: 'app-work-calendar-list',
  templateUrl: 'work-calendar-list.component.html',
  styleUrls: ['./../../maintenance.components.css','../../maintenance-list.component.css', './work-calendar-list.component.css'],
})
export class WorkCalendarListComponent extends BaseListComponent<WorkCalendar> implements OnInit, OnDestroy {
  workCalendars: WorkCalendar[] = [];
  workCalendarSelected: WorkCalendar = null;
  workCalendarSelectedName: string = null;
  subscription: Subscription;
  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;
  roles: Role[];
  canAdd = false;
  canDelete = false;

  underline = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  value:IInputData = {
    year: new Date().getFullYear(),
    dates: [],
  };

  locale: LocaleSettings = {
    dayNamesMin: [],
    monthNames: []
  };

  filtersApi: FiltersApi = new FiltersApi();

  constructor(
    private toastrService: ToastrService,
    private workCalendarService: WorkCalendarService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {
    super(accountService, SSMService, router, hotkeys);
    this.baseRoute = '/maintenances/work_calendar';

    this.filtersApi.add(
      {
        field: "related",
        value: "true"
      }
    );
    this.filtersApi.addInclude("WCEvents");

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];

    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
    }

    this.onBuild = () => {
    };

    this.loadData = () => {
      this.spinner.show();
      this.workCalendarService.getAllWorkCalendar(this.filtersApi.getStrinFilterApi()).subscribe(workCalendars => {
        this.workCalendarService.updateWorkCalendars(workCalendars.items);
        this.workCalendars = workCalendars.items;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.RNService.showError(error);
      });
    };

    this.onInit = () => {
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.locale = getLocaleNames(this.translate);
      });
      this.translate.get('CALENDAR.DAYS_OF_WEEK').subscribe((daysOfWeekLang) => {
        this.locale.dayNamesMin = daysOfWeekLang;
      });
      this.translate.get('CALENDAR.MONTHS').subscribe((monthsLang) => {
        this.locale.monthNames = monthsLang;
      });

      this.loadData();

      this.workCalendarService.workCalendarObservable.subscribe(workCalendars => {
        this.workCalendars = workCalendars;

        if (this.workCalendars.length > 0) {
          // Por defecto, el primer work calendar es el seleccionado
          this.loadCalendar(this.workCalendars[0]);
        }
      });
    };

  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  editWorkCalendar(workCalendar: WorkCalendar): void {
    this.router.navigateByUrl(`${this.baseRoute}/${workCalendar.workCalendarId}`);
  }

  duplicateWorkCalendar(workCalendar: WorkCalendar): void {
    const observer = jsonpatch.observe(workCalendar);
    workCalendar.name = `${workCalendar.name}_${getDateString(new Date, "YYYYMMDD_HHmmss")}`;
    const patch = jsonpatch.generate(observer);

    this.spinner.show();
    this.workCalendarService.duplicateWorkCalendar(workCalendar.workCalendarId, patch).subscribe(
      reponse => {
        this.workCalendarService.getAllWorkCalendar(this.filtersApi.getStrinFilterApi()).subscribe(workCalendars => {
          this.workCalendarService.updateWorkCalendars(workCalendars.items);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.DUPLICATE').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.DUPLICATE_LONG_SIMPLE', {
            type: workCalendar.name,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.DUPLICATE').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
          this.RNService.showError(error);
        }
      }
    );
  }

  loadCalendar(workCalendar: WorkCalendar): void {
    this.workCalendarSelected = workCalendar;
    this.workCalendarSelectedName = workCalendar.name;
    this.value.disableWeekend = workCalendar.disableWeekends;
    this.loadEvents(workCalendar);
  }

  loadEvents(workCalendar: WorkCalendar): void {
    // Cada vez que recarguemos la lista de eventos, reiniciamos el array de fechas del calendario
    this.value.dates = [];

    workCalendar.wcEvents.forEach(event => {
      const countDates = this.value.dates.length;
      this.value.dates.push({
        id: countDates + 1,
        tooltip: event.name,
        start: moment(event.startDate).toDate(),
        end: moment(event.endDate).toDate(),
        color: EVENT_DAY_COLOR,
        select: () => this.onRangeSelect(event)
      })
    });
  }

  onRangeSelect(event: WCEvent) {
    const createRangeDialog = this.dialog.open(WorkCalendarCreateRangeDialogComponent, {
      width: '550px',
      data: {
        workCalendarId: this.workCalendarSelected.workCalendarId,
        workCalendarName: this.workCalendarSelected.name,
        event: event
      }
    });

    createRangeDialog.afterClosed().subscribe((dialogData) => {
      if (dialogData) {
        if (dialogData.updatedEvent) {
          let updatedEvent = dialogData.updatedEvent
          this.workCalendarSelected.wcEvents.forEach(event => {
            if (event.wcEventId == updatedEvent.wcEventId) {
              event = updatedEvent;
            }
          });
        } else if (dialogData.deletedEvent) {
          let deletedEvent = dialogData.deletedEvent
          let indexToDelete = null;
          this.workCalendarSelected.wcEvents.forEach((event, iEvent) => {
            if (event.wcEventId == deletedEvent.wcEventId) {
              indexToDelete = iEvent;
            }
          });
          if (indexToDelete) {
            this.workCalendarSelected.wcEvents.splice(indexToDelete, 1);
          }
        }
        this.loadEvents(this.workCalendarSelected);
      }
    });
  }

  createEvent(): void {
    const createRangeDialog = this.dialog.open(WorkCalendarCreateRangeDialogComponent, {
      width: '550px',
      data: {
        workCalendarId: this.workCalendarSelected.workCalendarId,
        workCalendarName: this.workCalendarSelected.name
      }
    });

    createRangeDialog.afterClosed().subscribe((dialogData) => {
      if (dialogData) {
        let createdEvent = dialogData.createdEvent;
        this.workCalendarSelected.wcEvents.push(createdEvent);
        this.loadEvents(this.workCalendarSelected);
      }
    });
  }

  importWCEvents(event: WCEvent) {
    const importWCEventsDialog = this.dialog.open(WorkCalendarImportEventsDialogComponent, {
      width: '550px',
      data: {
        workCalendars: this.workCalendars,
        workCalendarSelected: this.workCalendarSelected,
      }
    });

    importWCEventsDialog.afterClosed().subscribe(
      (dialogData) => {
        if (dialogData && dialogData.sourceWorkCalendarIds.length > 0) {
          this.spinner.show();
          this.workCalendarService.importEventsFromWorkCalendars(
            this.workCalendarSelected.workCalendarId,
            dialogData.sourceWorkCalendarIds
          ).subscribe(
            response => {
              this.workCalendarService.getAllWorkCalendar().subscribe(workCalendars => {
                this.workCalendarService.updateWorkCalendars(workCalendars.items);
              });
              let toastTitle = '';
              let toastBody = '';
              this.translate.get('CALENDAR.MESSAGES.SUCCESS.IMPORT').subscribe((a: string) => {
                toastTitle = a;
              });
              this.translate
                .get('CALENDAR.MESSAGES.SUCCESS.IMPORT_LONG', {
                  type: "",
                  code: this.workCalendarSelected.name,
                })
                .subscribe((a: string) => {
                  toastBody = a;
                });
              this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });

              this.spinner.hide();
            },
            error => {
              if (error.status === 500 || error.status === 0) {
              } else if (error.status === 409) {
                let title: string;
                this.translate.get('CALENDAR.MESSAGES.ERROR.IMPORT').subscribe((a: string) => {
                  title = a;
                  this.toastrService.error(error.error, title, { timeOut: 3000 });
                });
              } else {
              }
            },
          );
        }
      }
    );
  }
}

