import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AbsenceEvent } from 'src/app/interfaces/events.interface';
import { AbsenceStateCode } from 'src/app/models/absence-state-code-enum.model';
import { AbsenceState } from 'src/app/models/absence-state.model';
import { Absence } from 'src/app/models/absence.model';

@Component({
  selector: 'app-absence-management-list-item',
  templateUrl: './absence-management-list-item.component.html',
  styleUrls: ['./absence-management-list-item.component.css']
})
export class AbsenceManagementListItemComponent implements OnInit {
  @Input() absence: Absence;
  @Input() absenceStates: AbsenceState[] = [];

  @Output() actionAbsence: EventEmitter<AbsenceEvent> = new EventEmitter();

  showReason: boolean = false;
  formGroupControl: FormGroup;

  constructor() { }

  ngOnInit() {
    this.formGroupControl = new FormGroup({
      state: new FormControl(''),
      reason: new FormControl(''),
    });

    this.initValuesForm();
  }

  /**
   * Selecciona los valores iniciales del formulario
   */
  initValuesForm() {
    this.formGroupControl.setValue({
      state: this.absence.absenceStateId,
      reason: this.absence.reason,
    });
  }

  /**
   * Pasa la solicitud de vacaciones recibida como param al estado marcado en el selector
   *
   * @param absence - La solicitud de vacaciones que será cambiada de estado
   */
  setState(): void {
    let currentState = this.getState();
    let stateName = AbsenceStateCode[currentState.stateCode].toString();

    this.absence.absenceStateId = currentState.absenceStateId;
    this.absence.absenceState = currentState;
    this.absence.reason = this.getReason();

    this.actionAbsence.emit(
      {
        absence   : this.absence,
        eventType : stateName,
      }
    );
  }

  /**
   * Cancela el cambio de estado de la solicitud
   */
  resetState(): void {
    this.initValuesForm();

    this.showReason = false;
    // Quita las marcas "dirty" de los inputs
    this.formGroupControl.markAsPristine();
  }

  /**
   * Comprueba que el estado de la solicitud sea igual al que se le pasa como parámetro.
   *
   * @param state estado a comprobar
   * @returns un boolean con el resultado de la comparación
   */
  checkAbsenceState(state: string): boolean {
    let currentState = this.getState();

    this.showReason = currentState.stateCode == AbsenceStateCode[state];

    return this.showReason;
  }

  /**
   * Comprueba si se deben mostrar los botones o no.
   *
   * @returns un boolean con el resultado de la comprobación
   */
  showButtons(): boolean {
    let result = false;

    //https://stackoverflow.com/questions/40914413/how-can-i-get-all-dirty-modified-fields-using-angular2-forms
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      if (currentControl.dirty) {
        result = true;
        return;
      }
    });

    return result;
  }

  /**
   * Pasa la solicitud de vacaciones recibida como param al estado marcado en el selector
   *
   * @returns si el nuevo estado es 'REJECTED' recupera el motivo del rechazo, si no es así
   * devuelve una cadena vacía
   */
  getReason(): string {
    let newReason = "";

    if (this.checkAbsenceState('REJECTED')) {
      newReason = this.formGroupControl.get('reason').value;
    }

    return newReason;
  }

  /**
   * Recupera el estado selecionado.
   *
   * @returns un objeto con el nuevo estado para la solicitud
   */
  getState(): AbsenceState {
    let newState: AbsenceState = null;

    for(let state of this.absenceStates) {
      if (state.absenceStateId == this.formGroupControl.get("state").value) {
        newState = state;
        break;
      }
    }

    return newState;
  }
}
