export class EmployeeDocument {
  employeeDocumentId  : string;
  fileId              : string;
  description         : string;
  file                : File;

  constructor(obj?: Partial<EmployeeDocument>) {
    this.employeeDocumentId = (obj && obj.employeeDocumentId) || undefined;
    this.fileId             = (obj && obj.fileId) || undefined;
    this.description        = (obj && obj.description) || undefined;
    this.file               = (obj && obj.file) || undefined;
  }
}
