import { CloseYearEmployeeHistory } from "./close-year-employee-history.model";
import { WorkCalendarHistory } from "./work-calendar-history.model";
import { WorkCalendar } from "./work-calendar.model";
import { OperationsCYHistory } from './close-year-history-enum.model';
import { Employee } from "./employee.model";

export class CloseYearHistory {
  closeYearHistoryId        : string;
  year                      : string;
  log                       : string;
  isLocked                  : boolean;
  modifiedRecords           : number;
  operation                 : OperationsCYHistory;

  createdByUser             : Employee;
  createdDate               : string;

  //Relations
  closeYearEmployeeHistory  : CloseYearEmployeeHistory[];
  workCalendarHistory       : WorkCalendarHistory[];
  newWorkCalendars          : WorkCalendar[];

  constructor(obj?: Partial<CloseYearHistory>) {
    this.closeYearHistoryId       = (obj && obj.closeYearHistoryId) || undefined;
    this.year                     = (obj && obj.year) || undefined;
    this.log                      = (obj && obj.log) || undefined;
    this.isLocked                 = (obj && obj.isLocked) || undefined;
    this.modifiedRecords          = (obj && obj.modifiedRecords) || undefined;
    this.operation                = (obj && obj.operation) || undefined;

    this.createdByUser            = (obj && obj.createdByUser) || undefined;
    this.createdDate              = (obj && obj.createdDate) || undefined;

    //Relations
    this.closeYearEmployeeHistory = (obj && obj.closeYearEmployeeHistory) || [];
    this.workCalendarHistory      = (obj && obj.workCalendarHistory) || [];
    this.newWorkCalendars         = (obj && obj.newWorkCalendars) || [];
  }
}
