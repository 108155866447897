// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DataChangesService } from '../../../services/data-changes.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, pairwise, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, Hotkeys, Permission, Role, User, UserEdit } from 'inzo-portalempleado';
import { Employee } from '../../../../../models/employee.model';
import { IFormEdit, IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { EmployeeManagementInfo } from 'src/app/models/employee-management-info.model';
import { Area } from 'src/app/models/area.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Category } from 'src/app/models/category.model';
import { Centre } from 'src/app/models/centre.model';
import { ContractType } from 'src/app/models/contract-type.model';
import { EmployeeType } from 'src/app/models/employee-type.model';
import { Headquarters } from 'src/app/models/headquarters.model';
import { LaborAgreement } from 'src/app/models/labor-agreement.model';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { customStartsWith } from 'src/app/helpers/filter-data.helper';
import { SBA } from 'src/app/models/sba.model';
import { ClonerService } from 'src/app/helpers/clone-object.helper';
import { EmployeeHistoryDialogComponent } from '../../employee-history/employee-history-dialog/employee-history-dialog.component';
import { EmployeeContract } from 'src/app/models/employee-contract.model';
import { checkFormDate, stringToDate } from 'src/app/helpers/date.helper';
import { DataHistoryService } from '../../../services/data-history.service';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import moment = require('moment');
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';

import { DateMaskDirective } from 'src/app/directives/date-mask.directive';
import { DateTimeMaskDirective } from 'src/app/directives/date-time-mask.directive';
import { EmployeeHistory } from '../../../../../models/employee-history.model';
import { EmployeeSituationCode } from '../../../../../models/employee-situation-enum.model';

// ################################################################################################################
// ## CLASS MgntFormContractualComponent
// ################################################################################################################
@Component({
  selector: 'app-mgnt-form-contractual',
  templateUrl: './mgnt-form-contractual.component.html',
  styleUrls: [
    './mgnt-form-contractual.component.css',
  ],
})
export class MgntFormContractualComponent implements OnInit {
  /* ############################################################################################################## */
  /* ## ATRRIBUTES
  /* ############################################################################################################## */
  employeeView: boolean = false;
  employeeManagementInfo: EmployeeManagementInfo;
  employee: Employee;
  employeeContract: EmployeeContract;
  sba: SBA;

  selectedDesc: string = undefined;
  permissionList: Permission[];
  userPermission: Permission;

  backForm = true;
  userEdit: UserEdit;

  subscriptions: Subscription[] = [];

  // --------------------------------------------------------------------------------------------------------------
  // Permisos
  // --------------------------------------------------------------------------------------------------------------
  allUsers: User[];
  roles: Role[];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }
  // --------------------------------------------------------------------------------------------------------------

  loadEmployeeData;
  disabilityChecked;
  enabled = true;
  detail = false;
  deleted = false;
  permissionsLoaded = false;
  visible = true;
  userFilter = '';
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  workcalendarCtrl = new FormControl();
  filteredAreas: Observable<Area[]>;
  filteredCategories: Observable<Category[]>
  filteredCentres: Observable<Centre[]>;
  filteredContractTypes: Observable<ContractType[]>;
  filteredEmployeeTypes: Observable<EmployeeType[]>;
  filteredHeadquarters: Observable<Headquarters[]>;
  filteredLaborAgreements: Observable<LaborAgreement[]>;
  filteredManagers: Observable<Employee[]>;
  filteredUsers: Observable<User[]>;
  filteredValidators: Observable<Employee[]>;
  filteredWorkcalendars: Observable<WorkCalendar[]>;
  createMode: boolean = false;
  editable: boolean = false;

  // --------------------------------------------------------------------------------------------------------------

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  RAEdit: IFormEdit = {
    ContractualDataFormGroup: [],
  }

  RPEdit: IFormEdit = {
    ContractualDataFormGroup: [],
  }

  notEditFields: IFormEdit = {
    ContractualDataFormGroup: [
      "situation",
      "registrationDate",
      "leaveDate",
      "contractEndDate",
      "category",
      "categoryCode",
      "quotationGroupEquivalence",
    ],
  }

  // ------------------------------------------------------------------------------------------------------------------
  // ContractualDataFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  ContractualDataFormGroup = new FormGroup({
    situation: new FormControl({ value: '', disabled: true }),
    registrationDate: new FormControl(new Date(), Validators.required),
    leaveDate: new FormControl(new Date()),
    seniorityDate: new FormControl(new Date()),
    contractEndDate: new FormControl(new Date()),

    category: new FormControl('', Validators.required),
    categoryCode: new FormControl(''),
    quotationGroupEquivalence: new FormControl(''),
  });

  // ------------------------------------------------------------------------------------------------------------------
  // EmployeeContractTypeDataFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  EmployeeContractTypeDataFormGroup = new FormGroup({
    contractType: new FormControl(''),
    employeeType: new FormControl(''),
    laborAgreement: new FormControl(''),
  });

  // ------------------------------------------------------------------------------------------------------------------
  // SbaDataFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  SbaDataFormGroup = new FormGroup({
    startDate: new FormControl(''),
    coefficient: new FormControl(''),
    sba: new FormControl(''),
    costHour: new FormControl(''),
    observations: new FormControl(''),
  });
  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    protected permissionsService: PermissionsService,
    private dataChangesService: DataChangesService,
    private dataHistoryService: DataHistoryService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
    this.disableForm();
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // Get Form Data
    this.subscriptions.push(
      this.dataChangesService.formData.subscribe(data => {
        if (data) {
          this.assignContractualDataFormGroup();
        }
      })
    );

    this.subscriptions.push(
      this.dataChangesService.allUsers.subscribe(data => {
        this.allUsers = data;
      })
    );

    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;
      })
    );

    this.subscriptions.push(
      this.dataChangesService.employee.subscribe(data => {
        this.employee = data;

        this.loadDataOnForm();
      })
    );

    this.subscriptions.push(
      this.dataChangesService.employeeManagementInfo.subscribe(data => {
        this.employeeManagementInfo = data;

        if (data && data.employee) {
          this.employee = data.employee;
        }

        this.loadDataOnForm();
      })
    );

    // CreateMode
    this.subscriptions.push(
      this.dataChangesService.createMode.subscribe(data => {
        this.createMode = data;
      })
    );

    // EditMode
    this.subscriptions.push(
      this.dataChangesService.editMode.subscribe(data => {
        this.editable = data;

        if (data === true) {
          this.edit();
        } else {
          this.cancel();
        }
      })
    );

    this.subscriptions.push(
      this.dataChangesService.resetFormDirty.subscribe(data => {
        if (data) {
          this.resetFormDirty();
        }
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    this.cleanForm();
  }
  //#endregion

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  loadDataOnForm() {
    if (this.employee) {
      var categorySelected = undefined;
      var contractTypeSelected = undefined;
      var laborAgreementSelected = undefined;
      var employeeTypeSelected = undefined;
      var employeeCurrentSituation: EmployeeHistory = undefined;
      var sbaSelected = undefined;

      // ----------------------------------------------------------------------------------------------------------------
      // Inicializar datos
      // ----------------------------------------------------------------------------------------------------------------
      categorySelected = (this.employee.category)
        ? this.employee.category
        : undefined;

      if (this.employee.employeeHistory) {
        this.employee.employeeHistory = _.orderBy(this.employee.employeeHistory, a => moment(a.startDate).unix(), 'desc');
        employeeCurrentSituation = _(this.employee.employeeHistory).filter(x => moment(x.startDate) <= moment()).maxBy(x => moment(x.startDate).unix());

        if (
          (
            employeeCurrentSituation.newEmployeeSituation == undefined ||
            employeeCurrentSituation.newEmployeeSituation == null
          ) &&
          this.employeeManagementInfo &&
          this.employeeManagementInfo.allEmployeeSituations
        ) {
          employeeCurrentSituation.newEmployeeSituation = _(this.employeeManagementInfo.allEmployeeSituations).find(x => x.employeeSituationId == employeeCurrentSituation.newEmployeeSituationId);
        }

        if (employeeCurrentSituation.newEmployeeSituation && employeeCurrentSituation.newEmployeeSituation.stateCode == EmployeeSituationCode.LEAVING) {
          this.employee.leaveDate = employeeCurrentSituation.startDate;
        } else {
          this.employee.leaveDate = undefined;
        }
      }

      if (this.employee.employeeContract) {
        contractTypeSelected = (this.employee.employeeContract.contractType)
          ? this.employee.employeeContract.contractType
          : undefined;

        laborAgreementSelected = (this.employee.employeeContract.laborAgreement)
          ? this.employee.employeeContract.laborAgreement
          : undefined;

        employeeTypeSelected = (this.employee.employeeContract.employeeType)
          ? this.employee.employeeContract.employeeType
          : undefined;
      }

      sbaSelected = (this.employee.sba)
        ? this.employee.sba
        : undefined;

      if (this.employeeManagementInfo) {
        if (this.employee.employeeContract) {
          if (!contractTypeSelected && this.employeeManagementInfo.allContractTypes && this.employee.employeeContract.contractTypeId) {
            contractTypeSelected = this.employeeManagementInfo.allContractTypes.find(contractType => contractType.contractTypeId === this.employee.employeeContract.contractTypeId);
          }

          if (!laborAgreementSelected && this.employeeManagementInfo.allLaborAgreements && this.employee.employeeContract.laborAgreementId) {
            laborAgreementSelected = this.employeeManagementInfo.allLaborAgreements.find(laborAgreement => laborAgreement.laborAgreementId === this.employee.employeeContract.laborAgreementId);
          }

          if (!employeeTypeSelected && this.employeeManagementInfo.allEmployeeTypes && this.employee.employeeContract.employeeTypeId) {
            employeeTypeSelected = this.employeeManagementInfo.allEmployeeTypes.find(employeeType => employeeType.employeeTypeId === this.employee.employeeContract.employeeTypeId);
          }
        }

        // ------------------------------------------------------------------------------------------------------------------
        // ContractualDataFormGroup
        // ------------------------------------------------------------------------------------------------------------------
        this.ContractualDataFormGroup.setValue({
          situation: this.getEmployeeSituation() || null,
          registrationDate: (this.employee.registrationDate)
            ? this.employee.registrationDate || null
            : null,
          leaveDate: (this.employee.leaveDate)
            ? this.employee.leaveDate || null
            : null,
          seniorityDate: (this.employee.seniorityDate)
            ? this.employee.seniorityDate || null
            : null,
          contractEndDate: (this.employee.employeeContract)
            ? this.employee.employeeContract.finishDate || null
            : null,

          category: (categorySelected)
            ? categorySelected.careerPlan || null
            : null,
          categoryCode: (categorySelected)
            ? categorySelected.categoryCode || null
            : null,
          quotationGroupEquivalence: (categorySelected)
            ? categorySelected.quotationGroupEquivalence || null
            : null,
        });

        // ------------------------------------------------------------------------------------------------------------------
        // EmployeeContractTypeDataFormGroup
        // ------------------------------------------------------------------------------------------------------------------
        if (contractTypeSelected) {
          this.EmployeeContractTypeDataFormGroup.setValue({
            contractType: (contractTypeSelected)
              ? contractTypeSelected.description || null
              : null,
            employeeType: (employeeTypeSelected)
              ? employeeTypeSelected.description || null
              : null,
            laborAgreement: (laborAgreementSelected)
              ? laborAgreementSelected.description || null
              : null,
          });
        }

        // ------------------------------------------------------------------------------------------------------------------
        // SbaDataFormGroup
        // ------------------------------------------------------------------------------------------------------------------
        if (sbaSelected) {
          this.SbaDataFormGroup.setValue({
            startDate: sbaSelected.startDate,
            coefficient: sbaSelected.coefficient.toFixed(2),
            sba: sbaSelected.sba.toFixed(2),
            costHour: (sbaSelected.costHourManual && sbaSelected.costHourManual > 0)
              ? sbaSelected.costHourManual.toFixed(2)
              : sbaSelected.costHour.toFixed(2),
            observations: sbaSelected.observations,
          });
        } else {
          this.cleanSbaDataForm();
        }
      }
    }

    // ------------------------------------------------------------------------------------------------------------------
    // Filtros
    // ------------------------------------------------------------------------------------------------------------------
    this.addFilters();

    // ------------------------------------------------------------------------------------------------------------------
    // Informar cambios
    // ------------------------------------------------------------------------------------------------------------------
    this.onChangesForms();
  }

  onChangesForms(): void {
    // ------------------------------------------------------------------------------------------------------------------
    // ContractualDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    this.ContractualDataFormGroup.valueChanges.subscribe(val => {
      this.assignContractualDataFormGroup();
    });

    // let contractualDataFormGroupIsChanged: boolean = false;
    // Object.keys(this.ContractualDataFormGroup.controls).forEach((name) => {
    //   const currentControl = this.ContractualDataFormGroup.controls[name];

    //   currentControl.valueChanges
    //     .pipe(pairwise())
    //     .subscribe(([prev, next]: [any, any]) => {
    //       console.log("this", this);
    //       console.log("name", name);
    //       console.log("PREV1", prev);
    //       console.log("NEXT1", next);
    //     });
    //   // .subscribe(val => {
    //   //   console.log('val :>> ', val);
    //   // });
    // });
    // if (contractualDataFormGroupIsChanged == true) {
    //   this.assignContractualDataFormGroup();
    // }
  }

  cleanForm() {
    this.cleanContractualDataForm();
    this.cleanEmployeeContractTypeDataForm();
    this.cleanSbaDataForm();
  }

  cleanContractualDataForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // ContractualDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.ContractualDataFormGroup.controls).forEach((name) => {
      const currentControl = this.ContractualDataFormGroup.controls[name];

      currentControl.setValue('');
    });
  }

  cleanEmployeeContractTypeDataForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // EmployeeContractTypeDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.EmployeeContractTypeDataFormGroup.controls).forEach((name) => {
      const currentControl = this.EmployeeContractTypeDataFormGroup.controls[name];

      currentControl.setValue('');
    });
  }

  cleanSbaDataForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // SbaDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.SbaDataFormGroup.controls).forEach((name) => {
      const currentControl = this.SbaDataFormGroup.controls[name];

      currentControl.setValue('');
    });
  }

  disableForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // ContractualDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.ContractualDataFormGroup.controls).forEach((name) => {
      const currentControl = this.ContractualDataFormGroup.controls[name];

      currentControl.disable();
    });

    // ------------------------------------------------------------------------------------------------------------------
    // EmployeeContractTypeDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.EmployeeContractTypeDataFormGroup.controls).forEach((name) => {
      const currentControl = this.EmployeeContractTypeDataFormGroup.controls[name];

      currentControl.disable();
    });

    // ------------------------------------------------------------------------------------------------------------------
    // SbaDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.SbaDataFormGroup.controls).forEach((name) => {
      const currentControl = this.SbaDataFormGroup.controls[name];

      currentControl.disable();
    });
  }

  enableForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // ContractualDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.ContractualDataFormGroup.controls).forEach((name) => {
      const currentControl = this.ContractualDataFormGroup.controls[name];
      let continueEnable: boolean = false;

      // Si el nombre del campo no está entre los deshabilitados
      if (!this.notEditFields.ContractualDataFormGroup.includes(name)) {
        if (this.userRolesPermissions.isRRHH) {
          continueEnable = true;
        } else if (this.userRolesPermissions.isRA && this.RAEdit.ContractualDataFormGroup.includes(name)) {
          continueEnable = true;
        } else if (this.userRolesPermissions.isRP && this.RPEdit.ContractualDataFormGroup.includes(name)) {
          continueEnable = true;
        }
      }

      if (continueEnable) {
        currentControl.enable();
      }
    });

    // ------------------------------------------------------------------------------------------------------------------
    // EmployeeContractTypeDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------

    // ------------------------------------------------------------------------------------------------------------------
    // SbaDataFormGroup
    // ------------------------------------------------------------------------------------------------------------------
  }

  edit() {
    this.enabled = true;
    this.enableForm();
  }

  cancel() {
    this.enabled = false;
    this.disableForm();
    this.loadDataOnForm();
  }

  resetForm() {
    this.loadDataOnForm();

    // Quita las marcas "dirty" de los inputs
    this.resetFormDirty();
  }

  resetFormDirty() {
    // Quita las marcas "dirty" de los inputs
  }
  //#endregion

  //#region ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  assignContractualDataFormGroup() {
    if (this.employee == undefined || this.employee == null) {
      this.employee = new Employee();
    }

    // ContractualDataFormGroup
    this.employee.seniorityDate = this.ContractualDataFormGroup.get('seniorityDate').value;

    // Se notifica a los observadores de los cambios
    // this.dataChangesService.setEmployeeChange(this.employee);
  }
  //#endregion

  //#region ACTIONS METHODS
  /* ################################################################################################################## */
  /* ## ACTIONS METHODS
  /* ################################################################################################################## */
  /**
   * Abre un cuadro de diálogo cargando el histórico que se pasa como argumento.
   *
   * @param target nombre del histórico que se quiere mostrar
   */
  openEmployeeContractualHistorical(target: string) {
    this.dataHistoryService.setEmployeeViewChange(false);
    this.dataHistoryService.setSelectedViewChange(target);

    const employeeHistoryDialog = this.dialog.open(EmployeeHistoryDialogComponent, {
      maxWidth: '80vw',
      // maxHeight: '75vh',
      // data: {}
    });

    employeeHistoryDialog.afterClosed().subscribe(response => {
      this.loadDataOnForm();
    });
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  addFilters() {
    if (this.employeeManagementInfo) {
      // ------------------------------------------------------------------------------------------------------------------
      // ContractualDataFormGroup
      // ------------------------------------------------------------------------------------------------------------------
      if (this.employeeManagementInfo.allCategories) {
        this.filteredCategories = this.ContractualDataFormGroup.controls['category'].valueChanges.pipe(startWith(""), map(val =>
          this.employeeManagementInfo.allCategories.filter(empCategory =>
            customStartsWith(empCategory.careerPlan, val)
          ))
        );
      }

      // ------------------------------------------------------------------------------------------------------------------
      // EmployeeContractTypeDataFormGroup
      // ------------------------------------------------------------------------------------------------------------------
      if (this.employeeManagementInfo.allContractTypes) {
        this.filteredContractTypes = this.EmployeeContractTypeDataFormGroup.controls['contractType'].valueChanges.pipe(startWith(""), map(val =>
          this.employeeManagementInfo.allContractTypes.filter(contractType =>
            customStartsWith(contractType.description, val)
          ))
        );
      }

      if (this.employeeManagementInfo.allEmployeeTypes) {
        this.filteredEmployeeTypes = this.EmployeeContractTypeDataFormGroup.controls['employeeType'].valueChanges.pipe(startWith(""), map(val =>
          this.employeeManagementInfo.allEmployeeTypes.filter(emplType =>
            customStartsWith(emplType.description, val)
          ))
        );
      }

      if (this.employeeManagementInfo.allLaborAgreements) {
        this.filteredLaborAgreements = this.EmployeeContractTypeDataFormGroup.controls['laborAgreement'].valueChanges.pipe(startWith(""), map(val =>
          this.employeeManagementInfo.allLaborAgreements.filter(laborAgreement =>
            customStartsWith(laborAgreement.description, val)
          ))
        );
      }
    }
  }

  getEmployeeSituation() {
    let employeeSituation = null;

    if (
      this.employee &&
      this.employee.employeeSituationId &&
      this.employeeManagementInfo.allEmployeeSituations
    ) {
      employeeSituation = this.employeeManagementInfo.allEmployeeSituations.find(element => element.employeeSituationId == this.employee.employeeSituationId);
    }

    let result = (employeeSituation != null)
      ? employeeSituation.description
      : "";

    return result;
  }

  setDate(event: any, fieldName: string): void {
    const date = moment(event.target.value, 'DD/MM/YYYY');
    this.ContractualDataFormGroup.get(fieldName).setValue(date);
  }
  //#endregion
}
