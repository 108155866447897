import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { Subscription } from 'rxjs';
import { WorkCalendarService } from 'src/app/services/work-calendar.service';
import { BaseFormComponent, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import * as jsonpatch from 'fast-json-patch';
import { MatDialog } from '@angular/material';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { FiltersApi } from 'src/app/models/filters-api.model';

@Component({
  selector: 'app-work-calendar-form',
  templateUrl: 'work-calendar-form.component.html',
  styleUrls: ['./work-calendar-form.component.css','./../../maintenance.components.css'],
})
export class WorkCalendarFormComponent extends BaseFormComponent<WorkCalendar> implements OnInit, OnDestroy {
  selectedDesc: string = undefined;
  workCalendar: WorkCalendar;

  roles: Role[];

  enabled = true;
  detail = false;
  permissionsLoaded = false;

  formGroupControl = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    validYear: new FormControl(
      '',
      [
        Validators.required,
        Validators.pattern(/^\d{4}$/),
      ]
    ),
    isActive: new FormControl(true),
    disableWeekends: new FormControl(true),
  });

  canAdd = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;
  subscriptions: Subscription[] = [];

  filtersApi: FiltersApi = new FiltersApi();

  constructor(
    private toastrService: ToastrService,
    private workCalendarService: WorkCalendarService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];

    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
    }
    this.PK = 'workCalendarId';

    this.baseRoute = '/maintenances/work_calendar';

    this.filtersApi.add(
      {
        field: "related",
        value: "true"
      }
    );
    this.filtersApi.addInclude("WCEvents");

    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('DEPARTMENTS_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  private init() {
    this.formGroupControl.get('name').disable();
    this.formGroupControl.get('description').disable();
    this.formGroupControl.get('validYear').disable();
    this.formGroupControl.get('isActive').disable();
    this.formGroupControl.get('disableWeekends').disable();
    this.workCalendar = new WorkCalendar();

    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        const transId = params.get('id');
        this.workCalendarService.updateWorkCalendarRoutes(transId);
        if (transId) {
          if (transId === 'new') {
            this.detail = false;
            this.enabled = true;
            this.workCalendar = new WorkCalendar();
            this.selectedDesc = undefined;

            this.cleanForm();

            this.formGroupControl.get('name').enable();
            this.formGroupControl.get('description').enable();
            this.formGroupControl.get('validYear').enable();
            this.formGroupControl.get('isActive').enable();
            this.formGroupControl.get('disableWeekends').enable();
          } else {
            this.subscriptions.push(
              this.workCalendarService.getWorkCalendar(transId).subscribe(response => {
                this.detail = true;
                this.enabled = false;
                this.workCalendar = response;
                this.formGroupControl.setValue(
                  { name: this.workCalendar.name,
                    description: this.workCalendar.description,
                    validYear: this.workCalendar.validYear,
                    isActive: this.workCalendar.isActive,
                    disableWeekends: this.workCalendar.disableWeekends
                  }
                );
                this.formGroupControl.get('name').disable();
                this.formGroupControl.get('description').disable();
                this.formGroupControl.get('validYear').disable();
                this.formGroupControl.get('isActive').disable();
                this.formGroupControl.get('disableWeekends').disable();
                this.selectedDesc = response.name;
              })
            )
          }
        } else {
          this.selectedDesc = undefined;
          this.detail = false;
          this.enabled = false;
          this.workCalendar = new WorkCalendar();
        }
      })
    );
  }

  edit() {
    this.enabled = true;
    this.formGroupControl.get('name').enable();
    this.formGroupControl.get('description').enable();
    this.formGroupControl.get('validYear').enable();
    this.formGroupControl.get('isActive').enable();
    this.formGroupControl.get('disableWeekends').enable();
  }

  cleanForm() {
    this.formGroupControl.setValue(
      {
        name: '',
        description: '',
        validYear: moment(new Date()).year(),
        isActive: true,
        disableWeekends: true
      }
    );
  }

  // ======================== CRUD ========================

  create() {
    this.spinner.show();

    this.workCalendar = new WorkCalendar({
      name: this.formGroupControl.get('name').value,
      description: this.formGroupControl.get('description').value,
      validYear: this.formGroupControl.get('validYear').value,
      isActive: this.formGroupControl.get('isActive').value,
      disableWeekends: this.formGroupControl.get('disableWeekends').value,
    });
    this.workCalendarService.createWorkCalendar(this.workCalendar).subscribe(
      response => {
        this.workCalendarService.getAllWorkCalendar(this.filtersApi.getStrinFilterApi()).subscribe(workCalendars => {
          this.workCalendarService.updateWorkCalendars(workCalendars.items);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.CREATE_LONG', {
            type: this.componentTypeTranslated,
            code: this.workCalendar.name,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });

        this.spinner.hide();

        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.cancel();
      },
      error => {
        if (error.status === 500 || error.status === 0) {
        } else if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
        }

        this.spinner.hide();
      },
    );
  }

  update() {
    const observer = jsonpatch.observe(this.workCalendar);
    this.workCalendar.name = this.formGroupControl.get('name').value;
    this.workCalendar.description = this.formGroupControl.get('description').value;
    this.workCalendar.validYear = this.formGroupControl.get('validYear').value;
    this.workCalendar.isActive = this.formGroupControl.get('isActive').value;
    this.workCalendar.disableWeekends = this.formGroupControl.get('disableWeekends').value;
    const patch = jsonpatch.generate(observer);

    this.workCalendarService.modifyWorkCalendar(this.workCalendar.workCalendarId, patch).subscribe(
      response => {
        this.workCalendarService.getAllWorkCalendar(this.filtersApi.getStrinFilterApi()).subscribe(workCalendars => {
          this.workCalendarService.updateWorkCalendars(workCalendars.items);
        });
        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );
  }

  delete() {
    this.workCalendarService.deleteWorkCalendar(this.workCalendar.workCalendarId).subscribe(response => {
      this.workCalendarService.getAllWorkCalendar(this.filtersApi.getStrinFilterApi()).subscribe(workCalendars => {
        this.workCalendarService.updateWorkCalendars(workCalendars.items);
      });
      let toastTitle = '';
      let toastBody = '';
      this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {
        toastTitle = a;
      });
      this.translate
        .get('MESSAGES.SUCCESS.DELETE_LONG', {
          type: this.componentTypeTranslated,
          name: this.workCalendar.name,
        })
        .subscribe((a: string) => {
          toastBody = a;
        });
      this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
      this.cancel();
    });
  }

  cancel() {
    this.cleanForm();
    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }

  back() {
    this.cancel();
  }
}
