import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TrainingCenter } from '../../models/training-center.model';
import moment = require('moment');
import { TrainingCenterService } from 'src/app/services/training-center.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponseNotificationService } from 'inzo-portalempleado';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-employee-select-associate-confirm-dialog',
  templateUrl: './employee-select-associate-confirm-dialog.component.html',
  styleUrls: ['./employee-select-associate-confirm-dialog.component.css']
})
export class EmployeeSelectAssociateConfirmDialogComponent implements OnInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  maxDate = moment();
  asassociate: boolean = false;
  text: boolean = false;
  trainingCenters: TrainingCenter[] = [];

  subscription: Subscription;

  formGroupControl = new FormGroup({
    trainingCenter: new FormControl('', Validators.required),
    finishDate: new FormControl('', Validators.required),
  });

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private trainingCenterService: TrainingCenterService,
    private RNService: ResponseNotificationService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    if (this.data) {
      if (this.data.asassociate) {
        this.asassociate = this.data.asassociate;
      }

      if (this.data.text) {
        this.text = this.data.text;
      }
    }

    this.loadTrainingCenters();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  loadTrainingCenters() {
    this.subscription = this.trainingCenterService.getAllTrainingCenters().subscribe(trainingCenters => {
      this.trainingCenterService.updateTrainingCenterRoutes(trainingCenters.items);
      this.trainingCenters = trainingCenters.items;
    }, error => {
      this.RNService.showError(error);
    });
  }

  assignTrainingCenter(e) {
    this.formGroupControl.get('trainingCenter').setValue(e.option.value);
  }

  displayTrainingCenter(trainingCenter: TrainingCenter): string | undefined {
    return (trainingCenter) ? trainingCenter.name : undefined;
  }

  returnDate() {
    if (this.asassociate) {
      this.dialogRef.close(
        {
          trainingCenterId  : this.formGroupControl.get('trainingCenter').value.trainingCenterId,
          finishDate        : this.formGroupControl.get('finishDate').value,
        }
      );
    } else {
      this.dialogRef.close(true);
    }
  }
}
