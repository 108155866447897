import { Component, OnInit } from '@angular/core';

import { PRCContractService } from 'src/app/services/prc-contract.service';

@Component({
  selector: 'app-prc-contract',
  templateUrl: './prc-contract.component.html',
  styleUrls: [
    './prc-contract.component.css',
    './../../../components/maintenance.components.css',
  ],
})
export class PrcContractComponent implements OnInit {
  showForm = false;

  constructor(private prcContractService: PRCContractService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.prcContractService.prcContractRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
}
