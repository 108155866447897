import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DegreeFormItemComponent } from '../degree-form-item/degree-form-item.component';

@Component({
  selector: 'app-degree-certification-dialog',
  templateUrl: './degree-certification-dialog.component.html',
  styleUrls: ['./degree-certification-dialog.component.css']
})
export class DegreeCertificationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DegreeFormItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

}
