import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { Area } from '../models/area.model';

@Injectable({ providedIn: 'root' })
export class AreaService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private areaSubject: ReplaySubject<Area[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public areaObservable: Observable<Area[]> = this.areaSubject.asObservable();

    private areaRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public areaRouteObservable: Observable<string> = this.areaRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/area';
    }

    constructor(private http: HttpClient) {
    }

    getAllArea() {
        return this.http.get<PaginatedResult<Area>>(`${this.serviceUrl}`);
    }

    getArea(areaId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<Area>(`${this.serviceUrl}/${areaId}${relatedFilter}`);
    }

    createArea(area: Area) { //SeleccionDelegate only for test: Phase 1
        const endpointUrl = `${this.serviceUrl}`;
        return this.http.post(endpointUrl, area);
    }

    deleteArea(areaId: string) {//SeleccionDelegate only for test: Phase 1
        const endpointUrl = `${this.serviceUrl}/${areaId}`;
        return this.http.delete(endpointUrl);
    }

    modifyArea(areaId:string, area: any) {//SeleccionDelegate only for test: Phase 1
        const endpointUrl = `${this.serviceUrl}/${areaId}`;
        return this.http.patch(endpointUrl, area);
    }

    updateAreas(areas: any) {
        this.areaSubject.next(areas);
    }

    updateAreaRoutes(id: string) {
        this.areaRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}
