import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, Hotkeys, ResponseNotificationService, SecurityStateManagementService } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { DCRStateCode } from 'src/app/models/dcr-state.model';
import { DataChangeRequestService } from 'src/app/services/data-change-request.service';
import { DCRStateService } from 'src/app/services/dcr-state.service';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';

import { DcrGroupsComponent } from '../dcr-groups/dcr-groups.component';


import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';

@Component({
  selector: 'app-dcr-groups-employee',
  templateUrl: './dcr-groups-employee.component.html',
  styleUrls: [
    './dcr-groups-employee.component.css',
    './../../action.components.css',
    '../../action-list.component.css',
  ],
})
export class DcrGroupsEmployeeComponent extends DcrGroupsComponent {

  constructor(
    protected dataChangeRequestService: DataChangeRequestService,
    protected dcrStateService: DCRStateService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    protected RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    protected spinner: NgxSpinnerService,
    private employeeManagementService: EmployeeManagementService,
    protected permissionsService: PermissionsService,
  ) {
    super(
      dataChangeRequestService,
      dcrStateService,
      route,
      router,
      SSMService,
      RNService,
      accountService,
      hotkeys,
      spinner,
      permissionsService,
    );
    this.baseRoute = '/actions/dataChange/';
    this.employeeView = true;

    this.filterApi.add(
      {
        field: "related",
        value: "true"
      }
    );

    this.filterApi.addInclude("Employee");

    this.loadData = () => {
      this.spinner.show();

      this.employeeManagementService.getEmployeeByUserId(this.accountService.currentUser.id).subscribe(employeeResponse => {
        this.employeeManagementService.updateEmployeeManagementRoutes(employeeResponse.employeeId);
        if (employeeResponse.employeeId) {
              this.filterApi.add(
                {
                  field: "employeeId",
                  value: employeeResponse.employeeId
                },
                true
              );

              this.dataChangeRequestService.getAllDataChangeRequestGroupBy(this.filterApi.getStrinFilterApi()).subscribe(dataChangeRequest => {
                const filteredItems = this.assignRequestStates(dataChangeRequest.items);

                this.dataChangeRequestService.updateDataChangeRequests(filteredItems);
                this.loadDataChangeRequests(filteredItems);

                this.spinner.hide();
              }, error => {
                this.spinner.hide();
                this.RNService.showError(error);
              });
        } else {
          this.loadDataChangeRequests([]);
        }
      });
    };
  }

  /**
   * Selecciona los estados que se deben manejar y los que se deben mostrar.
   */
  setRequestStates() {
    this.tabStates = [
      "REGISTERED",
      "PENDING_APROVAL",
      "APPROVED",
      "REJECTED",
      "ALL",
    ];

    this.excludeDcrStates = [
      DCRStateCode.CANCELED,
    ];

    this.setFilterRequestsStates();
  }
}

