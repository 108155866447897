import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbsenceTypeService } from '../../services/absence-type.service';

@Component({
  selector: 'app-absence-type-maintenance',
  templateUrl: './absence-type-maintenance.component.html',
  styleUrls: ['./absence-type-maintenance.component.css']
})
export class AbsenceTypeMaintenanceComponent implements AfterViewInit, OnInit {
  showForm = false;

  constructor(
    private absenceTypeService: AbsenceTypeService
  ) {
  }

  ngAfterViewInit() {
    this.absenceTypeService.absenceTypeRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }

  ngOnInit() {
  }
}
