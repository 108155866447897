import { Component, ElementRef, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, BaseListComponent, Hotkeys, Role, SecurityStateManagementService } from 'inzo-portalempleado';
import { DataChangeRequestGroupItems } from 'src/app/interfaces/dcr-group-api.interface';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { DataChangeRequest } from 'src/app/models/data-chage-request.model';
import { DCRState, DCRStateCode } from 'src/app/models/dcr-state.model';

@Component({
  selector: 'app-dcr-list',
  templateUrl: './dcr-list.component.html',
  styleUrls: [
    './dcr-list.component.css',
    '../../action.components.css',
    '../../action-list.component.css',
  ],
})
export class DcrListComponent extends BaseListComponent<DataChangeRequest> implements OnInit, OnDestroy  {
  @Input() dataChangeRequests: DataChangeRequestGroupItems[] = [];
  @Input() employeeView = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  @Input() dcrStates: DCRState[] = [];
  @Input() canApprove = false;
  panelOpenState = false;
  canAdd = false;
  canDelete = false;

  roles: Role[];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public SSMService: SecurityStateManagementService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
  ) {
    super(accountService, SSMService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];

    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.Empleado)) {
        this.canApprove = false;
    }
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      if (this.router.url === "/managements/dataChange") {
        this.canApprove = true;
      } else {
        this.canApprove = false;
      }
    }
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  getCanApprove(data){
    return data.state.stateCode == DCRStateCode.PENDING_APROVAL && this.canApprove;
  }

  getCanSend(data){
    return data.state.stateCode == DCRStateCode.REGISTERED;
  }
}

