import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HolidayRequestService } from 'src/app/services/holiday-request.service';

@Component({
  selector: 'app-maintenance-holiday-request-management',
  templateUrl: './holiday-request-management.component.html',
  styleUrls: ['./../maintenance.components.css', '../maintenance-list.component.css', './holiday-request-management.component.css'],
})
export class HolidayRequestManagementComponent implements AfterViewInit, OnInit {
  constructor() { }

  ngAfterViewInit() {
  }
  ngOnInit() {
  }
}
