import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AbsenceState } from 'src/app/models/absence-state.model';
import { Absence } from 'src/app/models/absence.model';
import { Employee } from 'src/app/models/employee.model';
import { AbsenceCreateRangeDialogComponent } from '../absence-create-range-dialog/absence-create-range-dialog.component';

@Component({
  selector: 'app-absence-calendar-actions',
  templateUrl: './absence-calendar-actions.component.html',
  styleUrls: ['./absence-calendar-actions.component.css']
})
export class AbsenceCalendarActionsComponent implements OnInit {
  @Input() currentEmployee: Employee;
  @Input() absenceStates: AbsenceState[] = [];

  @Output() absenceCreateEvent: EventEmitter<Absence> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  createAbsence(): void {
    const createRangeDialog = this.dialog.open(AbsenceCreateRangeDialogComponent, {
      width: '550px',
      data: {
        currentEmployee: this.currentEmployee,
        absenceStates: this.absenceStates,
      }
    });

    createRangeDialog.afterClosed().subscribe((newAbsence: Absence) => {
      if (newAbsence) {
        this.absenceCreateEvent.emit(newAbsence);
      }
    });
  }
}
