import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { PRCContract } from '../models/prc-contract.model';
import { PRCContractsManagementInfo } from '../models/prc-contracts-management-info.model';

@Injectable({ providedIn: 'root' })
export class PRCContractService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private prcContractSubject: ReplaySubject<PRCContract[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public prcContractObservable: Observable<PRCContract[]> = this.prcContractSubject.asObservable();

  private prcContractRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public prcContractRouteObservable: Observable<string> = this.prcContractRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/prcContract';
  }

  constructor(private http: HttpClient) {
  }

  getAllPRCContracts(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getManagementContractTypes(contractDurationTypeId?: string) {
    if (contractDurationTypeId == undefined || contractDurationTypeId == null) {
      contractDurationTypeId = "";
    }

    const url = `${this.serviceUrl}/Management/${contractDurationTypeId}`;

    return this.http.get<PRCContractsManagementInfo>(url);
  }

  getPRCContract(prcContractId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/${prcContractId}${filter}`;

    return this.http.get<PRCContract>(url);
  }

  createPRCContract(prcContract: PRCContract) {
    const url = `${this.serviceUrl}`;

    return this.http.post(url, prcContract);
  }

  deletePRCContract(prcContractId: string) {
    const url = `${this.serviceUrl}/${prcContractId}`;

    return this.http.delete(url);
  }

  modifyPRCContract(prcContractId: string, prcContract: any) {
    const url = `${this.serviceUrl}/${prcContractId}`;

    return this.http.patch(url, prcContract);
  }

  updatePRCContracts(prcContracts: any) {
    this.prcContractSubject.next(prcContracts);
  }

  updatePRCContractRoutes(id: string) {
    this.prcContractRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
