import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TrainingCenterService } from 'src/app/services/training-center.service';

@Component({
  selector: 'app-training-center',
  templateUrl: './training-center.component.html',
  styleUrls: ['./training-center.component.css']
})
export class TrainingCenterComponent implements OnInit ,AfterViewInit {

  showForm = false;
  constructor(private trainingCenterService: TrainingCenterService) { }

  ngAfterViewInit() {
    this.trainingCenterService.trainingCenterRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }

  ngOnInit() {
  }

}
