import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { EmployeeValidatorHistory } from '../models/employee-validator-history.model';


@Injectable({ providedIn: 'root' })
export class EmployeeValidatorHistoryService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private employeeValidatorHistorySubject: ReplaySubject<EmployeeValidatorHistory[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeValidatorHistoryObservable: Observable<EmployeeValidatorHistory[]> = this.employeeValidatorHistorySubject.asObservable();

    private employeeValidatorHistoryRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeValidatorHistoryRouteObservable: Observable<string> = this.employeeValidatorHistoryRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/employeeValidatorHistory';
    }

    constructor(private http: HttpClient) {
    }



    getAllEmployeeValidatorHistory() {
        return this.http.get<any>(`${this.serviceUrl}`);
    }

    getAllEmployeeValidatorHistorybyEmployeeId(employeeId) {
      return this.http.get<any>(`${this.serviceUrl+"/employee/"+employeeId}`);
    }

    getAllEmployeesValidators() {
      return this.http.get<any>(`${this.serviceUrl+"/allvalidators"}`);
    }

    createEmployeeValidatorHistory(employeeHistory :EmployeeValidatorHistory){
      console.log(employeeHistory);
      return this.http.post<EmployeeValidatorHistory>(`${this.serviceUrl}`,employeeHistory);
    }

    deleteEmployeeValidatorHistory(EmployeeValidatorHistoryId: string) {
        const endpointUrl = `${this.serviceUrl}/${EmployeeValidatorHistoryId}`;
        return this.http.delete<any>(endpointUrl);
    }

    // notifyChanges() {
    //     this.changeSubject.next();
    // }
}
