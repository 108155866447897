import {animate, state, style, transition, trigger} from '@angular/animations';
import { AfterContentInit, Component, ElementRef, Inject, Input, OnInit,  SimpleChanges, ViewChild } from '@angular/core';
import { MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ResponseNotificationService, SecurityStateManagementService } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { transformDate } from 'src/app/helpers/date.helper';
import { OperationsCYHistory } from 'src/app/models/close-year-history-enum.model';
import { CloseYearHistory } from 'src/app/models/close-year-history.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { CloseYearEmployeeHistory } from '../../../../../models/close-year-employee-history.model';
import { CloseYearHistoryService } from '../../../services/close-year-history.service';

@Component({
  selector: 'app-close-year-history-details-dialog',
  templateUrl: './close-year-history-details-dialog.component.html',
  styleUrls: [
    './close-year-history-details-dialog.component.css',
    './../../../../../components/maintenance-list.component.css',
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CloseYearHistoryDetailsDialogComponent implements OnInit, AfterContentInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  displayedColumns: string[] = ['createdDate', 'employee', 'operation', 'log'];
  closeYearEmployeeHistory: CloseYearEmployeeHistory[] = [];
  closeYearHistory: CloseYearHistory;
  title: string = "";

  ELEMENT_DATA: CloseYearEmployeeHistory[] = [];
  dataSource: MatTableDataSource<CloseYearEmployeeHistory>;
  selectedCloseYearEmployeeHistory: CloseYearEmployeeHistory;
  expandedItem: CloseYearEmployeeHistory | null;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild("inputTableSearch", { static: false }) inputTableSearch: ElementRef;

  subscriptions: Subscription[] = [];
  filterApiCloseYear: FiltersApi = new FiltersApi();

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */

  constructor(
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    protected closeYearHistoryService: CloseYearHistoryService,
    public dialogRef: MatDialogRef<CloseYearEmployeeHistory>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public SSMService: SecurityStateManagementService,
    protected RNService: ResponseNotificationService,
  ) {
    this.filterApiCloseYear.add(
      {
        field: "related",
        value: "true"
      }
    );
    this.filterApiCloseYear.addInclude("Employee");
  }

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.closeYearHistory = this.data.closeYearHistory;

    this.loadData();
  }

  ngAfterContentInit(): void {
    // Se usa el setTimeout() para dejar tiempo a que se cargue la vista
    setTimeout(() => {
      this.inputTableSearch.nativeElement.focus();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  loadData() {
    this.spinner.show();
    this.ELEMENT_DATA = [];

    this.subscriptions.push(
      this.closeYearHistoryService.getCloseYearHistory(this.closeYearHistory.closeYearHistoryId, this.filterApiCloseYear.getStrinFilterApi()).subscribe(response => {
        this.closeYearHistory = response;
        this.closeYearEmployeeHistory = this.closeYearHistory.closeYearEmployeeHistory;

        this.ELEMENT_DATA = this.closeYearEmployeeHistory;

        this.dataSource = new MatTableDataSource<CloseYearEmployeeHistory>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSource.filterPredicate = (data: CloseYearEmployeeHistory, filter: string): boolean => {
          const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
            return (currentTerm + (data as { [key: string]: any })[key] + '◬');
          }, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

          const transformedFilter = filter.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

          return dataStr.indexOf(transformedFilter) !== -1;
        };

        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.RNService.showError(error);
      })
    )
  };

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  getColumnName(column) {
    let result = column;

    this.translate.get(`CLOSEYEAR_HISTORY.EMPLOYEE.LABEL.${column.toUpperCase()}`).subscribe((str) => {
      result = str;
    });

    return result;
  }

  getRow(element: CloseYearEmployeeHistory, column) {
    let result = "";

    switch(column) {
      case "createdDate":
        result = transformDate(element[column], 'DD-MM-YYYY HH:mm');
        break;
      case "employee":
        result = `${element.employee.name} ${element.employee.firstSurname} ${element.employee.secondSurname}`;
        break;
      case "log":
        result = element.closeYearHistory.log;
        break;
      case "operation":
        result = this.getOperationString(element[column]);
        break;
      default:
        result = element[column];
        break;
    }

    return result;
  }

  /**
   * Devuelve el string correspondiente al código de operaicón pasado.
   *
   * @param operation código de la operación
   * @returns un string
   */
  getOperationString(operation: number) {
    const operationStringCode = OperationsCYHistory[operation];
    let result = operationStringCode;

    this.translate.get(`CLOSEYEAR_HISTORY.OPERATIONS.${operationStringCode}`).subscribe((str) => {
      result = str;
    });

    return result;
  }

  /**
   * Selecciona las propiedades del estilo adecuados al estado del item
   */
  getSyleItem(workCalendarHistory: any) {
    let style = {
      'color': 'inherit'
    };

    if (((this.selectedCloseYearEmployeeHistory != undefined || this.selectedCloseYearEmployeeHistory != null) && this.selectedCloseYearEmployeeHistory == workCalendarHistory)) {
      style = {
        'color': '#009900'
      };
    }

    return style;
  }
}
