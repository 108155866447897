import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Area } from 'src/app/models/area.model';
import { Subscription } from 'rxjs';
import { AreaService } from 'src/app/services/area.service';
import { BaseFormComponent, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import * as jsonpatch from 'fast-json-patch';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company.model';

@Component({
  selector: 'app-area-form',
  templateUrl: 'area-form.component.html',
  styleUrls: ['./area-form.component.css','./../../maintenance.components.css'],
})
export class AreaFormComponent extends BaseFormComponent<Area> implements OnInit, OnDestroy {
  selectedDesc: string = undefined;
  area: Area;
  allCompany: Company[];

  roles: Role[];

  enabled = true;
  detail = false;
  permissionsLoaded = false;

  formGroupControl = new FormGroup({
    code: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    company: new FormControl('', Validators.required),
  });

  canAdd = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;
  subscription: Subscription;

  constructor(
    private toastrService: ToastrService,
    private areaService: AreaService,
    public accountService: AccountService,
    private companyService: CompanyService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
    }

    this.PK = 'areaId';

    this.baseRoute = '/maintenances/area';


    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('AREA_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
    this.init();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          if (
            this.accountService.currentUser.isAdmin ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RA) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RP)
          ) {
          }
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  private init() {
    this.formGroupControl.get('code').disable();
    this.formGroupControl.get('description').disable();
    this.formGroupControl.get('company').disable();
    this.area = new Area();
    this.subscription = this.route.paramMap.subscribe(params => {
      this.companyService.getAllCompany().subscribe(companyResponse => {
        this.allCompany = companyResponse.items;
        const transId = params.get('id');
        this.areaService.updateAreaRoutes(transId);
        if (transId) {
          if (transId === 'new') {
            this.detail = false;
            this.enabled = true;
            this.area = new Area();
            this.selectedDesc = undefined;
            this.formGroupControl.setValue({ code: '', description: '', company: '' });
            this.formGroupControl.get('code').enable();
            this.formGroupControl.get('description').enable();
            this.formGroupControl.get('company').enable();
          } else {
            this.areaService.getArea(transId).subscribe(response => {
              this.detail = true;
              this.enabled = false;
              this.area = response;
              const companySelected = this.allCompany.find(company => company.companyId === this.area.companyId);
              this.formGroupControl.setValue({ code: this.area.code, description: this.area.description, company: companySelected });
              this.formGroupControl.get('code').disable();
              this.formGroupControl.get('description').disable();
              this.formGroupControl.get('company').disable();
              this.selectedDesc = response.description;
            });
          }
        } else {
          this.selectedDesc = undefined;
          this.detail = false;
          this.enabled = false;
          this.area = new Area();
        }
      });
    });
  }

  edit() {
    this.enabled = true;
    // this.formGroupControl.get('code').enable();
    this.formGroupControl.get('description').enable();
    this.formGroupControl.get('company').enable();
  }

  cleanForm() {
    this.formGroupControl.setValue({ code: '', description: '', company: ''});
  }

  // ======================== CRUD ========================

  create() {
    this.area = new Area({
      code: this.formGroupControl.get('code').value,
      description: this.formGroupControl.get('description').value,
      companyId: this.formGroupControl.get('company').value.companyId
    });
    this.areaService.createArea(this.area).subscribe(
      response => {
        this.areaService.getAllArea().subscribe(areas => {
          this.areaService.updateAreas(areas.items);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.CREATE_LONG', {
            type: this.componentTypeTranslated,
            code: this.area.description,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.cancel();
      },
      error => {
        if (error.status === 500 || error.status === 0) {
        } else if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
        }
      },
    );
  }

  update() {
    const observer = jsonpatch.observe(this.area);
    this.area.code = this.formGroupControl.get('code').value;
    this.area.description = this.formGroupControl.get('description').value;
    this.area.companyId = this.formGroupControl.get('company').value.companyId;
    const patch = jsonpatch.generate(observer);
    this.areaService.modifyArea(this.area.areaId, patch).subscribe(
      response => {
        this.areaService.getAllArea().subscribe(departments => {
          this.areaService.updateAreas(departments.items);
        });
        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );

  }

  delete() {
    this.areaService.deleteArea(this.area.areaId).subscribe(response => {
      this.areaService.getAllArea().subscribe(areas => {
        this.areaService.updateAreas(areas.items);
      });
      let toastTitle = '';
      let toastBody = '';
      this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {
        toastTitle = a;
      });
      this.translate
        .get('MESSAGES.SUCCESS.DELETE_LONG', {
          type: this.componentTypeTranslated,
          code: this.area.description,
        })
        .subscribe((a: string) => {
          toastBody = a;
        });
      this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
      this.cancel();
    });
  }

  assingCompany(e) {
    this.formGroupControl.get('company').setValue(e.option.value);
  }

  displayCompany(company: Company): string | undefined {
    return (company) ? company.businessName : undefined;
  }

  cancel() {
    this.cleanForm();
    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }

  back() {
    this.cancel();
  }
}
