import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { DataChangeRequestGroupItems } from '../interfaces/dcr-group-api.interface';
import { DataChangeRequest } from '../models/data-chage-request.model';


@Injectable({ providedIn: 'root' })
export class DataChangeRequestService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private dataChangeRequestSubject: ReplaySubject<DataChangeRequestGroupItems[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public dataChangeRequestObservable: Observable<DataChangeRequestGroupItems[]> = this.dataChangeRequestSubject.asObservable();

  private dataChangeRequestRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public dataChangeRequestRouteObservable: Observable<string> = this.dataChangeRequestRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/dataChangeRequest';
  }

  constructor(private http: HttpClient) {
  }

  getAllDataChangeRequest(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    let url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getAllDataChangeRequestGroupBy(filter: string) {
    let url = `${this.serviceUrl}/Group${filter}`;

    return this.http.get<any>(url);
  }

  getDataChangeRequest(dataChangeRequestId: string, related?: boolean) {
    let relatedFilter = "";
    if (related != undefined && related != null) {
      relatedFilter += "?related=" + related;
    }
    return this.http.get<DataChangeRequest>(`${this.serviceUrl}/${dataChangeRequestId}${relatedFilter}`);
  }

  createDataChangeRequest(dataChangeRequest: DataChangeRequest) {
    const endpointUrl = `${this.serviceUrl}`;
    return this.http.post(endpointUrl, dataChangeRequest);
  }

  changeStateDataChangeRequest(dataChangeRequestId: string, dcrStateID: string, reason?: string) {
    let endpointUrl = `${this.serviceUrl}/${dataChangeRequestId}/DCRState/${dcrStateID}/Reason/`;

    if (reason != undefined && reason != "") {
      endpointUrl += `${reason}`;
    }
    return this.http.put(endpointUrl, null);
  }

  updateDataChangeRequests(dataChangeRequests: DataChangeRequestGroupItems[]) {
    this.dataChangeRequestSubject.next(dataChangeRequests);
  }

  updateDataChangeRequestRoutes(id: string) {
    this.dataChangeRequestRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
