import {NgModule} from '@angular/core';

import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { BreadcrumbModule, FooterModule, GridModule, HeaderModule, InfoDialogModule, SidenavModule } from 'inzo-portalempleado';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatDatepickerModule, MatInputModule } from '@angular/material';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgPipesModule } from 'ngx-pipes';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NgxSpinnerModule } from 'ngx-spinner';

import { InzoCalendarLibModule } from 'node_modules/inzo-calendar-lib';

@NgModule({
  exports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    HeaderModule,
    NgPipesModule,
    SidenavModule,
    FlexLayoutModule,
    FooterModule,
    BreadcrumbModule,
    GridModule,
    ReactiveFormsModule,

    MaterialFileInputModule,

    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,

    NgxMatMomentModule,

    InfoDialogModule,
    NgxSpinnerModule,

    MatDatepickerModule,
    MatInputModule,

    InzoCalendarLibModule,
  ],
})

export class CustomCommonImportsModule { }
