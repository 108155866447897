import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {

  isEmployee = true;

  constructor(
    public accountService: AccountService,
    public route: ActivatedRoute,
    public router: Router,
  ) {  }

  ngOnInit() {
    // TODO: gestionar para empleado
    // if (
    //   this.accountService.currentUser.isAdmin ||
    //   this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)
    // ) {
    //   this.router.navigateByUrl('dashboard');
    // } else {
    //   this.router.navigateByUrl('personal_data');
    // }
  }
}
