import { Component, OnInit, ElementRef, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { HolidayRequestState } from 'src/app/models/holiday-request-state.model';
import { HolidayRequestStateCode } from 'src/app/models/holiday-request-state-code-enum.model';
import { HolidayRequestEvent } from 'src/app/interfaces/events.interface';
import { HolidayRequestGroupsItems, HolidayRequestStateLabel } from 'src/app/interfaces/holidays.interface';
import { TranslateService } from '@ngx-translate/core';
import { HolidayRequestManagementCalendarComponent } from '../../holiday-management/holiday-request-management-calendar/holiday-request-management-calendar.component';

// ################################################################################################################
// ## CLASS HolidayRequestManagementTabsComponent
// ################################################################################################################
@Component({
  selector: 'app-holiday-request-tabs',
  templateUrl: './holiday-request-tabs.component.html',
  styleUrls: [
    './holiday-request-tabs.component.css',
    '../../maintenance.components.css',
    '../../maintenance-list.component.css',
  ],
})
export class HolidayRequestTabsComponent implements OnInit {
  @Input() holidayRequestsGroups: HolidayRequestGroupsItems[] = [];
  @Input() holidayRequestStates: HolidayRequestState[] = [];

  @Input() visibleStatesCalendar: HolidayRequestStateCode[] = [];

  @Input() employeeView : boolean = false;

  holidayRequestStatesTabs: HolidayRequestStateLabel[]= [];

  searching = true;
  filter = '';

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  @Input() calendar: HolidayRequestManagementCalendarComponent;
  @Output() actionHoliday: EventEmitter<HolidayRequestEvent> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();

  // Permissions
  canDirectAssignment: boolean = false;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.holidayRequestStatesTabs = [];

    for (let item of Object.keys(this.holidayRequestsGroups)) {
      let textTab = "";

      this.translate.get(`HOLIDAY_REQUEST_STATES.${item}`).subscribe((a: string) => {
        textTab = a;
      });

      this.holidayRequestStatesTabs.push( {
        alias: textTab,
        stateName: item
      });
    }
  }

  refresh() {
    this.refreshData.emit(true);
  }

  /**
   * Recoge un evento del componente de listado de solicitudes y ejecuta la acción correspondiente a ese evento.
   *
   * @param holidayRequestEvent evento recogido
   */
  actionHolidayReply(holidayRequestEvent: HolidayRequestEvent) {
    this.actionHoliday.emit(holidayRequestEvent);
  }

  /**
   * Devuelve el nombre del estado con valor state
   *
   * @param stateCode código de estado
   */
  getStateName(stateCode) {
    return HolidayRequestStateCode[stateCode];
  }

  /**
   * Devuelve el de solicitudes que se debe mostrar en las pestañas
   *
   * @param stateCode código de estado
   */
  getTabCount(holidayRequestsName: any): string {
    let holidayRequestsGroup: any = this.holidayRequestsGroups[holidayRequestsName];
    let countRequest = 0;

    for (let i = 0; i < holidayRequestsGroup.length; i++) {
      countRequest += holidayRequestsGroup[i].length;
    }

    return `${countRequest}`;
  }
}
