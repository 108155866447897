import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Category } from 'src/app/models/category.model';
import { EmployeeSituation } from 'src/app/models/employee-situation.model';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeType } from 'src/app/models/employee-type.model';
import { Area } from 'src/app/models/area.model';
import { Subscription } from 'rxjs';
import { DataHistoryService } from '../../../services/data-history.service';
import { DataChangesService } from '../../../services/data-changes.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { EmployeeContract } from '../../../../../models/employee-contract.model';

@Component({
  selector: 'app-employee-history-dialog',
  templateUrl: './employee-history-dialog.component.html',
  styleUrls: [
    './employee-history-dialog.component.css',
    '../../../../../../assets/styles/action.components.css',
    '../../../../../../assets/styles/action-list.component.css',
  ],
})
export class EmployeeHistoryDialogComponent implements OnInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  selectedView        : string = 'situation';
  employeeView        : boolean = true;
  employee            : Employee;

  areas               : Area[] = [];
  categories          : Category[] = [];
  employees           : Employee[] = [];
  employeeSituations  : EmployeeSituation[] = [];
  employeeContracts   : EmployeeContract[] = [];

  subscriptions       : Subscription[] = [];

  updatedAttributes: any;

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private dataChangesService: DataChangesService,
    private dataHistoryService: DataHistoryService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
  ) {
    this.updatedAttributes = {};

    // SelectedView
    this.subscriptions.push(
      this.dataHistoryService.selectedView.subscribe(data => {
        this.selectedView = data;
      })
    );
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // employeeManagementInfo
    this.subscriptions.push(
      this.dataChangesService.employeeManagementInfo.subscribe(data => {

        if (data) {
          // Datos para Históricos
          this.dataHistoryService.setEmployeeChange(data.employee);
          this.dataHistoryService.setAreasChange(data.allAreas);
          this.dataHistoryService.setCategoriesChange(data.allCategories);
          this.dataHistoryService.setEmployeesChange(data.allValidators);
          this.dataHistoryService.setEmployeeSituationsChange(data.allEmployeeSituations);
          this.dataHistoryService.setEmployeeTypesChange(data.allEmployeeTypes);
          this.dataHistoryService.setContractTypesChange(data.allContractTypes);
          this.dataHistoryService.setLaborAgreementsChange(data.allLaborAgreements);
        }
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  onReloadNeeded(event, attrib: string) {
    this.updatedAttributes[attrib] = event;
  }

  closeDialog() {
    this.dialogRef.close(this.updatedAttributes);
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  //#endregion

  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
}
