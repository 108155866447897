import { Employee } from "./employee.model";

export class Country{
  countryId   : string;
  name        : string;
  iso_name    : string;
  alfa2       : string;
  alfa3       : string;
  numerico    : number;


  constructor(obj?: Partial<Country>) {
    Object.assign(this, obj);
  }
}
