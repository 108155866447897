// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import { Injectable } from "@angular/core";
import { AuthService, Role, User } from "inzo-portalempleado";
import { BehaviorSubject, Subscription } from "rxjs";

import { IUserRolesPermissions } from "../interfaces/permissions.interface";
import { AppPermissions } from "../../../models/app-permission.model";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

// ################################################################################################################
// ## CLASS PermissionsService
// ################################################################################################################
@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  // employeeView
  private employeeViewEvent = new BehaviorSubject<boolean>(null);
  public employeeView = this.employeeViewEvent.asObservable();

  // roles
  private rolesEvent = new BehaviorSubject<Role[]>(null);
  public roles = this.rolesEvent.asObservable();

  // userRolesPermissions
  private userRolesPermissionsEvent = new BehaviorSubject<IUserRolesPermissions>(null);
  public userRolesPermissions = this.userRolesPermissionsEvent.asObservable();

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private toastrService: ToastrService,
  ) {}

  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  // employeeView
  public setEmployeeView(data: boolean) {
    this.employeeViewEvent.next(data);
  }

  // roles
  public setRoles(data: Role[]) {
    this.rolesEvent.next(data);
  }

  // userRolesPermissions
  public setPermissions(userRolesPermissions: IUserRolesPermissions) {
    this.userRolesPermissionsEvent.next(userRolesPermissions);
  }

  /**
   * Si hay un error de inicio de sesión hace que el usuario salga de la aplicación.
   * @param timeMSG tiempo de espera para hacer el logout
   */
  public loginError(timeMSG = 2000) {
    this.translate.get('MESSAGES.ERROR.LOGIN').subscribe((toastTitle: string) => {
      let toastBody = '';
      this.translate.get('MESSAGES.ERROR.LOGIN_BODY').subscribe((a: string) => {toastBody = a});
      this.toastrService.warning(toastBody, toastTitle, { disableTimeOut: true, positionClass: "toast-center-center" });

      // Se sale de la aplicación
      this.logout(timeMSG);
    });
  }

  /**
   * Inicia el logout en el tiempo especificado.
   * @param timeMSG tiempo de espera para hacer el logout
   */
  public logout(timeMSG = 0) {
    // Se sale de la aplicación
    setTimeout(() => {
      this.authService.logout();
    }, timeMSG);
  }
}
