import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Degree } from 'src/app/models/degree.model';
import { DegreeService } from 'src/app/services/degree.service';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeDegree } from 'src/app/models/employee-degree.model';
import { EmployeeManagementChangesService } from 'src/app/services/employee-management-changes.service';
import { getDateString } from 'src/app/helpers/date.helper';


@Component({
  selector: 'app-degree-list',
  templateUrl: 'degree-list.component.html',
  styleUrls: ['./../../maintenance.components.css','../../maintenance-list.component.css'],
})
export class DegreeListComponent extends BaseListComponent<Degree> implements OnInit, OnDestroy {
  @Input() degrees: Degree[] = [];
  @Input() employeeView: boolean = false;
  @Input() currentEmployee: Employee;
  employeeDegree: EmployeeDegree;
  editable:boolean;

  @Output() currentDegree: EventEmitter<Degree> = new EventEmitter();

  roles: Role[];

  subscription: Subscription;
  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;

  degreeId: string;

  canAdd = false;
  canDelete = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  constructor(
    private degreeService: DegreeService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private employeeManagementChanges: EmployeeManagementChangesService
  ) {
    super(accountService, SSMService, router, hotkeys);
    this.baseRoute = '/formations/degree';
    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ) {
      this.canAdd = true;
      this.canDelete = true;
    }

    this.onBuild = () => {
    };

    this.loadData = () => {
      if (!this.employeeView) {
        this.degreeService.getAllDegrees().subscribe(degrees => {
          this.degreeService.updateDegrees(degrees.items);
          this.degrees = degrees.items;
        }, error => {
          this.RNService.showError(error);
        });
      }
    };

    this.onInit = () => {
      if(!this.employeeView) {
        this.loadData();

        this.degreeService.degreeObservable.subscribe(degrees => {
          this.degrees = degrees;
        });
      }

      this.employeeManagementChanges.canEdit.subscribe( canEdit => {
        this.editable = canEdit;
      });
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  disassociateDegree(degree: Degree){
    this.currentDegree.emit(degree);
  }

  getEmployeeDegree(degree: Degree): EmployeeDegree{
    let employeeDegrees = this.currentEmployee.employeeDegrees;
    let result: EmployeeDegree = null;

    for(let id of employeeDegrees ){
      if(id.degreeId == degree.degreeId){
        result = id;
        break;
      }
    }

    return result;
  }

  getTrainingCenter(degree: Degree) {
    let employeeDegree = this.getEmployeeDegree(degree);

    return (employeeDegree && employeeDegree.trainingCenter)
      ? employeeDegree.trainingCenter.name
      : "";
  }

  getFinishDate(degree: Degree) {
    let employeeDegree = this.getEmployeeDegree(degree);

    return employeeDegree && employeeDegree.finishDate
      ? getDateString(employeeDegree.finishDate, "DD/MM/YYYY")
      : "";
  }
}

