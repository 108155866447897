// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import { Component, EventEmitter, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material';
import { IInzoPaginator } from '../../interfaces/inzo-mat-paginator.interface';

// ################################################################################################################
// ## CLASS InzoMatPaginatorComponent
// ################################################################################################################
@Component({
  selector: 'app-inzo-mat-paginator',
  templateUrl: './inzo-mat-paginator.component.html',
  styleUrls: ['./inzo-mat-paginator.component.css']
})
export class InzoMatPaginatorComponent implements OnInit {
  //#region ANGULAR METHODS
  // ##############################################################################################################
  // ## Arguments
  // ##############################################################################################################
  showFirstLastButtons  : boolean = true;
  pageSizeOptions       : number[] = [10, 20, 30, 40];
  pageSizeDefault       : number = 10;
  currentPageDefault    : number = 0;

  _inzoPaginator : IInzoPaginator = {
    currentPage : this.pageSizeDefault,
    pageSize    : this.currentPageDefault,
    totalCount  : 0,
  };
  inzoPage: IInzoPaginator = {
    currentPage: this._inzoPaginator.currentPage + 1,
    pageSize: this._inzoPaginator.pageSize,
  }

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Output() page: EventEmitter<IInzoPaginator> = new EventEmitter();
  //#endregion

  //#region CONSTRUCTOR
  // ##############################################################################################################
  // ## Constructor
  // ##############################################################################################################
  constructor() {
  }
  //#endregion

  //#region ANGULAR METHODS
  // ##############################################################################################################
  // ## Angular Methods
  // ##############################################################################################################
  ngOnInit() {
  }
  //#endregion

  set inzoPaginator(inzoPaginator: IInzoPaginator) {
    this._inzoPaginator.currentPage = (inzoPaginator.currentPage > 1)
      ? inzoPaginator.currentPage - 1
      : 0;
    this._inzoPaginator.pageSize = inzoPaginator.pageSize;
    this._inzoPaginator.totalCount = inzoPaginator.totalCount;
  }

  //#region CUSTOM METHODS
  // ##############################################################################################################
  // ## Custom Methods
  // ##############################################################################################################
  public firstPage() {
    this._inzoPaginator.currentPage = 0;
    this._inzoPaginator.pageSize = this.pageSizeDefault;

    this.setApiPaginator(this._inzoPaginator);

    this.page.emit(this.inzoPage);
  }

  setApiPaginator(event: IInzoPaginator) {
    if (event.currentPage < 0) {
      this.resetPaginator();
    } else {
      this._inzoPaginator.currentPage = event.currentPage;
      this._inzoPaginator.pageSize = event.pageSize;

      this.setInzoPaginator();
    }
  }

  private setInzoPaginator() {
    this.inzoPage = {
      currentPage: this._inzoPaginator.currentPage + 1,
      pageSize: this._inzoPaginator.pageSize,
    };
  }

  resetPaginator() {
    if (this.paginator) {
      this.paginator.firstPage();
      this.paginator.pageSize = this.pageSizeDefault;
    } else {
      this._inzoPaginator.currentPage = 1;
      this._inzoPaginator.pageSize = this.pageSizeDefault;
    }

    this.setInzoPaginator();
    this.page.emit(this._inzoPaginator);
  }

  getPage(event: PageEvent): PageEvent {
    let goPage = event.pageIndex;

    this._inzoPaginator.currentPage = goPage;
    this._inzoPaginator.pageSize = this.paginator.pageSize;

    this.setApiPaginator(this._inzoPaginator);

    this.page.emit(this.inzoPage);

    return event;
  }
  //#endregion

  //#region UTILS
  // ##############################################################################################################
  // ## Utils
  // ##############################################################################################################
  //#endregion
}
