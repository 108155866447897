import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Course } from 'src/app/models/course.model';
import { CourseService } from 'src/app/services/course.service';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeCourse } from 'src/app/models/employee-course.model';
import { EmployeeManagementChangesService } from 'src/app/services/employee-management-changes.service';
import { getDateString } from 'src/app/helpers/date.helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-course-list',
  templateUrl: 'course-list.component.html',
  styleUrls: ['./../../maintenance.components.css','../../maintenance-list.component.css'],
})
export class CourseListComponent extends BaseListComponent<Course> implements OnInit, OnDestroy {
  @Input() courses: Course[] = [];
  @Input() employeeView: boolean = false;
  @Input() currentEmployee: Employee;
  @Input() editable: boolean;
  employeeCourse: EmployeeCourse;
  isCertificationCourse = false;

  roles: Role[];

  @Output() currentCourse: EventEmitter<Course> = new EventEmitter();

  subscriptions: Subscription[] = [];
  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;

  canAdd = false;
  canDelete = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  constructor(
    protected courseService: CourseService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    protected RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    protected translate: TranslateService,
    protected spinner: NgxSpinnerService,
    protected employeeManagementChanges: EmployeeManagementChangesService,
  ) {
    super(accountService, SSMService, router, hotkeys);

    this.baseRoute = '/formations/course';

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
    }

    this.onBuild = () => {
    };

    this.loadData = () => {
      if (!this.employeeView) {
        this.spinner.show();

        this.subscriptions.push(
          this.route.paramMap.subscribe(params => {
            if (this.isCertificationCourse) {
              this.courseService.getAllCertificationCourses().subscribe(courses => {
                this.courseService.updateCourses(courses.items);
                this.courses = courses.items;
                this.spinner.hide();
              }, error => {
                this.spinner.hide();
                this.RNService.showError(error);
              });
            } else {
              this.courseService.getAllCourses().subscribe(courses => {
                this.courseService.updateCourses(courses.items);
                this.courses = courses.items;
                this.spinner.hide();
              }, error => {
                this.spinner.hide();
                this.RNService.showError(error);
              });
            }
          })
        );
      }
    };

    this.onInit = () => {
      if (this.employeeView) {
        this.baseRoute = '';
      }

      this.subscriptions.push(
        this.employeeManagementChanges.canEdit.subscribe( canEdit => {
          this.editable = canEdit;
        })
      );

      if(!this.employeeView) {
        this.loadData();

        this.courseService.courseObservable.subscribe(courses => {
          this.courses = courses;
        });
      }
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          if (
            this.accountService.currentUser.isAdmin ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RA) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RP)
          ) {
          }
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  disassociateCourse(course: Course){
    this.currentCourse.emit(course);
  }

  getEmployeeCourse(course: Course): EmployeeCourse{
    let employeeCourses = (this.isCertificationCourse)
      ? this.currentEmployee.employeeCertificationCourses
      : this.currentEmployee.employeeCourses;
    let result: EmployeeCourse = null;

    for(let id of employeeCourses ){
      if(id.courseId == course.courseId){
        result = id;
        break;
      }
    }

    return result;
  }

  getTrainingCenter(course: Course) {
    let employeeCourse = this.getEmployeeCourse(course);

    return (employeeCourse && employeeCourse.trainingCenter)
    ? employeeCourse.trainingCenter.name
      : "";
  }

  getFinishDate(course: Course) {
    let employeeCourse = this.getEmployeeCourse(course);

    return (employeeCourse && employeeCourse.finishDate)
      ? getDateString(employeeCourse.finishDate, "DD/MM/YYYY")
      : "";
  }

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  getTitle(): string {
    let title = 'COURSES_MAINTENANCE.LIST.HEADER';

    if (this.isCertificationCourse) {
      title += '_CERTIFICATION';
    }

    this.translate.get(`${title}`).subscribe((a: string) => {
      title = a;
    });

    return title;
  }
  //#endregion
}

