import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { TrainingChangeRequestGroupItems } from '../interfaces/tcr-group-api.interface';
import { TrainingChangeRequest } from '../models/training-change-request.model';


@Injectable({ providedIn: 'root' })
export class TrainingChangeRequestService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private trainingChangeRequestSubject: ReplaySubject<TrainingChangeRequestGroupItems[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public trainingChangeRequestObservable: Observable<TrainingChangeRequestGroupItems[]> = this.trainingChangeRequestSubject.asObservable();

  private trainingChangeRequestRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public trainingChangeRequestRouteObservable: Observable<string> = this.trainingChangeRequestRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/trainingChangeRequest';
  }

  constructor(private http: HttpClient) {
  }

  getAllTrainingChangeRequest(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    let url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getAllTrainingChangeRequestGroupBy(filter: string) {
    let url = `${this.serviceUrl}/Group${filter}`;

    return this.http.get<any>(url);
  }

  getTrainingChangeRequest(trainingChangeRequestId: string, related?: boolean) {
    let relatedFilter = "";
    if (related != undefined && related != null) {
      relatedFilter += "?related=" + related;
    }
    return this.http.get<TrainingChangeRequest>(`${this.serviceUrl}/${trainingChangeRequestId}${relatedFilter}`);
  }

  createTrainingChangeRequest(trainingChangeRequest: TrainingChangeRequest) {
    const endpointUrl = `${this.serviceUrl}`;
    return this.http.post(endpointUrl, trainingChangeRequest);
  }

  changeStateTrainingChangeRequest(trainingChangeRequestId: string, tcrStateID: string, reason?: string) {
    let endpointUrl = `${this.serviceUrl}/${trainingChangeRequestId}/TCRState/${tcrStateID}/Reason/`;

    if (reason != undefined && reason != "") {
      endpointUrl += `${reason}`;
    }
    return this.http.put(endpointUrl, null);
  }

  updateTrainingChangeRequests(trainingChangeRequests: TrainingChangeRequestGroupItems[]) {
    this.trainingChangeRequestSubject.next(trainingChangeRequests);
  }

  updateTrainingChangeRequestRoutes(id: string) {
    this.trainingChangeRequestRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
