

export class CourseType {
    courseTypeId: string;
    name: string;
    description: string;



    constructor(obj?: Partial<CourseType>) {
      this.courseTypeId = (obj && obj.courseTypeId) || undefined;
      this.name = (obj && obj.name) || undefined;
      this.description = (obj && obj.description) || undefined;


    }
  }
