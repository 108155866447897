import { Certification } from "./certification.model";
import { Course } from "./course.model";
import { Employee } from "./employee.model";
import { TrainingCenter } from "./training-center.model";

export class EmployeeCourse {
  employeeCourseId    : string;
  finishDate          : string;

  //Relations
  employeeId          : string;
  employee            : Employee;

  courseId            : string;
  course              : Course;

  employeeDocumentId  : string;
  certification       : Certification;

  trainingCenterId    : string;
  trainingCenter      : TrainingCenter;

  constructor(obj?: Partial<EmployeeCourse>) {
    this.employeeCourseId   = (obj && obj.employeeCourseId) || undefined;
    this.finishDate         = (obj && obj.finishDate) || undefined;

    //Relations
    this.employeeId         = (obj && obj.employeeId) || undefined;
    this.employee           = (obj && obj.employee) || undefined;

    this.courseId           = (obj && obj.courseId) || undefined;
    this.course             = (obj && obj.course) || undefined;

    this.employeeDocumentId = (obj && obj.employeeDocumentId) || undefined;
    this.certification      = (obj && obj.certification) || undefined;

    this.trainingCenterId   = (obj && obj.trainingCenterId) || undefined;
    this.trainingCenter     = (obj && obj.trainingCenter) || undefined;
  }
}
