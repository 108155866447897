export class AnnualWorkHour {
  annualWorkHourId  : string;
  year              : string;
  workHours         : number;
  maximumBase       : number;

  //Relations

  constructor(obj?: Partial<AnnualWorkHour>) {
    this.annualWorkHourId = (obj && obj.annualWorkHourId) || undefined;
    this.year             = (obj && obj.year) || undefined;
    this.workHours        = (obj && obj.workHours) || undefined;
    this.maximumBase      = (obj && obj.maximumBase) || undefined;

    //Relations
  }
}
