import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HolidayRequestComponent } from '../holiday-request.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HolidayRequest } from 'src/app/models/holiday-request.model';
import { HolidayRequestService } from 'src/app/services/holiday-request.service';
import { Employee } from 'src/app/models/employee.model';
import { AccountService } from 'inzo-portalempleado';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { disableDay, disableWeekEnd, getArrayDateString, transformDate } from 'src/app/helpers/date.helper';
import { WorkCalendarService } from 'src/app/services/work-calendar.service';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { HolidayRequestState } from 'src/app/models/holiday-request-state.model';
import moment = require('moment');
import { HolidayRequestStateCode } from 'src/app/models/holiday-request-state-code-enum.model';
import { HolidayRequestChangesService } from 'src/app/services/holiday-request-changes.service';
import { ListDates } from 'src/app/models/list-dates.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-holiday-request-create-range-dialog',
  templateUrl: './holiday-request-create-range-dialog.component.html',
  styleUrls: ['./holiday-request-create-range-dialog.component.css'],
})
export class HolidayRequestCreateRangeDialogComponent {
  totalDaysRequested : number = 0;
  totalDaysRequestedLastYear : number = 0;
  holidayRequestStates: HolidayRequestState[] = [];

  showReason: boolean = false;

  formGroupControl: FormGroup;

  allHolidayRequestTypes = [];
  holidaysRequestId: string;
  holidayRequestName: string;
  holidayRequest: HolidayRequest = null;

  currentEmployee: Employee = null;
  disabledDays: any[] = [];
  edit = false;

  selectedDates: ListDates = new ListDates();

  subscriptions: Subscription[] = [];

  // Permissions
  canDirectAssignment: boolean = false;
  directAssignment: boolean = false;

  constructor (
    public dialogRef: MatDialogRef<HolidayRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private accountService: AccountService,
    private employeeManagementService: EmployeeManagementService,
    private holidayRequestService: HolidayRequestService,
    private holidayRequestChangesService: HolidayRequestChangesService,
    private workCalendarService: WorkCalendarService,
  ) { }

  ngOnInit() {
    this.formGroupControl = new FormGroup({
      startDate: new FormControl('', Validators.required),
      finishDate: new FormControl('', Validators.required),
      state: new FormControl(''),
      reason: new FormControl(''),
    });

    this.subscriptions.push(
      this.holidayRequestChangesService.canDirectAssignment.subscribe(checked => {
        this.canDirectAssignment = checked;
      })
    );

    this.subscriptions.push(
      this.holidayRequestChangesService.check.subscribe(checked => {
        this.directAssignment = checked;
      })
    );

    const userId = this.accountService.currentUser.id;

    if (this.data.currentEmployee) {
      this.currentEmployee = this.data.currentEmployee;

      if (this.data.holidayRequestStates) {
        this.holidayRequestStates = this.data.holidayRequestStates;
      }

      this.totalDaysRequested = this.currentEmployee.totalAvailableHolidays - this.currentEmployee.totalHolidaysEnjoyed;
      this.totalDaysRequestedLastYear = this.currentEmployee.totalPendingHolidaysLastYear - this.currentEmployee.totalPendingHolidaysLastYearEnjoyed;
    } else {
      this.employeeManagementService.getEmployeeByUserId(userId, true).subscribe(employee => {
        this.currentEmployee = employee;
      });

      this.totalDaysRequested = this.data.totalDaysRequested;
      this.totalDaysRequestedLastYear = this.data.totalDaysRequestedLastYear;
    }

    this.holidaysRequestId = this.data.holidaysRequestId;
    this.holidayRequestName = this.data.holidayRequestName;
    this.holidayRequest = this.data.holidayRequest;

    if (this.currentEmployee && this.currentEmployee.workCalendar) {
      this.loadDisabledDays(this.currentEmployee.workCalendar);
    } else if (this.currentEmployee) {
      this.workCalendarService.getWorkCalendar(this.currentEmployee.workCalendarId).subscribe(response => {
        this.currentEmployee.workCalendar = response;
        this.loadDisabledDays(this.currentEmployee.workCalendar);
      });
    }

    if (this.holidayRequest) {
      this.edit = true;
      this.formGroupControl.setValue({
        startDate: this.holidayRequest.startDate,
        finishDate: this.holidayRequest.finishDate,
        state: this.holidayRequest.holidaysRequestStateId,
        reason: this.holidayRequest.reason,
      });
    }
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  selectedDatesChange(event) {
    this.selectedDates = event;

    if (this.selectedDates.empty()) {
      this.formGroupControl.get("startDate").reset();
      this.formGroupControl.get("finishDate").reset();
    } else {
      this.formGroupControl.patchValue(
        {
          startDate: this.selectedDates.first(),
          finishDate: this.selectedDates.last(),
        }
      );
    }
  }

  create(send: boolean = false) {
    this.spinner.show();

    this.holidayRequest = new HolidayRequest({
      startDate: transformDate(this.formGroupControl.get('startDate').value, 'YYYY-MM-DD'),
      finishDate: transformDate(this.formGroupControl.get('finishDate').value, 'YYYY-MM-DD'),
      employeeId: this.currentEmployee.employeeId,
      requestedDates: this.selectedDates.toDatesStrings(),
    });

    if (this.directAssignment) {
      let holidaysRequestStateId = this.formGroupControl.get('state').value;
      let reason = this.formGroupControl.get('reason').value;

      this.holidayRequestService.createHolidaysRequestToEmployee(this.holidayRequest, holidaysRequestStateId, reason).subscribe(response => {
        this.holidayRequest = response as HolidayRequest;
        this.createSuccessMessage();

      }, error => {
        this.createErrorMessage(error);
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
        this.dialogRef.close(this.holidayRequest);
      });
    } else {
      if (send) {
        let holidayRequestState = this.holidayRequestStates.find(element => element.stateCode == HolidayRequestStateCode.PENDING_APROVAL);
        this.holidayRequest.holidaysRequestStateId == holidayRequestState.holidaysRequestStateId;
      }
      this.holidayRequestService.createHolidayRequest(this.holidayRequest, send).subscribe(response => {
        this.holidayRequest = response as HolidayRequest;
        this.createSuccessMessage();

      }, error => {
        this.createErrorMessage(error);
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
        this.dialogRef.close(this.holidayRequest);
      });
    }
  }

  createSuccessMessage(timeMSG = 3000) {
    this.translate.get('HOLIDAY_REQUEST_MAINTENANCE.NAME').subscribe((componentTranslated: string) => {
      let toastTitle = '';
      let toastBody = '';
      this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {toastTitle = a});
      this.translate.get('MESSAGES.SUCCESS.CREATE_LONG_SIMPLE', {type: componentTranslated}).subscribe((a: string) => {toastBody = a});
      this.toastrService.success(toastBody, toastTitle, { timeOut: timeMSG });
    });
  }

  createErrorMessage(error, timeMSG = 3000) {
    let errorOverlappingDates = false;
    let errorTooManyRequestedDates = false;
    let dateFormat = "DD-MM-YYYY";

    let title = "MESSAGES.ERROR.CREATE_CONFLICT";
    let message = "MESSAGES.ERROR.CREATE_CONFLITCT_BODY";

    switch(error.error.type) {
      case "OVERLAPPING_DATES":
        errorOverlappingDates = true;
        break;
      case "TOO_MANY_REQUESTED_DATES":
        errorTooManyRequestedDates = true;
        break;
      default:
        title = 'MESSAGES.ERROR.UNEXPECTED';
        message = 'MESSAGES.ERROR.UNEXPECTED_BODY';
        break;
    }

    if (errorOverlappingDates || errorTooManyRequestedDates) {
      title = `MESSAGES.ERROR.${error.error.type}`;
      message = `MESSAGES.ERROR.${error.error.type}_BODY`;

      let value1 = "";
      let value2 = "";

      if (errorOverlappingDates) {
        value1 = getArrayDateString(error.error.absencesOverlappingDates, dateFormat);
        value2 = getArrayDateString(error.error.holidaysRequestOverlappingDates, dateFormat);
      } else {
        value1 = error.error.totalDaysAvailable;
        value2 = error.error.totalDaysRequested;
      }

      this.translate.get(message, { value1, value2 }).subscribe((transitionStr: string) => {
        message = transitionStr;
      });
    } else {
      this.translate.get(message).subscribe((transitionStr: string) => {
        message = transitionStr;
      });
    }

    this.translate.get(title).subscribe((transitionStr: string) => {
      title = transitionStr;
    });

    this.toastrService.error(message, title, { timeOut: timeMSG });
  }

  assingHolidayRequestType(e) {
    this.formGroupControl.get('holidayRequestType').setValue(e.option.value);
  }

  loadDisabledDays(workCalendar: WorkCalendar): void {
    const disabledDays = [];

    workCalendar.wcEvents.forEach(event => {
      let startDate = moment(event.startDate);
      let endDate = moment(event.endDate);
      while (startDate.isSameOrBefore(endDate)) {
        disabledDays.push({date: startDate.toDate()})
        startDate.add(1, 'days');
      }
    });

    this.disabledDays = disabledDays;
  }

  /**
   * Comprueba los días que se deben deshabilitar del calendario
   *
   * @param d fecha en formato objeto momentjs
   * @returns
   */
  getDisabledDays (d: any | null): boolean {
    let result = true;

    if ((d.day() == 0 || d.day() == 6)) {
      result = disableWeekEnd(d, this.currentEmployee.workCalendar);
    } else {
      result = disableDay(d, this.disabledDays);
    }

    return result;
  }

  getHolidayRequestState(state: string) {
    let currentState = null;

    for(let state of this.holidayRequestStates) {
      if (state.holidaysRequestStateId == this.formGroupControl.get("state").value) {
        currentState = state;
        break;
      }
    }

    this.showReason = currentState.stateCode == HolidayRequestStateCode[state];
  }
}
