import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, Hotkeys } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CourseService } from 'src/app/services/course.service';
import { CourseFormComponent } from './course-form.component';

@Component({
  selector: 'app-certification-course-from',
  templateUrl: 'course-form.component.html',
  styleUrls: [
    './course-form.component.css',
    './../../maintenance.components.css',
  ],
})
export class CertificationCourseFromComponent extends CourseFormComponent {

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    protected toastrService: ToastrService,
    protected courseService: CourseService,
    public accountService: AccountService,
    protected route: ActivatedRoute,
    public router: Router,
    public hotkeys: Hotkeys,
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected spinner: NgxSpinnerService,
  ) {
    super(toastrService, courseService, accountService, route, router, hotkeys, translate, dialog, spinner );

    this.isCertificationCourse = true;
    this.baseRoute = '/formations/certificationCourse';
  }
}
