import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { WorkCalendarHistoryEvent } from 'src/app/interfaces/events.interface';
import { OperationsWCHistory } from 'src/app/models/work-calendar-history-enum.model';
import { WorkCalendarHistory } from 'src/app/models/work-calendar-history.model';
import { CloseYearHistroyConfirmDialogComponent, ConfirmDialogType } from '../../close-year-histroy-confirm-dialog/close-year-histroy-confirm-dialog.component';

@Component({
  selector: 'app-close-year-wc-history',
  templateUrl: './close-year-wc-history.component.html',
  styleUrls: ['./close-year-wc-history.component.css']
})
export class CloseYearWorkCalendarHistoryComponent implements OnInit, AfterContentInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  @Input() displayedColumns: string[] = ['createdDate', 'createdBy', 'operation', 'log', 'oldWorkCalendar', 'newWorkCalendar'];
  @Input() workCalendarHistoryArray: WorkCalendarHistory[] = [];
  @Input() title: string = "";
  @Output() selectWorkCalendarHistory: EventEmitter<WorkCalendarHistoryEvent> = new EventEmitter();

  ELEMENT_DATA: WorkCalendarHistory[] = [];
  dataSource: MatTableDataSource<WorkCalendarHistory>;
  selectedWorkCalendarHistory: WorkCalendarHistory;

  enableCloseYear = false;
  enableUndoWorkCalendars = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild("inputTableSearch", { static: false }) inputTableSearch: ElementRef;

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    ) {}

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
  }

  ngAfterContentInit(): void {
    // Se usa el setTimeout() para dejar tiempo a que se cargue la vista
    setTimeout(() => {
      this.inputTableSearch.nativeElement.focus();

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }

  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  loadData() {
    this.ELEMENT_DATA = [];
    this.ELEMENT_DATA = this.workCalendarHistoryArray;

    this.dataSource = new MatTableDataSource<WorkCalendarHistory>(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: WorkCalendarHistory, filter: string): boolean => {
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        return (currentTerm + (data as { [key: string]: any })[key] + '◬');
      }, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

      const transformedFilter = filter.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getCreatedBy(element: WorkCalendarHistory) {
    let result = "";

    if (element != null && element.createdByUser != undefined && element.createdByUser != null) {
      result = `${element.createdByUser.name} ${element.createdByUser.firstSurname} ${element.createdByUser.secondSurname}`;
    }

    return result;
  }

  /**
  * Devuelve el objeto WorkCalendarHistory selecionado.
  * @param workCalendarHistory objeto WorkCalendarHistory selecionado
  */
  returnWorkCalendarHistory(workCalendarHistory: WorkCalendarHistory): void {
    this.selectedWorkCalendarHistory = workCalendarHistory;

    if (workCalendarHistory.operation == OperationsWCHistory.CLOSE_YEAR && workCalendarHistory.isLocked != true) {
      this.enableCloseYear = true;
      this.enableUndoWorkCalendars = true;
    } else {
      this.enableCloseYear = false;
      this.enableUndoWorkCalendars = false;
    }
  }

  createCloseYear() {
    const confirmWCRevert =  this.dialog.open(CloseYearHistroyConfirmDialogComponent, {
      disableClose: true,
      width: '550px',
      data: {
        confirmDialogType: ConfirmDialogType.CLOSEYEAR_GENERATE
      }
    });

    confirmWCRevert.afterClosed().subscribe(async (confirm) => {
      if (confirm === true) {
        this.enableCloseYear = false;

        this.selectWorkCalendarHistory.emit(
          {
            workCalendarHistory: this.selectedWorkCalendarHistory,
            eventType: "CREATE"
          }
        );
      }
    });
  }

  reversionCreatedWorkCalendars() {
    const confirmWCRevert =  this.dialog.open(CloseYearHistroyConfirmDialogComponent, {
      disableClose: true,
      width: '550px',
      data: {
        confirmDialogType: ConfirmDialogType.WORKCALENDARS_REVERSION
      }
    });

    confirmWCRevert.afterClosed().subscribe(async (confirm) => {
      if (confirm === true) {
        this.enableUndoWorkCalendars = false;

        this.selectWorkCalendarHistory.emit(
          {
            workCalendarHistory: this.selectedWorkCalendarHistory,
            eventType: "REVERSION"
          }
        );
      }
    });
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  /**
   * Devuelve el string correspondiente al código de operaicón pasado.
   *
   * @param operation código de la operación
   * @returns un string
   */
  getOperationString(operation: number) {
    const operationStringCode = OperationsWCHistory[operation];
    let result = operationStringCode;

    this.translate.get(`WORKCALENDAR_HISTORY.OPERATIONS.${operationStringCode}`).subscribe((str) => {
      result = str;
    });

    return result;
  }

  /**
   * Selecciona las propiedades del estilo adecuados al estado del item
   */
  getSyleItem(workCalendarHistory: WorkCalendarHistory) {
    let style = {
      'color': 'inherit'
    };

    if (((this.selectedWorkCalendarHistory != undefined || this.selectedWorkCalendarHistory != null) && this.selectedWorkCalendarHistory == workCalendarHistory)) {
      style = {
        'color': '#009900'
      };
    }

    return style;
  }
}
