import { EmployeeDegree } from "./employee-degree.model";

export class Degree {
  degreeId        : string;
  code            : string;
  description     : string;

  //Relations
  employeeDegrees : EmployeeDegree[];

  constructor(obj?: Partial<Degree>) {
    this.degreeId         = (obj && obj.degreeId) || undefined;
    this.code             = (obj && obj.code) || undefined;
    this.description      = (obj && obj.description) || undefined;

    //Relations
    this.employeeDegrees  = (obj && obj.employeeDegrees) || [];
  }
}
