import { Component, ViewChild, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { dateDifference, transformDate } from 'src/app/helpers/date.helper';
import { HRADateFilterService } from 'src/app/services/hra-date-filter.service';
import { Employee } from 'src/app/models/employee.model';
import { Subscription } from 'rxjs';
import { getDateTimeString } from 'src/app/helpers/date.helper';
import { SBA } from 'src/app/models/sba.model';
import { Category } from 'src/app/models/category.model';
import { EmployeeType } from 'src/app/models/employee-type.model';
import { DataHistoryService } from '../../../services/data-history.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SBAService } from '../../../../../services/sba.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as Rxjs from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { EmployeeContract } from '../../../../../models/employee-contract.model';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';

@Component({
  selector: 'app-sba-history-list',
  templateUrl: './employee-sba-history-list.component.html',
  styleUrls: [
    './employee-sba-history-list.component.css',
    '../../../../../../assets/styles/action.components.css',
    '../../../../../../assets/styles/action-list.component.css',
  ],
})
export class EmployeeSBAHistoryListComponent implements OnInit, OnDestroy {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  showAddPanel = false;
  employeeView = false;
  employee: Employee = null;

  minDate: Date;

  categories: Category[] = [];
  employeeTypes: EmployeeType[] = [];

  displayedColumns: string[] = [
    'actions',
    'startDate',
    'sba',
    'categoryId',
    'employeeTypeId',
    'coefficient',
    'observations',
    'costHour',
    'costHourManual',
  ];

  startDateFilter: string;
  finishDateFilter: string;

  currentSBA : SBA = new SBA();

  ELEMENT_DATA: SBA[] = [];
  dataSource: MatTableDataSource<SBA>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  subscriptions: Subscription[] = [];

  sbaCoefficientValue: number = 100;
  sbaValue: number;
  costHourManualValue: number;

  SBAHistoryFormGroup: FormGroup = new FormGroup({
    // sbaId               : new FormControl(''),
    category            : new FormControl('', Validators.required),
    coefficient         : new FormControl('', Validators.required),
    costHour            : new FormControl(''),
    costHourManual      : new FormControl(''),
    employeeContractId  : new FormControl(''),
    employeeType        : new FormControl('', Validators.required),
    observations        : new FormControl(''),
    sba                 : new FormControl('', Validators.required),
    startDate           : new FormControl('', Validators.required),
  });

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private dataHistoryService: DataHistoryService,
    private hraDateFilterService: HRADateFilterService,
    protected permissionsService: PermissionsService,
    private sbaService: SBAService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
  ) {
    this.minDate = moment().startOf('month').toDate();
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;
      })
    );

    // EmployeeView
    this.subscriptions.push(
      this.dataHistoryService.employeeView.subscribe(data => {
        this.employeeView = data;
      })
    );


    // Categories
    this.subscriptions.push(
      this.dataHistoryService.categories.subscribe(data => {
        this.categories = data;
      })
    );

    // EmpoyeeTypes
    this.subscriptions.push(
      this.dataHistoryService.employeeTypes.subscribe(data => {
        this.employeeTypes = data;
      })
    );

    // Employee
    this.subscriptions.push(
      this.dataHistoryService.employee.subscribe(data => {
        this.employee = data;

        this.loadDataOnTable();
      })
    );

    
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  //#endregion

  /* ################################################################################################################## */
  /* ## DATA METHODS
  /* ################################################################################################################## */
  loadDataOnTable() {
    if (this.employee != undefined && this.employee != null) {
      this.ELEMENT_DATA = this.employee.sbas;

      // Se busca el valor actual
      this.assigCurrentSba();

      this.ELEMENT_DATA = _.orderBy(this.ELEMENT_DATA, a => moment(a.startDate).unix(), 'desc');
      this.dataSource = new MatTableDataSource<SBA>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  getAndLoadDataOnTable() {
    let filters: string = "";
    this.spinner.show();

    this.sbaService.getSBAsForEmployee(this.employee.employeeId).subscribe(SBAHisoryResponse => {
      this.employee.sbas = (SBAHisoryResponse && SBAHisoryResponse.items) || [];

      this.loadDataOnTable();

      // Se propaga el cambio a los observadores
      this.dataHistoryService.setEmployeeChange(this.employee);

      this.spinner.hide();
    },
    error => {
      this.spinner.hide();
    });
  }

  onCreate() {
    if (this.SBAHistoryFormGroup && this.SBAHistoryFormGroup.valid) {
      this.spinner.show();

      this.getSBAToSend();

      this.sbaService.createSBA(this.currentSBA).subscribe(response => {
        this.SBAHistoryFormGroup.get('startDate').setValue('');

        this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.SBA.CREATION_SUCCESS').subscribe((text: string) => {
          this.toastrService.success(text, '', { timeOut: 3000 });
        });

        this.resetForm();
        this.getAndLoadDataOnTable();

        this.showAddPanel = false;
      },
      error => {
        console.error('error --> ', error);
        this.spinner.hide();
        if(error.error === 'MISSING_PARAMS') {
          this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.SBA.ERROR.MISSING_PARAMS').subscribe((text: string) => {
            this.toastrService.error(text, '', { timeOut: 3000 });
          });
        } else {
          this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.SBA.ERROR.UNEXPECTED').subscribe((text: string) => {
            this.toastrService.error(text, '', { timeOut: 3000 });
          });
        }
      });
    }
  }

  onRemove(SBA: SBA) {
    let text;
    this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.MESSAGE.SBA.DELETE_CONFIRMATION.TEXT').subscribe((transitionStr: string) => {
      text = transitionStr;
    });

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      data: {
        text
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.spinner.show();
        this.sbaService.deleteSBA(SBA.sbaId).subscribe(response => {
            this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.SBA.DELETION_SUCCESS').subscribe((text: string) => {
              this.toastrService.success(text, '', { timeOut: 3000 });
            });

            this.getAndLoadDataOnTable();
        },
        error => {
          this.spinner.hide();
          this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.SBA.ERROR.UNEXPECTED').subscribe((text: string) => {
            this.toastrService.error(text, '', { timeOut: 3000 });
          });
        });
      }
    });
  }

  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  assigCurrentSba() {
    // Se busca el valor actual
    const currentSBAHistory = _(this.employee.sbas).filter(x => moment(x.startDate) <= moment()).maxBy(x => moment(x.startDate).unix());

    if (currentSBAHistory) {
      this.employee.sba = currentSBAHistory;
      this.employee.sbaId = currentSBAHistory.sbaId;
    }
  }

  resetForm() {
    this.showAddPanel = false;
    Object.keys(this.SBAHistoryFormGroup.controls).forEach((name) => {
      const currentControl = this.SBAHistoryFormGroup.controls[name];

      let valueField = '';

      if (name == 'coefficient') {
        valueField = '100';
      }

      currentControl.setValue(valueField);
    });

    this.SBAHistoryFormGroup.markAsPristine();
    this.SBAHistoryFormGroup.clearValidators();
  }

  /* ****************************************************************************************************************** */
  /* ** SBA
  /* ****************************************************************************************************************** */
  getSBAToSend() {
    this.currentSBA.employeeId = this.employee.employeeId;
    this.currentSBA.startDate = moment(this.SBAHistoryFormGroup.get('startDate').value, 'DD/MM/YYYY').format('YYYY-MM-DD');

    this.currentSBA.coefficient = this.sbaCoefficientValue;
    this.currentSBA.sba = this.sbaValue;
    this.currentSBA.observations = this.SBAHistoryFormGroup.get('observations').value;

    this.currentSBA.costHourManual = this.costHourManualValue;

    // El coste hora se calcula en la API y no es necesario mandarlo
    this.currentSBA.costHour = undefined;

    // Se eliminan los modelos completos, es suficiente con mandar sólo los Ids de los mismos
    this.currentSBA.category = undefined;
    this.currentSBA.company = undefined;
    this.currentSBA.employee = undefined;
    this.currentSBA.employeeContract = undefined;
    this.currentSBA.employeeType = undefined;
  }

  getSBACalculationData(): SBA {
    const result: SBA = new SBA();

    result.employeeId = this.employee.employeeId;
    result.startDate = moment(this.SBAHistoryFormGroup.get('startDate').value, 'DD/MM/YYYY').format('YYYY-MM-DD');

    result.coefficient = this.sbaCoefficientValue;
    result.sba = this.sbaValue;
    result.observations = this.SBAHistoryFormGroup.get('observations').value;

    result.categoryId = this.currentSBA.categoryId;
    result.category = this.currentSBA.category;

    result.employeeContractId = this.currentSBA.employeeContractId;
    result.employeeContract = this.currentSBA.employeeContract;

    result.employeeTypeId = this.currentSBA.employeeTypeId;
    result.employeeType = this.currentSBA.employeeType;

    return result;
  }

  assignSBACategoryData(category: Category) {
    this.currentSBA.categoryId = category.categoryId;
    this.currentSBA.category = category;

    this.SBAHistoryFormGroup.get('category').setValue(this.currentSBA.category.careerPlan);
  }

  assignSBAConttractData(contract: EmployeeContract) {
    this.currentSBA.companyId = contract.companyId;
    this.currentSBA.company = contract.company;

    this.currentSBA.employeeContractId = contract.employeeContractId;
    this.currentSBA.employeeContract = contract;

    this.currentSBA.employeeTypeId = contract.employeeTypeId;
    this.currentSBA.employeeType = contract.employeeType;

    this.SBAHistoryFormGroup.get('employeeContractId').setValue(this.currentSBA.employeeContractId);
    this.SBAHistoryFormGroup.get('employeeType').setValue(this.currentSBA.employeeType.description);
  }

  /* ################################################################################################################## */
  /* ## AUTOCOMPLETE METHODS
  /* ################################################################################################################## */
  onStartDateSelect(event: any): void {
    const date = event.target.value;
    this.SBAHistoryFormGroup.get('startDate').setValue(moment(date).format('DD/MM/YYYY'));

    // Se resetean los valores autorellenados del formulario
    this.currentSBA = new SBA();
    this.SBAHistoryFormGroup.get('category').setValue('');
    this.SBAHistoryFormGroup.get('costHour').setValue('');
    this.SBAHistoryFormGroup.get('employeeContractId').setValue('');
    this.SBAHistoryFormGroup.get('employeeType').setValue('');

    // Se buscan los contratos en vigor en la fecha pasada
    let contracts = this.employee.employeeContracts.filter((item) =>
      dateDifference(moment(item.startDate, 'YYYY-MM-DD').toDate(), date.toDate()) >= 0
    );

    if (contracts && contracts.length > 0) {
      contracts = _.orderBy(contracts, ['startDate'], ['desc']);

      contracts[0].employeeType = this.getEmployeeType(contracts[0].employeeTypeId);

      this.assignSBAConttractData(contracts[0]);

      // Se informa si hay más de un valor posible
      if (contracts.length > 1) {
      }
    }

    let categories = this.employee.employeeCategoriesHistory.filter((item) =>
      dateDifference(moment(item.startDate, 'YYYY-MM-DD').toDate(), date.toDate()) >= 0
    );

    if (categories && categories.length > 0) {
      categories = _.orderBy(categories, ['startDate'], ['desc']);

      this.assignSBACategoryData(this.categories.find(item => item.categoryId == categories[0].categoryId));

      // Se informa si hay más de un valor posible
      if (categories.length > 1) {
      }
    }

    this.getCostHour();
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  getCategoryStr(categoryId) {
    let employeeType = this.getCategory(categoryId);

    return (employeeType != null)
      ? employeeType.categoryCode
      : '';
  }

  getCategory(categoryId) {
    let result;

    if (this.categories) {
      result = this.categories.find(element => element.categoryId == categoryId);
    }

    return result;
  }

  getEmployeeTypeStr(employeeTypeId) {
    let employeeType = this.getEmployeeType(employeeTypeId);

    return (employeeType != null)
      ? employeeType.description
      : '';
  }

  getEmployeeType(employeeTypeId) {
    let result;

    if (this.employeeTypes) {
      result = this.employeeTypes.find(element => element.employeeTypeId == employeeTypeId);
    }

    return result;
  }

  getCostHour() {
    const currentSBAData = this.getSBACalculationData();
    this.getSBAToSend();

    const compareAttributes = [
      'startDate',
      'sba',
      'coefficient',
      'category',
      'employeeType',
    ];

    var compareResult = _.isEqual(
      _.omit(currentSBAData, compareAttributes),
      _.omit(this.currentSBA, compareAttributes)
    );

    if (this.SBAHistoryFormGroup.valid && !compareResult) {
      this.sbaService.hourCostCalculation(currentSBAData).subscribe(response => {
        let value = response as number;

        this.SBAHistoryFormGroup.get('costHour').setValue(value.toFixed(2));
      },
      error => {
        this.spinner.hide();
        console.error('error --> ', error);

        this.toastrService.error(error.error, '', { timeOut: 3000 });
      });
    }
  }

  isCurrentValue(item): boolean {
    return (this.employee)
      ? this.employee.sbaId == item.sbaId
      : false;
  }

  getDate(date: any, format: string): string {
    let result = getDateTimeString(date, format);
    return result != "Invalid date" ? result : "";
  }

  canAdd(): boolean {
    return (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH);
  }

  canDelete(item): boolean {
    let result = (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH);
    const startDate = moment(item.startDate);
    const currentDate = moment();

    if (startDate.year() == currentDate.year()) {
      result = result && (startDate.month() >= currentDate.month());
    } else if (startDate.year() > currentDate.year()) {
      result = true;
    } else {
      result = false;
    }

    return result;
  }
}
