import { Component, OnInit, ElementRef, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbsenceGroupsItems, AbsenceStateLabel } from 'src/app/interfaces/absence.interface';
import { AbsenceEvent } from 'src/app/interfaces/events.interface';
import { AbsenceStateCode } from 'src/app/models/absence-state-code-enum.model';
import { AbsenceState } from 'src/app/models/absence-state.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { AbsenceChangesService } from 'src/app/services/absence-changes.service';
import { ITLeaveManagementCalendarComponent } from '../it-leave-management-calendar/it-leave-management-calendar.component';

@Component({
  selector: 'app-it-leave-management-tabs',
  templateUrl: './it-leave-management-tabs.component.html',
  styleUrls: [
    './it-leave-management-tabs.component.css',
    '../../maintenance.components.css',
    '../../maintenance-list.component.css',
  ],
})
export class ITLeaveManagementTabsComponent implements OnInit {
  @Input() absencesGroups: AbsenceGroupsItems[] = [];
  @Input() absenceStates: AbsenceState[] = [];

  @Input() visibleStatesCalendar: AbsenceStateCode[] = [];

  @Input() employeeView : boolean = false;

  @Input() filtersApi: FiltersApi = new FiltersApi();

  absenceStatesTabs: AbsenceStateLabel[]= [];

  searching = true;
  filter = '';

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  @Input() calendar: ITLeaveManagementCalendarComponent;
  @Output() actionAbsence: EventEmitter<AbsenceEvent> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();

  // Permissions
  canDirectAssignment: boolean = false;

  constructor(
    private translate: TranslateService,
    protected absenceChangesService: AbsenceChangesService,
  ) { }

  ngOnInit() {
    this.absenceChangesService.canDirectAssignment.subscribe(checked => {
      this.canDirectAssignment = checked;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.absenceStatesTabs = [];

    for (let item of Object.keys(this.absencesGroups)) {
      let textTab = "";

      this.translate.get(`ABSENCE_STATES.${item}`).subscribe((a: string) => {
        textTab = a;
      });

      this.absenceStatesTabs.push( {
        alias: textTab,
        stateName: item
      });
    }
  }

  refresh() {
    this.refreshData.emit(true);
  }

  /**
   * Recoge un evento del componente de listado de solicitudes y ejecuta la acción correspondiente a ese evento.
   *
   * @param absenceEvent evento recogido
   */
  actionAbsenceReply(absenceEvent: AbsenceEvent) {
    this.actionAbsence.emit(absenceEvent);
  }

  /**
   * Devuelve el nombre del estado con valor state
   * @param stateCode código de estado
   */
  getStateName(stateCode) {
    return AbsenceStateCode[stateCode];
  }

  /**
   * Devuelve el de solicitudes que se debe mostrar en las pestañas
   *
   * @param stateCode código de estado
   */
  getTabCount(absencesGroup: any): string {
    let result = ``;
    let countGroups = absencesGroup.length;
    let countRequest = 0;

    for (let item in absencesGroup) {
      countRequest += absencesGroup[item].length;
    }

    if (this.employeeView) {
      result += `${countRequest}`;
    } else {
      // result += `${countGroups} - ${countRequest}`;
      result += `${countGroups}`;
    }

    return result;
  }
}
