import { Component, OnInit, ElementRef, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';

import { HolidayRequest } from 'src/app/models/holiday-request.model';
import { HolidayRequestStateCode, HolidayRequestStateColor } from 'src/app/models/holiday-request-state-code-enum.model';
import { HolidayRequestManagementListComponent } from '../../holiday-management/holiday-request-management-list/holiday-request-management-list.component';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { HolidayRequestState } from 'src/app/models/holiday-request-state.model';
import { HolidayRequestEvent } from 'src/app/interfaces/events.interface';
import { HolidayRequestChangesService } from 'src/app/services/holiday-request-changes.service';
import { HolidayRequestManagementCalendarComponent } from '../../holiday-management/holiday-request-management-calendar/holiday-request-management-calendar.component';

@Component({
  selector: 'app-holiday-request-list',
  templateUrl: 'holiday-request-list.component.html',
  styleUrls: ['./holiday-request-list.component.css', './../../action.components.css', '../../action-list.component.css'],
})
export class HolidayRequestListComponent {
  @Input() filtersApi: FiltersApi = new FiltersApi();

  @Input() holidayRequests: HolidayRequest[] = [];
  @Input() holidayRequestStates: HolidayRequestState[] = [];

  @Input() visibleStatesCalendar: HolidayRequestStateCode[] = [];

  searching = true;
  filter = '';

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  @Input() calendar: HolidayRequestManagementCalendarComponent;
  @Output() actionHoliday: EventEmitter<HolidayRequestEvent> = new EventEmitter();

  // Permissions
  canDirectAssignment: boolean = false;
  directAssignment: boolean = false;

  constructor(
    protected holidayRequestChangesService: HolidayRequestChangesService,
  ) { }

  ngOnInit() {
    this.holidayRequestChangesService.canDirectAssignment.subscribe(checked => {
      this.canDirectAssignment = checked;
    });
    this.holidayRequestChangesService.check.subscribe(checked => {
      this.directAssignment = checked;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  /**
   * Recoge un evento del componente de listado de solicitudes y ejecuta la acción correspondiente a ese evento.
   *
   * @param holidayRequestEvent evento recogido
   */
   actionHolidayEvent(holidayRequestEvent: HolidayRequestEvent) {
    this.actionHoliday.emit(holidayRequestEvent);
  }

  /**
   * Pasa la solicitud de vacaciones recibida como param a estado 'ABORTED'
   * @param holidayRequest - La solicitud de vacaciones que será pasada al estado abortada
   */
  abortHoliday(holidayRequest: HolidayRequest): void {
    this.actionHoliday.emit(
      {
        holidayRequest,
        eventType: "ANNULLED"
      }
    );
  }

  /**
   * Pasa la solicitud de vacaciones recibida como param a estado 'APPROVED' o 'ANNULLED'
   *
   * @param holidayRequest - La solicitud de vacaciones que será pasada al estado 'APPROVED' o 'ANNULLED'
   */
  approveHoliday(holidayRequest: HolidayRequest): void {
    let eventType = "APPROVED";

    if (holidayRequest.holidaysRequestState.stateCode == HolidayRequestStateCode.PENDING_ANNULLED) {
      eventType = "ANNULLED";
    }

    this.actionHoliday.emit(
      {
        holidayRequest,
        eventType
      }
    );
  }

  /**
   * Pasa la solicitud de vacaciones recibida como param a estado 'REJECTED' o 'ANNULLED
   *
   * @param holidayRequest - La solicitud de vacaciones a cancelar
   */
  rejectHoliday(holidayRequest: HolidayRequest): void {
    let eventType = "REJECTED";

    if (holidayRequest.holidaysRequestState.stateCode == HolidayRequestStateCode.PENDING_ANNULLED) {
      // Si se niega la anulación pasa a estado aprobado (se supo que sólo se puede solicitar la anulación de una solicitud aprobada)
      eventType = "APPROVED";
    }

    this.actionHoliday.emit(
      {
        holidayRequest,
        eventType
      }
    );
  }

  // CALENDAR
  /**
   * Determina si se puede mostrar o no la solicitud en el calendario
   */
  toggleHolidayLoadOnCalendar(holidayRequest: HolidayRequest) {
    if (this.visibleStatesCalendar.includes(holidayRequest.holidaysRequestState.stateCode)) {
      this.calendar.toggleHolidayLoadOnCalendar(holidayRequest);
    }
  }

  /**
   * Selecciona las clases que debe tener la solicitud para su visualización correcta
   */
  getClassesItem(holidayRequest: HolidayRequest) {
    let assignedClasses = "mat-line my-auto";

    if (this.visibleStatesCalendar.includes(holidayRequest.holidaysRequestState.stateCode)) {
      assignedClasses += " pointer";

      assignedClasses += (holidayRequest.visibleOnYearCalendar) ? '' : ' unselect';
    }

    return assignedClasses;
  }

  /**
   * Selecciona el color del estado de la solicitud
   */
  getColorItem(holidayRequest: HolidayRequest) {
    let color = "";

    if (this.visibleStatesCalendar.includes(holidayRequest.holidaysRequestState.stateCode) && holidayRequest.visibleOnYearCalendar) {
      let stateName = HolidayRequestStateCode[holidayRequest.holidaysRequestState.stateCode];

      color = HolidayRequestStateColor[stateName];
    }

    return color;
  }

  /**
   * Función que determina si se deben mostrar el botón de enviar la solicitud
   *
   * @param holidayRequest - solicitud a valorar por la condición que dictará si mostrar el botón o no.
   * @returns
   */
  showSendButton(holidayRequest: HolidayRequest): boolean {
    return holidayRequest.holidaysRequestState.stateCode == HolidayRequestStateCode.REGISTERED;
  }

  /**
   * Función que determina si se deben mostrar el botón de cancelar la solicitud
   *
   * @param holidayRequest - solicitud a valorar por la condición que dictará si mostrar el botón o no.
   * @returns
   */
  showCancelButton(holidayRequest: HolidayRequest): boolean {
    let result = false;

    if(
      holidayRequest.holidaysRequestState.stateCode == HolidayRequestStateCode.APPROVED ||
      holidayRequest.holidaysRequestState.stateCode == HolidayRequestStateCode.PENDING_APROVAL ||
      holidayRequest.holidaysRequestState.stateCode == HolidayRequestStateCode.REGISTERED
    ) {
      result = true;
    }

    return result;
  }

  /**
   * Pasa la solicitud de vacaciones recibida como param a estado 'REJECTED', para ello previamente se abrirá un
   * dialog en el que se deberá escribir la razón por la cual se rechaza la solicitud.
   *
   * @param holidayRequest - La solicitud de vacaciones a cancelar
   */
  cancelHolidayRequest(holidayRequest: HolidayRequest): void {
    let eventType = "CANCELED";

    if (
      holidayRequest.holidaysRequestState.stateCode == HolidayRequestStateCode.APPROVED
    ) {
      eventType = "PENDING_ANNULLED";
    }

    this.actionHoliday.emit(
      {
        holidayRequest,
        eventType
      }
    );
  }

  /**
   * Pasa la solicitud de vacaciones recibida como param a estado 'REJECTED', para ello previamente se abrirá un
   * dialog en el que se deberá escribir la razón por la cual se rechaza la solicitud.
   *
   * @param holidayRequest - La solicitud de vacaciones a cancelar
   */
  sendHolidayRequest(holidayRequest: HolidayRequest): void {
    this.actionHoliday.emit(
      {
        holidayRequest,
        eventType: "PENDING_APROVAL"
      }
    );
  }
}
