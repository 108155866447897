import { Employee } from "./employee.model";


export class EmployeeType {
  employeeTypeId: string;
  type: string;
  description: string;

  //Relations
  employee: Employee[];

  constructor(obj?: Partial<EmployeeType>) {
    this.employeeTypeId = (obj && obj.employeeTypeId) || undefined;
    this.type = (obj && obj.type) || undefined;
    this.description = (obj && obj.description) || undefined;

    //Relations
    this.employee = (obj && obj.employee) || [];
  }
}
