import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-maintenance-course',
  templateUrl: './course.component.html',
  styleUrls: [
    './course.component.css',
    './../../../components/maintenance.components.css',
  ],
})
export class CourseComponent implements AfterViewInit, OnInit {
  showForm = false;
  constructor( private courseService: CourseService) {
  }
  ngAfterViewInit() {
    this.courseService.courseRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
  ngOnInit() {
  }
}
