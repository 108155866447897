import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Centre } from 'src/app/models/centre.model';
import { CentreService } from 'src/app/services/centre.service';


@Component({
  selector: 'app-centre-list',
  templateUrl: 'centre-list.component.html',
  styleUrls: ['./../../maintenance.components.css','../../maintenance-list.component.css'],
})
export class CentreListComponent extends BaseListComponent<Centre> implements OnInit, OnDestroy {
  centres: Centre[] = [];
  subscription: Subscription;
  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;

  roles: Role[];

  canView = false;
  canAdd = false;
  canDelete = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  constructor(
    private centreService: CentreService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
  ) {
    super(accountService, SSMService, router, hotkeys);
    this.baseRoute = '/maintenances/centre';
    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
    }

    this.onBuild = () => {
    };

    this.loadData = () => {
      this.spinner.show();
      this.centreService.getAllCentres().subscribe(centres => {
        this.centres = [];

        for(let centre of centres.items){
          if(centre.name !== "DEFAULT"){
            this.centres.push(centre);
          }
        }

        this.centreService.updateCentres(this.centres);
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.RNService.showError(error);
      });
    };

    this.onInit = () => {
      if(this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH)) {
        this.canView = true;
        this.canAdd = true;
        this.canDelete = true;

        this.loadData();

        this.centreService.centreObservable.subscribe(centres => {
          this.centres = centres;
        });
      }
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;
          this.onInit();
        });
      });
    } else {
      this.roles = this.accountService.roles;
      this.onInit();
    }
  }
  //#endregion
}

