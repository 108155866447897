import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/department.service';

@Component({
  selector: 'app-maintenance-department',
  templateUrl: './department.component.html',
  styleUrls: ['./../maintenance.components.css'],
})
export class DepartmentComponent implements AfterViewInit, OnInit {
  showForm = false;
  constructor( private departmentService: DepartmentService) {
  }
  ngAfterViewInit() {
    this.departmentService.departmentRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
  ngOnInit() {
  }
}
