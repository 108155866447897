// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import { Component, ElementRef, ViewChild, Input, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, BaseListComponent, Hotkeys, SecurityStateManagementService } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { MatTableDataSource } from '@angular/material/table';
import { HRAHistoryService } from 'src/app/services/hra-history.service';
import { HRAHistory } from 'src/app/models/hra-history.model';
import { MatPaginator, MatSort } from '@angular/material';
import { transformDate } from 'src/app/helpers/date.helper';
import { HRADateFilterService } from 'src/app/services/hra-date-filter.service';
import { Employee } from 'src/app/models/employee.model';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { DataChangesService } from '../../../services/data-changes.service';
import { Subscription } from 'rxjs';
import { DataHistoryService } from '../../../services/data-history.service';

// ################################################################################################################
// ## CLASS AbsencesHistoryListComponent
// ################################################################################################################
@Component({
  selector: 'app-absences-history-list',
  templateUrl: './absences-history-list.component.html',
  styleUrls: [
    './absences-history-list.component.css',
    '../../../../../../assets/styles/action.components.css',
    '../../../../../../assets/styles/action-list.component.css',
  ],
})
export class AbsencesHistoryListComponent implements OnInit, OnDestroy {
  /* ############################################################################################################## */
  /* ## ATRRIBUTES
  /* ############################################################################################################## */
  @Input() employeeView = false;
  employee: Employee;
  employees: Employee[] = [];
  abseceHistory: HRAHistory[] = [];

  subscriptions: Subscription[] = [];

  @Input() displayedColumns: string[] = [
    'createdDate',
    'log',
    'managerId',
    'approveDate',
    'reason',
    'startDate',
    'finishDate',
    'oldStatus',
    'newStatus'
  ];

  startDateFilter: string;
  finishDateFilter: string;

  ELEMENT_DATA: HRAHistory[] = [];
  dataSource: MatTableDataSource<HRAHistory>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  // --------------------------------------------------------------------------------------------------------------
  // Permisos
  // --------------------------------------------------------------------------------------------------------------
  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }
  // --------------------------------------------------------------------------------------------------------------

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private dataHistoryService: DataHistoryService,
    private hraDateFilterService: HRADateFilterService,
  ) {
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // EmployeeView
    this.subscriptions.push(
      this.dataHistoryService.employeeView.subscribe(data => {
        this.employeeView = data;
      })
    );

    // Employee
    this.subscriptions.push(
      this.dataHistoryService.employee.subscribe(data => {
        this.employee = data;
      })
    );

    // Employees
    this.subscriptions.push(
      this.dataHistoryService.employees.subscribe(data => {
        this.employees = data;
      })
    );

    // Absences
    this.subscriptions.push(
      this.dataHistoryService.absences.subscribe(data => {
        this.abseceHistory = data;

        this.loadDataOnTable();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hraDateFilterService.dates.subscribe(dates => {
      this.startDateFilter = dates.dataStartDate;
      this.finishDateFilter = dates.dataFinishDate;

      if (this.employee && this.employee.employeeId) {
        this.loadDataOnTable();
      }
    });
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  loadDataOnTable() {
    let filters: string = "absences";

    if (this.abseceHistory) {
      this.ELEMENT_DATA = this.abseceHistory;

      this.changeDateFormat(this.ELEMENT_DATA);

      this.dataSource = new MatTableDataSource<HRAHistory>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  changeDateFormat(ELEMENT_DATA) {
    for (let item of ELEMENT_DATA) {
      item.startDate = transformDate(item.startDate);
      item.finishDate = transformDate(item.finishDate);
      item.createdDate = transformDate(item.createdDate);
      if (item.approveDate != null) {
        item.approveDate = transformDate(item.approveDate);
      }
    }
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  getManager(employeeId: string) {
    let result = '';

    if (this.employees) {
      let employee = this.employees.find(element => element.employeeId == employeeId);
      result = (employee != null)
        ? `${employee.secondSurname} ${employee.firstSurname}, ${employee.name}`
        : "";
    }

    return result;
  }
  //#endregion
}
