import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { map, startWith } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as jsonpatch from 'fast-json-patch';

import { AccountService, BaseFormComponent, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';

import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';

import { ContractType } from 'src/app/models/contract-type.model';
import { ContractTypeService } from 'src/app/services/contract-type.service';
import { ContractTypeManagementInfo } from 'src/app/models/contract-type-management-info.model';
import { ContractDurationType } from 'src/app/models/contract-duration-type.model';
import { ContractWorkingHoursType } from 'src/app/models/contract-working-hours-type.model';
import { customStartsWith } from 'src/app/helpers/filter-data.helper';

@Component({
  selector: 'app-contract-type-form',
  templateUrl: './contract-type-form.component.html',
  styleUrls: [
    './contract-type-form.component.css',
    './../../maintenance.components.css',
  ],
})
export class ContractTypeFormComponent extends BaseFormComponent<ContractType> implements OnInit, OnDestroy {
  //#region ATRRIBUTES
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  selectedDesc: string = undefined;
  contractType: ContractType;
  contractTypeManagementInfo: ContractTypeManagementInfo;

  roles: Role[];

  canAdd = false;
  subscription: Subscription;

  filteredContractDurationTypes: Observable<ContractDurationType[]>;
  filteredContractWorkingHoursTypes: Observable<ContractWorkingHoursType[]>

  /* ****************************************************************************************************************** */
  /* ** INHERITED
  /* ****************************************************************************************************************** */
  enabled = true;
  detail = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  formGroupControl = new FormGroup({
    contractCode: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    governmentContractCode: new FormControl('', Validators.required),

    contractDurationType: new FormControl('', Validators.required),

    contractWorkingHoursType: new FormControl('', Validators.required),
  });
  //#endregion
  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private toastrService: ToastrService,
    private contractTypeService: ContractTypeService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ) {
      this.canAdd = true;
      this.canDelete = true;
    }
    this.PK = 'contractTypeId';

    this.baseRoute = '/maintenances/contract_types';

    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('CATEGORIES_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
    this.init();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          if (
            this.accountService.currentUser.isAdmin ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RA) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RP)
          ) {
          }
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  //#region DATA MANIPULATION
  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  private init() {
    this.disableForm();

    this.contractType = new ContractType();
    this.subscription = this.route.paramMap.subscribe(params => {
      const transId = params.get('id');
      const contractTypeId = (transId && transId != 'new')
        ? transId
        : '';

      this.contractTypeService.getManagementContractTypes(contractTypeId).subscribe(response => {
        this.contractTypeService.updateContractTypeRoutes(transId);
        this.contractTypeManagementInfo = response;
        this.addFilters()

        if (transId) {
          if (transId === 'new') {
            this.detail = false;
            this.enabled = true;
            this.contractType = new ContractType();
            this.selectedDesc = undefined;
            this.cleanForm();
            this.enableForm();
          } else {
              this.detail = true;
              this.enabled = false;
              this.contractType = response.contractType;

              this.disableForm();
              this.loadDataOnForm();
              this.selectedDesc = (response.contractType)
                ? response.contractType.contractCode
                : undefined;
          }
        } else {
          this.selectedDesc = undefined;
          this.detail = false;
          this.enabled = false;
          this.contractType = new ContractType();
        }
      });
    });
  }
  //#endregion

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  cleanForm() {
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      currentControl.enable();
    });
  }

  disableForm() {
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      currentControl.disable();
    });
  }

  enableForm() {
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      currentControl.enable();
    });
  }

  loadDataOnForm() {
    this.formGroupControl.setValue({
      contractCode              : this.contractType.contractCode,
      description               : this.contractType.description,
      governmentContractCode    : this.contractType.governmentContractCode,

      contractDurationType      : this.contractTypeManagementInfo.allContractDurationTypes.find(item => item.contractDurationTypeId == this.contractType.contractDurationTypeId),

      contractWorkingHoursType  : this.contractTypeManagementInfo.allContractWorkingHoursTypes.find(item => item.contractWorkingHoursTypeId == this.contractType.contractWorkingHoursTypeId),
    });
  }

  edit() {
    this.enabled = true;

    this.enableForm();
  }

  cancel() {
    this.cleanForm();
    this.disableForm();

    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }
  //#endregion
  //#endregion

  //#region CRUD
  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  /* ****************************************************************************************************************** */
  /* ** CREATE
  /* ****************************************************************************************************************** */
  create() {
    this.assignContractType();

    this.contractTypeService.createContractType(this.contractType).subscribe(
      response => {
        this.contractTypeService.getAllContractTypes().subscribe(contractTypes => {
          this.contractTypeService.updateContractTypes(contractTypes.items);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.CREATE_LONG', {
            type: this.componentTypeTranslated,
            contractCode: this.contractType.contractCode,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.cancel();
      },
      error => {
        if (error.status === 500 || error.status === 0) {
        } else if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
        }
      },
    );
  }

  /* ****************************************************************************************************************** */
  /* ** UPDATE
  /* ****************************************************************************************************************** */
  update() {
    const observer = jsonpatch.observe(this.contractType);
    this.assignContractType();

    const patch = jsonpatch.generate(observer);

    this.contractTypeService.modifyContractType(this.contractType.contractTypeId,patch).subscribe(
      response => {
        this.contractTypeService.getAllContractTypes().subscribe(contractTypes => {
          this.contractTypeService.updateContractTypes(contractTypes.items);
        });
        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );
  }

  /* ****************************************************************************************************************** */
  /* ** DELETE
  /* ****************************************************************************************************************** */
  delete() {
    let text = '';

    this.translate.get('CONTRACT_TYPE_MAINTENANCE.MESSAGE.DELETE_CONFIRMATION.TEXT', { value: this.contractType.contractCode }).subscribe((transitionStr: string) => {
      text = transitionStr;
    });

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      data: {
        text
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.contractTypeService.deleteContractType(this.contractType.contractTypeId).subscribe(response => {
          this.contractTypeService.getAllContractTypes().subscribe(contractTypes => {
            this.contractTypeService.updateContractTypes(contractTypes.items);
          });
          let toastTitle = '';
          let toastBody = '';
          this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {
            toastTitle = a;
          });
          this.translate.get('MESSAGES.SUCCESS.DELETE_LONG', {type: this.componentTypeTranslated, contractCode: this.contractType.contractCode}).subscribe((a: string) => {
            toastBody = a;
          });
          this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
          this.cancel();
        });
      }
    });
  }
  //#endregion

  //#region ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  assignContractType() {
    if (this.contractType == undefined || this.contractType == null) {
      this.contractType = new ContractType();
    }

    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];
      const temp = currentControl.value;

      if (temp != null && temp != '') {
        switch(name) {
          case 'contractDurationType':
            this.contractType['contractDurationTypeId'] = temp.contractDurationTypeId;
            break;
          case 'contractWorkingHoursType':
            this.contractType['contractWorkingHoursTypeId'] = temp.contractWorkingHoursTypeId;
            break;
          default:
            this.contractType[name] = temp;
            break;
        }
      }
    });
  }

  assignContractDurationType(e) {
    this.formGroupControl.get('contractDurationType').setValue(e.option.value);
  }

  displayContractDurationType(item: ContractDurationType): string | undefined {
    return (item)
      ? item.description
      : undefined;
  }

  assignContractWorkingHoursType(e) {
    this.formGroupControl.get('contractWorkingHoursType').setValue(e.option.value);
  }

  displayContractWorkingHoursType(item: ContractWorkingHoursType): string | undefined {
    return (item)
      ? item.description
      : undefined;
  }

  addFilters() {
    this.filteredContractDurationTypes = this.formGroupControl.controls['contractDurationType'].valueChanges.pipe(startWith(""), map(val =>
      this.contractTypeManagementInfo.allContractDurationTypes.filter(item =>
        customStartsWith(item.description, val)
      ))
    );

    this.filteredContractWorkingHoursTypes = this.formGroupControl.controls['contractWorkingHoursType'].valueChanges.pipe(startWith(""), map(val =>
      this.contractTypeManagementInfo.allContractWorkingHoursTypes.filter(item =>
        customStartsWith(item.description, val)
      ))
    );
  }
  //#endregion

  //#region OTHERS
  /* ****************************************************************************************************************** */
  /* ** OTHERS
  /* ****************************************************************************************************************** */
  back() {
    this.cancel();
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  //#endregion
}
