import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { Centre } from '../models/centre.model';


@Injectable({ providedIn: 'root' })
export class CentreService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private centreSubject: ReplaySubject<Centre[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public centreObservable: Observable<Centre[]> = this.centreSubject.asObservable();

    private centreRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public centreRouteObservable: Observable<string> = this.centreRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/centre';
    }

    constructor(private http: HttpClient) {
    }

    getAllCentres() {
        return this.http.get<any>(`${this.serviceUrl}`);
    }

    getCentre(centreId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<Centre>(`${this.serviceUrl}/${centreId}${relatedFilter}`);
    }

    createCentre(centre: Centre) {
        const endpointUrl = `${this.serviceUrl}`;
        return this.http.post(endpointUrl, centre);
    }
    modifyCentre(centreId: string, newCentre: any) {
        const endpointUrl = `${this.serviceUrl}/${centreId}`;
        return this.http.patch(endpointUrl, newCentre);
    }
    deleteCentre(centreId: string) {
        const endpointUrl = `${this.serviceUrl}/${centreId}`;
        return this.http.delete(endpointUrl);
    }
    reassociateCentre(centreId: string, employeeId: string) {
        const endpointUrl = `${this.serviceUrl}/${centreId}/Employee/${employeeId}`;
        return this.http.post(endpointUrl, null);
    }

    updateCentres(centres: Centre[]) {
        this.centreSubject.next(centres);
    }

    updateCentreRoutes(id: string) {
        this.centreRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}