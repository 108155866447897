export enum HolidayRequestStateCode {
  REGISTERED              = 0,
  PENDING_APROVAL         = 1,
  APPROVED                = 2,
  PENDING_ANNULLED        = 3,
  REJECTED                = 4,
  CANCELED                = 5,
  ANNULLED                = 6,
  PENDING_CHANGE_APROVAL  = 7,
}

export enum HolidayRequestStateColor {
  REGISTERED        = "#95a8d0",
  PENDING_APROVAL   = "#ffc107",
  PENDING_CHANGE_APROVAL          = "#28a746",
  APPROVED          = "#28a745",
  PENDING_ANNULLED  = "#cc0000",
  REJECTED          = "gray",
  CANCELED          = "gray",
  ANNULLED          = "gray",
}
