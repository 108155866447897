export class LaborAgreement {
  laborAgreementId        : string;
  dateValidity            : string;
  publicationDate         : string;
  code                    : string;
  description             : string;
  tgss                    : string;
  regimeCode              : number;
  regime                  : string;
  sectorCode              : number;
  sector                  : string;
  variableIncreaseArrears : number;
  integrationFactor       : number;
  document                : string;
  observations            : string;
  currency                : string;
  ordinance               : string;
  isLocked                : boolean;

  //Relations

  constructor(obj?: Partial<LaborAgreement>) {
    this.laborAgreementId         = (obj && obj.laborAgreementId) || undefined;
    this.dateValidity             = (obj && obj.dateValidity) || undefined;
    this.publicationDate          = (obj && obj.publicationDate) || undefined;
    this.code                     = (obj && obj.code) || undefined;
    this.description              = (obj && obj.description) || undefined;
    this.tgss                     = (obj && obj.tgss) || undefined;
    this.regimeCode               = (obj && obj.regimeCode) || undefined;
    this.regime                   = (obj && obj.regime) || undefined;
    this.sectorCode               = (obj && obj.sectorCode) || undefined;
    this.sector                   = (obj && obj.sector) || undefined;
    this.variableIncreaseArrears  = (obj && obj.variableIncreaseArrears) || undefined;
    this.integrationFactor        = (obj && obj.integrationFactor) || undefined;
    this.document                 = (obj && obj.document) || undefined;
    this.observations             = (obj && obj.observations) || undefined;
    this.currency                 = (obj && obj.currency) || undefined;
    this.ordinance                = (obj && obj.ordinance) || undefined;
    this.isLocked                 = (obj && obj.isLocked) || undefined;

    //Relations
  }
}
