import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { AbsenceManagementComponent } from '../absence-management.component';

@Component({
  selector: 'app-absence-management-reject-dialog',
  templateUrl: './absence-management-reject-dialog.component.html',
  styleUrls: ['./absence-management-reject-dialog.component.css']
})
export class AbsenceManagementRejectDialogComponent{
  formGroupControl: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AbsenceManagementComponent>,
  ) { }

  ngOnInit() {
    this.formGroupControl = new FormGroup({
      reason: new FormControl('', Validators.required),
    });
  }

  update() {
    this.dialogRef.close(this.formGroupControl.get('reason').value);
  }

}
