import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { EmployeeSituation } from '../models/employee-situation.model';

@Injectable({ providedIn: 'root' })
export class EmployeeSituationService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private employeeSubject: ReplaySubject<EmployeeSituation[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeObservable: Observable<EmployeeSituation[]> = this.employeeSubject.asObservable();

    private employeeRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeRouteObservable: Observable<string> = this.employeeRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/EmployeeSituation';
    }

    constructor(private http: HttpClient) {
    }

    getAllEmployeeSituation(filter?: string) {
      if (filter == undefined || filter == null) {
        filter = "";
      }

      let url = `${this.serviceUrl}${filter}`;

      return this.http.get<any>(url);
    }

    getEmployeeSituationsForEmployee(employeeId: string, filter?: string) {
      if (filter == undefined || filter == null) {
        filter = "";
      }
  
      const url = `${this.serviceUrl}/employee/${employeeId}${filter}`;
  
      return this.http.get<any>(url);
    }
  
    updateEmployeeSituations(employees: EmployeeSituation[]) {
        this.employeeSubject.next(employees);
    }

    updateEmployeeSituationsRoutes(id: string) {
        this.employeeRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}
