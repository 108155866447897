import { Country } from '../models/country.model';
import { Province } from '../models/province.model';

export function getCountryAlfa3(country: any): string | null {
  let result = null;

  if (country instanceof String) {
    result = country;
  } else if (country) {
    result = (country as Country).alfa3;
  }

  return result
}

export function getCountryName(country: any): string | null {
  let result = null;

  if (country instanceof String) {
    result = country;
  } else if (country) {
    result = (country as Country).name;
  }

  return result
}

export function getProvinceName(province: any): string | null {
  let result = null;

  if (province instanceof String) {
    result = province;
  } else if (province) {
    result = (province as Province).name;
  }

  return result
}

