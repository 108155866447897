import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { SBA } from '../models/sba.model';

@Injectable({ providedIn: 'root' })
export class SBAService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private sbaSubject: ReplaySubject<SBA[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public sbaObservable: Observable<SBA[]> = this.sbaSubject.asObservable();

  private sbaRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public sbaRouteObservable: Observable<string> = this.sbaRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/sba';
  }

  constructor(private http: HttpClient) {
  }

  getAllSBAs(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getSBA(sbaId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/${sbaId}${filter}`;

    return this.http.get<SBA>(url);
  }

  getSBAsForEmployee(employeeId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/employee/${employeeId}${filter}`;

    return this.http.get<any>(url);
  }

  createSBA(sba: SBA) {
    const url = `${this.serviceUrl}`;

    return this.http.post<SBA>(url, sba);
  }

  deleteSBA(sbaId: string) {
    const url = `${this.serviceUrl}/${sbaId}`;

    return this.http.delete(url);
  }

  modifySBA(sbaId: string, sba: any) {
    const url = `${this.serviceUrl}/${sbaId}`;

    return this.http.patch(url, sba);
  }

  hourCostCalculation(sba: SBA) {
    const url = `${this.serviceUrl}/HourCostCalculation`;

    return this.http.post(url, sba);
  }

  updateSBAs(sbas: any) {
    this.sbaSubject.next(sbas);
  }

  updateSBARoutes(id: string) {
    this.sbaRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
