import { Area } from "./area.model";
import { Employee } from "./employee.model";


export class Department {
  departmentId: string;
  name: string;
  description: string;

  //Relations
  employees: Employee[];

  areaId: string;
  area: Area;

  constructor(obj?: Partial<Department>) {
    this.departmentId = (obj && obj.departmentId) || undefined;
    this.name = (obj && obj.name) || undefined;
    this.description = (obj && obj.description) || undefined;

    //Relations
    this.employees = (obj && obj.employees) || [];

    this.areaId = (obj && obj.areaId) || undefined;
    this.area = (obj && obj.area) || undefined;
  }
}
