import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { CloseYearHistoryEvent } from 'src/app/interfaces/events.interface';
import { OperationsCYHistory } from 'src/app/models/close-year-history-enum.model';
import { CloseYearHistory } from 'src/app/models/close-year-history.model';
import { CloseYearHistoryDetailsDialogComponent } from '../close-year-history-details-dialog/close-year-history-details-dialog.component';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { CloseYearHistroyConfirmDialogComponent, ConfirmDialogType } from '../../close-year-histroy-confirm-dialog/close-year-histroy-confirm-dialog.component';

@Component({
  selector: 'app-close-year-history',
  templateUrl: './close-year-history.component.html',
  styleUrls: ['./close-year-history.component.css']
})
export class CloseYearHistoryComponent implements OnInit, AfterContentInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  @Input() displayedColumns: string[] = ['createdDate', 'createdBy', 'year', 'operation', 'log', 'modifiedRecords'];
  @Input() closeYearHistoryArray: CloseYearHistory[] = [];
  @Input() title: string = "";
  @Output() selectCloseYearHistory: EventEmitter<CloseYearHistoryEvent> = new EventEmitter();

  ELEMENT_DATA: CloseYearHistory[] = [];
  dataSource: MatTableDataSource<CloseYearHistory>;
  selectedCloseYearHistory: CloseYearHistory;

  enableCloseYear = false;
  enableCloseYearDetails = false;
  enableUndoCloseYear = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild("inputTableSearch", { static: false }) inputTableSearch: ElementRef;

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {}

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
  }

  ngAfterContentInit(): void {
    // Se usa el setTimeout() para dejar tiempo a que se cargue la vista
    setTimeout(() => {
      this.inputTableSearch.nativeElement.focus();

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }

  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  loadData() {
    this.ELEMENT_DATA = [];
    this.ELEMENT_DATA = this.closeYearHistoryArray;

    this.dataSource = new MatTableDataSource<CloseYearHistory>(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: CloseYearHistory, filter: string): boolean => {
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        return (currentTerm + (data as { [key: string]: any })[key] + '◬');
      }, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

      const transformedFilter = filter.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  /**
  * Devuelve el objeto CloseYearHistory selecionado.
  * @param closeYearHistory objeto CloseYearHistory selecionado
  */
  returnCloseYearHistory(closeYearHistory: CloseYearHistory): void {
    this.selectedCloseYearHistory = closeYearHistory;

    if (closeYearHistory.operation == OperationsCYHistory.WORKCALENDAR_CREATION && closeYearHistory.isLocked != true) {
      this.enableCloseYear = true;
      this.enableUndoCloseYear = false;

      this.enableCloseYearDetails = false;
    } else if (closeYearHistory.operation == OperationsCYHistory.CLOSE_YEAR_CREATION) {
      if (closeYearHistory.isLocked != true) {
        this.enableCloseYear = false;
        this.enableUndoCloseYear = true;
      }

      this.enableCloseYearDetails = true;
    }  else {
      this.enableCloseYear = false;
      this.enableUndoCloseYear = false;

      if (closeYearHistory.operation == OperationsCYHistory.CLOSE_YEAR_REVERSION) {
        this.enableCloseYearDetails = true;
      } else {
        this.enableCloseYearDetails = false;
      }
    }
  }

  createCloseYear() {
    const confirmWCRevert =  this.dialog.open(CloseYearHistroyConfirmDialogComponent, {
      disableClose: true,
      width: '550px',
      data: {
        confirmDialogType: ConfirmDialogType.CLOSEYEAR_GENERATE
      }
    });

    confirmWCRevert.afterClosed().subscribe(async (confirm) => {
      if (confirm === true) {
        this.enableCloseYear = false;

        this.selectCloseYearHistory.emit(
          {
            closeYearHistory: this.selectedCloseYearHistory,
            eventType: "CREATE"
          }
        );
      }
    });
  }

  closeYearDetails() {
    const confirmationDialog = this.dialog.open(CloseYearHistoryDetailsDialogComponent, {
      width: '1200px',
      data: {
        closeYearHistory: this.selectedCloseYearHistory,
      }
    });
    confirmationDialog.afterClosed().subscribe(async (confirm) => {});
  }

  reversionCloseYear() {
    const confirmWCRevert =  this.dialog.open(CloseYearHistroyConfirmDialogComponent, {
      disableClose: true,
      width: '550px',
      data: {
        confirmDialogType: ConfirmDialogType.CLOSEYEAR_REVERSION
      }
    });

    confirmWCRevert.afterClosed().subscribe(async (confirm) => {
      if (confirm === true) {
        this.enableUndoCloseYear = false;

        this.selectCloseYearHistory.emit(
          {
            closeYearHistory: this.selectedCloseYearHistory,
            eventType: "REVERSION"
          }
        );
      }
    });
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  getCreatedBy(element: CloseYearHistory) {
    let result = "";

    if (element != null && element.createdByUser != undefined && element.createdByUser != null) {
      result = `${element.createdByUser.name} ${element.createdByUser.firstSurname} ${element.createdByUser.secondSurname}`;
    }

    return result;
  }

  /**
   * Selecciona las propiedades del estilo adecuados al estado del item
   */
  getSyleItem(closeYearHistory: CloseYearHistory) {
    let style = {
      'color': 'inherit'
    };

    if (((this.selectedCloseYearHistory != undefined || this.selectedCloseYearHistory != null) && this.selectedCloseYearHistory == closeYearHistory)) {
      style = {
        'color': '#009900'
      };
    }

    return style;
  }

  /**
   * Devuelve el string correspondiente al código de operaicón pasado.
   *
   * @param operation código de la operación
   * @returns un string
   */
  getOperationString(operation: number) {
    const operationStringCode = OperationsCYHistory[operation];
    let result = operationStringCode;

    this.translate.get(`CLOSEYEAR_HISTORY.OPERATIONS.${operationStringCode}`).subscribe((str) => {
      result = str;
    });

    return result;
  }
}
