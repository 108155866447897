import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { WCEventType } from '../models/wc-event-type.model';


@Injectable({ providedIn: 'root' })
export class WCEventTypeService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private wcEventSubject: ReplaySubject<WCEventType[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public wcEventObservable: Observable<WCEventType[]> = this.wcEventSubject.asObservable();

    private wcEventRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public wcEventRouteObservable: Observable<string> = this.wcEventRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/WCEventType';
    }

    constructor(private http: HttpClient) {
    }

    getAllWCEventType() {
        return this.http.get<any>(`${this.serviceUrl}`);
    }

    getWCEventType(wcEventId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<WCEventType>(`${this.serviceUrl}/${wcEventId}${relatedFilter}`);
    }
}