import { FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export function BankAccountFormValidator(): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    let isValid = false;
    const IBAN = form.controls["bankAccount"].value;

    if (IBAN) {
      if (IBAN.length > 0) {
        isValid = fn_ValidateIBAN(IBAN);
      }
    } else {
      isValid = true;
    }

    (isValid)
      ? form.controls["bankAccount"].setErrors(null)
      : form.controls["bankAccount"].setErrors({ invalidBankAccount: true });

    return null;
  }
}

function fn_ValidateIBAN(IBAN) {
    IBAN = IBAN.toUpperCase();
    //Se quita los blancos de principio y final.
    IBAN = IBAN.trim();
    IBAN = IBAN.replace(/\s/g, ""); //Y se quita los espacios en blanco dentro de la cadena

    var firstChar, secondChar, firstNum, secondNumber,remainer;
    var isbanaux;
    //La longitud debe ser siempre de 24 caracteres
    if (IBAN.length != 24) {
        return false;
    }

    // Se coge las primeras dos letras y se pasan a números
    firstChar = IBAN.substring(0, 1);
    secondChar = IBAN.substring(1, 2);
    firstNum = getnumIBAN(firstChar);
    secondNumber = getnumIBAN(secondChar);
    //Se sustituye las letras por números.
    isbanaux = String(firstNum) + String(secondNumber) + IBAN.substring(2);
    // Se mueve los 6 primeros caracteres al final de la cadena.
    isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);

    //Se calcula el resto, llamando a la función modulo97, definida más abajo
    remainer = module97(isbanaux);
    if (remainer == 1) {
        return true;
    } else {
        return false;
    }
}

function module97(iban) {
    var parts = Math.ceil(iban.length / 7);
    var remainer = "";

    for (var i = 1; i <= parts; i++) {
        remainer = String(parseFloat(remainer + iban.substr((i - 1) * 7, 7)) % 97);
    }

    return remainer;
}

function getnumIBAN(char) {
    var ls_chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return ls_chars.search(char) + 10;
}
