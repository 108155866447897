import { Component, OnInit, ElementRef, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AbsenceGroupsItems, AbsenceStateLabel } from 'src/app/interfaces/absence.interface';
import { AbsenceEvent } from 'src/app/interfaces/events.interface';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { AbsenceStateCode } from 'src/app/models/absence-state-code-enum.model';
import { AbsenceState } from 'src/app/models/absence-state.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { AbsenceChangesService } from 'src/app/services/absence-changes.service';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { AbsenceManagementCalendarComponent } from '../absence-management-calendar/absence-management-calendar.component';
import { Role } from 'inzo-portalempleado';

// ################################################################################################################
// ## CLASS AbsenceManagementTabsComponent
// ################################################################################################################
@Component({
  selector: 'app-absence-management-tabs',
  templateUrl: './absence-management-tabs.component.html',
  styleUrls: [
    './absence-management-tabs.component.css',
    '../../maintenance.components.css',
    '../../maintenance-list.component.css',
  ],
})
export class AbsenceManagementTabsComponent implements OnInit {
  //#region ARGUMENTS
  // ##############################################################################################################
  // ## Arguments
  // ##############################################################################################################
  @Input() absencesGroups: AbsenceGroupsItems[] = [];
  @Input() absenceStates: AbsenceState[] = [];

  @Input() visibleStatesCalendar: AbsenceStateCode[] = [];

  @Input() filtersApi: FiltersApi = new FiltersApi();

  absenceStatesTabs: AbsenceStateLabel[]= [];
  tabStates: string[] = [];
  defaultTabState: string = "PENDING";
  selectedTab: number = 0;

  searching = true;
  filter = '';

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  @Input() calendar: AbsenceManagementCalendarComponent;
  @Output() actionAbsence: EventEmitter<AbsenceEvent> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();

  subscriptions: Subscription[] = [];

  // Permissions
  employeeView : boolean = false;
  canDirectAssignment: boolean = false;
  directAssignment : boolean = false;

  roles: Role[];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }
  //#endregion

  //#region CONSTRUCTOR
  // ##############################################################################################################
  // ## Constructor
  // ##############################################################################################################
  constructor(
    private translate: TranslateService,
    protected absenceChangesService: AbsenceChangesService,
    protected permissionsService: PermissionsService,
  ) { }
  //#endregion

  //#region ANGULAR METHODS
  // ##############################################################################################################
  // ## Angular Methods
  // ##############################################################################################################
  ngOnInit() {
    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;

        if (this.userRolesPermissions && this.userRolesPermissions.isRRHH && !this.employeeView) {
          this.directAssignment = true;

          this.canDirectAssignment = true;
        }

        this.setRequestStates();

        if (this.employeeView === false && this.absenceStatesTabs) {
          this.selectedTab = this.getDefaultTabIndex();

          this.loadTabData(this.selectedTab);
        }
      })
    );

    // roles
    this.subscriptions.push(
      this.permissionsService.roles.subscribe(data => {
        this.roles = data;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  //#region CUSTOM METHODS
  // ##############################################################################################################
  // ## Custom Methods
  // ##############################################################################################################
  //#endregion

  //#region UTILS
  // ##############################################################################################################
  // ## Utils
  // ##############################################################################################################
  refresh() {
    this.refreshData.emit(true);
  }

  /**
   * Recoge un evento del componente de listado de solicitudes y ejecuta la acción correspondiente a ese evento.
   *
   * @param absenceEvent evento recogido
   */
  actionAbsenceReply(absenceEvent: AbsenceEvent) {
    this.actionAbsence.emit(absenceEvent);
  }

  /**
   * Devuelve el nombre del estado con valor state
   * @param stateCode código de estado
   */
  getStateName(stateCode) {
    return AbsenceStateCode[stateCode];
  }

  /**
   * Devuelve el de solicitudes que se debe mostrar en las pestañas
   *
   * @param stateCode código de estado
   */
  getTabCount(absencesGroup: any): string {
    let result = ``;
    let countGroups = absencesGroup.length;
    let countRequest = 0;

    for (let item in absencesGroup) {
      countRequest += absencesGroup[item].length;
    }

    if (this.employeeView) {
      result += `${countRequest}`;
    } else {
      // result += `${countGroups} - ${countRequest}`;
      result += `${countGroups}`;
    }

    return result;
  }

  loadTabData(index) {
    this.selectedTab = index;

    if (this.employeeView === false) {
      const currentTab = this.absenceStatesTabs[index];

      this.absenceChangesService.onChangeSelectedStateRequest(currentTab);
    }
  }

  getDefaultTabIndex(): number {
    let result = this.absenceStatesTabs.findIndex(element => element.stateName == this.defaultTabState);

    return (result > -1)
      ? result
      : 0;
  }

  /**
   * Selecciona los estados que se deben manejar y los que se deben mostrar.
   */
  setRequestStates() {
    this.tabStates = [
      "REGISTERED",
      "PENDING",
      "APPROVED",
      "REJECTED",
      "ANNULLED",
      "ALL",
    ];

    this.setTabNames();
  }

  setTabNames() {
    this.absenceStatesTabs = [];

    for (let item of this.tabStates) {
      let textTab = "";

      this.translate.get(`ABSENCE_STATES.${item}`).subscribe((a: string) => {
        textTab = a;
      });

      this.absenceStatesTabs.push( {
        alias: textTab,
        stateName: item
      });
    }
  }
  //#endregion
}
