import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import {
  ResponseNotificationService,
  BaseListComponent,
  SecurityStateManagementService,
  AccountService,
  Hotkeys,
  Role,
} from "inzo-portalempleado";
import { AppPermissions } from "src/app/models/app-permission.model";
import { NgxSpinnerService } from "ngx-spinner";
import { IRPF } from "src/app/models/irpf.model";
import { IRPFService } from "src/app/services/irpf.service";
import { EmployeeService } from "src/app/services/employee.service";
import { EmployeeManagementService } from "src/app/services/employee-management.service";
import { FiltersApi } from "../../../models/filters-api.model";
import { FileService } from "src/app/services/file.service";
import { FormControl } from "@angular/forms";
import { EmployeeDocument } from "src/app/models/employee-document.model";

@Component({
  selector: "app-personal-irpf-list",
  templateUrl: "personal-irpf-list.component.html",
  styleUrls: [
    "./../../maintenance.components.css",
    "../../maintenance-list.component.css",
  ],
})
export class PersonalIRPFListComponent
  extends BaseListComponent<IRPF>
  implements OnInit, OnDestroy
{
  irpfs: IRPF[] = [];
  subscription: Subscription;
  searching = true;
  filter = "";
  mediaSize: string;
  watcher: Subscription;
  roles: Role[];

  filtersApi: FiltersApi = new FiltersApi();

  canAdd = false;
  canDelete = false;

  @ViewChild("inputsearch", { static: false }) inputSearch: ElementRef;

  constructor(
    private fileService: FileService,
    private irpfService: IRPFService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
    private employeeManagementService: EmployeeManagementService
  ) {
    super(accountService, SSMService, router, hotkeys);
    this.baseRoute = "/documents/personalIRPF";
    this.viewPermission = [
      AppPermissions.PERMISSIONS_DATA.RRHH.value,
      AppPermissions.PERMISSIONS_DATA.Manager.value,
      AppPermissions.PERMISSIONS_DATA.Employee.value,
    ];
    this.createPermission = [AppPermissions.PERMISSIONS_DATA.RRHH.value];
    this.deletePermission = [AppPermissions.PERMISSIONS_DATA.RRHH.value];
    if (
      this.accountService.userHasPermission(
        AppPermissions.PERMISSIONS_DATA.RRHH.value
      )
    ) {
    }

    this.onBuild = () => {};

    this.filtersApi.add({
      field: "related",
      value: "true",
    });

    this.loadData = () => {
      this.spinner.show();

      this.irpfService
        .getAllIRPFsByEmployee(this.filtersApi.getStrinFilterApi())
        .subscribe(
          (acActividad) => {
            this.irpfService.updateIRPFs(acActividad.items);
            this.irpfs = acActividad.items;
            this.spinner.hide();
          },
          (error) => {
            this.RNService.showError(error);
          }
        );
    };

    this.onInit = () => {
      this.loadData();

      this.irpfService.irpfObservable.subscribe((arrayacActividad) => {
        this.irpfs = arrayacActividad;
      });
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  downloadDocument(document: EmployeeDocument) {
    this.fileService.getFile(document.fileId).subscribe((response) => {
      this.fileService.downloadBase64File(response);
    });
  }

  openDocument(document: EmployeeDocument) {
    this.fileService.getFile(document.fileId).subscribe((response) => {
      this.fileService.openFile(response);
    });
  }
}
