import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Department } from 'src/app/models/department.model';
import { Subscription } from 'rxjs';
import { DepartmentService } from 'src/app/services/department.service';
import { BaseFormComponent, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import * as jsonpatch from 'fast-json-patch';
import { Area } from 'src/app/models/area.model';
import { AreaService } from 'src/app/services/area.service';
import { DepartmentConfirmationDeleteDialogComponent } from '../department-confirmation-delete-dialog/department-confirmation-delete-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-department-form',
  templateUrl: 'department-form.component.html',
  styleUrls: ['./department-form.component.css','./../../maintenance.components.css'],
})
export class DepartmentFormComponent extends BaseFormComponent<Department> implements OnInit, OnDestroy {
  selectedDesc: string = undefined;
  department: Department;
  allAreas: Area[];

  enabled = true;
  detail = false;

  roles: Role[];

  formGroupControl = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    area: new FormControl(''),
  });

  canAdd = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;
  subscription: Subscription;

  constructor(
    private toastrService: ToastrService,
    private departmentService: DepartmentService,
    private areaService: AreaService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super(accountService, router, hotkeys);
    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
    }
    this.PK = 'departmentId';

    this.baseRoute = '/maintenances/department';


    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('DEPARTMENTS_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  private init() {
    this.formGroupControl.get('name').disable();
    this.formGroupControl.get('description').disable();
    this.formGroupControl.get('area').disable();
    this.department = new Department();
    this.subscription = this.route.paramMap.subscribe(params => {
      this.areaService.getAllArea().subscribe(areasResponse => {
        this.allAreas = areasResponse.items;
        const transId = params.get('id');
        this.departmentService.updateDepartmentRoutes(transId);
        if (transId) {
          if (transId === 'new') {
            this.detail = false;
            this.enabled = true;
            this.department = new Department();
            this.selectedDesc = undefined;
            this.formGroupControl.setValue({ name: '', description: '', area: '' });
            this.formGroupControl.get('name').enable();
            this.formGroupControl.get('description').enable();
            this.formGroupControl.get('area').enable();
          } else {
            this.departmentService.getDepartment(transId,false).subscribe(response => {
              this.detail = true;
              this.enabled = false;
              this.department = response;
              const selectedArea = this.allAreas.find(area => area.areaId === this.department.areaId);
              if (selectedArea) {
                this.formGroupControl.setValue({ name: this.department.name, description: this.department.description, area: selectedArea });
              } else {
                this.formGroupControl.setValue({ name: this.department.name, description: this.department.description, area: '' });
              }
              this.formGroupControl.get('name').disable();
              this.formGroupControl.get('description').disable();
              this.formGroupControl.get('area').disable();
              this.selectedDesc = response.name;
            });
          }
        } else {
          this.selectedDesc = undefined;
          this.detail = false;
          this.enabled = false;
          this.department = new Department();
        }
      });
    });
  }

  edit() {
    this.enabled = true;
    // this.formGroupControl.get('name').enable();
    this.formGroupControl.get('description').enable();
    this.formGroupControl.get('area').enable();
  }

  cleanForm() {
    this.formGroupControl.setValue({ name: '', description: '', area: '' });
  }

  assingArea(e) {
    this.formGroupControl.get('area').setValue(e.option.value);
  }

  displayArea(area: Area): string | undefined {
    return (area) ? area.description : undefined;
  }

  // ======================== CRUD ========================

  create() {
    this.department = new Department({
      name: this.formGroupControl.get('name').value,
      description: this.formGroupControl.get('description').value,
      areaId: this.formGroupControl.get('area').value.areaId,
    });
    this.departmentService.createDepartment(this.department).subscribe(
      response => {
        this.departmentService.getAllDepartments().subscribe(departments => {
          this.departmentService.updateDepartments(departments.items);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.CREATE_LONG', {
            type: this.componentTypeTranslated,
            code: this.department.name,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.cancel();
      },
      error => {
        if (error.status === 500 || error.status === 0) {
        } else if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
        }
      },
    );
  }

  update() {
    const observer = jsonpatch.observe(this.department);
    this.department.name = this.formGroupControl.get('name').value;
    this.department.description = this.formGroupControl.get('description').value;
    this.department.areaId = this.formGroupControl.get('area').value.areaId;
    const patch = jsonpatch.generate(observer);

    this.departmentService.modifyDepartment(this.department.departmentId, patch).subscribe(
      response => {
        this.departmentService.getAllDepartments().subscribe(departments => {
          this.departmentService.updateDepartments(departments.items);
        });
        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );
  }

  delete() {
    const confirmationDialog = this.dialog.open(DepartmentConfirmationDeleteDialogComponent, {
      width: '550px',
      data: {
        departmentName: this.department.name
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.departmentService.deleteDepartment(this.department.departmentId).subscribe(response => {
          this.departmentService.getAllDepartments().subscribe(departments => {
            this.departmentService.updateDepartments(departments.items);
          });
          let toastTitle = '';
          let toastBody = '';
          this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {
            toastTitle = a;
          });
          this.translate
            .get('MESSAGES.SUCCESS.DELETE_LONG', {
              type: this.componentTypeTranslated,
              name: this.department.name,
            })
            .subscribe((a: string) => {
              toastBody = a;
            });
          this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
          this.cancel();
        });
      }
    });
  }

  cancel() {
    this.cleanForm();
    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }

  back() {
    this.cancel();
  }
}
