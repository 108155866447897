//#region GENERIC IMPORTS
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { forkJoin, Subscription, BehaviorSubject, Observable } from 'rxjs';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatDialog, MatHorizontalStepper } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { STEPPER_GLOBAL_OPTIONS, STEP_STATE } from '@angular/cdk/stepper';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as jsonpatch from 'fast-json-patch';
import * as _ from 'lodash';
import moment = require('moment');
//#endregion

//#region CUSTOM IMPORTS
import { Address } from 'src/app/models/address.model';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { Area } from 'src/app/models/area.model';
import { BankAccountFormValidator } from 'src/app/helpers/accountBankValidator';
import { BaseFormComponent, AccountService, Hotkeys, UserEdit, User, Permission, Role, Permissions } from 'inzo-portalempleado';
import { Category } from '../../../models/category.model';
import { Centre } from 'src/app/models/centre.model';
import { checkFormDate, compare2Dates, getDateTimeString } from 'src/app/helpers/date.helper';
import { CommonResources } from 'src/app/models/common-resources';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { Country } from 'src/app/models/country.model';
import { Course } from 'src/app/models/course.model';
import { CourseService } from 'src/app/services/course.service';
import { Degree } from 'src/app/models/degree.model';
import { DegreeService } from 'src/app/services/degree.service';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeHistory } from 'src/app/models/employee-history.model';
import { EmployeeHistoryDlgComponent } from '../../employee-history/employee-history-dialog/employee-history-dialog.component';
import { EmployeeHistoryFilterRangueDialogComponent } from '../../employee-history/employee-history-filter-rangue-dialog/employee-history-filter-rangue-dialog.component';
import { EmployeeManagementChangesService } from 'src/app/services/employee-management-changes.service';
import { EmployeeManagementChangeStateDialogComponent } from '../employee-mgnt-change-state-dialog/employee-mgnt-change-state-dialog.component';
import { EmployeeManagementDeleteConfirmDialogComponent } from '../employee-management-delete-confirm-dialog/employee-management-delete-confirm-dialog.component';
import { EmployeeManagementInfo } from '../../../models/employee-management-info.model';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { EmployeeSelectAssociateConfirmDialogComponent } from '../../employee-select-associate-confirm-dialog/employee-select-associate-confirm-dialog.component';
import { EmployeeService } from 'src/app/services/employee.service';
import { EmployeeSituationCode } from 'src/app/models/employee-situation-enum.model';
import { EmployeeType } from 'src/app/models/employee-type.model';
import { Headquarters } from 'src/app/models/headquarters.model';
import { HRADateFilterService } from 'src/app/services/hra-date-filter.service';
import { IdentificationDocumentFormValidator } from 'src/app/helpers/identificationDocumentValidator';
import { Language } from 'src/app/models/language.model';
import { LanguageService } from 'src/app/services/language.service';
import { SBA } from 'src/app/models/sba.model';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { EmployeeContract } from 'src/app/models/employee-contract.model';
import { ContractType } from 'src/app/models/contract-type.model';
import { LaborAgreement } from 'src/app/models/labor-agreement.model';
import { customContains, customStartsWith } from 'src/app/helpers/filter-data.helper';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { DataChangesService } from 'src/app/modules/employee/services/data-changes.service';
import { ClonerService } from 'src/app/helpers/clone-object.helper';
import { EmployeeHistoryService } from 'src/app/services/employee-history.service';
import { EmployeeSituation } from 'src/app/models/employee-situation.model';
import { DataHistoryService } from 'src/app/modules/employee/services/data-history.service';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
//#endregion

interface IFormEdit {
  ProfileFormGroup: string[];
  HomeAddressFormGroup: string[];
  CompanyFormGroup: string[];
  FormationsFormGroup: string[];
  ContractualDataFormGroup: string[];
  EmployeeContractTypeDataFormGroup: string[];
  SbaDataFormGroup: string[];
}

@Component({
  selector: 'app-employeeManagement-form',
  templateUrl: 'employeeManagement-form.component.html',
  styleUrls: [
    './employeeManagement-form.component.css',
    './../../maintenance.components.css'
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: CommonResources.CUSTOM_DATE_FORMAT },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } },
  ],
})
export class EmployeeManagementFormComponent extends BaseFormComponent<Employee> implements OnInit, OnDestroy {
  //#region ATRRIBUTES
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  //#region CUSTOM
  HISTORY_STEP = 4;
  adminPermissions: string[] = [
    "roles.assign",
    "users.update",
    "users.view",
  ];
  managerPermissions: string[] = [
    "users.update",
    "users.view",
  ];

  employeeView: boolean = false;
  employeeManagementInfo: EmployeeManagementInfo;

  employee: Employee;
  currentemployeeSituation: string = '';
  employeeSituations: EmployeeSituation[] = [];
  employeeCopy: Employee;

  homeAddress: Address;
  employeeContract: EmployeeContract;
  sba: SBA;

  permissionList: Permission[];
  userPermission: Permission;

  backForm = true;
  userEdit: UserEdit;

  // --------------------------------------------------------------------------------------------------------------
  // Permisos
  // --------------------------------------------------------------------------------------------------------------
  allUsers: User[];
  roles: Role[];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  // --------------------------------------------------------------------------------------------------------------

  userActual: User;
  // --------------------------------------------------------------------------------------------------------------

  loadEmployeeData;
  disabilityChecked;
  enabled = true;
  detail = false;
  deleted = false;
  permissionsLoaded = false;
  visible = true;
  userFilter = '';
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  workcalendarCtrl = new FormControl();
  filteredAreas: Observable<Area[]>;
  filteredCategories: Observable<Category[]>
  filteredCentres: Observable<Centre[]>;
  filteredContractTypes: Observable<ContractType[]>;
  filteredEmployeeTypes: Observable<EmployeeType[]>;
  filteredHeadquarters: Observable<Headquarters[]>;
  filteredLaborAgreements: Observable<LaborAgreement[]>;
  filteredManagers: Observable<Employee[]>;
  filteredUsers: Observable<User[]>;
  filteredValidators: Observable<Employee[]>;
  filteredWorkcalendars: Observable<WorkCalendar[]>;

  // --------------------------------------------------------------------------------------------------------------
  loadFormationsData;

  // CV
  addCV: boolean = false;

  // COURSES
  addCourse: boolean = false;
  certificationCoursesId = [];
  coursesId = [];
  certificationCourseByEmployee: Course[] = [];
  courseByEmployee: Course[] = [];
  filteredCourses: Observable<Course[]>;
  filteredCertificationCourses: Observable<Course[]>;

  // DEGREES
  addDegree: boolean = false;
  degreesId = [];
  degreeByEmployee = [];
  filteredDegrees: Observable<Degree[]>;
  filteredDegreesCode: Observable<Degree[]>;

  // LANGUAGES
  addLanguage: boolean = false;
  languagesId = [];
  languageByEmployee = [];
  filteredLanguages: Observable<Language[]>;
  // --------------------------------------------------------------------------------------------------------------

  filteredCountries: Observable<Country[]>;

  editable: boolean = false;

  disabilityPercentages = CommonResources.DISABILITY_PERCENTAGES;
  identiDocumTypes = CommonResources.IDENTIFICATION_DOCUMENT_TYPES;
  genders = CommonResources.GENDERS;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  filter = '';

  /*@core.ViewChild('workcalendarInput') workcalendarInput: core.ElementRef<HTMLInputElement>;
  @core.ViewChild('auto') matAutocomplete: MatAutocomplete;*/

  canAdd = false;
  subscriptions: Subscription[] = [];

  @ViewChild('stepper', { static: false }) stepper: MatHorizontalStepper;

  showEmployeeSituation: boolean = false;
  employeeSituationBtnColor: string = 'primary';
  employeeSituationBtnString: string = '';
  //#endregion

  //#region INHERITED
  /* ****************************************************************************************************************** */
  /* ** INHERITED
  /* ****************************************************************************************************************** */
  canUpdate = false;
  canDelete = false;
  createMode = false;

  componentTypeTranslated: string;
  //#endregion
  //#endregion

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  RAEdit: IFormEdit = {
    ProfileFormGroup: [
      "companyEmail",
      "companyPhone",
    ],
    HomeAddressFormGroup: [],
    CompanyFormGroup: [
      "validator",
    ],
    FormationsFormGroup: [],
    ContractualDataFormGroup: [],
    EmployeeContractTypeDataFormGroup: [],
    SbaDataFormGroup: [],
  }
  RPEdit: IFormEdit = {
    ProfileFormGroup: [
      "companyEmail",
      "companyPhone",
    ],
    HomeAddressFormGroup: [],
    CompanyFormGroup: [],
    FormationsFormGroup: [],
    ContractualDataFormGroup: [],
    EmployeeContractTypeDataFormGroup: [],
    SbaDataFormGroup: [],
  }

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    protected permissionsService: PermissionsService,
    private employeeHistoryService: EmployeeHistoryService,
    private employeeMgntService: EmployeeManagementService,
    private simpleEmployeeService: EmployeeService,
    private employeeDataService: EmployeeService,
    private employeeManagementChanges: EmployeeManagementChangesService,
    private hraDateFilterService: HRADateFilterService,
    public accountService: AccountService,
    private dataChangesService: DataChangesService,
    private dataHistoryService: DataHistoryService,
    private toastrService: ToastrService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,

  ) {
    super(accountService, router, hotkeys);

    this.loadEmployeeData = forkJoin({
      usersVal: accountService.getUsers(),
    });

    this.permissionList = [
      new Permission("Employee", "Employee", "Employee Permissions", "Permiso para empleado"),
      new Permission("Manager", "Manager", "Manager Permissions", "Permiso para manager"),
      new Permission("RRHH", "RRHH", "RRHH Permissions", "Permiso para rrhh")
      //new Permission("users.view", "RRHH", "RRHH Permissions", "Permiso para rrhh")
    ];

    /*this.filteredWorkcalendars = this.workcalendarCtrl.valueChanges.pipe(
      startWith(null),
      map((workCalendar: WorkCalendar | null) => workCalendar ? this._filter(workCalendar.name) : this.allWorkcalendars.slice()));
    */
    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
    ];

    this.detail = false;

    this.PK = 'employeeId';

    this.baseRoute = '/managements/employeeManagement';

    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    // onInit to ngOnInit
    this.onInit = this.init;

    this.translate.get('EMPLOYEE_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;

        if (this.userRolesPermissions) {
          this.assignPermissions();
          this.init();
        }
      })
    );

    // Roles
    this.subscriptions.push(
      this.permissionsService.roles.subscribe(data => {
        this.roles = data;
      })
    );

    // Employee
    this.subscriptions.push(
      this.dataHistoryService.employee.subscribe(data => {
        this.employee = data;

        if (this.employee) {
          this.getEmployeeSituation();
          this.getEmployeeSituationBtnString();
          this.getEmployeeSituationBtnColor();
          this.showEmployeeSituationBtn();
        }
      })
    );

    this.employeeManagementChanges.canEdit.subscribe(canEdit => {
      this.editable = canEdit;
    });
    this.subscriptions.push(
      this.dataChangesService.employee.subscribe(data => {
        this.employeeCopy = data;
      })
    );

    this.subscriptions.push(
      this.dataChangesService.homeAddress.subscribe(data => {
        this.homeAddress = data;
      })
    );

    this.subscriptions.push(
      this.dataChangesService.employeeContract.subscribe(data => {
        this.employeeContract = data;
      })
    );

    this.subscriptions.push(
      this.dataChangesService.sba.subscribe(data => {
        this.sba = data;
      })
    );
  }

  ngOnDestroy() {
    this.dataChangesService.resetEmployeeData(this.employeeManagementInfo);
    this.dataHistoryService.resetAllObservables();

    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  assignPermissions() {
    if (
      this.userRolesPermissions.isAdmin ||
      this.userRolesPermissions.isRRHH ||
      this.userRolesPermissions.isRA ||
      this.userRolesPermissions.isRP
    ) {
      this.enabled = true;
    }

    if (
      this.userRolesPermissions.isAdmin ||
      this.userRolesPermissions.isRRHH
    ) {
      this.canAdd = true;
      this.canDelete = true;
    }
  }
  //#endregion

  //#region DATA MANIPULATION
  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  private init() {
    this.spinner.show();

    // Se resetea el valor predterminado
    this.dataChangesService.setResetFormDirty(false);

    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        const transId = params.get('id');

        if (transId) {
          this.subscriptions.push(
            this.employeeMgntService.getManagementInfo(transId).subscribe(employeeResponse => {
              this.employeeMgntService.updateEmployeeManagementRoutes(transId);
              let employeeManagementInfoCopy: EmployeeManagementInfo = ClonerService.deepClone(employeeResponse);
              this.createMode = false;
              let editMode = false;
              let homeAddress = new Address();

              this.loadEmployeeData.subscribe(data => {
                this.allUsers = data.usersVal;
                this.dataChangesService.setAllUsersChange(data.usersVal);

                if (transId === 'new') {
                  this.employee = null;
                  employeeManagementInfoCopy.employee = new Employee();

                  this.detail = false;
                  this.enabled = true;
                  editMode = true;
                  this.createMode = true;
                } else {
                  this.detail = true;
                  this.deleted = false;
                  this.addCourse = false;
                  this.addDegree = false;
                  this.addLanguage = false;
                  this.editable = false;

                  this.userPermission = null;

                  if (employeeManagementInfoCopy.employee) {
                    homeAddress = ClonerService.deepClone(employeeManagementInfoCopy.employee.homeAddress);
                  }

                  if (employeeManagementInfoCopy.employee && employeeManagementInfoCopy.employee.sba) {
                    employeeManagementInfoCopy.employee.sba.costHour = Number(employeeManagementInfoCopy.employee.sba.costHour.toFixed(2));
                    employeeManagementInfoCopy.employee.sba.costHourManual = (employeeManagementInfoCopy.employee.sba.costHourManual)
                      ? Number(employeeManagementInfoCopy.employee.sba.costHourManual.toFixed(2))
                      : null;
                  }
                }

                this.employeeManagementInfo = employeeManagementInfoCopy;
                this.dataChangesService.setEmployeeManagementInfoChange(employeeManagementInfoCopy);
                // Se hacen copias de los datos para comparar los cambios con los originales y generar los objetos Http Patch
                this.employee = ClonerService.deepClone(employeeManagementInfoCopy.employee);

                this.employeeCopy = ClonerService.deepClone(employeeManagementInfoCopy.employee);
                this.dataChangesService.setEmployeeChange(this.employeeCopy);
                this.dataChangesService.setHomeAddressChange(homeAddress);
                this.dataChangesService.setEmployeeContractChange((employeeManagementInfoCopy.employee) ? ClonerService.deepClone(employeeManagementInfoCopy.employee.employeeContract) : new EmployeeContract());
                this.dataChangesService.setSbaChange((employeeManagementInfoCopy.employee) ? ClonerService.deepClone(employeeManagementInfoCopy.employee.sba) : new SBA());

                this.dataChangesService.setCreateModeChange(this.createMode);
                this.dataChangesService.setEditModeChange(editMode);

                this.employeeSituations = employeeManagementInfoCopy.allEmployeeSituations;

                // Se actualiza la situación del empleado
                this.getEmployeeSituation();
                this.getEmployeeSituationBtnString();
                this.getEmployeeSituationBtnColor();
                this.showEmployeeSituationBtn();

                this.spinner.hide();
              });
            },
            error => {
              this.spinner.hide();
              this.dataChangesService.getFormData(false);
              if (error.status === 500 || error.status === 0) {
                let title: string;
                this.translate.get('MESSAGES.ERROR.CRITICAL_BODY').subscribe((a: string) => {
                  title = a;
                  this.toastrService.error(error.error, title, { timeOut: 3000 });
                });
              } else if (error.status === 409) {
                let title: string;
                this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
                  title = a;
                  this.toastrService.error(error.error, title, { timeOut: 3000 });
                });
              } else {
                let title: string;
                this.translate.get('MESSAGES.ERROR.UNEXPECTED').subscribe((a: string) => {
                  title = a;
                  this.toastrService.error(error.error, title, { timeOut: 3000 });
                });
              }
            })
          );
        } else {
          this.detail = false;
          this.enabled = false;
          this.employee = new Employee();

          this.spinner.hide();
        }
      })
    );
  }
  //#endregion

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  edit() {
    this.editable = !this.editable;

    if (this.editable) {
      this.dataChangesService.setEditModeChange(true);
      this.detail = true;
      this.enabled = true;
    } else {
      this.cancel();
    }
  }

  cancel() {
    this.dataChangesService.setEditModeChange(false);
    this.editable = false;
  }

  isValidForm() {
    return true;
  }

  resetForm() {
    this.init();
    this.resetFormDirty();
  }

  resetFormDirty() {
    // Quita las marcas "dirty" de los inputs
    this.dataChangesService.setResetFormDirty(true);
  }
  //#region FORMS

  back() {
    this.cancel();
    this.createMode = false;
    this.dataChangesService.setCreateModeChange(this.createMode);

    this.router.navigateByUrl(this.baseRoute);
  }

  goToEmployee(employeeId) {
    this.cancel();
    this.router.navigateByUrl(`${this.baseRoute}/${employeeId}`);
  }

  //#region CRUD
  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  /* ****************************************************************************************************************** */
  /* ** CREATE
  /* ****************************************************************************************************************** */
  create() {
    this.dataChangesService.getFormData(true);

    // Employee
    this.employee = ClonerService.deepClone(this.employeeCopy);

    // HomeAddress
    this.employee.homeAddress = (this.homeAddress)
      ? this.homeAddress
      : undefined;

    // EmployeeContract
    this.employee.employeeContract = (this.employeeContract)
      ? this.employeeContract
      : undefined;

    // SBA
    this.employee.sba = (this.sba)
      ? this.sba
      : undefined;

    this.employeeMgntService.createEmployee(this.employee).subscribe(
      response => {
        this.dataChangesService.getFormData(false);

        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.REGISTERED').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.REGISTERED', {
            type: this.componentTypeTranslated,
            code: this.employee.name,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.goToEmployee(response.employeeId);
      },
      error => {
        this.dataChangesService.getFormData(false);
        if (error.status === 500 || error.status === 0) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.CRITICAL_BODY').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.REGISTERED').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
          let title: string;
          this.translate.get('MESSAGES.ERROR.UNEXPECTED').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        }
      },
      () => {
        this.createMode = false;

        this.dataChangesService.setCreateModeChange(this.createMode);
      }
    );
  }

  /* ****************************************************************************************************************** */
  /* ** UPDATE
  /* ****************************************************************************************************************** */
  update() {
    if (this.employee) {
      this.spinner.show();

      // Employee
      let employeePatch = (this.employee)
        ? jsonpatch.compare(this.employee, this.employeeCopy)
        : jsonpatch.compare(new Employee(), this.employeeCopy);

      // HomeAddress
      let homeAddressPatch = (this.employee.homeAddress)
        ? jsonpatch.compare(this.employee.homeAddress, this.homeAddress)
        : jsonpatch.compare(new Address(), this.homeAddress);

      let text = '';

      this.translate.get('EMPLOYEEMANAGEMENT_MAINTENANCE.MESSAGE.CONFIRMATION.UPDATE_DATA').subscribe((transitionStr: string) => {
        text = transitionStr;
      });

      const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        width: '550px',
        data: {
          text
        }
      });

      this.dataChangesService.setEditModeChange(false); // Se desactivan los formularios
      this.spinner.hide();

      confirmationDialog.afterClosed().subscribe(async (confirm) => {
        if (confirm == true) {
          this.updateEmployee(employeePatch, homeAddressPatch);
        }
      });
    }
  }

  updateEmployee(employeePatch, homeAddressPatch) {
    this.spinner.show();

    this.employeeDataService.modifyPersonalData(this.employee.employeeId, employeePatch, homeAddressPatch).subscribe(
      response => {
        let title: string;
        this.translate.get('MESSAGES.SUCCESS.UPDATE').subscribe((a: string) => {
          title = a;
          this.toastrService.success(title);
        });

        this.goToEmployee(response.employeeId);

        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );
  }
  //#endregion

  //#region EMPLOYEE SITUATION
  /* ****************************************************************************************************************** */
  /* ** EMPLOYEE SITUATION
  /* ****************************************************************************************************************** */
  updateEmployeeSituation() {
    //despliega ventana form de cambio de situación empleado
    if (this.showEmployeeSituationBtn) {
      let registerEmployee = false;

      if (this.employee.employeeSituation == undefined || this.employee.employeeSituation == null) {
        this.employee.employeeSituation = _.find(this.employeeSituations, item => item.employeeSituationId == this.employee.employeeSituationId);
      }

      if (
        this.employee.employeeSituation.stateCode == EmployeeSituationCode.LEAVING ||
        this.employee.employeeSituation.stateCode == EmployeeSituationCode.SUSPENSION
      ) {
        registerEmployee = true;
      }

      //se crea componente EmployeeManagementChangeStateDialogComponent en el dialog
      const confirmationDialog = this.dialog.open(EmployeeManagementChangeStateDialogComponent, {
        width: '550px',
        data: { registerEmployee }
      });

      confirmationDialog.afterClosed().subscribe(async (confirm) => {
        if (confirm != false) {
          this.modifyEmployeeSituation(confirm);
        }
      });
    }
  }

  modifyEmployeeSituation(data: any) {
    let textTranslate: string;
    let text: string;
    let patch;

    let employeeHistory: EmployeeHistory = new EmployeeHistory(
      {
        reason: data.reason,
        startDate: data.startDate,
      }
    );

    if (data.action == "ACTIVATE") {
      textTranslate = "REGISTER_EMPLOYEE";

      employeeHistory.reason = "";
      employeeHistory.newEmployeeSituationId = this.employeeManagementInfo.allEmployeeSituations.find(x => x.stateCode == EmployeeSituationCode.ACTIVE).employeeSituationId;
    } else if (data.action == "SUSPEND") {
      textTranslate = "SUSPEND_EMPLOYEE";

      employeeHistory.finishDate = data.finishDate;
      employeeHistory.newEmployeeSituationId = this.employeeManagementInfo.allEmployeeSituations.find(x => x.stateCode == EmployeeSituationCode.SUSPENSION).employeeSituationId;
    } else if (data.action == "UNSUBSCRIBE") {
      textTranslate = "DELETE_EMPLOYEE";

      employeeHistory.newEmployeeSituationId = this.employeeManagementInfo.allEmployeeSituations.find(x => x.stateCode == EmployeeSituationCode.LEAVING).employeeSituationId;

      patch = [
        {
          "op": "replace",
          "path": "leaveDate",
          "value": data.startDate,
        }
      ];
    }

    this.translate.get(`EMPLOYEE_STATE.DIALOG_CONFIRMATION.${textTranslate}`, {
      value: `${this.employee.firstSurname} ${this.employee.secondSurname}, ${this.employee.name}`
    }).subscribe((a: string) => {
      text = a;
    });

    const confirmationDialog = this.dialog.open(EmployeeManagementDeleteConfirmDialogComponent, {
      width: '550px',
      data: { text }
    });

    confirmationDialog.afterClosed().subscribe(async (confirm) => {
      if (confirm) {
        this.spinner.show();

        this.simpleEmployeeService.modifyEmployeeSituation(this.employee.employeeId, employeeHistory, patch).subscribe(response => {
          if (response != null) {
            this.employeeHistoryService.getHistoryForEmployee(this.employee.employeeId).subscribe(EmployeeHisoryResponse => {
              this.employee.employeeHistory = (EmployeeHisoryResponse && EmployeeHisoryResponse.items) || [];

              const activeSituation = _.find(this.employeeSituations, item => item.stateCode == EmployeeSituationCode.ACTIVE);
              const leaveSituation = _.find(this.employeeSituations, item => item.stateCode == EmployeeSituationCode.LEAVING);

              // Se busca el último alta
              const lastActiveSituationHistory = _(this.employee.employeeHistory).filter(x => moment(x.startDate) <= moment() && x.newEmployeeSituationId == activeSituation.employeeSituationId).maxBy(x => moment(x.startDate).unix());

              if (lastActiveSituationHistory) {
                this.employee.registrationDate = lastActiveSituationHistory.startDate;
              }

              // Se busca la situacion actual
              const currentEmployeeSituationHistory = _(this.employee.employeeHistory).filter(x => moment(x.startDate) <= moment()).maxBy(x => moment(x.startDate).unix());

              if (currentEmployeeSituationHistory) {
                this.employee.employeeSituationId = currentEmployeeSituationHistory.newEmployeeSituationId;
                this.employee.employeeSituation = (currentEmployeeSituationHistory.newEmployeeSituation)
                  ? currentEmployeeSituationHistory.newEmployeeSituation
                  : _.find(this.employeeSituations, item => item.employeeSituationId == currentEmployeeSituationHistory.newEmployeeSituationId);

                if (this.employee.employeeSituationId != leaveSituation.employeeSituationId) {
                  this.employee.leaveDate = undefined;
                } else {
                  this.employee.leaveDate = currentEmployeeSituationHistory.startDate;
                }
              }

              // Se propaga el cambio a los observadores
              this.dataHistoryService.setEmployeeChange(this.employee);

              let toastTitle = '';
              let toastBody = '';
              this.translate.get('EMPLOYEE_STATE.MESSAGES.SUCCESS.TITLE').subscribe((a: string) => {
                toastTitle = a;
              });
              this.translate
                .get(`EMPLOYEE_STATE.MESSAGES.SUCCESS.${textTranslate}`, {
                  value: `${this.employee.name} ${this.employee.firstSurname} ${this.employee.secondSurname}`,
                })
                .subscribe((a: string) => {
                  toastBody = a;
                });

              this.spinner.hide();
              this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });

              this.spinner.hide();
            },
            error => {
              console.error(error);
              this.spinner.hide();
            });
          }
        }, error => {
          let toastTitle = '';
          let toastBody = '';
          this.translate.get('EMPLOYEE_STATE.MESSAGES.ERROR.TITLE').subscribe((a: string) => {
            toastTitle = a;
          });
          this.translate
            .get(`EMPLOYEE_STATE.MESSAGES.ERROR.${textTranslate}`, {
              value: `${this.employee.name} ${this.employee.firstSurname} ${this.employee.secondSurname}`,
            })
            .subscribe((a: string) => {
              toastBody = a;
            });

          this.spinner.hide();
          this.toastrService.error(toastBody, toastTitle, { timeOut: 3000 });
        })
      }
    })
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  clearPreviousAppPermissions(listOfPermissions: Permission[]): Permission[] {
    listOfPermissions //= listOfPermissions.filter(perm => CommonResources.PERMISSION_LIST.findIndex(permissionToClear => permissionToClear.name == perm.name) == -1);

    return listOfPermissions;
  }

  getEmployeeSituationBtnString() {
    let result = "BTN";
    let btnDefault = "ACTION.UNSUBSCRIBE_SUSPEND";

    if (
      this.employee &&
      this.employee.employeeSituation != undefined &&
      this.employee.employeeSituation != null &&
      (
        this.employee.employeeSituation.stateCode == EmployeeSituationCode.LEAVING ||
        this.employee.employeeSituation.stateCode == EmployeeSituationCode.SUSPENSION
      )
    ) {
      btnDefault = "ACTION.REGISTER";
    }

    this.translate.get(btnDefault).subscribe((a: string) => {
      result = a;
    });

    this.employeeSituationBtnString = result;
  }

  showEmployeeSituationBtn() {
    let result = false;

    if (
      this.employee &&
      this.employee.employeeSituation != undefined &&
      this.employee.employeeSituation != null &&
      (
        this.employee.employeeSituation.stateCode == EmployeeSituationCode.ACTIVE ||
        this.employee.employeeSituation.stateCode == EmployeeSituationCode.LEAVING ||
        this.employee.employeeSituation.stateCode == EmployeeSituationCode.SUSPENSION
      )
    ) {
      result = true;
    }

    this.showEmployeeSituation = result;
  }

  getEmployeeSituationBtnColor() {
    let btnDefault = "warn";

    if (
      this.employee &&
      this.employee.employeeSituation != undefined &&
      this.employee.employeeSituation != null &&
      (
        this.employee.employeeSituation.stateCode == EmployeeSituationCode.LEAVING ||
        this.employee.employeeSituation.stateCode == EmployeeSituationCode.SUSPENSION
      )
    ) {
      btnDefault = "primary";
    }

    this.employeeSituationBtnColor = btnDefault;
  }

  getEditButtonName() {
    let textButton = "";

    if (this.editable == false) {
      this.translate.get('ACTION.EDIT').subscribe((a: string) => {
        textButton = a;
      })
    } else {
      this.translate.get('ACTION.CANCEL').subscribe((a: string) => {
        textButton = a;
      })
    }

    return textButton;
  }

  datePicker() {
    this.dialog.open(EmployeeHistoryFilterRangueDialogComponent, {
      width: '550px'
    });
  }

  resetFilter() {
    this.hraDateFilterService.onChangeDates(this.hraDateFilterService.firstDate, this.hraDateFilterService.lastDate);
  }

  getEmployeeStatus() {
    let result = "";
    let employeeStatus = "AVAILABLE";
    let employeeSituationCode = null;
    let dateAux=new Date(Date.now());
    dateAux.setHours(0,0,0,0);
    const currentDate = dateAux;

    //const currentDate = new Date(dateAux.getDate());

    let absenceFound = false;
    let itLeaveFound = false;
    let holidaysRequestFound = false;

    if (this.employee && this.employee.employeeSituationId) {
      if (this.employeeManagementInfo.allEmployeeSituations) {
        employeeSituationCode = this.employeeManagementInfo.allEmployeeSituations.find(element => element.employeeSituationId == this.employee.employeeSituationId).stateCode;
      }

      if (employeeSituationCode == EmployeeSituationCode.LEAVING) {
        employeeStatus = "NO_CONTRACT";
      } else {
        for (let absence of this.employee.absences) {
          for (let date of absence.requestedDates) {
            let daterequestA=new Date(date);
            daterequestA.setHours(0,0,0,0);

            if (daterequestA.getTime()== currentDate.getTime()) {
              if (absence.isMedicalLeave) {
                absenceFound = true;
                employeeStatus = "ITLEAVE";
                break;
              } else {
                itLeaveFound = true;
                employeeStatus = "ABSENCE";
                break;
              }
            }
          }

          if (absenceFound || itLeaveFound) {
            break;
          }
        }
        if (!absenceFound && !itLeaveFound) {
          for (let holidaysRequest of this.employee.holidaysRequests) {
            for (let date of holidaysRequest.requestedDates) {
              let daterequestH=new Date(date);
              daterequestH.setHours(0,0,0,0);

              if (daterequestH.getTime()== currentDate.getTime()) {
                employeeStatus = "HOLIDAYS";
                holidaysRequestFound = true;
                break;
              }
            }

            if (holidaysRequestFound) {
              break;
            }
          }
        }
      }

      this.translate.get(`EMPLOYEEMANAGEMENT_MAINTENANCE.STATUS.${employeeStatus}`).subscribe((a: string) => {
        result = a;
      });
    }

    return result;
  }

  getEmployeeSituation() {
    let employeeSituation = null;

    if (this.employee && this.employee.employeeSituationId && this.employeeSituations) {
      employeeSituation = _.find(this.employeeSituations, item => item.employeeSituationId == this.employee.employeeSituationId);
    };

    let result = (employeeSituation != null)
      ? employeeSituation.description
      : "";

    this.currentemployeeSituation = result;
    return result;
  }

  getDate(date: any, format: string): string {
    let result = getDateTimeString(date, format);

    return result != "Invalid date" ? result : "";
  }

  createButtonEnabled(): boolean {
    return true;
  }

  setDate($event) {
    $event.value = moment($event.target.value).format('DD/MM/YYYY');

    return $event;
  }

  assignDate(date: string) {
    let result = null;

    if (date != "") {
      result = new Date(date) || undefined;
    }

    return result;
  }

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  /**
   * Comprueba si un formulario ha sido cambiado
   *
   * @param formGroup formulario a procesar
   * @param nameMask mascara para limintar el nombre de los inputs a verificar
   * @returns los cambios hechos en el formulario
   */
  isFormChanges(formGroup: FormGroup, nameMask: string = ''): boolean {
    let changedProperties = [];

    //https://stackoverflow.com/questions/40914413/how-can-i-get-all-dirty-modified-fields-using-angular2-forms
    Object.keys(formGroup.controls).forEach((name) => {
      if (
        nameMask == '' ||
        (nameMask != '' && name.includes(nameMask))
      ) {
        const currentControl = formGroup.controls[name];

        if (currentControl.dirty) {
          changedProperties.push(name);
        }
      }
    });

    return (changedProperties.length > 0) ? true : false;
  }

  /**
   * Se cambia el valor (string) del control del formulario por un objeto entre la colección pasada.
   *
   * @param arrayValues Colección de valores en los que buscar
   * @param formGroup Formulario al que asignar el valor buscado
   * @param controlName Nombre del control del formulario al que se reasociará el objeto
   * @param property Nombre de la propiedad a evaluar la búsqueda
   */
  assignObjectFromArrayToForm(arrayValues, formGroup, controlName, property) {
    const currentControl = formGroup.controls[controlName];

    const tempData = arrayValues.find((element) => element[property] == currentControl.value);

    currentControl.setValue(tempData);
  }
  //#endregion
  //#endregion
}
