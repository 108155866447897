import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DataChangeRequestService } from 'src/app/services/data-change-request.service';
import { DcrComponent } from './dcr.component';

@Component({
  selector: 'app-atcion-dcr-employee',
  templateUrl: './dcr.component.html',
  styleUrls: ['./../action.components.css'],
})
export class DcrComponentEmployee extends DcrComponent {

  constructor(protected dataChangeRequestService: DataChangeRequestService) {
    super(dataChangeRequestService);

    this.employeeView = true;
  }
}

