import { Employee } from './employee.model';
export class Address {
  addressId   : string;
  country     : string;
  province    : string;
  flat        : string;
  location    : string;
  location2   : string;
  number      : number;
  portal      : string;
  postalCode  : string;
  street      : string;
  typeStreet  : string;

  //Relations

  constructor(obj?: Partial<Address>) {
    Object.assign(this, obj);
  }
}
