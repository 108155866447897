import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as jsonpatch from 'fast-json-patch';

import { AccountService, BaseFormComponent, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';

import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { FiltersApi } from 'src/app/models/filters-api.model';

import { AnnualWorkHour } from 'src/app/models/annual-work-hour.model';
import { AnnualWorkHourService } from 'src/app/services/annual-work-hour.service';

@Component({
  selector: 'app-annual-work-hour-form',
  templateUrl: './annual-work-hour-form.component.html',
  styleUrls: [
    './annual-work-hour-form.component.css',
    './../../maintenance.components.css',
  ],
})
export class AnnualWorkHourFormComponent extends BaseFormComponent<AnnualWorkHour> implements OnInit, OnDestroy {
  //#region ATRRIBUTES
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  selectedDesc: string = undefined;
  annualWorkHour: AnnualWorkHour;

  roles: Role[];

  canAdd = false;
  subscription: Subscription;

  filterApi: FiltersApi = new FiltersApi();

  /* ****************************************************************************************************************** */
  /* ** INHERITED
  /* ****************************************************************************************************************** */
  enabled = true;
  detail = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  formGroupControl = new FormGroup({
    year: new FormControl('', Validators.required),
    workHours: new FormControl('', Validators.required),
    maximumBase: new FormControl('', Validators.required),
  });
  //#endregion
  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private toastrService: ToastrService,
    private annualWorkHourService: AnnualWorkHourService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ) {
      this.canAdd = true;
      this.canDelete = true;
    }
    this.PK = 'annualWorkHourId';

    this.baseRoute = '/maintenances/annual_workhours';

    this.filterApi.add(
      {
        field: "related",
        value: "false"
      }
    );

    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('CATEGORIES_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
    this.init();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          if (
            this.accountService.currentUser.isAdmin ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RA) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RP)
          ) {
          }
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  //#region DATA MANIPULATION
  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  private init() {
    this.disableForm();

    this.annualWorkHour = new AnnualWorkHour();
    this.subscription = this.route.paramMap.subscribe(params => {
      const transId = params.get('id');
      this.annualWorkHourService.updateAnnualWorkHourRoutes(transId);
      if (transId) {
        if (transId === 'new') {
          this.detail = false;
          this.enabled = true;
          this.annualWorkHour = new AnnualWorkHour();
          this.selectedDesc = undefined;
          this.cleanForm();
          this.enableForm();
        } else {
          this.annualWorkHourService.getAnnualWorkHour(transId, this.filterApi.getStrinFilterApi()).subscribe(response => {
            this.detail = true;
            this.enabled = false;
            this.annualWorkHour = response;
            this.disableForm();
            this.loadDataOnForm();
            this.selectedDesc = response.year;
          });
        }
      } else {
        this.selectedDesc = undefined;
        this.detail = false;
        this.enabled = false;
        this.annualWorkHour = new AnnualWorkHour();
      }
    });
  }
  //#endregion

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  cleanForm() {
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      currentControl.enable();
    });
  }

  disableForm() {
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      currentControl.disable();
    });
  }

  enableForm() {
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      currentControl.enable();
    });
  }

  loadDataOnForm() {
    this.formGroupControl.setValue({
      year        : this.annualWorkHour.year,
      workHours   : this.annualWorkHour.workHours,
      maximumBase : this.annualWorkHour.maximumBase,
    });
  }

  edit() {
    this.enabled = true;

    this.enableForm();
  }

  cancel() {
    this.cleanForm();
    this.disableForm();

    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }
  //#endregion
  //#endregion

  //#region CRUD
  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  /* ****************************************************************************************************************** */
  /* ** CREATE
  /* ****************************************************************************************************************** */
  create() {
    this.assignAnnualWorkHour();

    this.annualWorkHourService.createAnnualWorkHour(this.annualWorkHour).subscribe(
      response => {
        this.annualWorkHourService.getAllAnnualWorkHours().subscribe(annualWorkHours => {
          this.annualWorkHourService.updateAnnualWorkHours(annualWorkHours.items);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.CREATE_LONG', {
            type: this.componentTypeTranslated,
            code: this.annualWorkHour.year,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.cancel();
      },
      error => {
        if (error.status === 500 || error.status === 0) {
        } else if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
        }
      },
    );
  }

  /* ****************************************************************************************************************** */
  /* ** UPDATE
  /* ****************************************************************************************************************** */
  update() {
    const observer = jsonpatch.observe(this.annualWorkHour);
    this.assignAnnualWorkHour();

    const patch = jsonpatch.generate(observer);

    this.annualWorkHourService.modifyAnnualWorkHour(this.annualWorkHour.annualWorkHourId,patch).subscribe(
      response => {
        this.annualWorkHourService.getAllAnnualWorkHours().subscribe(annualWorkHours => {
          this.annualWorkHourService.updateAnnualWorkHours(annualWorkHours.items);
        });
        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );
  }

  /* ****************************************************************************************************************** */
  /* ** DELETE
  /* ****************************************************************************************************************** */
  delete() {
    let text = '';

    this.translate.get('ANNULA_WORKHOUR_MAINTENANCE.MESSAGE.DELETE_CONFIRMATION.TEXT', { value: this.annualWorkHour.year }).subscribe((transitionStr: string) => {
      text = transitionStr;
    });

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      data: {
        text
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.annualWorkHourService.deleteAnnualWorkHour(this.annualWorkHour.annualWorkHourId).subscribe(response => {
          this.annualWorkHourService.getAllAnnualWorkHours().subscribe(annualWorkHours => {
            this.annualWorkHourService.updateAnnualWorkHours(annualWorkHours.items);
          });
          let toastTitle = '';
          let toastBody = '';
          this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {
            toastTitle = a;
          });
          this.translate.get('MESSAGES.SUCCESS.DELETE_LONG', {type: this.componentTypeTranslated, code: this.annualWorkHour.year}).subscribe((a: string) => {
            toastBody = a;
          });
          this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
          this.cancel();
        });
      }
    });
  }
  //#endregion

  //#region ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  assignAnnualWorkHour() {
    if (this.annualWorkHour == undefined || this.annualWorkHour == null) {
      this.annualWorkHour = new AnnualWorkHour();
    }

    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];
      const temp = currentControl.value;

      if (temp != null && temp != '') {
        this.annualWorkHour[name] = temp;
      }
    });
  }
  //#endregion

  //#region OTHERS
  /* ****************************************************************************************************************** */
  /* ** OTHERS
  /* ****************************************************************************************************************** */
  back() {
    this.cancel();
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  //#endregion
}
