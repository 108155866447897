import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';

import { ContractWorkingHoursType } from '../models/contract-working-hours-type.model';


@Injectable({ providedIn: 'root' })
export class ContractWorkingHoursTypeService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private contractWorkingHoursTypeSubject: ReplaySubject<ContractWorkingHoursType[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public contractWorkingHoursTypeObservable: Observable<ContractWorkingHoursType[]> = this.contractWorkingHoursTypeSubject.asObservable();

  private contractWorkingHoursTypeRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public contractWorkingHoursTypeRouteObservable: Observable<string> = this.contractWorkingHoursTypeRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/contractWorkingHoursType';
  }

  constructor(private http: HttpClient) {
  }

  getAllContractWorkingHoursTypes(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getContractWorkingHoursType(contractWorkingHoursTypeId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/${contractWorkingHoursTypeId}${filter}`;

    return this.http.get<ContractWorkingHoursType>(url);
  }

  createContractWorkingHoursType(contractWorkingHoursType: ContractWorkingHoursType) {
    const url = `${this.serviceUrl}`;

    return this.http.post(url, contractWorkingHoursType);
  }

  deleteContractWorkingHoursType(contractWorkingHoursTypeId: string) {
    const url = `${this.serviceUrl}/${contractWorkingHoursTypeId}`;

    return this.http.delete(url);
  }

  modifyContractWorkingHoursType(contractWorkingHoursTypeId: string, contractWorkingHoursType: any) {
    const url = `${this.serviceUrl}/${contractWorkingHoursTypeId}`;

    return this.http.patch(url, contractWorkingHoursType);
  }

  updateContractWorkingHoursTypes(contractWorkingHoursTypes: any) {
    this.contractWorkingHoursTypeSubject.next(contractWorkingHoursTypes);
  }

  updateContractWorkingHoursTypeRoutes(id: string) {
    this.contractWorkingHoursTypeRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
