import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AbsenceManagementComponent } from '../absence-management.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Absence } from 'src/app/models/absence.model';
import { AbsenceService } from 'src/app/services/absence.service';

import { AbsenceTypeService } from 'src/app/services/absence-type.service';

@Component({
  selector: 'app-absence-management-reject-range-dialog',
  templateUrl: './absence-management-reject-range-dialog.component.html',
  styleUrls: ['./absence-management-reject-range-dialog.component.css'],
})
export class AbsenceRejectRangeDialogComponent {
  formGroupControl: FormGroup;


  absence: Absence = null;
  reason: string;


  constructor(
    public dialogRef: MatDialogRef<AbsenceManagementComponent>,
    public absenceTypeService: AbsenceTypeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private absenceService: AbsenceService,
  ) { }

  ngOnInit() {
    this.formGroupControl = new FormGroup({
      reason: new FormControl('', Validators.required),
    });

  }

  save() {

    this.reason= this.formGroupControl.get('reason').value,

    this.dialogRef.close(this.reason);

  }

}
