import { Address } from "./address.model";
import { Absence } from "./absence.model";
import { Centre } from "./centre.model";
import { CV } from "./cv.model";
import { DataChangeRequest } from "./data-chage-request.model";
import { Department } from "./department.model";
import { EmployeeCourse } from "./employee-course.model";
import { EmployeeDegree } from "./employee-degree.model";
import { EmployeeLanguage } from "./employee-language.model";
import { EmployeeType } from "./employee-type.model";
import { Headquarters } from "./headquarters.model";
import { IRPF } from "./irpf.model";
import { Payroll } from "./payroll.model";
import { WorkCalendar } from "./work-calendar.model";
import { EmployeeSituation } from "./employee-situation.model";
import { HolidayRequest } from "./holiday-request.model";
import { Country } from "./country.model";
import { Area } from "./area.model";
import { Category } from "./category.model";
import { EmployeeCategoryHistory } from './employee-category-history.model';
import { EmployeeAreaHistory } from './employee-area-history.model';
import { SBA } from './sba.model';
import { EmployeeHistory } from "./employee-history.model";
import { EmployeeContract } from "./employee-contract.model";
import { HRAHistory } from "./hra-history.model";
import { TrainingChangeRequest } from "./training-change-request.model";
import { WorkCalendarHistory } from "./work-calendar-history.model";
import { EmployeeManagerHistory } from "./employee-manager-history.model";
import { EmployeeValidatorHistory } from "./employee-validator-history.model";

export class Employee {
  // Company data
  userId                                : string;
  employeeId                            : string;
  code                                  : string;
  login                                 : string;
  registrationDate                      : string;
  leaveDate                             : string;
  seniorityDate                         : string;
  contractEndDate                       : string;

  // Personal data
  name                                  : string;
  firstSurname                          : string;
  secondSurname                         : string;
  gender                                : string;
  birthdate                             : string;
  birthplace                            : string;
  birthplaceProvince                    : string;
  disability                            : boolean;
  disabilityPercentage                  : number;

  // Documentation data
  documentNumber                        : string;
  documentType                          : string;
  documentExpirationDate                : string;
  nss                                   : string;
  bankAccount                           : string;

  // Contact data
  personalEmail                         : string;
  personalPhone                         : string;

  companyEmail                          : string;
  companyPhone                          : string;

  // Current Absences
  totalAbsencesRequested                : number = 0;
  totalITLeaves                         : number = 0;

  // Last year Absences
  totalAbsencesRequestedLastYear        : number = 0;
  totalITLeavesLastYear                 : number = 0;

  // Holidays data
  totalAvailableHolidays                : number = 0;
  totalHolidaysEnjoyed                  : number = 0;
  totalHolidaysRequested                : number = 0;

  // Last year Holidays Pending
  totalPendingHolidaysLastYear          : number = 0;
  totalPendingHolidaysLastYearEnjoyed   : number = 0;
  totalPendingHolidaysLastYearRequested : number = 0;

  // HolidayWeekend
  totalHolidayWeekend                   : number = 0;
  holidayWeekendEnjoyed                 : number = 0;
  holidayWeekendRequested               : number = 0;

  //Relations
  // -----------------------------------------------------------------------------------
  // -- A
  // -----------------------------------------------------------------------------------
  absences                            : Absence[] = [];

  areaId                              : string;
  area                                : Area;

  // -----------------------------------------------------------------------------------
  // -- C
  // -----------------------------------------------------------------------------------
  categoryId                          : string;
  category                            : Category;

  centreId                            : string;
  centre                              : Centre;

  countryId                           : string;
  country                             : Country;

  cvId                                : string;
  cv                                  : CV;

  // -----------------------------------------------------------------------------------
  // -- D
  // -----------------------------------------------------------------------------------
  DCRs                                : DataChangeRequest[] = [];

  // -----------------------------------------------------------------------------------
  // -- E
  // -----------------------------------------------------------------------------------
  employeeAreasHistory                : EmployeeAreaHistory[] = [];
  employeeCategoriesHistory           : EmployeeCategoryHistory[] = [];

  employeeContractId                  : string;
  employeeContract                    : EmployeeContract;
  employeeContracts                   : EmployeeContract[] = [];

  employeeCertificationCourses        : EmployeeCourse[] = [];
  employeeCourses                     : EmployeeCourse[] = [];
  employeeDegrees                     : EmployeeDegree[] = [];
  employeeLanguages                   : EmployeeLanguage[] = [];

  employeeHistory                     : EmployeeHistory[];

  employeeSituationId                 : string;
  employeeSituation                   : EmployeeSituation;

  // -----------------------------------------------------------------------------------
  // -- H
  // -----------------------------------------------------------------------------------
  headquartersId                      : string;
  headquarters                        : Headquarters;

  homeAddressId                       : string;
  homeAddress                         : Address;

  holidaysRequests                    : HolidayRequest[] = [];
  hraHistory                          : HRAHistory[] = [];

  // -----------------------------------------------------------------------------------
  // -- I
  // -----------------------------------------------------------------------------------
  IRPFs                               : IRPF[] = [];

  // -----------------------------------------------------------------------------------
  // -- M
  // -----------------------------------------------------------------------------------
  managerId                           : string;
  manager                             : Employee;
  managedEmployees                    : Employee[] = [];

  managerHistory                      : EmployeeManagerHistory[];

  // -----------------------------------------------------------------------------------
  // -- P
  // -----------------------------------------------------------------------------------
  payrolls                            : Payroll[] = [];

  // -----------------------------------------------------------------------------------
  // -- S
  // -----------------------------------------------------------------------------------
  sbaId                               : string;
  sba                                 : SBA;
  sbas                                : SBA[] = [];

  // -----------------------------------------------------------------------------------
  // -- V
  // -----------------------------------------------------------------------------------
  validatorId                         : string;
  validator                           : Employee;
  validatedEmployees                  : Employee[] = [];

  validatorHistory                    : EmployeeValidatorHistory[];

  // -----------------------------------------------------------------------------------
  // -- T
  // -----------------------------------------------------------------------------------
  tCRs                                : TrainingChangeRequest[] = [];

  // -----------------------------------------------------------------------------------
  // -- W
  // -----------------------------------------------------------------------------------
  workCalendarId                      : string;
  workCalendar                        : WorkCalendar;

  constructor(obj?: Partial<Employee>) {
    Object.assign(this, obj);
  }
}
