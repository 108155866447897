import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { IRPF } from '../models/irpf.model';


@Injectable({ providedIn: 'root' })
export class IRPFService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private irpfSubject: ReplaySubject<IRPF[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public irpfObservable: Observable<IRPF[]> = this.irpfSubject.asObservable();

  private irpfRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public irpfRouteObservable: Observable<string> = this.irpfRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/irpf';
  }

  constructor(private http: HttpClient) {
  }

  getAllIRPFs(employeeId?: string) {
    let filter = employeeId ? "?employeeId=" + employeeId : "";

    let url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getAllIRPFsByEmployee(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    let url = `${this.serviceUrl}/Employee/${filter}`;

    return this.http.get<any>(url);
  }

  getIRPF(irpfId: string, related?: boolean) {
    let relatedFilter = "";
    if (related != undefined && related != null) {
      relatedFilter += "?related=" + related;
    }
    return this.http.get<IRPF>(`${this.serviceUrl}/${irpfId}${relatedFilter}`);
  }

  createIRPF(employeeId: string, newIRPFData: IRPF) {
    const endpointUrl = `${this.serviceUrl}/Employee/${employeeId}`;
    return this.http.post(endpointUrl, newIRPFData);
  }
  modifyIRPF(irpfId: string, newIRPFData: any) {
    const endpointUrl = `${this.serviceUrl}/${irpfId}`;
    return this.http.patch(endpointUrl, newIRPFData);
  }
  deleteIRPF(irpfId: string) {
    const endpointUrl = `${this.serviceUrl}/${irpfId}`;
    return this.http.delete(endpointUrl);
  }
  reassociateIRPF(irpfId: string, employeeIdSource: string, employeeIdDestination: string) {
    const endpointUrl = `${this.serviceUrl}/${irpfId}/Source/${employeeIdSource}/Destination/${employeeIdDestination}`;
    return this.http.put(endpointUrl, null);
  }

  updateIRPFs(irpfs: IRPF[]) {
    this.irpfSubject.next(irpfs);
  }

  updateIRPFRoutes(id: string) {
    this.irpfRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
