import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';

import { LaborAgreement } from '../models/labor-agreement.model';

@Injectable({ providedIn: 'root' })
export class LaborAgreementService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private laborAgreementSubject: ReplaySubject<LaborAgreement[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public laborAgreementObservable: Observable<LaborAgreement[]> = this.laborAgreementSubject.asObservable();

  private laborAgreementRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public laborAgreementRouteObservable: Observable<string> = this.laborAgreementRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/laborAgreement';
  }

  constructor(private http: HttpClient) {
  }

  getAllLaborAgreements(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getLaborAgreement(laborAgreementId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/${laborAgreementId}${filter}`;

    return this.http.get<LaborAgreement>(url);
  }

  createLaborAgreement(laborAgreement: LaborAgreement) {
    const url = `${this.serviceUrl}`;

    return this.http.post(url, laborAgreement);
  }

  deleteLaborAgreement(laborAgreementId: string) {
    const url = `${this.serviceUrl}/${laborAgreementId}`;

    return this.http.delete(url);
  }

  modifyLaborAgreement(laborAgreementId: string, laborAgreement: any) {
    const url = `${this.serviceUrl}/${laborAgreementId}`;

    return this.http.patch(url, laborAgreement);
  }

  updateLaborAgreements(laborAgreements: any) {
    this.laborAgreementSubject.next(laborAgreements);
  }

  updateLaborAgreementRoutes(id: string) {
    this.laborAgreementRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
