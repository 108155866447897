import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "inzo-portalempleado";
import { Observable, of, Subject, ReplaySubject } from "rxjs";
import { AbsenceGroupsItems } from "../interfaces/absence.interface";
import { AbsenceDocument } from "../models/absence-document.model";
import { Absence } from "../models/absence.model";
import { StorageFile } from '../models/storage-file.model';

@Injectable({ providedIn: "root" })
export class AbsenceService {
  private changeSubject: Subject<any> = new Subject();
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private absenceSubject: ReplaySubject<Absence[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public absenceObservable: Observable<Absence[]> = this.absenceSubject.asObservable();

  private absenceGroupSubject: ReplaySubject<AbsenceGroupsItems[]> =
    new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public absenceGroupObservable: Observable<AbsenceGroupsItems[]> =
    this.absenceGroupSubject.asObservable();

  private absenceRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public absenceRouteObservable: Observable<string> =
    this.absenceRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + "/absence";
  }

  constructor(private http: HttpClient) {}

  getAllAbsence(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }
    let url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getAllAbsenceGroupBy(filter?: string, bodyFilter?: string[]) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    let url = `${this.serviceUrl}/Group${filter}`;

    return this.http.post<any>(url, bodyFilter);
  }

  getAllAbsenceHierarchyGroupBy(filter?: string, bodyFilter?: string[]) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    let url = `${this.serviceUrl}/HierarchyGroup${filter}`;

    return this.http.post<any>(url, bodyFilter);
  }

  getAbsence(absenceId: string, related?: boolean) {
    let relatedFilter = "";
    if (related != undefined && related != null) {
      relatedFilter += "?related=" + related;
    }

    return this.http.get<Absence>(
      `${this.serviceUrl}/${absenceId}${relatedFilter}`
    );
  }

  createAbsence(absence: Absence, send: boolean = false) {
    const endpointUrl = `${this.serviceUrl}${send ? "?send=true" : ""}`;

    return this.http.post(endpointUrl, absence);
  }

  modifyAbsence(absenceId: string, absence: any) {
    const endpointUrl = `${this.serviceUrl}/${absenceId}`;

    return this.http.patch(endpointUrl, absence);
  }

  deleteAbsence(absenceId: string) {
    const endpointUrl = `${this.serviceUrl}/${absenceId}`;

    return this.http.delete(endpointUrl);
  }

  changeStateAbsence(absenceId: string, absenceState: string, reason = "") {
    const endpointUrl = `${this.serviceUrl}/${absenceId}/AbsenceState/${absenceState}/Reason/${reason}`;

    return this.http.put(endpointUrl, null);
  }

  addAbsenceDocument(absenceId: string, absenceDocument: FormData) {
    const endpointUrl = `${this.serviceUrl}/${absenceId}/Document`;

    return this.http.post<Absence>(endpointUrl, absenceDocument);//, { headers });
  }

  removeAbsenceDocument(absenceId: string, absenceDocumentId: string) {
    const endpointUrl = `${this.serviceUrl}/${absenceId}/Document/${absenceDocumentId}`;

    return this.http.delete<Absence>(endpointUrl);
  }

  // -----------------------------------------------------------------------------------------------------------
  // SIN LÓGICA DE PROCESO
  // -----------------------------------------------------------------------------------------------------------
  createAbsenceToEmployee(
    holidayRequest: Absence,
    absenceState: string,
    reason = ""
  ) {
    const endpointUrl = `${this.serviceUrl}/employee/${absenceState}/Reason/${reason}`;

    return this.http.post(endpointUrl, holidayRequest);
  }

  modifyLeaveIT(absenceId: string, absence: any) {
    const endpointUrl = `${this.serviceUrl}/IT/${absenceId}`;

    return this.http.patch(endpointUrl, absence);
  }

  modifyAbsenceManagement(absenceId: string, absence: any) {
    const endpointUrl = `${this.serviceUrl}/${absenceId}/Management`;

    return this.http.patch(endpointUrl, absence);
  }

  changeStateAbsenceToEmployee(
    holidayRequestId: string,
    absenceState: string,
    reason = ""
  ) {
    const endpointUrl = `${this.serviceUrl}/employee/${holidayRequestId}/AbsenceState/${absenceState}/Reason/${reason}`;

    return this.http.put(endpointUrl, null);
  }
  // -----------------------------------------------------------------------------------------------------------

  updateAbsences(absences: Absence[]) {
    this.absenceSubject.next(absences);
  }

  updateAbsencesGroup(Absences: AbsenceGroupsItems[]) {
    this.absenceGroupSubject.next(Absences);
  }

  updateAbsenceRoutes(id: string) {
    this.absenceRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
