import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CentreComponent } from '../centre.component';

@Component({
  selector: 'app-centre-confirmation-delete-dialog',
  templateUrl: './centre-confirmation-delete-dialog.component.html',
  styleUrls: ['./centre-confirmation-delete-dialog.component.css'],
})
export class CentreConfirmationDeleteDialogComponent {

  constructor(
      public dialogRef: MatDialogRef<CentreComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

}