import {
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { MatDialogRef, MAT_DIALOG_DATA, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { LayoutComponent } from './components/layout.component';
import {
  AuthInterceptor,
  TenantInterceptor,
  UserInterceptor,
} from 'inzo-portalempleado';

import {
  TranslateService,
  TranslateModule,
  TranslateLoader,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { AppService } from './app.service';

import {
  NGX_MAT_DATE_FORMATS,
} from '@angular-material-components/datetime-picker';

// ------------------------------------------------------------------------------------------------------------
// Helpers
// ------------------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------------------
// Custom modules
// ------------------------------------------------------------------------------------------------------------
import { MaterialModule } from './modules/material/material.module';
import { CustomCommonImportsModule } from './modules/custom-common-imports/custom-common-imports.module';
import { CloseYearModule } from './modules/close-year/close-year.module';

// ------------------------------------------------------------------------------------------------------------
// Custom components
// ------------------------------------------------------------------------------------------------------------
import { CommonResources } from './models/common-resources';
import { DateRangeInputComponent } from './components/date-range-input/date-range-input.component';

// Generic Dialogs
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';

// ------------------------------------------------------------------------------------------------------------
// Pages
// ------------------------------------------------------------------------------------------------------------

// Home

// Documents

// Formations
import { CertificationCourseComponent } from './pages/formations/certification-course/certification-course.component';
import { CourseComponent } from './pages/formations/course/course.component';

// Managements
import { AnnualWorkHourComponent } from './pages/managements/annual-work-hour/annual-work-hour.component';
import { CategoryComponent } from './pages/managements/category/category.component';
import { ContractDurationTypeComponent } from './pages/managements/contract-duration-type/contract-duration-type.component';
import { ContractTypeComponent } from './pages/managements/contract-type/contract-type.component';
import { ContractWorkingHoursTypeComponent } from './pages/managements/contract-working-hours-type/contract-working-hours-type.component';
import { LaborAgreementComponent } from './pages/managements/labor-agreement/labor-agreement.component';
import { PrcContractComponent } from './pages/managements/prc-contract/prc-contract.component';

// Manteinances

// Requests

// ------------------------------------------------------------------------------------------------------------
// Components
// ------------------------------------------------------------------------------------------------------------
// Absence
import { AbsenceComponent } from './components/absence/absence.component';
import { AbsenceListComponent } from './components/absence/absence-list/absence-list.component';
import { AbsenceGroupsComponent } from './components/absence/absence-groups/absence-groups.component';
import { AbsenceCalendarActionsComponent } from './components/absence/absence-calendar-actions/absence-calendar-actions.component';
import { AbsenceTabsComponent } from './components/absence/absence-tabs/absence-tabs.component';

import { AbsenceCreateRangeDialogComponent } from './components/absence/absence-create-range-dialog/absence-create-range-dialog.component';
import { AbsenceEditDialogComponent } from './components/absence-management/absence-edit-dialog/absence-edit-dialog.component';

// AbsenceDocument
import { AbsenceDocumentDialogComponent } from './components/absence-document-dialog/absence-document-dialog.component';
import { AbsenceDocumentCreateDialogComponent } from './components/absence-document-dialog/absence-document-create-dialog/absence-document-create-dialog.component';

// AbsenceManagement
import { AbsenceManagementComponent } from './components/absence-management/absence-management.component';
import { AbsenceManagementCalendarComponent } from './components/absence-management/absence-management-calendar/absence-management-calendar.component';
import { AbsenceManagementGroupsComponent } from './components/absence-management/absence-management-groups/absence-management-groups.component';
import { AbsenceManagementListComponent } from './components/absence-management/absence-management-list/absence-management-list.component';
import { AbsenceManagementListItemComponent } from './components/absence-management/absence-management-list-item/absence-management-list-item.component';
import { AbsenceManagementTabsComponent } from './components/absence-management/absence-management-tabs/absence-management-tabs.component';
import { AbsenceManagementSelectEmployeeComponent } from './components/absence-management/absence-management-select-employee/absence-management-select-employee.component';

import { AbsenceManagementRejectDialogComponent } from './components/absence-management/absence-management-reject-dialog/absence-management-reject-dialog.component';
import { AbsenceRejectRangeDialogComponent } from './components/absence-management/absence-management-reject-range-dialog/absence-management-reject-range-dialog.component';

// AbsenceTypeMaintenance
import { AbsenceTypeMaintenanceComponent } from './components/absence-type-maintenance/absence-type-maintenance.component';
import { AbsenceTypeMaintenanceFormComponent } from './components/absence-type-maintenance/absence-type-maintenance-form/absence-type-maintenance-form.component';
import { AbsenceTypeMaintenanceListComponent } from './components/absence-type-maintenance/absence-type-maintenance-list/absence-type-maintenance-list.component';

import { AbsenceTypeConfirmationDeleteDialogComponent } from './components/absence-type-maintenance/absence-type-confirmation-delete-dialog/absence-type-confirmation-delete-dialog.component';

// AnnualWorkHour

// Area
import { AreaComponent } from './components/area/area.component';
import { AreaListComponent } from './components/area/area-list/area-list.component';
import { AreaFormComponent } from './components/area/area-form/area-form.component';

// Category
import { CategoryFormComponent } from './components/category/category-form/category-form.component';
import { CategoryListComponent } from './components/category/category-list/category-list.component';

// Centre
import { CentreComponent } from './components/centre/centre.component';
import { CentreListComponent } from './components/centre/centre-list/centre-list.component';
import { CentreFormComponent } from './components/centre/centre-form/centre-form.component';

import { CentreConfirmationDeleteDialogComponent } from './components/centre/centre-confirmation-delete-dialog/centre-confirmation-delete-dialog.component';

// ContractDurationType

// ContractType

// ContractWorkingHoursType

// Course
import { CourseFormComponent } from './components/course/course-form/course-form.component';
import { CertificationCourseFromComponent } from './components/course/course-form/certification-course-from.component';
import { CourseListComponent } from './components/course/course-list/course-list.component';
import { CertificationCourseListComponent } from './components/course/course-list/certification-course-list.component';
import { CourseTypeComponent } from './components/course-type/course-type.component';
import { CourseTypeListComponent } from './components/course-type/course-type-list/course-type-list.component';
import { CourseTypeFormComponent } from './components/course-type/course-type-form/course-type-form.component';
import { CourseFormItemComponent } from './components/course/course-form-item/course-form-item.component';

import { CourseConfirmationDeleteDialogComponent } from './components/course/course-confirmation-delete-dialog/course-confirmation-delete-dialog.component';
import { CourseCertificationDialogComponent } from './components/course/course-certification-dialog/course-certification-dialog.component';

// CV
import { CVComponent } from './components/cv/cv.component';
import { CVFormComponent } from './components/cv/cv-form/cv-form.component';
import { CVListComponent } from './components/cv/cv-list/cv-list.component';

// DataChangeRequest
import { DcrComponent } from './components/dcr/dcr.component';
import { DcrComponentEmployee } from './components/dcr/dcr.component-employee';
import { DcrFormComponent } from './components/dcr/dcr-form/dcr-form.component';
import { DcrListComponent } from './components/dcr/dcr-list/dcr-list.component';
import { DcrListEmployeeComponent } from './components/dcr/dcr-list-employee/dcr-list-employee.component';
import { DcrGroupsComponent } from './components/dcr/dcr-groups/dcr-groups.component';
import { DcrGroupsEmployeeComponent } from './components/dcr/dcr-groups-employee/dcr-groups-employee.component';

import { DcrDialogComponent } from './components/dcr/dcr-dialog/dcr-dialog.component';

// Degree
import { DegreeComponent } from './components/degree/degree.component';
import { DegreeFormComponent } from './components/degree/degree-form/degree-form.component';
import { DegreeListComponent } from './components/degree/degree-list/degree-list.component';
import { DegreeFormItemComponent } from './components/degree/degree-form-item/degree-form-item.component';

import { DegreeCertificationDialogComponent } from './components/degree/degree-certification-dialog/degree-certification-dialog.component';

// Department
import { DepartmentComponent } from './components/department/department.component';
import { DepartmentFormComponent } from './components/department/department-form/department-form.component';
import { DepartmentListComponent } from './components/department/department-list/department-list.component';

import { DepartmentConfirmationDeleteDialogComponent } from './components/department/department-confirmation-delete-dialog/department-confirmation-delete-dialog.component';

// Employee
import { EmployeeSelectAssociateConfirmDialogComponent } from './components/employee-select-associate-confirm-dialog/employee-select-associate-confirm-dialog.component';

// EmployeeHistory
import { EmployeeAreaHistoryComponent } from './components/employee-history/employee-area-history-list/employee-area-history-list.component';

import { EmployeeHistoryDlgComponent } from './components/employee-history/employee-history-dialog/employee-history-dialog.component';

// EmployeeType
import { EmployeeTypeComponent } from './components/employee-type/Employee-type.component';
import { EmployeeTypeFormComponent } from './components/employee-type/employee-type-form/employee-type-form.component';
import { EmployeeTypeListComponent } from './components/employee-type/employee-type-list/employee-type-list.component';

// EmployeeManagement
import { EmployeeManagementComponent } from './components/employeeManagement/employeeManagement.component';
import { EmployeeManagementListComponent } from './components/employeeManagement/employeeManagement-list/employeeManagement-list.component';
import { EmployeeManagementFormComponent } from './components/employeeManagement/employeeManagement-form/employeeManagement-form.component';
import { EmployeeManagementRegisterConfirmDialogComponent } from './components/employeeManagement/employee-management-register-confirm-dialog/employee-management-register-confirm-dialog.component';

import { EmployeeSelectDialogComponent } from './components/employee-select-dialog/employee-select-dialog.component';
import { EmployeeManagementDeleteConfirmDialogComponent } from './components/employeeManagement/employee-management-delete-confirm-dialog/employee-management-delete-confirm-dialog.component';
import { EmployeeManagementChangeStateDialogComponent } from './components/employeeManagement/employee-mgnt-change-state-dialog/employee-mgnt-change-state-dialog.component';

// Headquarters
import { HeadquartersComponent } from './components/headquarters/headquarters.component';
import { HeadquartersListComponent } from './components/headquarters/headquarters-list/headquarters-list.component';
import { HeadquartersFormComponent } from './components/headquarters/headquarters-form/headquarters-form.component';

// History
import { EmployeeHistoryFilterRangueDialogComponent } from './components/employee-history/employee-history-filter-rangue-dialog/employee-history-filter-rangue-dialog.component';

// HolidayRequest
import { HolidayRequestComponent } from './components/holiday-request/holiday-request.component';
import { HolidayRequestListComponent } from './components/holiday-request/holiday-request-list/holiday-request-list.component';
import { HolidayRequestGroupsComponent } from './components/holiday-request/holiday-request-groups/holiday-request-groups.component';
import { HolidayRequestCalendarActionsComponent } from './components/holiday-request/holiday-request-calendar-actions/holiday-request-calendar-actions.component';
import { HolidayRequestTabsComponent } from './components/holiday-request/holiday-request-tabs/holiday-request-tabs.component';

import { HolidayRequestCreateRangeDialogComponent } from './components/holiday-request/holiday-request-create-range-dialog/holiday-request-create-range-dialog.component';
import { HolidayRequestEditDialogComponent } from './components/holiday-management/holiday-request-edit-dialog/holiday-request-edit-dialog.component';

// HolidayRequestManagement
import { HolidayRequestManagementComponent } from './components/holiday-management/holiday-request-management.component';
import { HolidayRequestManagementCalendarComponent } from './components/holiday-management/holiday-request-management-calendar/holiday-request-management-calendar.component';
import { HolidayRequestManagementGroupsComponent } from './components/holiday-management/holiday-request-management-groups/holiday-request-management-groups.component';
import { HolidayRequestManagementListComponent } from './components/holiday-management/holiday-request-management-list/holiday-request-management-list.component';
import { HolidayRequestManagementListItemComponent } from './components/holiday-management/holiday-request-management-list-item/holiday-request-management-list-item.component';
import { HolidayRequestManagementTabsComponent } from './components/holiday-management/holiday-request-management-tabs/holiday-request-management-tabs.component';
import { HolidayRequestSummaryDaysComponent } from './components/holiday-management/holiday-request-summary-days/holiday-request-summary-days.component';

import { HolidayManagementRejectDialogComponent } from './components/holiday-management/holiday-management-reject-dialog/holiday-management-reject-dialog.component';

// IRPF
import { IRPFComponent } from './components/irpf/irpf.component';
import { IRPFListComponent } from './components/irpf/irpf-list/irpf-list.component';
import { IRPFFormComponent } from './components/irpf/irpf-form/irpf-form.component';

// ITLeave
import { ITLeaveComponent } from './components/it-leave/it-leave.component';
import { ITLeaveGroupsComponent } from './components/it-leave/it-leave-groups/it-leave-groups.component';
import { ITLeaveCalendarActionsComponent } from './components/it-leave/it-leave-calendar-actions/it-leave-calendar-actions.component';
import { ITLeaveManagementListComponent } from './components/it-leave-management/it-leave-management-list/it-leave-management-list.component';

import { ITLeaveCreateRangeDialogComponent } from './components/it-leave/it-leave-create-range-dialog/it-leave-create-range-dialog.component';
import { ITLeaveEditDialogComponent } from './components/it-leave-management/it-leave-edit-dialog/it-leave-edit-dialog.component';

// ITLeaveManagement
import { ITLeaveManagementComponent } from './components/it-leave-management/it-leave-management.component';
import { ITLeaveManagementCalendarComponent } from './components/it-leave-management/it-leave-management-calendar/it-leave-management-calendar.component';
import { ITLeaveManagementGroupsComponent } from './components/it-leave-management/it-leave-management-groups/it-leave-management-groups.component';
import { ITLeaveManagementTabsComponent } from './components/it-leave-management/it-leave-management-tabs/it-leave-management-tabs.component';

import { ITLeaveManagementRejectDialogComponent } from './components/it-leave-management/it-leave-management-reject-dialog/it-leave-management-reject-dialog.component';

// LaborAgreement

// Language
import { LanguageComponent } from './components/language/language.component';
import { LanguageFormComponent } from './components/language/language-form/language-form.component';
import { LanguageListComponent } from './components/language/language-list/language-list.component';
import { LanguageFormItemComponent } from './components/language/language-form-item/language-form-item.component';

import { LanguageCertificationDialogComponent } from './components/language/language-certification-dialog/language-certification-dialog.component';

// Payroll
import { PayrollComponent } from './components/payroll/payroll.component';
import { PayrollListComponent } from './components/payroll/payroll-list/payroll-list.component';
import { PayrollFormComponent } from './components/payroll/payroll-form/payroll-form.component';

// PersonalData
import { PersonalDataComponent } from './components/personal-data/personal-data.component';
import { PersonalDataFormComponent } from './components/personal-data/personal-data-form/personal-data-form.component';

// PersonalCourse
import { PersonalCourseComponent } from './components/personal-course/personal-course.component';
import { PersonalCourseListComponent } from './components/personal-course/personal-course-list/personal-course-list.component';

// PersonalCV
import { PersonalCVComponent } from './components/personal-cv/personal-cv.component';
import { PersonalCVListComponent } from './components/personal-cv/personal-cv-list/personal-cv-list.component';

// PersonalDegree
import { PersonalDegreeComponent } from './components/personal-degree/personal-degree.component';
import { PersonalDegreeListComponent } from './components/personal-degree/personal-degree-list/personal-degree-list.component';

// PersonalIRPF
import { PersonalIRPFComponent } from './components/personal-irpf/personal-irpf.component';
import { PersonalIRPFListComponent } from './components/personal-irpf/personal-irpf-list/personal-irpf-list.component';

// PersonalLanguage
import { PersonalLanguageComponent } from './components/personal-language/personal-language.component';
import { PersonalLanguageListComponent } from './components/personal-language/personal-language-list/personal-language-list.component';

// PersonalPayroll
import { PersonalPayrollComponent } from './components/personal-payroll/personal-payroll.component';
import { PersonalPayrollListComponent } from './components/personal-payroll/personal-payroll-list/personal-payroll-list.component';

// PrcContract

// TrainingCenter
import { TrainingCenterComponent } from './components/training-center/training-center.component';
import { TrainingCenterListComponent } from './components/training-center/training-center-list/training-center-list.component';
import { TrainingCenterFormComponent } from './components/training-center/training-center-form/training-center-form.component';

// TrainingChangeRequest
import { TCRComponent } from './components/tcr/tcr.component';
import { TCRFormComponent } from './components/tcr/tcr-form/tcr-form.component';
import { TCRGroupsComponent } from './components/tcr/tcr-groups/tcr-groups.component';
import { TCRGroupsEmployeeComponent } from './components/tcr/tcr-groups-employee/tcr-groups-employee.component';
import { TCRListComponent } from './components/tcr/tcr-list/tcr-list.component';
import { TCRListEmployeeComponent } from './components/tcr/tcr-list-employee/tcr-list-employee.component';

import { TCRDialogComponent } from './components/personal-data/tcr-dialog/tcr-dialog.component';

// WorkCalendar
import { WorkCalendarListComponent } from './components/work-calendar/work-calendar-list/work-calendar-list.component';
import { WorkCalendarComponent } from './components/work-calendar/work-calendar.component';
import { WorkCalendarFormComponent } from './components/work-calendar/work-calendar-form/work-calendar-form.component';

import { WorkCalendarCreateRangeDialogComponent } from './components/work-calendar/work-calendar-create-range-dialog/work-calendar-create-range-dialog.component';
import { WorkCalendarImportEventsDialogComponent } from './components/work-calendar/work-calendar-import-events-dialog/work-calendar-import-events-dialog.component';
import { WorkCalendarImportEventsConfirmDialogComponent } from './components/work-calendar/work-calendar-import-events-confirm-dialog/work-calendar-import-events-confirm-dialog.component';

// Dashboard
import { DashboardComponent } from './components/dashboard/dashboard.component';
// Dashboard employee
import { DashboardEmployeeComponent } from './components/dashboard-employee/dashboard-employee.component';

// ------------------------------------------------------------------------------------------------------------

// Pipes
import { FilterPipe } from './pipes/filter.pipe';
import { FilterGroupPipe } from './pipes/filter-group.pipe';

// Directives
import { DateMaskDirective } from './directives/date-mask.directive';
import { DateTimeMaskDirective } from './directives/date-time-mask.directive';
import { PrcContractFormComponent } from './components/prc-contract/prc-contract-form/prc-contract-form.component';
import { PrcContractListComponent } from './components/prc-contract/prc-contract-list/prc-contract-list.component';
import { LaborAgreementFormComponent } from './components/labor-agreement/labor-agreement-form/labor-agreement-form.component';
import { LaborAgreementListComponent } from './components/labor-agreement/labor-agreement-list/labor-agreement-list.component';
import { AnnualWorkHourFormComponent } from './components/annual-work-hour/annual-work-hour-form/annual-work-hour-form.component';
import { AnnualWorkHourListComponent } from './components/annual-work-hour/annual-work-hour-list/annual-work-hour-list.component';
import { ContractDurationTypeFormComponent } from './components/contract/contract-duration-type-form/contract-duration-type-form.component';
import { ContractDurationTypeListComponent } from './components/contract/contract-duration-type-list/contract-duration-type-list.component';
import { ContractTypeFormComponent } from './components/contract/contract-type-form/contract-type-form.component';
import { ContractTypeListComponent } from './components/contract/contract-type-list/contract-type-list.component';
import { ContractWorkingHoursTypeFormComponent } from './components/contract/contract-working-hours-type-form/contract-working-hours-type-form.component';
import { ContractWorkingHoursTypeListComponent } from './components/contract/contract-working-hours-type-list/contract-working-hours-type-list.component';
import { EmployeeModule } from './modules/employee/employee.module';
import { PECommonModule } from './modules/pe-common/pe-common.module';


// ------------------------------------------------------------------------------------------------------------

export function initializeAuthService(appService: AppService) {
  return () => appService.initializeAuth();
}

registerLocaleData(localeEs, 'es');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,

    // Custom components
    DateRangeInputComponent,

    // --------------------------------------------------------------------------------------------------------
    // Pages
    // --------------------------------------------------------------------------------------------------------
    // Home

    // Documents

    // Formations
    CertificationCourseComponent,
    CourseComponent,

    // Managements
    AnnualWorkHourComponent,

    CategoryComponent,

    ContractDurationTypeComponent,

    ContractTypeComponent,

    ContractWorkingHoursTypeComponent,

    LaborAgreementComponent,

    PrcContractComponent,

    // Manteinances

    // Requests

    // --------------------------------------------------------------------------------------------------------
    // Components
    // --------------------------------------------------------------------------------------------------------
    // Absence
    AbsenceComponent,
    AbsenceListComponent,
    AbsenceGroupsComponent,
    AbsenceCalendarActionsComponent,
    AbsenceTabsComponent,

    // AbsenceManagement
    AbsenceManagementComponent,
    AbsenceManagementCalendarComponent,
    AbsenceManagementListComponent,
    AbsenceManagementListItemComponent,
    AbsenceManagementGroupsComponent,
    AbsenceManagementSelectEmployeeComponent,
    AbsenceManagementTabsComponent,

    // AbsenceTypeMaintenance
    AbsenceTypeMaintenanceComponent,
    AbsenceTypeMaintenanceFormComponent,
    AbsenceTypeMaintenanceListComponent,

    // AnnualWorkHour
    AnnualWorkHourFormComponent,
    AnnualWorkHourListComponent,

    // Area
    AreaComponent,
    AreaFormComponent,
    AreaListComponent,
    AreaListComponent,

    // Category
    CategoryFormComponent,
    CategoryListComponent,

    // Centre
    CentreComponent,
    CentreFormComponent,
    CentreListComponent,

    // ContractDurationType
    ContractDurationTypeFormComponent,
    ContractDurationTypeListComponent,

    // ContractType
    ContractTypeFormComponent,
    ContractTypeListComponent,

    // ContractWorkingHoursType
    ContractWorkingHoursTypeFormComponent,
    ContractWorkingHoursTypeListComponent,

    // Course
    CourseFormComponent,
    CertificationCourseFromComponent,
    CourseListComponent,
    CertificationCourseListComponent,
    CourseFormItemComponent,

    CourseTypeComponent,
    CourseTypeListComponent,
    CourseTypeFormComponent,

    // CV
    CVComponent,
    CVFormComponent,
    CVListComponent,

    // DataChangeRequest
    DcrComponent,
    DcrComponentEmployee,
    DcrListComponent,
    DcrListEmployeeComponent,
    DcrFormComponent,
    DcrGroupsComponent,
    DcrGroupsEmployeeComponent,

    // Degree
    DegreeComponent,
    DegreeFormComponent,
    DegreeListComponent,
    DegreeFormItemComponent,

    // Department
    DepartmentComponent,
    DepartmentFormComponent,
    DepartmentListComponent,

    // EmployeeHistory
    EmployeeAreaHistoryComponent,


    // EmployeeType
    EmployeeTypeComponent,
    EmployeeTypeFormComponent,
    EmployeeTypeListComponent,

    // EmployeeManagement
    EmployeeManagementComponent,
    EmployeeManagementFormComponent,
    EmployeeManagementListComponent,

    // Headquarters
    HeadquartersComponent,
    HeadquartersFormComponent,
    HeadquartersListComponent,

    // HolidayRequest
    HolidayRequestComponent,
    HolidayRequestListComponent,
    HolidayRequestGroupsComponent,
    HolidayRequestCalendarActionsComponent,
    HolidayRequestTabsComponent,

    // HolidayRequestManagement
    HolidayRequestManagementComponent,
    HolidayRequestManagementCalendarComponent,
    HolidayRequestManagementGroupsComponent,
    HolidayRequestManagementListComponent,
    HolidayRequestManagementListItemComponent,
    HolidayRequestManagementTabsComponent,
    HolidayRequestSummaryDaysComponent,

    // IRPF
    IRPFComponent,
    IRPFFormComponent,
    IRPFListComponent,

    // ITLeave
    ITLeaveComponent,
    ITLeaveGroupsComponent,
    ITLeaveCalendarActionsComponent,

    // ITLeaveManagement
    ITLeaveManagementComponent,
    ITLeaveManagementCalendarComponent,
    ITLeaveManagementGroupsComponent,
    ITLeaveManagementTabsComponent,
    ITLeaveManagementListComponent,

    // LaborAgreement
    LaborAgreementFormComponent,
    LaborAgreementListComponent,

    // Language
    LanguageComponent,
    LanguageFormComponent,
    LanguageListComponent,
    LanguageFormItemComponent,

    // Payroll
    PayrollComponent,
    PayrollFormComponent,
    PayrollListComponent,

    //PersonalCourses
    PersonalCourseComponent,
    PersonalCourseListComponent,

    // PersonalCVs
    PersonalCVComponent,
    PersonalCVListComponent,

    // PersonalData
    PersonalDataComponent,
    PersonalDataFormComponent,

    // PersonalDegrees
    PersonalDegreeComponent,
    PersonalDegreeListComponent,

    // PersonalIRPF
    PersonalIRPFComponent,
    PersonalIRPFListComponent,

    // PersonalPayrolls
    PersonalPayrollComponent,
    PersonalPayrollListComponent,

    // PersonalLanguages
    PersonalLanguageComponent,
    PersonalLanguageListComponent,

    // PrcContract
    PrcContractFormComponent,
    PrcContractListComponent,

    // TrainingCenter
    TrainingCenterComponent,
    TrainingCenterListComponent,
    TrainingCenterFormComponent,

    // TrainingChageRequest
    TCRComponent,
    TCRFormComponent,
    TCRGroupsComponent,
    TCRGroupsEmployeeComponent,
    TCRListEmployeeComponent,
    TCRListComponent,

    // WorkCalendar
    WorkCalendarComponent,
    WorkCalendarFormComponent,
    WorkCalendarListComponent,

    // --------------------------------------------------------------------------------------------------------
    // Dialogs
    // --------------------------------------------------------------------------------------------------------
    // Generic Dialogs
    ConfirmationDialogComponent,

    // AbsenceDocument
    AbsenceDocumentDialogComponent,
    AbsenceDocumentCreateDialogComponent,

    // AbsenceManagement
    AbsenceCreateRangeDialogComponent,
    AbsenceRejectRangeDialogComponent,
    AbsenceManagementRejectDialogComponent,
    AbsenceEditDialogComponent,

    // AbsenceTypeMaintenance
    AbsenceTypeConfirmationDeleteDialogComponent,

    // Centre
    CentreConfirmationDeleteDialogComponent,

    // Course
    CourseConfirmationDeleteDialogComponent,
    CourseCertificationDialogComponent,

    // DataChangeRequest
    DcrDialogComponent,

    // Department
    DepartmentConfirmationDeleteDialogComponent,

    // Degree
    DegreeCertificationDialogComponent,

    // Employee
    EmployeeSelectAssociateConfirmDialogComponent,

    // EmployeeManagement
    EmployeeSelectDialogComponent,
    EmployeeManagementDeleteConfirmDialogComponent,
    EmployeeManagementRegisterConfirmDialogComponent,
    EmployeeManagementChangeStateDialogComponent,

    // EmployeeHistory
    EmployeeHistoryDlgComponent,
    EmployeeHistoryFilterRangueDialogComponent,

    // HolidayRequestManagement
    HolidayRequestCreateRangeDialogComponent,
    HolidayManagementRejectDialogComponent,
    HolidayRequestEditDialogComponent,

    // ITLeaveManagement
    ITLeaveManagementRejectDialogComponent,
    ITLeaveCreateRangeDialogComponent,
    ITLeaveEditDialogComponent,

    // Language
    LanguageCertificationDialogComponent,

    // TrainingChageRequest
    TCRDialogComponent,

    // WorkCalendar
    WorkCalendarCreateRangeDialogComponent,
    WorkCalendarImportEventsDialogComponent,
    WorkCalendarImportEventsConfirmDialogComponent,

    // Dashboard
    DashboardComponent,
    // Dashboard employee
    DashboardEmployeeComponent,

    // --------------------------------------------------------------------------------------------------------
    // Pipes
    // --------------------------------------------------------------------------------------------------------
    FilterPipe,
    FilterGroupPipe,

    // --------------------------------------------------------------------------------------------------------
    // Directives
    // --------------------------------------------------------------------------------------------------------
    DateMaskDirective,
    DateTimeMaskDirective,

  ],
  imports: [
    // Custom modules
    MaterialModule,
    MatSlideToggleModule,
    EmployeeModule,

    CustomCommonImportsModule,
    PECommonModule,
    CloseYearModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      timeOut: 5000,
      enableHtml: true
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuthService,
      deps: [AppService],
      multi: true,
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CommonResources.CUSTOM_DATETIME_FORMATS },
    FilterPipe,

    // Calendarios
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    // {
    //   provide: NgxMatDateAdapter,
    //   useClass: NgxMatNativeDateAdapter,
    //   deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    // },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    // Generic Dialogs
    ConfirmationDialogComponent,

    // AbsenceManagement
    AbsenceCreateRangeDialogComponent,
    AbsenceRejectRangeDialogComponent,
    AbsenceManagementRejectDialogComponent,
    AbsenceEditDialogComponent,

    // AbsenceDocument
    AbsenceDocumentDialogComponent,
    AbsenceDocumentCreateDialogComponent,

    // AbsenceTypeMaintenance
    AbsenceTypeConfirmationDeleteDialogComponent,

    // Centre
    CentreConfirmationDeleteDialogComponent,

    // Course
    CourseConfirmationDeleteDialogComponent,
    CourseCertificationDialogComponent,

    // DataChangeRequest
    DcrDialogComponent,

    // Degree
    DegreeCertificationDialogComponent,

    // Department
    DepartmentConfirmationDeleteDialogComponent,

    // Employee
    EmployeeSelectAssociateConfirmDialogComponent,

    // EmployeeManagement
    EmployeeSelectDialogComponent,
    EmployeeManagementDeleteConfirmDialogComponent,
    EmployeeManagementChangeStateDialogComponent,
    EmployeeManagementRegisterConfirmDialogComponent,

    // EmployeeHistory
    EmployeeHistoryDlgComponent,
    EmployeeHistoryFilterRangueDialogComponent,


    // HolidayRequestManagement
    HolidayRequestCreateRangeDialogComponent,
    HolidayManagementRejectDialogComponent,
    HolidayRequestEditDialogComponent,

    // ITLeaveManagement
    ITLeaveManagementRejectDialogComponent,
    ITLeaveCreateRangeDialogComponent,
    ITLeaveEditDialogComponent,

    // Language
    LanguageCertificationDialogComponent,

    // TrainingChageRequest
    TCRDialogComponent,

    // WorkCalendar
    WorkCalendarCreateRangeDialogComponent,
    WorkCalendarImportEventsDialogComponent,
    WorkCalendarImportEventsConfirmDialogComponent,
  ],
  exports: [
  ],
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('es');
    translate.use('es');
  }
}

