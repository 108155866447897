import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';


@Injectable({ providedIn: 'root' })
export class HolidayRequestStateService {

    get serviceUrl() {
        return Config.apiBaseUrl + '/HolidaysRequestState';
    }

    constructor(private http: HttpClient) {
    }

    getAllHolidaysRequestState() {
        return this.http.get<any>(`${this.serviceUrl}`);
    }
}