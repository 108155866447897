import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import moment = require('moment');
import { SelectInput } from 'src/app/interfaces/select-input.interface';

@Component({
  selector: 'app-employee-mgnt-change-state-dialog',
  templateUrl: './employee-mgnt-change-state-dialog.component.html',
  styleUrls: ['./employee-mgnt-change-state-dialog.component.css']
})
export class EmployeeManagementChangeStateDialogComponent implements OnInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  formGroupControl: FormGroup;
  formActions: SelectInput[] = [];
  title: string = "";
  registerEmployee: boolean = false;
  suspend: boolean = false;

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
  ) {
    this.getFormActions();
  }

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.translate.get('EMPLOYEE_STATE.DIALOG_CONFIRMATION.TITLE').subscribe((a: string) => {
      this.title = a;
    });

    this.registerEmployee = this.data.registerEmployee;
  }

  ngAfterContentInit(): void {
    if (this.registerEmployee) {
      this.formGroupControl = new FormGroup(
        {
          action: new FormControl("ACTIVATE"),
          reason: new FormControl(""),
          startDate: new FormControl("", Validators.required),
          finishDate: new FormControl(""),
        }
      );
    } else {
      this.formGroupControl = new FormGroup(
        {
          action: new FormControl("", Validators.required),
          reason: new FormControl("", Validators.required),
          startDate: new FormControl("", Validators.required),
          finishDate: new FormControl(""),
        }
      );
    }
  }

  onNgModelChange($event) {
    if (this.formGroupControl.get('action').value == 'SUSPEND') {
      this.suspend = true;
    } else {
      this.suspend = false;
    }
  }

  /* ################################################################################################################## */
  /* ##
  /* ################################################################################################################## */
  getFormActions() {
    this.formActions = [];
    let suspendLabel = "";
    let unsusbscribeLabel = "";

    this.translate.get('EMPLOYEE_STATE.LABEL.SUSPEND_EMPLOYEE').subscribe((a: string) => {
      suspendLabel = a;
    });

    this.translate.get('EMPLOYEE_STATE.LABEL.DELETE_EMPLOYEE').subscribe((a: string) => {
      unsusbscribeLabel = a;
    });

    this.formActions.push(
      {
        value: "SUSPEND",
        label: suspendLabel
      }
    );

    this.formActions.push(
      {
        value: "UNSUBSCRIBE",
        label: unsusbscribeLabel
      }
    );
  }

  selectionActionChange(event) {
    if (event.value === "SUSPEND") {
      this.suspend = true;
    } else {
      this.suspend = false;
    }
  }

  updateEmployeeSituation() {
    //se formatea la fecha debido a el DatePicker y el formato UTC
    let iniDate=moment(this.formGroupControl.get("startDate").value).format('DD-MM-YYYY');
    let finDate=moment(this.formGroupControl.get("finishDate").value).format('DD-MM-YYYY');

    this.dialogRef.close(
      {
        action: this.formGroupControl.get("action").value,
        reason: this.formGroupControl.get("reason").value,
        startDate: iniDate,
        finishDate: finDate,
      }
    );
  }

  getStartDateLabel() {
    let result = "";
    let label = "EMPLOYEE_STATE.FORM.LABEL.LEAVE_DATE";

    if (this.suspend) {
      label = "EMPLOYEE_STATE.FORM.LABEL.START_DATE";
    }

    if (this.registerEmployee) {
      label = "EMPLOYEE_STATE.FORM.LABEL.DISCHARGE_DATE";
    }

    this.translate.get(label).subscribe((a: string) => {
      result = a;
    });

    return result;
  }
}
