import { AHRChanges } from "./ahr-changes.model";

export enum AHRCStateCode {
  PENDING_APROVAL = 0,
  APPROVED = 1,
  REJECTED = 2,
}

export class AHRCState {
  ahrcStateId  : string;
  stateCode   : AHRCStateCode;
  description : string;

  //Relations
  AHRCs        : AHRChanges[];

  constructor(obj?: Partial<AHRCState>) {
    this.ahrcStateId  = (obj && obj.ahrcStateId) || undefined;
    this.stateCode    = (obj && obj.stateCode) || undefined;
    this.description  = (obj && obj.description) || undefined;

    //Relations
    this.AHRCs        = (obj && obj.AHRCs) || [];
  }
}
