import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { WorkCalendarComponent } from '../work-calendar.component';
import { WCEventType } from "../../../models/wc-event-type.model";
import { WCEvent } from 'src/app/models/wc-event.model';
import { NgxSpinnerService } from 'ngx-spinner';
import * as jsonpatch from 'fast-json-patch';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { WCEventService } from 'src/app/services/wc-event.service';
import { WCEventTypeService } from 'src/app/services/wc-event-type.service';
import * as moment from 'moment';
@Component({
  selector: 'app-work-calendar-create-range-dialog',
  templateUrl: './work-calendar-create-range-dialog.component.html',
  styleUrls: ['./work-calendar-create-range-dialog.component.css'],
})
export class WorkCalendarCreateRangeDialogComponent {
  formGroupControl: FormGroup;

  allEventTypes = [];
  workCalendarId: string;
  workCalendarName: string;
  event: WCEvent = null;

  edit = false;

  constructor(
    public dialogRef: MatDialogRef<WorkCalendarComponent>,
    public eventTypeService: WCEventTypeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private wcEventService: WCEventService
  ) { }

  ngOnInit() {
    this.formGroupControl = new FormGroup({
      name: new FormControl('', Validators.required),
      location: new FormControl('', Validators.required),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      eventType: new FormControl('', Validators.required),
    });

    this.eventTypeService.getAllWCEventType().subscribe(eventTypesResponse => {
      this.allEventTypes = eventTypesResponse.items;
    });


    this.workCalendarId = this.data.workCalendarId;
    this.workCalendarName = this.data.workCalendarName;
    this.event = this.data.event;

    if (this.event) {
      this.edit = true;
      this.formGroupControl.setValue({
        name: this.event.name,
        location: this.event.location,
        startDate: this.event.startDate,
        endDate: this.event.endDate,
        eventType: this.event.wcEventType
      });
    }
  }

  create() {
    this.spinner.show();

    this.event = new WCEvent({
      name: this.formGroupControl.get('name').value,
      location: this.formGroupControl.get('location').value,
      startDate: moment(this.formGroupControl.get('startDate').value).format('YYYY-MM-DD'),
      endDate: moment(this.formGroupControl.get('endDate').value).format('YYYY-MM-DD'),
      WCEventTypeId: this.formGroupControl.get('eventType').value.wcEventTypeId,
      workCalendarId: this.workCalendarId
    });

    this.wcEventService.createWCEvent(this.event).subscribe(response => {
      this.event = response as WCEvent;
      this.event.wcEventType = this.formGroupControl.get('eventType').value;
      this.translate.get('WORK_CALENDAR_EVENTS.NAME').subscribe((componentTranslated: string) => {
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {toastTitle = a});
        this.translate.get('MESSAGES.SUCCESS.CREATE_LONG', {type: componentTranslated,code: this.event.name}).subscribe((a: string) => {toastBody = a});
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
      });

    }, error => {
      this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((lang: string) => {
        this.toastrService.error(error.error, lang, { timeOut: 3000 });
      });
    }, () => {
      this.spinner.hide();
      this.dialogRef.close({createdEvent: this.event});
    });

  }

  update() {
    const observer = jsonpatch.observe(this.event);
    this.event.name = this.formGroupControl.get('name').value;
    this.event.location = this.formGroupControl.get('location').value;
    this.event.startDate = moment(this.formGroupControl.get('startDate').value).format('YYYY-MM-DD');
    this.event.endDate = moment(this.formGroupControl.get('endDate').value).format('YYYY-MM-DD');
    this.event.WCEventTypeId = this.formGroupControl.get('eventType').value.wcEventTypeId;
    const patch = jsonpatch.generate(observer);

    this.wcEventService.modifyWCEvent(this.event.wcEventId, patch).subscribe(response => {
      this.event = response as WCEvent;
      this.event.wcEventType = this.formGroupControl.get('eventType').value;
      this.translate.get('WORK_CALENDAR_EVENTS.NAME').subscribe((componentTranslated: string) => {
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {toastTitle = a});
        this.translate.get('MESSAGES.SUCCESS.CREATE_LONG', {type: componentTranslated,code: this.event.name}).subscribe((a: string) => {toastBody = a});
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
      });

    }, error => {
      this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((lang: string) => {
        this.toastrService.error(error.error, lang, { timeOut: 3000 });
      });
    }, () => {
      this.spinner.hide();
      this.dialogRef.close({updatedEvent: this.event});
    });
  }

  assingEventType(e) {
    this.formGroupControl.get('eventType').setValue(e.option.value);
  }

  displayEventType(eventType: WCEventType): string | undefined {
    return (eventType) ? eventType.description : undefined;
  }

  delete(): void {
    this.wcEventService.deleteWCEvent(this.event.wcEventId).subscribe(response => {
      this.translate.get('WORK_CALENDAR_EVENTS.NAME').subscribe((componentTranslated: string) => {
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {toastTitle = a;});
        this.translate.get('MESSAGES.SUCCESS.DELETE_LONG', {type: componentTranslated,code: this.event.name}).subscribe((a: string) => {toastBody = a});
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
      });
      this.dialogRef.close({deletedEvent: this.event});
    });
  }

}
