import { Pipe, PipeTransform } from '@angular/core';
import { HolidayRequestGroupsItems } from '../interfaces/holidays.interface';

@Pipe({
  name: 'FilterGroupPipe',
})
export class FilterGroupPipe implements PipeTransform {
  transform(items: HolidayRequestGroupsItems[], filter: any): any {
    let result = items;

    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);

      result = items.filter(item => {
        return filterKeys.some((keyName) => {
            if (item[0] && item[0].employee[keyName]) {
                return new RegExp(filter[keyName], 'gi').test(item[keyName]) || new RegExp(filter[keyName], 'gi').test(item[0].employee[keyName]) || filter[keyName] == "";
            }
        });
      });
    }

    return result;
  }
}
