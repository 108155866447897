import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { CourseType } from 'src/app/models/course-type.model';
import { CourseTypeService } from 'src/app/services/course-type.service';


@Component({
  selector: 'app-course-type-list',
  templateUrl: 'course-type-list.component.html',
  styleUrls: ['./../../maintenance.components.css','../../maintenance-list.component.css'],
})
export class CourseTypeListComponent extends BaseListComponent<CourseType> implements OnInit, OnDestroy {
  coursesType: CourseType[] = [];
  subscription: Subscription;
  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;

  roles: Role[];

  canView = false;
  canAdd = false;
  canDelete = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  constructor(
    private courseTypeService: CourseTypeService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
  ) {
    super(accountService, SSMService, router, hotkeys);
    this.baseRoute = '/maintenances/course_type';

    this.onBuild = () => {
    };

    this.loadData = () => {
      this.spinner.show();
      this.courseTypeService.getAllCoursesType().subscribe(coursesType => {
        this.courseTypeService.updateCoursesType(coursesType.items);
        this.coursesType = coursesType.items;
        this.spinner.hide();
      }, error => {
        this.RNService.showError(error);
        this.spinner.hide();
      });
    };

    this.onInit = () => {
      if(this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH)) {
        this.canView = true;
        this.canAdd = true;
        this.canDelete = true;

        this.loadData();

        this.courseTypeService.courseTypeObservable.subscribe(coursesType => {
          this.coursesType = coursesType;
        });
      }
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;
          this.onInit();
        });
      });
    } else {
      this.roles = this.accountService.roles;
      this.onInit();
    }
  }
  //#endregion
}
