import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import moment = require('moment');
import { WorkCalendar } from 'src/app/models/work-calendar.model';

@Component({
  selector: 'app-close-year-wc-history-create-dialog',
  templateUrl: './close-year-wc-history-create-dialog.component.html',
  styleUrls: [
    './close-year-wc-history-create-dialog.component.css',
    './../../../../../components/maintenance-list.component.css',
  ],
})
export class CloseYearWorkCalendarHistoryCreateDialogComponent implements OnInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  formGroupControl: FormGroup;
  workCalendars: WorkCalendar[] = [];
  selectedWorkCalendarId: string;
  selectedWorkCalendarIds: string[] = [];

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    public dialogRef: MatDialogRef<WorkCalendar>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
  ) { }

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.formGroupControl = new FormGroup({
      validYear: new FormControl(
        moment(new Date()).year() + 1,
        [
          Validators.required,
          Validators.pattern(/^\d{4}$/)
        ]
      ),
    });

    this.workCalendars = this.data.selectedWorkCalendars;
  }

  onNgModelChange($event) {
    this.selectedWorkCalendarId = $event;
  }

  /* ################################################################################################################## */
  /* ##
  /* ################################################################################################################## */
}
