import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { EmployeeCategoryHistory } from '../models/employee-category-history.model';

@Injectable({ providedIn: 'root' })
export class EmployeeCategoryHistoryService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private employeeCategoryHistorySubject: ReplaySubject<EmployeeCategoryHistory[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public employeeCategoryHistoryObservable: Observable<EmployeeCategoryHistory[]> = this.employeeCategoryHistorySubject.asObservable();

  private employeeCategoryHistoryRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public employeeCategoryHistoryRouteObservable: Observable<string> = this.employeeCategoryHistoryRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/employeeCategoryHistory';
  }

  constructor(private http: HttpClient) {
  }

  getAllEmployeeCategoryHistorys(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getEmployeeCategoryHistory(employeeCategoryHistoryId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/${employeeCategoryHistoryId}${filter}`;

    return this.http.get<EmployeeCategoryHistory>(url);
  }

  getCategoriesForEmployee(employeeId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/employee/${employeeId}${filter}`;

    return this.http.get<any>(url);
  }

  create(employeeCategoryHistory: EmployeeCategoryHistory) {
    const url = `${this.serviceUrl}`;

    return this.http.post<EmployeeCategoryHistory>(url, employeeCategoryHistory);
  }

  delete(employeeCategoryHistoryId: string) {
    const url = `${this.serviceUrl}/${employeeCategoryHistoryId}`;

    return this.http.delete<EmployeeCategoryHistory>(url);
  }

  updateEmployeeCategoryHistorys(employeeCategoryHistorys: any) {
    this.employeeCategoryHistorySubject.next(employeeCategoryHistorys);
  }

  updateEmployeeCategoryHistoryRoutes(id: string) {
    this.employeeCategoryHistoryRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
