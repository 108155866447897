import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { EmployeeType } from '../models/employee-type.model';


@Injectable({ providedIn: 'root' })
export class EmployeeTypeService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private employeeTypeSubject: ReplaySubject<EmployeeType[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeTypeObservable: Observable<EmployeeType[]> = this.employeeTypeSubject.asObservable();

    private employeeTypeRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeTypeRouteObservable: Observable<string> = this.employeeTypeRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/employeeType';
    }

    constructor(private http: HttpClient) {
    }

    getAllEmployeeTypes() {
        return this.http.get<any>(`${this.serviceUrl}`);
    }

    getEmployeeType(employeeTypeId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<EmployeeType>(`${this.serviceUrl}/${employeeTypeId}${relatedFilter}`);
    }

    createEmployeeType(employeeType: EmployeeType) {
        const endpointUrl = `${this.serviceUrl}`;
        return this.http.post(endpointUrl, employeeType);
    }
    modifyEmployeeType(employeeTypeId: string, newEmployeeType: any) {
        const endpointUrl = `${this.serviceUrl}/${employeeTypeId}`;
        return this.http.patch(endpointUrl, newEmployeeType);
    }
    deleteEmployeeType(employeeTypeId: string) {
        const endpointUrl = `${this.serviceUrl}/${employeeTypeId}`;
        return this.http.delete(endpointUrl);
    }

    updateEmployeeTypes(employeeTypes: EmployeeType[]) {
        this.employeeTypeSubject.next(employeeTypes);
    }

    updateEmployeeTypeRoutes(id: string) {
        this.employeeTypeRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}
