import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Degree } from 'src/app/models/degree.model';
import { Subscription } from 'rxjs';
import { DegreeService } from 'src/app/services/degree.service';
import { BaseFormComponent, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import * as jsonpatch from 'fast-json-patch';
import { MatDialog } from '@angular/material';
import { EmployeeSelectDialogComponent } from '../../employee-select-dialog/employee-select-dialog.component';
import { EmployeeDegree } from 'src/app/models/employee-degree.model';
import { Employee } from '../../../models/employee.model';
import { getDateString } from 'src/app/helpers/date.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmployeeSelectAssociateConfirmDialogComponent } from '../../employee-select-associate-confirm-dialog/employee-select-associate-confirm-dialog.component';

@Component({
  selector: 'app-degree-form',
  templateUrl: 'degree-form.component.html',
  styleUrls: ['./degree-form.component.css', './../../maintenance.components.css'],
})
export class DegreeFormComponent extends BaseFormComponent<Degree> implements OnInit, OnDestroy {
  selectedDesc: string = undefined;
  degree: Degree;

  roles: Role[];

  enabled = true;
  detail = false;
  permissionsLoaded = false;

  formGroupControl = new FormGroup({
    description: new FormControl('', Validators.required),
    code: new FormControl('', Validators.required),
  });

  canAdd = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;
  subscription: Subscription;

  constructor(
    private toastrService: ToastrService,
    private degreeService: DegreeService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
    }
    this.PK = 'degreeId';

    this.baseRoute = '/formations/degree';


    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('DEGREES_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  private init() {
    this.formGroupControl.get('description').disable();
    this.degree = new Degree();
    this.subscription = this.route.paramMap.subscribe(params => {
      const transId = params.get('id');
      this.degreeService.updateDegreeRoutes(transId);
      if (transId) {
        if (transId === 'new') {
          this.detail = false;
          this.enabled = true;
          this.degree = new Degree();
          this.selectedDesc = undefined;
          this.formGroupControl.setValue({ description: '', code: '' });
          this.formGroupControl.get('description').enable();
          this.formGroupControl.get('code').enable();
        } else {
          this.degreeService.getDegree(transId, true).subscribe(response => {
            this.detail = true;
            this.enabled = false;
            this.degree = response;

            this.formGroupControl.setValue({ description: this.degree.description, code: this.degree.code });
            this.formGroupControl.get('description').disable();
            this.formGroupControl.get('code').disable();
            this.selectedDesc = response.description;
          });
        }
      } else {
        this.selectedDesc = undefined;
        this.detail = false;
        this.enabled = false;
        this.degree = new Degree();
        this.formGroupControl.get('description').disable();
        this.formGroupControl.get('code').disable();
      }
    });
  }

  edit() {
    this.enabled = true;
    this.formGroupControl.get('description').enable();
    this.formGroupControl.get('code').enable();
  }

  cleanForm() {
    this.formGroupControl.setValue({ description: '', code: '' });
  }

  // ======================== CRUD ========================

  cancel() {
    this.cleanForm();
    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }

  back() {
    this.cancel();
  }

  associateEmployee() {
    const employeeSelectionDialog = this.dialog.open(EmployeeSelectDialogComponent, {
      maxWidth: '80%',
      maxHeight: '65%',
      data: {
        employees: this.degree.employeeDegrees
      }
    });

    employeeSelectionDialog.afterClosed().subscribe(async (selection: Employee) => {
      if (selection) {
        let text: string;
        this.translate.get('ASSOCIATE_EMPLOYEES.DIALOG_CONFIRMATION.TEXT_ADD', {
          employee: `${selection.firstSurname} ${selection.secondSurname}, ${selection.name}`
        }).subscribe((a: string) => {
          text = a;
        });

        const confirmationDialog = this.dialog.open(EmployeeSelectAssociateConfirmDialogComponent, {
          width: '550px',
          data: {
            asassociate: true,
            text
          }
        });

        confirmationDialog.afterClosed().subscribe(async (confirm) => {
          if (confirm != false) {
            this.spinner.show();

            this.degreeService.associateDegree(
              this.degree.degreeId,
              selection.employeeId,
              confirm.trainingCenterId,
              confirm.finishDate.toISOString()
            ).subscribe(
              response => {
                this.degreeService.getDegree(this.degree.degreeId, true).subscribe(degree => {
                  this.degree = degree;
                  let title: string;
                  let msg: string;

                  this.translate.get('MESSAGES.SUCCESS.UPDATE_LONG_SIMPLE', {type: this.degree.description}).subscribe((a: string) => {
                    title = a;
                  });

                  this.spinner.hide();
                  this.toastrService.success(msg, title, { timeOut: 3000 });
                });
              },
              error => {
                let title: string;
                this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
                  title = a;
                  this.toastrService.error(error.error, title, { timeOut: 3000 });
                });
              },
            );
          }
        });
      }
    });
  }

  disassociateEmployee(employeeDegree: EmployeeDegree) {
    let text: string;
    this.translate.get('ASSOCIATE_EMPLOYEES.DIALOG_CONFIRMATION.TEXT_REMOVE', {
      employee: `${employeeDegree.employee.firstSurname} ${employeeDegree.employee.secondSurname}, ${employeeDegree.employee.name}`
    }).subscribe((a: string) => {
      text = a;
    });

    const confirmationDialog = this.dialog.open(EmployeeSelectAssociateConfirmDialogComponent, {
      width: '550px',
      data: {
        asassociate: false,
        text
      }
    });

    confirmationDialog.afterClosed().subscribe(async (confirm) => {
      if (confirm == true) {
        this.spinner.show();

        this.degreeService.disassociateDegree(
          this.degree.degreeId,
          employeeDegree.employeeId
        ).subscribe(
          response => {
            this.degreeService.getDegree(this.degree.degreeId, true).subscribe(degree => {
              this.degree = degree;
              let title: string;
              let msg: string;

              this.translate.get('MESSAGES.SUCCESS.DELETE_LONG_SIMPLE', {type: this.degree.description}).subscribe((a: string) => {
                title = a;
              });

              this.spinner.hide();
              this.toastrService.success(msg, title, { timeOut: 3000 });
            });
          },
          error => {
            let title: string;

            this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
              title = a;
            });

            this.spinner.hide();
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          },
        );
      }
    });
  }

  getFinishDate(employeeDegree: EmployeeDegree) {
    return employeeDegree && employeeDegree.finishDate
      ? getDateString(employeeDegree.finishDate, "DD/MM/YYYY")
      : "";
  }
}
