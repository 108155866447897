export class ContractWorkingHoursType {
  contractWorkingHoursTypeId  : string;
  code                        : string;
  description                 : string;

  //Relations

  constructor(obj?: Partial<ContractWorkingHoursType>) {
    this.contractWorkingHoursTypeId = (obj && obj.contractWorkingHoursTypeId) || undefined;
    this.code                       = (obj && obj.code) || undefined;
    this.description                = (obj && obj.description) || undefined;

    //Relations
  }
}
