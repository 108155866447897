import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, BaseFormComponent, Hotkeys, Role } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { EmployeeCourse } from 'src/app/models/employee-course.model';
import { CertificationService } from 'src/app/services/certification.service';
import { EmployeeManagementChangesService } from 'src/app/services/employee-management-changes.service';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { FileService } from 'src/app/services/file.service';
import { Certification } from '../../../models/certification.model';
import { CourseCertificationDialogComponent } from '../course-certification-dialog/course-certification-dialog.component';

@Component({
  selector: 'app-course-form-item',
  templateUrl: './course-form-item.component.html',
  styleUrls: ['./course-form-item.component.css']
})
export class CourseFormItemComponent extends BaseFormComponent<EmployeeCourse> implements OnInit, OnDestroy {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  @Input() employeeCourse: EmployeeCourse;
  @Input() editable: boolean;
  @Input() employeeView: boolean = false;
  certification = new Certification();

  roles: Role[];

  fileToUpload: File = null;
  fileId : string;

  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  formGroupControl = new FormGroup({
    document: new FormControl(''),
  });

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private employeeManagementService: EmployeeManagementService,
    private fileService: FileService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private certificationService: CertificationService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private employeeManagementChanges: EmployeeManagementChangesService
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
    }
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();

    if(this.employeeCourse != null){ //TODO recorre todos los course ?
      this.initCertification();
    }

    this.formGroupControl.setValue({ document: '' });
    this.formGroupControl.get('document').enable();

    if(this.employeeView){
      this.employeeManagementChanges.canEdit.subscribe( canEdit => {
        this.editable = canEdit;

        if(this.editable){
          this.formGroupControl.get('document').enable();
        }else{
          this.formGroupControl.get('document').disable();
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
    this.employeeManagementChanges.onChangeCheck(false);
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          if (
            this.accountService.currentUser.isAdmin ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RA) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RP)
          ) {
          }
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  initCertification() {
    this.certification = this.employeeCourse.certification;
  }

  cleanForm() {
    this.formGroupControl.setValue({ document: '' });
  }

  openDocument() {
    this.fileService.getFile(this.employeeCourse.certification.fileId).subscribe(fichero => {
      this.fileService.getFileBlob(this.employeeCourse.certification.fileId).subscribe(file => {
        this.fileService.openPDF(file);
    });
  });
  }

  cancel() {
    this.cleanForm();
  }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files[0];
  }

  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  update(onlyRemove = false) {
    let labelMSG = 'CERTIFICATION_DOCUMENTS.DIALOG_CONFIRMATION.TEXT_ADD';
    let valueMSG = {};

    let text: string;

    if (onlyRemove) {
      labelMSG = 'CERTIFICATION_DOCUMENTS.DIALOG_CONFIRMATION.TEXT_REMOVE';
    } else if(
      this.certification != undefined &&
      this.certification != null
    ) {
      labelMSG = 'CERTIFICATION_DOCUMENTS.DIALOG_CONFIRMATION.TEXT_REPLACE';
      valueMSG = {
        value: this.fileToUpload.name,
      };
    } else {
      valueMSG = {
        value: this.fileToUpload.name
      };
    }

    this.translate.get(labelMSG, valueMSG).subscribe((a: string) => {
      text = a;
    });

    const confirmationDialog = this.dialog.open(CourseCertificationDialogComponent, {
      width: '550px',
      data: { text }
    });

    confirmationDialog.afterClosed().subscribe(async (confirm) => {
      if (confirm) {
        try {
          this.removeCertification().then((result) => {
            if (!onlyRemove) {
              this.addCertification();
            }
          });

          this.cancel();
        } catch (error) {
          console.log('error :>> ', error);
        }
      }
    });
  }

  addCertification() {
    const fileInput = this.fileToUpload;
    this.spinner.show();
    this.initCertification();

    if (this.certification == undefined || this.certification == null) {
      this.certification = new Certification();
      this.certification.employeeCourseId = this.employeeCourse.courseId;
    }

    this.certification.employeeCourse = this.employeeCourse;
    this.certification.employeeCourse.certification = null; // para evitar la referencia circular


    return new Promise((resolve, reject) => {
      this.employeeManagementService.getEmployee(this.employeeCourse.employeeId, false).subscribe(employee => {
        this.fileService.getCourses(employee.login,false).subscribe(courses => {
          const formData = new FormData();
          //Construimos formdata
          formData.append('file', fileInput, fileInput.name);
           formData.append('directoryId', courses.items[0].directoryId);
          formData.append('name', fileInput.name);
          formData.append('path', employee.login+'/Certifications/Courses');
          this.fileService.addFile(formData).subscribe(response => {
            if(response){
              this.toastrService.success('StorageFile ' + fileInput.name + ' subido con éxito');
              this.certification.fileId =  response.fileId;
              this.certificationService.addCertification(
                this.employeeCourse.employeeId,
                this.certification
              ).subscribe(
                response => {
                  let result = false;

                  try {
                    this.employeeCourse.certification = response;
                    this.employeeCourse.employeeDocumentId = response.employeeDocumentId;
                    this.certification = response;

                    result = true;
                  } catch (error) {
                    console.log('error :>> ', error);
                  }
                  this.spinner.hide();
                  resolve(result);
                },
                error => {
                  let title: string;
                  this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
                    title = a;
                    this.toastrService.error(error.error, title, { timeOut: 3000 });
                    this.spinner.hide();
                  });
                },
              );
            }
          }, error => {
            this.toastrService.error('No se ha podido añadir el fichero.', error);
            this.spinner.hide();
          });
        }, error => {
          this.toastrService.error('No se ha podido recuperar datos del directorio en el servidor de ficheros.', error);
          this.spinner.hide();
        });

      }, error => {
        this.toastrService.error('No se ha podido recuperar los datos del empleado.', error);
        this.spinner.hide();

      });
    });
  }

  removeCertification() {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      if (
        this.employeeCourse.employeeDocumentId != undefined &&
        this.employeeCourse.employeeDocumentId != null
      ) {
        this.certificationService.removeCertification(
          this.employeeCourse.employeeId,
          this.employeeCourse.employeeDocumentId
        ).subscribe(
          response => {
            if (response) {
              this.fileService.deleteFileFromStorage(this.employeeCourse.certification.fileId).subscribe(response => {
                this.spinner.hide();
                if(response){
                  this.toastrService.success('StorageFile eliminado con éxito');
                }
              this.certification = null;
              this.employeeCourse.certification = null;
              this.employeeCourse.employeeDocumentId = null;
            }, error => {
              this.toastrService.error('No se ha podido eliminar el documento.', error);
              this.spinner.hide();
            });

            }

            resolve(response);
          },
          error => {
            let title: string;
            this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
              title = a;
              this.toastrService.error(error.error, title, { timeOut: 3000 });
              this.spinner.hide();
            });
          },
        );
      } else {
        resolve(Promise.resolve(false));
        this.spinner.hide();
      }
    });
  }
}
