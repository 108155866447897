export function customContains(attribute: String, value: any): boolean {
  if ((typeof value === "string" || value instanceof String)) {
    if (value == " ") {
      return true;
    }
    let normalizedAttribute = attribute.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    let normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    return normalizedAttribute.toLowerCase().indexOf(normalizedValue.toLowerCase()) != -1;
  } else {
    return true;
  }
}

export function customStartsWith(attribute: String, value: String): boolean {
  if ((typeof value === "string" || value instanceof String)) {
    if (value == " ") {
      return true;
    }
    let normalizedAttribute = attribute.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    let normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    return normalizedAttribute.toLowerCase().indexOf(normalizedValue.toLowerCase()) == 0;
  } else {
    return true;
  }
}
