import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Centre } from 'src/app/models/centre.model';
import { Subscription } from 'rxjs';
import { CentreService } from 'src/app/services/centre.service';
import { BaseFormComponent, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { WorkCalendarService } from 'src/app/services/work-calendar.service';
import * as jsonpatch from 'fast-json-patch';
import { MatDialog } from '@angular/material';
import { CentreConfirmationDeleteDialogComponent } from '../centre-confirmation-delete-dialog/centre-confirmation-delete-dialog.component';

@Component({
  selector: 'app-centre-form',
  templateUrl: 'centre-form.component.html',
  styleUrls: [
    './centre-form.component.css',
    './../../maintenance.components.css',
  ],
})
export class CentreFormComponent extends BaseFormComponent<Centre> implements OnInit, OnDestroy {
  selectedDesc: string = undefined;
  centre: Centre;
  allWorkCalendars: WorkCalendar[];

  enabled = true;
  detail = false;

  roles: Role[];

  formGroupControl = new FormGroup({
    name: new FormControl('', Validators.required),
    ccc: new FormControl('', Validators.required),
    workCalendar: new FormControl('', Validators.required),
  });

  canAdd = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;
  subscription: Subscription;

  constructor(
    private toastrService: ToastrService,
    private centreService: CentreService,
    private workCalendarService: WorkCalendarService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ) {
      this.canAdd = true;
      this.canDelete = true;
    }
    this.PK = 'centreId';

    this.baseRoute = '/maintenances/centre';


    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('CENTRES_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
    this.init();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          if (
            this.accountService.currentUser.isAdmin ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RA) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RP)
          ) {
          }
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  private init() {
    this.formGroupControl.get('name').disable();
    this.formGroupControl.get('ccc').disable();
    this.formGroupControl.get('workCalendar').disable();
    this.centre = new Centre();
    this.subscription = this.route.paramMap.subscribe(params => {
      this.workCalendarService.getAllWorkCalendar().subscribe(workCalendarsResponse => {
        this.allWorkCalendars = workCalendarsResponse.items;
        const transId = params.get('id');
        this.centreService.updateCentreRoutes(transId);
        if (transId) {
          if (transId === 'new') {
            this.detail = false;
            this.enabled = true;
            this.centre = new Centre();
            this.selectedDesc = undefined;
            this.formGroupControl.setValue({ name: '', ccc: '', workCalendar: '' });
            this.formGroupControl.get('name').enable();
            this.formGroupControl.get('ccc').enable();
            this.formGroupControl.get('workCalendar').enable();
          } else {
            this.centreService.getCentre(transId, true).subscribe(response => {
              this.detail = true;
              this.enabled = false;
              this.centre = response;
              const workCalendarSelected = this.allWorkCalendars.find(workCalendar => workCalendar.workCalendarId === this.centre.workCalendarId);
              this.formGroupControl.setValue({ name: this.centre.name, ccc: this.centre.ccc, workCalendar: workCalendarSelected });
              this.formGroupControl.get('name').disable();
              this.formGroupControl.get('ccc').disable();
              this.formGroupControl.get('workCalendar').disable();
              this.selectedDesc = response.name;
            });
          }
        } else {
          this.selectedDesc = undefined;
          this.detail = false;
          this.enabled = false;
          this.centre = new Centre();
        }
      });
    });
  }

  edit() {
    this.enabled = true;
    // this.formGroupControl.get('name').enable();
    this.formGroupControl.get('ccc').enable();
    this.formGroupControl.get('workCalendar').enable();
  }

  cleanForm() {
    this.formGroupControl.setValue({ name: '', ccc: '', workCalendar: '' });
  }

  assingWorkCalendar(e) {
    console.log('e -> ', e);
    this.formGroupControl.get('workCalendar').setValue(e.option.value);
  }

  displayWorkCalendar(workCalendar: WorkCalendar): string | undefined {
    return (workCalendar) ? workCalendar.name : undefined;
  }

  // ======================== CRUD ========================

  create() {
    this.centre = new Centre({
        name: this.formGroupControl.get('name').value,
        ccc: this.formGroupControl.get('ccc').value,
        workCalendarId: this.formGroupControl.get('workCalendar').value.workCalendarId,
    });
    this.centreService.createCentre(this.centre).subscribe(
      response => {
        this.centreService.getAllCentres().subscribe(centres => {
          this.centreService.updateCentres(centres.items);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.CREATE_LONG', {
            type: this.componentTypeTranslated,
            code: this.centre.name,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.cancel();
      },
      error => {
        if (error.status === 500 || error.status === 0) {
        } else if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
        }
      },
    );
  }

  update() {
    const observer = jsonpatch.observe(this.centre);
    this.centre.name = this.formGroupControl.get('name').value;
    this.centre.ccc = this.formGroupControl.get('ccc').value;
    this.centre.workCalendarId = this.formGroupControl.get('workCalendar').value.workCalendarId;
    const patch = jsonpatch.generate(observer);

    this.centreService.modifyCentre(this.centre.centreId,patch).subscribe(
      response => {
        this.centreService.getAllCentres().subscribe(centres => {
          this.centreService.updateCentres(centres.items);
        });
        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );
  }

  delete() {
    const confirmationDialog = this.dialog.open(CentreConfirmationDeleteDialogComponent, {
      width: '550px',
      data: {
        centreName: this.centre.name
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.centreService.deleteCentre(this.centre.centreId).subscribe(response => {
          this.centreService.getAllCentres().subscribe(centres => {
            this.centreService.updateCentres(centres.items);
          });
          let toastTitle = '';
          let toastBody = '';
          this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {
            toastTitle = a;
          });
          this.translate.get('MESSAGES.SUCCESS.DELETE_LONG', {type: this.componentTypeTranslated, code: this.centre.name}).subscribe((a: string) => {
            toastBody = a;
          });
          this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
          this.cancel();
        });
      }
    });
  }

  cancel() {
    this.cleanForm();
    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }

  back() {
    this.cancel();
  }
}
