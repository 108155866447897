import { Absence } from "./absence.model";

export class AbsenceType {
  absenceTypeId   : string;
  code            : string;
  description     : string;
  isMedicalLeave  : boolean;

  //Relations

  constructor(obj?: Partial<AbsenceType>) {
    this.absenceTypeId  = (obj && obj.absenceTypeId) || undefined;
    this.code           = (obj && obj.code) || undefined;
    this.description    = (obj && obj.description) || undefined;
    this.isMedicalLeave = (obj && obj.isMedicalLeave) || false;

    //Relations
  }
}
