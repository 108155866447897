import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-maintenance-it-leave-management',
  templateUrl: './it-leave-management.component.html',
  styleUrls: ['./../maintenance.components.css'],
})
export class ITLeaveManagementComponent implements AfterViewInit, OnInit {
  constructor() { }

  ngAfterViewInit() {
  }
  ngOnInit() {
  }
}
