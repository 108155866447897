import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { FilterApi } from '../interfaces/filter-api.interface';
import { HolidayRequestStateLabel } from '../interfaces/holidays.interface';
import { Employee } from '../models/employee.model';
import { FiltersApiIds } from '../models/filters-api-ids.model';
import { HolidayRequest } from '../models/holiday-request.model';

@Injectable({
  providedIn: 'root'
})
export class HolidayRequestChangesService {
  private refreshEvent = new BehaviorSubject<boolean>(false);
  public refresh = this.refreshEvent.asObservable();

  private checkEvent = new BehaviorSubject<boolean>(false);
  public check = this.checkEvent.asObservable();

  private canDirectAssignmentEvent = new BehaviorSubject<boolean>(false);
  public canDirectAssignment = this.canDirectAssignmentEvent.asObservable();

  private holidayRequestsEvent = new ReplaySubject<HolidayRequest[]>(null);
  public holidayRequests: Observable<HolidayRequest[]> = this.holidayRequestsEvent.asObservable();

  private currentEmployeeEvent = new BehaviorSubject<Employee>(null);
  public currentEmployee = this.currentEmployeeEvent.asObservable();

  private holidayRequestValidatorFilterEvent = new BehaviorSubject<FilterApi>(null);
  public holidayRequestValidatorFilter = this.holidayRequestValidatorFilterEvent.asObservable();

  private filtersHolidayRequestsEmployeeIdsEvent = new BehaviorSubject<FiltersApiIds>(null);
  public filtersHolidayRequestsEmployeeIds = this.filtersHolidayRequestsEmployeeIdsEvent.asObservable();

  private selectedStateRequestEvent = new BehaviorSubject<HolidayRequestStateLabel>(null);
  public selectedStateRequest = this.selectedStateRequestEvent.asObservable();

  constructor() { }

  refreshData(data: boolean) {
    this.refreshEvent.next(data);
  }

  onChangeCheck(data: boolean) {
    this.checkEvent.next(data);
  }

  onChangecanDirectAssignment(data: boolean) {
    this.canDirectAssignmentEvent.next(data);
  }

  onChangeHolidayRequests(data: HolidayRequest[]) {
    this.holidayRequestsEvent.next(data);
  }

  onChangeCurrentEmployee(data: Employee) {
    this.currentEmployeeEvent.next(data);
  }

  onChangeHolidayRequestValidatorFilter(data: FilterApi) {
    this.holidayRequestValidatorFilterEvent.next(data);
  }

  onChangeFiltersHolidayRequestsEmployeeIds(data: FiltersApiIds) {
    this.filtersHolidayRequestsEmployeeIdsEvent.next(data);
  }

  onChangeSelectedStateRequest(data: HolidayRequestStateLabel) {
    this.selectedStateRequestEvent.next(data);
  }

  // Reset Observables
  resetObservables() {
    this.refreshData(false);
    this.onChangeCheck(false);
    this.onChangecanDirectAssignment(null);
    this.onChangeHolidayRequests(null);
    this.onChangeCurrentEmployee(null);
    this.onChangeHolidayRequestValidatorFilter(null);
    this.onChangeFiltersHolidayRequestsEmployeeIds(null);
    this.onChangeSelectedStateRequest(null);
  }
}
