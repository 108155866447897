import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Category } from 'src/app/models/category.model';
import { EmployeeSituation } from 'src/app/models/employee-situation.model';
import { Employee } from '../../../models/employee.model';
import { EmployeeType } from '../../../models/employee-type.model';
import { Area } from '../../../models/area.model';

@Component({
  selector: 'app-employee-history-dlg',
  templateUrl: './employee-history-dialog.component.html',
  styleUrls: ['./employee-history-dialog.component.css']
})
export class EmployeeHistoryDlgComponent implements OnInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  selectedView        : string = 'situation';
  employee            : Employee;

  areas               : Area[] = [];
  categories          : Category[] = [];
  employees           : Employee[] = [];
  employeeSituations  : EmployeeSituation[] = [];
  employeeTypes       : EmployeeType[] = [];

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
  ) { }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    if (this.data) {
      if (this.data.selectedView) {
        this.selectedView = this.data.selectedView;
      }

      if (this.data.areas) {
        this.areas = this.data.areas;
      }

      if (this.data.categories) {
        this.categories = this.data.categories;
      }

      if (this.data.employee) {
        this.employee = this.data.employee;
      }

      if (this.data.employees) {
        this.employees = this.data.employees;
      }

      if (this.data.employeeSituations) {
        this.employeeSituations = this.data.employeeSituations;
      }

      if (this.data.employeeTypes) {
        this.employeeTypes = this.data.employeeTypes;
      }
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  //#endregion

  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
}
