import { Employee } from './employee.model';

export class EmployeeValidatorHistory {
  employeeValidatorHistoryId: string;
  startDate: Date;

  //Relations
  validatorId: string;
  employeeId: string;
  employee: Employee;
  validator : Employee;

  constructor(obj?: Partial<EmployeeValidatorHistory>) {
    this.employeeValidatorHistoryId = (obj && obj.employeeValidatorHistoryId) || undefined;
    this.startDate = (obj && obj.startDate) || undefined;

    //Relations
    this.validatorId = (obj && obj.validatorId) || undefined;
    this.employeeId = (obj && obj.employeeId) || undefined;
    this.employee = (obj && obj.employee) || undefined;
    this.validator = (obj && obj.employee) || undefined;
  }
}
