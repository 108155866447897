import { Component, ViewChild, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { transformDate } from 'src/app/helpers/date.helper';
import { HRADateFilterService } from 'src/app/services/hra-date-filter.service';
import { Employee } from 'src/app/models/employee.model';
import { Subscription } from 'rxjs';
import { getDateTimeString } from 'src/app/helpers/date.helper';
import { EmployeeCategoryHistory } from 'src/app/models/employee-category-history.model';
import { Category } from 'src/app/models/category.model';
import { DataHistoryService } from '../../../services/data-history.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as Rxjs from 'rxjs';
import * as _ from 'lodash';
import moment = require('moment');
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { EmployeeCategoryHistoryService } from '../../../../../services/employee-category-history.service';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';

@Component({
  selector: 'app-category-history-list',
  templateUrl: './employee-category-history-list.component.html',
  styleUrls: [
    './employee-category-history-list.component.css',
    '../../../../../../assets/styles/action.components.css',
    '../../../../../../assets/styles/action-list.component.css',
  ]
})
export class EmployeeCategoryHistoryListComponent implements OnInit, OnDestroy {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  showAddPanel = false;
  employeeView = false;
  employee: Employee = null;
  employees: Employee[] = [];
  categories: Category[] = [];
  displayedColumns: string[] = [
    'actions',
    'startDate',
    'createdByUserId',
    'categoryId',
    'quotationGroupEquivalence',
  ];

  startDateFilter: string;
  finishDateFilter: string;

  ELEMENT_DATA: EmployeeCategoryHistory[] = [];
  dataSource: MatTableDataSource<EmployeeCategoryHistory>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  subscriptions: Subscription[] = [];

  currentEmployeeCategoryHistoryService : EmployeeCategoryHistory = new EmployeeCategoryHistory();

  categoryHistoryFormGroup: FormGroup = new FormGroup({
    startDate                 : new FormControl('', Validators.required),
    careerPlan                : new FormControl('', Validators.required),
    categoryShort             : new FormControl('', Validators.required),
    categoryCode              : new FormControl('', Validators.required),
    quotationGroupEquivalence : new FormControl('', Validators.required),
  });

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private dataHistoryService: DataHistoryService,
    private hraDateFilterService: HRADateFilterService,
    private employeeCategoryHistoryService: EmployeeCategoryHistoryService,
    protected permissionsService: PermissionsService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
  ) {
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;
      })
    );

    // EmployeeView
    this.subscriptions.push(
      this.dataHistoryService.employeeView.subscribe(data => {
        this.employeeView = data;
      })
    );

    // Categories
    this.subscriptions.push(
      this.dataHistoryService.categories.subscribe(data => {
        this.categories = data;
      })
    );

    // Employees
    this.subscriptions.push(
      this.dataHistoryService.employees.subscribe(data => {
        this.employees = data;
      })
    );

    // Employee
    this.subscriptions.push(
      this.dataHistoryService.employee.subscribe(data => {
        this.employee = data;

        this.loadDataOnTable();
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  //#endregion

  /* ################################################################################################################## */
  /* ## DATA METHODS
  /* ################################################################################################################## */
  loadDataOnTable() {
    let filters: string = "";

    if (this.employee != undefined && this.employee != null) {
      this.ELEMENT_DATA = this.employee.employeeCategoriesHistory;

      // Se busca el valor actual
      this.assigCurrentCategory();

      this.ELEMENT_DATA = _.orderBy(this.ELEMENT_DATA, a => moment(a.startDate).unix(), 'desc');
      this.dataSource = new MatTableDataSource<EmployeeCategoryHistory>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  getAndLoadDataOnTable() {
    this.employeeCategoryHistoryService.getCategoriesForEmployee(this.employee.employeeId).subscribe(CategoryHisoryResponse => {
      this.employee.employeeCategoriesHistory = (CategoryHisoryResponse && CategoryHisoryResponse.items) || [];

      this.loadDataOnTable();

      // Se propaga el cambio a los observadores
      this.dataHistoryService.setEmployeeChange(this.employee);
      
      this.spinner.hide();
    },
    error => {
      this.spinner.hide();
    });
  }

  onCreate() {
    if (this.categoryHistoryFormGroup && this.categoryHistoryFormGroup.valid) {
      this.spinner.show();

      this.getCategoryToSend();

      this.employeeCategoryHistoryService.create(this.currentEmployeeCategoryHistoryService).subscribe(response => {
        this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.CATEGORY.CREATION_SUCCESS').subscribe((text: string) => {
          this.toastrService.success(text, '', { timeOut: 3000 });
        });

        this.resetForm();

        this.getAndLoadDataOnTable();

        this.showAddPanel = false;
      },
      error => {
        console.error('error --> ', error);
        this.spinner.hide();
        if(error.error === 'MISSING_PARAMS') {
          this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.CATEGORY.ERROR.MISSING_PARAMS').subscribe((text: string) => {
            this.toastrService.error(text, '', { timeOut: 3000 });
          });
        } else {
          this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.CATEGORY.ERROR.UNEXPECTED').subscribe((text: string) => {
            this.toastrService.error(text, '', { timeOut: 3000 });
          });
        }
      });
    }
  }

  onRemove(employeeCategoryHistory: EmployeeCategoryHistory) {
    let text;
    this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.MESSAGE.CATEGORY.DELETE_CONFIRMATION.TEXT').subscribe((transitionStr: string) => {
      text = transitionStr;
    });

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      data: {
        text
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.spinner.show();
        this.employeeCategoryHistoryService.delete(employeeCategoryHistory.employeeCategoryHistoryId).subscribe(response => {
            this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.CATEGORY.DELETION_SUCCESS').subscribe((text: string) => {
              this.toastrService.success(text, '', { timeOut: 3000 });
            });

            this.getAndLoadDataOnTable();
        },
        error => {
          this.spinner.hide();
          this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.CATEGORY.ERROR.UNEXPECTED').subscribe((text: string) => {
            this.toastrService.error(text, '', { timeOut: 3000 });
          });
        });
      }
    });
  }

  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  assigCurrentCategory() {
    // Se busca el valor actual
    const currentCategoryHistory = _(this.employee.employeeCategoriesHistory).filter(x => moment(x.startDate) <= moment()).maxBy(x => moment(x.startDate).unix());

    if (currentCategoryHistory) {
      this.employee.category = this.getCategoryById(currentCategoryHistory.categoryId);
      this.employee.categoryId = currentCategoryHistory.categoryId;
    }
  }

  resetForm() {
    this.showAddPanel = false;
    Object.keys(this.categoryHistoryFormGroup.controls).forEach((name) => {
      const currentControl = this.categoryHistoryFormGroup.controls[name];

      currentControl.setValue('');
    });

    this.categoryHistoryFormGroup.markAsPristine();
    this.categoryHistoryFormGroup.clearValidators();
  }

  /* ****************************************************************************************************************** */
  /* ** Category
  /* ****************************************************************************************************************** */
  getCategoryToSend() {
    this.currentEmployeeCategoryHistoryService.employeeId = this.employee.employeeId;
    this.currentEmployeeCategoryHistoryService.startDate = moment(this.categoryHistoryFormGroup.get('startDate').value, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }

  /* ################################################################################################################## */
  /* ## AUTOCOMPLETE METHODS
  /* ################################################################################################################## */
  // Category
  categorySelectedOptionChanged(e: any) {
    const item: Category = e.option.value;

    if (!_.isNil(item) && item.careerPlan !== "Sin asignar") {
      this.currentEmployeeCategoryHistoryService.categoryId = item.categoryId;

      this.categoryHistoryFormGroup.get('categoryShort').setValue(item.categoryShort);
      this.categoryHistoryFormGroup.get('categoryCode').setValue(item.categoryCode);
      this.categoryHistoryFormGroup.get('quotationGroupEquivalence').setValue(item.quotationGroupEquivalence);
    } else {
      this.currentEmployeeCategoryHistoryService.categoryId = undefined;

      this.categoryHistoryFormGroup.get('categoryShort').setValue('');
      this.categoryHistoryFormGroup.get('categoryCode').setValue('');
      this.categoryHistoryFormGroup.get('quotationGroupEquivalence').setValue('');
    }
  }

  // Category
  categoryDisplay = (item?: Category) => {
    let result = (item)
      ? item.careerPlan
      : undefined;

    return result;
  }

  onStartDateSelect(event: any): void {
    const date = event.target.value;
    this.categoryHistoryFormGroup.get('startDate').setValue(moment(date).format('DD/MM/YYYY'));
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  getCategory(categoryId) {
    let category = this.categories.find(element => element.categoryId == categoryId);
    let result = (category != null || category.categoryCode != null)
      ? `(${category.categoryCode}) ${category.careerPlan}`
      : "";

    return result;
  }


  getCategoryById(categoryId): Category {
    let category;

    if(this.categories) {
      category = this.categories.find(element => element.categoryId == categoryId);
    }
    
    return category;
  }

  getCreatedBy(employeeId) {
    let employee = this.employees.find(element => element.employeeId == employeeId);
    let result = (employee != null)
      ? `${employee.secondSurname} ${employee.firstSurname}, ${employee.name}`
      : "";

    return result;
  }

  getDate(date: any, format: string): string {
    let result = getDateTimeString(date, format);

    return result != "Invalid date"
      ? result
      : "";
  }

  isCurrentValue(item): boolean {
    const currentCategoryHistory = _(this.employee.employeeCategoriesHistory).filter(x => moment(x.startDate) < moment()).maxBy(x => moment(x.startDate).unix());
    return (currentCategoryHistory)
      ? currentCategoryHistory.employeeCategoryHistoryId == item.employeeCategoryHistoryId
      : false;
  }

  canAdd(): boolean {
    return (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH);
  }

  canDelete(item): boolean {
    let result = (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH);

    return result;
  }
}
