// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DataChangesService } from '../../../services/data-changes.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { AccountService, Hotkeys, Permission, Role, User, UserEdit } from 'inzo-portalempleado';

import { Employee } from '../../../../../models/employee.model';
import { IFormEdit, IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { EmployeeManagementInfo } from 'src/app/models/employee-management-info.model';
import { Area } from 'src/app/models/area.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Category } from 'src/app/models/category.model';
import { Centre } from 'src/app/models/centre.model';
import { ContractType } from 'src/app/models/contract-type.model';
import { EmployeeType } from 'src/app/models/employee-type.model';
import { Headquarters } from 'src/app/models/headquarters.model';
import { LaborAgreement } from 'src/app/models/labor-agreement.model';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { customStartsWith } from 'src/app/helpers/filter-data.helper';
import { EmployeeHistoryDialogComponent } from '../../employee-history/employee-history-dialog/employee-history-dialog.component';
import { SBA } from 'src/app/models/sba.model';
import { DataHistoryService } from '../../../services/data-history.service';
import { ResponsiveBreakPoints } from 'src/app/models/responsiveBreakPoints.model';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';

import { DateMaskDirective } from 'src/app/directives/date-mask.directive';
import { DateTimeMaskDirective } from 'src/app/directives/date-time-mask.directive';

// ################################################################################################################
// ## CLASS MgntFormCompanyComponent
// ################################################################################################################
@Component({
  selector: 'app-mgnt-form-company',
  templateUrl: './mgnt-form-company.component.html',
  styleUrls: [
    './mgnt-form-company.component.css',
  ],
  providers:[
    DateMaskDirective,
    DateTimeMaskDirective
  ],
})
export class MgntFormCompanyComponent implements OnInit {
  /* ############################################################################################################## */
  /* ## ATRRIBUTES
  /* ############################################################################################################## */
  employeeView: boolean = false;
  employeeManagementInfo: EmployeeManagementInfo;
  employee: Employee;
  sba: SBA;

  selectedDesc: string = undefined;
  permissionList: Permission[];
  userPermission: Permission;

  backForm = true;
  userEdit: UserEdit;

  subscriptions: Subscription[] = [];

  // --------------------------------------------------------------------------------------------------------------
  // Permisos
  // --------------------------------------------------------------------------------------------------------------
  allUsers: User[];
  roles: Role[];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }
  // --------------------------------------------------------------------------------------------------------------

  loadEmployeeData;
  disabilityChecked;
  enabled = true;
  detail = false;
  deleted = false;
  permissionsLoaded = false;
  visible = true;
  userFilter = '';
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  workcalendarCtrl = new FormControl();
  // filteredAreas: Observable<Area[]>;
  filteredCategories: Observable<Category[]>
  filteredCentres: Observable<Centre[]>;
  filteredContractTypes: Observable<ContractType[]>;
  filteredEmployeeTypes: Observable<EmployeeType[]>;
  filteredHeadquarters: Observable<Headquarters[]>;
  filteredLaborAgreements: Observable<LaborAgreement[]>;
  filteredManagers: Observable<Employee[]>;
  filteredUsers: Observable<User[]>;
  filteredValidators: Observable<Employee[]>;
  filteredWorkcalendars: Observable<WorkCalendar[]>;
  createMode: boolean = false;
  editable: boolean = false;

  // --------------------------------------------------------------------------------------------------------------

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  RAEdit: IFormEdit = {
    CompanyFormGroup: [],
  }

  RPEdit: IFormEdit = {
    CompanyFormGroup: [],
  }

  notEditFields: IFormEdit = {
    CompanyFormGroup: [
      "area",
      "manager",
      "validator",
    ],
  }

  // ------------------------------------------------------------------------------------------------------------------
  // CompanyFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  CompanyFormGroup = new FormGroup({
    area: new FormControl(''),
    centre: new FormControl('', Validators.required),
    headquarter: new FormControl('', Validators.required),
    manager: new FormControl(''),
    validator: new FormControl(''),
    permission: new FormControl(''),
    user: new FormControl(''),
    workcalendar: new FormControl('', Validators.required),
  });
  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    protected permissionsService: PermissionsService,
    private dataChangesService: DataChangesService,
    private dataHistoryService: DataHistoryService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
    this.disableForm();
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // Get Form Data
    this.subscriptions.push(
      this.dataChangesService.formData.subscribe(data => {
        if (data) {
          this.assignEmployeeData();
        }
      })
    );

    this.subscriptions.push(
      this.dataChangesService.allUsers.subscribe(data => {
        this.allUsers = data;
      })
    );

    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;
      })
    );

    this.subscriptions.push(
      this.dataChangesService.employeeManagementInfo.subscribe(data => {
        this.employeeManagementInfo = data;

        if (data && data.employee) {
          this.employee = data.employee;
        }

        this.loadDataOnForm();
      })
    );

    this.subscriptions.push(
      this.dataChangesService.sba.subscribe(data => {
        this.sba = data;
      })
    );

    // CreateMode
    this.subscriptions.push(
      this.dataChangesService.createMode.subscribe(data => {
        this.createMode = data;
      })
    );

    // EditMode
    this.subscriptions.push(
      this.dataChangesService.editMode.subscribe(data => {
        this.editable = data;

        if (data === true) {
          this.edit();
        } else {
          this.cancel();
        }
      })
    );

    this.subscriptions.push(
      this.dataChangesService.resetFormDirty.subscribe(data => {
        if (data) {
          this.resetFormDirty();
        }
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  loadDataOnForm() {
    if (this.employee) {
      var areaSelected          : Area = null;
      var centreSelected        : Centre;
      var headquarterSelected   : Headquarters;
      var managerSelected       : Employee;
      var selectedUser          : User;
      var validatorSelected     : Employee;
      var workcalendarSelected  : WorkCalendar;

      // ----------------------------------------------------------------------------------------------------------------
      // Inicializar datos
      // ----------------------------------------------------------------------------------------------------------------
      if (this.allUsers && this.employee) {
        // Si el usuario no ha iniciado sesión aún es posible que no se haya registrado el userId de identity
        if (this.employee.userId) {
          selectedUser = this.allUsers.find(user =>
            user.id === this.employee.userId
          );
        }

        if (this.employee.login) {
          if (!selectedUser) {
            selectedUser = this.allUsers.find(user =>
               user.userName === this.employee.login
            );
          }
        }
      }

      if (this.employeeManagementInfo) {
        if (this.employeeManagementInfo.allAreas) {
          areaSelected = this.employeeManagementInfo.allAreas.find(area => area.areaId === this.employee.areaId);
        }

        if (this.employee.centreId && this.employeeManagementInfo.allCentres) {
          centreSelected = this.employeeManagementInfo.allCentres.find(centre => centre.centreId === this.employee.centreId);
        }

        if (this.employee.headquartersId && this.employeeManagementInfo.allHeadquarters) {
          headquarterSelected = this.employeeManagementInfo.allHeadquarters.find(headquarter => headquarter.headquartersId === this.employee.headquartersId);
        }

        if (this.employee.managerId && this.employeeManagementInfo.allManagers) {
          managerSelected = this.employeeManagementInfo.allManagers.find(manager => manager.employeeId === this.employee.managerId);
        }

        if (this.employee.validatorId && this.employeeManagementInfo.allValidators) {
          validatorSelected = this.employeeManagementInfo.allValidators.find(validator => validator.employeeId === this.employee.validatorId);
        }

        if (this.employee.workCalendarId && this.employeeManagementInfo.allWorkcalendars) {
          workcalendarSelected = this.employeeManagementInfo.allWorkcalendars.find(workcalendar => workcalendar.workCalendarId === this.employee.workCalendarId);
        }
      }

      // ------------------------------------------------------------------------------------------------------------------
      // CompanyFormGroup
      // ------------------------------------------------------------------------------------------------------------------
      this.CompanyFormGroup.setValue({
        area          : (areaSelected)
          ? areaSelected.description
          : null,
        centre        : (centreSelected)
          ? centreSelected
          : null,
        headquarter   : (headquarterSelected)
          ? headquarterSelected
          : null,
        manager       : (managerSelected)
          ? managerSelected
          : null,
        permission    : null,
        user          : (selectedUser)
          ? this.displayUser(selectedUser)
          : this.employee.login || null,
        validator     : (validatorSelected)
          ? validatorSelected
          : null,
        workcalendar  : (workcalendarSelected)
          ? workcalendarSelected
          : null,
      });
      this.CompanyFormGroup.get('area').disable();
    }

    // ------------------------------------------------------------------------------------------------------------------
    // Filtros
    // ------------------------------------------------------------------------------------------------------------------
    this.addFilters();

    // ------------------------------------------------------------------------------------------------------------------
    // Informar cambios
    // ------------------------------------------------------------------------------------------------------------------
    this.onChangesForms();
  }

  onChangesForms(): void {
    // ------------------------------------------------------------------------------------------------------------------
    // CompanyFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    this.CompanyFormGroup.valueChanges.subscribe(val => {
      this.assignEmployeeData();
    });
  }

  cleanForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // CompanyFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.CompanyFormGroup.controls).forEach((name) => {
      const currentControl = this.CompanyFormGroup.controls[name];

      currentControl.setValue('');
    });
  }

  disableForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // CompanyFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.CompanyFormGroup.controls).forEach((name) => {
      const currentControl = this.CompanyFormGroup.controls[name];

      currentControl.disable();
    });
  }

  enableForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // CompanyFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.CompanyFormGroup.controls).forEach((name) => {
      const currentControl = this.CompanyFormGroup.controls[name];
      let continueEnable: boolean = false;

      // Si el nombre del campo no está entre los deshabilitados
      if (!this.notEditFields.CompanyFormGroup.includes(name)) {
        if (this.userRolesPermissions.isRRHH) {
          continueEnable = true;
        } else if (this.userRolesPermissions.isRA && this.RAEdit.CompanyFormGroup.includes(name)) {
          continueEnable = true;
        } else if (this.userRolesPermissions.isRP && this.RPEdit.CompanyFormGroup.includes(name)) {
          continueEnable = true;
        }
      }

      if (continueEnable) {
        currentControl.enable();
      }
    });
  }

  edit() {
    this.enabled = true;
    this.enableForm();
  }

  resetFormDirty() {
    // Quita las marcas "dirty" de los inputs
    this.CompanyFormGroup.markAsPristine();
  }

  cancel() {
    this.enabled = false;
    this.disableForm();
    this.loadDataOnForm();
  }

  resetForm() {
    this.loadDataOnForm();

    // Quita las marcas "dirty" de los inputs
    this.resetFormDirty();
  }
  //#endregion

  //#region ACTIONS METHODS
  /* ################################################################################################################## */
  /* ## ACTIONS METHODS
  /* ################################################################################################################## */
  /**
   * Abre un cuadro de diálogo cargando el histórico que se pasa como argumento.
   *
   * @param target nombre del histórico que se quiere mostrar
   */
  openEmployeeContractualHistorical(target: string) {
    this.dataHistoryService.setEmployeeViewChange(false);
    this.dataHistoryService.setSelectedViewChange(target);

    const employeeHistoryDialog = this.dialog.open(EmployeeHistoryDialogComponent, {
      width: window.innerWidth < 1400 ?  '90vw' : ( window.innerWidth < 1700 ? '70vw' : '50vw' ),
      disableClose: true,
      // maxHeight: '75vh',
      // data: {}
    });

    employeeHistoryDialog.afterClosed().subscribe(response => {
      this.loadDataOnForm();
    });
  }
  //#endregion

  //#region ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ****************************************************************************************************************** */
  /* ** AREA
  /* ****************************************************************************************************************** */
  assignArea(e) {
    this.CompanyFormGroup.get('area').setValue(e.option.value);
  }

  displayArea(area: Area): string | undefined {
    return (area)
      ? area.description
      : undefined;
  }

  /* ****************************************************************************************************************** */
  /* ** CENTRE
  /* ****************************************************************************************************************** */
  assignCentre(e) {
    this.CompanyFormGroup.get('centre').setValue(e.option.value);
  }

  displayCentre(centre: Centre): string | undefined {
    return (centre)
      ? centre.name
      : undefined;
  }

  /* ****************************************************************************************************************** */
  /* ** HEADQUARTER
  /* ****************************************************************************************************************** */
  assignHeadquarter(e) {
    this.CompanyFormGroup.get('headquarter').setValue(e.option.value);
  }

  displayHeadquarter(headquarter: Headquarters): string | undefined {
    return (headquarter)
      ? headquarter.name
      : undefined;
  }

  /* ****************************************************************************************************************** */
  /* ** MANAGER
  /* ****************************************************************************************************************** */
  assignManager(e) {
    this.CompanyFormGroup.get('manager').setValue(e.option.value);
  }

  displayManager(manager: Employee): string | undefined {
    return (manager)
      ? `${manager.name} ${manager.firstSurname} ${manager.secondSurname}`
      : undefined;
  }

  displayValidator(validator: Employee): string | undefined {
    return (validator)
      ? `${validator.name} ${validator.firstSurname} ${validator.secondSurname}`
      : undefined;
  }

  /* ****************************************************************************************************************** */
  /* ** PERMISSION
  /* ****************************************************************************************************************** */
  assignPermission(e) {
    this.CompanyFormGroup.get('permission').setValue(e.option.value);
    console.log(e.option.value);
  }

  displayPermission(permission: Permission): string | undefined {
    return (permission)
      ? permission.name
      : undefined;
  }

  /* ****************************************************************************************************************** */
  /* ** USER
  /* ****************************************************************************************************************** */
  assignUser(e) {
    this.CompanyFormGroup.get('user').setValue(e.option.value);
  }

  displayUser(user: any): string | undefined {
    let result;

    if (user) {
      if (user.userName) {
        result = user.userName;
      } else {
        result = user;
      }
    }

    return result;
  }

  /* ****************************************************************************************************************** */
  /* ** VALIDATOR
  /* ****************************************************************************************************************** */
  assignValidator(e) {
    this.CompanyFormGroup.get('validator').setValue(e.option.value);
  }

  /* ****************************************************************************************************************** */
  /* ** WORKCALENDAR
  /* ****************************************************************************************************************** */
  assignWorkcalendar(e) {
    this.CompanyFormGroup.get('workcalendar').setValue(e.option.value);
  }

  displayWorkcalendar(workcalendar: WorkCalendar): string | undefined {
    return (workcalendar)
      ? `${workcalendar.name} (${workcalendar.validYear})`
      : undefined;
  }

  /* ****************************************************************************************************************** */
  /* ** DATOS DE FORMULARIO
  /* ****************************************************************************************************************** */
  assignEmployeeData() {
    if (this.employee == undefined || this.employee == null) {
      this.employee = new Employee();
    }

    // CompanyFormGroup
    // this.employee.areaId = (this.CompanyFormGroup.get('area').value && this.CompanyFormGroup.get('area').value.areaId)
    //   ? this.CompanyFormGroup.get('area').value.areaId
    //   : null;
    this.employee.centreId = (this.CompanyFormGroup.get('centre').value && this.CompanyFormGroup.get('centre').value.centreId)
      ? this.CompanyFormGroup.get('centre').value.centreId
      : null;
    this.employee.headquartersId = (this.CompanyFormGroup.get('headquarter').value && this.CompanyFormGroup.get('headquarter').value.headquartersId)
      ? this.CompanyFormGroup.get('headquarter').value.headquartersId
      : null;

    if (this.CompanyFormGroup.get('user').value) {
      this.employee.login = (this.CompanyFormGroup.get('user').value.userName)
        ? this.CompanyFormGroup.get('user').value.userName
        : this.CompanyFormGroup.get('user').value; // Si el valor introducido en el login es un string

      this.employee.userId = (this.CompanyFormGroup.get('user').value.id)
        ? this.CompanyFormGroup.get('user').value.id
        : ''; // Si el valor introducido en el login es un string
    } else {
      this.employee.login = '';
      this.employee.userId = '';
    }

    this.employee.managerId = (this.CompanyFormGroup.get('manager').value && this.CompanyFormGroup.get('manager').value.employeeId)
      ? this.CompanyFormGroup.get('manager').value.employeeId
      : null;
    this.employee.validatorId = (this.CompanyFormGroup.get('validator').value && this.CompanyFormGroup.get('validator').value.employeeId)
      ? this.CompanyFormGroup.get('validator').value.employeeId
      : null;

    this.employee.workCalendarId = (this.CompanyFormGroup.get('workcalendar').value && this.CompanyFormGroup.get('workcalendar').value.workCalendarId)
      ? this.CompanyFormGroup.get('workcalendar').value.workCalendarId
      : null;

    // Se notifica a los observadores de los cambios
    this.dataChangesService.setEmployeeChange(this.employee);
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  addFilters() {
    if (this.employeeManagementInfo) {
      if (this.employeeManagementInfo.allCentres) {
        this.filteredCentres = this.CompanyFormGroup.controls['centre'].valueChanges.pipe(startWith(""), map(val =>
          this.employeeManagementInfo.allCentres.filter(centr =>
            customStartsWith(centr.name, val)
          ))
        );
      }

      if (this.employeeManagementInfo.allHeadquarters) {
        this.filteredHeadquarters = this.CompanyFormGroup.controls['headquarter'].valueChanges.pipe(startWith(""), map(val =>
          this.employeeManagementInfo.allHeadquarters.filter(headqrt =>
            customStartsWith(headqrt.description, val)
          ))
        );
      }

      if (this.employeeManagementInfo.allManagers) {
        this.filteredManagers = this.CompanyFormGroup.controls['manager'].valueChanges.pipe(startWith(""), map(val =>
          this.employeeManagementInfo.allManagers.filter(mngr =>
            customStartsWith(mngr.name + " " + mngr.firstSurname + " " + mngr.secondSurname, val)
          ))
        );
      }

      if (this.employeeManagementInfo.allValidators) {
        this.filteredValidators = this.CompanyFormGroup.controls['validator'].valueChanges.pipe(startWith(""), map(val =>
          this.employeeManagementInfo.allValidators.filter(validatr =>
            customStartsWith(validatr.name + " " + validatr.firstSurname + " " + validatr.secondSurname, val)
          ))
        );
      }

      if (this.employeeManagementInfo.allWorkcalendars) {
        this.filteredWorkcalendars = this.CompanyFormGroup.controls['workcalendar'].valueChanges.pipe(startWith(""), map(val =>
          this.employeeManagementInfo.allWorkcalendars.filter(wrkcld =>
            customStartsWith(wrkcld.description, val)
          ))
        );
      }
    }

    if (this.allUsers) {
      this.filteredUsers = this.CompanyFormGroup.controls['user'].valueChanges.pipe(startWith(""), map(val =>
        this.allUsers.filter(usr =>
          customStartsWith(usr.fullName, val)
        ))
      );
    }
  }

  getEmployeeFullName(employee: Employee): string {
    let result = "";

    if (employee) {
      if (employee.name) {
        result += employee.name;
      }
      if (employee.firstSurname) {
        result += ` ${employee.firstSurname}`;
      }
      if (employee.secondSurname) {
        result += ` ${employee.secondSurname}`;
      }
    }

    return result;
  }
  //#endregion
}
