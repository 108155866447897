import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { AbsenceComponent } from '../../absence/absence.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Absence } from 'src/app/models/absence.model';
import { AbsenceService } from 'src/app/services/absence.service';
import { Employee } from 'src/app/models/employee.model';
import { AccountService, ResponseNotificationService } from 'inzo-portalempleado';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { disableDay, disableWeekEnd, getDateTimeString, getMinDateString, transformDate } from 'src/app/helpers/date.helper';
import { WorkCalendarService } from 'src/app/services/work-calendar.service';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { AbsenceState } from 'src/app/models/absence-state.model';
import moment = require('moment');
import { AbsenceStateCode } from 'src/app/models/absence-state-code-enum.model';
import { AbsenceChangesService } from 'src/app/services/absence-changes.service';
import { ListDates } from 'src/app/models/list-dates.model';
import * as jsonpatch from 'fast-json-patch';
import { ITLeaveCreateRangeDialogComponent } from '../../it-leave/it-leave-create-range-dialog/it-leave-create-range-dialog.component';
import { ITLeaveComponent } from '../../it-leave/it-leave.component';
import { AbsenceTypeService } from 'src/app/services/absence-type.service';
import { isValidDate } from '../../../helpers/date.helper';
@Component({
  selector: 'app-it-leave-edit-dialog',
  templateUrl: './it-leave-edit-dialog.component.html',
  styleUrls: ['./it-leave-edit-dialog.component.css'],
})
export class ITLeaveEditDialogComponent extends ITLeaveCreateRangeDialogComponent {
  totalDaysRequested : number = 0;
  totalDaysRequestedLastYear : number = 0;
  itLeaveStates: AbsenceState[] = [];

  showReason: boolean = false;

  formGroupControl: FormGroup;

  allAbsenceTypes = [];
  itLeaveId: string;
  absenceName: string;
  absence: Absence = null;

  currentEmployee: Employee = null;
  disabledDays: any[] = [];
  edit = false;

  selectedDates: ListDates = new ListDates('DD/MM/YYYY', true);
  selectedDays = [];
  // Permissions
  canDirectAssignment: boolean = false;
  directAssignment: boolean = false;

  constructor (
    public dialogRef: MatDialogRef<ITLeaveComponent>,
    public absenceTypeService: AbsenceTypeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected spinner: NgxSpinnerService,
    protected translate: TranslateService,
    protected toastrService: ToastrService,
    protected accountService: AccountService,
    protected employeeManagementService: EmployeeManagementService,
    protected absenceService: AbsenceService,
    protected absenceChangesService: AbsenceChangesService,
    protected workCalendarService: WorkCalendarService,
    protected RNService: ResponseNotificationService,
    protected dialog: MatDialog,
  ) {
    super(
      dialogRef,
      absenceTypeService,
      data,
      spinner,
      translate,
      toastrService,
      accountService,
      employeeManagementService,
      absenceService,
      absenceChangesService,
      workCalendarService,
      RNService,
      dialog
    );
  }

  ngOnInit() {
    const itLeaveId = this.data.itLeaveId;

    if (!this.data.absence) {
      this.absenceService.getAbsence(itLeaveId).subscribe(response => {
        this.absence = response as Absence;
      });
    } else {
      this.absence = this.data.absence as Absence;
    }

    // Se comprueba si la baja está abierta o no, es decir, si tiene una fecha de fin.
    this.inputExpectedFinishDate = this.itLeaveIsOpen();

    this.selectedDays = this.data.days;
    this.selectedDates.setDates(this.selectedDays);

    this.formGroupControl = new FormGroup({
      startDate: new FormControl('', Validators.required),
      finishDate: new FormControl('', Validators.required),
      state: new FormControl(''),
      reason: new FormControl(''),
    });
    this.formGroupControl.patchValue(
      {
        startDate: this.selectedDates.first(),
        finishDate: this.selectedDates.last(),
      }
    );

    this.absenceChangesService.canDirectAssignment.subscribe(checked => {
      this.canDirectAssignment = checked;
    });

    this.absenceChangesService.check.subscribe(checked => {
      this.directAssignment = checked;
    });

    const userId = this.accountService.currentUser.id;

    if (this.data.currentEmployee) {
      this.currentEmployee = this.data.currentEmployee;

      if (this.data.itLeaveStates) {
        this.itLeaveStates = this.data.itLeaveStates;
      }

      this.totalDaysRequested = this.currentEmployee.totalAvailableHolidays - this.currentEmployee.totalHolidaysEnjoyed;
      this.totalDaysRequestedLastYear = this.currentEmployee.totalPendingHolidaysLastYear - this.currentEmployee.totalPendingHolidaysLastYearEnjoyed;
    } else {
      this.employeeManagementService.getEmployeeByUserId(userId, true).subscribe(employee => {
        this.currentEmployee = employee;
      });

      this.totalDaysRequested = this.data.totalDaysRequested;
      this.totalDaysRequestedLastYear = this.data.totalDaysRequestedLastYear;
    }

    this.itLeaveId = this.data.itLeaveId;
    this.absenceName = this.data.absenceName;
    this.absence = this.data.absence;

    if (this.currentEmployee && this.currentEmployee.workCalendar) {
      this.loadDisabledDays(this.currentEmployee.workCalendar);
    } else if (this.currentEmployee) {
      this.workCalendarService.getWorkCalendar(this.currentEmployee.workCalendarId).subscribe(response => {
        this.currentEmployee.workCalendar = response;
        this.loadDisabledDays(this.currentEmployee.workCalendar);
      });
    }

    if (this.absence) {
      this.edit = true;
    }
  }

  selectedDatesChange(event) {
    this.selectedDates = event;

    if (this.selectedDates.empty()) {
      this.formGroupControl.get("startDate").reset();
      this.formGroupControl.get("finishDate").reset();
    } else {
      this.formGroupControl.patchValue(
        {
          startDate: this.selectedDates.first(),
          finishDate: this.selectedDates.last(),
        }
      );
    }
  }

  update() {
    this.spinner.show();
    const observer = jsonpatch.observe(this.absence);
    this.absence.startDate = transformDate(this.formGroupControl.get('startDate').value, 'YYYY-MM-DD');
    this.absence.requestedDates = this.selectedDates.toDatesStrings();

    // Se asigna el valor de la fecha de final de la baja o la fecha de la próxima revisión de la misma (expectedFinishDate)
    if (this.inputExpectedFinishDate) {
      this.absence.expectedFinishDate = transformDate(this.formGroupControl.get('finishDate').value, 'YYYY-MM-DD');
      this.absence.finishDate = transformDate(getMinDateString(), 'YYYY-MM-DD');
    } else {
      this.absence.finishDate = transformDate(this.formGroupControl.get('finishDate').value, 'YYYY-MM-DD');
    }

    const patch = jsonpatch.generate(observer);

    this.absenceService.modifyLeaveIT(this.absence.absenceId, patch).subscribe((response) => {
      this.translate.get('ABSENCE_MAINTENANCE.NAME').subscribe((componentTranslated: string) => {
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.UPDATE').subscribe((a: string) => {toastTitle = a});
        this.translate.get('MESSAGES.SUCCESS.UPDATE_LONG_SIMPLE', {type: componentTranslated}).subscribe((a: string) => {toastBody = a});
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
      });

      this.spinner.hide();
      this.dialogRef.close(this.absence);
    }, error => {
      let title = "MESSAGES.ERROR.UPDATE_CONFLICT";
      let message = "MESSAGES.ERROR.UPDATE_CONFLITCT_BODY";

      if (error.error.type == "OVERLAPPING_DATES") {
        title = `MESSAGES.ERROR.${error.error.type}`;
        title = `MESSAGES.ERROR.${error.error.type}_BODY`;
      } else if (error.error.type == "error") {
        title = 'MESSAGES.ERROR.UNEXPECTED';
        title = 'MESSAGES.ERROR.UNEXPECTED_BODY';
      }

      this.translate.get(title).subscribe((lang: string) => {
        title = lang;
      });
      this.translate.get(message).subscribe((lang: string) => {
        message = lang;
      });

      this.spinner.hide();
      this.toastrService.error(message, title, { timeOut: 3000 });
    });
  }

  assingAbsenceType(e) {
    this.formGroupControl.get('absenceType').setValue(e.option.value);
  }

  loadDisabledDays(workCalendar: WorkCalendar): void {
    const disabledDays = [];

    if (workCalendar.wcEvents) {
      workCalendar.wcEvents.forEach(event => {
        let startDate = moment(event.startDate);
        let endDate = moment(event.endDate);
        while (startDate.isSameOrBefore(endDate)) {
          disabledDays.push({date: startDate.toDate()})
          startDate.add(1, 'days');
        }
      });
    }

    this.disabledDays = disabledDays;
  }

  /**
   * Comprueba los días que se deben deshabilitar del calendario
   *
   * @param d fecha en formato objeto momentjs
   * @returns
   */
  getDisabledDays (d: any | null): boolean {
    let result = true;

    if ((d.day() == 0 || d.day() == 6)) {
      result = disableWeekEnd(d, this.currentEmployee.workCalendar);
    } else {
      result = disableDay(d, this.disabledDays);
    }

    return result;
  }

  getAbsenceState(state: string) {
    let currentState = null;

    for(let state of this.itLeaveStates) {
      if (state.absenceStateId == this.formGroupControl.get("state").value) {
        currentState = state;
        break;
      }
    }

    this.showReason = currentState.stateCode == AbsenceStateCode[state];
  }

  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  /**
   * Devuelve si es o no una ausencia por Baja IT o false si no se puede comprobar.
   * @returns boolean
   */
  checkIsITLeave(): boolean {
    return (this.absence && this.absence.absenceType && this.absence.absenceType.isMedicalLeave)
      ? this.absence.absenceType.isMedicalLeave
      : false;
  }

  /**
   * Determina si el date picker debe seleccionar rangos de fecha o no.
   * @returns boolean
   */
  itLeaveIsOpen(): boolean {
    let result = false;

    if (this.checkIsITLeave() && !isValidDate(this.absence.finishDate)) {
      result = true;
    }

    return result;
  }

  closeDialog(): void {
    if (this.selectedDays.length > 0) {
      this.formGroupControl.get('startDate').setValue(this.selectedDays[0]);
    }
    if (this.selectedDays.length > 1) {
      this.formGroupControl.get('finishDate').setValue(this.selectedDays[1]);
    }
    this.dialogRef.close();
  }
}
