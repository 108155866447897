// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DataChangesService } from '../../../services/data-changes.service';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AccountService, Hotkeys, Permission, Role, User, UserEdit } from 'inzo-portalempleado';

import { Employee } from '../../../../../models/employee.model';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EmployeeHistoryFilterRangueDialogComponent } from '../../employee-history/employee-history-filter-rangue-dialog/employee-history-filter-rangue-dialog.component';
import { HRADateFilterService } from 'src/app/services/hra-date-filter.service';
import { DataHistoryService } from '../../../services/data-history.service';
import { HRAHistoryService } from '../../../../../services/hra-history.service';
import { DCRHistoryService } from '../../../../../services/dcr-history.service';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { FormGroup } from '@angular/forms';

// ################################################################################################################
// ## CLASS MgntFormHistoryComponent
// ################################################################################################################
@Component({
  selector: 'app-mgnt-form-history',
  templateUrl: './mgnt-form-history.component.html',
  styleUrls: [
    './mgnt-form-history.component.css',
  ],
})
export class MgntFormHistoryComponent implements OnInit {
  /* ############################################################################################################## */
  /* ## ATRRIBUTES
  /* ############################################################################################################## */
  employeeView: boolean = false;
  employee: Employee;

  employeeSubject: BehaviorSubject<Employee>;

  selectedDesc: string = undefined;
  permissionList: Permission[];
  userPermission: Permission;

  backForm = true;
  userEdit: UserEdit;

  // --------------------------------------------------------------------------------------------------------------
  // Permisos
  // --------------------------------------------------------------------------------------------------------------
  allUsers: User[];
  roles: Role[];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }
  // --------------------------------------------------------------------------------------------------------------

  startDateFilter: string;
  finishDateFilter: string;

  loadEmployeeData;
  disabilityChecked;
  enabled = true;
  detail = false;
  deleted = false;
  permissionsLoaded = false;
  visible = true;
  userFilter = '';
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  subscriptions       : Subscription[] = [];

  // --------------------------------------------------------------------------------------------------------------

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */

  // ------------------------------------------------------------------------------------------------------------------
  // EmployeeHistoryFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  EmployeeHistoryFormGroup = new FormGroup({});
  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    protected permissionsService: PermissionsService,
    private dataChangesService: DataChangesService,
    private dataHistoryService: DataHistoryService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private hraDateFilterService: HRADateFilterService,
    public HRAHistoryService: HRAHistoryService,
    public DCRHistoryService: DCRHistoryService,
  ) {
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.permissionsService.userRolesPermissions.subscribe(data => {
      this.userRolesPermissions = data;
    });

    this.subscriptions.push(
      this.hraDateFilterService.dates.subscribe(dates => {
        this.startDateFilter = dates.dataStartDate;
        this.finishDateFilter = dates.dataFinishDate;
      })
    );
    // employeeManagementInfo
    this.subscriptions.push(
      this.dataChangesService.employeeManagementInfo.subscribe(data => {
        if (data) {
          // Datos para Históricos
          if (data && data.employee) {
            this.dataHistoryService.setEmployeeChange(data.employee);
            this.employee = data.employee;

            this.loadData();
          }
          this.dataHistoryService.setEmployeesChange(data.allValidators); // TODO: cambiar
        }
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  loadData() {
    if (this.employee && this.employee.employeeId) {
      this.HRAHistoryService.getHRAHistoryFilteredByDate(this.employee.employeeId, this.startDateFilter, this.finishDateFilter).subscribe(response => {
        let holidays = response.items.filter(item => item.holidaysRequestId != null);
        let absences = response.items.filter(item => item.absenceId != null);
        this.dataHistoryService.setHolidaysChange(holidays);
        this.dataHistoryService.setAbsencesChange(absences);
      });

      this.DCRHistoryService.getAllDCRHistoryByEmployee(this.employee.employeeId).subscribe(response => {
        this.dataHistoryService.setDCRChange(response.items);
      });
    }
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  addFilters() {
  }

  datePicker() {
    this.dialog.open(EmployeeHistoryFilterRangueDialogComponent, {
      width: '550px'
    });
  }

  resetFilter() {
    this.hraDateFilterService.onChangeDates(this.hraDateFilterService.firstDate, this.hraDateFilterService.lastDate);
  }
  //#endregion
}
