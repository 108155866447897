import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { AnnualWorkHour } from '../models/annual-work-hour.model';

@Injectable({ providedIn: 'root' })
export class AnnualWorkHourService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private annualWorkHourSubject: ReplaySubject<AnnualWorkHour[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public annualWorkHourObservable: Observable<AnnualWorkHour[]> = this.annualWorkHourSubject.asObservable();

  private annualWorkHourRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public annualWorkHourRouteObservable: Observable<string> = this.annualWorkHourRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/annualWorkHour';
  }

  constructor(private http: HttpClient) {
  }

  getAllAnnualWorkHours(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getAnnualWorkHour(annualWorkHourId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/${annualWorkHourId}${filter}`;

    return this.http.get<AnnualWorkHour>(url);
  }

  createAnnualWorkHour(annualWorkHour: AnnualWorkHour) {
    const url = `${this.serviceUrl}`;

    return this.http.post(url, annualWorkHour);
  }

  deleteAnnualWorkHour(annualWorkHourId: string) {
    const url = `${this.serviceUrl}/${annualWorkHourId}`;

    return this.http.delete(url);
  }

  modifyAnnualWorkHour(annualWorkHourId: string, annualWorkHour: any) {
    const url = `${this.serviceUrl}/${annualWorkHourId}`;

    return this.http.patch(url, annualWorkHour);
  }

  updateAnnualWorkHours(annualWorkHours: any) {
    this.annualWorkHourSubject.next(annualWorkHours);
  }

  updateAnnualWorkHourRoutes(id: string) {
    this.annualWorkHourRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
