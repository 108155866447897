// https://www.leonelngande.com/deep-cloning-objects-in-angular-typescript-javascript/
import {Injectable} from '@angular/core';
import * as clone from 'clone';

@Injectable({providedIn: 'root'})
export class ClonerService {
    static deepClone<T>(value): T {
        return clone<T>(value);
    }
}
