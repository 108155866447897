import { Component, ViewChild, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatAutocompleteSelectedEvent, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { transformDate } from 'src/app/helpers/date.helper';
import { Employee } from 'src/app/models/employee.model';
import { Subscription } from 'rxjs';
import { getDateTimeString } from 'src/app/helpers/date.helper';
import { EmployeeValidatorHistory } from 'src/app/models/employee-validator-history.model';
import { DataHistoryService } from '../../../services/data-history.service';
import { EmployeeValidatorHistoryService } from 'src/app/services/employee-validator-history.service';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as Rxjs from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';

import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';

import { DataChangesService } from '../../../services/data-changes.service';
import { forEach } from 'lodash';
@Component({
  selector: 'app-validator-history-list',
  templateUrl: './employee-validator-history-list.component.html',
  styleUrls: [
    './employee-validator-history-list.component.css',
    '../../../../../../assets/styles/action.components.css',
    '../../../../../../assets/styles/action-list.component.css',
  ]
})
export class EmployeeValidatorHistoryListComponent implements OnInit, OnDestroy {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  @Output() onAreaReloadNeeded: EventEmitter<string> = new EventEmitter();

  showAddPanel = false;
  employeeView = false;

  employee: Employee = null;
  employees: Employee[] = [];
  allValidators: Employee[] = [];
  allValidatorsSearch: Employee[] = [];

  validators: EmployeeValidatorHistory[] = [];

  displayedColumns: string[] = [
    'actions',
    'startDate',
    'validator',
  ];

  validatorHistoryFormGroup: FormGroup;

  startDateFilter: string;
  finishDateFilter: string;

  //ELEMENT_DATA: EmployeeAreaHistory[] = [];
  dataSource: MatTableDataSource<EmployeeValidatorHistory>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  subscriptions: Subscription[] = [];

  roles: Role[];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  };

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private translate: TranslateService,
    private dataHistoryService: DataHistoryService,
    private employeeValidatorHistoryService: EmployeeValidatorHistoryService,
    private employeeManagementService: EmployeeManagementService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private RNService: ResponseNotificationService,
    private dataChangesService: DataChangesService,
    protected permissionsService: PermissionsService,
  ) {
    this.validatorHistoryFormGroup = new FormGroup({
      employeeId: new FormControl('', Validators.required),
      validatorId: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
    });
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    //EmployeeView
    this.subscriptions.push(
      this.dataHistoryService.employeeView.subscribe(data => {
        this.employeeView = data;
      })
    );

    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;
      })
    );

    // roles
    this.subscriptions.push(
      this.permissionsService.roles.subscribe(data => {
        this.roles = data;
      })
    );

    // Employee
    this.subscriptions.push(
      this.dataHistoryService.employee.subscribe(data => {
        this.employee = data;
        this.validatorHistoryFormGroup.get('employeeId').setValue(this.employee.employeeId);

        this.loadDataOnTable();
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  /* ################################################################################################################## */
  /* ## DATA METHODS
  /* ################################################################################################################## */
  onGetAllValidators() {
    this.showAddPanel = true;
    this.employeeValidatorHistoryService.getAllEmployeesValidators().subscribe(query => {
      this.allValidators = query.items;
      this.allValidatorsSearch = this.allValidators;
    }, error => {
      this.RNService.showError(error);
    });
  }

  searchValidators(event) {
    var listaux: Employee[] = []
    var valor: string = event.target.value;

    if (valor != "" && valor != undefined) {
      for (let val of this.allValidators) {
        if(val.name!=undefined && val.firstSurname!=undefined && val.secondSurname!=undefined){
          if (val.name.toLowerCase().includes(valor.toLowerCase()) || val.firstSurname.toLowerCase().includes(valor.toLowerCase()) || val.secondSurname.toLowerCase().includes(valor.toLowerCase())) {
            listaux.push(val);
          }
        }

      }
      this.allValidatorsSearch = listaux;
    }
    else {
      this.allValidatorsSearch = this.allValidators;
    }
  }

  onCreate() {
    if (this.validatorHistoryFormGroup && this.validatorHistoryFormGroup.valid) {

      this.spinner.show();
      const employeeHistory: EmployeeValidatorHistory = new EmployeeValidatorHistory();

      let dateaux = new Date(this.validatorHistoryFormGroup.get('startDate').value);
      dateaux.setUTCDate(dateaux.getUTCDate() + 1);
      dateaux.setUTCHours(0, 0, 0, 0,);

      let validator = this.validatorHistoryFormGroup.get('validatorId').value;

      employeeHistory.startDate = dateaux;
      employeeHistory.validatorId = (validator && validator.employeeId)
        ? validator.employeeId
        : validator;
      employeeHistory.employeeId = this.validatorHistoryFormGroup.get('employeeId').value;

      if (employeeHistory.startDate != null && employeeHistory.startDate != undefined && employeeHistory.validatorId != null && employeeHistory.validatorId != undefined) {

        this.employeeValidatorHistoryService.createEmployeeValidatorHistory(employeeHistory).subscribe(
          response => {
            if (response != null) {

              this.translate.get('VALIDATORHISTORY_MAINTENANCE.FORM.CREATION_SUCCESS').subscribe((text: string) => {
                this.toastrService.success(text, '', { timeOut: 3000 });
              });

              this.showAddPanel = false;
              this.validatorHistoryFormGroup.reset();
              this.getAndLoadDataOnTable();
            }
            this.spinner.hide();
          },
          error => {
            console.error('error --> ', error);
            this.spinner.hide();
            if (error.error === 'MISSING_PARAMS') {
              this.translate.get('VALIDATORHISTORY_MAINTENANCE.FORM.ERROR.MISSING_PARAMS').subscribe((text: string) => {
                this.toastrService.error(text, '', { timeOut: 3000 });
              });
            } else {
              this.translate.get('VALIDATORHISTORY_MAINTENANCE.FORM.ERROR.UNEXPECTED').subscribe((text: string) => {
                this.toastrService.error(text, '', { timeOut: 3000 });
              });
            }
          }
        );
      }
      else {
        this.spinner.hide();
        this.translate.get('MISSING_PARAMS').subscribe((text: string) => {
          this.toastrService.error(text, '', { timeOut: 3000 });
        });
      }
    }
  }

  onRemove(employeeValidatorHistory: EmployeeValidatorHistory) {
    let text;
    this.translate.get('VALIDATORHISTORY_MAINTENANCE.MESSAGE.DELETE_CONFIRMATION.TEXT').subscribe((transitionStr: string) => {
      text = transitionStr;
    });

    //se comprueba que al menos haya un validador asignado ya que es obligatorio que un empleado tenga al menos un validador
    if (this.validators.length > 1) {
      const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        width: '550px',
        data: {
          text
        }
      });

      confirmationDialog.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.spinner.show();
          this.employeeValidatorHistoryService.deleteEmployeeValidatorHistory(employeeValidatorHistory.employeeValidatorHistoryId).subscribe(
            response => {
              this.spinner.hide();
              this.translate.get('VALIDATORHISTORY_MAINTENANCE.FORM.DELETION_SUCCESS').subscribe((text: string) => {
                this.toastrService.success(text, '', { timeOut: 3000 });
              });

              this.getAndLoadDataOnTable();
            },
            error => {
              console.error('error --> ', error);
              this.spinner.hide();
              if (error.error === 'MISSING_PARAMS') {
                this.translate.get('VALIDATORHISTORY_MAINTENANCE.FORM.ERROR.MISSING_PARAMS').subscribe((text: string) => {
                  this.toastrService.error(text, '', { timeOut: 3000 });
                });
              } else {
                this.translate.get('VALIDATORHISTORY_MAINTENANCE.FORM.ERROR.UNEXPECTED').subscribe((text: string) => {
                  this.toastrService.error(text, '', { timeOut: 3000 });
                });
              }
            }
          );
        }
      });
    }
    else {
      this.translate.get('VALIDATORHISTORY_MAINTENANCE.FORM.ERROR.MANDATORY_VALIDATOR').subscribe((text: string) => {
        this.toastrService.error(text, '', { timeOut: 3000 });
      });
    }
  }

  loadDataOnTable() {
    if (this.employee != undefined && this.employee != null) {
      // Se busca el valor actual
      this.assigCurrentValidator();

      this.validators = this.employee.validatorHistory;
      this.validators = _.orderBy(this.validators, a => moment(a.startDate).unix(), 'desc');
      this.dataSource = new MatTableDataSource<EmployeeValidatorHistory>(this.validators);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  getAndLoadDataOnTable() {
    let filters: string = "";
    this.spinner.show();
    this.employeeValidatorHistoryService.getAllEmployeeValidatorHistorybyEmployeeId(this.employee.employeeId).subscribe(query => {
      this.employee.validatorHistory = (query && query.items) || [];

      this.loadDataOnTable();

      // Se propaga el cambio a los observadores
      this.dataHistoryService.setEmployeeChange(this.employee);

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.RNService.showError(error);
    });
  }

  /* ################################################################################################################## */
  /* ## AUTOCOMPLETE METHODS
  /* ################################################################################################################## */

  validatorDisplayFn = (employee?: Employee) => {
    let result = (employee)
      ? `${employee.name} ${employee.firstSurname} ${employee.secondSurname}`
      : undefined;

    return result;
  }

  onStartDateSelect(date: any): void {
    this.validatorHistoryFormGroup.get('startDate').setValue(moment(date).format('DD/MM/YYYY'));
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  assigCurrentValidator() {
    // Se busca el valor actual
    const currentValidator = _(this.employee.validatorHistory).filter(x => moment(x.startDate) <= moment()).maxBy(x => moment(x.startDate).unix());

    if (currentValidator) {
      this.employee.validator = currentValidator.validator;
      this.employee.validatorId = currentValidator.validatorId;
    }
  }

  isCurrentValue(item): boolean {
    const currentValidator = _(this.employee.validatorHistory).filter(x => moment(x.startDate) <= moment()).maxBy(x => moment(x.startDate).unix());
    return (currentValidator)
      ? currentValidator.validatorId == item.validatorId
      : false;
  }

  getCreatedBy(employeeId) {
    let employee = this.employees.find(element => element.employeeId == employeeId);
    let result = (employee != null)
      ? `${employee.secondSurname} ${employee.firstSurname}, ${employee.name}`
      : "";

    return result;
  }

  getDate(date: any, format: string): string {
    let result = getDateTimeString(date, format);

    return result != "Invalid date"
      ? result
      : "";
  }

  canAdd(): boolean {
    return (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH || this.userRolesPermissions.isRA);
  }

  canDelete(): boolean {
    let result = (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH || this.userRolesPermissions.isRA);

    result = result && this.employee.validatorHistory.length > 1;

    return result;
  }
}
