import { Component, ElementRef, ViewChild, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { NgxSpinnerService } from 'ngx-spinner';

import { DataChangeRequestService } from 'src/app/services/data-change-request.service';
import { DataChangeRequestGroupItems } from 'src/app/interfaces/dcr-group-api.interface';
import { DCRState, DCRStateCode } from 'src/app/models/dcr-state.model';
import { DCRStateService } from 'src/app/services/dcr-state.service';
import { FiltersApi } from 'src/app/models/filters-api.model';


import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';



@Component({
  selector: 'app-dcr-groups',
  templateUrl: './dcr-groups.component.html',
  styleUrls: [
    './dcr-groups.component.css',
    './../../action.components.css',
    '../../action-list.component.css',
  ],
})
export class DcrGroupsComponent extends BaseListComponent<DataChangeRequestGroupItems> {
  employeeView = false;
  canView = false;

  roles: Role[];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  dataChangeRequests: DataChangeRequestGroupItems[] = [];
  dcrStates: DCRState[] = [];
  subscriptions: Subscription[] = [];
  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;

  filterApi: FiltersApi = new FiltersApi();

  tabStates: string[] = [];
  excludeDcrStates: DCRStateCode[] = [];


  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  @Input() canApproveRRHH = true;

  constructor(
    protected dataChangeRequestService: DataChangeRequestService,
    protected dcrStateService: DCRStateService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    protected RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    protected spinner: NgxSpinnerService,
    protected permissionsService: PermissionsService,
  ) {
    super(accountService, SSMService, router, hotkeys);

    this.baseRoute = '/managements/dataChange';



    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
      AppPermissions.ROLE_DATA.Empleado
    ];

    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
    }

    this.onBuild = () => {
    };

    this.loadData = () => {
      this.spinner.show();
      this.filterApi.add(
        {
          field: "related",
          value: "true"
        }
      );

      this.filterApi.addInclude("Employee");

      this.subscriptions.push(
        this.route.paramMap.subscribe(params => {
          this.subscriptions.push(
            this.dataChangeRequestService.getAllDataChangeRequestGroupBy(this.filterApi.getStrinFilterApi()).subscribe(dataChangeRequest => {
              const filteredItems = this.assignRequestStates(dataChangeRequest.items);
              this.dataChangeRequestService.updateDataChangeRequests(filteredItems);

              this.loadDataChangeRequests(filteredItems);
              this.spinner.hide();
            }, error => {
              this.spinner.hide();
              this.RNService.showError(error);
            })
          )
        })
      );
    };

    this.onInit = () => {
      this.getDCRStates();

      this.loadData();

      this.subscriptions.push(
        this.dataChangeRequestService.dataChangeRequestObservable.subscribe(response => {
          this.loadDataChangeRequests(response);
        })
      );
    };
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();

    /* PERMISOS */
    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;
        // acciones después de cargar permisos
      })
    );

    // roles
    this.subscriptions.push(
      this.permissionsService.roles.subscribe(data => {
        this.roles = data;

      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;


          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  refresh() {
    this.loadData();
  }

  getDCRStates() {
    this.dcrStateService.getAllDCRStateRequest().subscribe(dcrStateRequests => {
      this.dcrStates = dcrStateRequests.items;
      //Se comprueba si hay datachanges y si el usuario es RRHH, si es así se habilita el bloque html con el flag canView
      //TODO habilitar por perfil RP y RA definir que solicitudes puede ver y cuales no
      //(que se devuelva desde la api comprobando el ROL y el usuario solicitante las requests correctas)
      if (this.dcrStates.length > 0 && this.userRolesPermissions.isRRHH)
        this.canView = true;

      this.setRequestStates();
    }, error => {
      this.RNService.showError(error);
    });
  }

  assignRequestStates(items) {
    for (let item of items) {
      for (let dcr of item) {
        // Se recupera el estado
        dcr.state = this.dcrStates.find(element => element.dcrStateId == dcr.dcrStateId);
      }
    }

    return items;
  }

  // TODO: refacotrizar para simplificar
  loadDataChangeRequests(items) {
    // Se vacía el array
    this.dataChangeRequests = new Array();

    // Se prepara el array nominativo
    for (let stateName of this.tabStates) {
      this.dataChangeRequests[stateName] = [];
    }

    // Se rellena el array con los distintos items
    for (let item of items) {
      let temp = {
        ALL: new Array()
      };

      // Se recorren las DCR de cada item y se clasifican
      for (let dcr of item) {


        if (!this.excludeDcrStates.includes(dcr.state.stateCode)) {
          let indexName = DCRStateCode[dcr.state.stateCode];
          if (temp.hasOwnProperty(indexName)) {
            temp[indexName].push(dcr);
          } else {
            temp[indexName] = [dcr];
          }

          temp["ALL"].push(dcr);
        }
      }

      // Se añaden las DCR ya clasificadas a la lista agrupadas por Employee
      for (let element in this.dataChangeRequests) {
        if (Object.keys(temp).includes(element)) {
          this.dataChangeRequests[element].push(temp[element]);
        }
      }
    }
  }

  /**
   * Devuelve el de solicitudes que se debe mostrar en las pestañas
   *
   * @param stateCode código de estado
   */
  getTabCount(dataChangeRequestsName: any): string {
    let result = ``;

    let dataChangeRequestsGroup: any = this.dataChangeRequests[dataChangeRequestsName];

    if (dataChangeRequestsGroup) {
      let countGroups = dataChangeRequestsGroup.length;
      let countRequest = 0;

      for (let i = 0; i < dataChangeRequestsGroup.length; i++) {
        countRequest += dataChangeRequestsGroup[i].length;
      }

      if (this.employeeView) {
        result += `${countRequest}`;
      } else {
        // result += `${countGroups} - ${countRequest}`;

        if (dataChangeRequestsName == "ALL") {
          countGroups=0;
          for (let i = 0; i < dataChangeRequestsGroup.length; i++) {
            countGroups += dataChangeRequestsGroup[i].length;
          }
        }
        result += `${countGroups}`;
      }
    }

    return result;
  }

  /**
   * Selecciona los estados que se deben manejar y los que se deben mostrar.
   */
  setRequestStates() {
    this.tabStates = [
      "PENDING_APROVAL",
      "APPROVED",
      "REJECTED",
      "ALL",
    ];

    this.excludeDcrStates = [
      DCRStateCode.REGISTERED,
      DCRStateCode.CANCELED,
    ];

    this.setFilterRequestsStates();
  }

  /**
   * Asigna el filtro para obtener sólo las solicitudes con los estados que se van a manejar.
   */
  setFilterRequestsStates() {
    this.filterApi.addMultipleValue(
      "dcrStateId",
      this.getRequestStatesId(this.excludeDcrStates, true),
      true
    );
  }

  /**
   * Devuelve los Ids de la base de datos de la lista de códigos de estado pasada como parámetro.
   *
   * @param requestStateCodeList lista de códigos a procesar.
   * @param reverse si es true en lugar de procesar los estados de la lista pasada se procesan los
   * los estados que no coincidan con la lista.
   * @returns una lista de Ids de estados
   */
  getRequestStatesId(requestStateCodeList: DCRStateCode[], reverse: boolean = false) {
    let result: string[] = [];

    let requestStateTemp: DCRState[] = [];

    if (reverse) {
      requestStateTemp = this.dcrStates.filter(element => !requestStateCodeList.includes(element.stateCode));
    } else {
      requestStateTemp = this.dcrStates.filter(element => requestStateCodeList.includes(element.stateCode));
    }

    for (let item of requestStateTemp) {
      result.push(item.dcrStateId);
    }

    return result;
  }
}

