import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { CourseManagementInfo } from '../models/course-info.model';
import { Course } from '../models/course.model';


@Injectable({ providedIn: 'root' })
export class CourseService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private courseSubject: ReplaySubject<Course[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public courseObservable: Observable<Course[]> = this.courseSubject.asObservable();

    private courseRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public courseRouteObservable: Observable<string> = this.courseRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/courses';
    }

    constructor(private http: HttpClient) {
    }

    //#region COURSES
    getAllCourses(filter? : string) {
      if (filter == undefined || filter  == null) {
        filter = "";
      }

      let url = `${this.serviceUrl}${filter}`;

      return this.http.get<any>(url);
    }

    getAllCoursesByEmployee(employeeId?: string) {
        let employeeIdFilter="";

        if(employeeId!=undefined && employeeId != null){
            employeeIdFilter += `?employeeId=${employeeId}`;
        }

        let url = `${this.serviceUrl}${employeeIdFilter}`;

        return this.http.get<any>(url);
    }

    getAllCoursesBy(courseId: any){
        const url = `${this.serviceUrl}/Group`;

        return this.http.post<any>(url, courseId);
    }

    getCourse(courseId: string, related?: boolean) {
        let relatedFilter="";

        if(related!=undefined && related != null){
            relatedFilter = `?related=${related}`;
        }

        let url = `${this.serviceUrl}/${courseId}${relatedFilter}`;

        return this.http.get<Course>(url);
    }

    getCourseInfo(courseId?: string) {
      if (courseId == undefined || courseId  == null) {
        courseId = "";
      }

      let url = `${this.serviceUrl}/Info/${courseId}`;

      return this.http.get<CourseManagementInfo>(url);
    }
    //#endregion

    //#region CERTIFICATION COURSES
    getAllCertificationCourses(filter? : string) {
      if (filter == undefined || filter  == null) {
        filter = "";
      }

      let url = `${this.serviceUrl}/Certifications/${filter}`;

      return this.http.get<any>(url);
    }

    getAllCertificationCoursesByEmployee(employeeId?: string) {
        let employeeIdFilter="";

        if(employeeId!=undefined && employeeId != null){
            employeeIdFilter += `?employeeId=${employeeId}`;
        }

        let url = `${this.serviceUrl}/Certifications/${employeeIdFilter}`;

        return this.http.get<any>(url);
    }

    getAllCertificationCoursesBy(courseId: any) {
        const url = `${this.serviceUrl}/Certifications/Group`;

        return this.http.post<any>(url, courseId);
    }

    getCertificationCourse(courseId: string, related?: boolean) {
        let relatedFilter="";

        if(related!=undefined && related != null){
            relatedFilter = `?related=${related}`;
        }

        let url = `${this.serviceUrl}/Certifications/${courseId}${relatedFilter}`;

        return this.http.get<Course>(url);
    }

    getCertificationCourseInfo(courseId?: string) {
      if (courseId == undefined || courseId  == null) {
        courseId = "";
      }

      let url = `${this.serviceUrl}/Certifications/Info/${courseId}`;

      return this.http.get<CourseManagementInfo>(url);
    }
    //#endregion

    //#region COMMON
    getInfo(isCertificationCourse: boolean, courseId?: string) {
      if (isCertificationCourse) {
        return this.getCertificationCourseInfo(courseId);
      }

      return this.getCourseInfo(courseId);
    }

    createCourse(course: Course) {
        const endpointUrl = `${this.serviceUrl}`;
        return this.http.post(endpointUrl, course);
    }
    modifyCourse(courseId: string, newCourseData: any) {
        const endpointUrl = `${this.serviceUrl}/${courseId}`;
        return this.http.patch(endpointUrl, newCourseData);
    }
    deleteCourse(courseId: string) {
        const endpointUrl = `${this.serviceUrl}/${courseId}`;
        return this.http.delete(endpointUrl);
    }
    associateCourse(courseId: string, employeeId: string, trainingCenterId: string, finishDate: string) {
        const endpointUrl = `${this.serviceUrl}/${courseId}/Employee/${employeeId}/Center/${trainingCenterId}/FinishDate/${finishDate}`;
        return this.http.post(endpointUrl, null);
    }

    disassociateCourse(courseId: string, employeeId: string) {
        const endpointUrl = `${this.serviceUrl}/${courseId}/Employee/${employeeId}`;
        return this.http.delete(endpointUrl);
    }

    updateCourses(courses: Course[]) {
        this.courseSubject.next(courses);
    }

    updateCourseRoutes(id: string) {
        this.courseRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
    //#endregion
}
