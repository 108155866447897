

export class TrainingCenter {
    trainingCenterId: string;
    name: string;
    description: string;



    constructor(obj?: Partial<TrainingCenter>) {
      this.trainingCenterId = (obj && obj.trainingCenterId) || undefined;
      this.name = (obj && obj.name) || undefined;
      this.description = (obj && obj.description) || undefined;


    }
  }
