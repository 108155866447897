import { Component, OnInit, Input, ElementRef, ViewChild, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IInputData } from 'inzo-calendar-lib/lib/Interface/IInputData';
import { LocaleSettings } from 'inzo-calendar-lib/lib/Interface/LocaleSettings';
import * as moment from 'moment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { HolidayRequest } from 'src/app/models/holiday-request.model';
import { HolidayRequestListEvent } from '../../../interfaces/events.interface';
import { Employee } from 'src/app/models/employee.model';
import { HolidayRequestStateCode, HolidayRequestStateColor } from 'src/app/models/holiday-request-state-code-enum.model';
import { WorkCalendarService } from 'src/app/services/work-calendar.service';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { ResponseNotificationService } from 'inzo-portalempleado';
import { HolidayRequestChangesService } from 'src/app/services/holiday-request-changes.service';
import { getLocaleNames, loadDatesFromArrayString } from 'src/app/helpers/date.helper';
import { DynamicCssClass } from 'src/app/helpers/css.helper';
import { ICalendarDate } from 'inzo-calendar-lib/lib/Interface/ICalendarDate';
import { MatDialog, MatSlideToggleChange } from '@angular/material';
import { HolidayRequestEditDialogComponent } from '../holiday-request-edit-dialog/holiday-request-edit-dialog.component';
import { AHRCStateCode } from 'src/app/models/ahrc-state.model';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { EmployeeSelectionService } from 'src/app/modules/employee/services/employee-selection.service';

@Component({
  selector: 'app-holiday-request-management-calendar',
  templateUrl: './holiday-request-management-calendar.component.html',
  styleUrls: [
    './holiday-request-management-calendar.component.css',
    './../../maintenance.components.css',
    '../../maintenance-list.component.css'
  ],
})
export class HolidayRequestManagementCalendarComponent implements OnInit {
  @Input() employeeView: boolean = false;
  @Input() loadOnInit: boolean = true;

  @Input() selectedEmployees: Employee[] = [];
  @Input() currentEmployee: Employee;
  @Input() holidayRequests: HolidayRequest[] = [];
  @Input() holidayRequestStateCodes = HolidayRequestStateCode;

  @Input() visibleStatesCalendar: HolidayRequestStateCode[] = [];
  @Input() holidayRequestStates: HolidayRequestStateCode[] = [];

  @Input() showAllEmployeesToogledButton: boolean = false;

  @Output() currentYear: EventEmitter<number> = new EventEmitter();
  @Output() holidayRequestListEvent: EventEmitter<HolidayRequestListEvent> = new EventEmitter();

  @Output() holidayRequestCreateEvent: EventEmitter<HolidayRequest> = new EventEmitter();

  @Output() showAllEmployeesToogledEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  value: IInputData = {
    year: new Date().getFullYear(),
    dates: [],
    publicHolidayDays: [],
    disableWeekend: false,
    disablePublicHolidayDays: true,
  };

  locale: LocaleSettings = {
    dayNamesMin: [],
    monthNames: []
  };

  allHolidayRequestsAlreadyDisplayed = true;
  allHolidayRequestChangesAlreadyDisplayed = true;

  // Permissions
  canDirectAssignment: boolean = false;
  directAssignment: boolean = false;
  subscriptions: Subscription[] = [];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  constructor(
    private translate: TranslateService,
    private toastrService: ToastrService,
    private RNService: ResponseNotificationService,
    private workCalendarService: WorkCalendarService,
    protected holidayRequestChangesService: HolidayRequestChangesService,
    private dialog: MatDialog,
    protected permissionsService: PermissionsService,
    protected employeeSelectionService: EmployeeSelectionService,
  ) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = getLocaleNames(this.translate);
    });

    this.translate.get('CALENDAR.DAYS_OF_WEEK').subscribe((daysOfWeekLang) => {
      this.locale.dayNamesMin = daysOfWeekLang;
    });
    this.translate.get('CALENDAR.MONTHS').subscribe((monthsLang) => {
      this.locale.monthNames = monthsLang;
    });

    this.subscriptions.push(
      this.holidayRequestChangesService.canDirectAssignment.subscribe(checked => {
        this.canDirectAssignment = checked;
      })
    );

    this.subscriptions.push(
      this.holidayRequestChangesService.check.subscribe(checked => {
        this.directAssignment = checked;
      })
    );

    this.subscriptions.push(
      this.holidayRequestChangesService.holidayRequests.subscribe(data => {
        this.holidayRequests = data;

        this.loadHolidayRequests(this.holidayRequests);
      })
    );

    this.subscriptions.push(
      this.employeeSelectionService.currentEmployee.subscribe(currentEmployee => {
        this.currentEmployee = currentEmployee;

        this.loadWorkCalendar();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadWorkCalendar();

    if (this.loadOnInit) {
      this.loadHolidayRequests(this.holidayRequests);
    }
  }

  onCheckedDirectAssignment(event) {
    this.directAssignment = event.checked;
    this.holidayRequestChangesService.onChangeCheck(this.directAssignment);
  }

  OnDestroy() {
    this.holidayRequestChangesService.onChangeCheck(false);

    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /**
   * Cambia el año a mostrar
   * @param year
   */
  changeYear(year: number) {
    this.value.year += year;

    // Se actualiza el valor del año actual
    this.currentYear.emit(this.value.year);
  }

  /**
   * Carga las solicitudes de vacaciones en el calendario anual.
   * @param holidays - Array de solicitudes de vacaciones que se cargarán en el plugin de yearcalendar
   */
  loadHolidayRequests(holidayRequests: HolidayRequest[]): void {
    // Cada vez que recarguemos la lista de solicitudes de vacaciones, reiniciamos el array de fechas del calendario
    this.value.dates = [];

    if (holidayRequests) {
      holidayRequests.forEach(holidayRequest => {
        if (this.visibleStatesCalendar.includes(holidayRequest.holidaysRequestState.stateCode)) {
          holidayRequest.visibleOnYearCalendar = true;

          const color = this.getStateColor(holidayRequest.holidaysRequestState.stateCode);

          if (holidayRequest.holidaysRequestChanges.length > 0) {
            holidayRequest.holidaysRequestChanges.filter(x => (x && x.state && x.state.stateCode) === AHRCStateCode.PENDING_APROVAL).forEach(ahrChanges => {
              if (ahrChanges.datesToRemove && ahrChanges.datesToRemove.length > 0) {
                this.value.dates.push({
                  id: ahrChanges.ahrChangesId,
                  tooltip: '',
                  days: loadDatesFromArrayString(ahrChanges.datesToRemove),
                  color: color,
                  borderColor: '#df4759',
                  borderSize: '3px',
                  highlightedDates: loadDatesFromArrayString(ahrChanges.datesToRemove),
                });
              }
            });
          }

          // Se cambia el color del estado según el campo enum del modelo
          this.value.dates.push({
            id: holidayRequest.holidaysRequestId,
            tooltip: holidayRequest.employee.name + ' ' + holidayRequest.employee.firstSurname,
            days: loadDatesFromArrayString(holidayRequest.requestedDates),
            select: (range: ICalendarDate) => {this.editHolidayRequest(range)},
            color: color,
          });
        }
      });

      // Se actualiza el estado de las solicitudes
      this.holidayRequestListEvent.emit({ holidayRequestList: this.holidayRequests });
    }
  }

  loadWorkCalendar() {
    if(this.currentEmployee) {
      if(this.currentEmployee.workCalendar) {
        this.loadDisabledDays(this.currentEmployee.workCalendar);
      } else {
        this.workCalendarService.getWorkCalendar(this.currentEmployee.workCalendarId).subscribe(workCalendar => {
          this.loadDisabledDays(workCalendar);
        }, error => {
          this.RNService.showError(error);
        });
      }
    } else {
      this.value.disableWeekend = false;
      this.value.publicHolidayDays = [];
    }
  };

  loadDisabledDays(workCalendar: WorkCalendar): void {
    const publicHolidayDays = [];

    if (workCalendar.wcEvents) {
      workCalendar.wcEvents.forEach(event => {
        let startDate = moment(event.startDate);
        let endDate = moment(event.endDate);
        while (startDate.isSameOrBefore(endDate)) {
          publicHolidayDays.push({date: startDate.toDate()})
          startDate.add(1, 'days');
        }
      });
    }

    this.value.disableWeekend = workCalendar.disableWeekends;
    this.value.publicHolidayDays = publicHolidayDays;
  }

  /**
   * Muestra/oculta la solicitud de vacaciones en el calendario.
   * @param holidayRequest - La solicitud a mostrar/ocultar.
   */
  toggleHolidayLoadOnCalendar(holidayRequest: HolidayRequest): void {
    let indexOfHolidayRequestAlreadyVisibleOnCalendar = null;
    this.value.dates.forEach((date, iDate) => {
      if (date.id == holidayRequest.holidaysRequestId) {
        indexOfHolidayRequestAlreadyVisibleOnCalendar = iDate;
      }
    });

    if (indexOfHolidayRequestAlreadyVisibleOnCalendar == null) {
      // Se cambia el color del estado según el campo enum del modelo
      const color = this.getStateColor(holidayRequest.holidaysRequestState.stateCode);
      this.value.dates.push({
        id: holidayRequest.holidaysRequestId,
        tooltip: holidayRequest.employee.name + ' ' + holidayRequest.employee.firstSurname,
        days: loadDatesFromArrayString(holidayRequest.requestedDates),
        select: (range: ICalendarDate) => {this.editHolidayRequest(range)},
        color: color,
      });
      holidayRequest.visibleOnYearCalendar = true;
    } else {
      this.value.dates.splice(indexOfHolidayRequestAlreadyVisibleOnCalendar, 1);
      holidayRequest.visibleOnYearCalendar = false;
    }
  }

  /**
   * Muestra/oculta todas las solicitudes de vacaciones.
   */
  toggleDisplayAllHolidayRequests(): void {
    if (this.allHolidayRequestsAlreadyDisplayed) {
      this.holidayRequests.forEach(holidayRequest => {
        holidayRequest.visibleOnYearCalendar = false;
      });
      this.value.dates = [];
      this.allHolidayRequestsAlreadyDisplayed = false;
    } else {
      this.holidayRequests.forEach(holidayRequest => {
        holidayRequest.visibleOnYearCalendar = true;
      });
      this.loadHolidayRequests(this.holidayRequests);
      this.allHolidayRequestsAlreadyDisplayed = true;
    }
  }

  /**
   * Muestra todas las solicitudes de vacaciones cuyo estado sea `APPROVED`
   */
  displayApprovedHolidayRequests(): void {
    const onlyApprovedHolidayRequests = this.holidayRequests.filter(holidayRequest => {
      return (holidayRequest.holidaysRequestState.stateCode == this.holidayRequestStateCodes.APPROVED)
    });
    const allHolidayRequestsExceptApproved = this.holidayRequests.filter(holidayRequest => {
      return (holidayRequest.holidaysRequestState.stateCode !== this.holidayRequestStateCodes.APPROVED)
    });
    allHolidayRequestsExceptApproved.forEach(holidayRequest => {
      holidayRequest.visibleOnYearCalendar = false;
    });
    this.loadHolidayRequests(onlyApprovedHolidayRequests);
  }

  /**
   * Muestra todas las solicitudes de vacaciones cuyo estado sea `PENDING_APROVAL`
   */
  displayPendingHolidayRequests(): void {
    const onlyPendingHolidayRequests = this.holidayRequests.filter(holidayRequest => {
      return (holidayRequest.holidaysRequestState.stateCode == this.holidayRequestStateCodes.PENDING_APROVAL)
    });
    const allHolidayRequestsExceptPending = this.holidayRequests.filter(holidayRequest => {
      return (holidayRequest.holidaysRequestState.stateCode !== this.holidayRequestStateCodes.PENDING_APROVAL)
    });
    allHolidayRequestsExceptPending.forEach(holidayRequest => {
      holidayRequest.visibleOnYearCalendar = false;
    });
    this.loadHolidayRequests(onlyPendingHolidayRequests);
  }

  /**
   * Recoge un evento del componente de creación de una solicitud y ejecuta la acción correspondiente a ese evento.
   *
   * @param HolidayRequest evento recogido
   */
  createHolidayRequest(holidayRequest: HolidayRequest) {
    this.holidayRequestCreateEvent.emit(holidayRequest);
  }

  getStateColor(holidayRequestStateCode: number) {
    let color = "";

    if (this.visibleStatesCalendar.includes(holidayRequestStateCode)) {
      let stateName = HolidayRequestStateCode[holidayRequestStateCode];

      color = HolidayRequestStateColor[stateName];
    }

    return color;
  }

  /**
   * Crea la leyenda de colores del calendario
   */
  getLegend() {
    let result = "";

    for(let item of this.visibleStatesCalendar) {
      let stateName = HolidayRequestStateCode[item];
      let styleName = `dynamic-style-${stateName}`;
      let textLegend = "";

      this.translate.get(`HOLIDAY_REQUEST_STATES.${stateName}`).subscribe((a: string) => {
        textLegend = a;
      });

      let customClass: DynamicCssClass = new DynamicCssClass(
        {
          name: styleName,
          styleRules: [
            {
              key: 'color',
              value: this.getStateColor(item)
            }
          ]
        }
      );

      customClass.appendToDocument(true);

      result += `<span class="${styleName}">${textLegend}</span>`;
    }

    return result;
  }

  /**
   * Lanza un modal sobre el que poder editar los días de una solicitud de vacaciones aprobada o pendiente de aprobar.
   * En caso de que la solicitud esté pendiente de aprobar se actualizará directamente mientras que para el caso
   * de la edición de solicitudes aprobadas lo que se generará será una nueva solicitud pero será una solicitud de
   * cambio de días sobre la solicitud de vacaciones aprobada.
   * @param range
   */
  editHolidayRequest(range: ICalendarDate) {
    const holidayRequestToEdit = this.holidayRequests.filter(holidayRequest => {
      return holidayRequest.holidaysRequestId == range.id;
    })[0];

    if(holidayRequestToEdit && moment(holidayRequestToEdit.startDate) > moment.utc()) {
        const editHolidayRequestDialog = this.dialog.open(HolidayRequestEditDialogComponent, {
          width: '550px',
          data: {
            currentEmployee: this.currentEmployee,
            days: range.days,
            holidayRequestId: range.id,
            holidayRequestStates: this.holidayRequestStates
          }
        });

        editHolidayRequestDialog.afterClosed().subscribe((holidayRequestEdited) => {
          /**
           * En caso de que la solicitud de vacaciones a editar tenga el estado `PENDING_APROVAL` la editamos directamente.
           * Pero si su estado es `APPROVED` se creará una solicitud de cambio de vacaciones vinculada a esta solicitud
           * de vacaciones. Por tanto debemos reflejar esa solicitud en el calendario y no editarla directamente.
           */
          if (holidayRequestEdited) {
            if (holidayRequestToEdit.holidaysRequestState.stateCode == this.holidayRequestStateCodes.APPROVED) {
              holidayRequestEdited.holidaysRequestChanges.forEach(holidayRequestChanges => {
                const datesToRemoveList = [];
                holidayRequestChanges.datesToRemove.forEach(dateToRemove => {
                  datesToRemoveList.push(moment(dateToRemove).toDate());
                });
                this.value.dates.push({
                  id: holidayRequestChanges.ahrChangesId,
                  tooltip: '',
                  days: datesToRemoveList,
                  color: '#df4759',
                });
              });
            } else if (holidayRequestToEdit.holidaysRequestState.stateCode == this.holidayRequestStateCodes.PENDING_APROVAL) {
              let indexOfHolidayRequestFoundOnCalendar = this.value.dates.findIndex(date => date.id == holidayRequestEdited.holidaysRequestId);

              if (indexOfHolidayRequestFoundOnCalendar >= 0) {
                const holidayRequestEditedDaysList = [];
                holidayRequestEdited.requestedDates.forEach(requestedDate => {
                  holidayRequestEditedDaysList.push(moment(requestedDate).toDate());
                });
                this.value.dates[indexOfHolidayRequestFoundOnCalendar].days = holidayRequestEditedDaysList;
              }
            }
          } else {
            this.toggleHolidayLoadOnCalendar(holidayRequestToEdit);
            this.toggleHolidayLoadOnCalendar(holidayRequestToEdit);
          }
        });
    } else {
      this.translate.get('HOLIDAY_REQUEST_MAINTENANCE.FORM.ERROR.MODIFICATION_NOT_ALLOWED').subscribe((a: string) => {
        this.toastrService.error(a, '', { timeOut: 3000 });
      });
    }
  }
}
