// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Employee } from 'src/app/models/employee.model';
import { EmployeeType } from '../../../models/employee-type.model';
import { Category } from '../../../models/category.model';
import { Area } from '../../../models/area.model';
import { EmployeeSituation } from '../../../models/employee-situation.model';
import { HRAHistory } from '../../../models/hra-history.model';
import { DCRHistory } from '../../../models/dcr-history.model';
import { ContractType } from '../../../models/contract-type.model';
import { LaborAgreement } from 'src/app/models/labor-agreement.model';

// ################################################################################################################
// ## CLASS DataHistoryService
// ################################################################################################################
@Injectable({
  providedIn: 'root'
})
export class DataHistoryService {
  // SelectedView
  private selectedViewEvent = new BehaviorSubject<string>(null);
  public selectedView = this.selectedViewEvent.asObservable();

  // EmployeeView
  private employeeViewEvent = new BehaviorSubject<boolean>(null);
  public employeeView = this.employeeViewEvent.asObservable();

  // Employee
  private employeeEvent = new BehaviorSubject<Employee>(null);
  public employee = this.employeeEvent.asObservable();

  // Areas
  private areasEvent = new BehaviorSubject<Area[]>(null);
  public areas = this.areasEvent.asObservable();

  // Categories
  private categoriesEvent = new BehaviorSubject<Category[]>(null);
  public categories = this.categoriesEvent.asObservable();

  // ContractTypes
  private contractTypesEvent = new BehaviorSubject<ContractType[]>(null);
  public contractTypes = this.contractTypesEvent.asObservable();

  // Employees
  private employeesEvent = new BehaviorSubject<Employee[]>(null);
  public employees = this.employeesEvent.asObservable();

  // EmployeeSituations
  private employeeSituationsEvent = new BehaviorSubject<EmployeeSituation[]>(null);
  public employeeSituations = this.employeeSituationsEvent.asObservable();

  // LaborAgreements
  private laborAgreementsEvent = new BehaviorSubject<LaborAgreement[]>(null);
  public laborAgreements = this.laborAgreementsEvent.asObservable();

  // EmpoyeeTypes
  private employeeTypesEvent = new BehaviorSubject<EmployeeType[]>(null);
  public employeeTypes = this.employeeTypesEvent.asObservable();

  // Absences
  private absencesEvent = new BehaviorSubject<HRAHistory[]>(null);
  public absences = this.absencesEvent.asObservable();

  // Data Changes
  private dcrEvent = new BehaviorSubject<DCRHistory[]>(null);
  public dcr = this.dcrEvent.asObservable();

  // Holidays
  private holidaysEvent = new BehaviorSubject<HRAHistory[]>(null);
  public holidays = this.holidaysEvent.asObservable();

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor() { }

  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  // SelectedView
  setSelectedViewChange(data: string) {
    this.selectedViewEvent.next(data);
  }

  // EmployeeView
  setEmployeeViewChange(data: boolean) {
    this.employeeViewEvent.next(data);
  }

  // Employee
  setEmployeeChange(data: Employee) {
    this.employeeEvent.next(data);
  }

  // Areas
  setAreasChange(data: Area[]) {
    this.areasEvent.next(data);
  }

  // Categories
  setCategoriesChange(data: Category[]) {
    this.categoriesEvent.next(data);
  }

  // ContractTypes
  setContractTypesChange(data: ContractType[]) {
    this.contractTypesEvent.next(data);
  }

  // Employees
  setEmployeesChange(data: Employee[]) {
    this.employeesEvent.next(data);
  }

  // EmployeeSituations
  setEmployeeSituationsChange(data: EmployeeSituation[]) {
    this.employeeSituationsEvent.next(data);
  }

  // EmpoyeeTypes
  setEmployeeTypesChange(data: EmployeeType[]) {
    this.employeeTypesEvent.next(data);
  }

  // LaborAgreements
  setLaborAgreementsChange(data: LaborAgreement[]) {
    this.laborAgreementsEvent.next(data);
  }

  // Absences
  setAbsencesChange(data: HRAHistory[]) {
    this.absencesEvent.next(data);
  }

  // Data Changes
  setDCRChange(data: DCRHistory[]) {
    this.dcrEvent.next(data);
  }

  // Holidays
  setHolidaysChange(data: HRAHistory[]) {
    this.holidaysEvent.next(data);
  }

  /* ****************************************************************************************************************** */
  /* ** Reset Observables
  /* ****************************************************************************************************************** */
  resetObservables() {
    this.setEmployeeChange(null);
    this.setAreasChange(null);
    this.setCategoriesChange(null);
    this.setEmployeesChange(null);
    this.setEmployeeSituationsChange(null);
    this.setLaborAgreementsChange(null);
    this.setEmployeeTypesChange(null);
    this.setAbsencesChange(null);
    this.setDCRChange(null);
    this.setHolidaysChange(null);
  }

  resetAllObservables() {
    this.resetObservables();

    this.setSelectedViewChange(null);
    this.setEmployeeViewChange(null);
  }
}
