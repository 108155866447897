import { Component, OnInit, AfterViewInit } from '@angular/core';
import { EmployeeTypeService } from 'src/app/services/employee-type.service';

@Component({
  selector: 'app-maintenance-employee-type',
  templateUrl: './employee-type.component.html',
  styleUrls: ['./../maintenance.components.css'],
})
export class EmployeeTypeComponent implements AfterViewInit, OnInit {
  showForm = false;
  constructor( private employeeTypeService: EmployeeTypeService) {
  }
  ngAfterViewInit() {
    this.employeeTypeService.employeeTypeRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
  ngOnInit() {
  }
}
