import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AbsenceComponent } from '../../absence/absence.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Absence } from 'src/app/models/absence.model';
import { AbsenceService } from 'src/app/services/absence.service';
import { Employee } from 'src/app/models/employee.model';
import { AccountService } from 'inzo-portalempleado';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { disableDay, disableWeekEnd, transformDate } from 'src/app/helpers/date.helper';
import { WorkCalendarService } from 'src/app/services/work-calendar.service';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { AbsenceState } from 'src/app/models/absence-state.model';
import moment = require('moment');
import { AbsenceStateCode } from 'src/app/models/absence-state-code-enum.model';
import { AbsenceChangesService } from 'src/app/services/absence-changes.service';
import { ListDates } from 'src/app/models/list-dates.model';
import * as jsonpatch from 'fast-json-patch';
import { getDateTimeString } from '../../../helpers/date.helper';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

@Component({
  selector: 'app-absence-edit-dialog',
  templateUrl: './absence-edit-dialog.component.html',
  styleUrls: ['./absence-edit-dialog.component.css'],
})
export class AbsenceEditDialogComponent {
  absenceStates: AbsenceState[] = [];

  showReason: boolean = false;

  formGroupControl: FormGroup;

  allAbsenceTypes = [];
  absenceId: string;
  absenceName: string;
  absence: Absence = null;

  currentEmployee: Employee = null;
  disabledDays: any[] = [];
  edit = false;

  selectedDates: ListDates = new ListDates(DATE_FORMAT, true);

  // Permissions
  canDirectAssignment: boolean = false;
  directAssignment: boolean = false;

  constructor (
    public dialogRef: MatDialogRef<AbsenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private accountService: AccountService,
    private employeeManagementService: EmployeeManagementService,
    private absenceService: AbsenceService,
    private absenceChangesService: AbsenceChangesService,
    private workCalendarService: WorkCalendarService,
  ) { }

  ngOnInit() {
    const absenceId = this.data.absenceId;

    if (!this.data.absence) {
      this.absenceService.getAbsence(absenceId).subscribe(response => {
        this.absence = response as Absence;
        this.selectedDates.setDates(this.absence.requestedDates);
      });
    } else {
      this.absence = this.data.absence as Absence;
      this.selectedDates.setDates(this.absence.requestedDates);
    }

    this.formGroupControl = new FormGroup({
      startDate: new FormControl('', Validators.required),
      finishDate: new FormControl('', Validators.required),
      state: new FormControl(''),
      reason: new FormControl(''),
    });
    this.formGroupControl.patchValue(
      {
        startDate: this.selectedDates.first(),
        finishDate: this.selectedDates.last(),
      }
    );

    this.absenceChangesService.canDirectAssignment.subscribe(checked => {
      this.canDirectAssignment = checked;
    });

    this.absenceChangesService.check.subscribe(checked => {
      this.directAssignment = checked;
    });

    const userId = this.accountService.currentUser.id;

    if (this.data.currentEmployee) {
      this.currentEmployee = this.data.currentEmployee;

      if (this.data.absenceStates) {
        this.absenceStates = this.data.absenceStates;
      }
    } else {
      this.employeeManagementService.getEmployeeByUserId(userId, true).subscribe(employee => {
        this.currentEmployee = employee;
      });
    }

    this.absenceId = this.data.absenceId;
    this.absenceName = this.data.absenceName;
    this.absence = this.data.absence;

    if (this.currentEmployee && this.currentEmployee.workCalendar) {
      this.loadDisabledDays(this.currentEmployee.workCalendar);
    } else if (this.currentEmployee) {
      this.workCalendarService.getWorkCalendar(this.currentEmployee.workCalendarId).subscribe(response => {
        this.currentEmployee.workCalendar = response;
        this.loadDisabledDays(this.currentEmployee.workCalendar);
      });
    }

    if (this.absence) {
      this.edit = true;
      this.formGroupControl.setValue({
        startDate: this.absence.startDate,
        finishDate: this.absence.finishDate,
        state: this.absence.absenceStateId,
        reason: this.absence.reason,
      });
    }
  }

  selectedDatesChange(event) {
    this.selectedDates = event;

    if (this.selectedDates.empty()) {
      this.formGroupControl.get("startDate").reset();
      this.formGroupControl.get("finishDate").reset();
    } else {
      this.formGroupControl.patchValue(
        {
          startDate: this.selectedDates.first(),
          finishDate: this.selectedDates.last(),
        }
      );
    }
  }

  update() {
    this.spinner.show();
    const observer = jsonpatch.observe(this.absence);
    this.absence.startDate = getDateTimeString(this.selectedDates.first(), DATE_FORMAT);
    this.absence.finishDate = getDateTimeString(this.selectedDates.last(), DATE_FORMAT);
    this.absence.requestedDates = this.selectedDates.toDatesStrings();
    const patch = jsonpatch.generate(observer);

    this.absenceService.modifyAbsence(this.absence.absenceId, patch).subscribe(() => {
      this.translate.get('HOLIDAY_REQUEST_MAINTENANCE.NAME').subscribe((componentTranslated: string) => {
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.UPDATE').subscribe((a: string) => {toastTitle = a});
        this.translate.get('MESSAGES.SUCCESS.UPDATE_LONG_SIMPLE', {type: componentTranslated}).subscribe((a: string) => {toastBody = a});
        this.spinner.hide();
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
      });
    }, error => {
      this.spinner.hide();
      let title = "MESSAGES.ERROR.UPDATE_CONFLICT";
      let message = "MESSAGES.ERROR.UPDATE_CONFLITCT_BODY";

      if (error.error.type == "OVERLAPPING_DATES") {
        title = `MESSAGES.ERROR.${error.error.type}`;
        title = `MESSAGES.ERROR.${error.error.type}_BODY`;
      } else if (error.error.type == "error") {
        title = 'MESSAGES.ERROR.UNEXPECTED';
        title = 'MESSAGES.ERROR.UNEXPECTED_BODY';
      }

      this.translate.get(title).subscribe((lang: string) => {
        title = lang;
      });
      this.translate.get(message).subscribe((lang: string) => {
        message = lang;
      });

      this.toastrService.error(message, title, { timeOut: 3000 });
    }, () => {
      this.spinner.hide();
      this.dialogRef.close(this.absence);
    });
  }

  assingAbsenceType(e) {
    this.formGroupControl.get('absenceType').setValue(e.option.value);
  }

  loadDisabledDays(workCalendar: WorkCalendar): void {
    const disabledDays = [];

    if (workCalendar.wcEvents) {
      workCalendar.wcEvents.forEach(event => {
        let startDate = moment(event.startDate);
        let endDate = moment(event.endDate);
        while (startDate.isSameOrBefore(endDate)) {
          disabledDays.push({date: startDate.toDate()})
          startDate.add(1, 'days');
        }
      });
    }

    this.disabledDays = disabledDays;
  }

  /**
   * Comprueba los días que se deben deshabilitar del calendario
   *
   * @param d fecha en formato objeto momentjs
   * @returns
   */
  getDisabledDays (d: any | null): boolean {
    let result = true;

    if ((d.day() == 0 || d.day() == 6)) {
      result = disableWeekEnd(d, this.currentEmployee.workCalendar);
    } else {
      result = disableDay(d, this.disabledDays);
    }

    return result;
  }

  getAbsenceState(state: string) {
    let currentState = null;

    for(let state of this.absenceStates) {
      if (state.absenceStateId == this.formGroupControl.get("state").value) {
        currentState = state;
        break;
      }
    }

    this.showReason = currentState.stateCode == AbsenceStateCode[state];
  }

  closeDialog(): void {
    this.formGroupControl.get('startDate').setValue(this.selectedDates.first());
    this.formGroupControl.get('finishDate').setValue(this.selectedDates.last());

    this.dialogRef.close();
  }

}
