import { Component, ViewChild, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatAutocompleteSelectedEvent, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { transformDate } from 'src/app/helpers/date.helper';
import { HRADateFilterService } from 'src/app/services/hra-date-filter.service';
import { Employee } from 'src/app/models/employee.model';
import { Subscription } from 'rxjs';
import { getDateTimeString } from 'src/app/helpers/date.helper';
import { EmployeeType } from 'src/app/models/employee-type.model';
import { DataHistoryService } from '../../../services/data-history.service';
import { EmployeeContract } from 'src/app/models/employee-contract.model';
import { EmployeeContractService } from '../../../../../services/employee-contract.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as Rxjs from 'rxjs';
import * as _ from 'lodash';
import moment = require('moment');
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ContractType } from '../../../../../models/contract-type.model';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { LaborAgreement } from 'src/app/models/labor-agreement.model';

@Component({
  selector: 'app-contract-history-list',
  templateUrl: './employee-contract-history-list.component.html',
  styleUrls: [
    './employee-contract-history-list.component.css',
    '../../../../../../assets/styles/action.components.css',
    '../../../../../../assets/styles/action-list.component.css',
  ],
})
export class EmployeeContractHistoryListComponent implements OnInit, OnDestroy {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  showAddPanel = false;
  employeeView = false;
  employee: Employee = null;
  employees: Employee[] = [];

  minDate: Date;

  laborAgreements: LaborAgreement[] = [];
  contractTypes: ContractType[] = [];
  employeeTypes: EmployeeType[] = [];

  displayedColumns: string[] = [
    'actions',
    'startDate',
    'finishDate',
    'contractCode',
    'contractType',
    'employeeType',
  ];

  startDateFilter: string;
  finishDateFilter: string;

  currentEmployeeContract : EmployeeContract = new EmployeeContract();

  ELEMENT_DATA: EmployeeContract[] = [];
  dataSource: MatTableDataSource<EmployeeContract>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  subscriptions: Subscription[] = [];

  EmployeeContractHistoryFormGroup: FormGroup = new FormGroup({
    // employeeContractId  : new FormControl(''),
    contractCode  : new FormControl('', Validators.required),
    contractType  : new FormControl('', Validators.required),
    employeeType  : new FormControl('', Validators.required),
    startDate     : new FormControl('', Validators.required),
    finishDate    : new FormControl(''),
  });

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private dataHistoryService: DataHistoryService,
    private hraDateFilterService: HRADateFilterService,
    private employeeContractService: EmployeeContractService,
    protected permissionsService: PermissionsService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
  ) {
    this.minDate = moment().startOf('month').toDate();
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;
      })
    );

    // EmployeeView
    this.subscriptions.push(
      this.dataHistoryService.employeeView.subscribe(data => {
        this.employeeView = data;
      })
    );

    // ContractTypes
    this.subscriptions.push(
      this.dataHistoryService.contractTypes.subscribe(data => {
        this.contractTypes = data;
      })
    );

    // LaborAgreements
    this.subscriptions.push(
      this.dataHistoryService.laborAgreements.subscribe(data => {
        this.laborAgreements = data;
      })
    );

    // EmpoyeeTypes
    this.subscriptions.push(
      this.dataHistoryService.employeeTypes.subscribe(data => {
        this.employeeTypes = data;
      })
    );

    // Employee
    this.subscriptions.push(
      this.dataHistoryService.employee.subscribe(data => {
        this.employee = data;

        this.loadDataOnTable();
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  //#endregion

  /* ################################################################################################################## */
  /* ## DATA METHODS
  /* ################################################################################################################## */
  loadDataOnTable() {
    let filters: string = "";

    if (this.employee != undefined && this.employee != null) {
      this.ELEMENT_DATA = this.employee.employeeContracts;

      // Se busca el valor actual
      this.assigCurrentContract();

      this.ELEMENT_DATA = _.orderBy(this.ELEMENT_DATA, a => moment(a.startDate).unix(), 'desc');
      this.dataSource = new MatTableDataSource<EmployeeContract>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  getAndLoadDataOnTable() {
    this.employeeContractService.getEmployeeContractsForEmployee(this.employee.employeeId).subscribe(EmployeeContractHisoryResponse => {
      this.employee.employeeContracts = (EmployeeContractHisoryResponse && EmployeeContractHisoryResponse.items) || [];

      this.loadDataOnTable();

      // Se propaga el cambio a los observadores
      this.dataHistoryService.setEmployeeChange(this.employee);

      this.spinner.hide();
    },
    error => {
      this.spinner.hide();
    });
  }

  onCreate() {
    if (this.EmployeeContractHistoryFormGroup && this.EmployeeContractHistoryFormGroup.valid) {
      this.spinner.show();

      this.getEmployeeContractToSend();

      this.employeeContractService.createEmployeeContract(this.currentEmployeeContract).subscribe(response => {
        this.EmployeeContractHistoryFormGroup.get('startDate').setValue('');

        this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.EMPLOYEE_CONTRACT.CREATION_SUCCESS').subscribe((text: string) => {
          this.toastrService.success(text, '', { timeOut: 3000 });
        });

        this.resetForm();

        this.getAndLoadDataOnTable();

        this.showAddPanel = false;
      },
      error => {
        console.error('error --> ', error);
        this.spinner.hide();
        if(error.error === 'MISSING_PARAMS') {
          this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.EMPLOYEE_CONTRACT.ERROR.MISSING_PARAMS').subscribe((text: string) => {
            this.toastrService.error(text, '', { timeOut: 3000 });
          });
        } else {
          this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.EMPLOYEE_CONTRACT.ERROR.UNEXPECTED').subscribe((text: string) => {
            this.toastrService.error(text, '', { timeOut: 3000 });
          });
        }
      });
    }
  }

  onRemove(EmployeeContract: EmployeeContract) {
    let text;
    this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.MESSAGE.EMPLOYEE_CONTRACT.DELETE_CONFIRMATION.TEXT').subscribe((transitionStr: string) => {
      text = transitionStr;
    });

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      data: {
        text
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.spinner.show();
        this.employeeContractService.deleteEmployeeContract(EmployeeContract.employeeContractId).subscribe(response => {
            this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.EMPLOYEE_CONTRACT.DELETION_SUCCESS').subscribe((text: string) => {
              this.toastrService.success(text, '', { timeOut: 3000 });
            });

            this.getAndLoadDataOnTable();
        },
        error => {
          this.spinner.hide();
          this.translate.get('EMPLOYEE_CONTRACTUAL_HISTORY.FORM.EMPLOYEE_CONTRACT.ERROR.UNEXPECTED').subscribe((text: string) => {
            this.toastrService.error(text, '', { timeOut: 3000 });
          });
        });
      }
    });
  }

  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  assigCurrentContract() {
    // Se busca el valor actual
    const currentEmployeeContractHistory = _(this.employee.employeeContracts).filter(x => moment(x.startDate) <= moment()).maxBy(x => moment(x.startDate).unix());

    if (currentEmployeeContractHistory) {
      currentEmployeeContractHistory.contractType = this.getContractType(currentEmployeeContractHistory.contractTypeId);
      currentEmployeeContractHistory.employeeType = this.getEmployeeType(currentEmployeeContractHistory.employeeTypeId);
      currentEmployeeContractHistory.laborAgreement = this.getLaborAgreement(currentEmployeeContractHistory.laborAgreementId);

      this.employee.employeeContract = currentEmployeeContractHistory;
      this.employee.employeeContractId = currentEmployeeContractHistory.employeeContractId;

      if (currentEmployeeContractHistory.finishDate) {
        this.employee.contractEndDate = currentEmployeeContractHistory.finishDate;
      }
    }
  }

  resetForm() {
    this.showAddPanel = false;
    Object.keys(this.EmployeeContractHistoryFormGroup.controls).forEach((name) => {
      const currentControl = this.EmployeeContractHistoryFormGroup.controls[name];

      currentControl.setValue('');
    });

    this.EmployeeContractHistoryFormGroup.markAsPristine();
    this.EmployeeContractHistoryFormGroup.clearValidators();
  }

  /* ****************************************************************************************************************** */
  /* ** EmployeeContract
  /* ****************************************************************************************************************** */
  getEmployeeContractToSend() {
    this.currentEmployeeContract.employeeId = this.employee.employeeId;
    let startDate = this.EmployeeContractHistoryFormGroup.get('startDate').value
    let finishDate = this.EmployeeContractHistoryFormGroup.get('finishDate').value

    this.currentEmployeeContract.startDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.currentEmployeeContract.finishDate = moment(finishDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

    if (!finishDate || finishDate == '') {
      this.currentEmployeeContract.finishDate = undefined;
    }
  }

  /* ################################################################################################################## */
  /* ## AUTOCOMPLETE METHODS
  /* ################################################################################################################## */
  // ContractType
  contractTypeSelectedOptionChanged(e: MatAutocompleteSelectedEvent) {
    const item: ContractType = e.option.value;

    if (!_.isNil(item)) {
      this.currentEmployeeContract.contractTypeId = item.contractTypeId;
      this.currentEmployeeContract.contractType = item;

      this.EmployeeContractHistoryFormGroup.get('contractCode').setValue(item.contractCode);
    } else {
      this.currentEmployeeContract.contractTypeId = undefined;
      this.currentEmployeeContract.contractType = undefined;

      this.EmployeeContractHistoryFormGroup.get('contractCode').setValue('');
    }
  }

  // ContractType
  contractTypeDisplay = (item?: ContractType) => {
    let result = (item)
      ? item.description
      : undefined;

    return result;
  }

  // EmployeeType
  employeeTypeSelectedOptionChanged(e: MatAutocompleteSelectedEvent) {
    const item: EmployeeType = e.option.value;

    if (!_.isNil(item)) {
      this.currentEmployeeContract.employeeTypeId = item.employeeTypeId;
      this.currentEmployeeContract.employeeType = item;
    } else {
      this.currentEmployeeContract.employeeTypeId = undefined;
      this.currentEmployeeContract.employeeType = undefined;
    }
  }

  // EmployeeType
  employeeTypeDisplay = (item?: EmployeeType) => {
    let result = (item)
      ? item.description
      : undefined;

    return result;
  }

  onStartDateSelect(event: any): void {
    const date = event.target.value;
    this.EmployeeContractHistoryFormGroup.get('startDate').setValue(moment(date).format('DD/MM/YYYY'));
  }

  onFinishDateSelect(event: any): void {
    const date = event.target.value;
    this.EmployeeContractHistoryFormGroup.get('finishDate').setValue(moment(date).format('DD/MM/YYYY'));
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  getContractTypeStr(contractTypeId) {
    let contractType = this.getContractType(contractTypeId);

    return (contractType != null)
      ? contractType.description
      : '';
  }

  getContractType(contractTypeId) {
    let result;

    if (this.contractTypes) {
      result = this.contractTypes.find(element => element.contractTypeId == contractTypeId);
    }

    return result;
  }

  getEmployeeTypeStr(employeeTypeId) {
    let employeeType = this.getEmployeeType(employeeTypeId);

    return (employeeType != null)
      ? employeeType.description
      : '';
  }

  getEmployeeType(employeeTypeId) {
    let result;

    if (this.employeeTypes) {
      result = this.employeeTypes.find(element => element.employeeTypeId == employeeTypeId);
    }

    return result;
  }

  getLaborAgreement(laborAgreementId) {
    let result;

    if (this.laborAgreements) {
      result = this.laborAgreements.find(element => element.laborAgreementId == laborAgreementId);
    }

    return result;
  }

  getContractCode(contractTypeId) {
    let result = '';

    if (this.contractTypes) {
      let employeeType = this.contractTypes.find(element => element.contractTypeId == contractTypeId);
      result = (employeeType != null)
        ? employeeType.contractCode
        : "";
    }

    return result;
  }

  getCreatedBy(employeeId) {
    let result = '';

    if (this.employees) {
      let employee = this.employees.find(element => element.employeeId == employeeId);
      result = (employee != null)
        ? `${employee.secondSurname} ${employee.firstSurname}, ${employee.name}`
        : "";
    }

    return result;
  }

  getDate(date: any, format: string): string {
    let result = getDateTimeString(date, format);

    return result != "Invalid date"
      ? result
      : "";
  }

  isCurrentValue(item): boolean {
    return this.employee.employeeContractId == item.employeeContractId;
  }

  canAdd(): boolean {
    return (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH);
  }

  canDelete(item): boolean {
    let result = (this.userRolesPermissions.isAdmin || this.userRolesPermissions.isRRHH);
    const startDate = moment(item.startDate);
    const currentDate = moment();

    if (startDate.year() == currentDate.year()) {
      result = result && (startDate.month() >= currentDate.month());
    } else if (startDate.year() > currentDate.year()) {
      result = true;
    } else {
      result = false;
    }

    return result;
  }
}
