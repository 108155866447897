import { WCEventType } from "./wc-event-type.model";
import { WorkCalendar } from "./work-calendar.model";

export class WCEvent {
  wcEventId         : string;
  name            : string;
  location        : string;
  startDate       : any;
  endDate         : any;

  //Relations
  workCalendarId  : string;
  workCalendar    : WorkCalendar;

  WCEventTypeId     : string;
  wcEventType     : WCEventType;

  constructor(obj?: Partial<WCEvent>) {
    this.wcEventId        = (obj && obj.wcEventId) || undefined;
    this.name           = (obj && obj.name) || undefined;
    this.location       = (obj && obj.location) || undefined;
    this.startDate      = (obj && obj.startDate) || undefined;
    this.endDate        = (obj && obj.endDate) || undefined;

    //Relations
    this.workCalendarId = (obj && obj.workCalendarId) || undefined;
    this.workCalendar   = (obj && obj.workCalendar) || undefined;

    this.WCEventTypeId    = (obj && obj.WCEventTypeId) || undefined;
    this.wcEventType    = (obj && obj.wcEventType) || undefined;
  }
}
