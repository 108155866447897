import { EmployeeCourse } from "./employee-course.model";
import { CourseType } from './course-type.model';

export class Course {
  courseId        : string;
  code            : string;
  description     : string;

  //Relations
  courseTypeId    : string;
  courseType      : CourseType;
  employeeCourses : EmployeeCourse[];

  constructor(obj?: Partial<Course>) {
    this.courseId         = (obj && obj.courseId) || undefined;
    this.code             = (obj && obj.code) || undefined;
    this.description      = (obj && obj.description) || undefined;

    //Relations
    this.courseTypeId     = (obj && obj.courseTypeId) || undefined;
    this.courseType       = (obj && obj.courseType) || undefined;

    this.employeeCourses  = (obj && obj.employeeCourses) || [];
  }
}
