import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector    : 'app-maintenance-personal-language',
  templateUrl : './personal-language.component.html',
  styleUrls   : ['./../maintenance.components.css'],
})
export class PersonalLanguageComponent implements AfterViewInit, OnInit {
  showForm = false;
  constructor( private languageService: LanguageService) {
  }
  ngAfterViewInit() {
    this.languageService.languageRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
  ngOnInit() {
  }
}
