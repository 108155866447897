import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Employee } from 'src/app/models/employee.model';
import { HolidayRequestState } from 'src/app/models/holiday-request-state.model';
import { HolidayRequest } from 'src/app/models/holiday-request.model';
import { HolidayRequestChangesService } from 'src/app/services/holiday-request-changes.service';
import { HolidayRequestCreateRangeDialogComponent } from '../holiday-request-create-range-dialog/holiday-request-create-range-dialog.component';

@Component({
  selector: 'app-holiday-request-calendar-actions',
  templateUrl: './holiday-request-calendar-actions.component.html',
  styleUrls: ['./holiday-request-calendar-actions.component.css']
})
export class HolidayRequestCalendarActionsComponent implements OnInit {
  @Input() currentEmployee: Employee;
  @Input() holidayRequestStates: HolidayRequestState[] = [];

  @Output() holidayRequestCreateEvent: EventEmitter<HolidayRequest> = new EventEmitter();

  directAssignment: boolean = false;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  createHolidayRequest(): void {
    const createRangeDialog = this.dialog.open(HolidayRequestCreateRangeDialogComponent, {
      width: '500px',
      data: {
        currentEmployee: this.currentEmployee,
        holidayRequestStates: this.holidayRequestStates,
      }
    });

    createRangeDialog.afterClosed().subscribe((newHolidayRequest: HolidayRequest) => {
      if (newHolidayRequest) {
        this.holidayRequestCreateEvent.emit(newHolidayRequest);
      }
    });
  }
}
