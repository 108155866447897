import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeManagementChangesService {
  private editable = new BehaviorSubject<boolean>(false);

  public canEdit = this.editable.asObservable();

  constructor() { }

  onChangeCheck(data: boolean) {
    this.editable.next(data);
  }

}