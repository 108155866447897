export { } // this will make it module
 
declare global { // this is important to access it as global type String
    interface String {
        capitalizeFirstLetter(): string;
        capitalizeEachWord(): string;
        isEqualIgnoreCase(otherString: string): boolean;
    }

    interface Array<T> {
        insertAt(i: number, ...rest: Array<T>): Array<T>;
    }

    interface Date {
        stdTimezoneOffset(): number;
        isDstObserved(): boolean;
    }
}

String.prototype.capitalizeFirstLetter = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

String.prototype.capitalizeEachWord = function() {
    var index, word, words, _i, _len;
    words = this.split(" ");
    for (index = _i = 0, _len = words.length; _i < _len; index = ++_i) {
        word = words[index].charAt(0).toUpperCase();
        words[index] = word + words[index].substr(1);
    }
    return words.join(" ");
};

String.prototype.isEqualIgnoreCase = function(otherString: string) {
    if((this===null || this===undefined) && (otherString!==null && otherString!==undefined)) {
        return false;
    } else if((otherString===null || otherString===undefined) && (this!==null || this!==undefined) ) {
        return false;
    } else {
        return this.toUpperCase() === otherString.toUpperCase();
    }
}

Array.prototype.insertAt = function(i: number,...rest){
    return this.slice(0,i).concat(rest,this.slice(i));
}

Date.prototype.stdTimezoneOffset = function () {
    var jan = new Date(this.getFullYear(), 0, 1);
    var jul = new Date(this.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

Date.prototype.isDstObserved = function () {
    return this.getTimezoneOffset() < this.stdTimezoneOffset();
}