import { Component, OnInit } from '@angular/core';

import { AnnualWorkHourService } from 'src/app/services/annual-work-hour.service';

@Component({
  selector: 'app-annual-work-hour',
  templateUrl: './annual-work-hour.component.html',
  styleUrls: [
    './annual-work-hour.component.css',
    './../../../components/maintenance.components.css',
  ],
})
export class AnnualWorkHourComponent implements OnInit {
  showForm = false;

  constructor(private annualWorkHourService: AnnualWorkHourService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.annualWorkHourService.annualWorkHourRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
}
