import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { HolidayRequestManagementComponent } from '../holiday-request-management.component';
@Component({
  selector: 'app-holiday-management-reject-dialog',
  templateUrl: './holiday-management-reject-dialog.component.html',
  styleUrls: ['./holiday-management-reject-dialog.component.css'],
})
export class HolidayManagementRejectDialogComponent {
  formGroupControl: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<HolidayRequestManagementComponent>,
  ) { }

  ngOnInit() {
    this.formGroupControl = new FormGroup({
      reason: new FormControl('', Validators.required),
    });
  }

  update() {
    this.dialogRef.close(this.formGroupControl.get('reason').value);
  }

}