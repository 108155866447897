import { Component, ViewChild, Input, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, BaseListComponent, Hotkeys, SecurityStateManagementService } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatSort } from '@angular/material';
import { transformDate } from 'src/app/helpers/date.helper';
import { HRADateFilterService } from 'src/app/services/hra-date-filter.service';
import { Employee } from '../../../models/employee.model';
import { Subscription } from 'rxjs';
import { getDateTimeString } from '../../../helpers/date.helper';
import { Area } from 'src/app/models/area.model';
import { EmployeeAreaHistory } from 'src/app/models/employee-area-history.model';

@Component({
  selector: 'app-area-history',
  templateUrl: './employee-area-history-list.component.html',
  styleUrls: [
    './employee-area-history-list.component.css',
    '../../action.components.css',
    '../../action-list.component.css',
  ]
})
export class EmployeeAreaHistoryComponent extends BaseListComponent<EmployeeAreaHistory> implements OnInit, OnDestroy {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  @Input() employeeView = false;
  @Input() employee: Employee = null;
  @Input() employees: Employee[] = [];
  @Input() areas: Area[] = [];
  @Input() displayedColumns: string[] = [
    // 'createdDate',
    'startDate',
    'createdByUserId',
    'areaId',
  ];

  canAdd = false;
  canDelete = false;

  startDateFilter: string;
  finishDateFilter: string;

  ELEMENT_DATA: EmployeeAreaHistory[] = [];
  dataSource: MatTableDataSource<EmployeeAreaHistory>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  subscriptions: Subscription[] = [];

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public SSMService: SecurityStateManagementService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private hraDateFilterService: HRADateFilterService,

  ) {
    super(accountService, SSMService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
    ];

    this.onBuild = () => {
    };

    this.onInit = () => {
    }
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnChanges(changes: SimpleChanges): void {
    this.hraDateFilterService.dates.subscribe(dates => {
      this.startDateFilter = dates.dataStartDate;
      this.finishDateFilter = dates.dataFinishDate;
      if (this.employee && this.employee.employeeId) {
        this.loadDataOnTable();
      }
    });
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  //#endregion

  /* ################################################################################################################## */
  /* ## DATA METHODS
  /* ################################################################################################################## */
  loadDataOnTable() {
    let filters: string = "";

    if (this.employee != undefined && this.employee != null) {
      this.ELEMENT_DATA = this.employee.employeeAreasHistory;

      this.changeDateFormat(this.ELEMENT_DATA);

      this.dataSource = new MatTableDataSource<EmployeeAreaHistory>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  changeDateFormat(ELEMENT_DATA) {
    for (let item of ELEMENT_DATA) {
      item.startDate = transformDate(item.startDate);
      item.finishDate = transformDate(item.finishDate);
      item.createdDate = transformDate(item.createdDate);
      if (item.approveDate != null) {
        item.approveDate = transformDate(item.approveDate);
      }
    }
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  getArea(areaId) {
    let area = this.areas.find(element => element.areaId == areaId);
    let result = (area != null)
      ? area.description
      : "";

    return result;
  }

  getCreatedBy(employeeId) {
    let employee = this.employees.find(element => element.employeeId == employeeId);
    let result = (employee != null)
      ? `${employee.secondSurname} ${employee.firstSurname}, ${employee.name}`
      : "";

    return result;
  }

  getDate(date: any, format: string): string {
    let result = getDateTimeString(date, format);

    return result != "Invalid date"
      ? result
      : "";
  }
}
