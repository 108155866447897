import { Component, OnInit } from '@angular/core';
import { HolidayRequest } from 'src/app/models/holiday-request.model';
import { HolidayRequestStateService } from 'src/app/services/holiday-request-state.service';
import { HolidayRequestService } from 'src/app/services/holiday-request.service';
import { forkJoin } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { HolidayRequestStateCode } from 'src/app/models/holiday-request-state-code-enum.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HolidayRequestState } from 'src/app/models/holiday-request-state.model';
import { AbsenceService } from 'src/app/services/absence.service';
import { Absence } from 'src/app/models/absence.model';
import { AbsenceStateService } from 'src/app/services/absence-state.service';
import { AbsenceState } from 'src/app/models/absence-state.model';
import { AbsenceStateCode } from 'src/app/models/absence-state-code-enum.model';
import { AccountService } from 'inzo-portalempleado';
import { EmployeeService } from 'src/app/services/employee.service';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { Employee } from 'src/app/models/employee.model';

@Component({
  selector: 'app-dashboard-employee',
  templateUrl: './dashboard-employee.component.html',
  styleUrls: ['./dashboard-employee.component.css', '../maintenance.components.css', '../maintenance-list.component.css']
})
export class DashboardEmployeeComponent implements OnInit {

  searching = true;
  filter = '';

  apiFilters: FiltersApi = new FiltersApi();

  pendingHolidayRequests: HolidayRequest[] = [];
  holidayRequestStates: HolidayRequestState[] = [];
  totalHolidaysEnjoyed
  totalHolidaysToEnjoyFromLastYear
  totalPendingHolidaysLastYearEnjoyed

  pendingAbsences: Absence[] = [];
  absenceStates: AbsenceState[] = [];
  currentEmployee: Employee;

  totalAbsencesRequested
  totalAbsencesRequestedLastYear

  userFullName = '';

  constructor(
    private accountService: AccountService,
    private employeeService: EmployeeService,
    private holidayRequestService: HolidayRequestService,
    private holidayRequestStateService: HolidayRequestStateService,
    private absenceService: AbsenceService,
    private absenceStateService: AbsenceStateService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private translate: TranslateService,
  ) {  }

  ngOnInit() {
    this.userFullName = this.accountService.currentUser.firstName + ' ' + this.accountService.currentUser.lastName

    const userId = this.accountService.currentUser.id;

    this.spinner.show();

    this.apiFilters.add(
      {
        field: "Related",
        value: "true"
      }
    );

    this.apiFilters.addInclude('HolidaysRequests')
    this.apiFilters.addInclude('Absences')

    this.employeeService.getMyEmployeeInfo(this.apiFilters.getStrinFilterApi()).subscribe(employeeInfo => {
      this.currentEmployee = employeeInfo;
    }, error => {
      this.translate.get('MESSAGES.ERROR.CRITICAL').subscribe((lang: string) => {
        this.toastrService.error(error.error, lang, { timeOut: 3000 });
      });
    }, () => {
      this.spinner.hide();
    });

    // forkJoin([
    //   this.holidayRequestStateService.getAllHolidaysRequestState(),
    //   this.holidayRequestService.getAllHolidayRequest(this.apiFilters.getStrinFilterApi()),
    //   this.absenceStateService.getAllAbsenceState(),
    //   this.absenceService.getAllAbsence(this.apiFilters.getStrinFilterApi())
    // ]).subscribe(([holidayRequestStates, holidayRequests, absenceStates, absences]) => {

    //   this.holidayRequestStates = holidayRequestStates.items;

    //   const stateCodeOfPendingHolidayRequest = 1;

    //   const pendingHolidayRequestState = holidayRequestStates.items.filter(holidayRequestState => {
    //     return holidayRequestState.stateCode === stateCodeOfPendingHolidayRequest
    //   })[0];

    //   // Si se ha encontrado un estado pendiente, hacemos el filtrado por solicitudes de vacaciones pendientes.
    //   if (pendingHolidayRequestState && holidayRequests) {
    //     this.pendingHolidayRequests = holidayRequests.items.filter(holidayRequest => {
    //       return holidayRequest.holidaysRequestStateId === pendingHolidayRequestState.holidaysRequestStateId;
    //     });
    //   }


    //   this.absenceStates = absenceStates.items;

    //   const stateCodeOfPendingAbsence = 1;

    //   const pendingAbsenceState = absenceStates.items.filter(absenceState => {
    //     return absenceState.stateCode === stateCodeOfPendingAbsence
    //   })[0];

    //   // Si se ha encontrado un estado pendiente, hacemos el filtrado por ausencias pendientes.
    //   if (pendingAbsenceState && absences) {
    //     this.pendingAbsences = absences.items.filter(absence => {
    //       return absence.absenceStateId === pendingAbsenceState.absenceStateId;
    //     });
    //   }
    // }, error => {
    //   this.translate.get('MESSAGES.ERROR.CRITICAL').subscribe((lang: string) => {
    //     this.toastrService.error(error.error, lang, { timeOut: 3000 });
    //   });
    // }, () => {
    //   this.spinner.hide();
    // });
  }

  /**
   * Pasa la solicitud de vacaciones recibida como param a estado 'CANCELED'.
   *
   * @param holidayRequest - La solicitud de vacaciones a cancelar
   */
  cancelHolidayRequest(holidayRequest: HolidayRequest) {
    const holidayRequestNewState = this.holidayRequestStates.find(element => element.stateCode == HolidayRequestStateCode['CANCELED']);
    const reasonCancel = '';

    this.spinner.show();

    this.holidayRequestService.changeStateHolidayRequest(holidayRequest.holidaysRequestId, holidayRequestNewState.holidaysRequestStateId, reasonCancel).subscribe(() => {
      const indexToSplice = this.pendingHolidayRequests.findIndex(pendingHolidayRequest => {
        return pendingHolidayRequest.holidaysRequestId == holidayRequest.holidaysRequestId;
      });

      if (indexToSplice !== null) {
        this.pendingHolidayRequests.splice(indexToSplice, 1);
        /**
         * Splicing an element into the array does not change the Object reference hence not picked by change detection.
         * You need a way to trigger change detection.
         * Below should work
         */
        this.pendingHolidayRequests = this.pendingHolidayRequests.slice();
      }

    }, error => {
      this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((lang: string) => {
        this.toastrService.error(error.error, lang, { timeOut: 3000 });
      });
    }, () => {
      this.spinner.hide();
    });
  }


  /**
   * Pasa la solicitud de ausencias recibida como param y el nuevo estado.
   * @param absence - La solicitud de ausencias a actualizar
   * @param newState - Estado al que debe camibar la solicitud de ausencias
   */
  cancelAbsence(absence: Absence) {
    const absenceNewState = this.absenceStates.find(element => element.stateCode == AbsenceStateCode['CANCELED']);
    const reasonCancel = '';

    this.spinner.show();

    this.absenceService.changeStateAbsence(absence.absenceId, absenceNewState.absenceStateId, reasonCancel).subscribe(() => {
      const indexToSplice = this.pendingAbsences.findIndex(pendingAbsence => {
        return pendingAbsence.absenceId == absence.absenceId;
      });

      if (indexToSplice !== null) {
        this.pendingAbsences.splice(indexToSplice, 1);
        /**
         * Splicing an element into the array does not change the Object reference hence not picked by change detection.
         * You need a way to trigger change detection.
         * Below should work
         */
        this.pendingAbsences = this.pendingAbsences.slice();
      }

    }, error => {
      this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((lang: string) => {
        this.toastrService.error(error.error, lang, { timeOut: 3000 });
      });
    }, () => {
      this.spinner.hide();
    });
  }
}
