import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as jsonpatch from 'fast-json-patch';

import { AccountService, BaseFormComponent, Hotkeys } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';

import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { FiltersApi } from 'src/app/models/filters-api.model';

import { LaborAgreement } from 'src/app/models/labor-agreement.model';
import { LaborAgreementService } from 'src/app/services/labor-agreement.service';

@Component({
  selector: 'app-labor-agreement-form',
  templateUrl: './labor-agreement-form.component.html',
  styleUrls: [
    './labor-agreement-form.component.css',
    './../../maintenance.components.css',
  ],
})
export class LaborAgreementFormComponent extends BaseFormComponent<LaborAgreement> implements OnInit, OnDestroy {
  //#region ATRRIBUTES
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  selectedDesc: string = undefined;
  laborAgreement: LaborAgreement;

  permissionsLoaded = false;

  canAdd = false;
  subscription: Subscription;

  filterApi: FiltersApi = new FiltersApi();

  /* ****************************************************************************************************************** */
  /* ** INHERITED
  /* ****************************************************************************************************************** */
  enabled = true;
  detail = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  formGroupControl = new FormGroup({
    dateValidity            : new FormControl('', Validators.required),
    publicationDate         : new FormControl('', Validators.required),
    code                    : new FormControl('', Validators.required),
    description             : new FormControl('', Validators.required),
    tgss                    : new FormControl(''),
    regimeCode              : new FormControl('', Validators.required),
    regime                  : new FormControl('', Validators.required),
    sectorCode              : new FormControl('', Validators.required),
    sector                  : new FormControl('', Validators.required),
    variableIncreaseArrears : new FormControl('', Validators.required),
    integrationFactor       : new FormControl('', Validators.required),
    document                : new FormControl(''),
    observations            : new FormControl(''),
    currency                : new FormControl('', Validators.required),
    ordinance               : new FormControl('', Validators.required),
    isLocked                : new FormControl(''),
  });
  //#endregion
  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private toastrService: ToastrService,
    private laborAgreementService: LaborAgreementService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ) {
      this.canAdd = true;
      this.canDelete = true;
    }
    this.PK = 'laborAgreementId';

    this.baseRoute = '/maintenances/labor_agreements';

    this.filterApi.add(
      {
        field: "related",
        value: "false"
      }
    );

    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('CATEGORIES_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  //#endregion

  //#region DATA MANIPULATION
  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  private init() {
    this.disableForm();

    this.laborAgreement = new LaborAgreement();
    this.subscription = this.route.paramMap.subscribe(params => {
      const transId = params.get('id');
      this.laborAgreementService.updateLaborAgreementRoutes(transId);
      if (transId) {
        if (transId === 'new') {
          this.detail = false;
          this.enabled = true;
          this.laborAgreement = new LaborAgreement();
          this.selectedDesc = undefined;
          this.cleanForm();
          this.enableForm();
        } else {
          this.laborAgreementService.getLaborAgreement(transId, this.filterApi.getStrinFilterApi()).subscribe(response => {
            this.detail = true;
            this.enabled = false;
            this.laborAgreement = response;
            this.disableForm();
            this.loadDataOnForm();
            this.selectedDesc = response.description;
          });
        }
      } else {
        this.selectedDesc = undefined;
        this.detail = false;
        this.enabled = false;
        this.laborAgreement = new LaborAgreement();
      }
    });
  }
  //#endregion

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  cleanForm() {
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      currentControl.enable();
    });
  }

  disableForm() {
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      currentControl.disable();
    });
  }

  enableForm() {
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      currentControl.enable();
    });
  }

  loadDataOnForm() {
    this.formGroupControl.setValue({
      dateValidity            : this.laborAgreement.dateValidity,
      publicationDate         : this.laborAgreement.publicationDate,
      code                    : this.laborAgreement.code,
      description             : this.laborAgreement.description,
      tgss                    : this.laborAgreement.tgss,
      regimeCode              : this.laborAgreement.regimeCode,
      regime                  : this.laborAgreement.regime,
      sectorCode              : this.laborAgreement.sectorCode,
      sector                  : this.laborAgreement.sector,
      variableIncreaseArrears : this.laborAgreement.variableIncreaseArrears,
      integrationFactor       : this.laborAgreement.integrationFactor,
      document                : this.laborAgreement.document,
      observations            : this.laborAgreement.observations,
      currency                : this.laborAgreement.currency,
      ordinance               : this.laborAgreement.ordinance,
      isLocked                : this.laborAgreement.isLocked,
    });
  }

  edit() {
    this.enabled = true;

    this.enableForm();
  }

  cancel() {
    this.cleanForm();
    this.disableForm();

    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }
  //#endregion
  //#endregion

  //#region CRUD
  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  /* ****************************************************************************************************************** */
  /* ** CREATE
  /* ****************************************************************************************************************** */
  create() {
    this.assignLaborAgreement();

    this.laborAgreementService.createLaborAgreement(this.laborAgreement).subscribe(
      response => {
        this.laborAgreementService.getAllLaborAgreements().subscribe(laborAgreements => {
          this.laborAgreementService.updateLaborAgreements(laborAgreements.items);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.CREATE_LONG', {
            type: this.componentTypeTranslated,
            code: this.laborAgreement.description,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.cancel();
      },
      error => {
        if (error.status === 500 || error.status === 0) {
        } else if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
        }
      },
    );
  }

  /* ****************************************************************************************************************** */
  /* ** UPDATE
  /* ****************************************************************************************************************** */
  update() {
    const observer = jsonpatch.observe(this.laborAgreement);
    this.assignLaborAgreement();

    const patch = jsonpatch.generate(observer);

    this.laborAgreementService.modifyLaborAgreement(this.laborAgreement.laborAgreementId,patch).subscribe(
      response => {
        this.laborAgreementService.getAllLaborAgreements().subscribe(laborAgreements => {
          this.laborAgreementService.updateLaborAgreements(laborAgreements.items);
        });
        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );
  }

  /* ****************************************************************************************************************** */
  /* ** DELETE
  /* ****************************************************************************************************************** */
  delete() {
    let text = '';

    this.translate.get('LABOR_AGREEMENT_MAINTENANCE.MESSAGE.DELETE_CONFIRMATION.TEXT', { value: this.laborAgreement.description }).subscribe((transitionStr: string) => {
      text = transitionStr;
    });

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      data: {
        text
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.laborAgreementService.deleteLaborAgreement(this.laborAgreement.laborAgreementId).subscribe(response => {
          this.laborAgreementService.getAllLaborAgreements().subscribe(laborAgreements => {
            this.laborAgreementService.updateLaborAgreements(laborAgreements.items);
          });
          let toastTitle = '';
          let toastBody = '';
          this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {
            toastTitle = a;
          });
          this.translate.get('MESSAGES.SUCCESS.DELETE_LONG', {type: this.componentTypeTranslated, code: this.laborAgreement.description}).subscribe((a: string) => {
            toastBody = a;
          });
          this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
          this.cancel();
        });
      }
    });
  }
  //#endregion

  //#region ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  assignLaborAgreement() {
    if (this.laborAgreement == undefined || this.laborAgreement == null) {
      this.laborAgreement = new LaborAgreement();
    }

    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];
      const temp = currentControl.value;

      if (temp != null && temp != '') {
        this.laborAgreement[name] = temp;
      }
    });
  }
  //#endregion

  //#region OTHERS
  /* ****************************************************************************************************************** */
  /* ** OTHERS
  /* ****************************************************************************************************************** */
  back() {
    this.cancel();
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  //#endregion
}
