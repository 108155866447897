import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { EmployeeManagerHistory } from '../models/employee-manager-history.model';


@Injectable({ providedIn: 'root' })
export class EmployeeManagerHistoryService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private employeeManagerHistorySubject: ReplaySubject<EmployeeManagerHistory[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeManagerHistoryObservable: Observable<EmployeeManagerHistory[]> = this.employeeManagerHistorySubject.asObservable();

    private employeeManagerHistoryRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public employeeManagerHistoryRouteObservable: Observable<string> = this.employeeManagerHistoryRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/employeeManagerHistory';
    }

    constructor(private http: HttpClient) {
    }



    getAllEmployeeManagerHistory() {
        return this.http.get<any>(`${this.serviceUrl}`);
    }

    getAllEmployeeManagerHistorybyEmployeeId(employeeId) {
      return this.http.get<any>(`${this.serviceUrl+"/employee/"+employeeId}`);
    }

    getAllEmployeesManagers() {
      return this.http.get<any>(`${this.serviceUrl+"/allmanagers"}`);
    }

    createEmployeeManagerHistory(employeeHistory :EmployeeManagerHistory){
      console.log(employeeHistory);
      return this.http.post<EmployeeManagerHistory>(`${this.serviceUrl}`,employeeHistory);
    }

    deleteEmployeeManagerHistory(EmployeeManagerHistoryId: string) {
        const endpointUrl = `${this.serviceUrl}/${EmployeeManagerHistoryId}`;
        return this.http.delete<any>(endpointUrl);
    }

    // notifyChanges() {
    //     this.changeSubject.next();
    // }
}
