import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { Certification } from '../models/certification.model';


@Injectable({ providedIn: 'root' })
export class CertificationService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private certificationSubject: ReplaySubject<Certification[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public certificationObservable: Observable<Certification[]> = this.certificationSubject.asObservable();

    private certificationRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public certificationRouteObservable: Observable<string> = this.certificationRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/certification';
    }

    constructor(private http: HttpClient) {
    }

    addCertification(employeeId: string, certification: Certification) {
        const endpointUrl = `${this.serviceUrl}/Employee/${employeeId}`;
        return this.http.post<Certification>(endpointUrl, certification);
    }

    removeCertification(employeeId: string, certificationId: string) {
        const endpointUrl = `${this.serviceUrl}/${certificationId}/Employee/${employeeId}`;
        return this.http.delete(endpointUrl);
    }

    updateCertifications(certifications: Certification[]) {
        this.certificationSubject.next(certifications);
    }

    updateCertificationRoutes(id: string) {
        this.certificationRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}