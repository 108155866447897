import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CustomCommonImportsModule } from '../custom-common-imports/custom-common-imports.module';
import { AppComponent } from 'src/app/app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import localeEs from '@angular/common/locales/es';
import { HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from '../material/material.module';

import { CloseYearLayoutComponent } from './components/close-year-layout/close-year-layout.component';

// CloseYear
import { CloseYearHistoryComponent } from './components/closeyear/close-year-history/close-year-history.component';

// CloseYear - Dialogs
import { CloseYearHistoryDetailsDialogComponent } from './components/closeyear/close-year-history-details-dialog/close-year-history-details-dialog.component';

// WorkCalendar
import { CloseYearWorkCalendarHistoryComponent } from './components/workcalendar/close-year-wc-history/close-year-wc-history.component';
import { CloseYearWorkCalendarHistoryCreateComponent } from './components/workcalendar/close-year-wc-history-create/close-year-wc-history-create.component';

// WorkCalendar -Dialogs
import { CloseYearWorkCalendarHistoryCreateDialogComponent } from './components/workcalendar/close-year-wc-history-create-dialog/close-year-wc-history-create-dialog.component';
import { CloseYearWorkCalendarHistoryEditDialogComponent } from './components/workcalendar/close-year-wc-history-edit-dialog/close-year-wc-history-edit-dialog.component';

// Dialogs
import { CloseYearHistroyConfirmDialogComponent } from './components/close-year-histroy-confirm-dialog/close-year-histroy-confirm-dialog.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// ------------------------------------------------------------

registerLocaleData(localeEs, 'es');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    CloseYearLayoutComponent,

    // CloseYear
    CloseYearHistoryComponent,

    // CloseYear - Dialogs
    CloseYearHistoryDetailsDialogComponent,

    // WorkCalendar
    CloseYearWorkCalendarHistoryComponent,
    CloseYearWorkCalendarHistoryCreateComponent,

    // WorkCalendar - Dialogs
    CloseYearWorkCalendarHistoryCreateDialogComponent,
    CloseYearWorkCalendarHistoryEditDialogComponent,

    // Dialogs
    CloseYearHistroyConfirmDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    MaterialModule,
    CustomCommonImportsModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      timeOut: 5000,
      enableHtml: true
    }),
  ],
  exports: [
    CloseYearLayoutComponent,

    // CloseYear
    CloseYearHistoryComponent,

    // CloseYear - Dialogs
    CloseYearHistoryDetailsDialogComponent,

    // WorkCalendar
    CloseYearWorkCalendarHistoryComponent,
    CloseYearWorkCalendarHistoryCreateComponent,

    // WorkCalendar - Dialogs
    CloseYearWorkCalendarHistoryCreateDialogComponent,

    // Dialogs
    CloseYearHistroyConfirmDialogComponent,
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    // CloseYear - Dialogs
    CloseYearHistoryDetailsDialogComponent,

    // WorkCalendar - Dialogs
    CloseYearWorkCalendarHistoryCreateDialogComponent,
    CloseYearWorkCalendarHistoryEditDialogComponent,

    // Dialogs
    CloseYearHistroyConfirmDialogComponent,
  ],
})
export class CloseYearModule { }
