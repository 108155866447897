import { Component, OnInit, ElementRef, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { HolidayRequestState } from 'src/app/models/holiday-request-state.model';
import { HolidayRequestStateCode } from 'src/app/models/holiday-request-state-code-enum.model';
import { HolidayRequestManagementCalendarComponent } from '../holiday-request-management-calendar/holiday-request-management-calendar.component';
import { HolidayRequestEvent } from 'src/app/interfaces/events.interface';
import { HolidayRequestGroupsItems, HolidayRequestStateLabel } from 'src/app/interfaces/holidays.interface';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Role } from 'inzo-portalempleado';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { HolidayRequestChangesService } from 'src/app/services/holiday-request-changes.service';

// ################################################################################################################
// ## CLASS HolidayRequestManagementTabsComponent
// ################################################################################################################
@Component({
  selector: 'app-holiday-request-management-tabs',
  templateUrl: './holiday-request-management-tabs.component.html',
  styleUrls: [
    './holiday-request-management-tabs.component.css',
    '../../maintenance.components.css',
    '../../maintenance-list.component.css',
  ]
})
export class HolidayRequestManagementTabsComponent implements OnInit {
  //#region ARGUMENTS
  // ##############################################################################################################
  // ## Arguments
  // ##############################################################################################################
  @Input() holidayRequestsGroups: HolidayRequestGroupsItems[] = [];
  @Input() holidayRequestStates: HolidayRequestState[] = [];

  @Input() visibleStatesCalendar: HolidayRequestStateCode[] = [];

  @Input() employeeView : boolean = false;

 holidayRequestStatesTabs: HolidayRequestStateLabel[]= [];
 tabStates: string[] = [];
 defaultTabState: string = "PENDING";
 selectedTab: number = 0;

  searching = true;
  filter = '';

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  @Input() calendar: HolidayRequestManagementCalendarComponent;
  @Output() actionHoliday: EventEmitter<HolidayRequestEvent> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();

  subscriptions: Subscription[] = [];

  // Permissions
  canDirectAssignment: boolean = false;
  directAssignment : boolean = false;

  roles: Role[];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }
  //#endregion

  //#region CONSTRUCTOR
  // ##############################################################################################################
  // ## Constructor
  // ##############################################################################################################
  constructor(
    protected holidayRequestChangesService: HolidayRequestChangesService,
    private translate: TranslateService,
    protected permissionsService: PermissionsService,
  ) { }
  //#endregion

  //#region ANGULAR METHODS
  // ##############################################################################################################
  // ## Angular Methods
  // ##############################################################################################################
  ngOnInit() {
    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;

        if (this.userRolesPermissions && this.userRolesPermissions.isRRHH && !this.employeeView) {
          this.directAssignment = true;

          this.canDirectAssignment = true;
        }

        this.setRequestStates();

        if (this.employeeView === false && this.holidayRequestStatesTabs) {
          this.selectedTab = this.getDefaultTabIndex();

          this.loadTabData(this.selectedTab);
        }
      })
    );

    // roles
    this.subscriptions.push(
      this.permissionsService.roles.subscribe(data => {
        this.roles = data;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  //#region CUSTOM METHODS
  // ##############################################################################################################
  // ## Custom Methods
  // ##############################################################################################################
  //#endregion

  //#region UTILS
  // ##############################################################################################################
  // ## Utils
  // ##############################################################################################################
  refresh() {
    this.refreshData.emit(true);
  }

  /**
   * Recoge un evento del componente de listado de solicitudes y ejecuta la acción correspondiente a ese evento.
   *
   * @param holidayRequestEvent evento recogido
   */
  actionHolidayReply(holidayRequestEvent: HolidayRequestEvent) {
    this.actionHoliday.emit(holidayRequestEvent);
  }

  /**
   * Devuelve el nombre del estado con valor state
   *
   * @param stateCode código de estado
   */
  getStateName(stateCode) {
    return HolidayRequestStateCode[stateCode];
  }

  /**
   * Devuelve el de solicitudes que se debe mostrar en las pestañas
   *
   * @param stateCode código de estado
   */
  getTabCount(holidayRequestsGroup: any): string {
    let result = ``;
    let countGroups = holidayRequestsGroup.length;
    let countRequest = 0;

    for (let item in holidayRequestsGroup) {
      countRequest += holidayRequestsGroup[item].length;
    }

    if (this.employeeView) {
      result += `${countRequest}`;
    } else {
      // result += `${countGroups} - ${countRequest}`;
      result += `${countGroups}`;
    }

    return result;
  }

  loadTabData(index) {
    if (this.employeeView === false) {
      const currentTab = this.holidayRequestStatesTabs[index];

      this.holidayRequestChangesService.onChangeSelectedStateRequest(currentTab);
    }
  }

  getDefaultTabIndex(): number {
    let result = this.holidayRequestStatesTabs.findIndex(element => element.stateName == this.defaultTabState);

    return (result > -1)
      ? result
      : 0;
  }

  /**
   * Selecciona los estados que se deben manejar y los que se deben mostrar.
   */
  setRequestStates() {
    if (this.directAssignment) {
      this.tabStates = [
        // "REGISTERED",
        "PENDING",
        "APPROVED",
        "REJECTED",
        "ANNULLED",
        // "CANCELED",
        "ALL",
      ];
    } else {
      this.tabStates = [
        "PENDING",
        "APPROVED",
        "REJECTED",
        "ANNULLED",
        "ALL",
      ];
    }

    this.setTabNames();
  }

  setTabNames() {
    this.holidayRequestStatesTabs = [];

    for (let item of this.tabStates) {
      let textTab = "";

      this.translate.get(`HOLIDAY_REQUEST_STATES.${item}`).subscribe((a: string) => {
        textTab = a;
      });

      this.holidayRequestStatesTabs.push( {
        alias: textTab,
        stateName: item
      });
    }
  }
  //#endregion
}
