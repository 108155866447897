import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';

import { ContractDurationType } from '../models/contract-duration-type.model';

@Injectable({ providedIn: 'root' })
export class ContractDurationTypeService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private contractDurationTypeSubject: ReplaySubject<ContractDurationType[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public contractDurationTypeObservable: Observable<ContractDurationType[]> = this.contractDurationTypeSubject.asObservable();

  private contractDurationTypeRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public contractDurationTypeRouteObservable: Observable<string> = this.contractDurationTypeRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/contractDurationType';
  }

  constructor(private http: HttpClient) {
  }

  getAllContractDurationTypes(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getContractDurationType(contractDurationTypeId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/${contractDurationTypeId}${filter}`;

    return this.http.get<ContractDurationType>(url);
  }

  createContractDurationType(contractDurationType: ContractDurationType) {
    const url = `${this.serviceUrl}`;

    return this.http.post(url, contractDurationType);
  }

  deleteContractDurationType(contractDurationTypeId: string) {
    const url = `${this.serviceUrl}/${contractDurationTypeId}`;

    return this.http.delete(url);
  }

  modifyContractDurationType(contractDurationTypeId: string, contractDurationType: any) {
    const url = `${this.serviceUrl}/${contractDurationTypeId}`;

    return this.http.patch(url, contractDurationType);
  }

  updateContractDurationTypes(contractDurationTypes: any) {
    this.contractDurationTypeSubject.next(contractDurationTypes);
  }

  updateContractDurationTypeRoutes(id: string) {
    this.contractDurationTypeRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
