import { FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export function NSSFormValidator(): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    let isValid = false;//do it required
    const NSS = form.controls["nss"].value;

    if (NSS) {
      if (NSS.length == 12) {
        isValid = fn_ValidateNSS(NSS);
      }
    } else {
      isValid = true;
    }

    (isValid)
      ? form.controls["nss"].setErrors(null)
      : form.controls["nss"].setErrors({ invalidNSS: true });

    return null;
  }
}

function fn_ValidateNSS(NSS: string) {
  var firstTwoChar: string, lastTwoChar: string, charOnMiddle: string, calculated: number;
  var indexOfSlash = NSS.indexOf('/');

  //se coge la segunda parte si se inserta el /
  if (indexOfSlash != -1) {
      NSS = NSS.split('/')[1];
  }

  NSS = NSS.trim();
  NSS = NSS.replace(/\s/g, ""); //Y se quita los espacios en blanco dentro de la cadena

  if (new RegExp('^[0-9]+$').test(NSS) === false || NSS.length < 5) { //regex with letter return false
      return false;
  }

  firstTwoChar = NSS.substring(0, 2);
  lastTwoChar = NSS.substring(NSS.length - 2, NSS.length);
  charOnMiddle = NSS.substring(2, NSS.length - 2);

  if (parseInt(charOnMiddle) < 10000000) {
      calculated = parseInt(charOnMiddle) + parseInt(firstTwoChar) * 10000000; //valor de medio + valor de primeros dos * 10000000
  } else {
      calculated = parseInt(firstTwoChar.concat(charOnMiddle)); //Valor de las cadenas concatenadas primeros dos digitos y las de medio.
  }

  var validation = (calculated % 97);

  if (parseInt(lastTwoChar) == (validation)) {
      return true;
  } else {
      return false;
  }
}
