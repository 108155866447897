import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { Department } from '../models/department.model';


@Injectable({ providedIn: 'root' })
export class DepartmentService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private departmentSubject: ReplaySubject<Department[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public departmentObservable: Observable<Department[]> = this.departmentSubject.asObservable();

    private departmentRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public departmentRouteObservable: Observable<string> = this.departmentRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/departments';
    }

    constructor(private http: HttpClient) {
    }

    getAllDepartments(filter?: string) {
        return this.http.get<any>(`${this.serviceUrl}${(filter!=undefined && filter !=null) ? filter : ""}`);
    }

    getDepartment(departmentId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<Department>(`${this.serviceUrl}/${departmentId}${relatedFilter}`);
    }

    createDepartment(department: Department) {
        const endpointUrl = `${this.serviceUrl}`;
        return this.http.post(endpointUrl, department);
    }
    modifyDepartment(departmentId:string, department: any) {
        const endpointUrl = `${this.serviceUrl}/${departmentId}`;
        return this.http.patch(endpointUrl, department);
    }
    deleteDepartment(departmentId: string) {
        const endpointUrl = `${this.serviceUrl}/${departmentId}`;
        return this.http.delete(endpointUrl);
    }
    updateDepartments(departments: Department[]) {
        this.departmentSubject.next(departments);
    }

    updateDepartmentRoutes(id: string) {
        this.departmentRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}
