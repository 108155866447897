// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import { Component, ViewChild, Input, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatSort } from '@angular/material';
import { DCRHistory } from 'src/app/models/dcr-history.model';
import { transformDate } from 'src/app/helpers/date.helper';
import { Employee } from 'src/app/models/employee.model';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { Subscription } from 'rxjs';
import { DataHistoryService } from '../../../services/data-history.service';

// ################################################################################################################
// ## CLASS DCRHistoryListComponent
// ################################################################################################################
@Component({
  selector: 'app-dcr-history-list',
  templateUrl: './dcr-history-list.component.html',
  styleUrls: [
    './dcr-history-list.component.css',
    '../../../../../../assets/styles/action.components.css',
    '../../../../../../assets/styles/action-list.component.css',
  ],
})
export class DCRHistoryListComponent implements OnInit, OnDestroy {
  /* ############################################################################################################## */
  /* ## ATRRIBUTES
  /* ############################################################################################################## */
  @Input() employeeView = false;
  employee: Employee;
  employees: Employee[] = [];
  dcrHistory: DCRHistory[] = [];

  subscriptions: Subscription[] = [];

  @Input() displayedColumns: string[] = [
    'createdDate',
    'managerId',
    'approveDate',
    'reason',
    'log',
    'oldValue',
    'newValue'
  ];

  ELEMENT_DATA: DCRHistory[] = [];
  dataSource: MatTableDataSource<DCRHistory>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  // --------------------------------------------------------------------------------------------------------------
  // Permisos
  // --------------------------------------------------------------------------------------------------------------
  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }
  // --------------------------------------------------------------------------------------------------------------

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private dataHistoryService: DataHistoryService,
  ) {
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // EmployeeView
    this.subscriptions.push(
      this.dataHistoryService.employeeView.subscribe(data => {
        this.employeeView = data;
      })
    );

    // Employee
    this.subscriptions.push(
      this.dataHistoryService.employee.subscribe(data => {
        this.employee = data;
      })
    );

    // Employees
    this.subscriptions.push(
      this.dataHistoryService.employees.subscribe(data => {
        this.employees = data;
      })
    );

    // dcr
    this.subscriptions.push(
      this.dataHistoryService.dcr.subscribe(data => {
        this.dcrHistory = data;

        this.loadDataOnTable();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.employee && this.employee.employeeId) {
      this.loadDataOnTable();
    }
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  loadDataOnTable() {
    if (this.dcrHistory) {
      this.ELEMENT_DATA = this.dcrHistory;

      this.dataSource = new MatTableDataSource<DCRHistory>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  getManager(employeeId: string) {
    let result = '';

    if (this.employees) {
      let employee = this.employees.find(element => element.employeeId == employeeId);
      result = (employee != null)
        ? `${employee.secondSurname} ${employee.firstSurname}, ${employee.name}`
        : "";
    }

    return result;
  }
  //#endregion
}
