import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { CV } from '../models/cv.model';


@Injectable({ providedIn: 'root' })
export class CVService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private cvSubject: ReplaySubject<CV[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public cvObservable: Observable<CV[]> = this.cvSubject.asObservable();

  private cvRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public cvRouteObservable: Observable<string> = this.cvRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/cv';
  }

  constructor(private http: HttpClient) {
  }

  getAllCVs(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    let url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getAllCVsByEmployee(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    let url = `${this.serviceUrl}/Employee${filter}`;

    return this.http.get<any>(url);
  }

  getCV(cvId: string, related?: boolean) {
    let relatedFilter = "";
    if (related != undefined && related != null) {
      relatedFilter += "?related=" + related;
    }
    return this.http.get<CV>(`${this.serviceUrl}/${cvId}${relatedFilter}`);
  }

  createCV(newCVData: CV) {
    const endpointUrl = `${this.serviceUrl}`;
    return this.http.post(endpointUrl, newCVData);
  }
  modifyCV(cvId: string, newCVData: any) {
    const endpointUrl = `${this.serviceUrl}/${cvId}`;
    return this.http.patch(endpointUrl, newCVData);
  }
  deleteCV(cvId: string) {
    const endpointUrl = `${this.serviceUrl}/${cvId}`;
    return this.http.delete(endpointUrl);
  }
  reassociateCV(cvId: string, employeeIdSource: string, employeeIdDestination: string) {
    const endpointUrl = `${this.serviceUrl}/${cvId}/Source/${employeeIdSource}/Destination/${employeeIdDestination}`;
    return this.http.put(endpointUrl, null);
  }

  updateCvs(cvs: CV[]) {
    this.cvSubject.next(cvs);
  }

  updateCvRoutes(id: string) {
    this.cvRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
