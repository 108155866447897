import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { ResponseNotificationService } from "inzo-portalempleado";
import { ToastrService } from "ngx-toastr";
import { AbsenceDocument } from "src/app/models/absence-document.model";
import { StorageFile } from "src/app/models/storage-file.model";
import { FiltersApi } from "src/app/models/filters-api.model";
import { AbsenceService } from "src/app/services/absence.service";
import { FileService } from "src/app/services/file.service";
import { Absence } from "../../models/absence.model";
import { AbsenceDocumentCreateDialogComponent } from "./absence-document-create-dialog/absence-document-create-dialog.component";
import { IUserRolesPermissions } from "src/app/modules/pe-common/interfaces/permissions.interface";
import { Subscription } from "rxjs";
import { PermissionsService } from "src/app/modules/pe-common/services/permissions.service";
import { result } from "lodash";

@Component({
  selector: "app-absence-document-dialog",
  templateUrl: "./absence-document-dialog.component.html",
  styleUrls: ["./absence-document-dialog.component.css"],
})
export class AbsenceDocumentDialogComponent implements OnInit {
  employeeView = false;

  absence: Absence = null;
  absenceService: AbsenceService = null;
  RNService: ResponseNotificationService = null;

  translate: TranslateService = null;
  toastrService: ToastrService = null;

  filtersApi: FiltersApi = new FiltersApi();

  formGroupControl = new FormGroup({
    document: new FormControl(""),
  });

  fileToUpload: File = null;

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  subscriptions: Subscription[] = [];

  constructor(
    protected permissionsService: PermissionsService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<Absence>,
    protected fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // employeeView
    this.subscriptions.push(
      this.permissionsService.employeeView.subscribe(data => {
        this.employeeView = data;
      })
    );

    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;
      })
    );

    this.absenceService       = this.data.absenceService;
    this.translate            = this.data.translate;
    this.toastrService        = this.data.toastrService;
    this.RNService            = this.data.RNService;

    this.absence              = this.data.absence;
    this.filtersApi           = this.data.filtersApi;

    this.formGroupControl.setValue({ document: "" });
    this.formGroupControl.get("document").enable();
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  cleanForm() {
    this.formGroupControl.setValue({ document: "" });
  }

  showAddDeleteButtons(){
    let result = false
    if( (this.absence.isMedicalLeave && !this.employeeView) || !this.absence.isMedicalLeave ){
      result = true;
    } 
    return result;
  }

  addDocument() {
    let absenceDocument = new AbsenceDocument();
    const formData = new FormData();
    //Construimos formdata
    formData.append("file", this.fileToUpload, this.fileToUpload.name);
    formData.append("name", this.fileToUpload.name);
    formData.append("path", "absences");

    absenceDocument.file = this.fileToUpload;
    absenceDocument.description = this.fileToUpload.name;

    this.absenceService
      .addAbsenceDocument(this.absence.absenceId, formData)
      .subscribe(
        (response) => {
          this.absence.absenceDocuments = response.absenceDocuments;
        },
        (error) => {
          let title: string;
          this.translate
            .get("MESSAGES.ERROR.UPDATE_CONFLICT")
            .subscribe((a: string) => {
              title = a;
              this.toastrService.error(error.error, title, { timeOut: 3000 });
            });
        }
      );
  }

  removeDocument(document: AbsenceDocument) {
    let text: string;
    this.translate
      .get("ABSENCE_DOCUMENTS.DIALOG_CONFIRMATION.TEXT_REMOVE", {
        value: document.description,
      })
      .subscribe((a: string) => {
        text = a;
      });

    const confirmationDialog = this.dialog.open(
      AbsenceDocumentCreateDialogComponent,
      {
        width: "550px",
        data: { text },
      }
    );

    confirmationDialog.afterClosed().subscribe(async (confirm) => {
      if (confirm) {
        this.absenceService
          .removeAbsenceDocument(
            this.absence.absenceId,
            document.employeeDocumentId
          )
          .subscribe(
            (response) => {
              this.absence.absenceDocuments = response.absenceDocuments;
            },
            (error) => {
              let title: string;
              this.translate
                .get("MESSAGES.ERROR.UPDATE_CONFLICT")
                .subscribe((a: string) => {
                  title = a;
                  this.toastrService.error(error.error, title, {
                    timeOut: 3000,
                  });
                });
            }
          );
      }
    });
  }

  downloadDocument(document: AbsenceDocument) {
    let isManagement: boolean = false;

    if (
      !this.employeeView &&
      this.userRolesPermissions &&
      (
        this.userRolesPermissions.isAdmin ||
        this.userRolesPermissions.isRRHH ||
        this.userRolesPermissions.isRA ||
        this.userRolesPermissions.isRP ||
        this.userRolesPermissions.isAbsenceValidator
      )
    ) {
      isManagement = true;
    }

    this.fileService.getFile(document.fileId, isManagement).subscribe(response => {
      this.fileService.downloadBase64File(response);
    });
  }

  openDocument(document: AbsenceDocument) {
    let isManagement: boolean = false;

    if (
      !this.employeeView &&
      this.userRolesPermissions &&
      (
        this.userRolesPermissions.isAdmin ||
        this.userRolesPermissions.isRRHH  ||
        this.userRolesPermissions.isRA ||
        this.userRolesPermissions.isRP ||
        this.userRolesPermissions.isAbsenceValidator
      )
    ) {
      isManagement = true;
    }

    this.fileService.getFile(document.fileId, isManagement).subscribe(response => {
      this.fileService.openFile(response);
    });
  }

  cancel() {
    this.cleanForm();
  }




  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);

    let text: string;
    this.translate
      .get("ABSENCE_DOCUMENTS.DIALOG_CONFIRMATION.TEXT_ADD", {
        value: this.fileToUpload.name,
      })
      .subscribe((a: string) => {
        text = a;
      });

    const confirmationDialog = this.dialog.open(
      AbsenceDocumentCreateDialogComponent,
      {
        width: "550px",
        data: { text },
      }
    );

    confirmationDialog.afterClosed().subscribe(async (confirm) => {
      if (confirm) {
        this.addDocument();
      }
    });
  }

  getOwnerName() {
    let result = '';

    if (this.absence.employee) {
      result = `${this.absence.employee.firstSurname} ${this.absence.employee.secondSurname}, ${this.absence.employee.name}`;
    }

    return result;
  }
}
