import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { HRAHistory } from '../models/hra-history.model';


@Injectable({ providedIn: 'root' })
export class HRAHistoryService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private hraHistoryRequestSubject: ReplaySubject<HRAHistory[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public hraHistoryRequestObservable: Observable<HRAHistory[]> = this.hraHistoryRequestSubject.asObservable();

    private hraHistoryRequestRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public hraHistoryRequestRouteObservable: Observable<string> = this.hraHistoryRequestRouteSubject.asObservable();

    get serviceUrl() {
        return `${Config.apiBaseUrl}/HRAHistory`;
    }

    constructor(private http: HttpClient) {
    }

    getAllHRAHistory(filters?: string) {
        let filter = filters ? "&" + filters : "";

        let url = `${this.serviceUrl}${filter}`;

        return this.http.get<any>(url);
      }

    getAllHRAHistoryByEmployee(employeeId : string, filters? : string) {

        let url = `${this.serviceUrl}?employeeid=${employeeId}`;

        if (filters != undefined && filters != null) {
            url += `&${filters}`;
        }
        return this.http.get<any>(url);
    }

    getHRAHistoryFilteredByDate(employeeId : string, startDate: string, finishDate: string, filters? : string, ) {
      let url = `${this.serviceUrl}?employeeid=${employeeId}&startdate=${startDate}&finishdate=${finishDate}`;

      if (filters != undefined && filters != null) {
          url += `&${filters}`;
      }
      return this.http.get<any>(url);
    }
}
