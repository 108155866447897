import { Company } from './company.model';
import { Department } from './department.model';

export class Area {
  areaId: string;
  code: string;
  description: string;

  //Relations
  departments: Department[];
  companyId: string;
  company: Company;

  constructor(obj?: Partial<Area>) {
    this.areaId = (obj && obj.areaId) || undefined;
    this.code = (obj && obj.code) || undefined;
    this.description = (obj && obj.description) || undefined;

    //Relations
    this.departments = (obj && obj.departments) || [];
    this.companyId = (obj && obj.companyId) || undefined;
    this.company = (obj && obj.company) || undefined;
  }
}
