import { EmployeeLanguage } from "./employee-language.model";

export class Language {
  languageId: string;
  code: string;
  description: string;
  categoryCode: string;
  categoryDescription: string;

  //Relations
  employeeLanguages: EmployeeLanguage[];

  constructor(obj?: Partial<Language>) {
    this.languageId = (obj && obj.languageId) || undefined;
    this.code = (obj && obj.code) || undefined;
    this.description = (obj && obj.description) || undefined;
    this.categoryCode = (obj && obj.categoryCode) || undefined;
    this.categoryDescription = (obj && obj.categoryDescription) || undefined;

    //Relations
    this.employeeLanguages = (obj && obj.employeeLanguages) || [];
  }
}
