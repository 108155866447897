import { Employee } from "./employee.model";
import { WorkCalendar } from "./work-calendar.model";

export class Centre {
    centreId: string;
    name: string;
    ccc: string;

    //Relations
    employees: Employee[];

    workCalendarId: string;
    workCalendar: WorkCalendar;

    constructor(obj?: Partial<Centre>) {
      this.centreId = (obj && obj.centreId) || undefined;
      this.name = (obj && obj.name) || undefined;
      this.ccc = (obj && obj.ccc) || undefined;

      //Relations
      this.employees = (obj && obj.employees) || [];
      this.workCalendarId = (obj && obj.workCalendarId) || undefined;
      this.workCalendar = (obj && obj.workCalendar) || undefined;
    }
  }
