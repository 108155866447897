import { Component, OnInit } from '@angular/core';

import { LaborAgreementService } from 'src/app/services/labor-agreement.service';

@Component({
  selector: 'app-labor-agreement',
  templateUrl: './labor-agreement.component.html',
  styleUrls: [
    './labor-agreement.component.css',
    './../../../components/maintenance.components.css',
  ],
})
export class LaborAgreementComponent implements OnInit {
  showForm = false;

  constructor(private laborAgreementService: LaborAgreementService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.laborAgreementService.laborAgreementRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
}
