
export class PushNotification {
    userId?: string;
    endPoint?: string;
    p256dh?: string;
    auth?: string;
    constructor(
      userId?: string,
      endPoint?: string,
      p256dh?: string,
      auth?: string) {
      this.userId = userId || undefined;
      this.endPoint = endPoint || undefined;
      this.p256dh = p256dh || undefined;
      this.auth = auth || undefined;
    }
  }