import { Centre } from "./centre.model";
import { CloseYearHistory } from "./close-year-history.model";
import { Employee } from "./employee.model";
import { WCEvent } from "./wc-event.model";
import { WorkCalendarHistory } from "./work-calendar-history.model";

export class WorkCalendar {
  workCalendarId      : string;
  name                : string;
  description         : string;
  validYear           : string;
  isActive            : boolean;
  disableWeekends     : boolean;
  holidayWeekend      : number;

  //Relations
  employees           : Employee[];
  centres             : Centre[];
  wcEvents            : WCEvent[];
  workCalendarHistory : WorkCalendarHistory[];
  closeYearHistory    : CloseYearHistory;

  constructor(obj?: Partial<WorkCalendar>) {
    this.workCalendarId       = (obj && obj.workCalendarId) || undefined;
    this.name                 = (obj && obj.name) || undefined;
    this.description          = (obj && obj.description) || undefined;
    this.validYear            = (obj && obj.validYear) || undefined;
    this.isActive             = (obj && obj.isActive) || undefined;
    this.disableWeekends      = (obj && obj.disableWeekends) || undefined;
    this.holidayWeekend       = (obj && obj.holidayWeekend) || undefined;

    //Relations
    this.employees            = (obj && obj.employees) || [];
    this.centres              = (obj && obj.centres) || [];
    this.wcEvents             = (obj && obj.wcEvents) || [];
    this.workCalendarHistory  = (obj && obj.workCalendarHistory) || [];
    this.closeYearHistory     = (obj && obj.closeYearHistory) || undefined;
  }
}
