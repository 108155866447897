// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import { Component, ElementRef, ViewChild, Input, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, BaseListComponent, Hotkeys, SecurityStateManagementService } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { Employee } from 'src/app/models/employee.model';
import { MatTableDataSource } from '@angular/material/table';
import { HRAHistoryService } from 'src/app/services/hra-history.service';
import { HRAHistory } from 'src/app/models/hra-history.model';
import { MatPaginator, MatSort } from '@angular/material';
import { transformDate } from 'src/app/helpers/date.helper';
import { HRADateFilterService } from 'src/app/services/hra-date-filter.service';
import moment = require('moment');
import { firstDefined } from '@fullcalendar/core/util/misc';
import { DataChangesService } from '../../../services/data-changes.service';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { Subscription } from 'rxjs';
import { DataHistoryService } from '../../../services/data-history.service';

// ################################################################################################################
// ## CLASS HolidaysHistoryListComponent
// ################################################################################################################
@Component({
  selector: 'app-holidays-history-list',
  templateUrl: './holidays-history-list.component.html',
  styleUrls: [
    './holidays-history-list.component.css',
    '../../../../../../assets/styles/action.components.css',
    '../../../../../../assets/styles/action-list.component.css',
  ],
})
export class HolidaysHistoryListComponent implements OnInit, OnDestroy {
  /* ############################################################################################################## */
  /* ## ATRRIBUTES
  /* ############################################################################################################## */
  @Input() employeeView = false;
  employee: Employee;
  employees: Employee[] = [];
  holidayHistory: HRAHistory[] = [];

  @Input() displayedColumns: string[] = [
    'createdDate',
    'log',
    'managerId',
    'approveDate',
    'reason',
    'startDate',
    'finishDate',
    'oldStatus',
    'newStatus'
  ];

  startDateFilter: string;
  finishDateFilter: string;

  ELEMENT_DATA: HRAHistory[] = [];
  dataSource: MatTableDataSource<HRAHistory>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  subscriptions: Subscription[] = [];

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private dataHistoryService: DataHistoryService,
    private hraDateFilterService: HRADateFilterService,
  ) {
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // EmployeeView
    this.subscriptions.push(
      this.dataHistoryService.employeeView.subscribe(data => {
        this.employeeView = data;
      })
    );

    // Employee
    this.subscriptions.push(
      this.dataHistoryService.employee.subscribe(data => {
        this.employee = data;
      })
    );

    // Employees
    this.subscriptions.push(
      this.dataHistoryService.employees.subscribe(data => {
        this.employees = data;
      })
    );

    // Holidays
    this.subscriptions.push(
      this.dataHistoryService.holidays.subscribe(data => {
        this.holidayHistory = data;

        this.loadDataOnTable();
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  /* ################################################################################################################## */
  /* ## DATA METHODS
  /* ################################################################################################################## */
  loadDataOnTable() {
    let filters: string = "holidays";

    if (this.holidayHistory) {
      this.ELEMENT_DATA = this.holidayHistory;

      this.dataSource = new MatTableDataSource<HRAHistory>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  getManager(employeeId: string) {
    let result = '';

    if (this.employees) {
      let employee = this.employees.find(element => element.employeeId == employeeId);
      result = (employee != null)
        ? `${employee.secondSurname} ${employee.firstSurname}, ${employee.name}`
        : "";
    }

    return result;
  }
}
