// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, Hotkeys } from 'inzo-portalempleado';
import { NgxSpinnerService } from 'ngx-spinner';
import { IFormEdit, IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { CommonResources } from 'src/app/models/common-resources';
import { Employee } from 'src/app/models/employee.model';
import { DataChangesService } from '../../../services/data-changes.service';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';

// ################################################################################################################
// ## CLASS MgntFormFormationsComponent
// ################################################################################################################
@Component({
  selector: 'app-mgnt-form-formations',
  templateUrl: './mgnt-form-formations.component.html',
  styleUrls: ['./mgnt-form-formations.component.css']
})
export class MgntFormFormationsComponent implements OnInit {
  @Input() employeeView: boolean = true;
  employee: Employee;


  disabilityPercentages = CommonResources.DISABILITY_PERCENTAGES;
  identiDocumTypes = CommonResources.IDENTIFICATION_DOCUMENT_TYPES;
  genders = CommonResources.GENDERS;

  backForm = true;

  // --------------------------------------------------------------------------------------------------------------
  // Permisos
  // --------------------------------------------------------------------------------------------------------------
  // allUsers: User[];
  // roles: Role[];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin     : false,
    isRRHH      : false,
    isRA        : false,
    isRP        : false,
    isEmployee  : false,
  }
  // --------------------------------------------------------------------------------------------------------------

  loadEmployeeData;
  disabilityChecked;
  enabled = true;
  detail = false;
  deleted = false;
  permissionsLoaded = false;
  visible = true;
  userFilter = '';
  selectable = true;
  removable = true;
  createMode: boolean = false;
  editable: boolean = false;

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  RAEdit: IFormEdit = {
    FormationsFormGroup  : [],
  }

  RPEdit: IFormEdit = {
    FormationsFormGroup  : [],
  }

  // ------------------------------------------------------------------------------------------------------------------
  // FormationsFormGroup
  // ------------------------------------------------------------------------------------------------------------------
  FormationsFormGroup = new FormGroup({
    certificationCourse: new FormControl(''),
    course: new FormControl(''),
    language: new FormControl(''),
    languageCategory: new FormControl(''),
    degree: new FormControl(''),
    degreeCode: new FormControl(''),
  });

  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    protected permissionsService: PermissionsService,
    private dataChangesService: DataChangesService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
   this.disableForm();
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.dataChangesService.employee.subscribe(data => {
      this.employee = data;
      this.loadDataOnForm();
    });
    this.permissionsService.userRolesPermissions.subscribe(data => {
      this.userRolesPermissions = data;
    });
  }
  //#endregion

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  loadDataOnForm() {

    // ------------------------------------------------------------------------------------------------------------------
    // Filtros
    // ------------------------------------------------------------------------------------------------------------------
    this.addFilters();
  }

  cleanForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // FormationsFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.FormationsFormGroup.controls).forEach((name) => {
      const currentControl = this.FormationsFormGroup.controls[name];

      currentControl.setValue('');
    });

  }

  disableForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // FormationsFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.FormationsFormGroup.controls).forEach((name) => {
      const currentControl = this.FormationsFormGroup.controls[name];

      currentControl.disable();
    });
  }

  enableForm() {
    // ------------------------------------------------------------------------------------------------------------------
    // FormationsFormGroup
    // ------------------------------------------------------------------------------------------------------------------
    Object.keys(this.FormationsFormGroup.controls).forEach((name) => {
      const currentControl = this.FormationsFormGroup.controls[name];
      let continueEnable: boolean = false;

      if (this.userRolesPermissions.isRRHH) {
        continueEnable = true;
      } else if (this.userRolesPermissions.isRA && this.RAEdit.ProfileFormGroup.includes(name)) {
        continueEnable = true;
      } else if (this.userRolesPermissions.isRP && this.RPEdit.ProfileFormGroup.includes(name)) {
        continueEnable = true;
      }

      if (continueEnable) {
        if (name != 'disabilityPercentage') {
          currentControl.enable();
        } else if (this.FormationsFormGroup.get('disability').value == true) {
          currentControl.enable();
        }
      }
    });
  }

  addFilters() {
  }
  //#endregion
}
