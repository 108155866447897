import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { ContractTypeManagementInfo } from '../models/contract-type-management-info.model';

import { ContractType } from '../models/contract-type.model';

@Injectable({ providedIn: 'root' })
export class ContractTypeService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();
  private contractTypeSubject: ReplaySubject<ContractType[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public contractTypeObservable: Observable<ContractType[]> = this.contractTypeSubject.asObservable();

  private contractTypeRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public contractTypeRouteObservable: Observable<string> = this.contractTypeRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/contractType';
  }

  constructor(private http: HttpClient) {
  }

  getAllContractTypes(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getManagementContractTypes(contractTypeId?: string) {
    if (contractTypeId == undefined || contractTypeId == null) {
      contractTypeId = "";
    }

    const url = `${this.serviceUrl}/Management/${contractTypeId}`;

    return this.http.get<ContractTypeManagementInfo>(url);
  }

  getContractType(contractTypeId: string, filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    const url = `${this.serviceUrl}/${contractTypeId}${filter}`;

    return this.http.get<ContractType>(url);
  }

  createContractType(contractType: ContractType) {
    const url = `${this.serviceUrl}`;

    return this.http.post(url, contractType);
  }

  deleteContractType(contractTypeId: string) {
    const url = `${this.serviceUrl}/${contractTypeId}`;

    return this.http.delete(url);
  }

  modifyContractType(contractTypeId: string, contractType: any) {
    const url = `${this.serviceUrl}/${contractTypeId}`;

    return this.http.patch(url, contractType);
  }

  updateContractTypes(contractTypes: any) {
    this.contractTypeSubject.next(contractTypes);
  }

  updateContractTypeRoutes(id: string) {
    this.contractTypeRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
