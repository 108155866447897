import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CentreService } from 'src/app/services/centre.service';

@Component({
  selector: 'app-maintenance-centre',
  templateUrl: './centre.component.html',
  styleUrls: ['./../maintenance.components.css'],
})
export class CentreComponent implements AfterViewInit, OnInit {
  showForm = false;
  constructor( private centreService: CentreService) {
  }
  ngAfterViewInit() {
    this.centreService.centreRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
  ngOnInit() {
  }
}
