export class FiltersApiIds {
  _filters: string[] = [];

  constructor() { }

  get filters() {
    return this._filters;
  }

  /**
   * Añade un filtro nuevo.
   *
   * @param filter nuevo filtro
   * @returns boolean con el resultado de la operación
   */
  add(filterApi: string): boolean {
    let result = false;

    if (filterApi && !this._filters.includes(filterApi)) {
      this._filters.push(filterApi);
      result = true;
    }

    return result;
  }

  /**
   * Añade una colección de filtros nuevos.
   *
   * @param filters colección de filtros
   * @returns boolean con el resultado de la operación
   */
  addMultiple(filters: string[]): boolean {
    let result = false;

    for (let filter of filters) {
      result = this.add(filter)
    }

    return result;
  }

  /**
   * Elimina el filtro con el nombre especificado.
   *
   * @param filterName nombre del filtro a eliminar
   * @returns boolean con el resultado de la operación
   */
  delete(filterName: string): boolean {
    let result = false;
    const index = this._filters.indexOf(filterName);

    if (index > -1) {
      this._filters.splice(index, 1);
      result = true;
    }

    return result;
  }

  /**
   * Elimina todos los filtros de la lista.
   */
  reset(): void {
    this._filters = [];
  }
}
