import { Component, OnInit, Input, ElementRef, ViewChild, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Absence } from 'src/app/models/absence.model';
import { AbsenceListEvent } from '../../../interfaces/events.interface';
import { Employee } from 'src/app/models/employee.model';
import { AbsenceStateCode, AbsenceStateColor } from 'src/app/models/absence-state-code-enum.model';
import { WorkCalendarService } from 'src/app/services/work-calendar.service';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { AbsenceChangesService } from 'src/app/services/absence-changes.service';
import { getArrayDates, getLocaleNames, isValidDate, loadDatesFromArrayString, loadDatesFromArrayStringAnyFormat } from 'src/app/helpers/date.helper';
import { DynamicCssClass } from 'src/app/helpers/css.helper';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';

import { ResponseNotificationService } from 'inzo-portalempleado';
import { IInputData } from 'inzo-calendar-lib/lib/Interface/IInputData';
import { LocaleSettings } from 'inzo-calendar-lib/lib/Interface/LocaleSettings';
import { ICalendarDate } from 'inzo-calendar-lib/lib/Interface/ICalendarDate';
import { ITLeaveEditDialogComponent } from '../it-leave-edit-dialog/it-leave-edit-dialog.component';
import { Subscription } from 'rxjs';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { EmployeeSelectionService } from 'src/app/modules/employee/services/employee-selection.service';
import { AbsenceService } from '../../../services/absence.service';
import { FiltersApi } from '../../../models/filters-api.model';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-it-leave-management-calendar',
  templateUrl: './it-leave-management-calendar.component.html',
  styleUrls: [
    './it-leave-management-calendar.component.css',
    './../../maintenance.components.css',
    '../../maintenance-list.component.css'
  ],
})
export class ITLeaveManagementCalendarComponent implements OnInit {
  @Input() employeeView: boolean = false;
  @Input() loadOnInit: boolean = false;

  @Input() selectedEmployees: Employee[] = [];
  @Input() currentEmployee: Employee;
  @Input() absences: Absence[] = [];
  @Input() absenceStateCodes = AbsenceStateCode;

  @Input() visibleStatesCalendar: AbsenceStateCode[] = [];
  @Input() absenceStates: AbsenceStateCode[] = [];

  @Output() currentYear: EventEmitter<number> = new EventEmitter();
  @Output() absenceListEvent: EventEmitter<AbsenceListEvent> = new EventEmitter();

  @Output() absenceCreateEvent: EventEmitter<Absence> = new EventEmitter();

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;

  value: IInputData = {
    year: new Date().getFullYear(),
    dates: [],
    publicHolidayDays: [],
    disableWeekend: false,
    disablePublicHolidayDays: true,
  };

  locale: LocaleSettings = {
    dayNamesMin: [],
    monthNames: []
  };

  allAbsencesAlreadyDisplayed = true;
  allAbsenceChangesAlreadyDisplayed = true;

  subscriptions: Subscription[] = [];

  // Permissions
  canDirectAssignment: boolean = true;
  directAssignment: boolean = true;

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  constructor(
    private translate: TranslateService,
    private RNService: ResponseNotificationService,
    private workCalendarService: WorkCalendarService,
    protected absenceChangesService: AbsenceChangesService,
    protected spinner: NgxSpinnerService,
    private dialog: MatDialog,
    protected permissionsService: PermissionsService,
    protected employeeSelectionService: EmployeeSelectionService,
    protected absenceService: AbsenceService,
  ) {}

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = getLocaleNames(this.translate);
    });

    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;
      })
    );

    this.translate.get('CALENDAR.DAYS_OF_WEEK').subscribe((daysOfWeekLang) => {
      this.locale.dayNamesMin = daysOfWeekLang;
    });
    this.translate.get('CALENDAR.MONTHS').subscribe((monthsLang) => {
      this.locale.monthNames = monthsLang;
    });

    this.subscriptions.push(
      this.absenceChangesService.canDirectAssignment.subscribe(checked => {
        this.canDirectAssignment = checked;
      })
    );

    this.subscriptions.push(
      this.absenceChangesService.check.subscribe(checked => {
        this.directAssignment = checked;
      })
    );

    this.subscriptions.push(
      this.absenceChangesService.absences.subscribe((response) =>{
        this.absences = response;

        this.loadAbsences(this.absences);
      })
    );

    this.subscriptions.push(
      this.employeeSelectionService.currentEmployee.subscribe((response) => {
        this.currentEmployee = response;

        this.loadWorkCalendar();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadWorkCalendar();

    if (this.loadOnInit) {
      this.loadAbsences(this.absences);
    }
  }

  onCheckedDirectAssignment(event) {
    this.directAssignment = event.checked;
    this.absenceChangesService.onChangeCheck(this.directAssignment);
  }

  OnDestroy() {
    this.absenceChangesService.onChangeCheck(false);

    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /**
   * Cambia el año a mostrar
   * @param year
   */
  changeYear(year: number) {
    this.spinner.show();

    this.value.year += year;

    // Se actualiza el valor del año actual
    this.currentYear.emit(this.value.year);
  }

  /**
   * Carga las solicitudes de vacaciones en el calendario anual.
   * @param holidays - Array de solicitudes de vacaciones que se cargarán en el plugin de yearcalendar
   */
  loadAbsences(absences: Absence[]): void {
    // Cada vez que recarguemos la lista de solicitudes de vacaciones, reiniciamos el array de fechas del calendario
    this.value.dates = [];

    if (absences) {
      absences.forEach(absence => {
        if (this.visibleStatesCalendar.includes(absence.absenceState.stateCode)) {
          this.loadAbsence(absence, true);
        }
      });
    }
  }

  /**
   * Carga los datos de una solicitud en el calendario.
   * @param absence - La solicitud que se va a añadir o a actualizar.
   */
  loadAbsence(absence: Absence, update: boolean = false): ICalendarDate {
    // Se cambia el color del estado según el campo enum del modelo
    const color = this.getStateColor(absence.absenceState.stateCode);
    const isValidFinishDate = isValidDate(absence.finishDate);

    let printDates = (isValidFinishDate)
      ? loadDatesFromArrayStringAnyFormat(absence.requestedDates)
      : loadDatesFromArrayStringAnyFormat(absence.expectedFinishDates);

    let calendarItem : ICalendarDate ={
      id: absence.absenceId,
      tooltip: absence.employee.name + ' ' + absence.employee.firstSurname,
      days: printDates,
      // select: (range: ICalendarDate) => {this.editAbsence(range)},
      color: color,
      borderColor: AbsenceStateColor.NOFINISHDATE,
      highlightedDates: (isValidFinishDate)
        ? []
        : printDates,
    };

    let indexOfAbsenceAlreadyVisibleOnCalendar = null;

    this.value.dates.forEach((date, iDate) => {
      if (date.id == absence.absenceId) {
        indexOfAbsenceAlreadyVisibleOnCalendar = iDate;
      }
    });

    if (indexOfAbsenceAlreadyVisibleOnCalendar == null) {
      this.value.dates.push(calendarItem);
    } else if (update) {
      this.value.dates[indexOfAbsenceAlreadyVisibleOnCalendar] = calendarItem;
    }

    return calendarItem;
  }

  loadWorkCalendar() {
    if(this.currentEmployee) {
      if(this.currentEmployee.workCalendar) {
        this.loadDisabledDays(this.currentEmployee.workCalendar);
      } else {
        this.workCalendarService.getWorkCalendar(this.currentEmployee.workCalendarId).subscribe(workCalendar => {
          this.currentEmployee.workCalendar = workCalendar;
          this.loadDisabledDays(workCalendar);
        }, error => {
          this.RNService.showError(error);
        });
      }
    } else {
      this.value.disableWeekend = false;
      this.value.publicHolidayDays = [];
    }
  };

  loadDisabledDays(workCalendar: WorkCalendar): void {
    const publicHolidayDays = [];

    if (workCalendar.wcEvents) {
      workCalendar.wcEvents.forEach(event => {
        let startDate = moment(event.startDate);
        let endDate = moment(event.endDate);
        while (startDate.isSameOrBefore(endDate)) {
          publicHolidayDays.push({date: startDate.toDate()})
          startDate.add(1, 'days');
        }
      });
    }

    this.value.disableWeekend = workCalendar.disableWeekends;
    this.value.publicHolidayDays = publicHolidayDays;
  }

  /**
   * Muestra/oculta la solicitud de vacaciones en el calendario.
   * @param absence - La solicitud a mostrar/ocultar.
   */
  toggleAbsenceLoadOnCalendar(absence: Absence): void {
    this.spinner.show();

    let indexOfAbsenceAlreadyVisibleOnCalendar = null;
    this.value.dates.forEach((date, iDate) => {
      if (date.id == absence.absenceId) {
        indexOfAbsenceAlreadyVisibleOnCalendar = iDate;
      }
    });

    if (indexOfAbsenceAlreadyVisibleOnCalendar == null) {
      this.loadAbsence(absence, true);

      absence.visibleOnYearCalendar = true;
    } else {
      this.value.dates.splice(indexOfAbsenceAlreadyVisibleOnCalendar, 1);
      absence.visibleOnYearCalendar = false;
    }

    this.spinner.hide();
  }

  /**
   * Muestra/oculta todas las solicitudes de vacaciones.
   */
  toggleDisplayAllAbsences(): void {
    this.spinner.show();

    this.allAbsencesAlreadyDisplayed = true; // TODO: quitar, se ha puesto para forzar que siempre se oculten las solicitudes

    if (this.allAbsencesAlreadyDisplayed) {
      this.absences.forEach(absence => {
        absence.visibleOnYearCalendar = false;
      });
      this.value.dates = [];
      // this.allAbsencesAlreadyDisplayed = false; // TODO: quitar, se ha puesto para forzar que siempre se oculten las solicitudes
    } else {
      this.absences.forEach(absence => {
        absence.visibleOnYearCalendar = true;
      });
      this.loadAbsences(this.absences);
      this.allAbsencesAlreadyDisplayed = true;
    }

    this.spinner.hide();
  }

  /**
   * Recoge un evento del componente de creación de una solicitud y ejecuta la acción correspondiente a ese evento.
   *
   * @param Absence evento recogido
   */
  createAbsence(absence: Absence) {
    this.absenceCreateEvent.emit(absence);
  }

  getStateColor(absenceStateCode: number) {
    let color = "";

    if (this.visibleStatesCalendar.includes(absenceStateCode)) {
      let stateName = AbsenceStateCode[absenceStateCode];

      color = AbsenceStateColor[stateName];
    }

    return color;
  }

  /**
   * Crea la leyenda de colores del calendario
   */
  getLegend() {
    let result = "";

    for(let item of this.visibleStatesCalendar) {
      let stateName = AbsenceStateCode[item];
      let styleName = `dynamic-style-${stateName}`;
      let textLegend = "";

      this.translate.get(`ABSENCE_STATES.${stateName}`).subscribe((a: string) => {
        textLegend = a;
      });

      let customClass: DynamicCssClass = new DynamicCssClass(
        {
          name: styleName,
          styleRules: [
            {
              key: 'color',
              value: this.getStateColor(item)
            }
          ]
        }
      );

      customClass.appendToDocument(true);

      result += `<span class="${styleName}">${textLegend}</span>`;
    }

    return result;
  }

  loadAndEditAbsence(absence: Absence) {
    if (this.currentEmployee) {
      if (this.currentEmployee.workCalendar) {
        const range = this.loadAbsence(absence);

        this.editAbsence(range, absence);
      } else {
        this.workCalendarService.getWorkCalendar(this.currentEmployee.workCalendarId).subscribe(workCalendar => {
          this.currentEmployee.workCalendar = workCalendar;
          const range = this.loadAbsence(absence);

          this.editAbsence(range, absence);
        }, error => {
          this.RNService.showError(error);
        });
      }
    }
  }

  /**
   * Lanza un modal sobre el que poder editar los días de una solicitud de vacaciones aprobada o pendiente de aprobar.
   * En caso de que la solicitud esté pendiente de aprobar se actualizará directamente mientras que para el caso
   * de la edición de solicitudes aprobadas lo que se generará será una nueva solicitud pero será una solicitud de
   * cambio de días sobre la solicitud de vacaciones aprobada.
   * @param range
   * @param absence
   */
  editAbsence(range: ICalendarDate, absence: Absence = null) {
    if (this.userRolesPermissions.isRRHH) {
      const absenceToEdit = (absence)
        ? absence
        : this.absences.filter(absence => {
          return absence.absenceId == range.id;
        })[0];

      const editAbsenceDialog = this.dialog.open(ITLeaveEditDialogComponent, {
        width: '550px',
        data: {
          currentEmployee : this.currentEmployee,
          days            : range.days,
          absenceId       : range.id,
          absenceStates   : this.absenceStates,
          absence         : absenceToEdit,
        }
      });

      editAbsenceDialog.afterClosed().subscribe((absenceEdited) => {
        if (absenceEdited) {
          this.absenceChangesService.refreshData(true);
        }
      });
    }
  }
}
