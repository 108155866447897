import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { TrainingCenter } from '../models/training-center.model';


@Injectable({ providedIn: 'root' })
export class TrainingCenterService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private trainingCenterSubject: ReplaySubject<TrainingCenter[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public trainingCenterObservable: Observable<TrainingCenter[]> = this.trainingCenterSubject.asObservable();

    private trainingCenterRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public trainingCenterRouteObservable: Observable<string> = this.trainingCenterRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/TrainingCenter';
    }

    constructor(private http: HttpClient) {
    }

    getAllTrainingCenters() {
        return this.http.get<any>(`${this.serviceUrl}`);
    }

    getTrainingCenter(trainingCenterId: string, related?: boolean) {
        let relatedFilter="";
        if(related!=undefined && related != null){
            relatedFilter += "?related="+related;
        }
        return this.http.get<TrainingCenter>(`${this.serviceUrl}/${trainingCenterId}${relatedFilter}`);
    }

    createTrainingCenter(trainingCenter: TrainingCenter) {
        const endpointUrl = `${this.serviceUrl}`;
        return this.http.post(endpointUrl, trainingCenter);
    }
    modifyTrainingCenter(trainingCenterId: string, newTrainingCenter: any) {
        const endpointUrl = `${this.serviceUrl}/${trainingCenterId}`;
        return this.http.patch(endpointUrl, newTrainingCenter);
    }
    deleteTrainingCenter(trainingCenterId: string) {
        const endpointUrl = `${this.serviceUrl}/${trainingCenterId}`;
        return this.http.delete(endpointUrl);
    }


    updateTrainingCenters(trainingCenter: TrainingCenter[]) {
        this.trainingCenterSubject.next(trainingCenter);
    }

    updateTrainingCenterRoutes(id: string) {
        this.trainingCenterRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}
