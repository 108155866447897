import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as jsonpatch from 'fast-json-patch';

import { AccountService, BaseFormComponent, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';

import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';

import { PRCContract } from 'src/app/models/prc-contract.model';
import { PRCContractService } from 'src/app/services/prc-contract.service';
import { Company } from 'src/app/models/company.model';
import { EmployeeType } from 'src/app/models/employee-type.model';
import { map, startWith } from 'rxjs/operators';
import { customStartsWith } from 'src/app/helpers/filter-data.helper';
import { PRCContractsManagementInfo } from 'src/app/models/prc-contracts-management-info.model';
import { getDateString } from 'src/app/helpers/date.helper';

@Component({
  selector: 'app-prc-contract-form',
  templateUrl: './prc-contract-form.component.html',
  styleUrls: [
    './prc-contract-form.component.css',
    './../../maintenance.components.css',
  ],
})
export class PrcContractFormComponent extends BaseFormComponent<PRCContract> implements OnInit, OnDestroy {
  //#region ATRRIBUTES
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  selectedDesc: string = undefined;
  prcContract: PRCContract;
  prcContractsManagementInfo: PRCContractsManagementInfo;

  roles: Role[];

  canAdd = false;
  subscription: Subscription;

  filteredCompanies: Observable<Company[]>;
  filteredEmployeeTypes: Observable<EmployeeType[]>

  /* ****************************************************************************************************************** */
  /* ** INHERITED
  /* ****************************************************************************************************************** */
  enabled = true;
  detail = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;

  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  formGroupControl = new FormGroup({
    startDate: new FormControl('', Validators.required),
    value: new FormControl('', Validators.required),

    company: new FormControl('', Validators.required),

    employeeType: new FormControl('', Validators.required),
  });
  //#endregion
  //#endregion

  //#region CONSTRUCTOR
  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private toastrService: ToastrService,
    private prcContractService: PRCContractService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ) {
      this.canAdd = true;
      this.canDelete = true;
    }
    this.PK = 'prcContractId';

    this.baseRoute = '/maintenances/prc_contracts';

    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('CATEGORIES_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }
  //#endregion

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          this.onInit();
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  //#region DATA MANIPULATION
  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  private init() {
    this.disableForm();

    this.prcContract = new PRCContract();
    this.subscription = this.route.paramMap.subscribe(params => {
      const transId = params.get('id');
      const prcContractId = (transId && transId != 'new')
        ? transId
        : '';

      this.prcContractService.getManagementContractTypes(prcContractId).subscribe(response => {
        this.prcContractService.updatePRCContractRoutes(transId);
        this.prcContractsManagementInfo = response;
        this.addFilters()

        if (transId) {
          if (transId === 'new') {
            this.detail = false;
            this.enabled = true;
            this.prcContract = new PRCContract();
            this.selectedDesc = undefined;
            this.cleanForm();
            this.enableForm();
          } else {
            this.detail = true;
            this.enabled = false;
            this.prcContract = response.prcContract;
            this.disableForm();
            this.loadDataOnForm();
            this.selectedDesc = (response.prcContract)
              ? `${response.prcContract.employeeType.description} (${response.prcContract.company.businessName})`
              : undefined;
          }
        } else {
          this.selectedDesc = undefined;
          this.detail = false;
          this.enabled = false;
          this.prcContract = new PRCContract();
        }
      });
    });
  }
  //#endregion

  //#region CUSTOM METHODS
  /* ################################################################################################################## */
  /* ## CUSTOM METHODS
  /* ################################################################################################################## */
  //#region FORMS
  /* ****************************************************************************************************************** */
  /* ** FORMS
  /* ****************************************************************************************************************** */
  cleanForm() {
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      currentControl.enable();
    });
  }

  disableForm() {
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      currentControl.disable();
    });
  }

  enableForm() {
    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];

      currentControl.enable();
    });
  }

  loadDataOnForm() {
    this.formGroupControl.setValue({
      startDate     : getDateString(this.prcContract.startDate),
      value         : this.prcContract.value,

      company       : this.prcContract.company,

      employeeType  : this.prcContract.employeeType,
    });
  }

  edit() {
    this.enabled = true;

    this.enableForm();
  }

  cancel() {
    this.cleanForm();
    this.disableForm();

    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }
  //#endregion
  //#endregion

  //#region CRUD
  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  /* ****************************************************************************************************************** */
  /* ** CREATE
  /* ****************************************************************************************************************** */
  create() {
    this.assignPRCContract();

    this.prcContractService.createPRCContract(this.prcContract).subscribe(
      response => {
        this.prcContractService.getAllPRCContracts().subscribe(prcContracts => {
          this.prcContractService.updatePRCContracts(prcContracts.items);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.CREATE_LONG', {
            type: this.componentTypeTranslated,
            startDate: getDateString(this.prcContract.startDate),
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.cancel();
      },
      error => {
        if (error.status === 500 || error.status === 0) {
        } else if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
        }
      },
    );
  }

  /* ****************************************************************************************************************** */
  /* ** UPDATE
  /* ****************************************************************************************************************** */
  update() {
    const observer = jsonpatch.observe(this.prcContract);
    this.assignPRCContract();

    const patch = jsonpatch.generate(observer);

    this.prcContractService.modifyPRCContract(this.prcContract.prcContractId,patch).subscribe(
      response => {
        this.prcContractService.getAllPRCContracts().subscribe(prcContracts => {
          this.prcContractService.updatePRCContracts(prcContracts.items);
        });
        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );
  }

  /* ****************************************************************************************************************** */
  /* ** DELETE
  /* ****************************************************************************************************************** */
  delete() {
    let text = '';

    this.translate.get('PRC_CONTRACT_MAINTENANCE.MESSAGE.DELETE_CONFIRMATION.TEXT', { value: getDateString(this.prcContract.startDate) }).subscribe((transitionStr: string) => {
      text = transitionStr;
    });

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      data: {
        text
      }
    });

    confirmationDialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.prcContractService.deletePRCContract(this.prcContract.prcContractId).subscribe(response => {
          this.prcContractService.getAllPRCContracts().subscribe(prcContracts => {
            this.prcContractService.updatePRCContracts(prcContracts.items);
          });
          let toastTitle = '';
          let toastBody = '';
          this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {
            toastTitle = a;
          });
          this.translate.get('MESSAGES.SUCCESS.DELETE_LONG', {type: this.componentTypeTranslated, startDate: getDateString(this.prcContract.startDate)}).subscribe((a: string) => {
            toastBody = a;
          });
          this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
          this.cancel();
        });
      }
    });
  }
  //#endregion

  //#region ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  assignPRCContract() {
    if (this.prcContract == undefined || this.prcContract == null) {
      this.prcContract = new PRCContract();
    }

    Object.keys(this.formGroupControl.controls).forEach((name) => {
      const currentControl = this.formGroupControl.controls[name];
      const temp = currentControl.value;

      if (temp != null && temp != '') {
        switch(name) {
          case 'company':
            this.prcContract['companyId'] = temp.companyId;
            break;
          case 'employeeType':
            this.prcContract['employeeTypeId'] = temp.employeeTypeId;
            break;
          default:
            this.prcContract[name] = temp;
            break;
        }
      }
    });
  }

  assignCompany(e) {
    this.formGroupControl.get('company').setValue(e.option.value);
  }

  displayCompany(item: Company): string | undefined {
    return (item)
      ? item.businessName
      : undefined;
  }

  assignEmployeeType(e) {
    this.formGroupControl.get('employeeType').setValue(e.option.value);
  }

  displayEmployeeType(item: EmployeeType): string | undefined {
    return (item)
      ? item.description
      : undefined;
  }

  addFilters() {
    this.filteredCompanies = this.formGroupControl.controls['company'].valueChanges.pipe(startWith(""), map(val =>
      this.prcContractsManagementInfo.allCompanies.filter(item =>
        customStartsWith(item.businessName, val)
      ))
    );

    this.filteredEmployeeTypes = this.formGroupControl.controls['employeeType'].valueChanges.pipe(startWith(""), map(val =>
      this.prcContractsManagementInfo.allEmployeeTypes.filter(item =>
        customStartsWith(item.description, val)
      ))
    );
  }
  //#endregion

  //#region OTHERS
  /* ****************************************************************************************************************** */
  /* ** OTHERS
  /* ****************************************************************************************************************** */
  back() {
    this.cancel();
  }
  //#endregion

  //#region UTILS
  /* ################################################################################################################## */
  /* ## UTILS
  /* ################################################################################################################## */
  //#endregion
}
