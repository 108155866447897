import { Directive, ElementRef, OnDestroy } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
  selector: '[appDateTimeMask]'
})
export class DateTimeMaskDirective {

  public mask = [
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/,  '-',         
    /\d/, /\d/,        
    ' ' ,
    /\d/, /\d/, ':', /\d/, /\d/ ]; // yyyy-mm-dd hh:mm
   
  public maskedInputController;

  constructor(private element: ElementRef) {
    this.maskedInputController = textMask.maskInput({
      inputElement: this.element.nativeElement,
      mask: this.mask
    });
  }

  public ngOnDestroy(): void {
    this.maskedInputController.destroy();
  }

}
