import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { AbsenceType } from '../models/absence-type.model';


@Injectable({ providedIn: 'root' })
export class AbsenceTypeService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private absenceTypeSubject: ReplaySubject<AbsenceType[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public absenceTypeObservable: Observable<AbsenceType[]> = this.absenceTypeSubject.asObservable();

    private absenceTypeRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public absenceTypeRouteObservable: Observable<string> = this.absenceTypeRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/absenceType';
    }

    constructor(private http: HttpClient) {
    }

    getAllAbsenceTypes(filter?: string) {
      if (filter == undefined || filter  == null) {
        filter = "";
      }
      let url = `${this.serviceUrl}${filter}`;

      return this.http.get<any>(url);
    }

    getAbsenceType(absenceTypeId: string, related?: boolean) {
      let relatedFilter="";

      if (related!=undefined && related != null) {
          relatedFilter = `?related=${related}`;
      }

      let url = `${this.serviceUrl}/${absenceTypeId}${relatedFilter}`;

      return this.http.get<any>(url);
    }

    createAbsenceType(absenceType: AbsenceType) {
      const endpointUrl = `${this.serviceUrl}`;
      return this.http.post(endpointUrl, absenceType);
    }

    deleteAbsenceType(absenceTypeId: string) {
      const endpointUrl = `${this.serviceUrl}/${absenceTypeId}`;
      return this.http.delete(endpointUrl);
    }

    modifyAbsenceType(absenceTypeId:string, absenceType: any) {
      const endpointUrl = `${this.serviceUrl}/${absenceTypeId}`;
      return this.http.patch(endpointUrl, absenceType);
    }

    updateAbsenceTypes(absenceTypes: any) {
      this.absenceTypeSubject.next(absenceTypes);
    }

    updateAbsenceTypeRoutes(id: string) {
      this.absenceTypeRouteSubject.next(id);
    }

    notifyChanges() {
      this.changeSubject.next();
    }
}
