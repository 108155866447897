import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { ToastrService } from 'ngx-toastr';
import { EmployeeEvent, AbsenceEvent, AbsenceListEvent } from 'src/app/interfaces/events.interface';
import { Employee } from '../../../models/employee.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { FiltersApiIds } from 'src/app/models/filters-api-ids.model';
import { AbsenceGroupsItems } from 'src/app/interfaces/absence.interface';
import { Absence } from 'src/app/models/absence.model';
import { AbsenceStateCode } from 'src/app/models/absence-state-code-enum.model';
import { AbsenceState } from 'src/app/models/absence-state.model';
import { AbsenceManagementCalendarComponent } from '../absence-management-calendar/absence-management-calendar.component';
import { AbsenceService } from 'src/app/services/absence.service';
import { AbsenceStateService } from 'src/app/services/absence-state.service';
import { AbsenceManagementRejectDialogComponent } from '../absence-management-reject-dialog/absence-management-reject-dialog.component';
import { AbsenceTypeService } from 'src/app/services/absence-type.service';
import { AbsenceType } from '../../../models/absence-type.model';
import { EmployeeSituationCode } from '../../../models/employee-situation-enum.model';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { EmployeeSelectionService } from 'src/app/modules/employee/services/employee-selection.service';
import { AbsenceChangesService } from '../../../services/absence-changes.service';

@Component({
  selector: 'app-absence-management-groups',
  templateUrl: './absence-management-groups.component.html',
  styleUrls: [
    './absence-management-groups.component.css',
    '../../maintenance.components.css',
    '../../maintenance-list.component.css',
  ],
})
export class AbsenceManagementGroupsComponent extends BaseListComponent<AbsenceGroupsItems> implements OnInit, OnDestroy {
  employeeView : boolean = false;

  employees: Employee[] = [];
  absencesGroups: AbsenceGroupsItems[] = [];
  absences: Absence[] = [];
  selectedEmployees: Employee[] = [];
  currentEmployee: Employee;
  absenceStateCode = AbsenceStateCode;

  absenceStates: AbsenceState[] = [];
  allAbsenceTypes: AbsenceType[] = [];

  tabStates: string[] = [];
  excludeStates: AbsenceStateCode[] = [];
  visibleStatesCalendar: AbsenceStateCode[] = [];

  currentYear: number;

  subscriptions: Subscription[] = [];
  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;
  employeeAssociatedToUserLoggedId = null;
  loadDataAlreadyLaunched = false;

  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  @ViewChild('calendar', { static: false }) calendar: AbsenceManagementCalendarComponent;

  filtersApiEmployee: FiltersApi = new FiltersApi();
  filtersApiAbsences: FiltersApi = new FiltersApi();
  filtersRequestEmployeeIds: FiltersApiIds = new FiltersApiIds();

  // Permissions
  roles: Role[];
  canAdd = false;
  canDelete = false;

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  constructor(
    protected absenceService: AbsenceService,
    public absenceStateService: AbsenceStateService,
    public absenceTypeService: AbsenceTypeService,
    public absenceChangesService: AbsenceChangesService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    protected RNService: ResponseNotificationService,
    public accountService: AccountService,
    public employeeManagementService: EmployeeManagementService,
    public hotkeys: Hotkeys,
    protected spinner: NgxSpinnerService,
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected toastrService: ToastrService,
    protected employeeSelectionService: EmployeeSelectionService,
    protected permissionsService: PermissionsService,
    ) {
    super(accountService, SSMService, router, hotkeys);
    this.baseRoute = '/managements/absence_management';

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
    ];
    this.onBuild = () => {
    };

    // Filtros para búsqueda de solicitudes
    this.currentYear = new Date().getFullYear();
    this.setFilterDate();

    this.filtersApiAbsences.add(
      {
        field: "related",
        value: "true"
      }
    );

    // Filtros para búsqueda de Empleados
    this.filtersApiEmployee.add(
      {
        field: "related",
        value: "false"
      }
    );

    this.filtersApiEmployee.add(
      {
        field: "situation",
        value: EmployeeSituationCode[EmployeeSituationCode.ACTIVE]
      }
    );

    this.loadData = () => {
      this.spinner.show();
      const userId = this.accountService.currentUser.id;

      this.subscriptions.push(
        this.route.paramMap.subscribe(params => {
          this.subscriptions.push(
            this.employeeManagementService.getEmployeeByUserId(userId, false).subscribe(employeeAssociatedToUserLogged => {
              this.employeeAssociatedToUserLoggedId = employeeAssociatedToUserLogged.employeeId;

              if (!this.loadDataAlreadyLaunched) {
                // Filtros para búsqueda de solicitudes
                this.filtersApiAbsences.add(
                  {
                    field: "Management",
                    value: "true"
                  }
                );

                // Filtros para búsqueda de Empleados
                this.filtersApiEmployee.add(
                  {
                    field: "validatorId",
                    value: this.employeeAssociatedToUserLoggedId
                  }
                );

                // this.filtersApiEmployee.add(
                //   {
                //     field: "Management",
                //     value: "true"
                //   }
                // );

                if(!this.employeeView) {
                  this.loadEmployees();
                }

                this.loadDataAlreadyLaunched = true;
              }

              this.absenceService.getAllAbsenceGroupBy(this.filtersApiAbsences.getStrinFilterApi(), this.filtersRequestEmployeeIds.filters).subscribe(absencesGroups => {
                const filteredItems = this.assignAbsencesStates(absencesGroups.items);

                this.absenceService.updateAbsencesGroup(filteredItems);
                this.loadAbsences(filteredItems);

                this.spinner.hide();
              });
            })
          );
        })
      );
    };

    this.onInit = this.init;
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
    // employeeView
    this.permissionsService.setEmployeeView(this.employeeView);

    // roles
    this.subscriptions.push(
      this.permissionsService.roles.subscribe(data => {
        this.roles = data;
      })
    );

    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;

        this.assignPermissions();
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  assignPermissions() {
    if (this.userRolesPermissions) {
      if (
        this.userRolesPermissions.isAdmin ||
        this.userRolesPermissions.isRRHH ||
        this.userRolesPermissions.isRA ||
        this.userRolesPermissions.isRP
      ) {
        if (
          this.userRolesPermissions.isAdmin ||
          this.userRolesPermissions.isRRHH
        ) {
          this.canAdd = true;
          this.canDelete = true;
        }
      }

      this.init();
    }
  }
  //#endregion
  init() {
    this.getAbsenceStates();
    this.getAbsenceTypes();

    this.setRequestStates();
    this.loadData();

    this.subscriptions.push(
      this.absenceService.absenceGroupObservable.subscribe(absences => {
        this.loadAbsences(absences);
      })
    );
  }

  refresh() {
    // Al refrescar forzamos a cargar los datos de nuevo asignando la variable `loadDataAlreadyLaunched` a false
    this.loadDataAlreadyLaunched = false;
    this.loadData();

    this.absenceChangesService.refreshData(true);
  }

  loadEmployees() {
    this.spinner.show();
    this.employeeManagementService.getAllEmployees(this.filtersApiEmployee.getStrinFilterApi()).subscribe(employees => {
      this.employees = employees.items;
      this.employeeManagementService.updateEmployeeManagements(this.employees);

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.RNService.showError(error);
    });
  }

  // TODO: refacotrizar para simplificar
  loadAbsences(items) {
    // Se vacían los arrays
    this.absencesGroups = new Array();
    this.absences = new Array();

    // Se prepara el array nominativo
    for (let stateName of this.tabStates) {
          this.absencesGroups[stateName] = [];
    }

    // Se rellena el array con los distintos items
    for (let item of items) {
      let temp = {
        ALL: new Array()
      };

      // Se recorren las Absence de cada item y se clasifican
      for (let absence of item) {
        if (!this.excludeStates.includes(absence.absenceState.stateCode)) {
          let indexName = AbsenceStateCode[absence.absenceState.stateCode];
          if (indexName.includes("PENDING")) {
            indexName = "PENDING";
          }

          if (temp.hasOwnProperty(indexName)) {
            temp[indexName].push(absence);
          } else {
            temp[indexName] = [absence];
          }

          this.absences.push(absence);
          temp["ALL"].push(absence);
        }
      }

      // Se añaden las Absence ya clasificadas a la lista agrupadas por Employee
      for (let element in this.absencesGroups) {
        if (Object.keys(temp).includes(element)) {
          this.absencesGroups[element].push(temp[element]);
        }
      }
    }
  }

  getAbsenceStates() {
    this.absenceStateService.getAllAbsenceState().subscribe(absenceStates => {
      this.absenceStates = absenceStates.items;
    }, error => {
      this.RNService.showError(error);
    });
  }

  getAbsenceTypes() {
    this.absenceTypeService.getAllAbsenceTypes().subscribe(absenceTypesResponse => {
      this.allAbsenceTypes = absenceTypesResponse.items;
    });
  }

  assignAbsencesStates(items) {
    for (let item of items) {
      for (let absence of item) {
        // Se recupera el estado
        absence.absenceState = this.absenceStates.find(element => element.absenceStateId == absence.absenceStateId);
        absence.absenceType = this.allAbsenceTypes.find(element => element.absenceTypeId == absence.absenceTypeId);
      }
    }

    return items;
  }

  /**
   * Recoge un evento del componente de listado de Employees y ejecuta la acción correspondiente a ese evento.
   *
   * @param employeeEvent evento recogido
   */
  selectEmployee(employeeEvent: EmployeeEvent) {
    if (employeeEvent.eventType == "SELECT") {
      if (employeeEvent.employee == null) {
        // Filtros para búsqueda de solicitudes
        this.filtersApiAbsences.delete("employeeId");
        // Filtros para búsqueda de Empleados
        this.filtersApiEmployee.delete("employeeId");

        this.selectedEmployees = [];
        this.filtersRequestEmployeeIds = new FiltersApiIds();
        this.currentEmployee = null;
      } else {
        if (!this.selectedEmployees.includes(employeeEvent.employee)) {
          this.selectedEmployees.push(employeeEvent.employee);
          this.currentEmployee = employeeEvent.employee;

          // Filtros para búsqueda de solicitudes
          this.filtersRequestEmployeeIds.add(employeeEvent.employee.employeeId);
        } else {
          const index = this.selectedEmployees.indexOf(employeeEvent.employee);

          if (index > -1) {
            this.selectedEmployees.splice(index, 1);
          }

          // Filtros para búsqueda de solicitudes
          this.filtersRequestEmployeeIds.delete(employeeEvent.employee.employeeId);

          this.currentEmployee = (this.selectedEmployees[0])
            ? this.selectedEmployees[0]
            : null;
        }
      }

      this.employeeSelectionService.onChangeCurrentEmployee(this.currentEmployee);
      this.employeeSelectionService.onChangeSelectionEmployees(this.selectedEmployees);

      this.loadData();
    }
  }

  /**
   * Actualiza el filtro de año
   *
   * @param year nuevo año
   */
  currentYearEvent(year: number) {
    this.currentYear = year;
    this.setFilterDate();
    this.loadData();
  }

  /**
   * Actualiza el estado de las solicitudes
   *
   * @param absenceList nuevo año
   */
  absenceListEvent(absenceList: AbsenceListEvent) {
    this.absences = absenceList.absenceList;
  }

  /**
   * Selecciona los filtros para establecer el rango de fechas de las ausencias
   */
  setFilterDate() {
    this.filtersApiAbsences.add(
      {
        field: "startDate",
        value: `${this.currentYear}-01-01T00:00:00.000Z`
      },
      true
    );

    this.filtersApiAbsences.add(
      {
        field: "finishDate",
        value: `${this.currentYear}-12-31T23:59:59.999Z`
      },
      true
    );
  }

  /**
   * Recoge un evento del componente de listado de solicitudes y ejecuta la acción correspondiente a ese evento.
   *
   * @param absenceEvent evento recogido
   */
  actionAbsence(absenceEvent: AbsenceEvent) {
    switch (absenceEvent.eventType) {
      case "REJECTED":
        this.rejectAbsence(absenceEvent.absence, absenceEvent.eventType);
        break;
      default:
        this.updateAbsence(absenceEvent.absence, absenceEvent.eventType);
        break;
    }
  }

  /**
   * Pasa la solicitud de ausencias recibida como param y el nuevo estado y se abre un
   * dialog en el que se deberá escribir la razón por la cual se rechaza la solicitud.
   * @param absence - La solicitud de ausencias a actualizar
   * @param newState - Estado al que debe camibar la solicitud de ausencias
   */
  async rejectAbsence(absence: Absence, newState: string) {
    const rejectAbsenceDialog = this.dialog.open(AbsenceManagementRejectDialogComponent, { width: '550px' });

    rejectAbsenceDialog.afterClosed().subscribe((reason: string) => {
      if (reason) {
        this.updateAbsence(absence, newState, reason);
      }
    });
  }

  /**
   * Pasa la solicitud de ausencias recibida como param y el nuevo estado.
   * @param absence - La solicitud de ausencias a actualizar
   * @param newState - Estado al que debe camibar la solicitud de ausencias
   */
  async updateAbsence(absence: Absence, newState: string, reasonReject: string = "") {
    let absenceNewState = this.absenceStates.find(element => element.stateCode == AbsenceStateCode[newState]);
    this.spinner.show();

    this.absenceService.changeStateAbsence(absence.absenceId, absenceNewState.absenceStateId, reasonReject).subscribe(response => {

      this.absenceService.getAllAbsenceGroupBy(this.filtersApiAbsences.getStrinFilterApi(), this.filtersRequestEmployeeIds.filters).subscribe(absencesGroups => {
        const filteredItems = this.assignAbsencesStates(absencesGroups.items);

        this.absenceService.updateAbsencesGroup(filteredItems);
        this.refresh();

        this.successToastRequest(newState, absence.reason);
        this.spinner.hide();
      });
    }, error => {
      this.spinner.hide();
      this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((lang: string) => {
        this.toastrService.error(error.error, lang, { timeOut: 3000 });
      });
    });
  }

  /**
   * Crea un mensaje de cambio de estado correcto de la solicitud
   *
   * @param state nuevo estado de la solicitud
   * @param reasonReject razón del rechazo
   */
  successToastRequest(state: string, reasonReject: string = "") {
    let msg = "UPDATE";

    switch (state) {
      case "APPROVED":
        msg = "APPROVED";
        break;
      case "ANNULLED":
        msg = "ANNULLED";
        break;
      case "CANCELED":
        msg = "CANCEL";
        break;
      case "PENDING_APROVAL":
      case "PENDING_ANNULLED":
        msg = "SEND";
        break;
      case "REJECTED":
        msg = "REJECTED";
        break;
    }

    this.translate.get('ABSENCE_MAINTENANCE.NAME').subscribe((componentTranslated: string) => {
      let toastTitle = '';
      let toastBody = '';
      this.translate.get(`MESSAGES.SUCCESS.${msg}`).subscribe((a: string) => { toastTitle = a });
      this.translate.get(`MESSAGES.SUCCESS.${msg}_LONG_SIMPLE`, { type: componentTranslated, code: reasonReject }).subscribe((a: string) => { toastBody = a });
      this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
    });
  }

  /**
   * Selecciona los estados que se deben manejar y los que se deben mostrar.
   */
  setRequestStates() {
    this.tabStates = [
      "REGISTERED",
      "PENDING",
      "APPROVED",
      "REJECTED",
      "ANNULLED",
      // "CANCELED",
      "ALL",
    ];

    this.excludeStates = [
      AbsenceStateCode.CANCELED,
    ];

    this.visibleStatesCalendar = [
      AbsenceStateCode.REGISTERED,
      AbsenceStateCode.APPROVED,
      AbsenceStateCode.PENDING_APROVAL,
      AbsenceStateCode.PENDING_ANNULLED,
    ];

    this.setFilterAbsencesStates();
  }

  /**
   * Asigna el filtro para obtener sólo las solicitudes con los estados que se van a manejar.
   */
  setFilterAbsencesStates() {
    this.filtersApiAbsences.addMultipleValue(
      "absenceStateId",
      this.getRequestStatesId(this.excludeStates, true),
      true
    );
  }

  /**
   * Devuelve los Ids de la base de datos de la lista de códigos de estado pasada como parámetro.
   *
   * @param absenceStateCodeList lista de códigos a procesar.
   * @param reverse si es true en lugar de procesar los estados de la lista pasada se procesan los
   * los estados que no coincidan con la lista.
   * @returns una lista de Ids de estados
   */
  getRequestStatesId(absenceStateCodeList: AbsenceStateCode[], reverse: boolean = false) {
    let result: string[] = [];

    let absenceStateTemp: AbsenceState[] = [];

    if (reverse) {
      absenceStateTemp = this.absenceStates.filter(element => !absenceStateCodeList.includes(element.stateCode));
    } else {
      absenceStateTemp = this.absenceStates.filter(element => absenceStateCodeList.includes(element.stateCode));
    }

    for (let item of absenceStateTemp) {
      result.push(item.absenceStateId);
    }

    return result;
  }
}
