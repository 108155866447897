// ################################################################################################################
// ## IMPORTS
// ################################################################################################################
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { Employee } from "src/app/models/employee.model";
import {
  MatSlideToggleChange,
  MatTableDataSource,
  PageEvent,
} from "@angular/material";
import { EmployeeManagementService } from "src/app/services/employee-management.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  AccountService,
  ResponseNotificationService,
} from "inzo-portalempleado";
import { EmployeeSituationCode } from "src/app/models/employee-situation-enum.model";
import * as _ from "lodash";
import * as Rxjs from "rxjs";
import { debounceTime, filter, switchMap } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import { AbsenceChangesService } from "src/app/services/absence-changes.service";
import { FiltersApi } from "src/app/models/filters-api.model";
import { InzoMatPaginatorComponent } from "src/app/modules/pe-common/components/inzo-mat-paginator/inzo-mat-paginator.component";
import {
  IInzoPaginator,
} from "src/app/modules/pe-common/interfaces/inzo-mat-paginator.interface";
import { EmployeeEvent } from 'src/app/interfaces/events.interface';
import { TranslateService } from "@ngx-translate/core";
import { EmployeeSelectionService } from "../../../services/employee-selection.service";
import { FilterApi } from "src/app/interfaces/filter-api.interface";

// ################################################################################################################
// ## CLASS EmployeeTableComponent
// ################################################################################################################
@Component({
  selector: 'app-employee-table',
  templateUrl: './employee-table.component.html',
  styleUrls: [
    './employee-table.component.css',
    '../../../../../components/maintenance.components.css',
    '../../../../../components/maintenance-list.component.css',
  ],
})
export class EmployeeTableComponent implements OnInit {
  //#region ARGUMENTS
  // ##############################################################################################################
  // ## Arguments
  // ##############################################################################################################
  @Input() displayedColumns: string[] = [
    "code",
    "firstSurname",
    "secondSurname",
    "name",
    "companyEmail",
    "companyPhone",
  ];

  @Input() employees: Employee[] = [];
  @Input() title: string = "";
  @Output() selectEmployee: EventEmitter<EmployeeEvent> = new EventEmitter();
  @ViewChild("tableFilterInput", { static: true }) tableFilterInput: ElementRef;

  @Input() selectedEmployees: Employee[] = [];
  @Input() showAllEmployeesToogledButton: boolean = false;
  @Input() activateToogledButton: boolean = false;
  @Input() takeValidatorHierarchy: boolean = true;
  @Input() hierarchyCurrentState: boolean = false;

  @Output() shownEmployeesChanged: EventEmitter<Employee[]> = new EventEmitter();

  subscriptions: Subscription[] = [];

  // Search
  @Output() onDebounce: EventEmitter<string> = new EventEmitter();
  debouncer: Subject<string> = new Subject();
  filter = "";

  employeeAssociatedToUserLoggedId = null;

  filtersApiEmployee: FiltersApi = new FiltersApi();

  dataSource: MatTableDataSource<Employee>;
  inzoPaginatorFirstPage : IInzoPaginator = {
    currentPage: 1,
    pageSize: 10,
  };

  @ViewChild("employeePaginator", { static: false })
  employeePaginator: InzoMatPaginatorComponent = new InzoMatPaginatorComponent();
  //#endregion

  //#region CONSTRUCTOR
  // ##############################################################################################################
  // ## Constructor
  // ##############################################################################################################
  constructor(
    protected employeeManagementService: EmployeeManagementService,
    protected employeeSelectionService: EmployeeSelectionService,
    protected spinner: NgxSpinnerService,
    protected accountService: AccountService,
    protected RNService: ResponseNotificationService,
    protected translate: TranslateService,
  ) {
    this.employeePaginator.showFirstLastButtons = true ;
    this.employeePaginator.pageSizeOptions = [10, 20, 30, 40];
    this.employeePaginator.pageSizeDefault = this.inzoPaginatorFirstPage.pageSize;
    this.employeePaginator.currentPageDefault = this.inzoPaginatorFirstPage.currentPage;

    this.employeePaginator.inzoPaginator = this.inzoPaginatorFirstPage;
  }
  //#endregion

  //#region ANGULAR METHODS
  // ##############################################################################################################
  // ## Angular Methods
  // ##############################################################################################################
  ngOnInit() {
    const userId = this.accountService.currentUser.id;
    this.setFirstPage();

    this.subscriptions.push(
      this.employeeSelectionService.refresh.subscribe((response) => {
        if (response == true) {
          this.refresh();
        }
      })
    );

    this.setDefaultFilters();
    this.loadEmployees();

    this.subscriptions.push(
      this.debouncer.pipe(debounceTime(300)).subscribe((filter) => {
        this.customApplyFilter(filter);
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  //#endregion

  //#region CUSTOM METHODS
  // ##############################################################################################################
  // ## Custom Methods
  // ##############################################################################################################
  loadEmployees() {
    this.spinner.show();

    this.filtersApiEmployee.delete("validatorId");

    this.employeeManagementService.GetAllEmployeesHierarchy(this.filtersApiEmployee.getStrinFilterApi())
      .subscribe(
        (employees) => {
          const {
            currentPage,
            pageSize,
            totalCount,
          } = employees;

          this.employeePaginator.inzoPaginator = {
            currentPage,
            pageSize,
            totalCount
          };

          this.employeeManagementService.updateEmployeeManagements(employees);
          this.employees = employees.items;

          this.employeeManagementService.updateEmployeeManagements(
            this.employees
          );

          this.shownEmployeesChanged.emit(this.employees);

          const filterOr : FilterApi = this.filtersApiEmployee.search("filtersOr");

          if (this.filtersApiEmployee && filterOr && filterOr.value === "true") {
            this.employeeSelectionService.onChangeSelectionEmployees(this.employees);
          }

          this.dataSource = new MatTableDataSource<Employee>(this.employees);

          this.spinner.hide();
        },
        (error) => {
          this.RNService.showError(error);
        }
      );
  }

  showAllEmployeesToogled(toogleChange: MatSlideToggleChange) {
    this.hierarchyCurrentState = toogleChange.checked;
    const getAllRequests: FilterApi =
    {
      field: "getAll",
      value: "false"
    };

    this.setFirstPage();

    if (!this.hierarchyCurrentState) {
      this.filtersApiEmployee.delete("validatorId");
      getAllRequests.value = "false";
    } else {
      this.filtersApiEmployee.add(
        {
          field: "validatorId",
          value: this.employeeAssociatedToUserLoggedId,
        },
        true
      );
      getAllRequests.value = "true";
    }

    this.filtersApiEmployee.add(
      {
        field: "hierarchy",
        value: this.hierarchyCurrentState.toString(),
      },
      true
    );

    const currentValidatorFilter = new FiltersApi();
    currentValidatorFilter.add(getAllRequests);

    if (this.filtersApiEmployee.search("situation")) {
      currentValidatorFilter.add(this.filtersApiEmployee.search("situation"));
    }

    // if (this.filtersApiEmployee.search("validatorId")) {
    //   currentValidatorFilter.add(this.filtersApiEmployee.search("validatorId"));
    // }

    if (this.filtersApiEmployee.search("validator")) {
      currentValidatorFilter.add(this.filtersApiEmployee.search("validator"));
    }

    if (this.filtersApiEmployee.search("hierarchy")) {
      currentValidatorFilter.add(this.filtersApiEmployee.search("hierarchy"));
    }

    this.employeeSelectionService.onChangeFilterListApi(currentValidatorFilter);

    this.loadEmployees();
  }

  refresh() {
    this.returnEmployee(null);

    this.setFirstPage();
    this.filter = "";
    this.debouncer.next(this.filter);
  }

  /**
  * Devuelve el objeto Employee selecionado.
  * @param employee objeto Employee selecionado
  */
  returnEmployee(employee: Employee): void {
    this.selectEmployee.emit(
      {
        employee,
        eventType: "SELECT"
      }
    );
  }

  //#endregion

  //#region PAGINATOR
  /* ################################################################################################################## */
  /* ## PAGINATOR
  /* ################################################################################################################## */
  setApiPaginator(inzoPaginator: IInzoPaginator): void {
    this.filtersApiEmployee.add(
      {
        field: "currentPage",
        value: inzoPaginator.currentPage.toString(),
      },
      true
    );

    this.filtersApiEmployee.add(
      {
        field: "pageSize",
        value: inzoPaginator.pageSize.toString(),
      },
      true
    );
  }

  getPage(event: IInzoPaginator): void {
    this.setApiPaginator(event);

    this.loadEmployees();
  }

  setFirstPage() {
    this.setApiPaginator(this.inzoPaginatorFirstPage);
  }
  //#endregion

  //#region ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ## ASSIGNMENT AND DISPLAY METHODS
  /* ################################################################################################################## */
  /* ****************************************************************************************************************** */
  /* ** FILTERS
  /* ****************************************************************************************************************** */
  keyFilterPressed() {
    this.debouncer.next(this.filter);
  }

  customApplyFilter(filterValue: string) {
    this.setFirstPage();

    if (filterValue === "") {
      this.filtersApiEmployee.delete("filtersOr");
    } else {
      this.filtersApiEmployee.add(
        {
          field: "filtersOr",
          value: "true",
        },
        true
      );
    }

    for (let searchCriteria of this.displayedColumns) {
      if (filterValue === "") {
        this.filtersApiEmployee.delete(searchCriteria);
      } else {
        this.filtersApiEmployee.add(
          {
            field: searchCriteria,
            value: filterValue,
          },
          true
        );
      }
    }

    this.loadEmployees();
  }

  setDefaultFilters() {
    this.setFirstPage();

    // Filtros para búsqueda de Empleados
    this.filtersApiEmployee.add({
      field: "related",
      value: "false",
    });

    this.filtersApiEmployee.add(
      {
        field: "validator",
        value: this.takeValidatorHierarchy.toString(),
      },
      true
    );

    this.filtersApiEmployee.add(
      {
        field: "hierarchy",
        value: this.hierarchyCurrentState.toString(),
      },
      true
    );

    this.filtersApiEmployee.add({
      field: "situation",
      value: EmployeeSituationCode[EmployeeSituationCode.ACTIVE],
    });
  }
  //#endregion

  //#region UTILS
  // ##############################################################################################################
  // ## Utils
  // ##############################################################################################################
  /**
   * Selecciona las propiedades del estilo adecuados al estado del item
   */
  getSyleItem(employee: Employee) {
    let style = {
      color: "lightgray",
    };

    if (
      (this.selectedEmployees && this.selectedEmployees.length == 0) ||
      this.selectedEmployees.includes(employee)
    ) {
      style = {
        color: "inherit",
      };
    }

    return style;
  }
  //#endregion
}
