import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { DCRState } from '../models/dcr-state.model';


@Injectable({ providedIn: 'root' })
export class DCRStateService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private dcrStateRequestSubject: ReplaySubject<DCRState[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public dcrStateRequestObservable: Observable<DCRState[]> = this.dcrStateRequestSubject.asObservable();

    private dcrStateRequestRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public dcrStateRequestRouteObservable: Observable<string> = this.dcrStateRequestRouteSubject.asObservable();

    get serviceUrl() {
        return `${Config.apiBaseUrl}/DCRStates`;
    }

    constructor(private http: HttpClient) {
    }

    getAllDCRStateRequest() {
        let url = `${this.serviceUrl}`;

        return this.http.get<any>(url);
    }

    getDCRStateRequest(dcrStateRequestId: string, related?: boolean) {
        let url = `${this.serviceUrl}}/${dcrStateRequestId}`;

        if(related != undefined && related != null){
          url += `?related=${related}`;
        }

        return this.http.get<DCRState>(url);
    }

    updateDCRStateRequests(dcrStateRequests: DCRState[]) {
        this.dcrStateRequestSubject.next(dcrStateRequests);
    }

    updateDCRStateRequestRoutes(id: string) {
        this.dcrStateRequestRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}