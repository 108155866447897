// https://github.com/efja/proxectOS-UI/master/src/app/helpers/check-types.helper.ts
import moment = require("moment");
import {
    isJSONValue,
    isJSONObject,
    isJSONArray,
    isString,
    isNumber,
    isBoolean,
    isNull,
    isUndefined,
    JSONObject,
    JSONValue,
    JSONArray,
} from "types-json";
import { ResultCheckType } from "../interfaces/checks-helper.interface";

export function checkType(obj: any) : ResultCheckType {
    let result : ResultCheckType = {
        getObjectType   : "",
        isArray         : (obj) ? isJSONArray(obj) : false,
        isBoolean       : (obj) ? isBoolean(obj) : false,
        isDate          : (obj) ? moment(new Date(obj.toString())).isValid() : false,
        isNull          : isNull(obj),
        isNumber        : (obj) ? isNumber(obj) : false,
        isObject        : false,
        isString        : (obj) ? isString(obj) : false,
        isUndefined     : (obj != null) ? isUndefined(obj) : false,
    };

    if (obj) {
      try {
          if (!result.isNumber && !result.isDate && result.isString) {
              result.isNumber = isNumber(Number(obj));
          }
      } catch { }

      try {
          if (!result.isArray && result.isString) {
              result.isArray = isJSONArray(JSON.parse(obj));
          }
      } catch { }

      try {
          if (
              !result.isArray ||
              !result.isBoolean ||
              !result.isDate ||
              !result.isNumber ||
              !result.isString
          ) {
              result.getObjectType =  getClassName(obj);
          }
      } catch { }
    }

    return result;
}

export function getClassName(item: any) {
  return item.__meta.name
}
