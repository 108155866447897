import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PayrollService } from 'src/app/services/payroll.service';

@Component({
  selector: 'app-maintenance-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./../maintenance.components.css'],
})
export class PayrollComponent implements AfterViewInit, OnInit {
  showForm = false;
  constructor( private payrollService: PayrollService) {
  }
  ngAfterViewInit() {
    this.payrollService.payrollRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
  ngOnInit() {
  }
}
