import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-maintenance-personal-course',
  templateUrl: './personal-course.component.html',
  styleUrls: ['./../maintenance.components.css'],
})
export class PersonalCourseComponent implements AfterViewInit, OnInit {
  showForm = false;
  constructor( private courseService: CourseService) {
  }
  ngAfterViewInit() {
    this.courseService.courseRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
  ngOnInit() {
  }
}
