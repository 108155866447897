import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AbsenceState } from 'src/app/models/absence-state.model';
import { Absence } from 'src/app/models/absence.model';
import { Employee } from 'src/app/models/employee.model';
import { AbsenceChangesService } from 'src/app/services/absence-changes.service';
import { ITLeaveCreateRangeDialogComponent } from '../it-leave-create-range-dialog/it-leave-create-range-dialog.component';

@Component({
  selector: 'app-it-leave-calendar-actions',
  templateUrl: './it-leave-calendar-actions.component.html',
  styleUrls: ['./it-leave-calendar-actions.component.css']
})
export class ITLeaveCalendarActionsComponent implements OnInit {
  @Input() currentEmployee: Employee;
  @Input() itLeaveStates: AbsenceState[] = [];

  @Output() absenceCreateEvent: EventEmitter<Absence> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    protected absenceChangesService: AbsenceChangesService,
  ) { }

  ngOnInit() {
  }

  createIT(): void {
    const createRangeDialog = this.dialog.open(ITLeaveCreateRangeDialogComponent, {
      width: '550px',
      data: {
        disableClose: true,
        currentEmployee: this.currentEmployee,
        itLeaveStates: this.itLeaveStates,
      }
    });

    createRangeDialog.afterClosed().subscribe((newAbsence) => {
      if (newAbsence) {
        this.absenceChangesService.refreshData(true);
        // this.absenceCreateEvent.emit(newAbsence as Absence);
      }
    });
  }
}
