import { AbsenceDocument } from "./absence-document.model";
import { AbsenceState } from "./absence-state.model";
import { AbsenceType } from "./absence-type.model";
import { AHRChanges } from "./ahr-changes.model";
import { Employee } from "./employee.model";

export class Absence {
  absenceId             : string;
  description           : string;
  startDate             : any;
  finishDate            : any;
  expectedFinishDate    : any;
  expectedFinishDates   : any[];
  requestedDates        : any[];
  reason                : string;
  createdDate           : string;
  updatedDate           : string;

  visibleOnYearCalendar : boolean;
  isMedicalLeave        : boolean;

  //Relations
  employeeId            : string;
  employee              : Employee;

  absenceDocuments      : AbsenceDocument[];

  absenceStateId        : string;
  absenceState          : AbsenceState;
  absenceChanges        : AHRChanges[];

  absenceTypeId         : string;
  absenceType           : AbsenceType;

  constructor(obj?: Partial<Absence>) {
    this.absenceId              = (obj && obj.absenceId) || undefined;
    this.description            = (obj && obj.description) || undefined;
    this.startDate              = (obj && obj.startDate) || undefined;
    this.finishDate             = (obj && obj.finishDate) || undefined;
    this.expectedFinishDate     = (obj && obj.expectedFinishDate) || undefined;
    this.expectedFinishDates    = (obj && obj.requestedDates) || undefined;
    this.requestedDates         = (obj && obj.requestedDates) || undefined;
    this.reason                 = (obj && obj.reason) || undefined;
    this.createdDate            = (obj && obj.createdDate) || undefined;
    this.updatedDate            = (obj && obj.updatedDate) || undefined;

    this.visibleOnYearCalendar  = (obj && obj.visibleOnYearCalendar) || undefined;

    //Relations
    this.employeeId             = (obj && obj.employeeId) || undefined;
    this.employee               = (obj && obj.employee) || undefined;

    this.absenceDocuments       = (obj && obj.absenceDocuments) || [];

    this.absenceStateId         = (obj && obj.absenceStateId) || undefined;
    this.absenceState           = (obj && obj.absenceState) || undefined;
    this.absenceChanges         = (obj && obj.absenceChanges) || [];

    this.absenceTypeId          = (obj && obj.absenceTypeId) || undefined;
    this.absenceType            = (obj && obj.absenceType) || undefined;
  }
}
