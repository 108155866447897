import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-employee-management-delete-confirm-dialog',
  templateUrl: './employee-management-delete-confirm-dialog.component.html',
  styleUrls: ['./employee-management-delete-confirm-dialog.component.css']
})
export class EmployeeManagementDeleteConfirmDialogComponent {

  constructor(
      public dialogRef: MatDialogRef<any>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

}