import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AbsenceService } from 'src/app/services/absence.service';

@Component({
  selector: 'app-maintenance-absence-management',
  templateUrl: './absence-management.component.html',
  styleUrls: ['./../maintenance.components.css'],
})
export class AbsenceManagementComponent implements AfterViewInit, OnInit {
  constructor() { }

  ngAfterViewInit() {
  }
  ngOnInit() {
  }
}
