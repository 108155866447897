import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { HolidayRequestGroupsItems } from '../interfaces/holidays.interface';
import { HolidayRequest } from '../models/holiday-request.model';


@Injectable({ providedIn: 'root' })
export class HolidayRequestService {
  private changeSubject: Subject<any> = new Subject;
  public changeObservable: Observable<any> = this.changeSubject.asObservable();

  private holidayRequestSubject: ReplaySubject<HolidayRequest[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public holidayRequestObservable: Observable<HolidayRequest[]> = this.holidayRequestSubject.asObservable();

  private holidayRequestGroupSubject: ReplaySubject<HolidayRequestGroupsItems[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public holidayRequestGroupObservable: Observable<HolidayRequestGroupsItems[]> = this.holidayRequestGroupSubject.asObservable();

  private holidayRequestRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public holidayRequestRouteObservable: Observable<string> = this.holidayRequestRouteSubject.asObservable();

  get serviceUrl() {
    return Config.apiBaseUrl + '/holidaysRequest';
  }

  constructor(private http: HttpClient) {
  }

  getAllHolidayRequest(filter?: string) {
    if (filter == undefined || filter == null) {
      filter = "?related=" + true;
    }

    let url = `${this.serviceUrl}${filter}`;

    return this.http.get<any>(url);
  }

  getAllHolidayRequestGroupBy(filter?: string, bodyFilter?: string[]) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    let url = `${this.serviceUrl}/Group${filter}`;

    return this.http.post<any>(url, bodyFilter);
  }

  getAllHolidayRequestHierarchyGroupBy(filter?: string, bodyFilter?: string[]) {
    if (filter == undefined || filter == null) {
      filter = "";
    }

    let url = `${this.serviceUrl}/HierarchyGroup${filter}`;

    return this.http.post<any>(url, bodyFilter);
  }

  getHolidayRequest(holidaysRequestId: string, related?: boolean) {
    let relatedFilter = "";
    if (related != undefined && related != null) {
      relatedFilter += "?related=" + related;
    }
    return this.http.get<HolidayRequest>(`${this.serviceUrl}/${holidaysRequestId}${relatedFilter}`);
  }

  createHolidayRequest(holidayRequest: HolidayRequest, send: boolean = false) {
    const endpointUrl = `${this.serviceUrl}${send ? "?send=true" : ""}`;

    return this.http.post(endpointUrl, holidayRequest);
  }
  modifyHolidayRequest(holidaysRequestId: string, holidayRequest: any) {
    const endpointUrl = `${this.serviceUrl}/${holidaysRequestId}`;
    return this.http.patch(endpointUrl, holidayRequest);
  }
  changeStateHolidayRequest(holidayRequestId: string, holidaysRequestState: string, reason = '') {
    const endpointUrl = `${this.serviceUrl}/${holidayRequestId}/HolidaysRequestState/${holidaysRequestState}/Reason/${reason}`;
    return this.http.put(endpointUrl, null);
  }

  // -----------------------------------------------------------------------------------------------------------
  // SIN LÓGICA DE PROCESO
  // -----------------------------------------------------------------------------------------------------------
  createHolidaysRequestToEmployee(holidayRequest: HolidayRequest, holidaysRequestState: string, reason = '') {
    const endpointUrl = `${this.serviceUrl}/employee/${holidaysRequestState}/Reason/${reason}`;
    return this.http.post(endpointUrl, holidayRequest);
  }
  changeStateHolidaysRequestToEmployee(holidayRequestId: string, holidaysRequestState: string, reason = '') {
    const endpointUrl = `${this.serviceUrl}/employee/${holidayRequestId}/HolidaysRequestState/${holidaysRequestState}/Reason/${reason}`;
    return this.http.put(endpointUrl, null);
  }
  // -----------------------------------------------------------------------------------------------------------

  updateHolidayRequests(HolidayRequests: HolidayRequest[]) {
    this.holidayRequestSubject.next(HolidayRequests);
  }
  updateHolidayRequestsGroup(HolidayRequests: HolidayRequestGroupsItems[]) {
    this.holidayRequestGroupSubject.next(HolidayRequests);
  }

  updateHolidayRequestRoutes(id: string) {
    this.holidayRequestRouteSubject.next(id);
  }

  notifyChanges() {
    this.changeSubject.next();
  }
}
