import { Category } from './category.model';
import { Employee } from './employee.model';

export class EmployeeCategoryHistory {
  employeeCategoryHistoryId : string;
  startDate                 : string;

  //Relations
  categoryId                : string;
  category                  : Category;
  employeeId                : string;
  employee                  : Employee;
  createdByUserId           : string;
  createdByUser             : Employee;

  constructor(obj?: Partial<EmployeeCategoryHistory>) {
    this.employeeCategoryHistoryId  = (obj && obj.employeeCategoryHistoryId) || undefined;
    this.startDate                  = (obj && obj.startDate) || undefined;

    //Relations
    this.categoryId                 = (obj && obj.categoryId) || undefined;
    this.category                   = (obj && obj.category) || undefined;
    this.employeeId                 = (obj && obj.employeeId) || undefined;
    this.employee                   = (obj && obj.employee) || undefined;
    this.createdByUserId            = (obj && obj.createdByUserId) || undefined;
    this.createdByUser              = (obj && obj.createdByUser) || undefined;
  }
}
