import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { CourseType } from 'src/app/models/course-type.model';
import { Subscription } from 'rxjs';
import { CourseTypeService } from 'src/app/services/course-type.service';
import { BaseFormComponent, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';

import * as jsonpatch from 'fast-json-patch';

@Component({
  selector: 'app-course-type-form',
  templateUrl: './course-type-form.component.html',
  styleUrls: ['./../../maintenance.components.css','../../maintenance-list.component.css'],

})
export class CourseTypeFormComponent extends BaseFormComponent<CourseType> implements OnInit, OnDestroy {
  selectedDesc: string = undefined;
  courseType: CourseType;

  roles: Role[];

  enabled = true;
  detail = false;
  permissionsLoaded = false;

  formGroupControl = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),

  });

  canAdd = false;
  canUpdate = false;
  canDelete = false;

  componentTypeTranslated: string;
  subscription: Subscription;

  constructor(
    private toastrService: ToastrService,
    private courseTypeService: CourseTypeService,
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH)) {
      this.canAdd = true;
      this.canDelete = true;
    }

    this.PK = 'courseTypeId';

    this.baseRoute = '/maintenances/course_type';


    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
    };

    this.onEnableForm = () => {
      if (this.enabled) {
      }
    };

    this.onInit = this.init;
    this.translate.get('AREA_MAINTENANCE.LIST.HEADER').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.registerPermissionsLoadedSubscription();
    this.init();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  //#region PERMISSIONS
  /* ################################################################################################################## */
  /* ## PERMISSIONS
  /* ################################################################################################################## */
  registerPermissionsLoadedSubscription() {
    if (!this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription = this.accountService.userPermissionsLoaded.subscribe(() => {
        this.accountService.userRolesLoaded.subscribe((rolesResponse) => {
          this.roles = this.accountService.roles;

          if (
            this.accountService.currentUser.isAdmin ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RRHH) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RA) ||
            this.roles.some(x => x.name === AppPermissions.ROLE_DATA.RP)
          ) {
          }
        });
      });
    } else {
      this.onInit();
    }
  }
  //#endregion

  //#region Inicio componente desde método private

  /* Método de privado ejecutable desde el onInit*/
  /* ************************************************************ */
  private init() {
    this.formGroupControl.get('name').disable();
    this.formGroupControl.get('description').disable();

    this.courseType = new CourseType();
    this.subscription = this.route.paramMap.subscribe(params => {
      const transId = params.get('id');
      this.courseTypeService.updateCourseTypeRoutes(transId);

      if (transId) {


        if (transId === 'new') {
          this.detail = false;
          this.edit();

          this.courseType = new CourseType();
          this.selectedDesc = undefined;
          this.formGroupControl.setValue({ name: '', description: '' });

        } else {
            this.courseTypeService.getCourseType(transId).subscribe(response => {
            this.detail = true;
            this.enabled = false;
            this.courseType = response;

            this.formGroupControl.setValue({ name: this.courseType.name, description: this.courseType.description });
            this.formGroupControl.get('name').disable();
            this.formGroupControl.get('description').disable();

            this.selectedDesc = response.name;
          });
        }
      } else {
        this.selectedDesc = undefined;
        this.detail = false;
        this.enabled = false;
        this.courseType = new CourseType();
      }


    });
  }
  /* ************************************************************ */
  //#endregion



  //#region CRUD desde API.INZO

  /* métodos del CRUD */
  /* *********************************************************** */
  create() {
    this.courseType = new CourseType({
      name: this.formGroupControl.get('name').value,
      description: this.formGroupControl.get('description').value,

    });
    this.courseTypeService.createCourseType(this.courseType).subscribe(
      response => {
        this.courseTypeService.getAllCoursesType().subscribe(coursesType => {
          this.courseTypeService.updateCoursesType(coursesType.items);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => {
          toastTitle = a;
        });
        this.translate
          .get('MESSAGES.SUCCESS.CREATE_LONG', {
            type: this.componentTypeTranslated,
            code: this.courseType.name,
          })
          .subscribe((a: string) => {
            toastBody = a;
          });
        this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
        this.cancel();
      },
      error => {
        if (error.status === 500 || error.status === 0) {
        } else if (error.status === 409) {
          let title: string;
          this.translate.get('MESSAGES.ERROR.CREATE_CONFLICT').subscribe((a: string) => {
            title = a;
            this.toastrService.error(error.error, title, { timeOut: 3000 });
          });
        } else {
        }
      },
    );
  }

  update() {
    const observer = jsonpatch.observe(this.courseType);
    this.courseType.name = this.formGroupControl.get('name').value;
    this.courseType.description = this.formGroupControl.get('description').value;

    const patch = jsonpatch.generate(observer);
    this.courseTypeService.modifyCourseType(this.courseType.courseTypeId, patch).subscribe(
      response => {
        this.courseTypeService.getAllCoursesType().subscribe(coursesType => {
          this.courseTypeService.updateCoursesType(coursesType.items);

          this.translate.get('MESSAGES.SUCCESS.UPDATE').subscribe((a: string) => {
            let toastTitle = '';
            let toastBody = a;

            this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
          });


        });

        this.cancel();
      },
      error => {
        let title: string;
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((a: string) => {
          title = a;
          this.toastrService.error(error.error, title, { timeOut: 3000 });
        });
      },
    );

  }

  delete() {
    this.courseTypeService.deleteCourseType(this.courseType.courseTypeId).subscribe(response => {
      this.courseTypeService.getAllCoursesType().subscribe(coursesType => {
        this.courseTypeService.updateCoursesType(coursesType.items);
      });
      let toastTitle = '';
      let toastBody = '';
      this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => {
        toastTitle = a;
      });
      this.translate
        .get('MESSAGES.SUCCESS.DELETE_LONG', {
          type: this.componentTypeTranslated,
          code: this.courseType.name,
        })
        .subscribe((a: string) => {
          toastBody = a;
        });
      this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
      this.cancel();
    });
  }
  /* *********************************************************** */

  //#endregion

  edit() {
    this.enabled = true;
    this.formGroupControl.get('name').enable();
    this.formGroupControl.get('description').enable();

  }

  cleanForm() {
    this.formGroupControl.setValue({ name: '', description: ''});
  }

  cancel() {
    this.cleanForm();
    this.detail = false;
    this.enabled = false;
    this.router.navigateByUrl(this.baseRoute);
  }

  back() {
    this.cancel();
  }
}
