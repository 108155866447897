import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AbsenceService } from 'src/app/services/absence.service';

@Component({
  selector: 'app-maintenance-it-leave',
  templateUrl: './it-leave.component.html',
  styleUrls: ['./../maintenance.components.css'],
})
export class ITLeaveComponent implements AfterViewInit, OnInit {
  constructor() { }

  ngAfterViewInit() {
  }
  ngOnInit() {
  }
}
