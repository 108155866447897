import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { IInputData } from 'inzo-calendar-lib/lib/Interface/IInputData';
import { LocaleSettings } from 'inzo-calendar-lib/lib/Interface/LocaleSettings';
import { AccountService, Hotkeys, ResponseNotificationService, SecurityStateManagementService } from 'inzo-portalempleado';
import moment = require('moment');
import * as jsonpatch from 'fast-json-patch';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WorkCalendarCreateRangeDialogComponent } from 'src/app/components/work-calendar/work-calendar-create-range-dialog/work-calendar-create-range-dialog.component';
import { getLocaleNames } from 'src/app/helpers/date.helper';
import { CloseYearHistory } from 'src/app/models/close-year-history.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { WCEvent } from 'src/app/models/wc-event.model';
import { WorkCalendar } from 'src/app/models/work-calendar.model';
import { WorkCalendarService } from 'src/app/services/work-calendar.service';
import { WorkCalendarHistory } from '../../../../../models/work-calendar-history.model';
import { CloseYearHistoryService } from '../../../services/close-year-history.service';
import { WorkCalendarHistoryService } from '../../../services/work-calendar-history.service';
import { CloseYearHistroyConfirmDialogComponent, ConfirmDialogType } from '../../close-year-histroy-confirm-dialog/close-year-histroy-confirm-dialog.component';

const EVENT_DAY_COLOR = "fuchsia";

@Component({
  selector: 'app-close-year-wc-history-edit-dialog',
  templateUrl: './close-year-wc-history-edit-dialog.component.html',
  styleUrls: [
    './close-year-wc-history-edit-dialog.component.css',
    './../../../../../components/maintenance-list.component.css',
  ],
})
export class CloseYearWorkCalendarHistoryEditDialogComponent implements OnInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  formGroupControl: FormGroup;

  workCalendarsHistoryArray: WorkCalendarHistory[] = [];
  revisedWorkCalendars: Set<string> = new Set();
  closeYearHistory: CloseYearHistory;

  closeYearHistoryArray: CloseYearHistory[] = [];

  filterApiWorkCalendar: FiltersApi = new FiltersApi();
  filterApiWorkCalendarHistory: FiltersApi = new FiltersApi();
  filterApiCloseYear: FiltersApi = new FiltersApi();

  workCalendarSelected: WorkCalendar = null;

  value: IInputData = {
    year: new Date().getFullYear(),
    dates: [],
    publicHolidayDays: [],
    disableWeekend: false,
    disablePublicHolidayDays: false,
  };

  locale: LocaleSettings = {
    dayNamesMin: [],
    monthNames: []
  };

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    private workCalendarService: WorkCalendarService,
    private workCalendarHistoryService: WorkCalendarHistoryService,
    private closeYearHistoryService: CloseYearHistoryService,
    public SSMService: SecurityStateManagementService,
    private RNService: ResponseNotificationService,
    public accountService: AccountService,
    public hotkeys: Hotkeys,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<WorkCalendar>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private toastrService: ToastrService,
  ) {
    this.filterApiWorkCalendar.add(
      {
        field: "related",
        value: "true"
      }
    );
    this.filterApiWorkCalendar.add(
      {
        field: "CloseYearHistoryNull",
        value: "false"
      }
    );
    this.filterApiCloseYear.add(
      {
        field: "related",
        value: "true"
      }
    );
    this.filterApiWorkCalendarHistory.add(
      {
        field: "related",
        value: "true"
      }
    );
    this.filterApiWorkCalendarHistory.addInclude("OldWorkCalendar");
    this.filterApiWorkCalendarHistory.addInclude("NewWorkCalendar");
  }

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = getLocaleNames(this.translate);
    });
    this.translate.get('CALENDAR.DAYS_OF_WEEK').subscribe((daysOfWeekLang) => {
      this.locale.dayNamesMin = daysOfWeekLang;
    });
    this.translate.get('CALENDAR.MONTHS').subscribe((monthsLang) => {
      this.locale.monthNames = monthsLang;
    });

    this.closeYearHistory = this.data.closeYearHistory;

    this.loadData();
    this.loadForm();
  }

  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.

  }

  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  loadData() {
    this.spinner.show();
    this.filterApiWorkCalendarHistory.add(
      {
        field: "CloseYearHistoryId",
        value: this.closeYearHistory.closeYearHistoryId
      },
      true
    );

    this.workCalendarHistoryService.getAllWorkCalendarHistory(this.filterApiWorkCalendarHistory.getStrinFilterApi()).subscribe(response => {
      this.workCalendarsHistoryArray = response.items;

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.RNService.showError(error);
    });
  };

  loadForm() {
    this.formGroupControl = new FormGroup({
      name: new FormControl(this.workCalendarSelected.name || '', Validators.required),
      validYear: new FormControl(
        this.workCalendarSelected.validYear  || moment(new Date()).year() + 1,
        [
          Validators.required,
          Validators.pattern(/^\d{4}$/),
        ]
      ),
      description: new FormControl(this.workCalendarSelected.description  || '', Validators.required),
      isActive: new FormControl(this.workCalendarSelected.isActive || false),
      disableWeekends: new FormControl(this.workCalendarSelected.disableWeekends || false),
    });
  }

  loadCalendar(workCalendarId: string): void {
    this.spinner.show();
    this.workCalendarService.getWorkCalendar(workCalendarId, true).subscribe(workCalendar => {
      this.workCalendarSelected = workCalendar;
      // this.value.disableWeekend = workCalendar.disableWeekends;

      this.value.year = parseInt(workCalendar.validYear);

      this.loadEvents(this.workCalendarSelected);
      this.loadForm();

      this.spinner.hide();
    });
  }

  loadEvents(workCalendar: WorkCalendar): void {
    // Cada vez que recarguemos la lista de eventos, reiniciamos el array de fechas del calendario
    this.value.dates = [];

    workCalendar.wcEvents.forEach(event => {
      const countDates = this.value.dates.length;
      this.value.dates.push({
        id: countDates + 1,
        tooltip: event.name,
        start: moment(event.startDate).toDate(),
        end: moment(event.endDate).toDate(),
        color: EVENT_DAY_COLOR,
        select: () => this.onRangeSelect(event)
      })
    });
  }

  onRangeSelect(event: WCEvent) {
    const createRangeDialog = this.dialog.open(WorkCalendarCreateRangeDialogComponent, {
      width: '550px',
      data: {
        workCalendarId: this.workCalendarSelected.workCalendarId,
        workCalendarName: this.workCalendarSelected.name,
        event: event
      }
    });

    createRangeDialog.afterClosed().subscribe((dialogData) => {
      if (dialogData) {
        if (dialogData.updatedEvent) {
          let updatedEvent = dialogData.updatedEvent
          this.workCalendarSelected.wcEvents.forEach(event => {
            if (event.wcEventId == updatedEvent.wcEventId) {
              event = updatedEvent;
            }
          });
        } else if (dialogData.deletedEvent) {
          let deletedEvent = dialogData.deletedEvent
          let indexToDelete = null;
          this.workCalendarSelected.wcEvents.forEach((event, iEvent) => {
            if (event.wcEventId == deletedEvent.wcEventId) {
              indexToDelete = iEvent;
            }
          });
          if (indexToDelete) {
            this.workCalendarSelected.wcEvents.splice(indexToDelete, 1);
          }
        }
        this.loadEvents(this.workCalendarSelected);
      }
    });
  }

  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  createEvent(): void {
    const createRangeDialog = this.dialog.open(WorkCalendarCreateRangeDialogComponent, {
      width: '550px',
      data: {
        workCalendarId: this.workCalendarSelected.workCalendarId,
        workCalendarName: this.workCalendarSelected.name
      }
    });

    createRangeDialog.afterClosed().subscribe((dialogData) => {
      if (dialogData) {
        let createdEvent = dialogData.createdEvent;
        this.workCalendarSelected.wcEvents.push(createdEvent);
        this.loadEvents(this.workCalendarSelected);
      }
    });
  }

  editWorkCalendar(workCalendar: WorkCalendar) {
    this.loadCalendar(workCalendar.workCalendarId);
  }

  onDaySelect(event) {
  }

  revisedlWorkCalendars() {
    this.spinner.show();
    const observer = jsonpatch.observe(this.workCalendarSelected);
    this.workCalendarSelected.name = this.formGroupControl.get('name').value;
    this.workCalendarSelected.description = this.formGroupControl.get('description').value;
    this.workCalendarSelected.validYear = this.formGroupControl.get('validYear').value;
    this.workCalendarSelected.isActive = this.formGroupControl.get('isActive').value;
    this.workCalendarSelected.disableWeekends = this.formGroupControl.get('disableWeekends').value;
    const patch = jsonpatch.generate(observer);

    this.workCalendarService.modifyWorkCalendar(this.workCalendarSelected.workCalendarId, patch).subscribe(
      response => {
        this.revisedWorkCalendars.add(this.workCalendarSelected.workCalendarId);

        this.workCalendarSelected = null;
        this.spinner.hide();

        this.loadData();
      }
    );
  }

  closeYear() {
    const confirmWCRevert =  this.dialog.open(CloseYearHistroyConfirmDialogComponent, {
      disableClose: true,
      width: '550px',
      data: {
        confirmDialogType: ConfirmDialogType.CLOSEYEAR_GENERATE
      }
    });

    confirmWCRevert.afterClosed().subscribe(async (confirm) => {
      if (confirm === true) {
        this.spinner.show();

        let newWorkCalendars: WorkCalendar[] = [];
        this.workCalendarsHistoryArray.forEach((item) => {
          newWorkCalendars.push(item.newWorkCalendar);
        });

        this.closeYearHistory.newWorkCalendars = newWorkCalendars;

        this.closeYearHistoryService.createCloseYearHistory(this.closeYearHistory, this.closeYearHistory.year).subscribe(
          response => {
            this.dialogRef.close(response);
            this.spinner.hide();
          }
        );
      }
    });
  }
}
