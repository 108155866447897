import { Component, OnInit, AfterViewInit } from '@angular/core';
import { WorkCalendarService } from 'src/app/services/work-calendar.service';

@Component({
  selector: 'app-maintenance-work-calendar',
  templateUrl: './work-calendar.component.html',
  styleUrls: ['./../maintenance.components.css'],
})
export class WorkCalendarComponent implements AfterViewInit, OnInit {
  showForm = false;
  constructor( private workCalendarService: WorkCalendarService) {
  }
  ngAfterViewInit() {
    this.workCalendarService.workCalendarRouteObservable.subscribe(p => {
      setTimeout(() => this.showForm = (p ? true : false));
    });
  }
  ngOnInit() {
  }
}
