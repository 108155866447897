import { NgxMatDateFormats } from "@angular-material-components/datetime-picker";
import { Permission } from "inzo-portalempleado";
export class CommonResources {
    public static IDENTIFICATION_DOCUMENT_TYPES: string[] = ["NIF", "NIE","Pasaporte"];
    public static DISABILITY_PERCENTAGES: number[] = [0, 33, 65]; //TODO better as string on API 0%,33%,65%,<65%
    public static GENDERS: string[] = ["Hombre", "Mujer", "Otros"];
    public static PHONEPATTERN="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$";
    public static EMAILPATTERN="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
    // public static email: RegExp = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/);
    public static POSTALCODEPATTERN="^([0-9]{5})([\-]{1}[0-9]{4})?$";
    public static NIF_PATTERN ="^[0-9]{8,8}[a-zA-Z]$";
    public static NIE_PATTERN="^[xyzXYZ][0-9]{7,8}[a-zA-Z]$";
    public static CIF_PATTERN="[a-zA-Z]{1}[0-9]{7}[a-zA-Z0-9]{1}";
    public static ONLY_INTEGERS_PATTERN='^[0-9]+$';
    public static CUSTOM_DATE_FORMAT = {
        parse: {
          dateInput: 'input',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      };
    public static CUSTOM_DATETIME_FORMATS: NgxMatDateFormats = {
        parse: {
            dateInput: 'input'
        },
        display: {
            dateInput: 'DD/MM/YYYY HH:mm:ss',
            monthYearLabel: 'MMM YYYY',
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'MMMM YYYY',
        }
    };
    public static PERMISSION_LIST: Permission[] = [
        new Permission("Employee", "Employee", "Employee Permissions", "Permiso para empleado"),
        new Permission("Manager", "Manager", "Manager Permissions", "Permiso para manager"),
        new Permission("RRHH", "RRHH", "RRHH Permissions", "Permiso para rrhh"),
        new Permission("Asignar roles","roles.assign", "User Permissions","Permiso para cambiar los roles de los usuarios."),
        new Permission("Actualizar usuarios","users.update", "User Permissions","Permiso para poder actualizar los detalles de usuarios."),
        new Permission("Ver usuarios","users.view", "User Permissions","Permiso para ver los detalles de usuarios."),
    ]
}
