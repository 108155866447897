import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'inzo-portalempleado';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { Payroll } from '../models/payroll.model';


@Injectable({ providedIn: 'root' })
export class PayrollService {
    private changeSubject: Subject<any> = new Subject;
    public changeObservable: Observable<any> = this.changeSubject.asObservable();
    private payrollSubject: ReplaySubject<Payroll[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public payrollObservable: Observable<Payroll[]> = this.payrollSubject.asObservable();

    private payrollRouteSubject: ReplaySubject<string> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
    public payrollRouteObservable: Observable<string> = this.payrollRouteSubject.asObservable();

    get serviceUrl() {
        return Config.apiBaseUrl + '/payroll';
    }

    constructor(private http: HttpClient) {
    }

    getAllPayrolls(filter?: string) {
        return this.http.get<any>(`${this.serviceUrl}${(filter!=undefined && filter !=null) ? filter : ""}`);
    }

    GetAllPayrollsByEmployee(filter?: string) {
      if (filter == undefined || filter == null) {
        filter = "";
      }

      let url = `${this.serviceUrl}/Employee/${filter}`;

      return this.http.get<any>(url);
    }

    getAllPayrollYearsByEmployee() {

      let url = `${this.serviceUrl}/Employee/Years`;

      return this.http.get<any>(url);
    }

    getPayroll(payrollId: string, filter?: string) {
        return this.http.get<Payroll>(`${this.serviceUrl}/${payrollId}${(filter!=undefined && filter !=null) ? filter : ""}`);
    }

    createPayroll(employeeId:string, newPayrollData: Payroll) {
        const endpointUrl = `${this.serviceUrl}/Employee/${employeeId}`;
        return this.http.post(endpointUrl, newPayrollData);
    }
    modifyPayroll(payrollId: string, newPayrollData: any) {
        const endpointUrl = `${this.serviceUrl}/${payrollId}`;
        return this.http.patch(endpointUrl, newPayrollData);
    }
    deletePayroll(payrollId: string) {
        const endpointUrl = `${this.serviceUrl}/${payrollId}`;
        return this.http.delete(endpointUrl);
    }
    reassociatePayroll(payrollId: string, employeeIdSource: string, employeeIdDestination: string) {
        const endpointUrl = `${this.serviceUrl}/${payrollId}/Source/${employeeIdSource}/Destination/${employeeIdDestination}`;
        return this.http.put(endpointUrl, null);
    }

    updatepayrolls(payrolls: Payroll[]) {
        this.payrollSubject.next(payrolls);
    }

    updatePayrollRoutes(id: string) {
        this.payrollRouteSubject.next(id);
    }

    notifyChanges() {
        this.changeSubject.next();
    }
}
